import { Button, Modal, Row, Table, Typography } from 'antd';
import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useAuth, useFirestoreCollectionData } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { getItemsAmounts } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import TableTitle from '../tables/TableTitle';

function NotifyMultiple({ userDocument, open, close }) {
    const { team } = useContext(TeamContext)
    const q = query(collection(getFirestore(), "payments"), where("team", "==", team.id), where('status', '==', 'requires_payment_method'), orderBy('timestamp', 'desc'))
    const { status, data } = useFirestoreCollectionData(q,
        {
            idField: 'fid'

        })

    const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(false);
    const auth = useAuth()

    useEffect(() => {
        if (status === "success") {

            setPayments(data.map((c) => {
                return { ...c, fid: c.id, alreadySelected: false, blocked: !c.client?.phone }
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])


    const rowSelection = {
        selectedRowKeys: payments.filter((c) => c.alreadySelected).map((c) => c.fid),
        onChange: (selectedRowKeys, selectedRows) => {
            payments.map((c) => {
                if (selectedRowKeys.findIndex((i) => i === c.fid) > -1) {
                    c.alreadySelected = true
                } else {
                    c.alreadySelected = false
                }
                return c
            })
            setPayments([...payments])
        },
        getCheckboxProps: (record) => ({
            disabled: false,
            checked: record.alreadySelected,
            name: record.name,
        }),
    };

    return (
        <Modal visible={open} onCancel={() => close()} footer={null} title={null} width={
            window.innerWidth > 600 ? '50%' : '90%'
        }
            style={{ top: 10 }}
        >
            <div className="d-flex flex-column">
                <Row>
                    <div className="d-flex flex-column">
                        <Typography.Title style={{ margin: 0 }} level={4}>Notificar a múltiples en espera de pago</Typography.Title>

                    </div>
                </Row>
                <Table
                    className="pro-table"
                    pagination={{ hideOnSinglePage: true }}
                    style={{ marginTop: '25px' }}
                    loading={status === "loading"}
                    locale="es"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={[
                        {
                            title: <TableTitle title="Cliente" />,
                            dataIndex: 'client',
                            render: (text, record) => {
                                return <Typography.Text style={{
                                    fontSize: '11px', marginBottom: '0px',
                                }}>{record.client?.name?.toUpperCase() ?? 'Sin cliente'}</Typography.Text>
                            },
                        },
                        {
                            title: <TableTitle title="Teléfono" />,
                            dataIndex: 'status',
                            render: (text, record) => <Typography.Text style={{ fontSize: '11px', marginBottom: '0px' }}>{record.client?.phone}</Typography.Text>
                        },
                        {
                            title: <TableTitle title="Enviado" />,
                            dataIndex: 'timestamp',
                            render: (text, record) => {
                                return <div className="d-flex flex-column">
                                    <Typography.Text className={''} style={{ fontSize: '11px', marginBottom: '0px' }}>{moment(text).format('dddd D MMM YYYY')}</Typography.Text>
                                    <Typography.Text className={''} style={{ fontSize: '9px', marginBottom: '0px' }}>{moment(text).fromNow()}</Typography.Text>
                                </div>
                            },
                        },
                        {
                            title: <TableTitle title="Total" />,
                            dataIndex: 'total',
                            render: (text, record) => {

                                return <Typography.Text className={''} style={{ fontSize: '11px', marginBottom: '0px' }} >{getItemsAmounts(record.items).totalString}</Typography.Text>
                            },
                        },]}
                    rowKey={(record) => record.fid}
                    dataSource={payments}
                />
                <Row justify='end'>
                    <Button onClick={async () => {

                        try {
                            setLoading(true)
                            const paymentsToNotify = payments.filter((c) => c.alreadySelected);
                            const onlyIds = paymentsToNotify.map((c) => c.fid);

                            await SignedInternalAPIRequest({
                                payments: onlyIds
                            }, 'hilosApp/v1/sendrequest', auth.currentUser, {})

                            setLoading(false)
                            close();
                        } catch (error) {
                            setLoading(false)
                        }

                    }} loading={loading} disabled={
                        payments.filter((c) => c.alreadySelected).length === 0
                    } type="primary">
                        Notificar {payments.filter((c) => c.alreadySelected).length} pagos
                    </Button>
                </Row>
            </div>
        </Modal>
    );
}

export default NotifyMultiple;