import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    ExperimentOutlined,
    SearchOutlined,
    SettingOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import {
    Alert,
    Button,
    Col,
    message,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import {
    collection,
    doc,
    getCountFromServer,
    getDocs,
    getFirestore, limit, onSnapshot, orderBy,
    query,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext";
import infoGrad from '../../assets/images/information-grad.png';
import TeamContext from "../../context/teamcontext/TeamContext";
import UserContext from "../../context/usercontext/UserContext";
import { useUserPermissions } from "../../customHooks/userPermissions";
import ActionablePageHeader from "../../widgets/headers/ActionablePageHeader";
import DefaultHelpPageHeader from "../../widgets/headers/DefaultHelpPageHeader";
import HelperCard from '../../widgets/cards/HelperCard';
import TableTitle from "../../widgets/tables/TableTitle";
import {
    getItemsAmounts,
    paymentForms,
    returnCurrencyValue,
} from "../functions/helpers";
import GeneralSettingsReceiptsModal from "../../widgets/modals/GeneralSettingsReceiptsModal";
import { BiWorld } from 'react-icons/bi';
import RelationsDrawer from "../../widgets/relations/RelationsDrawer";
import SearchModal from "../../widgets/search/SearchModal";
import CreateInvoiceFromReceiptModal from "../../widgets/modals/CreateInvoiceFromReceiptModal";
import { AlgoliaSearchComponent } from "../../widgets/search/AlgoliaSearchComponents";
import { ReceiptActions } from "../../widgets/actions/ReceiptActions";
import CreateReceipt from "../../widgets/invoices/CreateReceipt";

/**
     * It returns a tag with a color and an icon depending on the status of the receipt
     * @returns A tag with a row inside of it. The row has an icon and a text.
     */
export const ReceiptStatusTag = ({ receipt }) => {
    var icon =
        receipt.invoice || receipt.status === "completed" ? (
            <CheckOutlined />
        ) : receipt.status === "cancelled" ? (
            <CloseOutlined />
        ) : (
            <ClockCircleOutlined />
        );
    var statusColor =
        receipt.invoice || receipt.status === "completed" ? "#CCF6DB" : receipt.status === 'cancelled' ? '#FAEAE9' : "#E6BEFF";

    return (
        <div className="d-flex flex-column">
            <div >
                <Tag style={{ backgroundColor: statusColor, border: "none", color: receipt.status === 'cancelled' ? '#E06560' : 'black' }}>
                    <Row align="middle">
                        {icon}
                        <Typography.Text type="" style={{ marginLeft: "5px", color: receipt.status === 'cancelled' ? '#E06560' : 'black' }}>
                            {receipt?.status === "completed" || receipt.invoice
                                ? "Completo" : receipt?.status === 'cancelled' ? 'Cancelado'
                                    : "En espera"}
                        </Typography.Text>
                    </Row>
                </Tag>
            </div>
            <div className="display-flex">
                {(receipt?.invoiceError && receipt?.status !== 'completed') && <Tag style={{ backgroundColor: '#FEE9E9', border: "none" }}>
                    <Row align="middle">
                        <WarningOutlined style={{ color: '#F15B5B' }} />
                        <Typography.Text type="" style={{ marginLeft: "5px", backgroundColor: '#FEE9E9', border: "none", color: '#F15B5B' }}>
                            Ocurrio un error
                        </Typography.Text>
                    </Row>
                </Tag>}
            </div>
        </div>

    );
};


function ReceiptsPage({ userDocument, changeRender, subtab }) {
    const { user } = useContext(UserContext);

    const { team } = useContext(TeamContext);
    const { billingAccount } = useContext(BillingAccountContext);
    const [globalInvoicesId, setGlobalInvoicesId] = useState([]);
    const fs = getFirestore();
    const [pageActive, setpageActive] = useState(1);
    const [pageSize, setpageSize] = useState(10)



    // const receiptsRef = query(
    //     collection(fs, `receipts`),
    //     where("team", "==", team?.id ?? ""),
    //     orderBy("timestamp", "desc")
    // );

    // const { status: receiptsStatus, data: receipts } = useFirestoreCollectionData(receiptsRef, {
    //     idField: "id",
    // });

    // const [receipts, setreceipts] = useState([]);

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [openEditReceiptModal, /*setopenEditReceiptModal*/] = useState(false);
    const [createReceiptModalEdit, /*setCreateReceiptModalEdit*/] = useState(null);
    const [createReceipt, setcreateReceipt] = useState(false);
    const [search, /*setSearch*/] = useState('')
    const [filterBy, setFilterBy] = useState('')

    const [generalSettingsReceiptsModal, setGeneralSettingsReceiptsModal] = useState(false);
    const { canCreateInvoices } = useUserPermissions({
        user,
        team,
        billingAccount,
    });
    const [showRelacion, setShowRelation] = useState(false);

    const receiptsRefPagination = query(collection(fs, `receipts`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), limit(1000));
    const [receiptsData, setReceiptsData] = useState([])
    const [lastReceiptDoc, setLastReceiptDoc] = useState(null)
    const [totalReceipts, setTotalReceipts] = useState(0)
    const [tableLoading, setTableLoading] = useState(false)

    const [/*actionOnReceipt*/, /*setactionOnReceipt*/] = useState(null)
    useEffect(() => {
        setReceiptsData([])
        setLastReceiptDoc(null)
        setTableLoading(true)

        const getInitial = async () => {
            setTableLoading(true)
            const coll = query(collection(fs, `receipts`), where('team', '==', team?.id ?? ''));
            const snapshot = await getCountFromServer(coll);
            setTotalReceipts(snapshot.data().count)
            onSnapshot(receiptsRefPagination, (data) => {
                let newDocs = data.docs.map((doc) => {
                    return doc.data()
                })
                setReceiptsData(newDocs)
                setLastReceiptDoc(data.docs[newDocs.length - 1])
                setTableLoading(false)

            });
        }
        getInitial();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.id])

    const getNextPage = async ({ pa, ps }) => {
        try {
            setTableLoading(true)
            if ((pa * ps - receiptsData.length) <= 0) {
                setTableLoading(false)
            }
            const receiptsRefPagination = query(collection(fs, `receipts`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), startAfter(lastReceiptDoc), limit(pa * ps - receiptsData.length));
            // console.log(pa * ps - receiptsData.length)
            const newDocs = await getDocs(receiptsRefPagination)
            let newDocsData = newDocs.docs.map((d) => d.data())
            setReceiptsData([...receiptsData, ...newDocsData])
            setLastReceiptDoc(newDocs.docs[newDocsData.length - 1])
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
        }
    }

    useEffect(() => {
        serchDuplicateInvoices();

        //eslint-disable-next-line
    }, [receiptsData]);


    const serchDuplicateInvoices = async () => {
        const invoicesIds = receiptsData.map((receipt) => receipt?.invoice ?? null);
        const invocesIdsNotNulls = invoicesIds.filter(i => i !== null)
        const invoicesGlobal = invocesIdsNotNulls.filter((elemento, indice) => invocesIdsNotNulls.indexOf(elemento) !== indice);
        setGlobalInvoicesId(invoicesGlobal);
    }

    // Dentro del componente...
    useEffect(() => {
        // console.log(createReceiptModalEdit, 'createReceiptModalEdit');

    }, [createReceiptModalEdit]);

    useEffect(() => {
        // console.log(openEditReceiptModal, 'openEditReceiptModal');
    }, [openEditReceiptModal]);


    var filteredReceipts = receiptsData?.filter((receipt) => {
        if (filterBy === "emailErrors") {
            return ['bounce', 'deferred', 'blocked'].includes(receipt?.emails?.lastEvent)
        }

        if (search === '') return true
        // return receipt?.client?.name?.toLowerCase().includes(search.toLowerCase()) || receipt?.client?.email?.toLowerCase().includes(search.toLowerCase()) || receipt?.id?.toLowerCase().includes(search.toLowerCase()) || receipt?.invoice?.toLowerCase().includes(search.toLowerCase()) || receipt?.status?.toLowerCase().includes(search.toLowerCase()) || receipt?.url?.toLowerCase().includes(search.toLowerCase()) || receipt?.items?.some((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
        //BY CURRENCY 

        return receipt?.client?.name?.toLowerCase().includes(search?.toLowerCase()) || receipt?.client?.email?.toLowerCase().includes(search?.toLowerCase()) || receipt?.id?.toLowerCase().includes(search?.toLowerCase()) || receipt?.invoice?.toLowerCase().includes(search?.toLowerCase()) || receipt?.status?.toLowerCase().includes(search?.toLowerCase()) || String(receipt?.whmcsInvoiceId ?? '').toLocaleLowerCase().includes(search.toLocaleLowerCase()) || receipt?.url?.toLowerCase().includes(search?.toLowerCase()) || receipt?.items?.some((item) => item?.name?.toLowerCase().includes(search?.toLowerCase())) || receipt?.currency?.toLowerCase().includes(search?.toLowerCase())
    })
    /**
     * It returns a row with two columns, the first one has a page header with a title and a button to
     * create a new receipt, the second one has a table with the receipts
     * @returns A row with a column that has a page header with a title of "Recibos creados" and a button
     * that says "Nuevo recibo"
    
    */
    const SaleReceipt = () => {
        return (

            <Row style={{ width: "100%" }}>
                {createReceipt && <CreateReceipt open={createReceipt} userDocument={userDocument} close={() => setcreateReceipt(false)} />}
                <CreateInvoiceFromReceiptModal />

                {showSearchModal && <SearchModal open={showSearchModal} close={() => setShowSearchModal(false)} localTypeData="receipts" localData={receiptsData.map(r => {
                    return {
                        ...r,
                        clientName: r?.client?.name ?? '',
                        clientEmail: r?.client?.email ?? '',
                        rawData: JSON.stringify(r)
                    }
                })} changeRender={(v) => changeRender(v)} />}
                <HelperCard supportImageUrl={infoGrad} squareImg={true} title='¿Qué son los recibos de venta?' description='Los recibos de venta son documentos que se envían al cliente después de que ha realizado un pago. El propósito de estos recibos es permitir al cliente generar su propia autofactura a partir de la información proporcionada en el recibo.' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/7546346-hablemos-del-recibo-de-venta', '_blank')} />
                <Col xs={24} style={{ marginTop: "25px" }}>
                    <ActionablePageHeader
                        title="Recibos creados"
                        actions={
                            <Space>
                                {generalSettingsReceiptsModal && (<GeneralSettingsReceiptsModal open={generalSettingsReceiptsModal} close={(v) => {
                                    setGeneralSettingsReceiptsModal(false)
                                }}
                                />
                                )}
                                <Button icon={<SettingOutlined />} onClick={() => { setGeneralSettingsReceiptsModal(true) }} />
                                {canCreateInvoices && <Button onClick={() => {
                                    window.location.href = '/createReceipt'
                                }} type="primary">
                                    Nuevo recibo
                                </Button>}
                            </Space>
                        }
                    />

                </Col>
                <Col xs={24}>
                    <Row align="middle" justify="end" style={{ marginTop: '20px' }}>
                        <Alert
                            onClick={() => { setGeneralSettingsReceiptsModal(true) }}
                            type={team?.defaults?.avoidReceipts ? 'error' : 'success'}
                            style={{ marginRight: 'auto', textAlign: 'center', borderRadius: '25px', cursor: 'pointer', height: '35px' }}
                            message={
                                team?.defaults?.avoidReceipts
                                    ? 'Recibos de venta automáticos desactivado.'
                                    : 'Recibos de venta automáticos activado.'
                            }
                        />
                        <Space direction="vertical" style={{ width: window.innerWidth < 900 ? '100%' : '50%' }}>
                            {/* <Input.Search style={{ width: '100%' }} placeholder={`Buscar en ${receiptsData?.length} de ${totalReceipts} recibos`} defaultValue={search} onSearch={(v) => {
                                setpageActive(1)
                                setSearch(v)
                            }} /> */}
                            <AlgoliaSearchComponent indexName='receipts' teamId={team.id} type='receipts' />
                            <Row justify="end" style={{ marginTop: '5px' }}>
                                <Space>
                                    <Tag onClick={() => setFilterBy(filterBy !== '' ? '' : 'emailErrors')} className={filterBy ? 'activeTag clickable' : ' clickable'}>Correo con errores</Tag>
                                </Space>
                            </Row>
                        </Space>
                        {/* <Button shape="circle" icon={<SearchOutlined />} onClick={() => setShowSearchModal(true)} /> */}
                    </Row>
                    {/* <Row justify="end">
                        
                    </Row> */}
                </Col>
                <Col xs={24} style={{ marginTop: "25px" }}>
                    <Table
                        dataSource={filteredReceipts}
                        loading={tableLoading}


                        // pagination={{
                        //     current: pageActive,
                        //     pageSize: pageSize,


                        //     onChange: (p, ps) => {

                        //         setpageActive(p)
                        //         setpageSize(ps)
                        //     },
                        //     // onPageChange: (p) => setpageActive(p)
                        // }}

                        pagination={{
                            pageSize: pageSize,
                            defaultCurrent: pageActive,
                            onChange: (p, ps) => {
                                setpageActive(p)
                                setpageSize(ps)
                                if (receiptsData.length <= p * ps) {
                                    getNextPage({ pa: p, ps: ps })
                                }
                            },
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100'],
                            showTotal: (total, range) => {
                                if (search) {
                                    return `${range[0]}-${range[1]} de ${filteredReceipts.length} recibos`
                                } else {
                                    return `${range[0]}-${range[1]} de ${total} recibos`
                                }
                            },
                            position: ['bottomCenter'],
                            defaultPageSize: 10,
                            showQuickJumper: false,
                            responsive: true,
                            total: (search || filterBy) ? filteredReceipts : totalReceipts ?? 1,
                            //total: totalReceipts ?? 1,
                            locale: { items_per_page: '/ página' }
                        }}

                        locale={
                            {
                                emptyText: (
                                    <div style={{ textAlign: 'center' }}>
                                        <p>No se encuentra registro en los últimos 35 dias, busca en todo tu historial</p>
                                        <Button shape="circle" icon={<SearchOutlined />} onClick={() => setShowSearchModal(true)} />
                                    </div>
                                ),
                            }
                        }

                        columns={[
                            {
                                title: <TableTitle title="Cliente" />,
                                dataIndex: "client",
                                key: "client",
                                render: (text, record) => {
                                    return (
                                        <div className="d-flex flex-column">
                                            <Row align="middle">
                                                <Typography.Text type="secondary">
                                                    {record?.client?.name ?? "Público en general"}
                                                </Typography.Text>
                                                {!record.livemode && (
                                                    <Tooltip title="Modo pruebas">
                                                        <ExperimentOutlined
                                                            style={{
                                                                marginLeft: "5px",
                                                                fontSize: "10px",
                                                                color: "#cecece",
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Row>
                                            <Typography.Text
                                                type="secondary"
                                                style={{ fontSize: "11px" }}
                                                copyable={{ text: record.id }}
                                            >
                                                ID: {record.id}
                                            </Typography.Text>
                                            {(record.orderId || record?.metadata?.orderId) && <Typography.Text type="secondary"
                                                style={{ fontSize: "11px" }}>Order ID: {record?.orderId ?? record?.metadata?.orderId ?? ''}</Typography.Text>}
                                            {record?.whmcsInvoiceId && <Typography.Text type="secondary" style={{ fontSize: '11px' }}>WHMCS: {record?.whmcsInvoiceId ?? ''}</Typography.Text>}
                                        </div>
                                    );
                                },
                            },
                            {
                                title: <TableTitle title="Estado" />,
                                dataIndex: "status",
                                key: "status",
                                render: (text, record) => {
                                    return (
                                        <ReceiptStatusTag receipt={record} />
                                    );
                                },
                            },
                            {
                                title: <TableTitle title="Total" />,
                                dataIndex: "total",
                                key: "total",
                                render: (text, record) => {
                                    var showTotal = getItemsAmounts(record.items, (record.discount > 0 && record.paidIn === 'bank')).total

                                    return <Tooltip title={record.exchangeRate ? `${returnCurrencyValue((showTotal) * (record.exchangeRate ?? 1))} mxn` : null}>
                                        <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => {
                                        }} ellipsis>{returnCurrencyValue(showTotal)} {(record?.currency ?? '').toUpperCase()} </Typography.Text>
                                    </Tooltip>
                                    // return (
                                    //     <Typography.Text type="secondary">
                                    //         {getItemsAmounts(record.items, record.paidIn === 'bank').totalWithoutFeeString}{" "}
                                    //         <span style={{ fontSize: "10px" }}>
                                    //             {record.currency
                                    //                 ? record.currency?.toUpperCase()
                                    //                 : "MXN"}
                                    //         </span>
                                    //     </Typography.Text>
                                    // );
                                },
                            },
                            {
                                title: <TableTitle title="Fecha" />,
                                dataIndex: "timestamp",
                                key: "timestamp",
                                render: (text, record) => {
                                    return (
                                        <Typography.Text type="secondary">
                                            {moment(text).format("DD MMM YYYY H:mm")}
                                        </Typography.Text>
                                    );
                                },
                            },
                            {
                                title: (
                                    <TableTitle
                                        title="Válido para auto-factura hasta"
                                        tooltip="Tus clientes podrán generar su factura antes de este día y hora"
                                    />
                                ),
                                dataIndex: "validDays",
                                key: "validDays",
                                render: (text, record) => {
                                    return (
                                        <Typography.Text type="secondary">
                                            {record.disallowInvoice
                                                ? "No facturable"
                                                : moment(record.validUntil).format("DD MMM YYYY H:mm")}
                                        </Typography.Text>
                                    );
                                },
                            },
                            {
                                title: <TableTitle title="Pagos relacionados" />,
                                dataIndex: "payments",
                                key: "payments",
                                render: (text, record) => {
                                    const payments = (record?.payments ?? []).length;
                                    const hasGlobalInvoice = globalInvoicesId?.includes(record?.invoice)
                                    if (!payments && !hasGlobalInvoice) return <></>
                                    return (
                                        <div className="d-flex flex-column">
                                            <div style={{ marginTop: "4px" }}>
                                                <Tag onClick={() => {
                                                    setpageActive(pageActive)
                                                    if (!hasGlobalInvoice) return
                                                    setShowRelation({
                                                        title: `Facura Global`,
                                                        type: 'invoices',
                                                        db: 'receipts',
                                                        relatedWith: record,
                                                        count: record?.receiptsCount ?? 1,
                                                        elementID: record?.id,
                                                        subdb: 'invoices',
                                                        item: record,
                                                        related: receiptsData.filter((r) => r.invoice === record.invoice),
                                                    })
                                                }} >
                                                    {payments ? `${payments} pago${payments > 1 ? "s" : ""}` : null}
                                                    {hasGlobalInvoice && <Tooltip title={'Recibo con factura global'}><BiWorld className='clickable' style={{ margin: '3px' }} /></Tooltip>}
                                                </Tag>
                                            </div>
                                        </div>
                                    );
                                },
                            },
                            {
                                title: (
                                    <TableTitle
                                        title="Método de pago"
                                        tooltip="Método utilizado para el pago"
                                    />
                                ),
                                dataIndex: "payment_form",
                                key: "payment_form",
                                render: (text, record) => {
                                    return (
                                        // <Typography.Text type="secondary">
                                        //     {/* {paymentForms.find((a) => a.value === (typeof record?.payment_form === 'string' ? record?.payment_form : record?.payment_form?.value)?.label)} */}
                                        //     {/* {typeof record?.payment_form === 'string' ? record?.payment_form : record?.payment_form?.value} */}
                                        //     {paymentForms.filter(a => a.value === (typeof record?.payment_form === 'string' ? record?.payment_form : record?.payment_form?.value))[0].label}
                                        // </Typography.Text>
                                        <Select
                                            showSearch
                                            optionFilterProp="label"
                                            bordered={false}
                                            value={typeof record?.payment_form === 'string' ? record?.payment_form : record?.payment_form?.value}
                                            options={paymentForms}
                                            style={{ width: '100%' }}
                                            onSelect={async (v) => {
                                                try {
                                                    await updateDoc(doc(fs, 'receipts', record.id), {
                                                        payment_form: v
                                                    })
                                                    message.success('Método de pago actualizado')
                                                } catch (error) {
                                                    message.error(error?.message ?? 'Error al actualizar método de pago')
                                                }
                                            }}
                                        />
                                    );
                                },
                            },

                            {
                                title: "",
                                dataIndex: "actions",
                                render: (text, record) => (
                                    <ReceiptActions record={record} />
                                ),
                            },
                        ]}
                    />
                </Col>
            </Row>
        );
    };

    return (
        <Row justify="center">

            {showRelacion && (
                <Suspense fallback={<>Loading...</>}>
                    <RelationsDrawer open={showRelacion} relation={showRelacion} render='invoicesGlobal' close={() => setShowRelation(false)} />
                </Suspense>
            )}

            <Col xs={24}>
                <DefaultHelpPageHeader
                    userDocument={userDocument}
                    title="Recibos de venta"
                    description={"Emite recibos de venta para que tus clientes se autofacturen."}
                    extraInSub={null
                        // <Row>
                        //   <div style={{ height: '5px', width: '5px', backgroundColor: 'green', borderRadius: '50%' }}></div>
                        //   <Typography.Text style={{ marginLeft: '5px' }}>Recibiendo eventos de Stripe</Typography.Text>
                        // </Row>
                    }
                />
            </Col>
            <Col xs={24} style={{ marginTop: "25px" }}>
                <SaleReceipt />
            </Col>

        </Row>
    );
}

export default ReceiptsPage;
