import { Button, Col, Divider, Dropdown, Layout, Menu, message, Row, Space, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useState, useEffect, Suspense, useContext } from 'react';

import { Column, Area } from '@ant-design/plots';
import { useAuth, useRemoteConfigValue } from 'reactfire';
import { SignedInternalAPIRequest } from '../functions/APIRequests';
import { paymentByStatus, paymentIntentsStatusMessage, returnCurrencyValue, returnTextColorByPaymentStatus } from '../functions/helpers';
import { useMembership } from '../../customHooks/useMembership';
import { motion } from 'framer-motion';

import defaultFinances from '../../assets/images/insightsBlurred.png';
import BlockedModal from '../../widgets/modals/BlockedModal';
import { AppstoreAddOutlined, DownOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
// import StripeModal from '../../widgets/modals/ConectModal';
// import BankModal from '../../widgets/integrations/BankModal';
// import FacturapiModal from '../../widgets/integrations/FacturapiModal';
// import IntegrationsContext from '../../context/integrationscontext/IntegrationsContext';
// import ServiceListModal from '../../widgets/services/ServicesListModal';
// import PaymentsListModal from '../../widgets/payments/PaymentsListModal';
// import TestInvoiceModal from '../../widgets/invoices/TestInvoiceModal';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import SavingsGraphModal from '../../widgets/modals/SavingsGraphModal';
// import DefaultsConfigDrawer from '../../widgets/drawers/DefaultsConfigDrawer';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

import stripeLogo from '../../assets/images/stripe_icon.png';
import speiLogo from '../../assets/images/spei.png';
import DrawerApps from '../../widgets/drawers/DrawerApps';
import gigstackLogo from '../../assets/images/GigstackLogoText.png';
import airtableLogo from '../../assets/images/Airtable-Logo.png';
import zapierLogo from '../../assets/images/zapierLogo.png';

export const returnColorOnStatist = (type) => {
    let color = '#53D182'
    switch (type) {
        case 'active':
            color = '#53D182'
            break;
        case 'error':
            color = '#FF666F'
            break;
        case 'inactive':
            color = '#989898'
            break;
        case 'activeLight':
            color = '#EBF9EF'
            break;
        case 'errorLight':
            color = '#ffe3e3'
            break;
        case 'inactiveLight':
            color = '#F8F8F8'
            break;
        default:
            color = '#53D182'
    }
    return color
}

function InsigthsPage({ userDocument, changeRender }) {
    const auth = useAuth()

    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    // const [setupIntegrationsCompleted, setSetupIntegrationsCompleted] = useState((team?.integrationsSetup ?? []).length === 0 || (team?.stripe?.completed && team?.facturapi?.completed && team?.bank?.completed) || team?.hideIntegrationsCards)

    // const {
    //     stripeModalOpen,
    //     satModalOpen,
    //     importProductsModalOpen,
    //     importPaymentsModalOpen,
    //     bankAccountModalOpen,
    //     testInvoiceModalOpen,
    //     handleCloseIntegrationModal,
    //     handleLoadingCards,
    //     defaultsModalOpen,
    //     automaticInvoicingModalOpen
    // } = useContext(IntegrationsContext)

    const { isPaidUser,
    } = useMembership({ uid: auth.currentUser.uid, billingAccountId: billingAccount?.id })


    const year = moment().format('YY');
    const [period, setPeriod] = useState(moment().startOf('month'))
    const [lastPeriod, setLastPeriod] = useState(moment().add(-1, 'months').startOf('month'))
    const [endOfLastPeriod, setEndOfLastPeriod] = useState(moment().add(-1, 'months').endOf('month'))
    const [endOfPeriod, setEndOfPeriod] = useState(moment().endOf('month'))
    const [periodText, setPeriodText] = useState('Este mes')
    const [loading, setloading] = useState(false)


    const [data, setdata] = useState([])
    const { data: showSavingsGraph } = useRemoteConfigValue("showSavingsGraphModal");
    const [showSavingsGraphModal, setshowSavingsGraphModal] = useState(false)
    const [savingsImageUrl, setSavingsImageUrl] = useState('')
    const [appsOpen, setAppsOpen] = useState(false)
    const totalAutomations = data?.filter(p => p.category === 'payment' && p.livemode === true && p.status === 'succeeded' && p.automations && p.timestamp < endOfPeriod && p.timestamp > period).length
    const [integrationsSections, setIntegrationsSections] = useState([
        {
            title: 'Stripe',
            description: '',
            available: true,
            image: stripeLogo,
            key: 'stripe',
            completed: team?.stripe?.completed,
            collapsed: true,
            showCompleted: true,
            imageHeight: 35,
        },
        {
            title: 'SPEI',
            description: '',
            available: true,
            image: speiLogo,
            key: 'bank',
            completed: team?.bank?.completed,
            collapsed: false,
            showCompleted: true,
            imageHeight: 20,
        },
        {
            title: 'Integraciones',
            description: '',
            available: true,
            image: [airtableLogo, zapierLogo, gigstackLogo],
            key: 'integrations',
            completed: false,
            showCompleted: false,
            collapsed: false,
            imageHeight: 20,
        },
    ])

    const invoices = data.filter(i => i.category === 'invoice' && i.livemode === true && i.status === 'valid' && !i.isGlobal && i.payments?.length === 1 && i.timestamp < endOfPeriod && i.timestamp > period)
    const receipts = data.filter(i => i.category === 'receipt' && i.livemode === true && i.status === 'pending' && i.timestamp < endOfPeriod && i.timestamp > period)


    const stripePayments = data.filter(i => i.category === 'payment' && i.livemode === true && (i.id.includes('pi_') || i.id.includes('ch_')) && i.status === 'succeeded' && i.timestamp < endOfPeriod && i.timestamp > period)
    const stripePaymentsTotal = stripePayments.reduce((a, b) => a + b.value, 0)
    const stripeInvoices = invoices?.filter(i => stripePayments.map(i => i.invoicesArray).flat().includes(i.id))
    const stripeInvoicesTotal = stripeInvoices.reduce((a, b) => a + b.value, 0)
    // const stripeReceipts = receipts.filter(r => r.payments[0].includes('pi_') || r.payments[0].includes('ch_'))
    const stripeReceipts = receipts.filter(receipt => stripePayments.map(payment => payment.id).includes(receipt.payments[0]))
    const stripeReceiptsTotal = stripeReceipts.length

    const speiPayments = data.filter(i => i.category === 'payment' && i.livemode === true && i.processor === 'spei' && i.status === 'succeeded' && i.timestamp < endOfPeriod && i.timestamp > period)
    const speiPaymentsTotal = speiPayments.reduce((a, b) => a + b.value, 0)
    const speiInvoices = invoices?.filter(i => speiPayments.map(i => i.invoicesArray).flat().includes(i.id))
    const speiInvoicesTotal = speiInvoices.reduce((a, b) => a + b.value, 0)
    // const speiReceipts = receipts.filter(i => speiPayments.map(i => i.id).includes(i.payments[0]))
    const speiReceipts = receipts?.filter(receipt => {
        const speiPaymentIds = speiPayments.map(payment => payment.id);
        return receipt.payments.every(paymentId => speiPaymentIds.includes(paymentId));
    })
    const speiReceiptsTotal = speiReceipts.length

    const otherProcessors = data.filter(i => i.category === 'payment' && i.livemode === true && i.processor !== 'spei' && i.processor !== 'stripe' && i.status === 'succeeded' && i.timestamp < endOfPeriod && i.timestamp > period)
    const otherProcessorsTotal = otherProcessors.reduce((a, b) => a + b.value, 0)
    const otherProcessorsInvoices = invoices?.filter(i => otherProcessors.map(i => i.invoicesArray).flat().includes(i.id))
    const otherProcessorsInvoicesTotal = otherProcessorsInvoices.reduce((a, b) => a + b.value, 0)
    // const otherProcessorsReceipts = receipts.filter(i => otherProcessors.map(i => i.id).includes(i.payments[0]))
    const otherProcessorsReceipts = receipts.filter(receipt => {
        const otherProcessorsPaymentIds = otherProcessors.map(payment => payment.id);
        return receipt.payments.every(paymentId => otherProcessorsPaymentIds.includes(paymentId));
    })
    const otherProcessorsReceiptsTotal = otherProcessorsReceipts.length

    const getInsigths = async () => {
        if (!isPaidUser) return
        if (data.length > 0) return
        setdata([])
        setloading(true)
        try {
            const res = await SignedInternalAPIRequest(
                { teamId: team.id }, 'appInsights/v1/main', auth.currentUser, {}, 'GET'
            )
            setdata(res.map(i => {
                return {
                    ...i,
                    timestamp: moment(i.timestamp.value).valueOf(),
                    year: moment(i.timestamp.value).format('YY-MM')
                }
            }))

            message.success('Datos Actualizados')
            setloading(false)
        } catch (error) {
            message.error(error.message)
            setloading(false)
        }
    }

    const requestGenerateSavingsGraph = async () => {
        try {
            const res = await SignedInternalAPIRequest(
                { teamId: team.id, billingAccountId: billingAccount.id }, 'getSavingsGraph', auth.currentUser, {}, 'GET'
            )
            setshowSavingsGraphModal(true)
            setSavingsImageUrl(res)
        } catch (error) {
            message.error(error?.message ?? 'Error al generar la imagen')
        }
    }

    useEffect(() => {
        getInsigths()
        if (showSavingsGraph._value === 'true' ? true : false) {
            requestGenerateSavingsGraph()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team])

    // const PaymentsPieGraphByStatus = () => {

    //     const G = G2.getEngine('canvas');

    //     let paymentsData = data.filter(i => i.category === 'payment' && i.livemode === true)

    //     if (year !== '') {
    //         paymentsData = paymentsData.filter(i => i.year?.includes(year))
    //     }
    //     const paymentsByStatus = paymentsData.reduce((acc, curr) => {
    //         if (acc[curr.status]) {
    //             acc[curr.status] += curr.value;
    //         } else {
    //             acc[curr.status] = curr.value;
    //         }
    //         return acc;
    //     }, {});

    //     let resultArray = Object.entries(paymentsByStatus).map(([status, value]) => ({ status, value }))

    //     const config = {
    //         appendPadding: 10,
    //         data: resultArray,
    //         angleField: 'value',
    //         colorField: 'status',
    //         innerRadius: 0.6,
    //         radius: 0.85,
    //         legend: false,
    //         color: resultArray.map(p => {
    //             return returnTextColorByPaymentStatus(p)
    //         }),
    //         tooltip: {
    //             formatter: (data) => {
    //                 return { name: paymentByStatus(data.status), value: `${returnCurrencyValue(data.value)}` };
    //             },
    //         },
    //         statistic: {
    //             title: {
    //                 offsetY: 4,
    //                 style: {
    //                     fontSize: '13px',
    //                 },
    //                 customHtml: () => {
    //                     return `<div class="d-flex flex-column">
    //                     <p style="font-size:13px; padding:0px 10px; margin-bottom:10px;">Pagos</p>
    //                     </div>`
    //                 },
    //             },

    //             content: {
    //                 offsetY: 4,
    //                 style: {
    //                     fontSize: '32px',
    //                 },
    //                 customHtml: (container, view, datum, data) => {
    //                     const total = resultArray.map((a) => a.value).reduce((a, b) => a + b, 0);
    //                     return `<p style="color:#333; font-size:${returnCurrencyValue(total).toString().length > 14 ? '9px' : '12px'};">${returnCurrencyValue(total)}</p>`
    //                 },
    //             },
    //         },
    //         label: {
    //             type: 'spider',
    //             labelHeight: 40,
    //             formatter: (data, mappingData) => {

    //                 const group = new G.Group({});
    //                 group.addShape({
    //                     type: 'circle',
    //                     attrs: {
    //                         x: 0,
    //                         y: 0,
    //                         width: 40,
    //                         height: 50,
    //                         r: 5,
    //                         fill: mappingData.color,
    //                     },
    //                 });
    //                 group.addShape({
    //                     type: 'text',
    //                     attrs: {
    //                         x: 10,
    //                         y: 8,
    //                         text: `${paymentIntentsStatusMessage(data)}`,
    //                         fill: '#333',//mappingData.color,
    //                     },
    //                 });
    //                 group.addShape({
    //                     type: 'text',
    //                     attrs: {
    //                         x: 0,
    //                         y: 25,
    //                         text: `${(Number(data?.percent ?? 0) * 100).toFixed(2)}%`,
    //                         fill: '#333',
    //                         fontWeight: 700,

    //                     },
    //                 });
    //                 return group;
    //             },
    //         },
    //         interactions: [
    //             {
    //                 type: 'element-selected',
    //             },
    //             {
    //                 type: 'element-active',
    //             },
    //         ],
    //     };

    //     return (
    //         <div style={{ height: '250px', width: '100%' }}>
    //             <Pie {...config} />
    //         </div>
    //     )
    // }

    // const ValuableInfoCard = ({ description, value, customChild }) => {
    //     return <Col xs={24} lg={12}>
    //         <div style={{ padding: '20px', backgroundColor: '#F4F7FA', borderRadius: '10px', marginTop: '5px', }} className="d-flex flex-column">
    //             <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '5px' }}>{description}</Typography.Text>
    //             {customChild ? customChild : <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>{value}</Typography.Title>}
    //         </div>
    //     </Col>
    // }

    const ValuableInfoCards = () => {

        // const payments = data.filter(i => i.category === 'payment' && i.status === 'succeeded' && i.livemode === true && i.year?.includes(year)).map(i => i.value)
        const payments = data.filter(i => i.category === 'payment' && i.status === 'succeeded' && i.livemode === true)
        const paymentsThisPeriod = payments.filter(i => i.timestamp >= period && i.timestamp <= endOfPeriod).map(i => i.value)
        const paymentsLastPeriod = payments.filter(i => i.timestamp >= lastPeriod && i.timestamp <= endOfLastPeriod).map(i => i.value)
        const paymentTotal = paymentsThisPeriod.reduce((a, b) => a + b, 0)
        const paymentTotalLastPeriod = paymentsLastPeriod.reduce((a, b) => a + b, 0)
        const paymentIncrement = paymentTotalLastPeriod === 0 ? 100 : ((paymentTotal - paymentTotalLastPeriod) / paymentTotalLastPeriod) * 100
        // const paymentsOnHold = data.filter(i => i.category === 'payment' && i.livemode === true && i.year?.includes(year) && i.status !== 'succeeded' && i.status !== 'review_requested' && i.status !== 'canceled').map(i => i.value)

        // const paymentTotal = payments.reduce((a, b) => a + b, 0)

        // const paymentsOnHoldTotal = paymentsOnHold.reduce((a, b) => a + b, 0)
        const invoices = data.filter(i => i.category === 'invoice' && i.livemode === true && i.status === 'valid')
        const invoicesThisPeriod = invoices?.filter(i => i.timestamp >= period && i.timestamp <= endOfPeriod).map(i => i.value)
        const invoicesLastPeriod = invoices?.filter(i => i.timestamp >= lastPeriod && i.timestamp <= endOfLastPeriod).map(i => i.value)
        const invoiceTotal = invoicesThisPeriod.reduce((a, b) => a + b, 0)
        const invoiceTotalLastPeriod = invoicesLastPeriod.reduce((a, b) => a + b, 0)
        const invoiceIncrement = invoiceTotalLastPeriod === 0 ? 100 : ((invoiceTotal - invoiceTotalLastPeriod) / invoiceTotalLastPeriod) * 100

        const receipts = data.filter(r => r.category === 'receipt' && r.livemode === true && r.status === 'pending')
        const receiptsThisPeriod = receipts.filter(i => i.timestamp >= period && i.timestamp <= endOfPeriod).map(i => i.value)
        const receiptsLastPeriod = receipts.filter(i => i.timestamp >= lastPeriod && i.timestamp <= endOfLastPeriod).map(i => i.value)
        const receiptTotal = receiptsThisPeriod.length
        const receiptTotalLastPeriod = receiptsLastPeriod.length
        const receiptIncrement = receiptTotalLastPeriod === 0 ? 100 : ((receiptTotal - receiptTotalLastPeriod) / receiptTotalLastPeriod) * 100

        const globalInvoices = data.filter(i => i.category === 'invoice' && i.livemode === true && i.status === 'valid' && i.isGlobal).reduce((a, b) => a + b.value, 0)

        return (
            <Col xs={24} xl={18}>
                <Typography.Title level={4} > Resumen </Typography.Title>
                <Row gutter={10}>
                    <Col xs={24} xl={8}>
                        <StatisticCard amount={paymentTotal} description="Total Vendido" isPositive={paymentIncrement > 0} increment={paymentIncrement.toFixed(2)} extraElement="Pagos recibidos durante el periodo" />
                    </Col>
                    <Col xs={24} xl={8}>
                        <StatisticCard amount={invoiceTotal} description="Total facturado" isPositive={invoiceIncrement > 0} increment={invoiceIncrement.toFixed(2)} extraElement={`Facturas globales por: ${returnCurrencyValue(globalInvoices)}`} />
                    </Col>
                    <Col xs={24} xl={8}>
                        <StatisticCard amount={receiptTotal} description="Recibos abiertos" isPositive={receiptIncrement > 0} increment={receiptIncrement.toFixed(2)} extraElement="Se emitirá una factura global a final del periodo" type='number' />
                    </Col>
                </Row>
            </Col>
        )
    }

    const IntegrationsSectionsCards = () => {

        return <Col xl={24} >
            <IntegrationStatistSection collapsed={integrationsSections[0].collapsed} onClick={() => setIntegrationsSections(integrationsSections.map(i => {
                if (i.key === 'stripe') return ({ ...i, collapsed: !i.collapsed })
                return i
            }))} integration={integrationsSections[0]} logo={integrationsSections[0].image} active={integrationsSections[0].completed} paymentsTotal={stripePaymentsTotal} invoicesTotal={stripeInvoicesTotal} receiptsTotal={stripeReceiptsTotal} />

            <IntegrationStatistSection collapsed={integrationsSections[1].collapsed} onClick={() => setIntegrationsSections(integrationsSections.map(i => {
                if (i.key === 'bank') return ({ ...i, collapsed: !i.collapsed })
                return i
            }))} integration={integrationsSections[1]} logo={integrationsSections[1].image} active={integrationsSections[1].completed} paymentsTotal={speiPaymentsTotal} invoicesTotal={speiInvoicesTotal} receiptsTotal={speiReceiptsTotal} />

            <IntegrationStatistSection collapsed={integrationsSections[2].collapsed} onClick={() => setIntegrationsSections(integrationsSections.map(i => {
                if (i.key === 'integrations') return ({ ...i, collapsed: !i.collapsed })
                return i
            }))} integration={integrationsSections[2]} logo={integrationsSections[2].image} active={integrationsSections[2].completed} paymentsTotal={otherProcessorsTotal} invoicesTotal={otherProcessorsInvoicesTotal} receiptsTotal={otherProcessorsReceiptsTotal} />
        </Col >
    }

    const PaymentsByPeriodGraph = () => {

        let paymentsData = data.filter(i => i.category === 'payment' && i.livemode === true && i.year?.includes(year)).map((a) => {
            return {
                month: Number(a.year.split('-')[1]),
                ...a
            }
        })

        paymentsData = paymentsData.sort((a, b) => {
            return Number(a.year.split('-')[0] + a.year.split('-')[1]) - Number(b.year.split('-')[0] + b.year.split('-')[1])
        })

        const config = {
            data: paymentsData,
            isStack: true,
            xField: 'year',
            yField: 'value',
            seriesField: 'status',
            legend: {
                position: 'top',
                itemName: {
                    style: {
                        fill: '#000',
                    },

                    formatter: (seriesField) => {
                        return paymentIntentsStatusMessage({ status: seriesField });
                    }
                }
            },
            tooltip: {
                formatter: (data) => {
                    return { name: paymentByStatus(data.status), value: `${returnCurrencyValue(data.value)}` };
                },
            },

            label: {
                position: 'middle',
                color: paymentsData.map(p => {

                    return returnTextColorByPaymentStatus(p)
                }),
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
                formatter: (v) => {
                    return returnCurrencyValue(v.value)
                }
            },
        };

        return <Column {...config} />
    }




    const PaymentsAndInvoicesGraph = () => {

        let paymentsAndInvoices = data.filter(pi => pi.livemode === true && (pi.status === 'succeeded' || pi.status === 'valid' || pi.status === 'pending' || pi.status === 'requires_payment_method' || pi.status === 'open') && pi.year?.includes(year))

        paymentsAndInvoices = paymentsAndInvoices.sort((a, b) => Number(a.year.split('-')[0] + a.year.split('-')[1]) - Number(b.year.split('-')[0] + b.year.split('-')[1]))

        paymentsAndInvoices = paymentsAndInvoices.map((a) => {
            let newStatus = null
            if (a.status === 'valid') {
                newStatus = 'invoices'
            } else if (a.status === 'succeeded') {
                newStatus = 'payments_paid'
            } else {
                newStatus = 'payments_pending'
            }
            return {
                ...a,
                newStatus
            }
        })

        const config = {
            data: paymentsAndInvoices,
            isGroup: true,
            xField: 'year',
            yField: 'value',
            seriesField: 'newStatus',
            legend: {
                position: 'top',
                itemName: {
                    style: {
                        fill: '#000',
                    },

                    formatter: (seriesField) => {
                        if (seriesField === 'invoices') return 'Facturas'
                        if (seriesField === 'payments_paid') return 'Pagos completos'
                        return 'Pendientes de pago'
                    }
                }

            },

            tooltip: {
                formatter: (data) => {
                    return { name: data.newStatus === 'invoices' ? 'Facturas' : data.newStatus === 'payments_paid' ? 'Pagos completos' : 'Pagos pendientes', value: `${returnCurrencyValue(data.value)}` };
                },
            },
            statistic: {
                title: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                    customHtml: () => {
                        return `<div class="d-flex flex-column">
                        <p style="font-size:20px; padding:0px 10px; margin-bottom:10px;">Pagos</p>
                        </div>`
                    },
                },

                content: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                    customHtml: (container, view, datum, data) => {
                        return `<p style="color:#8666FF; font-size:15px;">abc</p>`
                    },
                },
            },

            label: {
                formatter: (v) => {
                    return returnCurrencyValue(v.value)
                }
            }
        };
        return <Column {...config} />;
    }

    // const Onboarding = () => {
    //     return <>
    //         {testInvoiceModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <TestInvoiceModal open={testInvoiceModalOpen} close={() => {
    //                 handleCloseIntegrationModal('testInvoice')
    //                 handleLoadingCards('testInvoice', false)
    //             }} />
    //         </Suspense>}
    //         {automaticInvoicingModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <StripeModal open={automaticInvoicingModalOpen} close={() => {
    //                 handleCloseIntegrationModal('automaticInvoicing')
    //                 handleLoadingCards('automaticInvoicing', false)
    //             }} />
    //         </Suspense>}
    //         {importPaymentsModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <PaymentsListModal open={importPaymentsModalOpen} close={() => {
    //                 handleCloseIntegrationModal('importPayments')
    //                 handleLoadingCards('importPayments', false)
    //             }} userDocument={userDocument} importFromStripe={true} />
    //         </Suspense>}
    //         {importProductsModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <ServiceListModal open={importProductsModalOpen} close={() => {
    //                 handleCloseIntegrationModal('importProducts')
    //                 handleLoadingCards('importStripeProducts', false)
    //             }} userDocument={userDocument} importFromStripe={true} />
    //         </Suspense>}
    //         {satModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <FacturapiModal edit={false} open={satModalOpen} close={() => {
    //                 handleCloseIntegrationModal('sat')
    //                 handleLoadingCards('invoices', false)
    //             }} userDocument={userDocument} />
    //         </Suspense>}
    //         {stripeModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <StripeModal edit={false} open={stripeModalOpen} close={() => {
    //                 handleCloseIntegrationModal('stripe')
    //                 handleLoadingCards('stripeSetup', false)
    //             }} userDocument={userDocument} />
    //         </Suspense>}
    //         {bankAccountModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <BankModal open={bankAccountModalOpen} close={() => {
    //                 handleCloseIntegrationModal('bankAccount')
    //                 handleLoadingCards('bank')
    //             }} userDocument={userDocument} />
    //         </Suspense>}
    //         {defaultsModalOpen && <Suspense fallback={<LoadingOutlined />}>
    //             <DefaultsConfigDrawer open={defaultsModalOpen} close={() => {
    //                 handleCloseIntegrationModal('defaults')
    //                 handleLoadingCards('setDefaults')
    //             }} userDocument={userDocument} />
    //         </Suspense>}
    //         {/* {setupIntegrationsCompleted ? null : <LayoutSetupIntegrations changeRender={(n) => changeRender(n)} userDocument={userDocument} onCloseSection={() => setSetupIntegrationsCompleted(true)} />} */}
    //     </>
    // }


    const StatisticCard = ({ amount, description, isPositive, increment, extraElement, type = 'currency' }) => {
        return <div style={{ borderRadius: '10px', backgroundColor: 'white', padding: '20px 30px' }}>
            <div style={{}}>
                <Typography.Title level={2} style={{ padding: 0, margin: '6px 0px' }}>{type === 'number' ? amount : returnCurrencyValue(amount)} <span style={{ fontSize: '16px', fontWeight: 600, padding: 0, margin: 0 }}>{type === 'number' ? 'Recibos' : 'MXN'}</span></Typography.Title>
                <Typography.Text style={{ padding: 0, margin: '3px 0px', fontSize: '15px' }}>{description}</Typography.Text>
                <Row>
                    <Typography.Text type='secondary' style={{ fontSize: '12px', margin: '6px 0px' }}>
                        {extraElement}
                    </Typography.Text>
                </Row>
                <Row justify='start' align='middle'>
                    {isPositive ? <AiOutlineArrowUp style={{ color: returnColorOnStatist(isPositive ? 'active' : 'error'), fontSize: '16px' }} /> : <AiOutlineArrowDown style={{ color: returnColorOnStatist(isPositive ? 'active' : 'error'), fontSize: '16px' }} />}
                    <Typography.Text style={{ color: returnColorOnStatist(isPositive ? 'active' : 'error'), fontSize: '16px' }} type="secondary">{(increment)}%</Typography.Text>
                    <Typography.Text type="secondary" style={{ marginLeft: '8px', fontSize: '11px' }}>vs el periodo anterior</Typography.Text>
                </Row>
            </div>
        </div>
    }

    const DemoArea = ({ rawData = [], type, integration }) => {
        // const [data, setData] = useState(data);

        // timePeriod: '2021-01',
        // value: 3.3,

        let dataByIntegration = [...rawData]

        if (integration.key === 'stripe' && type === 'payment') {
            dataByIntegration = stripePayments
        } else if (integration.key === 'stripe' && type === 'invoice') {
            dataByIntegration = stripeInvoices
        } else if (integration.key === 'stripe' && type === 'receipt') {
            dataByIntegration = stripeReceipts
        } else if (integration.key === 'bank' && type === 'payment') {
            dataByIntegration = speiPayments
        } else if (integration.key === 'bank' && type === 'invoice') {
            dataByIntegration = speiInvoices
        } else if (integration.key === 'bank' && type === 'receipt') {
            dataByIntegration = speiReceipts
        } else if (integration.key === 'integrations' && type === 'payment') {
            dataByIntegration = otherProcessors
        } else if (integration.key === 'integrations' && type === 'invoice') {
            dataByIntegration = otherProcessorsInvoices
        } else if (integration.key === 'integrations' && type === 'receipt') {
            dataByIntegration = otherProcessorsReceipts
        }

        const dataToShow = dataByIntegration.filter(i => i.category === type && i.timestamp < endOfPeriod && i.timestamp > period).sort((a, b) => a.timestamp - b.timestamp).map(i => {
            return {
                timePeriod: moment(i.timestamp).format(periodText === 'Este mes' ? 'DD' : periodText === 'Desde el inicio' ? 'YY-MM' : 'MMM'),
                value: i.value
            }
        }).reduce((acc, curr) => {
            if (acc.find(i => i.timePeriod === curr.timePeriod)) {
                acc.find(i => i.timePeriod === curr.timePeriod).value += curr.value
            } else {
                acc.push(curr)
            }
            return acc
        }, [])

        const config = {
            data: dataToShow,
            xField: 'timePeriod',
            yField: 'value',
            xAxis: {
                range: [0, 1],
            },
            yAxis: {
                range: [0, 1],
            },
        };

        return <Area {...config} />;
    };

    const returnGreetings = () => {
        const hour = moment().hour()
        if (hour >= 0 && hour < 12) {
            return 'Buenos días'
        } else if (hour >= 12 && hour < 18) {
            return 'Buenas tardes'
        } else {
            return 'Buenas noches'
        }
    }

    const handlePeriod = (value) => {
        switch (value) {
            case 'month':
                setPeriod(moment().startOf('month'))
                setEndOfPeriod(moment().endOf('month'))
                setEndOfLastPeriod(moment().add(-1, 'months').endOf('month'))
                setLastPeriod(moment().add(-1, 'months').startOf('month'))
                break;
            case '3months':
                setPeriod(moment().add(-3, 'months').startOf('month'))
                setEndOfPeriod(moment().endOf('month'))
                setEndOfLastPeriod(moment().add(-4, 'months').endOf('month'))
                setLastPeriod(moment().add(-6, 'months').startOf('month'))
                break;
            case '6months':
                setPeriod(moment().add(-6, 'months').startOf('month'))
                setEndOfPeriod(moment().endOf('month'))
                setEndOfLastPeriod(moment().add(-7, 'months').endOf('month'))
                setLastPeriod(moment().add(-12, 'months').startOf('month'))
                break;
            case 'year':
                setPeriod(moment().startOf('year'))
                setEndOfPeriod(moment().endOf('month'))
                setEndOfLastPeriod(moment().add(-1, 'years').endOf('year'))
                setLastPeriod(moment().add(-1, 'years').startOf('year'))
                break;
            case 'lastyear':
                setPeriod(moment().add(-1, 'years').startOf('year'))
                setEndOfPeriod(moment().add(-1, 'years').endOf('year'))
                setEndOfLastPeriod(moment().add(-2, 'years').endOf('year'))
                setLastPeriod(moment().add(-2, 'years').startOf('year'))
                break;
            case 'all':
                setPeriod(moment().add(-20, 'years').startOf('year'))
                setEndOfPeriod(moment().endOf('month'))
                setEndOfLastPeriod(moment().add(-20, 'years').endOf('year'))
                setLastPeriod(moment().add(-20, 'years').startOf('year'))
                break;
            default:
                setPeriod(moment().startOf('month'))
                setEndOfPeriod(moment().endOf('month'))
                setEndOfLastPeriod(moment().add(-1, 'months').endOf('month'))
                setLastPeriod(moment().add(-1, 'months').startOf('month'))
        }
    }

    const GraphIntegrationSection = ({ title, total, integration, type }) => {
        return <div style={{ backgroundColor: '#FFFF', borderRadius: '10px', padding: '10px 15px' }}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Typography.Text>{title}</Typography.Text>
                <Typography.Title level={3} style={{ padding: 0, margin: 0 }}>{type === 'receipt' ? total : returnCurrencyValue(total)} <span style={{ fontSize: '16px', fontWeight: 600, padding: 0, margin: 0 }}> {type === 'receipt' ? 'Recibos' : 'MXN'}</span></Typography.Title>
                <div style={{ width: '100%' }}>
                    <DemoArea rawData={data} type={type} integration={integration} />
                </div>
            </Space>
        </div>
    }

    const IntegrationStatistSection = ({ onClick, collapsed, integration, logo, active, paymentsTotal = 0, invoicesTotal = 0, receiptsTotal = 0 }) => {
        return <div style={{ backgroundColor: '#FFFF', padding: '15px 25px', borderRadius: '10px', margin: '25px 0px' }} >
            <Row justify="space-between" align='middle' onClick={() => onClick()} className='clickable'>
                <Row align='middle' style={{ height: '40px' }}>
                    {typeof integration.image === 'object' ? integration.image.map(i => <img src={i} alt={integration?.name} height={integration.imageHeight} style={{ marginRight: '20px' }} />) : <img src={logo} alt={integration?.name} height={integration.imageHeight} style={{ marginRight: '10px' }} />}
                    {integration.showCompleted ? <Tag stye={{ height: '10px', marginLeft: '15px' }} color={returnColorOnStatist(active ? 'activeLight' : 'inactiveLight')}>
                        <Typography.Text style={{ color: returnColorOnStatist(active ? 'active' : 'inactive') }}>{active ? 'Activado' : 'Desactivado'}</Typography.Text>
                    </Tag> : null}
                </Row>
                {collapsed ? (
                    <motion.div
                        initial={{ opacity: 1, rotate: -90 }}
                        animate={{ opacity: 1, y: 0, rotate: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <DownOutlined />
                    </motion.div>) : (<motion.div
                        initial={{ opacity: 1, rotate: 90 }}
                        animate={{ opacity: 1, y: 0, rotate: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <RightOutlined />
                    </motion.div>)}
            </Row>
            {collapsed ? <div>
                <Divider style={{ margin: '5px 0px 0px 0px' }} />
                <div style={{ backgroundColor: '#F8F8F8', borderRadius: '10px', padding: '10px', margin: '20px 0px' }}>
                    <Row gutter={10}>
                        <Col xl={8}>
                            <GraphIntegrationSection title={`Total vendido (${periodText})`} total={paymentsTotal} integration={integration} type='payment' />
                        </Col>
                        <Col xl={8}>
                            <GraphIntegrationSection title={`Total facturado (${periodText})`} total={invoicesTotal} integration={integration} type='invoice' />
                        </Col>
                        <Col xl={8}>
                            <GraphIntegrationSection title={`Total recibos abiertos (${periodText})`} total={receiptsTotal} integration={integration} type='receipt' />
                        </Col>
                    </Row>
                </div>
            </div> : null}
        </div>
    }

    const periodItems = [
        { value: 'month', label: 'Este mes' },
        { value: '3months', label: 'Últimos 3 meses' },
        { value: '6months', label: 'Últimos 6 meses' },
        { value: 'year', label: 'Este año' },
        { value: 'lastyear', label: 'El año pasado' },
        { value: 'all', label: 'Desde el inicio' }
    ]

    const handleSelectPeriod = (e) => {
        handlePeriod(e.item.props.value)
        setPeriodText(periodItems.find(i => i.value === e.item.props.value).label)
    }

    return (<Layout>
        <Col xs={24} style={{ marginBottom: '20px' }}>
            <Row justify="space-between" align="middle">
                <div style={{ borderBottom: '1px solid #fafbfd', width: '100%' }} className="d-flex flex-column">
                    <Row justify='center'>
                        <Typography.Title onClick={async () => console.log(await auth.currentUser.getIdToken())} style={{ margin: 0, padding: 0 }} level={5}>{moment().format('dddd').charAt(0).toUpperCase() + moment().format('dddd').slice(1) + ', ' + moment().format('D MMMM')}</Typography.Title>
                    </Row>
                    <Row justify="center">
                        <Typography.Title style={{ margin: 0, padding: 0 }} level={2}>¡{returnGreetings()}, {userDocument?.firstName ?? ''}! </Typography.Title>
                    </Row>
                    <Row justify="space-between" style={{ marginTop: '10px' }} align='middle'>
                        <div style={{ width: '33%' }}></div>
                        <div style={{ width: '33%' }} className='d-flex'>
                            <div style={{ backgroundColor: 'white', borderRadius: '25px', display: 'flex', flexDirection: 'row', padding: '14px', width: 'fit-content', margin: 'auto' }}>
                                <Dropdown overlay={<Menu
                                    onClick={handleSelectPeriod}
                                    items={periodItems}
                                />} >
                                    <Space style={{ display: 'flex' }}>
                                        {periodText}
                                        <DownOutlined style={{ fontSize: '12px', marginBottom: '5px' }} />
                                    </Space>
                                </Dropdown>
                                <span style={{ borderRight: '1px solid #212121', margin: '0px 15px' }}></span>
                                <Typography.Text style={{ margin: 'auto', fontSize: '12px', color: '#333' }}>🚀 <span style={{ fontSize: '16px', fontWeight: 600 }}>{totalAutomations}</span> automatizaciones</Typography.Text>
                            </div>
                        </div>
                        <div className='d-flex' style={{ width: '33%' }}>
                            <Button icon={<AppstoreAddOutlined style={{ margin: 'auto' }} />} style={{ display: 'flex', marginLeft: 'auto' }} onClick={() => {
                                setAppsOpen(true)
                                getCurrentTeam(team.id)
                            }}>
                                Aplicaciones
                            </Button>
                            {/* <Button shape='circle' style={{ display: 'flex', marginLeft: '10px', borderRadius: '50%' }} onClick={() => {
                                setAppsOpen(true)
                                getCurrentTeam(team.id)
                            }} >
                                <AppstoreOutlined style={{ margin: 'auto' }} />
                            </Button> */}
                        </div>
                    </Row>
                </div>
            </Row>
        </Col>

        {/* <Onboarding /> */}

        {appsOpen && <DrawerApps open={appsOpen} close={() => setAppsOpen(false)} />}

        {showSavingsGraphModal && <Suspense fallback={<LoadingOutlined />}>
            <SavingsGraphModal imageUrl={savingsImageUrl} open={showSavingsGraphModal} close={() => {
                setshowSavingsGraphModal(false)
            }} userDocument={userDocument} />
        </Suspense>}

        {!isPaidUser ? <Layout.Content style={{ paddingBottom: '0px', height: '80vh' }}>
            <div style={{ height: '90%', width: '100%', backgroundImage: `url(${defaultFinances})`, backgroundSize: '70%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', alignItems: 'center', justifyContent: 'center' }} className="d-flex flex-column">
                <BlockedModal description="Obtén todos los beneficios exclusivos de gigstack.pro mejorando tu plan." />
            </div>
        </Layout.Content> : <Layout.Content style={{ paddingBottom: '100px' }}>
            {/* <Row justify='end' >
                <Select
                    onChange={handlePeriod}
                    suffixIcon={<CalendarOutlined />}
                    style={{ width: '150px' }}
                    defaultValue="month"
                    options={[
                        { value: 'month', label: 'Este mes', icon: <CalendarOutlined /> },
                        { value: '3months', label: 'Últimos 3 meses' },
                        { value: '6months', label: 'Últimos 6 meses' },
                        { value: 'year', label: 'Este año' },
                        { value: 'lastyear', label: 'El año pasado' },
                        { value: 'all', label: 'Desde el inicio' }
                    ]}
                />
                <Button shape='circle' style={{ display: 'flex', marginLeft: '10px', borderRadius: '50%' }} onClick={() => {
                    setAppsOpen(true)
                    getCurrentTeam(team.id)
                }} >
                    <AppstoreOutlined style={{ margin: 'auto' }} />
                </Button>
            </Row> */}
            {loading ? <Col xs={24} style={{ marginTop: '55px' }}>
                <Row justify='center'>
                    <LoadingOutlined style={{ fontSize: '25px' }} />
                </Row>
                <Row justify='center' style={{ marginTop: '20px' }}>
                    <Typography.Text type="secondary" style={{ fontSize: '12px' }}>Cargando la información de tu cuenta</Typography.Text>
                </Row> </Col> : <Row justify="center">

                <ValuableInfoCards />

                <IntegrationsSectionsCards />

                {/* <Col xs={24} style={{ marginTop: '25px' }}>


                    <Row justify="end">
                        <Space>
                            <Button type={year === (moment().add(-2, 'years').format('YY')) ? "primary" : "ghost"} onClick={() => {

                                setyear(moment().add(-2, 'years').format('YY'))
                            }}>{moment().year() - 2}</Button>
                            <Button type={year === (moment().add(-1, 'years').format('YY')) ? "primary" : "ghost"} onClick={() => {

                                setyear(moment().add(-1, 'years').format('YY'))
                            }}>{moment().year() - 1}</Button>
                            <Button type={year === moment().add(0, 'years').format('YY') ? "primary" : "ghost"} onClick={() => {

                                setyear(moment().add(0, 'years').format('YY'))
                            }}>{moment().year()}</Button>
                            <Button type={year === '' ? "primary" : "ghost"} onClick={() => {

                                setyear('')
                            }}>General</Button>
                        </Space>
                    </Row>
                </Col>



                {loading ? <Col xs={24} style={{ marginTop: '55px' }}>
                    <Row justify='center'>
                        <LoadingOutlined style={{ fontSize: '25px' }} />
                    </Row>
                    <Row justify='center' style={{ marginTop: '20px' }}>
                        <Typography.Text type="secondary" style={{ fontSize: '12px' }}>Cargando la información de tu cuenta</Typography.Text>
                    </Row>

                </Col> :

                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Row gutter={{ xs: 12, md: 15 }} style={{ margin: '20px 0px', borderRadius: '10px', padding: '25px 10px', backgroundColor: 'white' }} align="middle">
                                    <Col xs={24} lg={12}>
                                        <PaymentsPieGraphByStatus />
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <ValuableInfoCards />
                                    </Col>
                                </Row>
                            </Col>

                           

                        </Row>
                    </Col>}
                {loading && <></>} */}
                <Col xs={24}>
                    <Row gutter={{ xs: 12, md: 15 }} style={{ marginTop: '25px' }}>
                        <Col xs={12} style={{ margin: 0 }}>
                            <div style={{ backgroundColor: 'white', padding: '20px 25px', borderRadius: '10px' }}>
                                <PaymentsByPeriodGraph />
                            </div>

                        </Col>
                        <Col xs={12} >
                            <div style={{ backgroundColor: 'white', padding: '20px 25px', borderRadius: '10px' }}>
                                <PaymentsAndInvoicesGraph />

                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>}

        </Layout.Content>}
    </Layout>);

}

export default InsigthsPage;
