import { Col, Row, Table } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

import React, { Suspense } from 'react';
import { useFirestoreCollectionData } from 'reactfire';

import EmptyTable from '../states/EmptyTable';

function DefaultSimpleTable({ onRow, avoidContainer, ignoreQuery = false, userDocument, updateParent, data: forcedData, columns, customFilter, loading = false, empty = "Aún no tienes clientes", emptyAction, elementName, onPageChange = () => { }, defaultPage = 1, onChange = () => { }, query, filterSearch = "", pageSize = 10, filterSearchKey, importFromStripe = false, from = '', lastRowMaxHeigth, byTestMode = true }) {

    let filteredData = []
    const { status: dataStatus, data } = useFirestoreCollectionData(query, {
        //ADD DOCUMENT ID
        idField: 'id',
    })


    if (forcedData && (ignoreQuery || forcedData?.length > 0)) {
        filteredData = forcedData.filter((a) => {
            return a[filterSearchKey]?.toString()?.toLowerCase().indexOf(filterSearch.toLocaleLowerCase()) !== -1
        })
    } else {
        filteredData = data.filter((a) => {
            return a[filterSearchKey]?.toString()?.toLowerCase().indexOf(filterSearch.toLocaleLowerCase()) !== -1
        })
    }

    if (importFromStripe) {
        filteredData = forcedData?.filter((a) => a.name !== null)?.filter((a) => {
            return a[filterSearchKey]?.toString()?.toLowerCase().indexOf(filterSearch.toLocaleLowerCase()) !== -1
        })

    }

    if (!byTestMode) {
        filteredData = filteredData?.filter((a) => a.livemode !== undefined ? a.livemode : true)
    }

    return (
        <Row style={{ padding: 0, margin: 0, width: '100%' }}>
            <Col xs={24}>
                <div className={avoidContainer ? '' : 'tableContainer'}>
                    <Row style={{ width: '100%' }}>

                        <Col xs={24} style={{ marginTop: customFilter ? '25px' : '0px' }}>
                            <ErrorBoundary>
                                <Suspense>
                                    <Table
                                        rowClassName={(record, index) => {
                                            if (index === filteredData.length - 1 && (importFromStripe || lastRowMaxHeigth)) {
                                                if (from === 'services') {
                                                    return 'lastTableRow'
                                                }
                                                if (lastRowMaxHeigth) {
                                                    return 'lastTableRowMax'
                                                }
                                            }
                                        }}
                                        onChange={onChange}
                                        onRow={onRow ? onRow : (record, rowIndex) => { }}
                                        rowKey={record => record.id}
                                        // rowSelection={importFromStripe ? {
                                        //     selections: [Table.SELECTION_ALL]
                                        // } : null}
                                        loading={loading || dataStatus === 'loading'}
                                        scroll={{ x: 500 }}
                                        columns={columns}
                                        dataSource={filteredData}
                                        bordered={false}
                                        showSorterTooltip={false}
                                        pagination={{
                                            pageSize: pageSize ?? 10,
                                            hideOnSinglePage: true,
                                            onChange: (p) => onPageChange(p),
                                            pageSizeOptions: [10, 20, 50, 100],
                                            defaultCurrent: defaultPage,
                                            defaultPageSize: pageSize ?? 10
                                        }}
                                        className='pro-table'

                                        locale={{
                                            emptyText: <EmptyTable customMessage={empty} parentAction={importFromStripe ? null : emptyAction} elementName={elementName} />,
                                            filterCheckall: 'Todos',
                                        }}


                                    />
                                </Suspense>
                            </ErrorBoundary>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default DefaultSimpleTable;