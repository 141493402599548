import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocDataOnce } from "reactfire";


/**
 * `useMembershipOnce` returns a `membership` object that contains the `credits` and `membership`
 * properties one time from the database.
 */
export const useMembershipOnce = ({ uid, billingAccountId }) => {



    let ref
    if (billingAccountId) {
        ref = doc(getFirestore(), "billingAccounts", billingAccountId ?? '-');
    } else {
        ref = doc(getFirestore(), "memberships", uid ?? '-');
    }

    // const ref = doc(getFirestore(), "memberships", uid ?? '-');

    const {
        status,
        data: membership,
    } = useFirestoreDocDataOnce(ref);



    const loaded = status === "success";
    const credits = (membership?.credits ?? 0);
    const isPaidUser = ((membership?.membership ?? 0) > 0 && !membership?.payAsYouGo);

    return loaded === 'error' ? {} : !uid ? { membership: {} } : {
        isPaidUser,
        credits,
        loaded,
        membership,
        payAsYouGo: membership?.payAsYouGo,
    }


}