import { CheckCircleOutlined, CloseOutlined, DoubleLeftOutlined, ExclamationOutlined, EyeOutlined, LoadingOutlined, QuestionOutlined, StopOutlined } from "@ant-design/icons";
import { message, notification } from "antd";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { SignedInternalAPIRequest } from "./APIRequests";
import { saveAs } from 'file-saver';
import { useStorageTask } from "reactfire";
import moment from "moment";


var formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
});


/**
 * It takes a number and returns a string with the number formatted as currency
 * @param value - The value to be formatted.
 * @returns the value of the variable formatter.format(value).
 */
export function returnCurrencyValue(value) {
    return formatter.format(value);
}



/**
 * It takes a query string parameter (q) and returns the value of that parameter from the URL
 * @param q - The query parameter we want to get from the URL
 * @returns A function that takes a string as an argument and returns the value of the query string
 * parameter with the same name as the argument.
 */
export const SearchParamInURL = (q) => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    return params.get(q)
}
/**
 * It generates a random string of characters of a specified length
 * @param length - The length of the code you want to generate.
 * @returns A string of random characters.
 */

export function generateCode(length, prefix = '') {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return `${prefix}${prefix ? '_' : ''}${result}`;
}


/**
 * It takes a string, removes all non-numeric characters, and returns a number
 * @param value - The value to be formatted.
 * @returns A function that takes a value and returns a number.
 */
export function ForceDecimalNumber(value) {
    const v = value.replace(/[^\d.-]/g, '')
    return Number(v)
}

/**
 * If the payment status is 'succeeded' and the succeededTimestamp is null, then set the
 * succeededTimestamp to the created timestamp of the first charge
 * @param payment - The payment object that you want to process.
 * @returns A function that takes a payment object and returns a processed payment object.
 */
export const ReturnCorrectedPayment = (payment) => {
    var p = payment
    var processed = { ...p };

    if (p.status === 'succeeded' && !p.succeededTimestamp) {
        if (p.charges?.data && p.charges?.data?.length > 0) {
            var charge = p.charges.data[0];
            processed.succeededTimestamp = charge?.created * 1000;
        } else {
            if (p.webhooks_delivered_at) {
                processed.succeededTimestamp = p.webhooks_delivered_at * 1000;
            } else {
                processed.succeededTimestamp = p.timestamp.toString().length <= 10 ? (p.succeededTimestamp * 1000) : p.timestamp;

            }
        }
    } else {
        if (p.status === 'succeeded' || p.status === 'paid') {

            processed.succeededTimestamp = p.succeededTimestamp.toString().length <= 11 ? (p.succeededTimestamp * 1000) : p.succeededTimestamp

        }
    }




    return processed;

}

/**
 * It takes an array of items, and returns an object with the total, taxes, retentions, feeAdded,
 * canSend, feeAddedString, totalWithoutFeeString, totalWithoutFee, totalString, subtotalString,
 * taxesString, retentionsString, masterDiscount, taxesIncluded, masterDiscountString
 * @param [items] - An array of objects that contain the following properties:
 * @param [useDiscount=false] - If you want to use the discount in the calculation, set this to true.
 * @returns An object with the following properties:
 * total: Number(parseFloat(((subtotal + taxes) - taxesIncluded) - retentions, subtotal).toFixed(2)) -
 * masterDiscount,
 * taxes: taxes + taxesIncluded,
 * retentions,
 * feeAdded,
 * canSend,
 * feeAddedString: formatter.format(feeAdded),
 */
export const getItemsAmounts = (items = [], useDiscount = false) => {
    var subtotal = 0;
    var taxes = 0;
    var taxesIncluded = 0;
    var retentions = 0;
    var canSend = true;
    var feeAdded = 0;
    var masterDiscount = 0;
    // var haveIVA = false;

    items.forEach((s) => {
        var times = 1;
        var serviceTotal = 0;
        var paymentType = s?.paymentType ? s?.paymentType.value : "fixed";
        let discount = s.discount ?? 0;
        let taxInclusiveRate = Number(s?.taxes?.find(t => t?.inclusive)?.rate ?? 0);
        if (s?.taxes?.find(t => t?.inclusive)) {
            discount = discount / (1 + taxInclusiveRate);
        }

        masterDiscount = masterDiscount + (discount ?? 0);

        if (!s.product_key) {
            canSend = false;
        }
        if (s.quantity) {
            times = s.quantity;
        }
        if (paymentType === "hour") {
            serviceTotal = parseInt(s.hours, 10) * parseFloat(s.total) * times;
            subtotal =
                subtotal + parseInt(s.hours, 10) * parseFloat(s.total) * times;
            if (s.feeAdded) {
                feeAdded += s.feeAdded * parseInt(s.hours, 10) * times;
            }
        } else {
            serviceTotal = parseFloat(s.total) * times;
            subtotal = subtotal + ((parseFloat(s.total) * times) / (1 + taxInclusiveRate));
            if (s.feeAdded) {
                feeAdded += s.feeAdded * times;
            }
        }
        if (s?.taxes || s?.localTaxes) {
            var nFeeAdded = useDiscount ? s.feeAdded || 0 : 0;
            var d2 = s.discount ?? 0;
            (s.taxes ?? []).concat(s?.localTaxes ?? []).forEach((t) => {
                if (t.type === "IVA") {
                    // haveIVA = true;
                }
                if (!t.withholding) {
                    if (t.inclusive) {
                        taxesIncluded =
                            taxesIncluded +
                            (serviceTotal -
                                (nFeeAdded + d2) -
                                (serviceTotal - (nFeeAdded + d2)) / (parseFloat(t.rate) + 1));
                    } else {
                        taxes =
                            taxes + (serviceTotal - (nFeeAdded + d2)) * parseFloat(t.rate);
                    }
                } else {
                    retentions =
                        retentions +
                        (serviceTotal - (nFeeAdded + d2)) * parseFloat(t.rate);
                }
            });
        }
    });
    const fa = feeAdded || 0;
    const mad = masterDiscount || 0;
    masterDiscount = Number((((mad) + (fa)))?.toFixed(2))

    return {
        total:
            Number(parseFloat(subtotal + (taxesIncluded + taxes) - retentions).toFixed(2)) -
            masterDiscount,
        taxes: Number((taxes + taxesIncluded).toFixed(2)),
        retentions,
        feeAdded: feeAdded,
        canSend,
        feeAddedString: formatter.format(feeAdded),
        totalWithoutFeeString: formatter.format(
            subtotal + taxes - taxesIncluded - retentions - feeAdded
        ),
        totalWithoutFee: Number((subtotal + (taxes  /*- taxesIncluded*/) - retentions - feeAdded).toFixed(2)),
        totalString: formatter.format(
            Number(parseFloat(subtotal + (taxesIncluded + taxes) - retentions).toFixed(2)) -
            masterDiscount
        ),
        subtotalString: formatter.format(subtotal /*- taxesIncluded*/),
        subtotal: Number((subtotal /*- taxesIncluded*/).toFixed(2)),
        taxesString: formatter.format(taxes + taxesIncluded),
        retentionsString: formatter.format(retentions),
        masterDiscount,
        taxesIncluded,
        masterDiscountString: formatter.format(masterDiscount),
    };
}


/**
 * It creates a textarea element, sets its value to the text you want to copy, adds it to the DOM,
 * selects it, copies it, and then removes it from the DOM
 * @param text - The text to copy to the clipboard.
 */
export const CopyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    message.success('Copiado al portapapeles')
}


/**
 * It returns a string that describes the status of a payment
 * @param payment - The PaymentIntent object.
 * @returns A string
 */
export const paymentIntentsStatusMessage = (payment) => {
    if (!payment) { return '' }
    if (payment.review_status === 'pending') {
        return 'Por revisar'
    }
    const status = payment.status
    if (payment.refunded) {
        return 'El pago ha sido reembolsado'
    }
    switch (status) {
        case 'requires_payment_method':
            return 'En espera'
        case 'pending':
            return 'En espera'
        case 'review_requested':
            return 'Revisión'
        case 'requires_confirmation':
            return 'Por confirmar'
        case 'requires_action':
            return 'Requiere acción'
        case 'processing':
            return 'Procesando...'
        case 'requires_capture':
            return 'capture su pago'
        case 'succeeded':
            return 'Exitoso'
        case 'paid':
            return 'Exitoso'
        case 'canceled':
            return 'Cancelado'
        case 'failed':
            return 'Fallido'
        case 'void':
            return 'Inválido'
        default:
            return 'En espera'

    }
}
/**
 * It takes a string as an argument and returns a string
 * @param status - The status of the payment.
 * @returns A string.
 */
export const paymentByStatus = (status) => {
    if (!status) { return '' }

    switch (status) {
        case 'requires_payment_method':
            return 'En espera.'
        case 'pending':
            return 'Enviado.'
        case 'review_requested':
            return 'Confirmación manual'
        case 'requires_confirmation':
            return 'Revisión'
        case 'requires_action':
            return 'Requiere acción'
        case 'processing':
            return 'Procesando...'
        case 'requires_capture':
            return 'Capture su pago'
        case 'succeeded':
            return 'Exitoso'
        case 'paid':
            return 'Exitoso.'
        case 'canceled':
            return 'Cancelado'
        case 'failed':
            return 'Fallido'
        case 'void':
            return 'Inválido'
        default:
            return 'En espera'

    }
}
/**
 * It returns an icon based on the status of the payment.
 * @param payment - The payment object from Stripe
 * @param [avoidRefund=false] - This is a boolean value that will prevent the icon from showing a
 * refund icon if the payment has been refunded.
 * @returns A function that returns a react component
 */
export const paymentIntentsStatusIcon = (payment, avoidRefund = false) => {
    if (!payment) return <QuestionOutlined />
    if (payment.review_status === 'pending') {
        return <EyeOutlined />

    }
    const status = payment.status
    if (payment.refunded && !avoidRefund) {
        return <DoubleLeftOutlined />
    }
    switch (status) {
        case 'requires_payment_method':
            return <QuestionOutlined />
        case 'requires_confirmation':
            return <ExclamationOutlined />
        case 'requires_action':
            return <ExclamationOutlined />
        case 'processing':
            return <LoadingOutlined />
        case 'requires_capture':
            return <QuestionOutlined />
        case 'succeeded':
            return <CheckCircleOutlined />
        case 'paid':
            return <CheckCircleOutlined />
        case 'canceled':
            return <StopOutlined />
        case 'failed':
            return <CloseOutlined />
        case 'void':
            return <CloseOutlined />
        default:
            return <QuestionOutlined />

    }
}
/**
 * If the payment is pending review, return a light purple color. If the payment is refunded, return a
 * light red color. Otherwise, return a color based on the payment status
 * @param payment - the payment object
 */
export const returnColorByPaymentStatus = (payment) => {
    if (!payment) { return '#cecece' }
    if (payment.review_status === 'pending') {
        return 'rgba(206,126,255,0.5)'

    }

    const status = payment.status
    if (payment.refunded) {
        return '#FFECE1'
    }
    switch (status) {
        case 'requires_payment_method':
            return '#E7F3FA' // #DDEEF8
        case 'requires_confirmation':
            return '#E7F3FA'
        case 'pending':
            return '#E7F3FA'
        case 'requires_action':
            return '#FFECE1'
        case 'processing':
            return '#E7F3FA'
        case 'requires_capture':
            return '#E7F3FA'
        case 'succeeded':
            return '#DEF7E7'
        case 'paid':
            return '#DEF7E7'
        case 'canceled':
            return '#FEE9E9'
        case 'failed':
            return '#FEE9E9'
        case 'void':
            return '#E7EAEE'
        default:
            return '#E7F3FA'

    }
}
/**
 * It returns a color based on the payment status
 * @param payment - The payment object
 */
export const returnTextColorByPaymentStatus = (payment) => {
    if (!payment) { return '#000000' }
    if (payment.review_status === 'pending') {
        return '#6B7CF9'

    }
    const status = payment.status
    if (payment.refunded) {
        return '#FB7A30' // #FF9458
    }
    switch (status) {
        case 'requires_payment_method':
            return '#0B9DF8' // #2FAEFF
        case 'requires_confirmation':
            return '#0B9DF8'
        case 'requires_action':
            return '#FB7A30'
        case 'processing':
            return '#0B9DF8'
        case 'requires_capture':
            return '#0B9DF8'
        case 'succeeded':
            return '#0AB649' // #019049
        case 'paid':
            return '#0AB649'
        case 'canceled':
            return '#F15B5B'
        case 'failed':
            return '#F15B5B'
        case 'void':
            return '#556071'
        case 'review_requested':
            return '#ffd659'
        default:
            return '#0B9DF8'

    }
}


/**
 * It returns a text based on the user role
 * @param {string} role 
 * @returns String
 */
export const userRoleMessage = (role) => {
    switch (role) {
        case 'admin':
            return 'Administrador'
        case 'viewer':
            return 'Visor'
        case 'editor':
            return 'Editor'
        case 'active':
            return 'Activo'
        case 'inactive':
            return 'Inactivo'
        case 'blocked':
            return 'Bloqueado'
        default:
            break
    }
}

/**
 * It returns a color based on the user role
 * @param {string} role 
 * @returns String
 */
export const returnColorByUserRole = (role) => {
    switch (role) {
        case 'admin':
            return '#DEF7E7'
        case 'viewer':
            return '#FFECE1'
        case 'editor':
            return '#E7F3FA'
        case 'active':
            return '#DEF7E7'
        case 'inactive':
            return '#E7EAEE'
        case 'blocked':
            return '#FEE9E9'
        default:
            break
    }
}

/**
 * It returns a text color based on the user role
 * @param {string} role 
 * @returns String
 */
export const returnTextColorByUserRole = (role) => {
    switch (role) {
        case 'admin':
            return '#0AB649'
        case 'viewer':
            return '#FB7A30'
        case 'editor':
            return '#0B9DF8'
        case 'active':
            return '#0AB649'
        case 'inactive':
            return '#556071'
        case 'blocked':
            return '#F15B5B'
        default:
            break
    }
}

/**
 * It takes a Firebase error object and returns a readable error message
 * @param error - The error object returned by Firebase.
 * @returns A function that returns a string
 */
export const ReadableFirebaseErrorMessage = (error) => {
    if (!error) { return '' }

    if (error.code) {
        switch (error.code) {
            case 'auth/user-not-found':
                return 'Usuario no encontrado'
            case 'auth/wrong-password':
                return 'Contraseña incorrecta'
            case 'auth/user-disabled':
                return 'Usuario deshabilitado'
            case 'auth/invalid-email':
                return 'Correo electrónico inválido'
            case 'auth/email-already-in-use':
                return 'Correo electrónico ya en uso'
            case 'auth/weak-password':
                return 'Contraseña débil'
            case 'auth/requires-recent-login':
                return 'Se requiere un inicio de sesión reciente'
            default:
                return error.message ?? 'Error desconocido'
        }
    }

}


//[601, 603, 605, 606, 607, 608, 610, 611, 612, 614, 615, 616, 620, 621, 622, 623, 624, 625, 626] 
/* Creating an array of objects that will be used to populate the dropdown menu. */
export const taxRegimes = [
    { value: "601", label: "Ley General de Personas Morales" },
    { value: "603", label: "Personas Morales con Fines no Lucrativos" },
    { value: "605", label: "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
    { value: "606", label: "Arrendamiento" },
    { value: "607", label: "Régimen de Enajenación o Adquisición de Bienes" },
    { value: "608", label: "Demás ingresos" },
    { value: "609", label: "Consolidación" },
    { value: "610", label: "Residentes en el Extranjero sin Establecimiento Permanente en México" },
    { value: "610", label: "Residentes en el Extranjero" },
    { value: "611", label: "Ingresos por Dividendos(socios y accionistas)" },
    { value: "612", label: "Personas Físicas con Actividades Empresariales y Profesionales" },
    { value: "614", label: "Ingresos por intereses" },
    { value: "615", label: "Régimen de los ingresos por obtención de premios" },
    { value: "616", label: "Sin obligaciones fiscales" },
    { value: "620", label: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
    { value: "621", label: "Régimen de Incorporación Fiscal" },
    { value: "622", label: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
    { value: "623", label: "Opcional para Grupos de Sociedades" },
    { value: "624", label: "Coordinados" },
    { value: "625", label: "Régimen de las Actividades Empresariales con ingresos a través de plataformas tecnológicas" },
    { value: '626', label: "Régimen simplificado de confianza" },
    { value: "628", label: "Hidrocarburos" },
    { value: "629", label: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales" },
    { value: "630", label: "Enajenación de acciones en bolsa de valores" },



]
/**
 * It takes a value and returns the tax regime object that has that value
 * @param value - The value of the tax regime.
 * @returns The taxRegime object that matches the value passed in.
 */

export const findTaxRegime = (value) => {
    let taxRegime = taxRegimes.find(taxRegime => taxRegime.value === value)
    return taxRegime
}


/* Creating an array of objects that will be used to populate the invoiceUsage dropdown. */
export const invoiceUsage = [
    {
        label: "Adquisición De Mercancías",
        value: "G01"
    },
    {
        label: "Devoluciones Descuentos Bonificaciones",
        value: "G02"
    },
    {
        label: "Gastos En General",
        value: "G03"
    },
    {
        label: "Construcciones",
        value: "I01"
    },
    {
        label: "Mobiliario Y Equipo De Oficina",
        value: "I02"
    },
    {
        label: "Equipo de Transporte",
        value: "I03"
    },
    {
        label: "Equipo de Cómputo y Accesorios",
        value: "I04"
    },
    {
        label: "Dados, troqueles, moldes, matrices y herramental.",
        value: "I05"
    },
    {
        label: "Comunicaciones Telefónicas",
        value: "I06"
    },
    {
        label: "Comunicaciones Satelitales",
        value: "I07"
    },
    {
        label: "Otra Maquinaria",
        value: "I08"
    },
    {
        label: "Honorarios médicos, dentales y gastos hospitalarios.",
        value: "D01"
    },
    {
        label: "Gastos Médicos Por Incapacidad",
        value: "D02"
    },
    {
        label: "Gastos Funerales",
        value: "D03"
    },
    {
        label: "Donativos",
        value: "D04"
    },
    {
        label: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        value: "D05"
    },
    {
        label: "Aportaciones voluntarias al SAR.",
        value: "D06"
    },
    {
        label: "Prima Seguros Gastos Médicos",
        value: "D07"
    },
    {
        label: "Gastos de transportación escolar obligatoria",
        value: "D08"
    },
    {
        label: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
        value: "D09"
    },
    {
        label: "Pagos por servicios educativos (colegiaturas)",
        value: "D10"
    },
    {
        label: "Sin efectos fiscales",
        value: "S01"
    },
    {
        value: "CP01",
        label: "Pagos"

    },
    {
        label: "Nómina",
        value: "CN01"
    },

]
/**
 * It takes a value and returns the usage object that has that value
 * @param value - The value of the usage you want to find.
 * @returns The usage object that matches the value passed in.
 */
export const findUsage = (value) => {
    let usage = invoiceUsage.find(usage => usage.value === value)
    return usage
}

/* Creating an array of objects. */
export const paymentForms = [
    { value: "01", label: "Efectivo" },
    { value: "02", label: "Cheque nominativo" },
    { value: "03", label: "Transferencia electrónica de fondos" },
    { value: "04", label: "Tarjeta de crédito" },
    { value: "05", label: "Monedero electrónico" },
    { value: "06", label: "Dinero electrónico" },
    { value: "08", label: "Vales de despensa" },
    { value: "12", label: "Dación en pago" },
    { value: "13", label: "Pago por subrogación" },
    { value: "14", label: "Pago por consignación" },
    { value: "15", label: "Condonación" },
    { value: "17", label: "Compensación" },
    { value: "23", label: "Novación" },
    { value: "24", label: "Confusión" },
    { value: "25", label: "Remisión de deuda" },
    { value: "26", label: "Prescripción o caducidad" },
    { value: "27", label: "A satisfacción del acreedor" },
    { value: "28", label: "Tarjeta de débito" },
    { value: "29", label: "Tarjeta de servicios" },
    { value: "30", label: "Aplicación de anticipos" },
    { value: "31", label: "Intermediario pagos" },
    { value: "99", label: "Por definir" },
]

/* An array of objects that contains the value and label for the payment form. */
export const paymetFormForEgress = [
    //12, 13, 14, 15, 17, 23, 24, 25, 26, 27, 28, 29, 30, 31, 99, 01, 02, 03, 04, 05, 06, 08
    { value: "12", label: "Dación en pago" },
    { value: "13", label: "Pago por subrogación" },
    { value: "14", label: "Pago por consignación" },
    { value: "15", label: "Condonación" },
    { value: "17", label: "Compensación" },
    { value: "23", label: "Novación" },
    { value: "24", label: "Confusión" },
    { value: "25", label: "Remisión de deuda" },
    { value: "26", label: "Prescripción o caducidad" },
    { value: "27", label: "A satisfacción del acreedor" },
    { value: "28", label: "Tarjeta de débito" },
    { value: "29", label: "Tarjeta de servicios" },
    { value: "30", label: "Aplicación de anticipos" },
    { value: "31", label: "Intermediario pagos" },
    { value: "99", label: "Por definir" },

]

/* Creating an array of objects. */
export const relationOptions = [
    {
        "value": "01",
        "label": "Nota de crédito de los documentos relacionados"
    },
    {
        "value": "02",
        "label": "Nota de débito de los documentos relacionados"
    },
    {
        "value": "03",
        "label": "Devolución de mercancía sobre facturas o traslados previos"
    },
    {
        "value": "04",
        "label": "Sustitución de los CFDI previos"
    },
    {
        "value": "05",
        "label": "Traslados de mercancias facturados previamente"
    },
    {
        "value": "06",
        "label": "Factura generada por los traslados previos"
    },
    {
        "value": "07",
        "label": "CFDI por aplicación de anticipo"
    },
    {
        "value": "08",
        "label": "Factura generada por pagos en parcialidades"
    },
    {
        "value": "09",
        "label": "Factura generada por pagos diferidos"
    }
]

/**
 * It removes all the undefined properties from an object
 * @param obj - The object to remove undefined values from.
 * @returns the object with undefined keys removed.
 */

export const RemoveUndefined = (obj) => {
    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
    return obj
}

/**
 * It returns an object with the type of payment method, the fee, the feeA, and the fees array
 * @param user - The user object
 */
export const FeesAmounts = (user, team = {}) => {
    const fees = []
    var type = ""
    var fee = 0
    var feeA = 0
    if (team?.conekta?.completed) {
        fees.push({ type: "conekta", fee: 0, feeA: 12.50 })
        type = "conekta"
        fee = 0
        feeA = 12.5
    }
    if (team?.stripe?.completed) {
        fees.push({ type: "stripe", fee: 3.6, feeA: 3 })
        type = "stripe"
        fee = 3.6
        feeA = 3
    }
    if (team?.paypal?.completed) {
        fees.push({ type: "paypal", fee: 3.95, feeA: 4 })
        type = "paypal"
        fee = 3.95
        feeA = 4

    }

    return {
        type,
        fee,
        feeA,
        fees
    }
}

/**
 * If the user has a stripe account, and the stripe account has no past due requirements, then return
 * true
 * @param user - The user object
 * @returns A boolean value
 */
export const UserHasStripe = (user, team) => {
    let hasStripe = false
    if (team?.stripe?.completed) {
        hasStripe = true
        return hasStripe
    }
    return hasStripe
}


/**
 * It opens a new window with the whatsapp://send?text= url, and the text parameter is the text
 * you want to share
 * @param text - The text to share.
 * @returns A window object
 */
export const ShareViaWhatsapp = (text) => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=10,top=10`;
    return window.open(`whatsapp://send?text=${text}`, '_blank', params)
}
/**
 * It takes a string and returns a string
 * @param status - The status of the invoice.
 * @returns A function that returns a string
 */
export const InvoiceStatusReadable = (status) => {
    switch (status) {
        case 'valid':
            return 'Válida'
        case 'invalid':
            return 'Inválida'
        case 'canceled':
            return 'Cancelada'
        case 'requires_manual_cancellation':
            return 'Cancelación manual requerida'
        default:
            return ''
    }
}


/**
 * It downloads an invoice from the firebase storage and saves it to the user's computer
 */
export const DownloadInvoice = async ({ invoice, type = 'pdf', setdownloading, authUser, customDownloading }) => {


    setdownloading(customDownloading ?? invoice.id)
    try {

        // console.log('CALLING');
        const invoicereq = await SignedInternalAPIRequest({
            invoicingIntegration: 'facturapi', type: 'download_invoice', test: !invoice.livemode, downloadType: type, id: invoice?.id, invoiceType: invoice?.invoiceType ?? 'I',
            cancelation: invoice?.cancelation ?? null
        }, 'invoicing', authUser)

        var xhr = new XMLHttpRequest();
        if (invoice?.cancelation) {
            // console.log(invoicereq);

            // setdownloading(null)
            if (invoicereq.pdf) {
                //invoicerq.pdf IS BASE 64 PDF DOWNLOAD IT
                // console.log('DOWNLOADING');
                const prepB64 = `data:application/pdf;base64,${invoicereq.pdf}`
                const binaryData = atob(prepB64.replace(/^[^,]+,/, ''));
                const blob = new Blob([new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i))], {
                    type: 'application/pdf'
                });

                const url = URL.createObjectURL(blob);

                // Create an anchor element for downloading
                const a = document.createElement('a');
                a.href = url;
                a.download = `${invoice.uuid}.pdf`;

                // Simulate a click on the anchor element to trigger the download
                a.click();

                // Clean up by revoking the object URL
                URL.revokeObjectURL(url);
                setdownloading(null)

            }
            return;
        }


        const url = await getDownloadURL(ref(getStorage(), invoicereq.url))


        xhr.responseType = 'blob';
        xhr.onload = function (event) {
            var blob = xhr.response;
            saveAs(blob, `${invoice.uuid}.${type}`)
        };
        xhr.open('GET', url);
        xhr.send();
        xhr.addEventListener('progress', (e) => {
            var percent_complete = (e.loaded / e.total) * 100;
            if (percent_complete >= 100) {
                setdownloading(null)
            }
        });

    } catch (error) {
        message.error(error.message, 15)

        setdownloading(null)
    }

}

/**
 * 
 */
export const DownloadCanceledReceipt = async ({ invoice, setdownloading, authUser }) => {
    setdownloading(invoice.id)
    // console.log('entro')
    try {
        const xml = invoice?.cancellation_receipt
        const blob = new Blob([xml], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `Acuse-${invoice.uuid}.xml`)
        setdownloading(null)

    } catch (error) {
        message.error('Error al descargar el acuse de cancelación')
        setdownloading(null)
    }
}

/**
 * It takes an invoice object, and returns a new invoice object with the same data, but with a new date
 * and a new id and in live mode
 */
export const DuplicateInvoice = async ({ invoice, setloading = () => { }, onSuccess = () => { }, currentUser }) => {
    setloading(invoice.id)
    try {

        const ninv = {
            ...invoice,
            type: 'create_invoice',
            date: moment().toISOString(),
            signPastInovice: true,
            signPastInvoice: true,
            items: invoice.items,
            invoicingIntegration: 'facturapi',
            //TODO: REMOVE TEST
            test: false,
            payment_form: invoice.payment_form,
            series: invoice.series || 'gigstack',
            use: invoice.use,
            client: invoice.client,
            customer: invoice.client,
            relation: invoice.relation ?? null
        }


        if (invoice.relation?.length <= 0 || invoice.relation == null) {
            delete ninv['relation']
        }

        const response = await SignedInternalAPIRequest(ninv, 'invoicing', currentUser)

        if (response.error) {
            throw new Error(response.error)
        }
        notification.success({ placement: "bottomRight", description: 'Hemos timbrado la factura', })
        setloading(null)
        onSuccess(response)
    } catch (error) {
        message.error(error.message)
        setloading(null)
    }
}


export const HandleEmailStatus = (status) => {
    switch (status) {
        case 'dropped':
            return {
                message: 'Fallido',
                color: 'red'
            }

        case 'sent':
            return {
                message: 'Enviado',
                color: 'green'
            }

        case 'delivered':
            return {
                message: 'Entregado',
                color: 'green'
            }

        case 'processed':
            return {
                message: 'Procesado',
                color: 'blue'
            }

        case 'open':
            return {
                message: 'Abierto',
                color: 'green'
            }

        default:
            return {
                message: status,
                color: 'blue'
            }
    }
}

/**
 * It returns an object with a boolean and an array of strings. The boolean is true if all the products
 * have a product key, and false if at least one of them doesn't. The array of strings contains the
 * names of the products that don't have a product key
 * @param products - Array of products
 */

export const ProductsAvailableForSat = (products) => {
    var available = true;
    var messages = [];
    if (products.length <= 0) {
        return { available: false, messages: [] }
    }
    products.forEach(product => {
        if (!product.product_key) {
            available = false
            messages.push(`El producto ${product.name} no tiene clave de producto`);
        }
    }
    )
    return { available, messages }
}

/**
 * If the product doesn't have a product_key, then return false, otherwise return true
 * @param product - The product object
 * @returns A function that takes a product as an argument and returns a boolean.
 */
export const ProductAvailableForSAT = (product) => {
    if (!product.product_key) {
        return false
    } else return true
}


/**
 * If the client has a RFC, legal name, zip code, and tax system, then return true, otherwise return
 * false
 * @param client - The client object
 * @returns A boolean value
 */
export const ClientReadyForInvoice = (client) => {
    if (client?.rfc && client?.legal_name && client?.address?.zip && client?.tax_system) {
        return true
    }
    return false
}

/**
 * It returns an object with two properties: percentComplete and status
 * @returns An object with two properties: percentComplete and status.
 */
export function UploadProgress({ uploadTask, storageRef }) {
    const { status, data: uploadProgress } = useStorageTask(uploadTask, storageRef);

    let percentComplete;

    if (status === 'loading') {
        percentComplete = 0;
    } else {
        const { bytesTransferred, totalBytes } = uploadProgress;
        percentComplete = Math.round(100 * (bytesTransferred / totalBytes));
    }

    return { percentComplete, status };
}




/**
 * It downloads a file from a url and saves it to the user's computer
 */
export const DownloadFile = async ({ url, name, setdownloading = () => { }, type }) => {


    setdownloading(url)
    try {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
            var blob = xhr.response;
            saveAs(blob, `${name}.${type}`)
        };
        xhr.open('GET', url);
        xhr.send();
        xhr.addEventListener('progress', (e) => {
            var percent_complete = (e.loaded / e.total) * 100;
            if (percent_complete >= 100) {
                setdownloading(null)
            }
        });

    } catch (error) {
        setdownloading(null)
    }

}

/**
 * It takes an array of objects, and returns a new array of objects, where each object is unique based
 * on the value of a specified key
 * @param array - The array of objects you want to remove duplicates from.
 * @param [identifierKey=id] - The key that you want to use to identify the object.
 * @returns A function that takes an array and an identifier key and returns a new array with no
 * duplicates.
 */
export const RemoveDuplicatesFromObjectArray = (array, identifierKey = "id") => {
    var uniqueArray = []
    array.forEach((a) => {
        if (!uniqueArray.find(u => u[identifierKey] === a[identifierKey])) {
            uniqueArray.push(a)
        }
    })
    return uniqueArray
}


/**
 * It takes a string as an argument and returns an object with a text property and a color property
 * @param type - The type of invoice.
 * @returns An object with the text and color properties.
 */
export const InvoiceType = (type) => {
    switch (type) {
        case 'P':
            return { text: 'Pago', color: '#DEF7E7', textColor: '#0AB649' }
        case 'I':
            return { text: 'Ingreso', color: '#FEE9E9', textColor: '#F15B5B' }
        case 'E':
            return { text: 'Egreso', color: '#FEE9E9', textColor: '#F15B5B' }
        case 'N':
            return { text: 'Nómina', color: '#FEE9E9', textColor: '#F15B5B' }
        case 'T':
            return { text: 'Traslado', color: '#FEE9E9', textColor: '#F15B5B' }
        default:
            return { text: 'Factura', color: '#0B9DF8' }
    }
}

/**
 * It takes in an authUser object and a setLoading function as props, and then it checks the URL for a
 * platform and code query string. If it finds them, it makes an API request to the backend to create a
 * Stripe connection, and then it opens the Stripe connection URL in a new tab
 */

export const FinishStripeConnection = async ({ setLoading = () => { }, authUser }) => {
    const platform = SearchParamInURL('platform');
    if (platform === 'stripe') {
        // TODO Change redirect url
        const code = SearchParamInURL('code');
        const CreateConnectionStripe = async () => {
            try {
                const link = await SignedInternalAPIRequest({ type: 'connect_oauth', code, redirectUrl: 'https://app.gigstack.pro/settings?subtab=integrations', refreshUrl: 'https://app.gigstack.pro/settings?subtab=integrations' }, 'stripeActions', authUser)

                setLoading(false)
                //REMOVE PLATFORM AND CODE FROM URL



                return window.open(link.url, '_self')
            } catch (error) {
                setLoading(false)
            }
        }
        if (code) {
            setLoading(true)
            CreateConnectionStripe()
        }

    }
}


/**
 * It takes a string and returns an object with the same string as a value and a label
 * @param periodicity - The periodicity of the subscription.
 * @returns An object with the value and label of the periodicity
 */
export const TranslatePeriodicityToObject = (periodicity) => {
    switch (periodicity) {
        case 'day':
            return { value: 'day', label: 'Día' }
        case 'week':
            return { value: 'week', label: 'Semana' }
        case 'fortnight':
            return { value: 'fortnight', label: 'Quincenal' }
        case 'month':
            return { value: 'month', label: 'Mes' }
        case 'two_months':
            return { value: 'two_months', label: '2 meses' }
        default:
            return { value: 'month', label: 'Mes' }
    }
}



/**
 * It takes a string and returns an array of all the numbers that are inside double curly braces
 * @param text - The text to extract the variables from
 */
export const ExtractVariablesFromText = (text) => {
    var variables = [];
    var regex = /{{(\d+)}}/g;
    var match;
    // eslint-disable-next-line
    while (match = regex.exec(text)) {

        variables.push(match[1]);
    }


    return variables;
}

/**
 * It takes a number and returns it rounded
 * @params number - Number to round
 * @returns numberRounded
 */

export const roundAmount = (number) => {
    return Math.round(number)
}

const paymentObj = {
    "canceled_at": null,
    "review": null,
    "invoices": [
        "3kxj5FTO9Z7C"
    ],
    "on_behalf_of": null,
    "manualSuccess": true,
    "exchangeRate": 1,
    "receipt_email": "{{email}}",
    "isManual": false,
    "automatic_payment_methods": null,
    "lastViewed": 1673487601092,
    "source": null,
    "clientID": "1SwuEPWzLD",
    "succeededTimestamp": 1673488133913,
    "paidIn": "bank",
    "customer": {
        "zip": "{{ zip }}",
        "legal_name": "{{ legal_name }}",
        "tax_system": {
            "label": "Personas Físicas con Actividades Empresariales y Profesionales",
            "value": "612"
        },
        "name": "{{ name }}",
        "from": "{{ gigstack }}",
        "phoneNumbers": [
            {
                "value": "{{ phone }}"
            }
        ],
        "address": {
            "address": "{{ address }}",
            "zip": "{{ zip }}",
            "country": "{{ country }}"
        },
        "RFCvalidated": true,
        "phone": "{{ phone }}",
        "owner": "{{ owner }}",
        "company": "{{ company }}",
        "emailAddresses": [
            {
                "value": "{{ email }}"
            }
        ],
        "efos": {
            "data": {
                "mensaje": "La consulta para el rfc especificado no devolvió resultados",
                "detalles": []
            },
            "is_valid": true
        },
        "rfc": "XAXX010101000",
        "names": [
            {
                "displayName": "{{ displayName }}"
            }
        ],
        "email": "{{ email }}",
        "id": "1SwuEPWzLD",
        "country": {
            "label": "{{ country }}",
            "code": "MX",
            "value": "{{ country }}"
        },
        "timestamp": 1673330844947
    },
    "charges": null,
    "statement_descriptor": "",
    "application": null,
    "exchange_rate": 1,
    "payment_intent": null,
    "metadata": {
        "internalID": "{{ internalID }}",
        "items": 1,
        "owner": "{{ owner }}"
    },
    "amount_capturable": 0,
    "clientVoucher": "https://firebasestorage.googleapis.com/v0/b/gigstackpro.appspot.com/o/paymentVouchers%2FjgsGYBDw1xbyigs.jpeg?alt=media&token=419c97c4-8a00-4152-9465-234de163b615",
    "items": [
        {
            "id": "mE9JyqKaby",
            "product_key": "81111504",
            "quantity": 1,
            "description": "Creación de una landing page responsive ",
            "taxes": [
                {
                    "factor": "Tasa",
                    "rate": "0.16",
                    "type": "IVA",
                    "withholding": false
                }
            ],
            "hours": null,
            "owner": "{{ owner }}",
            "paymentType": {
                "label": "Precio fijo",
                "value": "fixed"
            },
            "total": "3",
            "name": "Desarrollo de Landing Page",
            "timestamp": 1673331152792
        }
    ],
    "capture_method": "automatic",
    "created": 1673487553593,
    "v": 2,
    "payment_method_types": [],
    "id": "{{ id }}",
    "application_fee_amount": null,
    "discount": 0,
    "client": {
        "testInvoicesCreated": 1,
        "name": "{{ name }}",
        "email": "{{ email }}",
        "zip": "91380",
        "id": "1SwuEPWzLD",
        "emailAddresses": [
            {
                "value": "{{ email }}"
            }
        ],
        "names": [
            {
                "displayName": "{{ name }}"
            }
        ],
        "timestamp": 1673330844947,
        "rfc": "XAXX010101000",
        "phoneNumbers": [
            {
                "value": "{{ phone }}"
            }
        ],
        "tax_system": {
            "label": "Personas Físicas con Actividades Empresariales y Profesionales",
            "value": "612"
        },
        "phone": "2281495452",
        "legal_name": "{{ name }}",
        "from": "{{ company }}",
        "owner": "{{ owner }}",
        "country": {
            "value": "{{ country }}",
            "code": "MX",
            "label": "{{ country }}"
        },
        "paymentsCreated": 1,
        "efos": {
            "data": {
                "mensaje": "La consulta para el rfc especificado no devolvió resultados",
                "detalles": []
            },
            "is_valid": true
        },
        "address": {
            "address": "{{ address }}",
            "country": "{{ country }}",
            "zip": "{{ zip }}"
        },
        "RFCvalidated": true,
        "company": "{{ company }}"
    },
    "proposals": null,
    "description": "",
    "payment_method": null,
    "confirmation_method": "",
    "shipping": null,
    "processor": "",
    "last_payment_error": null,
    "review_created_at": 1673488082149,
    "voucherMeta": {
        "extension": "jpeg",
        "path": "paymentVouchers/jgsGYBDw1xbyigs.jpeg"
    },
    "owner": "DiMlHSc5blTQAvednxNb9yBqkYS2",
    "sms": false,
    "shortURL": "https://gigstack.xyz/jgsGYBDw1xbyigs",
    "timestamp": 1673487553593,
    "custom_method_types": [
        {
            "logo": "https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png",
            "id": "bank",
            "details": "Pago con transferencia desde tu cuenta bancaria",
            "name": "Transferencia bancaria",
            "manualConfirmation": true
        }
    ],
    "currency": "mxn",
    "shortUrl": "https://gigstack.xyz/jgsGYBDw1xbyigs",
    "next_action": null,
    "amount_received": 0,
    "hasStripe": false,
    "exchangeRateDate": "Wed, 11 Jan 2023 00:00:01 +0000",
    "cancellation_reason": null,
    "livemode": true,
    "review_status": "succeeded",
    "object": "payment",
    "invoicesCount": 0,
    "viewed": 1,
    "status": "succeeded",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXF1ZXN0ZXIiOiJEaU1sSFNjNWJsVFFBdmVkbnhOYjl5QnFrWVMyIiwicGF5bWVudCI6Impnc0dZQkR3MXhieWlncyIsImlhdCI6MTY3MzQ4NzU2Mn0.AVJYR13_nDPcwvy6p10ciyCrfkydYJ72UirMfB1OUK8",
    "bank": {
        "holder_name": "{{ holder_name }}",
        "clabe": "{{ clabe }}",
        "country": "{{ country }}",
        "help": "Enviar captura de la transferencia o comprobante de pago",
        "formatOk": true,
        "account": "20011164435",
        "tag": "{{ bank }}",
        "multiple": true,
        "voucherRequired": true,
        "ok": true,
        "code": {
            "bank": "014",
            "city": "905"
        },
        "bank": "Banco Santander",
        "checksum": 6,
        "message": "Valid",
        "total": 2,
        "city": "Ciudad Industrial Framboyan, Veracruz MX-VER"
    },
    "amount": 5348,
    "internalStatus": "viewed",
    "fid": "{{ fid }}",
    "transfer_group": null
}

export const generateTestPayments = (n) => {
    let paymentsArray = []
    for (let i = 0; i <= n; i++) {
        paymentsArray.push({ ...paymentObj, id: `${i}`, customer: { ...paymentObj.customer, name: `${paymentObj.customer.name}-${i}` }, client: { ...paymentObj, name: `${paymentObj.client.name}-${i}` } })
    }
    return paymentsArray
}

export const updateModalDataFromContext = ({ ctx, toUpdate = {} }) => {
    const {
        newInvoiceModalOpen,
        newServiceModalOpen,
        newPaymentRequestModalOpen,
        modalInvoiceProps,
        modalPaymentRequestProps,
        handleSetModalPropsData,
        modalServiceProps
    } = ctx
    var check = [{ type: 'invoice', modal: newInvoiceModalOpen, props: modalInvoiceProps },
    { type: "service", modal: newServiceModalOpen, props: modalServiceProps },
    { type: "payent", modal: newPaymentRequestModalOpen, props: modalPaymentRequestProps }]

    for (const modal of check) {
        if (modal.modal) {
            let oldData = modal.props
            Object.keys(toUpdate).forEach((t) => {
                oldData[t] = toUpdate[t]
            })
            handleSetModalPropsData(modal.type, oldData)
        }
    }
}

export const findDataInContext = ({ ctx, key }) => {
    const {
        newInvoiceModalOpen,
        newServiceModalOpen,
        newPaymentRequestModalOpen,
        modalInvoiceProps,
        modalPaymentRequestProps,
        modalServiceProps
    } = ctx
    var check = [{ type: 'invoice', modal: newInvoiceModalOpen, props: modalInvoiceProps },
    { type: "service", modal: newServiceModalOpen, props: modalServiceProps },
    { type: "payent", modal: newPaymentRequestModalOpen, props: modalPaymentRequestProps }]

    var data;

    for (const modal of check) {
        var temp = { key: [], ...modal?.props }
        if (modal.modal && !data && temp[key]) {
            data = temp[key] ?? [];
        }
    }

    return data
}



export const InternalPayment = ({
    amount = 0,
    status = "pending",
    internalStatus = "pending",
    internalItems = [],
    custom_method_types = [],
    exchange = 1,
    currency = "MXN",
    paidId = "manual",
    id = "",
    fid = "",
    v = 2,
    from = "manual",
    processor = "",
    relatedTo = "",
    invoices = null,
    payments = null,
    client = null,
    discount = 0,
    payment = {},
    user = {},
    shortURL = null,
    shortUrl = null,
    token = null,
    team = "",
    billingAccount = "",
    hasStripe = null,
    timestamp = moment().valueOf(),
    created = moment().valueOf(),
    succeededTimestamp = null,
    payment_form = "99",
    binnacle = ""
}) => {
    return {
        ...payment,
        owner: user.uid,
        amount: Number(amount),
        status,
        internalStatus,
        internalItems,
        items: internalItems,
        custom_method_types,
        exchange,
        currency,
        paidId,
        id,
        fid,
        v,
        from,
        payment_form,
        processor,
        relatedTo,
        invoices,
        payments,
        team,
        billingAccount,
        client,
        clientId: client?.id ?? null,
        clientID: client?.id ?? null,
        discount: typeof discount === "object" ? 0 : discount,
        timestamp,
        created,
        shortURL,
        shortUrl,
        token,
        hasStripe,
        succeededTimestamp,
        binnacle
    };
};