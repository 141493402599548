import { CloseOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Form, Input, message, Row, Space, Typography } from 'antd';
import React, { Suspense, useContext, useState } from 'react';
import ServiceTaxModal from './ServiceTaxModal';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { generateCode, RemoveUndefined } from '../../app/functions/helpers';
import moment from 'moment';
import DefaultTableItemsLocal from '../tables/DefaultTableItemsLocal';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
const SearchSATProductkey = React.lazy(() => import('./SearchSATProductkey'));;

// const ClientsListModal = React.lazy(() => import('../clients/ClientsListModal'));
// {
//     searchPK && <Suspense fallback={<></>}>
//         <ClientsListModal open={searchPK} close={() => setsearchPK(false)} userDocument={userDocument} />
//     </Suspense>
// }





function AddNewService({ open, close, edit, userDocument, refreshChild }) {

    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const [loading, setloading] = useState(false)
    const [taxes, settaxes] = useState(edit ? (edit.taxes ?? []) : [])
    const [localTaxes, setLocalTaxes] = useState(edit ? (edit.localTaxes ?? []) : [])
    const [addLocalTax, setaddLocalTax] = useState(false)
    const [searchPK, setsearchPK] = useState(false)
    const [addTax, setaddTax] = useState(false)

    const [form] = Form.useForm();

    const fs = getFirestore()



    /**
     * It takes the values from the form, and saves them to the database
     * @param v - The form values
     */

    const Finish = async (v) => {
        try {
            setloading(true)
            var service = { owner: user?.uid, ...RemoveUndefined(v), total: Number(parseFloat(v.total)), taxes: taxes ?? [], unit_key: v.unit_key ?? 'E48', unit_name: v.unit_name ?? 'Unidad de Servicio', paymentType: { value: 'fixed', label: 'Precio fijo' } }

            service.quantity = edit ? edit.quantity : 1
            service.hours = null

            if (localTaxes.length > 0) {
                service.localTaxes = localTaxes
            }

            if (edit) {
                try {
                    await setDoc(doc(fs, 'services', edit.id), { ...service }, { merge: true })

                    if (refreshChild) {
                        refreshChild({ ...service, id: edit.id })
                    }
                    // getCurrentTeam(team?.id)
                    setloading(false)
                    close();
                } catch (error) {
                    message.error(error.message)
                    setloading(false)
                }

            } else {
                service.id = generateCode(10, 'service')
                service.timestamp = moment().valueOf()
                service.team = team?.id
                service.billingAccount = billingAccount?.id


                try {

                    await setDoc(doc(fs, 'services', service.id), service)
                    if (refreshChild) {
                        refreshChild(service);
                    }
                    // getCurrentTeam(team?.id)
                    close();
                } catch (error) {
                    message.error(error.message)
                    setloading(false)
                }
            }
        } catch (error) {
            message.error(error?.message ?? 'Ocurrió un error al crear el servicio')
            setloading(false)
        }
    }



    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} footer={null}>

            <Row style={{ maxWidth: '100%' }}>
                <Col xs={24}>
                    <ServiceTaxModal open={addTax} close={() => setaddTax(false)} add={(tax) => {
                        settaxes([...taxes, { ...tax, withholding: tax.withholding ?? false }])
                        setaddTax(false)
                    }} />

                    <ServiceTaxModal localTax={true} open={addLocalTax} close={() => setaddLocalTax(false)} add={(tax) => {
                        setLocalTaxes([...localTaxes, { ...tax, withholding: tax.withholding ?? false }])
                        setaddLocalTax(false)
                    }} />

                    {searchPK && <Suspense fallback={<>loading</>}>
                        <SearchSATProductkey open={searchPK} close={() => setsearchPK(false)} onSelected={(k) => {
                            form.setFieldsValue({ product_key: k })
                            setsearchPK(false)
                        }} />
                    </Suspense>}

                    <Form form={form} initialValues={edit ? { ...edit, } : { unit_key: 'E48', unit_name: 'Unidad de servicio' }} layout="vertical" onFinish={Finish}>
                        <div className="d-flex flex-column" style={{ padding: '5px' }}>
                            <div className="d-flex flex-column">
                                <Row justify="space-between">
                                    <Typography.Title level={4}>{edit ? 'Actualizar servicio' : 'Crear servicio'}</Typography.Title>
                                    <CloseOutlined onClick={() => close()} />
                                </Row>
                            </div>
                            <div className='d-flex flex-column'
                                style={{ flex: 1, maxHeight: '80vh', overflow: 'scroll', padding: '5px' }}>
                                <Row gutter={{ xs: 10, md: 12, lg: 14 }}>
                                    <Col xs={24} lg={12}>
                                        <Form.Item label="Nombre del servicio" name="name" rules={[{ required: true, message: 'Añade el nombre a tu servicio' }]}>
                                            <Input placeholder='ej: Edición de ...' />
                                        </Form.Item>
                                    </Col>

                                    {/* <Col xs={24} lg={12}>
                                    <Form.Item label="Cobro del servicio" name="paymentType" rules={[{ required: true, message: 'Añade el nombre a tu servicio' }]}>
                                        <Select
                                            styles={generalreactSelectStyles}
                                            className="pro-select-input"
                                            options={[{ label: 'Precio fijo', value: 'fixed' }]}
                                            isLoading={false}
                                            placeholder="Tipo de cobro"
                                        />
                                    </Form.Item>
                                </Col> */}
                                    <Col xs={24} lg={12}>
                                        <Form.Item label="Precio" name="total" rules={[{ required: true, message: 'Añade el precio de tu servicio' }]}>
                                            <Input type="number" className="pro-input" placeholder='$' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="Descripción del servicio" name="description" >
                                            <Input className="pro-input" placeholder='Descripción del servicio' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item label="SKU" name="sku" >
                                            <Input.TextArea className="pro-input" placeholder='SKU del producto o servicio' />
                                        </Form.Item>
                                    </Col>
                                    <Divider  ><Typography.Text type="secondary" style={{ fontSize: '11px', marginBottom: '0px' }}>Facturación</Typography.Text></Divider>

                                    <Col xs={24} style={{ marginBottom: '10px' }}>
                                        <Form.Item label="Cuenta para el impuesto predial" name="property_tax_account" style={{ flex: 1, margin: 0, padding: 0 }} >
                                            <Input className="pro-input" placeholder='Número de cuenta para el impuesto predial.' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} style={{ marginBottom: '10px' }}>
                                        <Row align="bottom">
                                            <Form.Item label="Código del producto/servicio" name="product_key" style={{ flex: 1, margin: 0, padding: 0 }} >
                                                <Input className="pro-input" placeholder='ej: 43232804 (Software de administración)' />
                                            </Form.Item>
                                            <Button shape="circle" icon={<SearchOutlined />} style={{ marginBottom: '2px', marginLeft: '10px' }} onClick={() => setsearchPK(true)} />

                                        </Row>
                                    </Col>
                                    <Typography.Text type="primary" style={{ marginBottom: '10px', padding: '10px' }}>Revisa el nombre de la unidad y código aqui que corresponde antes de crear el servicio <a href="http://pys.sat.gob.mx/PyS/catUnidades.aspx" target="_blank" rel="noreferrer">http://pys.sat.gob.mx/PyS/catUnidades.aspx</a>.</Typography.Text>
                                    <Col xs={24} lg={12}>
                                        <Form.Item label="Nombre de la unidad" name="unit_name" style={{ flex: 1, margin: 0, padding: 0 }} >
                                            <Input className="pro-input" placeholder='Unidad de servicio' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item label="Código de unidad" name="unit_key" style={{ flex: 1, margin: 0, padding: 0 }} >
                                            <Input className="pro-input" placeholder='ej: E48' />
                                        </Form.Item >
                                    </Col >
                                    <Col xs={24} style={{ marginTop: '25px' }}>
                                        <Row align='middle' justify="space-between" style={{ backgroundColor: '#EFEFEF', padding: '6px 10px', borderRadius: '10px' }}>
                                            <Typography.Text className='cLabel'>Impuestos</Typography.Text>
                                            <Button onClick={() => setaddTax(true)}>Añadir impuesto</Button>
                                        </Row>

                                    </Col>
                                    <Col xs={24}>
                                        <DefaultTableItemsLocal avoidContainer={true} empty="Registra un impuesto" elementName="impuesto" emptyAction={() => setaddTax(true)} data={taxes} columns={[{
                                            title: 'Impuesto',
                                            dataIndex: 'type',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: 'Factor',
                                            dataIndex: 'factor',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: 'Tarifa',
                                            dataIndex: 'rate',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: '',
                                            dataIndex: 'withholding',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{record.withholding ? 'retenido' : 'traslado'}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: '',
                                            dataIndex: 'rate',
                                            render: (text, record, i) => {
                                                return <>
                                                    <Space>
                                                        <DeleteOutlined onClick={() => {
                                                            taxes.splice(i, 1)
                                                            settaxes([...taxes])

                                                            // setstate({ ...state, taxes: t })
                                                        }} />
                                                    </Space>
                                                </>
                                            }
                                        },

                                        ]} />
                                    </Col>

                                    <Col xs={24} style={{ marginTop: '25px' }}>
                                        <Row align='middle' justify="space-between" style={{ backgroundColor: '#EFEFEF', padding: '6px 10px', borderRadius: '10px' }}>
                                            <Typography.Text className='cLabel'>Impuestos Locales</Typography.Text>
                                            <Button onClick={() => setaddLocalTax(true)}>Añadir impuesto local</Button>
                                        </Row>

                                    </Col>
                                    <Col xs={24}>
                                        <DefaultTableItemsLocal avoidContainer={true} empty="Registra un impuesto local" elementName="impuesto local" emptyAction={() => setaddLocalTax(true)} data={localTaxes} columns={[{
                                            title: 'Impuesto',
                                            dataIndex: 'type',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: 'Factor',
                                            dataIndex: 'factor',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: 'Tarifa',
                                            dataIndex: 'rate',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: '',
                                            dataIndex: 'withholding',
                                            render: (text, record) => {

                                                return <Typography.Text className="" type="secondary" ellipsis>{record.withholding ? 'retenido' : 'traslado'}</Typography.Text>
                                            }
                                        },
                                        {
                                            title: '',
                                            dataIndex: 'rate',
                                            render: (text, record, i) => {
                                                return <>
                                                    <Space>
                                                        <DeleteOutlined onClick={() => {
                                                            localTaxes.splice(i, 1)
                                                            setLocalTaxes([...localTaxes])

                                                            // setstate({ ...state, taxes: t })
                                                        }} />
                                                    </Space>
                                                </>
                                            }
                                        },

                                        ]} />
                                    </Col>
                                </Row >
                            </div >
                            <Row >
                                <Button htmlType="submit" loading={loading} type="primary" style={{ width: '100%' }}>{edit ? 'Actualizar servicio' : 'Crear servicio'}</Button>
                            </Row>
                        </div >






                    </Form >
                </Col >
            </Row >
        </Drawer>
    );
}

export default AddNewService;