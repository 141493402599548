import { Button, Form, Input, message, Modal, Row, Typography } from 'antd';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import Select from 'react-select';
import { useFirestore } from 'reactfire';
import { generateCode } from '../../app/functions/helpers';
import { generalreactSelectStyles } from '../../app/functions/styles';


function DynamicModalForm({ data = {}, open, close, userDocument }) {
    const ref = doc(useFirestore(), 'users', userDocument?.uid ?? '-');
    const [loading, setloading] = useState(false)
    const [form] = Form.useForm();


    /**
     * It maps the inputs array and returns a form item for each input
     * @returns The inputs of the form
     */
    const FormFile = () => {
        const inputs = data?.inputs.map((input) => {

            if (input.type === 'text') {
                return (
                    <Form.Item rules={[{ required: input.required }]} name={input.key} key={generateCode(10)} label={input.label}>
                        <Input placeholder={input?.placeholder ?? 'Respuesta...'} />
                    </Form.Item>
                )
            } else if (input.type === 'select') {
                return (
                    <Form.Item rules={[{ required: input.required }]} name={input.key} key={generateCode(10)} label={input.label}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={input.options}
                            isLoading={false}
                            placeholder="Tu respuesta"

                        />
                    </Form.Item>
                )
            } else {
                return <></>
            }
        })

        return inputs
    }

    return (
        <Modal title={null} footer={null} visible={open} onCancel={() => close()}>
            <Form form={form} layout="vertical"
                onFinish={async (values) => {
                    try {
                        setloading(true)
                        await updateDoc(ref, { ...values })
                        setloading(false)
                        message.success('Gracias por tu ayuda')
                        close()
                    } catch (error) {
                        setloading(false)
                        message.error('Error al actualizar')
                    }
                }}
            >
                <div className="d-flex flex-column">
                    <Typography.Title style={{ margin: '0' }} level={5}>{data.title}</Typography.Title>
                    <Typography.Text type="secondary" style={{ marginBottom: '25px' }}>{data.description}</Typography.Text>
                    <FormFile />


                    <Row justify="center">
                        <Button loading={loading} htmlType="submit" type="primary" >Siguiente</Button>
                    </Row>

                </div>
            </Form>
        </Modal>
    );
}

export default DynamicModalForm;