import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row, Typography, Button, Input, Col, Form, message } from 'antd';
import { useContext, useState } from 'react';
import stripeLogo from '../../assets/images/stripe_icon.png';
import HelperCard from '../cards/HelperCard';
import { handleStripeLink } from '../settings/PaymentOptions';
import { useAnalytics, useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { logEvent } from 'firebase/analytics';
import moment from 'moment';

function ConectModal({ open, close, userDocument }) {
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const auth = useAuth()
    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const analytics = useAnalytics();


    const ToRenderByStep = () => {
        switch (step) {
            case 0:
                return <ConectStripe setStep={(step) => setStep(step)} />
            case 1:
                return <ConectAPi step={step} setStep={(step) => setStep(step)} />
            default:
                return <ConectStripe step={step} setStep={(step) => setStep(step)} />
        }
    }

    const Header = () => {
        return <>
            <Row justify="space-between">
                <Typography.Title level={4}>Configura tu cuenta de Stripe</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close()} />
                <Typography.Text>Conecta tu cuenta de Stripe para poder recibir pagos de tus clientes.</Typography.Text>
            </Row>
            <HelperCard openIntercom={true} title={'¿Necesitas ayuda?'} description="Estaremos encantados de ayudarte!." supportImageUrl={stripeLogo} />

        </>
    }

    const Conect = () => {
        return (
            <div style={{ padding: '10px', height: '60px', border: "1px solid #ededed", borderRadius: '10px', alignItems: 'center', justifyContent: 'center', width: '100%' }} className={`d-flex flex-column hoverPrimaryBg`}>
                <div onClick={async () => {
                    const link = await handleStripeLink({ userDocument, auth })
                    window.open(link.url, '_blank')
                    logEvent(analytics, 'stripeCompletedAt', {
                        teamId: team?.id,
                        alias: team?.brand?.alias,
                        completedAt: moment().valueOf(),
                        dateISO: moment().toISOString(),
                        method: 'stripeConnect'
                    })
                    setTimeout(() => {
                    }, 3000);
                }} className="d-flex flex-row" style={{ alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <Row justify="center">
                            <Typography.Text style={{ textAlign: 'center', color: "#310db2", }}>Conecta mi cuenta de Stripe (Recomendado)</Typography.Text>
                        </Row>
                    </div>
                </div>
            </div>
        )

    }


    const Api = () => {
        return (
            <div onClick={() => setStep(step + 1)} style={{ padding: '10px', height: '60px', border: "1px solid #ededed", borderRadius: '10px', alignItems: 'center', justifyContent: 'center', width: '100%' }} className={`d-flex flex-column hoverPrimaryBg`}>
                <div className="d-flex flex-row" style={{ alignItems: 'center' }}>
                    <div style={{ width: '100%' }}>
                        {/* <div className='d-flex' style={{ width: '60px', height: '60px', backgroundColor: '#e3ccfd' }}>
                            <AiOutlineApi style={{ color: '' }} />
                        </div> */}
                        <Row justify="center">
                            <Typography.Text style={{ textAlign: 'center', color: "#8666FF", }}>Conectar mi cuenta via API</Typography.Text>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }


    const handleApiConnection = async (v) => {
        try {
            setLoading(true)
            await SignedInternalAPIRequest({
                teamId: team?.id,
                billingAccountId: billingAccount?.id,
                secret: v.apiKeyLive,
                testSecret: v.apiKeyTest,
                accountId: v.accountId
            }, 'stripeManualConnect', auth.currentUser, {})
            message.success('Conexión exitosa')
            setLoading(false)
            getCurrentTeam(team?.id)
            logEvent(analytics, 'stripeCompletedAt', {
                teamId: team?.id,
                alias: team?.brand?.alias,
                completedAt: moment().valueOf(),
                dateISO: moment().toISOString(),
                method: 'API'
            })
            close()
        } catch (error) {
            setLoading(false)
            message.error(error.message ?? 'Ocurrió un error al crear la conexión')
        }
    }

    const ConectAPi = () => {
        return (
            <>

                <Form layout='vertical' onFinish={handleApiConnection}>
                    <Row style={{ margin: '20px 0px ' }} align='middle' >
                        <Typography.Title style={{ margin: '0px', padding: '0px', marginLeft: '5px' }} level={5}>Conecta tu cuenta via API</Typography.Title>
                        <Typography.Text type='secondary'>Cónoce más sobre como conectarte a Stripe via API <a href='https://helpcenter.gigstack.pro/es/articles/7888939-como-conectarte-a-stripe-por-api-keys' target='_bank'>aquí</a></Typography.Text>
                    </Row>
                    <Form.Item label="ID de la cuenta" name='accountId' rules={[{ required: true, message: 'Por favor ingrese su ID de cuenta' }, {
                        validator: (rule, value) => {
                            if (!value.startsWith('acct_')) {
                                return Promise.reject('ID de cuenta inválido, debe comenzar con acct_')
                            } else {
                                return Promise.resolve()
                            }
                        }
                    }]}>
                        <Input placeholder="Account Id" />
                    </Form.Item>
                    <Form.Item label="API Key Live" name='apiKeyLive' rules={[{
                        required: true,
                        message: 'Por favor ingrese su API Live',
                    }, {
                        validator: (rule, value) => {
                            if (!value.startsWith('sk_live')) {
                                return Promise.reject('API Key Live inválida, debe comenzar con sk_live')
                            } else {
                                return Promise.resolve()
                            }
                        }
                    }]}>
                        <Input placeholder="API Key Live" />
                    </Form.Item>
                    <Form.Item label="API Key Test" name='apiKeyTest' rules={[{
                        required: true,
                        message: 'Por favor ingrese su API Key Test',
                    }, {
                        validator: (rule, value) => {
                            if (!value.startsWith('sk_test')) {
                                return Promise.reject('API Key Test inválida, debe comenzar con sk_test')
                            } else {
                                return Promise.resolve()
                            }
                        }
                    }]}>
                        <Input placeholder="API Key Test" />
                    </Form.Item>
                    <Row justify='center' style={{ margin: '10px', marginTop: '20px' }} >
                        <Button style={{ flex: 1, marginRight: '5px', width: '50%' }} onClick={() => setStep(step - 1)}>Atrás</Button>
                        <Button loading={loading} type="primary" style={{ flex: 1, marginRight: '5px', width: '50%' }} htmlType='submit'>Conectar mi cuenta via API</Button>
                    </Row>
                </Form>
            </>
        )
    }

    const ConectStripe = () => {
        return (
            <Row gutter={12} style={{ width: '100%', marginTop: '10px' }}>
                <Col xl={12} >
                    <Api />
                </Col>
                <Col xl={12} >
                    <Conect />
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Modal
                visible={open}
                onCancel={close}
                closable={false}
                footer={null}
                width={window.innerWidth < 780 ? "90%" : "40%"}
                style={{ top: 20 }}
            >
                <div className="d-flex flex-column">
                    <Header />
                    <ToRenderByStep />
                </div>
            </Modal>

        </>
    );
}

export default ConectModal;