import { CloseOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, Col, Drawer, Form, Input, message, Row, Switch, Tabs, Typography } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import Select from 'react-select'
import { countryLabeledListWithCodeAlpha3 } from '../../app/functions/datasets';
import { generateCode, invoiceUsage, RemoveUndefined, taxRegimes } from '../../app/functions/helpers';
import { generalreactSelectStyles, reactSelectMultiStyles } from '../../app/functions/styles';
import CreatableSelect from 'react-select/creatable';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import PaymentLimitComponent from '../payments/PaymentLimitComponent';
import { useAuth } from 'reactfire';



function AddNewClientForm({ edit, close, userDocument, open }) {

    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const [loading, setloading] = useState(false)
    const [tab, settab] = useState('personal')
    const [defaults, setdefaults] = useState({ ...(edit?.defaults ?? {}) })

    const auth = useAuth();


    /**
     * It creates a new client in the database
     * @param v - The form values
     */
    const CreateContact = async (v) => {
        setloading(true)

        const client = {
            ...v,
            names: [{ displayName: v.name ?? edit?.name ?? '' }],
            emailAddresses: [{ value: v.email ?? edit?.email ?? '' }],
            owner: auth.currentUser?.uid,
            timestamp: moment().valueOf(),
            from: 'gigstack',
            phoneNumbers: v.phone ? [{ value: v?.phone ?? edit?.phone }] : [],
            legal_name: v.legal_name?.toUpperCase() ?? edit?.legal_name?.toUpperCase() ?? '',
            email: v.email ?? edit?.email ?? '',
            country: v.country?.code ?? 'MEX',
            defaults: defaults,
            metadata: {
                internalId: v.internalId ?? edit?.metadata?.internalId ?? null,
                clabe: v.clabe ?? edit?.metadata?.clabe ?? null
            },
            use: typeof v.use === 'string' ? v.use : v.use?.value ?? 'G03',
        }
        delete client?.limitDaysToPay
        delete client.avoidNextInvoices
        if (v.zip || v.country) {
            client.address = {
                zip: v.zip ?? edit?.address?.zip ?? '',
                address: v.address ?? edit?.address?.address ?? '',
                country: typeof v.country === 'string' ? v.country : v.country?.code ?? edit?.address?.country ?? 'MEX',
                countryName: v.country?.label ?? edit?.address?.countryName ?? 'Mexico',
            }
        }
        setloading(false)

        try {
            if (edit) {
                var clientData = {
                    ...client, timestamp: edit.timestamp,
                    bcc: v.bcc?.map((e) => e.value),
                    defaults: defaults

                }
                if (v.tax_system) {
                    clientData.tax_system = typeof v.tax_system === 'string' ? v.tax_system : v.tax_system?.value ?? '601'
                }
                await updateDoc(doc(getFirestore(), 'clients', edit.id), RemoveUndefined(clientData));
                message.success('Cliente actualizado');
                close()
            } else {
                const code = generateCode(10, 'client')
                await setDoc(doc(getFirestore(), 'clients', code), RemoveUndefined({ ...client, id: code, team: team?.id, billingAccount: billingAccount?.id, bcc: v.bcc?.map((e) => e.value) }));
                message.success('Cliente creado con éxito');
                close()
            }

            getCurrentTeam(team?.id)
        } catch (error) {
            setloading(false)
            message.error(error.message)
        }
    }


    var initialCountry = edit ? typeof edit?.address?.country === 'string' ? edit?.address?.country : edit?.address?.country?.code : 'MEX'

    const ClientPersonalInformation = () => {
        return (
            <Row gutter={{ xs: 10, md: 12, lg: 14 }} style={{ marginTop: '10px' }}>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px' }}>Información de contacto</Typography.Text>
                <div style={{ padding: '20px', borderRadius: '10px', backgroundColor: '#fafbfd' }}>
                    <Row gutter={{ xs: 10, md: 12, lg: 14 }}>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Nombre" name={"name"} rules={[{ required: true, message: 'Añade el nombre a tu cliente' }]}>
                                <Input placeholder='ej: Diego Rivera' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item style={{ margin: 0, padding: 0 }} label="Empresa" name={"company"} >
                                <Input placeholder='ej: PiedPiper' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Correo de tu cliente" name={"email"} rules={[{ type: 'email', required: false, message: 'Añade el correo a tu cliente' }]}>
                                <Input placeholder='ej: diego@correo.com' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Teléfono" name="phone" >
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item label="Correos extra" name="bcc" >
                                <CreatableSelect
                                    isMulti

                                    styles={reactSelectMultiStyles}
                                    noOptionsMessage={(v) => "Escribe un correo"}
                                    className="pro-select-input pro-select-input-multi"
                                    placeholder="Añadir correos"
                                    formatCreateLabel={(userInput) => `Añadir correo secundario "${userInput}"`}
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </div>


                <Typography.Text style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px', marginTop: '25px' }}>Información fiscal</Typography.Text>
                <div style={{ padding: '20px', borderRadius: '10px', backgroundColor: '#fafbfd' }}>
                    <Row gutter={{ xs: 10, md: 12, lg: 14 }}>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Razón social" name="legal_name" >
                                <Input className="pro-input" placeholder='Nombre completo de Diego' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Identificador tributario (RFC)?" name="rfc" >
                                <Input className="pro-input" placeholder='Identificador tributario o RFC' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item label="Régimen Fiscal" name="tax_system" >
                                <Select
                                    styles={generalreactSelectStyles}
                                    className="pro-select-input"
                                    //onChange={setSelectedOption}
                                    options={taxRegimes}
                                    isLoading={false}
                                    placeholder="Cuál es el régimen fiscal"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} >
                            <Form.Item label="País" name="country" >
                                <Select
                                    styles={generalreactSelectStyles}
                                    className="pro-select-input"
                                    //onChange={setSelectedOption}
                                    options={countryLabeledListWithCodeAlpha3.map((e) => {
                                        return {
                                            label: e.name,
                                            value: e.name,
                                            code: e.code
                                        }
                                    })}
                                    isLoading={false}
                                    placeholder="ej: País de Diego"

                                />
                            </Form.Item>
                        </Col>

                        <Col xs={12} >
                            <Form.Item label="Uso de CFDI" name="use" >
                                <Select
                                    styles={generalreactSelectStyles}
                                    className="pro-select-input"
                                    //onChange={setSelectedOption}
                                    options={invoiceUsage.map((e) => {
                                        return {
                                            label: e.label,
                                            value: e.value
                                        }
                                    })}
                                    isLoading={false}
                                    placeholder="Uso del CFDI"

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Dirección" name="address" >
                                <Input className="pro-input" placeholder='ej: Calle de diego #19' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Código postal" name="zip" >
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>

                    </Row>
                </div>
                <Row style={{ width: '100%', marginTop: '15px' }}>
                    <Col xs={24} lg={24} xl={24} md={24}>
                        <Typography.Text style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '10px', marginTop: '25px' }}>
                            Metadata
                        </Typography.Text>
                    </Col>
                    <Col xs={24} lg={24} xl={24} md={24}>
                        <div style={{ padding: '20px', borderRadius: '10px', backgroundColor: '#fafbfd', width: '100%' }}>
                            <Form.Item label="ID interno" name="internalId">
                                <Input className="pro-input" />
                            </Form.Item>
                            <Form.Item label="Clabe" name="clabe">
                                <Input className="pro-input" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Col xs={24} style={{ marginTop: '10px' }}>
                    <Form.Item label="Notas" name="notes" >
                        <Input.TextArea className="pro-input" />
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const ClientDefaults = () => {

        return (
            <Row gutter={{ xs: 10, md: 12, lg: 14 }} style={{ marginTop: '10px', width: '100%' }}>
                <Col xs={24} style={{ marginTop: '10px', marginBottom: '15px' }}>
                    <Typography.Text className='cLabel'>Emitir facturas automáticas para {edit ? `${edit?.company ?? edit?.name}` : 'este cliente'}</Typography.Text>
                    <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                        <Switch onChange={async (v) => {
                            setdefaults({ ...defaults, avoidNextInvoices: !v })
                        }} defaultChecked={!defaults?.avoidNextInvoices} />
                    </div>
                    <div className='d-flex flex-row' style={{ marginTop: '15px' }}>
                        <Checkbox disabled={!defaults?.avoidNextInvoices} checked={defaults?.invoiceableReceipts} onChange={(v) => {
                            setdefaults({ ...defaults, invoiceableReceipts: v.target.checked })
                        }} >
                            Crear recibos de venta facturables
                        </Checkbox>
                    </div>
                    <Typography.Text type='secondary' style={{ fontSize: '10px' }}>{defaults?.avoidNextInvoices ? '*Emitiremos recibos de venta que NO podrán ser facturados' : '*Emitiremos facturas o recibos de venta facturables para este cliente.'}</Typography.Text>
                </Col>
                <Col xs={24}>
                    <Typography.Text className='cLabel' >Días límite de pago / crédito</Typography.Text>
                    <div style={{ marginTop: '15px' }}></div>
                    <PaymentLimitComponent
                        updateState={(v) => setdefaults({ ...defaults, ...v })}
                        userDocument={userDocument}
                    />
                </Col>

            </Row>
        )
    }


    const ClientHeader = () => {
        return <Col xs={24} style={{ marginTop: '25px' }}>
            <div style={{ backgroundColor: '#fafbfd', paddingTop: '14px', paddingBottom: '0px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '10px' }}>
                <Row justify="space-between">
                    <Avatar size={34} src={edit?.photoURL}>{edit?.name?.substring(0, 1) ?? '+'}</Avatar>
                    <Row>
                        <Tabs onChange={(k) => { settab(k) }} defaultActiveKey={tab} style={{ margin: 0, padding: 0 }} type="card">
                            <Tabs.TabPane tab={<Row align="middle">
                                <UserOutlined /><Typography.Text>Información</Typography.Text>
                            </Row>} key="personal">
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<Row align="middle">
                                <SettingOutlined /><Typography.Text>Ajustes</Typography.Text>
                            </Row>} key="defaults">
                            </Tabs.TabPane>
                        </Tabs>
                    </Row>
                </Row>
            </div>
        </Col>
    }

    // console.log(edit?.tax_system)
    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} footer={null}>

            <Row>
                <Col xs={24}>
                    <Row justify="space-between" align="middle">
                        <Typography.Title level={5}>{edit ? 'Actualizar cliente' : 'Crear cliente'}</Typography.Title>
                        <div onClick={() => close()} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fafbfd', padding: '5px', borderRadius: '50%', height: '30px', width: '30px' }} className='d-flex clickable'>
                            <CloseOutlined />
                        </div>
                    </Row>
                </Col>
                <ClientHeader />
                <Form
                    initialValues={edit ? {
                        ...edit,
                        clabe: edit?.metadata?.clabe ?? '',
                        internalId: edit?.metadata?.internalId ?? '',
                        zip: edit?.address?.zip ?? edit?.zip ?? '',
                        use: edit.use && typeof edit.use === 'object' ? edit.use : edit.use ? {
                            label: invoiceUsage.find((e) => e.value === edit?.use?.value)?.label || edit?.use,
                            value: invoiceUsage.find((e) => e.value === edit?.use?.value)?.value || edit?.use,
                        } : { label: 'Gastos en general', value: 'G03' },
                        country: edit?.address?.country ? {
                            label: countryLabeledListWithCodeAlpha3.find((e) => e.code === (initialCountry ?? 'MEX') || e.name === (initialCountry ?? 'MEX'))?.name ?? edit?.address?.country,
                            value: countryLabeledListWithCodeAlpha3.find((e) => e.code === (initialCountry ?? 'MEX') || e.name === (initialCountry ?? 'MEX'))?.name ?? edit?.address?.country,
                            code: countryLabeledListWithCodeAlpha3.find((e) => e.code === (initialCountry ?? 'MEX') || e.name === (initialCountry ?? 'MEX'))?.code ?? edit?.address?.country
                        } : { label: 'Mexico', value: 'Mexico', code: 'MEX' },
                        tax_system: typeof edit?.tax_system === 'string' ? taxRegimes.find((e) => e.value === edit?.tax_system) :
                            edit?.tax_system ? {
                                label: taxRegimes.find((e) => e.value === edit?.tax_system?.value)?.label || edit?.tax_system,
                                value: taxRegimes.find((e) => e.value === edit?.tax_system?.value)?.value || edit?.tax_system,
                            } : { label: 'General de Ley Personas Morales', value: '601' },
                        address: edit?.address?.address ?? '',

                        bcc: edit?.bcc?.map((e) => {
                            return { label: e, value: e }
                        })
                    } : {}} layout="vertical" onFinish={CreateContact} style={{ width: '100%' }}>
                    {tab === 'personal' ? <ClientPersonalInformation /> : <ClientDefaults />}

                    <Col xs={24} style={{ marginBottom: '50px' }}>
                        <Row justify="center">
                            <Button htmlType="submit" loading={loading} style={{ width: '100%' }} type="primary">
                                {edit ? 'Actualizar cliente' : 'Crear cliente'}
                            </Button>
                        </Row>
                    </Col>
                </Form>
            </Row>
        </Drawer>
    )

    // return (
    //     <Row>
    //         <Col xs={24}>
    //             <Row justify="space-between">
    //                 <Typography.Title level={4}>{edit ? 'Actualizar cliente' : 'Crear cliente'}</Typography.Title>
    //                 <CloseOutlined onClick={() => close()} />
    //             </Row>
    //         </Col>
    //         <Col xs={24}>
    //             <Form
    //                 initialValues={edit ? {
    //                     ...edit,
    //                     country: edit?.address?.country ? {
    //                         label: countryLabeledListWithCodeAlpha3.find((e) => e.code === (initialCountry ?? 'MEX') || e.name === (initialCountry ?? 'MEX'))?.name ?? edit?.address?.country,
    //                         value: countryLabeledListWithCodeAlpha3.find((e) => e.code === (initialCountry ?? 'MEX') || e.name === (initialCountry ?? 'MEX'))?.name ?? edit?.address?.country,
    //                         code: countryLabeledListWithCodeAlpha3.find((e) => e.code === (initialCountry ?? 'MEX') || e.name === (initialCountry ?? 'MEX'))?.code ?? edit?.address?.country
    //                     } : { label: 'Mexico', value: 'Mexico', code: 'MEX' },
    //                     address: edit?.address?.address ?? '',
    //                     bcc: edit?.bcc?.map((e) => {
    //                         return { label: e, value: e }
    //                     })
    //                 } : {}} layout="vertical" onFinish={CreateContact}>
    //                 <Col xs={24}>
    //                     <Tabs onChange={(k) => { settab(k) }} defaultActiveKey={tab} type="card">
    //                         <Tabs.TabPane tab={<Row align="middle">
    //                             <UserOutlined /><Typography.Text>Información</Typography.Text>
    //                         </Row>} key="personal">
    //                             <ClientPersonalInformation />
    //                         </Tabs.TabPane>

    //                         <Tabs.TabPane tab={<Row align="middle">
    //                             <SettingOutlined /><Typography.Text>Ajustes</Typography.Text>
    //                         </Row>} key="defaults">
    //                             <ClientDefaults />
    //                         </Tabs.TabPane>
    //                     </Tabs>
    //                 </Col>
    //                 <Col xs={24} style={{ marginBottom: '50px' }}>
    //                     <Row justify="center">
    //                         <Button htmlType="submit" loading={loading} style={{ width: '100%' }} type="primary">
    //                             {edit ? 'Actualizar cliente' : 'Crear cliente'}
    //                         </Button>
    //                     </Row>
    //                 </Col>
    //             </Form>
    //         </Col>
    //     </Row>
    // );
}

export default AddNewClientForm;