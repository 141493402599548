import { UserOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd';
import { logEvent } from 'firebase/analytics';
import { arrayUnion, doc, getFirestore, setDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useState } from 'react'
import Select from 'react-select';
import { useAnalytics, useAuth } from 'reactfire';
import { generalreactSelectStyles } from '../../../app/functions/styles';
// import BillingAccountContext from '../../../context/billingaccountcontext/BillingAccountContext';
import UserContext from '../../../context/usercontext/UserContext';

function PersonalInformation({ step, setStep }) {
    const fs = getFirestore();
    const auth = useAuth();
    const analytics = useAnalytics()
    const [loading, setLoading] = useState(false);

    // const { billingAccount } = useContext(BillingAccountContext)
    const { user, getUserData } = useContext(UserContext)


    const SavePersonalInformation = async (v) => {
        try {
            setLoading(true)
            await setDoc(doc(fs, `users/${auth.currentUser.uid}`), {
                ...(user ?? {}),
                ...v,
                phone: v.phone ?? null,
                name: v?.firstName + ' ' + v.lastName,
                uid: auth.currentUser.uid,
                onboarded: arrayUnion('personalInformation'),
                v: 'alpha',
                timestamp: moment().valueOf(),
                hearAbout: v.hearAbout ?? null,
                needCompleteProfile: false,
            }, { merge: true });
            getUserData(auth.currentUser.uid)
            logEvent(analytics, 'personalInformationSetUp', {});
            setLoading(false)
            setStep(step + 1)

        } catch (error) {
            message.error(error.message)
            setLoading(false)
        }

    }





    return (
        <Form initialValues={{ email: auth?.currentUser?.email, ...user }} layout="vertical" onFinish={SavePersonalInformation}>
            <Row justify="space-between">
                <p className='russo primary'>gigstack pro</p>
                <Typography.Text type="secondary" style={{ fontSize: '10px' }} className="secondary">2/4</Typography.Text>
            </Row>

            <Row align="middle">
                <UserOutlined className='primary' style={{ marginRight: '20px', fontSize: '30px' }} />
                <div className="d-flex flex-column">
                    <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>Creemos tu cuenta personal</Typography.Title>
                    <Typography.Text type="secondary">Tu información personal nos ayudará a personalizar tu cuenta, no te preocupes, en el siguiente paso añadiremos la información de la empresa.</Typography.Text>
                </div>

            </Row>
            <Divider />

            <Row style={{ width: '100%', marginTop: '30px' }} gutter={{ xs: 12, md: 12 }}>
                <Col xs={24} md={12}>
                    <Form.Item name="firstName" label="¿Cuál es tu nombre?" rules={[{ required: true, message: 'Por favor añade tu nombre.' }]}>
                        <Input placeholder="Tu nombre" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="¿Cuales son tus apellidos?" name="lastName" rules={[{ required: true, message: 'Por favor añade tu apellido.' }]}>
                        <Input placeholder="Tu(s) apellido(s)" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="¿Cuál es es tu correo?" name="email" rules={[{ required: true, type: 'email', message: 'Por favor añade tu correo.' }]}>
                        <Input placeholder="correo@electronico.com" />
                    </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item label="¿Cuál es tu número de teléfono?" name="phone" rules={[{ required: false, message: 'Por favor añade tu teléfono.' }]}>
                        <Input placeholder="+52" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} >
                    <Form.Item label="¿Dónde escuchaste sobre nosotros?" name="hearAbout">
                        <Select
                            styles={generalreactSelectStyles}
                            isMulti
                            className="pro-select-input"
                            options={[
                                { label: 'Redes Sociales', value: 'redesSociales' },
                                { label: 'Blog Post', value: 'blogPost' },
                                { label: 'Revista de negocios', value: 'revistaNegocios' },
                                { label: 'Conocidos', value: 'conocidos' },
                                { label: 'Radio / Televisión', value: 'radioTelevision' },
                                { label: 'Otros medios', value: 'Otros' },
                            ]}
                            isLoading={false}
                            placeholder="¿Dónde escuchaste sobre nosotros?"

                        />
                    </Form.Item>
                </Col>


                <Col xs={24}>
                    <Row justify="end">
                        <Button aria-hidden style={{ marginRight: '15px' }} onClick={() => {
                            setStep(step - 1);
                        }}>Iniciar</Button>
                        <Button loading={loading} type="primary" htmlType="submit">Continuar</Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default PersonalInformation;

