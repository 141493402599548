import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, message, Modal, Row, Space, Switch, Typography } from 'antd';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useAuth } from 'reactfire';
import HelperCard from '../cards/HelperCard';
import TeamContext from '../../context/teamcontext/TeamContext';
import stripeLogo from '../../assets/images/stripe_icon.png';
import { handleStripeLink } from '../settings/PaymentOptions';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { useForm } from 'antd/lib/form/Form';

const StripeModal = ({ open, close, userDocument }) => {

    const { team } = useContext(TeamContext);
    const [form] = useForm()
    const ref = doc(getFirestore(), "teams", team?.id)

    const { billingAccount } = useContext(BillingAccountContext)
    const auth = useAuth()
    const [onlyAutomaticInvoicingOnTestMode, setoOnlyAutomaticInvoicingOnTestMode] = useState(team?.stripe?.onlyAutomaticInvoicingOnTestMode ?? false)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.stripe?.automaticInvoicing ?? false)
    const [loading, setLoading] = useState(false)

    const connectStripe = async () => {
        if (loading === "stripe") return
        try {
            setLoading("stripe")
            const link = await handleStripeLink({ userDocument, auth, teamId: team.id, billingAccountId: billingAccount.id, team })
            return window.open(link.url, '_blank')
        } catch (error) {
            message.error(error?.message ?? 'Ocurrio un error al contectar la cuenta.')
        } finally {
            setLoading(null)
        }
    }

    const saveNewKeys = (v) => {
        if (loading === "keys") return
        setLoading("keys")
        updateDoc(ref, {
            stripe: {
                ...team?.stripe,
                testPublicKey: v.testPublicKey,
                livePublicKey: v.livePublicKey,
            }
        }, { merge: true }).then(() => {
            message.success('Se guardaron los cambios.')
            close({ haveChange: (team?.stripe?.livePublicKey !== v.livePublicKey || team?.stripe?.testPublicKey !== v.testPublicKey) })
        }).catch((error) => {
            message.error('Ocurrio un error al guardar los cambios.')
        }).finally(() => {
            setLoading(null)
        })
    }

    return (
        <Modal visible={open} onCancel={() => close({ haveChange: (automaticInvoicing !== team?.stripe?.automaticInvoicing || onlyAutomaticInvoicingOnTestMode !== team?.stripe?.onlyAutomaticInvoicingOnTestMode) })} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <Row justify="space-between">
                <Typography.Title level={4}>Configura tu facturacion automática</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close({ haveChange: (automaticInvoicing !== team?.stripe?.automaticInvoicing || onlyAutomaticInvoicingOnTestMode !== team?.stripe?.onlyAutomaticInvoicingOnTestMode) })} />
            </Row>
            <Typography.Text type="secondary" >Edita tu configuración de stripe <span onClick={connectStripe} target="_blank" className='primary clickable' rel="noreferrer">{loading === "stripe" ? 'redirigiendo...' : 'aquí'}</span>.</Typography.Text>
            <HelperCard openIntercom={true} title={'¿Necesitas ayuda?'} description="Estaremos encantados de ayudarte!." supportImageUrl={stripeLogo} />
            <div className="d-flex flex-column">
                <Row align="middle" style={{ marginTop: '25px', marginBottom: '35px' }}>
                    <Space direction='vertical'>
                        <Typography.Text style={{ fontWeight: 500 }}>Activar facturación automática</Typography.Text>
                        <Row style={{ marginTop: '5px' }} align="middle">
                            {<Switch defaultChecked={automaticInvoicing} checked={automaticInvoicing} onChange={(v) => {
                                updateDoc(ref, {
                                    stripe: {
                                        ...team?.stripe,
                                        automaticInvoicing: v,
                                        onlyAutomaticInvoicingOnTestMode: v ? onlyAutomaticInvoicingOnTestMode : false
                                    },
                                    integrationsSetup: team?.integrationsSetup?.filter(int => int !== 'automaticInvoicing')
                                })
                                message.success('Datos guardados')
                                setAutomaticInvoicing(v)
                            }} />}
                            <Typography.Text type="secondary" style={{ fontSize: '13px', flex: 1, marginLeft: '15px' }}>Siempre que tengas activada la automatización, realizaremos una factura por cada pago que recibas.</Typography.Text>
                        </Row>
                        {automaticInvoicing && <><Divider />
                            <Typography.Text style={{ fontWeight: 500 }}>Facturación automática solo en pagos de prueba</Typography.Text>
                            <Row align="middle">
                                {<Switch defaultChecked={onlyAutomaticInvoicingOnTestMode} checked={onlyAutomaticInvoicingOnTestMode} onChange={(v) => {
                                    updateDoc(ref, {
                                        stripe: {
                                            ...team?.stripe,
                                            automaticInvoicing,
                                            onlyAutomaticInvoicingOnTestMode: v
                                        },
                                        integrationsSetup: team?.integrationsSetup?.filter(int => int !== 'automaticInvoicing')
                                    })
                                    message.success('Datos guardados')
                                    setoOnlyAutomaticInvoicingOnTestMode(v)
                                }} />}
                                <Typography.Text type="secondary" style={{ fontSize: '13px', flex: 1, marginLeft: '15px' }}>Si activas la facturación automática solo para pagos de prueba, solo automatizaremos los pagos que recibamos en modo test</Typography.Text>
                            </Row></>}
                    </Space>
                </Row>
            </div >
            {team.stripe.connectType === 'api' && <div className="d-flex flex-column">
                <Divider />
                <Form layout='vertical' form={form} onFinish={saveNewKeys} initialValues={{
                    livePublicKey: team?.stripe?.livePublicKey ?? null,
                    testPublicKey: team?.stripe?.testPublicKey ?? null
                }}>
                    <Form.Item label="Llave pública live" name="livePublicKey" rules={[{ required: true, message: 'Es necesario ingresar la llave pública live' }, {
                        validator: (rule, value) => {
                            if (!value.startsWith('pk_live_')) {
                                return Promise.reject('Llave pública inválida, debe comenzar con pk_live_')
                            } else {
                                return Promise.resolve()
                            }
                        }
                    }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Llave pública test" name="testPublicKey" rules={[{ required: true, message: 'Es necesario ingresar la llave pública test' }, {
                        validator: (rule, value) => {
                            if (!value.startsWith('pk_test_')) {
                                return Promise.reject('Llave pública inválida, debe comenzar con pk_test_')
                            } else {
                                return Promise.resolve()
                            }
                        }
                    }]} >
                        <Input />
                    </Form.Item>
                    <Row align='center' style={{ marginTop: '15px' }}>
                        <Button type="primary" htmlType='submit' loading={loading === 'keys'}>
                            Guardar llaves
                        </Button>
                    </Row>
                </Form>
            </div>}
        </Modal >)
}

export default StripeModal;