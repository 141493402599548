import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

export const useTeamsData = ({ uid }) => {
    const fs = useFirestore();
    const q = query(collection(fs, "teams"), where("membersIds", "array-contains", uid));

    const {
        status,
        data: teams,
    } = useFirestoreCollectionData(q);



    const loaded = status === "success";


    return {
        loaded,
        teams,
    }
}