import { CloudDownloadOutlined, CopyOutlined, FundOutlined, LeftOutlined, LoadingOutlined, OrderedListOutlined, UserOutlined, } from '@ant-design/icons';
import { Button, Col, message, Row, Segmented, Space, Tag, Tooltip, Typography } from 'antd';
import { collection, doc, getFirestore, orderBy, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { useAuth, useFirestoreDocData } from 'reactfire';
import { SignedInternalAPIRequest } from '../functions/APIRequests';
import { getItemsAmounts, InvoiceStatusReadable, paymentByStatus, paymentIntentsStatusMessage, returnColorByPaymentStatus, ReturnCorrectedPayment, returnCurrencyValue, returnTextColorByPaymentStatus, SearchParamInURL } from '../functions/helpers';
import { Column } from '@ant-design/plots';
import DefaultTableItems from '../../widgets/tables/DefaultTableItems';
import TableTitle from '../../widgets/tables/TableTitle';
import PaymentRelations from '../../widgets/relations/PaymentsRelations';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import InvoicesRelations from '../../widgets/relations/InvoicesRelations';
import TeamContext from '../../context/teamcontext/TeamContext';
import { IoSettingsOutline } from 'react-icons/io5';
import ClientDefaultsModal from '../../widgets/clients/ClientDefaultsModal';
import { DownloadFilesModal } from '../../widgets/modals/DownloadFiles';

/**
    * It downloads an excel file from a signed internal API request
    */
export const DownloadXLSX = async ({ endpoint = `gigstackpro.pro.cleaned_invoices_view`, type = "facturas", setDownload, auth }) => {
    try {
        setDownload(type)
        await SignedInternalAPIRequest({
            clientId: SearchParamInURL('id') ?? null,
        }, `exportFiles/v1/${type}?clientId=${SearchParamInURL('id')}`, auth.currentUser, { isFile: true, fileName: `${type}-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
        setDownload(false)

    } catch (error) {
        message.error('Error al descargar el archivo')
        setDownload(false)
        throw error
    }
}


function ClientDetails({ userDocument, changeRender }) {
    const { team } = useContext(TeamContext)
    const fs = getFirestore()
    const ref = doc(fs, 'clients', SearchParamInURL('id'))
    const invoicesRef = query(collection(fs, `invoices`), where('team', '==', team?.id ?? ''), where('clientID', '==', SearchParamInURL('id')), orderBy('timestamp', 'desc'));
    const paymentsRef = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), where('clientID', '==', SearchParamInURL('id')), orderBy('timestamp', 'desc'));
    //eslint-disable-next-line
    const { status, data: clientData } = useFirestoreDocData(ref)

    const auth = useAuth()

    const [downloadFile, setDownloadFile] = useState(null)
    const [paymentsViewType, setPaymentsViewType] = useState('chart')
    const [invoicesViewType, setInvoicesViewType] = useState('chart')
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(true)
    const [filesModal, setFilesModal] = useState(false)


    const MainClientsQuery = async () => {
        if (data.length > 0) return
        setloading(true)
        try {
            const res = await SignedInternalAPIRequest({}, `appInsights/v1/client/${SearchParamInURL("id")}`, auth.currentUser, {}, 'GET')
            setdata(res)

            setloading(false)
            message.success('Información actualizada')
        } catch (error) {
            message.error(error.message)
            setloading(false)
        }
    }
    const [downloadFileForClient, setDownloadFileForClient] = useState(null)
    const DownloadXLSXForClient = async ({ endpoint = ``, type = "forClients" }) => {
        try {
            setDownloadFileForClient('forClients')
            await SignedInternalAPIRequest({
                clientId: SearchParamInURL('id')

            }, `exportFiles/v1/collectionPayments`, auth.currentUser, { isFile: true, fileName: `detalleCliente-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
            setDownloadFileForClient(false)

        } catch (error) {
            message.error(error?.message ?? 'Error al descargar el archivo')
            setDownloadFileForClient(false)
        }
    }

    useEffect(() => {
        MainClientsQuery()
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * It changes the render state to clients and then replaces the URL with the current pageActive value
     * @returns the window.history.replaceState() function.
     */
    const Close = () => {
        changeRender('clients')
        return window.history.replaceState(null, `tab`, `/clients?pageActive=${SearchParamInURL('pageActive') ?? '1'}`)
    }

    /**
     * > We take the payments data, we add a new field called `status` to each payment, and then we pass
     * the data to the `DualAxesConfig` function
     * @returns A Column component with a DualAxesConfig object as props.
     */
    const LineInTime = () => {

        let paymentsData = data.filter(i => i.category === 'payment' && i.livemode === true).map((a) => {
            return {
                month: Number(a.year.split('-')[1]),
                ...a
            }
        })

        paymentsData = paymentsData.sort((a, b) => {
            return a.month - b.month
        })
        let colors = (paymentsData.map((p) => returnTextColorByPaymentStatus(p)))
        colors = colors.filter((item, index) => colors.indexOf(item) === index)
        const config = {
            data: paymentsData,
            isStack: true,
            xField: 'year',
            yField: 'value',
            seriesField: 'status',
            color: colors,
            legend: {
                position: 'top',
                itemName: {
                    style: {
                        fill: '#000',
                    },

                    formatter: (seriesField) => {
                        return paymentIntentsStatusMessage({ status: seriesField });
                    }
                }

            },
            tooltip: {
                formatter: (data) => {
                    return { name: paymentByStatus(data.status), value: `${returnCurrencyValue(data.value)}` };
                },
            },
            label: {
                position: 'middle',
                color: paymentsData.map(p => {

                    return returnTextColorByPaymentStatus(p)
                }),
                formatter: (v) => {
                    return returnCurrencyValue(v.value)
                },
                layout: [
                    {
                        type: 'interval-adjust-position',
                    },
                    {
                        type: 'interval-hide-overlap',
                    },
                    {
                        type: 'adjust-color',
                    },
                ],
            },
        };

        return <Column {...config} />

        // return <Column {...DualAxesConfig({ colorField: 'yearmonth', data: processesPayments })} />


    }

    const PaymentsAndInvoicesBarChart = () => {

        let paymentsAndInvoices = data.filter(pi => pi.livemode === true && (pi.status === 'succeeded' || pi.status === 'valid' || pi.status === 'pending' || pi.status === 'requires_payment_method' || pi.status === 'open'))

        paymentsAndInvoices = paymentsAndInvoices?.sort((a, b) => Number(a.year.split('-')[0] + a.year.split('-')[1]) - Number(b.year.split('-')[0] + b.year.split('-')[1]))

        paymentsAndInvoices = paymentsAndInvoices?.map((a) => {
            let newStatus = null
            if (a.status === 'valid') {
                newStatus = 'invoices'
            } else if (a.status === 'succeeded') {
                newStatus = 'payments_paid'
            } else {
                newStatus = 'payments_pending'
            }
            return {
                ...a,
                newStatus
            }
        })

        const config = {
            data: paymentsAndInvoices,
            isGroup: true,
            xField: 'year',
            yField: 'value',
            seriesField: 'newStatus',
            legend: {
                position: 'top',
                itemName: {
                    style: {
                        fill: '#000',
                    },

                    formatter: (seriesField) => {
                        if (seriesField === 'invoices') return 'Facturas'
                        if (seriesField === 'payments_paid') return 'Pagos completos'
                        return 'Pendientes de pago'
                    }
                }

            },

            tooltip: {
                formatter: (data) => {
                    return { name: data.newStatus === 'invoices' ? 'Facturas' : data.newStatus === 'payments_paid' ? 'Pagos completos' : 'Pagos pendientes', value: `${returnCurrencyValue(data.value)}` };
                },
            },
            statistic: {
                title: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                    customHtml: () => {
                        return `<div class="d-flex flex-column">
                        <p style="font-size:20px; padding:0px 10px; margin-bottom:10px;">Pagos</p>
                        </div>`
                    },
                },

                content: {
                    offsetY: 4,
                    style: {
                        fontSize: '32px',
                    },
                    customHtml: (container, view, datum, data) => {
                        return `<p style="color:#8666FF; font-size:15px;">abc</p>`
                    },
                },
            },

            label: {
                formatter: (v) => {
                    return returnCurrencyValue(v.value)
                }
            }


            //color: ['#1ca9e6', '#f88c24'],
            // marginRatio: 0.1,

        };
        return <Column {...config} />;
    }


    const downloadAccountBalance = async () => {
        try {
            setDownloadFile('accountBalance')
            const resp = await SignedInternalAPIRequest({ clientId: clientData.id, teamId: team.id }, "generateClientAccountBalance", auth.currentUser, { isFile: true, fileName: `${clientData?.rfc ?? "Balance"}-${moment().format('DD/MM/YYYY')}`, fileExtension: 'pdf' }, 'POST')
            console.log("RESP: ", resp)

            setDownloadFile(false)
        } catch (error) {
            console.log("Error: ", error)
            message.error('Error al descargar el archivo')
            setDownloadFile(false)
        }
    }



    /**
     * It returns a column with a row that contains a row with a left arrow icon and a title, and a
     * space with two buttons
     * @returns A React component
     */

    const [addDefaults, setAddDefaults] = useState(false)
    const Header = () => {
        return <Col xs={24}>

            <DownloadFilesModal open={filesModal} close={() => setFilesModal(false)} data={{ client: clientData, type: 'invoices', readableType: 'facturas' }} />

            {addDefaults && <Suspense>

                <ClientDefaultsModal open={addDefaults} close={() => setAddDefaults(false)} client={clientData} />
            </Suspense>}
            <div style={{}} className="d-flex flex-column">
                <Row justify="space-between" align="middle">
                    <Row align="middle">
                        <LeftOutlined onClick={Close} />
                        <Typography.Title style={{ margin: 0, padding: 0, marginLeft: '10px' }} level={3}>{clientData?.name}</Typography.Title>
                    </Row>
                    <Space>
                        <Button onClick={() => {
                            downloadAccountBalance()
                            // DownloadXLSX({ endpoint: `gigstackpro.pro.cleaned_payments_view`, type: "payments" })

                        }} disabled={downloadFile === 'accountBalance' || loading} >
                            <Row align="middle">
                                {downloadFile === 'accountBalance' ? <LoadingOutlined /> : <UserOutlined />}
                                <Typography.Text style={{ marginLeft: '5px' }}>Balance</Typography.Text>
                            </Row>
                        </Button>
                        <div style={{ textAlign: 'right' }}>
                            <Button onClick={DownloadXLSXForClient} disabled={downloadFileForClient === 'accountBalance' || loading}><Row align="middle">
                                {downloadFileForClient === 'forClients' ? <LoadingOutlined /> : <CloudDownloadOutlined />}
                                <Typography.Text style={{ marginLeft: '5px' }}>Estado de cuenta</Typography.Text>
                            </Row></Button>
                        </div>
                        <Button onClick={() => {
                            DownloadXLSX({ endpoint: `gigstackpro.pro.cleaned_payments_view`, type: "payments", setDownload: (v) => setDownloadFile(v), auth })

                        }} disabled={downloadFile === 'payments' || loading} >
                            <Row align="middle">
                                {downloadFile === 'payments' ? <LoadingOutlined /> : <CloudDownloadOutlined />}
                                <Typography.Text style={{ marginLeft: '5px' }}>Descargar pagos</Typography.Text>
                            </Row>
                        </Button>
                        <Button onClick={() => {
                            // DownloadXLSX({ endpoint: `gigstackpro.pro.cleaned_invoices_view`, type: "invoices" })
                            setFilesModal(true)
                            console.log("CLIENT DATA: ", clientData)
                        }} disabled={downloadFile === 'invoices' || loading} >
                            <Row align="middle">
                                {downloadFile === 'invoices' ? <LoadingOutlined /> : <CloudDownloadOutlined />}
                                <Typography.Text style={{ marginLeft: '5px' }}>Descargar facturas</Typography.Text>
                            </Row>
                        </Button>
                        <Button shape="circle" onClick={() => setAddDefaults(true)} icon={<IoSettingsOutline />} />
                    </Space>
                </Row>
                <Row justify="start" style={{ marginLeft: '25px' }}>
                    <Typography.Text type="secondary">{clientData?.email}</Typography.Text>
                </Row>
            </div>
        </Col>
    }

    /**
     * It takes an invoice object as a parameter and returns a tag with the invoice status
     * @returns A tag with a row inside of it.
     */
    const InvoiceStatusTag = ({ invoice }) => {
        const cancelling = invoice.cancellation_status !== 'none';
        const status = invoice.status;
        var readableStatus = InvoiceStatusReadable(status);
        var statusColor = !invoice.livemode ? '#E6BEFF' : readableStatus === 'Válida' ? '#CCF6DB' : (readableStatus === 'Inválida') ? '#FEE9E9' : (readableStatus === 'Cancelada' || cancelling) ? '#FEE9E9' : '#CCF6DB';
        var textColor = !invoice.livemode ? '#876ee4' : readableStatus === 'Válida' ? '#019049' : (readableStatus === 'Inválida') ? '#F15B5B' : (readableStatus === 'Cancelada' || cancelling) ? '#F15B5B' : '#000000';

        return <Tag style={{ backgroundColor: statusColor, border: 'none' }}>
            <Row align="middle">

                <Typography.Text style={{ marginLeft: '0px', color: textColor }}>{invoice.livemode ? readableStatus : 'Sin timbrar'}</Typography.Text>
            </Row>
        </Tag>

    }

    const ClientStatisticsCard = ({ title, total, legend, customColor = '#333' }) => {
        return <div className="d-flex flex-column">
            <Typography.Title level={5}>{title}</Typography.Title>
            <div className='tableContainer d-flex flex-column' style={{ alignItems: 'center', justifyContent: 'start', minHeight: '125px' }}>
                {loading ? <LoadingOutlined /> : <Typography.Title style={{ color: customColor }} level={4}>{returnCurrencyValue(total ?? 0)} MXN</Typography.Title>}
                <Typography.Text type="secondary">{legend}</Typography.Text>
            </div>
        </div>
    }

    const StatisticsCardsContainer = () => {

        const totalPayments = data.filter(i => i.category === 'payment' && i.status === 'succeeded' && i.livemode === true).map(p => p.value).reduce((a, b) => a + b, 0)
        const payemntsLegend = `Has solicitado ${returnCurrencyValue(totalPayments ?? 0)} en pago${totalPayments > 1 ? 's' : ''}`

        const totalInvoices = data.filter(i => i.status === 'valid' && i.category === 'invoice' && i.livemode === true).map(p => p.value).reduce((a, b) => a + b, 0)
        const invoicesLegend = `Has emitido facturas por un total de ${returnCurrencyValue(totalInvoices ?? 0)} MXN`

        const totalPendingPayments = data.filter(i => i.category === 'payment' && i.livemode === true && (i.status === 'open' || i.status === 'requires_payment_method' || i.status === 'requires_action' || i.status === 'pending')).map(p => p.value).reduce((a, b) => a + b, 0)
        const pendingPaymentsLegend = `Tienes ${returnCurrencyValue(totalPendingPayments)} en pagos pendientes`

        const difference = (totalPayments - totalInvoices)
        const differenceLegend = difference > 0 ? `Emite una factura por ${returnCurrencyValue(difference)} para igualar los pagos` : difference < 0 ? `Solicita un pago por ${returnCurrencyValue(difference < 0 ? difference * (-1) : difference)} para igualar las facturas` : `Todo en orden`

        return <Row justify='center' gutter={{ xs: 12, md: 15, lg: 20 }}>
            <Col xs={12} lg={6}>
                <ClientStatisticsCard title='Total de pagos' total={totalPayments} legend={payemntsLegend} />
            </Col>
            <Col xs={12} lg={6}>
                <ClientStatisticsCard title='Total de facturas' total={totalInvoices} legend={invoicesLegend} />
            </Col>
            <Col xs={12} lg={6}>
                <ClientStatisticsCard title="Pagos pendientes" total={totalPendingPayments} legend={pendingPaymentsLegend} customColor='' />
            </Col>
            <Col xs={12} lg={6}>
                <ClientStatisticsCard title="Diferencia" total={difference < 0 ? difference * (-1) : difference} legend={differenceLegend} customColor={difference === 0 ? '#cecece' : '#dd7f49'} />
            </Col>
        </Row>
    }

    const paymentColumns = [{
        title: <TableTitle title="Estado" />,
        dataIndex: 'status',

        width: '10%',
        ellipsis: true,
        sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
        render: (text, record) => {

            return <Tooltip title={paymentIntentsStatusMessage(record)}>
                <Tag className="statusTag" color={returnColorByPaymentStatus(record)} style={{ color: returnTextColorByPaymentStatus(record), borderRadius: '10px', opacity: 0.7 }}>{paymentIntentsStatusMessage(record).charAt()}</Tag>
            </Tooltip>
        }
    },
    {
        title: <TableTitle title="Total" />,
        dataIndex: 'amount',

        sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),

        render: (text, record) => {
            const amounts = record.paidIn === 'bank' ? getItemsAmounts(record.internalItems, (record.paidIn === 'bank')) : getItemsAmounts(record.items, (record.paidIn === 'bank'))
            console.log("AMOUNTS: ", amounts)
            const total = amounts.total
            const totalString = amounts.totalString
            return <Tooltip title={record.exchangeRate ? `${returnCurrencyValue((total) / record.exchangeRate)} mxn` : null}>
                <Typography.Text className="" type="secondary" ellipsis>{totalString} {record.currency}</Typography.Text>
            </Tooltip>
        }
    },
    {
        title: <TableTitle title="Creado" />,
        dataIndex: 'timestamp',

        sorter: (a, b) => ((a?.rfc).localeCompare(b?.rfc)),
        render: (text, record) => {

            return <Typography.Text type="secondary" >{moment(record.timestamp).format('DD MMM YYYY HH:mm')}</Typography.Text>
        }
    },
    {
        title: <TableTitle title="Fecha de pago" />,
        dataIndex: 'succeededTimestamp',
        key: 'succeededTimestamp',
        sorter: (a, b) => moment(ReturnCorrectedPayment(a).succeededTimestamp).isBefore(moment(moment(ReturnCorrectedPayment(b).succeededTimestamp))) ? 1 : -1,
        render: (text, record) => {
            return <Typography.Text type="secondary"  >{record.status === 'succeeded' ? moment(ReturnCorrectedPayment(record).succeededTimestamp).format('DD/MM/YY HH:mm') : ''}</Typography.Text>
        }
    },
    // {
    //     title: <TableTitle title="Link" />,
    //     dataIndex: 'actions',
    //     key: 'actions',
    //     width: '10%',
    //     render: (text, record) => {

    //         return <Row>
    //             {record.shortURL && <Typography.Text type="secondary" copyable={{ text: record.shortURL, icon: <LinkOutlined /> }}></Typography.Text>}
    //         </Row>


    //     }
    // },
    {
        title: <TableTitle title="Facturas" />,
        dataIndex: 'invoices',
        key: 'invoices',

        render: (text, record) => {

            return <InvoicesRelations custom={true} items={record?.items} collection="payments" elementType="payment" changeState={(i) => {

            }} client={record?.client} item={{ ...record, invoices: record.invoices }} id={record?.fid} userDocument={userDocument} readable="pago" />


        }
    },

    {
        title: <TableTitle title="" />,
        dataIndex: 'actions',
        render: (text, record) => {
            return <Space>
                {record?.shortURL && <Typography.Text copyable={{
                    icon: <CopyOutlined />,
                    tooltips: ['Copiar', 'Copiado'],
                    text: record?.shortUrl ?? record?.shortURL ?? ''
                }}></Typography.Text>}
            </Space>
        }
    },
    ]
    if (team?.defaults?.showRecurringEventNumber) {
        paymentColumns.unshift({
            title: <TableTitle title="# Pago" />,
            dataIndex: 'recurringEventNumber',
            width: '15%',
            ellipsis: true,
            sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
            render: (text, record) => {
                return <div>
                    <Typography.Text>{text}</Typography.Text>
                </div>
            }
        })
    }

    /* A React component that is rendering a page with a table and a chart. */
    return (
        <Row justify='center'>

            <Header />

            <Col xs={24} style={{ marginTop: '50px' }}>
                <Row gutter={{ xs: 12, md: 15, lg: 20 }}>
                    <Col xs={24} style={{ marginBottom: '40px' }}>
                        <StatisticsCardsContainer />
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="d-flex flex-column">
                            <Typography.Title level={5}>Estado de los pagos</Typography.Title>
                            <Segmented
                                onChange={(v) => {
                                    setPaymentsViewType(v)
                                }}
                                options={[
                                    {
                                        label: 'Gráfico',
                                        value: 'chart',
                                        icon: <FundOutlined />,
                                    },
                                    {
                                        label: 'Lista',
                                        value: 'list',
                                        icon: <OrderedListOutlined />,
                                    },
                                ]}
                            />
                            <div className='tableContainer d-flex flex-column' style={{ marginTop: '10px' }}>
                                {loading ? <LoadingOutlined /> : paymentsViewType === 'chart' ? <LineInTime /> : <div className="d-flex flex-column">
                                    <ErrorBoundary>
                                        <Suspense fallback={<LoadingOutlined />}>

                                            <DefaultTableItems

                                                pageSize={10}
                                                onChange={(p, filters, sorter, extra) => {
                                                    if (extra.action === 'filter') {

                                                        //setdefaultClientSearch(extra.currentDataSource?.map((d) => d.client?.name ?? 'Sin cliente'))
                                                    }
                                                    //setpageSize(p.pageSize)
                                                }} empty='No tienes solicitudes de pago'
                                                columns={paymentColumns}
                                                query={paymentsRef}
                                                //data={filterByType ?? []} 
                                                //loading={paymentsDBStatus === 'loading'} userDocument={userDocument}
                                                onPageChange={(p) => {
                                                    //  setpageActive(p)
                                                }} defaultPage={1}

                                            />
                                        </Suspense>
                                    </ErrorBoundary>
                                </div>}
                            </div>
                        </div>

                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="d-flex flex-column">
                            <Typography.Title level={5}>Facturas y pagos</Typography.Title>
                            <Segmented
                                onChange={(v) => {
                                    setInvoicesViewType(v)
                                }}
                                options={[
                                    {
                                        label: 'Gráfico',
                                        value: 'chart',
                                        icon: <FundOutlined />,
                                    },
                                    {
                                        label: 'Lista',
                                        value: 'list',
                                        icon: <OrderedListOutlined />,
                                    },
                                ]}
                            />
                            <div className='tableContainer d-flex flex-column' style={{ marginTop: '10px' }}>

                                {loading ? <LoadingOutlined /> : invoicesViewType === 'chart' ? <PaymentsAndInvoicesBarChart /> : <div>
                                    <ErrorBoundary>
                                        <Suspense fallback={<LoadingOutlined />}>
                                            <DefaultTableItems empty='Este cliente no tiene facturas' onChange={(pagination, filters, sorter, extra) => {
                                                //setPageSize(pagination.pageSize)
                                                if (extra.action === 'filter') {
                                                    //setdefaultClientSearch(extra.currentDataSource?.map((d) => d.client?.name ?? 'Sin cliente'))
                                                }
                                            }}

                                                pageSize={10}
                                                columns={[

                                                    {
                                                        title: <TableTitle title="Estado" />,
                                                        dataIndex: 'status',
                                                        key: 'status',
                                                        render: (text, record) => {
                                                            return <InvoiceStatusTag invoice={record} />
                                                        },
                                                    },
                                                    {
                                                        title: <TableTitle title="Folio y Serie" />,
                                                        dataIndex: 'folio',
                                                        ellipsis: true,
                                                        key: 'folio',
                                                        render: (text, record) => {
                                                            return <Typography.Text type="secondary">{record.folio_number} {record.series}</Typography.Text>
                                                        },
                                                    },
                                                    {
                                                        title: <TableTitle title="Total" />,
                                                        dataIndex: 'total',
                                                        key: 'total',
                                                        render: (text, record) => {
                                                            return <Typography.Text type="secondary">{returnCurrencyValue(record.total)} <span style={{ fontSize: '10px' }}>{record.currency}</span></Typography.Text>
                                                        },
                                                    },
                                                    {
                                                        title: <TableTitle title="Fecha" />,
                                                        dataIndex: 'timestamp',
                                                        key: 'timestamp',
                                                        render: (text, record) => {
                                                            return <Typography.Text type="secondary">{moment(text).format('DD MMM YYYY H:mm')}</Typography.Text>
                                                        },
                                                    },
                                                    {
                                                        title: <TableTitle title="Pagos" />,
                                                        dataIndex: 'timestamp',
                                                        key: 'timestamp',
                                                        render: (text, record) => {

                                                            return <PaymentRelations
                                                                items={record?.internalItems}
                                                                hideTitle={true}
                                                                collection="invoices"
                                                                elementType="invoice"
                                                                allowSmall={true}
                                                                custom={true}
                                                                payments={record?.payments}
                                                                changeState={(i) => {
                                                                    //setState({ invoice: { ...record, ...i } })
                                                                }}
                                                                client={record?.internalClient ?? record.customer}
                                                                item={{ ...record }}
                                                                userDocument={userDocument}
                                                                refresh={(V) => {
                                                                    if (V) {
                                                                        window.location.reload()
                                                                    }
                                                                }}
                                                                id={record?.id}
                                                                readable="cfdi"
                                                            />
                                                        },
                                                    },
                                                ]}

                                                query={invoicesRef}

                                                onPageChange={(p) => {
                                                    // setpageActive(p)

                                                }} defaultPage={1} />
                                        </Suspense>
                                    </ErrorBoundary>
                                </div>}
                            </div>
                        </div>

                    </Col>
                </Row>
            </Col>

        </Row>
    );
}

export default ClientDetails;