import { CloseOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Col, Row, Typography, Form, Input, Tag, message, DatePicker, Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { generalreactSelectStyles, reactSelectMultiStyles } from '../../app/functions/styles';
import CreatableSelect from 'react-select/creatable';
import CurrencyChip from '../cards/CurrencyChip';
import FileUploader from '../uploads/FileUploader';
//import { setDoc } from 'firebase/firestore';
import moment from 'moment';
import { generateCode, RemoveUndefined } from '../../app/functions/helpers';
import { collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import DefaultTableItemsLocal from '../tables/DefaultTableItemsLocal';
import TableTitle from '../tables/TableTitle';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';


function AddNewExpense({ open, close, userDocument, edit }) {

    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const [state, setState] = useState({ currency: edit ? edit.currency : (userDocument?.currency ?? 'mxn'), date: moment(), files: [] })
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    /**
     * `SetDefaultConcept` is a function that takes a key as an argument and sets the form's title field to
     * the key
     * @param key - The key of the concept you want to set as default.
     */
    const SetDefaultConcept = (key) => {
        form.setFieldValue("title", key)
        setState({ ...state, title: key })
    }
    /**
     * It returns an array of objects with the label and value properties, depending on the value of the
     * expenseClassification state variable
     * @returns An array of objects with the label and value properties.
     */
    const SuggestionsDefauls = () => {
        switch (state.expenseClassification ?? '') {
            case 'Administrativos':
                return [
                    { label: 'Alquiler', value: 'Alquiler' },
                    { label: 'Internet', value: 'Internet' },
                    { label: 'Impuestos', value: 'Impuestos' },
                    { label: 'Seguros', value: 'Seguros' },
                    { label: 'Mantenimiento', value: 'Mantenimiento' },
                    { label: 'Nómina', value: 'Nómina' },
                    { label: 'Computo', value: 'Computo' },
                    { label: 'Otros', value: 'Otros' },
                ];
            case 'De producción':
                return [
                    { label: 'Materia prima', value: 'Materia prima' },
                    { label: 'Maquinaria', value: 'Maquinaria' },
                    { label: 'Insumos', value: 'Insumos' },
                    { label: 'Materiales', value: 'Materiales' },
                    { label: 'Otros', value: 'Otros' },
                ];
            case 'Ventas':
                return [
                    { label: 'Publicidad', value: 'Publicidad' },
                    { label: 'Comisiones', value: 'Comisiones' },
                    { label: 'Otros', value: 'Otros' },
                ];
            case 'Financieros':
                return [
                    { label: 'Intereses', value: 'Intereses' },
                    { label: 'Obligaciones Fiscales', value: 'Obligaciones Fiscales' },
                    { label: 'Financiamientos', value: 'Financiamientos' },
                    { label: 'Comisiones', value: 'Comisiones' },
                    { label: 'Prestamos', value: 'Prestamos' },
                    { label: 'Otros', value: 'Otros' },
                ];
            case 'Distribución':
                return [
                    { label: 'Transporte', value: 'Transporte' },
                    { label: 'Almacenaje', value: 'Almacenaje' },
                    { label: 'Otros', value: 'Otros' },
                ];

            case 'Producto':
                return [
                    { label: 'Servidores', value: 'Servidores' },
                    { label: 'Software', value: 'Software' },
                    { label: 'Otros', value: 'Otros' },
                ]

            default:
                return []
        }
    }


    /**
     * It creates a new expense in the database
     * @param vals - The values of the form
     */
    const CreateExpense = async (vals) => {
        setLoading(true);
        const code = generateCode(15, 'expense')
        const expense = {
            ...vals,
            "invoice": null,
            "livemode": true,
            "charges": null,
            "review": null,
            "amount_capturable": 0,
            "customer": null,
            "date": vals.date.valueOf(),
            "created": state.date.valueOf(),
            "timestamp": moment().valueOf(),
            "shipping": null,
            "amount_received": Number(vals.amount) * 100,
            "capture_method": "automatic",
            "transfer_group": null,
            "expenseClassification": vals.expenseClassification.value,
            "expenseType": vals.expenseType.value,
            "paymentMethod": vals.paymentMethod.value,
            "amount": Number(vals.amount) * 100,
            "status": "succeeded",
            "next_action": null,
            "viewed": 0,
            "receipt_email": null,
            "last_payment_error": null,
            "confirmation_method": "manual",
            "statement_descriptor": "",
            "owner": userDocument?.uid,
            "payment_method": state.paymentMethod ?? null,
            "application": null,
            "automatic_payment_methods": null,
            "description": vals.description ?? null,
            "lastViewed": null,
            "on_behalf_of": null,
            "currency": state.currency,
            "items": [{
                description: vals.description ?? '',
                quantity: 1,
                owner: userDocument.uid,
                id: generateCode(5),
                paymentType: { label: 'Tarifa fija', value: 'fixed' },
                total: Number(state.amount),
                timestamp: moment().valueOf(),
                taxes: [],
                name: vals.title
            }],
            "clientID": state.client?.id ?? null,
            "metadata": { "items": 1, "owner": userDocument.uid, "internalID": code },
            "internalStatus": "succeeded",
            "object": "manual_payment",
            "cancellation_reason": null,
            "source": null,
            "token": null,
            "id": code,
            "payment_method_types": [{ id: state.paymentMethod, type: state.paymentMethod }],
            "client": state.client ?? null,
            "application_fee_amount": null,
            "canceled_at": null,
            "fid": code,
            isManual: true,
            processor: 'manual',
            "team": team?.id,
            "billingAccount": billingAccount?.id
        }

        // const promises = state.files.map((file) => {
        //     return setDoc(doc(getFirestore(), `expenses/${code}/files/${file.code}`,), {
        //         ...file,
        //         type: file.name.split('.').pop() ?? '',
        //     })
        // })
        const batch = writeBatch(getFirestore());
        state.files.map((file) => {
            return batch.set(doc(getFirestore(), `expenses/${code}/files/${file.code}`,), {
                ...file,
                type: file.name.split('.').pop() ?? '',
            })
        })


        try {
            await setDoc(doc(getFirestore(), `expenses/${code}`), { ...RemoveUndefined(expense) });
            await batch.commit();
            setLoading(false);
            getCurrentTeam(team?.id)
            close();
        } catch (error) {
            setLoading(false);
            message.error(error?.message ?? 'Error al crear el gasto');
        }
    }

    /**
     * It updates an expense in the database
     * @param vals - The values of the form
     */
    const UpdateExpense = async (vals) => {
        setLoading(true);
        try {
            await updateDoc(doc(getFirestore(), `expenses/${edit.id}`), {
                ...vals,
                amount: Number(vals.amount) * 100,
                date: vals.date.valueOf(),
                currency: state.currency,
                expenseClassification: vals.expenseClassification.value,
                expenseType: vals.expenseType.value,
                paymentMethod: vals.paymentMethod.value,
                items: [{
                    description: vals.description ?? '',
                    quantity: 1,
                    owner: userDocument.uid,
                    id: generateCode(5),
                    paymentType: { label: 'Tarifa fija', value: 'fixed' },
                    total: Number(vals.amount),
                    timestamp: moment().valueOf(),
                    taxes: [],
                    name: vals.title
                }],
            })
            const batch = writeBatch(getFirestore());
            state.files.map((file) => {
                return batch.set(doc(getFirestore(), `expenses/${edit.id}/files/${file.code}`,), {
                    ...file,
                    type: file.name.split('.').pop() ?? '',
                })
            })
            await batch.commit();
            setLoading(false);
            getCurrentTeam(team?.id)
            close();
        } catch (error) {
            setLoading(false);
            message.error(error?.message ?? 'Error al actualizar el gasto');
        }
    }

    useEffect(() => {

        if (edit) {

            setState({
                ...state,
                ...edit,
                currency: edit.currency ?? userDocument?.currency ?? 'MXN',
            })
            //LOAD FILES
            getDocs(query(collection(getFirestore(), `expenses/${edit.id}/files`))).then((querySnapshot) => {
                const files = querySnapshot.docs.map((doc) => doc.data());
                setState({ ...state, files })
            })



        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    return (
        <Row>
            <Col xs={24}>
                <Row justify="space-between">
                    <Typography.Title level={4}>{edit ? 'Actualizar gasto' : 'Crear gasto'}</Typography.Title>
                    <CloseOutlined onClick={() => close()} />
                </Row>
            </Col>
            <Col xs={24}>
                <Form onFinish={edit !== null ? UpdateExpense : CreateExpense} form={form} initialValues={edit ? {
                    ...edit,
                    expenseClassification: { label: edit.expenseClassification, value: edit.expenseClassification },
                    expenseType: { label: edit.expenseType, value: edit.expenseType },
                    paymentMethod: { label: edit.paymentMethod, value: edit.paymentMethod },
                } : { expenseType: { label: 'Variable', value: 'variable' }, date: moment() }} layout="vertical">
                    <Row gutter={{ xs: 10, md: 12, lg: 14 }}>
                        <Col xs={24} md={24}>
                            <Row justify='end'>
                                <CurrencyChip onUpdate={(v) => {
                                    setState({ ...state, currency: v.currency })
                                }} state={state} />
                            </Row>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Tipo de gasto" name={"expenseType"} rules={[{ required: false, message: 'Añade el concepto del gasto' }]}>
                                <Select
                                    styles={generalreactSelectStyles}
                                    className="pro-select-input"
                                    options={[{ label: 'Fijo', value: 'fijo' }, { label: 'Variable', value: 'variable' }, { label: 'Imprevisto', value: 'imprevisto' }]}
                                    isLoading={false}
                                    placeholder="Tipo de gasto.."

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Clasificación de gasto" name={"expenseClassification"} rules={[{ required: false }]}>
                                <CreatableSelect
                                    isMulti={false}
                                    onChange={(newValue) => {
                                        if (!newValue) {
                                            return;
                                        }
                                        setState({ ...state, expenseClassification: newValue.value })
                                    }}
                                    options={[{ label: 'Administrativos', value: 'Administrativos' },
                                    { label: 'De producción', value: 'De producción' },
                                    { label: 'Financieros', value: 'Financieros' },
                                    { label: 'Ventas', value: 'Ventas' },
                                    { label: 'Distribución', value: 'Distribución' },
                                    { label: 'Producto', value: 'Producto' }
                                    ]}
                                    styles={reactSelectMultiStyles}
                                    noOptionsMessage={(v) => "Añade una clasificación"}
                                    className="pro-select-input pro-select-input-multi"
                                    placeholder="Clasificación del gasto.."
                                    formatCreateLabel={(userInput) => `Añadir: "${userInput}"`}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={16}>
                            <div className='d-flex flex-column ' style={{ width: '100%' }} >
                                <Form.Item label="Concepto" style={{ margin: '0px', marginBottom: '10px' }} name={"title"} rules={[{ required: true, message: 'Añade el concepto del gasto' }]}>
                                    <Input placeholder='ej: Gastos de ...' onChange={(v) => setState({ ...state, title: v })} />
                                </Form.Item>
                                <Row>
                                    {SuggestionsDefauls().map((item, index) => {
                                        return (
                                            <Tag key={index} className={`clickable hoverPrimary ${item.label === state.title ? 'primary' : ''}`} style={{ marginBottom: '5px' }} onClick={() => SetDefaultConcept(item.label)}>{item.label}</Tag>
                                        )
                                    })}
                                </Row>
                            </div>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item label="Fecha" name={"date"} rules={[{ required: true, message: 'Añade el concepto del gasto' }]}>
                                <DatePicker format={'DD/MM/YYYY'} placeholder='Fecha del gasto' onChange={(d) => {
                                    setState({ ...state, date: d })
                                }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={14}>
                            <Form.Item label="Total" name={"amount"} rules={[{ required: true, message: 'Añade el monto' }]}>
                                <Input placeholder='$' type="number" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={10}>
                            <Form.Item label="Método de pago" name={"paymentMethod"} rules={[{ required: true }]}>
                                <CreatableSelect
                                    isMulti={false}
                                    onChange={(newValue) => {
                                        if (!newValue) {
                                            return;
                                        }
                                        setState({ ...state, paymentMethod: newValue.value })
                                    }}
                                    options={[
                                        { label: 'Transferencia', value: 'Transferencia' },
                                        { label: 'Crédito', value: 'Crédito' },
                                        { label: 'Débito', value: 'Débito' },
                                        { label: 'Caja', value: 'Caja' },
                                    ]}
                                    styles={reactSelectMultiStyles}
                                    noOptionsMessage={(v) => "Añade una clasificación"}
                                    className="pro-select-input pro-select-input-multi"
                                    placeholder="Método de pago.."
                                    formatCreateLabel={(userInput) => `Añadir: "${userInput}"`}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item label="Descripción del gasto" name={"description"} rules={[{ required: false }]}>
                                <Input.TextArea placeholder="Descripción del gasto..." />
                            </Form.Item>
                        </Col>
                        {state.files.length > 0 && <Col xs={24} className='d-flex justify-content-end'>
                            <DefaultTableItemsLocal empty='No hay archivos aún' elementName={"archivos"} data={state.files} columns={[

                                {
                                    title: TableTitle({ title: 'Archivo' }),
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (text, record) => {
                                        return (
                                            <Row onClick={() => window.open(record.url, "_blank")}>
                                                <EyeOutlined style={{
                                                    marginRight: '5px'
                                                }} className='clickable hoverPrimary' onClick={() => {
                                                    window.open(record.url, "_blank")
                                                }} />
                                                <Typography.Text className='hoverPrimary clickable primary' style={{ fontSize: '11px' }}>{text}</Typography.Text>
                                            </Row>
                                        )
                                    }

                                },
                                {
                                    title: TableTitle({ title: '' }),
                                    dataIndex: 'actions',
                                    key: 'actions',
                                    render: (text, record) => {
                                        return (
                                            <div className='d-flex justify-content-end'>
                                                <Button type='text' danger onClick={async () => {
                                                    const reference = ref(getStorage(), record.path);
                                                    await deleteDoc(doc(getFirestore(), `expenses/${edit.id}/files/${record.code}`))
                                                    try {
                                                        await deleteObject(reference)

                                                        setState({ ...state, files: state.files.filter((item) => item.uid !== record.uid) })
                                                    } catch (error) {
                                                    }
                                                }}><DeleteOutlined /></Button>
                                            </div>
                                        )
                                    }
                                }

                            ]} />
                        </Col>}

                        <Col xs={24}>
                            <FileUploader
                                title=' <p style="margin-bottom:0px; font-size:13px;" class="strong">Subir captura</p>'
                                descriptions='<p style="font-size:12px;">Puedes arrastrar tus archivos o <span class="strong"">buscarlos</span> en tus archivos.</p>'
                                maxFiles={5}

                                //defaultFiles={state.files}
                                storageReference={`teams/${team.id}/files`}
                                dbref={`/teams/${team.id}/expenses`}

                                toDelete={async (ref) => {
                                    message.success('Archivo eliminado')
                                }}
                                onUpload={async (file) => {

                                    setState({ ...state, files: [...state.files, { ...file, status: "done", }] })
                                }}
                            />
                        </Col>


                        <Col xs={24} style={{ marginBottom: '25px' }}>
                            <Row justify="center">
                                <Button loading={loading} htmlType="submit" type='primary'>{edit === null ? 'Guardar gasto' : 'Actualizar'}</Button>
                            </Row>
                        </Col>

                    </Row>
                </Form>
            </Col>
        </Row>
    );
}

export default AddNewExpense;