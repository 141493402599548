import { getDoc, getFirestore, doc } from 'firebase/firestore';
import React, { useReducer } from 'react';

import {
    GET_BILLINGACCOUNT_DATA
} from '../../types';

import BillingAccountContext from './BillingAccountContext';
import BillingAccountReducer from './BillingAccountReducer';

const BillingAccountState = props => {

    const initialState = {
        billingAccount: null,
        billingAccountStatus: 'loading'
    }

    const fs = getFirestore();

    const [state, dispatch] = useReducer(BillingAccountReducer, initialState);

    /**
     * It get the billing account data from the database and dispatch it to the reducer
     * @param id 
     */
    const getBillingAccount = async (id) => {
        if (!id) return
        try {
            await getDoc(doc(fs, "billingAccounts", id)).then((doc) => {
                dispatch({
                    type: GET_BILLINGACCOUNT_DATA,
                    payload: doc.data()
                });
            });
        } catch (error) {
        }
    }

    return (
        <BillingAccountContext.Provider
            value={{
                billingAccount: state.billingAccount,
                getBillingAccount
            }}
        >
            {props.children}
        </BillingAccountContext.Provider>
    )
}

export default BillingAccountState;