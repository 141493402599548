import { Highlight, Hits, InstantSearch, SearchBox } from "react-instantsearch";
import moment from "moment";
import { Suspense, useContext, useState } from "react";
import { Button, Col, Divider, Empty, Row, Space, Tag, Typography } from "antd";
import { getItemsAmounts, paymentIntentsStatusMessage, returnColorByPaymentStatus, returnCurrencyValue, returnTextColorByPaymentStatus } from "../../app/functions/helpers";
import { BiLinkExternal } from "react-icons/bi";
import { InvoiceStatusTag } from "../invoices/InvoicesStatus";
import { ReceiptStatusTag } from "../../app/pages/ReceiptsPage";
import { InvoiceActions } from "../actions/InvoiceActions";
import { ReceiptActions } from "../actions/ReceiptActions";
import { PaymentActions } from "./SearchModal";
import { LoadingOutlined } from "@ant-design/icons";
import RelationsDrawer from "../relations/RelationsDrawer";
import UserContext from "../../context/usercontext/UserContext";
import CreateInvoice from "../invoices/CreateInvoice";
import searchClient from "./typesenseClient";
import TeamContext from "../../context/teamcontext/TeamContext"

export function AlgoliaHitComponent({ hit, type }) {

    const id = type === 'payments' ? hit.fid ?? hit.id : hit.id
    const externalLink = type === 'invoices' ? `invoiceDetails?id=${id}` : type === 'payments' ? `paymentDetails?id=${hit.fid ?? hit.id}` : null
    const total2 = getItemsAmounts(hit?.internalItems ?? hit.items ?? []).total

    const [createPaymentComplement, setCreatePaymentComplement] = useState(null)

    const StatusTag = () => {
        switch (type) {
            case 'payments':
                return <Tag className="statusTag" color={returnColorByPaymentStatus(hit)} style={{
                    color: returnTextColorByPaymentStatus(hit), borderRadius: '10px', opacity: 0.9, padding: '0px 10px', fontWeight: 500
                }}>{paymentIntentsStatusMessage(hit)}</Tag>
            case 'invoices':
                return <InvoiceStatusTag invoice={hit} useSecondStatus={false} />
            case 'receipts':
                return <ReceiptStatusTag receipt={hit} />
            default:
                return <Tag className="statusTag" color={returnColorByPaymentStatus(hit)} style={{
                    color: returnTextColorByPaymentStatus(hit), borderRadius: '10px', opacity: 0.9, padding: '0px 10px', fontWeight: 500
                }}>{paymentIntentsStatusMessage(hit)}</Tag>
        }
    }

    const Actions = () => {
        switch (type) {
            case 'payments':
                return <PaymentActions item={hit} showCreateInvoice={true} />
            case 'invoices':
                return <InvoiceActions record={hit} />
            case 'receipts':
                return <ReceiptActions record={hit} />
            default:
                return <></>
        }
    }

    const Relations = () => {

        const { user } = useContext(UserContext)
        const [viewRelations, setViewRelations] = useState(false)

        return <Row align="middle" style={{ marginTop: '10px', marginRight: '10px' }}>

            {viewRelations && <Suspense fallback={<LoadingOutlined />}>
                <RelationsDrawer userDocument={user} open={viewRelations} render={viewRelations.type} relation={viewRelations} close={() => setViewRelations(null)} />
            </Suspense>}

            {(hit?.payments ?? [])?.length > 0 && <Tag className="clickable" onClick={() => {
                setViewRelations({
                    title: 'Pagos relacionados',
                    type: 'payments',
                    db: type,
                    relatedWith: hit,
                    count: hit?.payments?.length ?? 1,
                    elementID: id,
                    subdb: 'payments',
                    item: hit
                })
            }}>
                {(hit?.payments ?? [])?.length} {hit?.payments?.length === 1 ? 'pago' : 'pagos'}
            </Tag>}

            {(hit?.invoices ?? [])?.length > 0 && <Tag className="clickable" onClick={() => {
                setViewRelations({
                    title: 'Facturas relacionadas',
                    type: 'invoices',
                    db: type,
                    relatedWith: hit,
                    count: hit?.invoices?.length ?? 1,
                    elementID: id,
                    subdb: 'invoices',
                    item: hit
                })
            }}>
                {(hit?.invoices ?? [])?.length} {hit?.invoices?.length === 1 ? 'factura' : 'facturas'}
            </Tag>}

            {(hit?.receipts ?? [])?.length > 0 && <Tag className="clickable" onClick={() => {
                setViewRelations({
                    title: 'Recibos relacionados',
                    type: 'receipts',
                    db: type,
                    relatedWith: hit,
                    count: hit?.receipts?.length ?? 1,
                    elementID: id,
                    subdb: 'receipts',
                    item: hit
                })
            }}>
                {(hit?.receipts ?? [])?.length} {hit?.receipts?.length === 1 ? 'recibo' : 'recibos'}
            </Tag>}
        </Row>
    }

    return (
        <div className="instant-search--result-item-container">
            <Row className="instant-search--result-item">
                <Col xs={16}>
                    {createPaymentComplement && <CreateInvoice data={{ ...createPaymentComplement, type: 'P', step: 2, customTitle: 'Crear complemento de pago', items: createPaymentComplement?.internalItems, client: { ...createPaymentComplement?.internalClient, tax_system: createPaymentComplement.client?.tax_system }, toRelate: createPaymentComplement.uuid, payments: [], emails: [] }} open={createPaymentComplement} close={() => {
                        setCreatePaymentComplement(null)
                    }
                    } />}
                    <Row align="middle" wrap='10' className="primary clickable" onClick={() => {
                        if (externalLink) {
                            window.open(externalLink, '_blank')
                        }
                    }}>
                        <Highlight attribute={type === 'invoices' ? 'customer.legal_name' : 'client.name'} hit={hit} classNames={{
                            root: 'instant-search--highlight-client-name'
                        }} />
                        {externalLink && <BiLinkExternal style={{ marginLeft: '5px' }} />}
                        {type === 'invoices' && <span style={{ margin: '0px 15px' }}>
                            <Typography.Text style={{ fontWeight: 900, marginLeft: '5px' }}>
                                {hit.series}-<Highlight attribute="folio_number" hit={hit} />
                            </Typography.Text>
                        </span>}
                    </Row>
                    <Highlight attribute={type === 'invoices' ? "uuid" : type === 'payments' ? "fid" : "id"} hit={hit} classNames={{
                        root: 'instant-search--highlight-fid'
                    }} />
                    {(hit.orderId || hit?.metadata?.orderId) && <Row>
                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Order ID: <Highlight attribute="metadata.orderId" hit={hit} /></Typography.Text>
                    </Row>}
                    {hit?.whmcsInvoiceId && <Row>
                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>WHMCS: <Highlight attribute="whmcsInvoiceId" hit={hit} /></Typography.Text>
                    </Row>}
                    <p className="secondary" style={{ margin: 0, padding: 0, fontSize: '10px' }}>{moment(hit?.timestamp).format('DD/MM/YYYY HH:mm')}</p>
                </Col>
                <Col xs={8}>
                    <Space direction="vertical" size="small" style={{ width: '100%', marginBottom: '5px' }}>
                        <Row justify="end" style={{ marginLeft: '10px' }}>
                            <Typography.Text style={{ fontWeight: 900 }}>
                                {returnCurrencyValue(total2)} {hit.currency.toUpperCase()}
                            </Typography.Text>
                        </Row>
                        <Row justify="end">
                            <StatusTag />
                        </Row>
                        <Row justify="end" align="middle">
                            {(hit.payment_form === '99' && !hit.global && type === 'invoices') && <div style={{ marginBottom: '4px' }}>
                                <Button size='small' onClick={() => {
                                    //UPDTE URL WITH PAGE ACTIVE

                                    setCreatePaymentComplement({
                                        client: hit?.internalClient,
                                        items: hit?.items,
                                        ...hit,
                                        'invoice': hit
                                    })
                                }}>
                                    Complemento de pago
                                </Button>
                            </div>}
                            <Relations />
                            <Actions />
                        </Row>
                    </Space>
                </Col>
            </Row>
            <Divider style={{ margin: 0, padding: 0 }} />
        </div>
    );
}

export const AlgoliaSearchComponent = ({ indexName, teamId, type = 'payments' }) => {
    const { team } = useContext(TeamContext)

    const [query, setQuery] = useState('');
    const [showEmpty, setShowEmpty] = useState(false)
 
    return <div style={{ width: '100%', position: 'relative' }}>
        <InstantSearch searchClient={searchClient({ team, indexName })} indexName={indexName} future={{
            preserveSharedStateOnUnmount: true,
        }} style={{ width: '100%' }}>
            <div className='d-flex flex-column' style={{ display: 'flex' }}>
                <SearchBox classNames={{
                    root: 'instant-search--algolia-search-box',
                    input: 'instant-search--algolia-search-input',
                    form: 'instant-search--algolia-search-box-form',
                    submit: 'instant-search--algolia-search-box-submit',
                    submitIcon: 'instant-search--algolia-search-box-submit-icon',
                    resetIcon: 'instant-search--algolia-search-box-reset-icon',
                }} placeholder={`Buscar ${type === 'invoices' ? 'facturas' : type === 'payments' ? 'pagos' : 'recibos'}`} onChangeCapture={(e) => setQuery(e.target.value)} />
                {(query) && <div style={{ display: query ? 'block' : 'none', position: 'absolute', zIndex: 999, top: '110%', left: 0, right: 0, border: '1px solid #ccc', backgroundColor: 'white', borderRadius: '10px', padding: '0px', maxHeight: '50vh', overflowY: 'scroll' }}>
                    <Hits hitComponent={(hit) => AlgoliaHitComponent({ ...hit, type })} tabIndx={false} transformItems={(items, { result }) => {
                        if (items.length === 0) {
                            setShowEmpty(true)
                        } else {
                            setShowEmpty(false)
                        }
                        return items
                    }} classNames={{
                        list: 'instant-search--algolia-search-hits-list',
                    }} />
                    {showEmpty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>
                        No se encontraron resultados para <b>{query}</b>
                    </span>} />}
                </div>}
            </div>

        </InstantSearch>
    </div>
}