import { Col, Row, Typography } from 'antd';
import React from 'react';


function ActionablePageHeader({ userDocument, title, actions }) {
    return (
        <Row>
            <Col xs={24}>
                <div className='actionableHeaderContainer'>
                    <Row align='middle'>
                        <Typography.Title className="" level={4} style={{ flex: 1, margin: 0, padding: 0 }}>{title}</Typography.Title>
                        {actions && actions}
                    </Row>
                </div>
            </Col>
        </Row >
    );
}

export default ActionablePageHeader;