import { CloseOutlined } from "@ant-design/icons"
import { Button, Form, Input, Modal, Row, Space, Switch, Typography, message } from "antd"
import HelperCard from "../cards/HelperCard"
import openpayLogo from '../../assets/images/logoopenpaySquare.jpg'
import TeamContext from "../../context/teamcontext/TeamContext"
import { useContext, useState } from "react"
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests"
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext"
import { useAuth } from "reactfire"
import { doc, getFirestore, updateDoc } from "firebase/firestore"

const OpenpayConnectModal = ({ open, close }) => {
    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const [loading, setLoading] = useState(false)
    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.openpay?.automaticInvoicing ?? false)
    const ref = doc(getFirestore(), "teams", team?.id)
    const auth = useAuth()

    const handleOpenpayConnection = async (v) => {
        try {
            setLoading(true)
            const resp = await SignedInternalAPIRequest({ teamId: team?.id, billingAccountId: billingAccount?.id, ...v }, 'openpayconnect', auth.currentUser, {}, 'POST', true)
            console.log(resp)
            message.success('Tu cuenta de Openpay ha sido conectada correctamente')
            setLoading(false)
            getCurrentTeam(team?.id)
            close()
        } catch (error) {
            console.log(error)
            setLoading(false)
            message.error(error?.message ?? 'Ocurrió un error al conectar tu cuenta de Openpay')
        }
    }

    const AutomaticInvoicing = () => {
        return (
            <Row align="middle" style={{ marginTop: '25px', marginBottom: '35px' }}>
                <Space direction='vertical'>
                    <Typography.Text style={{ fontWeight: 500 }}>Activar facturación automática</Typography.Text>
                    <Row style={{ marginTop: '5px' }} align="middle">
                        <Switch defaultChecked={automaticInvoicing} checked={automaticInvoicing} onChange={async (v) => {
                            try {
                                await updateDoc(ref, {
                                    openpay: {
                                        ...team?.openpay,
                                        automaticInvoicing: v
                                    },
                                    integrationsSetup: team?.integrationsSetup.filter(int => int !== 'automaticInvoicing')
                                })
                                getCurrentTeam(team?.id)
                                message.success('Datos guardados')
                                setAutomaticInvoicing(v)
                            } catch (error) {
                                message.error(error?.message ?? 'Ocurrió un error al guardar los datos')
                            }

                        }} />
                        <Typography.Text type="secondary" style={{ fontSize: '13px', flex: 1, marginLeft: '15px' }}>Siempre que tengas activada la automatización, realizaremos una factura por cada pago que recibas.</Typography.Text>
                    </Row>
                </Space>
            </Row>
        )
    }

    const ConnectOpenpayForm = () => {

        return (
            <Form layout='vertical' onFinish={handleOpenpayConnection}>
                <Row style={{ margin: '20px 0px ' }} align='middle' >
                    <Typography.Title style={{ margin: '0px', padding: '0px', marginLeft: '5px' }} level={5}>Conecta tu cuenta via API</Typography.Title>
                    <Typography.Text type='secondary'>Cónoce más sobre como conectarte a Openpay <a href='https://app.intercom.com/a/apps/acgd4n2q/articles/articles/8037631/show' target='_bank'>aquí</a></Typography.Text>
                </Row>
                <Form.Item label="ID de la cuenta" name='accountId' rules={[{ required: true, message: 'Por favor ingrese su ID de cuenta' }]}>
                    <Input placeholder="Account Id" />
                </Form.Item>
                <Form.Item label="API Key Pública" name='publicKey' rules={[{
                    required: true,
                    message: 'Por favor ingrese su API Pública',
                }, {
                    validator: (rule, value) => {
                        if (!value.startsWith('pk_')) {
                            return Promise.reject('API Key Pública inválida, debe comenzar con pk_')
                        } else {
                            return Promise.resolve()
                        }
                    }
                }]}>
                    <Input placeholder="API Key Live" />
                </Form.Item>
                <Form.Item label="API Key Privada" name='secretKey' rules={[{
                    required: true,
                    message: 'Por favor ingrese su API Key Privada',
                }, {
                    validator: (rule, value) => {
                        if (!value.startsWith('sk_')) {
                            return Promise.reject('API Key Privada inválida, debe comenzar con sk_')
                        } else {
                            return Promise.resolve()
                        }
                    }
                }]}>
                    <Input placeholder="API Key Test" />
                </Form.Item>
                <Row justify='center' style={{ margin: '10px', marginTop: '20px' }} >
                    <Button loading={loading} type="primary" style={{ flex: 1, marginRight: '5px', width: '50%' }} htmlType='submit'>Conectar mi cuenta de Openpay</Button>
                </Row>
            </Form>
        )
    }

    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "40%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4}>Configura tu cuenta de Openpay</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                    <Typography.Text>Conecta tu cuenta de Openpay para poder recibir pagos de tus clientes.</Typography.Text>
                </Row>
                <HelperCard squareImg={true} openIntercom={true} title={'¿Necesitas ayuda?'} description="Estaremos encantados de ayudarte!." supportImageUrl={openpayLogo} />
                {team?.openpay?.completed ? <AutomaticInvoicing /> : <ConnectOpenpayForm />}
            </div >
        </Modal >
    )
}

export default OpenpayConnectModal