import { Button, Col, Input, message, Popconfirm, Row, Space, Tabs, Tag, Tooltip, Typography, Dropdown, Menu, Table } from 'antd';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import ActionablePageHeader from '../../widgets/headers/ActionablePageHeader';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import DefaultTableItems from '../../widgets/tables/DefaultTableItems';
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query, updateDoc, where, onSnapshot, startAfter, limit, getCountFromServer } from 'firebase/firestore';
import TableTitle from '../../widgets/tables/TableTitle';
import { CopyToClipboard, generateCode, getItemsAmounts, paymentIntentsStatusMessage, RemoveDuplicatesFromObjectArray, returnColorByPaymentStatus, /*getItemsAmounts,*/ ReturnCorrectedPayment, returnCurrencyValue, returnTextColorByPaymentStatus, SearchParamInURL, ShareViaWhatsapp } from '../functions/helpers';
import moment from 'moment';
import { BarcodeOutlined, ClockCircleOutlined, CloudDownloadOutlined, DeleteOutlined, DownOutlined, EditOutlined, ExperimentOutlined, EyeInvisibleOutlined, InfoCircleOutlined, LinkOutlined, LoadingOutlined, MailOutlined, NotificationOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusCircleOutlined, RobotFilled, RobotOutlined, SearchOutlined, SettingOutlined, StopOutlined, UserAddOutlined, WarningOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { SignedInternalAPIRequest } from '../functions/APIRequests';
import { useAuth, useFirestoreCollectionData } from 'reactfire';
import InvoicesRelations from '../../widgets/relations/InvoicesRelations';
import ReviewPaymentModal from '../../widgets/payments/ReviewPaymentModal';
import { useMembershipOnce } from '../../customHooks/useMembershipOnce';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import ComposeEmailModal from '../../widgets/email/ComposeEmailModal';
import NotifyMultiple from '../../widgets/payments/NotifyMultiple';
import TeamContext from '../../context/teamcontext/TeamContext';
// import CreateElementHolder from '../../widgets/drawers/CreateElementHolder';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import NewPaymentRequest from '../../widgets/payments/NewPaymentRequest';
import { useUserPermissions } from '../../customHooks/userPermissions';
import ClientsListModal from '../../widgets/clients/ClientsListModal';
import RelationsDrawer from '../../widgets/relations/RelationsDrawer';
import RegisterPaymentModal from '../../widgets/payments/RegisterPaymentModal';
import StripeModal from '../../widgets/integrations/StripeModal';
import SearchModal from '../../widgets/search/SearchModal';
import NewRecurringPayment from '../../widgets/payments/NewRecurringPayment';
import ExcelJS from 'exceljs';
import { AlgoliaSearchComponent } from '../../widgets/search/AlgoliaSearchComponents';
import { SummarizeMessage } from '../../widgets/calendarize/SummarizeRecurring';



//TODO: CHECK MODALS

const RenderToReview = ({ userDocument, setconfirmPayment }) => {
  const limitToCreate = SearchParamInURL('limitToCreate');

  const { team } = useContext(TeamContext);
  //TODO: AQUÍ TENEMOS REAL TIME A NIVEL PAGOS
  const reviewP = query(collection(getFirestore(), `payments`), where('team', '==', team?.id ?? ''), where('review_status', '==', 'pending'), limit(limitToCreate ?? 4000), orderBy('review_created_at', 'desc'));

  const { status, data } = useFirestoreCollectionData(reviewP, { idOnDB: 'id' });

  if (status === 'loading') {
    return <LoadingOutlined />
  }

  if (data.length <= 0) {
    return <></>
  }
  return <Row style={{ width: '100%' }}>
    <Col xs={24} style={{ marginTop: '25px', marginBottom: '10px' }}>
      <ActionablePageHeader title="Revisa los pagos" actions={
        <Typography.Text type="secondary">{data.length} pago{data.length > 1 ? 's' : ''} por revisar</Typography.Text>}
      />
    </Col>

    <Col xs={24} style={{ marginTop: '25px' }}>
      <Row gutter={{ md: 12 }}>
        {data.map((rev_payment) => {
          const amounts = getItemsAmounts(rev_payment.items, true)

          return <Col key={rev_payment.id} xs={24} md={12} style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#F9F3DE', padding: '10px 20px', borderRadius: '10px' }}>
              <Row justify="space-between" align="middle">
                <Space>
                  <div style={{ minHeight: '50px', height: '100%', backgroundColor: rev_payment?.voucherConceptExists ? '#94ffc1' : '#ffb194', width: '5px', marginRight: '10px', borderRadius: '5px' }}></div>
                  <InfoCircleOutlined style={{ fontSize: '20px' }} />
                  <div className="d-flex flex-column " style={{ marginLeft: '10px' }}>
                    <Typography.Text style={{ fontSize: '13px' }} className="strong" >{rev_payment.client?.name ?? 'Sin Cliente'} / {returnCurrencyValue(amounts.total)} {rev_payment.currency}</Typography.Text>
                    <Typography.Text className="secondary" style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeigth: 'w400' }}>{moment(rev_payment.review_created_at).format('DD-MM-YYYY HH:mm:ss')}</Typography.Text>
                  </div>
                </Space>
                <Button type="" size="middle" onClick={() => setconfirmPayment(rev_payment)}>Revisar pago</Button>
              </Row>
            </div>
          </Col>
        })
        }
      </Row>
    </Col>
  </Row>




}


/**
 * It takes an id and an index, and then deletes the record with that id
 * @param id - The id of the document to be deleted
 * @param index - The index of the record in the array
 */
export const DeleteRecord = async (id, index) => {
  try {
    const fs = getFirestore();
    await deleteDoc(doc(fs, `payments/${id}`))
    message.success('Pago eliminado')

  } catch (error) {
    message.error('Error deleting record')
  }
}







function PaymentsPage({ userDocument, subtab, changeRender }) {


  const limitToCreate = SearchParamInURL('limitToCreate');
  const { team } = useContext(TeamContext)
  const { billingAccount } = useContext(BillingAccountContext)

  const fs = getFirestore();
  const auth = useAuth()
  // const paymentsRef = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));
  // const paymentsToReviewRef = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), where('review_status', '==', 'pending'), orderBy('timestamp', 'desc'));
  // const pendingPaymentsRef = query(collection(fs, `payments`), where('owner', '==', userDocument?.uid), orderBy('timestamp', 'desc'));
  const recurringRef = query(collection(fs, `recurringEvents`), where('team', '==', team?.id ?? ''), where('type', '==', 'payment'), orderBy('timestamp', 'desc'));

  const [actionOnPayment, setactionOnPayment] = useState(null)
  // const [clients, setclients] = useState([])
  const [createRecurring, setcreateRecurring] = useState(false)
  const [editRecurring, seteditRecurring] = useState(null)
  const [downloadingXLSX, setdownloadingXLSX] = useState(false)
  const [pageActive, setpageActive] = useState(SearchParamInURL('pageActive') ?? 1)
  const [pageSize, setpageSize] = useState(10)
  const [filterBy, setfilterBy] = useState('')
  const [filterByClient, setfilterByClient] = useState('')
  //const [showTestMode, setShowTestMode] = useState(false)
  //const [showHidden, setShowHidden] = useState(SearchParamInURL('showHidden') === "false" ? false : true);
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [composeEmail, setComposeEmail] = useState(null)
  const [defaultClientSearch, setdefaultClientSearch] = useState([])
  const [confirmPayment, setconfirmPayment] = useState(null)
  const [notifyMultiple, setNotifyMultiple] = useState(false);
  const [selectClient, setSelectClient] = useState(null)
  const [showReceiptRelations, setShowReceiptRelations] = useState(false)
  const [registerPayment, setRegisterPayment] = useState(false)
  const [generalSettingsModal, setGeneralSettingsModal] = useState(false);
  // const startDate = moment().subtract(35, 'days').valueOf();
  const paymentsRefPagination = query(
    collection(fs, 'payments'),
    where('team', '==', team?.id ?? ''),
    orderBy('timestamp', 'desc'),
    limit(limitToCreate ? limitToCreate : 1000)
  );

  // const paymentsRefPagination = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), limit(1000));
  const [paymentsData, setPaymentsData] = useState([])
  const [lastPaymentDoc, setLastPaymentDoc] = useState(null)
  const [totalPayments, setTotalPayments] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)

  const { credits, isPaidUser, payAsYouGo } = useMembershipOnce({ uid: userDocument?.uid, billingAccountId: billingAccount?.id })

  const { canCreatePayments } = useUserPermissions({ user: userDocument, team, billingAccount })

  useEffect(() => {
    setPaymentsData([])
    setLastPaymentDoc(null)
    setTableLoading(true)

    const getInitial = async () => {
      setTableLoading(true)
      const coll = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''));
      const snapshot = await getCountFromServer(coll);
      // console.log(snapshot.data());
      setTotalPayments(snapshot.data().count)
      onSnapshot(paymentsRefPagination, (data) => {
        let newDocs = data.docs.map((doc) => {
          return doc.data()
        })
        // console.log('CANTIDAD:', newDocs.length)
        setPaymentsData(newDocs)
        setLastPaymentDoc(data.docs[newDocs.length - 1])
        setTableLoading(false)

      });
    }
    getInitial();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team?.id])

  const getNextPage = async ({ pa, ps }) => {
    setTableLoading(true)
    if ((pa * ps - paymentsData.length) <= 0) {
      setTableLoading(false)
    }
    try {
      const paymentsRefPagination = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), startAfter(lastPaymentDoc), limit(pa * ps - paymentsData.length));
      const newDocs = await getDocs(paymentsRefPagination)
      let newDocsData = newDocs.docs.map((d) => d.data())
      setPaymentsData([...paymentsData, ...newDocsData])
      setLastPaymentDoc(newDocs.docs[newDocsData.length - 1])
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
    }
    // console.log(pa * ps - paymentsData.length)
  }

  //`
  //`gigstackpro.pro.cleaned_payments_view`
  const DownloadXLSX = async ({ objectUrl, fname = "pagos", type = "payments" }) => {


    try {
      setdownloadingXLSX(true)
      await SignedInternalAPIRequest({
      }, `exportFiles/v1/${type}`, auth.currentUser, { isFile: true, fileName: `${fname}-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
      //DOWNLOAD FILE FROM readableStream
      setdownloadingXLSX(false)
    } catch (error) {
      message.error('Error al descargar el archivo')
      setdownloadingXLSX(false)
    }
  }


  /**
   * It takes a payment object as an argument, sets the actionOnPayment state to the payment's id, then
   * makes a request to the server to cancel the payment, and if the request is successful, it sets the
   * actionOnPayment state to null
   * @param payment - The payment object that you want to cancel.
   * @returns const resp = await SignedInternalAPIRequest({ type: 'payment_request_cancel', id:
   * payment.paymentIntent, paymentID: payment.id }, 'stripeActions', auth.currentUser)
   */
  const CancelPayment = async (payment) => {

    setactionOnPayment(payment.id)
    message.loading('Cancelando pago...')
    try {
      const resp = await SignedInternalAPIRequest({ type: 'payment_request_cancel', id: payment.paymentIntent, paymentID: payment.id, fid: payment.fid }, 'paymentsHandler', auth.currentUser)
      // console.log(resp)
      if (resp.error) {
        message.error('Error al cancelar pago')
        return setactionOnPayment(null)
      }
      message.success('Pago cancelado')
      setactionOnPayment(null)
    } catch (error) {
      // console.log(error)
      message.error('Error al cancelar pago')
      setactionOnPayment(null)
    }

  }
  /**
   * It updates the status of a recurring event to paused or active
   * @param event - The event object that is being passed to the function.
   */
  const PauseRecurringEvent = async (event) => {
    setactionOnPayment(event.id)

    try {
      var summMessage = SummarizeMessage({ state: event })
      console.log(summMessage);
      await updateDoc(doc(fs, `recurringEvents/${event.id}`), {
        status: event?.status === 'paused' ? 'active' : 'paused', pausedReason: null,
        nextRun: event?.status === 'paused' ?
          summMessage.nextHit
          : null,
        nextRunUTC: summMessage.nextHit ? moment(summMessage.nextHit).utc().valueOf() : null,
        nextRunString: summMessage.nextHit ? moment(summMessage.nextHit).format('D MMMM YYYY HH:mm') : null,
      })
      setactionOnPayment(null)
    } catch (error) {
      message.error(error.message ?? 'Error al pausar evento');
      setactionOnPayment(null)
    }
  }

  // useEffect(() => {
  //   const GetClients = async () => {
  //     const cl = await getDocs(paymentsRef)
  //     setclients(cl.docs.map(d => d.data()))
  //   }
  //   // GetClients();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])



  const [createRequest, setcreateRequest] = useState(false)


  /* Creating a new array of objects with the client name as the key and the client name as the value. */

  const filterableClients = RemoveDuplicatesFromObjectArray(([])?.map((p) => ({
    text: (p?.client?.name ?? 'Sin cliente'), value: p?.client?.name ?? 'Sin cliente', key: (p?.client?.name ?? 'Sin cliente')
  })), 'key')


  /**
   * It renders a table with the payments of the user
   */
  const RenderPayments = () => {
    const { getCurrentTeam } = useContext(TeamContext)
    return <Row style={{ width: '100%' }}>
      <Col xs={24} style={{ marginTop: '25px' }}>
        <ActionablePageHeader title="Solicitudes de pago" actions={
          <Space align="center">
            {team?.hilos?.completed && <Tooltip title="Enviar recordatorio a multiples.">
              <NotificationOutlined onClick={() => {
                setNotifyMultiple(true)
              }} style={{ marginBottom: '5px', marginRight: '10px', color: 'black' }} className="clickable" />
            </Tooltip>}
            {generalSettingsModal && (<StripeModal open={generalSettingsModal} close={({ haveChange }) => {
              setGeneralSettingsModal(false)
              if (haveChange) getCurrentTeam(team?.id)
            }}
            />
            )}
            {team?.stripe?.completed && <Button icon={<SettingOutlined />} onClick={() => { setGeneralSettingsModal(true) }} />}
            <Button loading={downloadingXLSX} icon={<CloudDownloadOutlined />} onClick={() => DownloadXLSX({ objectUrl: 'gigstackpro.pro.cleaned_payments_view', type: "payments" })} />
            {(credits > 0 || payAsYouGo) && <Button onClick={() => setRegisterPayment(true)} type="ghost">Registrar pago</Button>}
            {(credits <= 0 && !payAsYouGo) ? <Button onClick={() => {
              changeRender('settings')
              window.history.pushState({}, '', `/settings?subtab=membership`)

            }}>{isPaidUser ? 'No tienes eventos' : 'Actualiza tu plan'}</Button> : canCreatePayments && <Button disabled={credits <= 0 && !payAsYouGo} onClick={() => {
              setcreateRequest(true);
            }} type='primary'>Nueva solicitud</Button>}
          </Space>}
        />
      </Col>



      <Col xs={24} style={{ marginTop: '25px' }}>
        <Row justify="end">
          <div style={{ width: window.innerWidth < 900 ? '100%' : '50%' }}>
            <AlgoliaSearchComponent indexName='payments' teamId={team.id} type='payments' />
          </div>
        </Row>
        <Row justify="end" style={{ marginBottom: '15px', marginTop: '10px' }}>
          <div className="d-flex flex-column">
            <Space wrap size="small" style={{ marginTop: '10px' }}>
              {team?.defaults?.allowPaymentsSetting && <Tag onClick={() => setfilterBy('past')} className={filterBy === 'past' ? 'activeTag clickable' : ' clickable'}>Atrasados</Tag>}
              <Tag onClick={() => setfilterBy('succeeded')} className={filterBy === 'succeeded' ? 'activeTag clickable' : ' clickable'}>Pagados</Tag>
              <Tag onClick={() => setfilterBy('toReview')} className={filterBy === 'toReview' ? 'activeTag clickable' : ' clickable'}>Por revisar</Tag>
              <Tag onClick={() => setfilterBy('requires_payment_method')} className={(filterBy === 'requires_payment_method' || filterBy === 'requires_action' || filterBy === 'requires_confirmation') ? 'activeTag clickable' : ' clickable'}>En espera</Tag>
              <Tag onClick={() => setfilterBy('canceled')} className={filterBy === 'canceled' ? 'activeTag clickable' : ' clickable'}>Cancelados</Tag>
              <Tag onClick={() => setfilterBy('')} className={filterBy === '' ? 'activeTag clickable' : ' clickable'}>Todos</Tag>
              {/* <Tag onClick={() => setShowHidden(!showHidden)} className={showHidden ? 'activeTag clickable' : ' clickable'}>Mostrar ocultos</Tag>
              <Tag onClick={() => setShowTestMode(!showTestMode)} className={showTestMode ? 'activeTag clickable' : ' clickable'}>Ver pagos de prueba</Tag> */}
            </Space>
          </div>
        </Row>
        {showSearchModal && <SearchModal open={showSearchModal} close={() => setShowSearchModal(false)} localTypeData="payments" localData={paymentsData.map(p => {
          return {
            ...p,
            clientName: p.client?.name ?? 'Sin cliente',
            clientEmail: p.client?.email ?? 'Sin cliente',
            items: JSON.stringify(p.items),
            rawData: JSON.stringify(p)
          }
        })} changeRender={(v) => changeRender(v)} />}
        {showReceiptRelations && <Suspense fallback={<LoadingOutlined />}><RelationsDrawer open={showReceiptRelations} db="payments" relation={showReceiptRelations} render='receipts' close={() => setShowReceiptRelations(false)} /></Suspense>}
        {<ErrorBoundary>
          <Suspense fallback={<LoadingOutlined />}>
            <Table
              // dataSource={paymentsData}

              dataSource={paymentsData.filter(p => {
                if (filterBy === 'past') {
                  var limitDate = p?.limitDaysToPay ? moment(p?.timestamp).add(p?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
                  var isLate = limitDate?.valueOf() < moment().valueOf()
                  return isLate
                }

                if (filterBy === 'review_requested') {
                  return p.status === 'review_requested'
                }
                if (filterBy === 'succeeded') {
                  return p.status === 'succeeded'
                }
                if (filterBy === 'toReview') {
                  return p.status === 'review_requested'
                }
                if (filterBy === 'requires_payment_method') {
                  return p.status === 'requires_payment_method'
                }
                if (filterBy === 'canceled') {
                  return p.status === 'canceled'
                }

                if (filterBy === 'all' && filterByClient === '') {
                  return true
                }

                return p.client?.name?.toLowerCase().includes(filterByClient?.toLowerCase()) ||
                  p.client?.email?.toLowerCase().includes(filterByClient?.toLowerCase()) ||
                  p.fid?.toLowerCase().includes(filterByClient?.toLowerCase()) ||
                  p.id?.toLowerCase().includes(filterByClient?.toLowerCase()) ||
                  p.currency?.toLowerCase().includes(filterByClient?.toLowerCase()) ||
                  p.amount === parseInt(filterByClient) ||
                  p.amount === parseFloat(filterByClient) ||
                  p.amount === parseFloat(filterByClient?.replace(',', '.')) ||
                  String(p?.whmcsInvoiceId ?? '').toLocaleLowerCase().includes(filterByClient.toLocaleLowerCase())

              })}
              pageSize={pageSize}
              loading={{
                spinning: tableLoading,
                indicator: <LoadingOutlined style={{ color: '#8666FF' }} />,
              }}
              pagination={{
                pageSize: pageSize,
                defaultCurrent: pageActive,
                onChange: (p, ps) => {
                  setpageActive(p)
                  setpageSize(ps)
                  if (paymentsData.length <= p * ps) {
                    getNextPage({ pa: p, ps: ps })
                  }
                },
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: (total, range) => {
                  if (filterByClient) {
                    return `${range[0]}-${range[1]} de ${paymentsData.length} pagos`
                  } else {
                    return `${range[0]}-${range[1]} de ${paymentsData.length} pagos`
                  }
                },
                // showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} pagos`,
                position: ['bottomCenter'],
                defaultPageSize: 10,
                showQuickJumper: false,
                responsive: true,
                total: filterByClient ? filterByClient : totalPayments ?? 1,

                //total: totalPayments ?? 1,
                locale: { items_per_page: '/ página' }
              }}
              onChange={(p, filters, sorter, extra) => {
                if (extra.action === 'filter') {
                  setdefaultClientSearch(extra.currentDataSource?.map((d) => d.client?.name ?? 'Sin cliente'))
                }
                setpageSize(p.pageSize)
              }}
              empty='No tienes solicitudes de pago'
              elementName="solicitud de pago"

              locale={
                {
                  emptyText: (
                    <div style={{ textAlign: 'center' }}>
                      <p>No se encuentra registro en los últimos 35 dias, busca en todo tu historial</p>
                      <Button shape="circle" icon={<SearchOutlined />} onClick={() => setShowSearchModal(true)} />
                    </div>
                  ),
                }
              }

              emptyAction={() => {
                setcreateRequest(true)
              }}
              columns={[
                {
                  title: <TableTitle title="Cliente" />,
                  dataIndex: 'client',
                  key: generateCode(5),
                  filters: filterableClients,
                  filterMode: 'multiple',
                  defaultFilteredValue: defaultClientSearch,
                  onFilter: (value, record) => {
                    return (record.client?.name ?? 'Sin cliente') === value
                  },
                  fixed: 'left',
                  width: '15%',
                  sorter: (a, b) => ((a?.name)?.localeCompare(b?.name)),
                  render: (text, record) => {
                    const clientId = record?.clientId ?? record?.clientID
                    return <div className="d-flex flex-column">
                      <Row align='middle'>
                        {record.hidden && <Tooltip title="Oculto">
                          <EyeInvisibleOutlined onClick={() => {
                            updateDoc(doc(fs, 'payments', record.fid), { hidden: false })
                          }} style={{ marginRight: '5px' }} type="secondary" className="secondary" />
                        </Tooltip>}
                        <div className='d-flex flex-row'>
                          <Typography.Text onClick={() => {
                            changeRender('paymentDetails')
                            return window.history.replaceState(null, `tab`, `/paymentDetails?id=${record?.fid}&pageActive=${pageActive}`)
                          }} className="primary clickable" style={{ flex: 1 }} >{record.client?.name ?? 'Sin Cliente'}</Typography.Text>
                          {((!clientId || record?.clientEdited) && [...(record?.invoices ?? [])].length === 0) &&
                            <Tooltip title="Asignar cliente">
                              <UserAddOutlined onClick={() => setSelectClient(record)} style={{ color: '#8666FF', fontSize: '14px', margin: '0px 5px' }} />
                            </Tooltip>
                          }
                        </div>
                        {!record.livemode && <Tooltip title="Modo pruebas">
                          <ExperimentOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#cecece' }} />
                        </Tooltip>}
                      </Row>
                      <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{record.client?.company ?? ''}</Typography.Text>
                      <Typography.Text copyable type="secondary" style={{ fontSize: '10px' }}>{record.fid}</Typography.Text>
                      {(record.orderId || record?.metadata?.orderId) && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Order ID: {record.orderId ?? record.metadata.orderId}</Typography.Text>}
                      {record?.whmcsInvoiceId && <Typography.Text type="secondary" style={{ fontSize: '11px' }}>WHMCS: {record?.whmcsInvoiceId ?? ''}</Typography.Text>}
                      {(record.conceptReference && record.id.includes('payment_')) && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Referencia: {record.conceptReference}</Typography.Text>}
                    </div>
                  }
                },
                {
                  title: <TableTitle title="Estado" />,
                  dataIndex: 'status',
                  key: generateCode(5),
                  width: '12%',
                  ellipsis: true,
                  sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
                  render: (text, record) => {
                    var limitDate = record?.limitDaysToPay ? moment(record?.timestamp).add(record?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null

                    return <div>
                      <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                        <Tooltip title={paymentIntentsStatusMessage(record)}>
                          <div> <Tag className="statusTag" color={returnColorByPaymentStatus(record)} style={{
                            color: returnTextColorByPaymentStatus(record), borderRadius: '10px', opacity: 0.9, padding: '0px 10px', fontWeight: 500
                          }}>{paymentIntentsStatusMessage(record)}</Tag></div>
                        </Tooltip>
                        {(team?.defaults?.allowPaymentsSetting && limitDate?.valueOf() < moment().valueOf() && record?.paymentLimit !== null && record.status !== 'succeeded') &&
                          <div style={{ marginTop: '5px' }}>  <Tag color="red" >{(limitDate?.valueOf() < moment().valueOf()) ? `PAGO ATRASADO ${moment(record?.timestamp).add(record?.limitDaysToPay, 'days').hours(team?.defaults?.limitHourToPay ?? '17').fromNow()}` : ''}</Tag></div>
                        }
                      </Row>
                    </div>

                  }
                },

                {
                  title: <TableTitle title="Total" />,
                  dataIndex: 'amount',
                  align: 'right',
                  width: '12%',
                  key: generateCode(5),
                  sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),

                  render: (text, record) => {
                    var showTotal = getItemsAmounts(record.items, (record.paidIn === 'bank')).total

                    return <Tooltip title={record.exchangeRate ? `${returnCurrencyValue((showTotal) * (record.exchangeRate ?? 1))} mxn` : null}>
                      <Row>
                        <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => {
                          // console.log(getItemsAmounts(record.items, (record.paidIn === 'bank')));

                        }} ellipsis>{returnCurrencyValue(showTotal)} {(record?.currency ?? '').toUpperCase()} </Typography.Text>
                        {(record.addTransactionFee && !record.updatedOnCreate) && <Tooltip title="Se añadirá el fee de transacción">
                          <ClockCircleOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#cecece' }} />
                        </Tooltip>}
                      </Row>
                    </Tooltip>
                  }
                },
                {
                  title: <TableTitle title="Creado" />,
                  dataIndex: 'timestamp',
                  width: '12%',
                  key: generateCode(5),
                  sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),
                  render: (text, record) => {

                    return <div className="d-flex flex-column">
                      <Typography.Text type="secondary" >{moment(record.timestamp).format('DD MMM YYYY HH:mm')}</Typography.Text>
                    </div>
                  }
                },
                {
                  title: <TableTitle title="Fecha de pago" />,
                  dataIndex: 'succeededTimestamp',
                  key: 'succeededTimestamp',
                  width: '12%',
                  sorter: (a, b) => moment(ReturnCorrectedPayment(a).succeededTimestamp).isBefore(moment(moment(ReturnCorrectedPayment(b).succeededTimestamp))) ? 1 : -1,
                  render: (text, record) => {
                    return <div className="d-flex flex-column">
                      <Typography.Text type="secondary" >{record.status === 'succeeded' ? moment(record?.succeededTimestamp ?? record?.timestamp).format('DD MMM YYYY HH:mm') : ''}</Typography.Text>
                    </div>

                    // <Typography.Text type="secondary" onClick={() => {

                    // }}  >{record.status === 'succeeded' ? moment(ReturnCorrectedPayment(record).succeededTimestamp).format('DD MMM YYYY HH:mm:ss') : ''}</Typography.Text>
                  }
                },
                {
                  title: <TableTitle title="Automatización" />,
                  dataIndex: 'automations',
                  key: generateCode(5),
                  width: '13%',
                  align: 'center',
                  render: (text, record) => {
                    const hasAutomation = record.automations?.length > 0
                    const isSucceeded = record.status === 'succeeded'
                    const automationFailled = hasAutomation && isSucceeded && !record.invoices && !record.receipts && moment().diff(moment(record.timestamp), 'minutes') > 2
                    const automationMessage = automationFailled ? 'Automatización fallida' : !hasAutomation ? "Sin automatización" : (hasAutomation && !isSucceeded) ? 'Automatización para factura' : 'Automatización exitosa'
                    return <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <div style={{ marginRight: '10px' }}>
                        {hasAutomation && <Tooltip title={automationMessage}>
                          {hasAutomation && !isSucceeded ? <RobotFilled style={{ color: '#6B7CF9', fontSize: 18 }} /> : null}
                          {automationFailled ? <RobotOutlined style={{ color: '#f96f6a', fontSize: 18 }} /> : null}
                          {(hasAutomation && isSucceeded && !automationFailled) ? <RobotOutlined style={{ color: '#b7b7b7', fontSize: 18 }} /> : null}
                        </Tooltip>}
                      </div>
                      {(record.automationWarnings?.length > 0 && (record.invoices?.length ?? 0) <= 0) && <Tooltip title={<div className="d-flex flex-column">
                        <Typography.Text className='strong' style={{ color: 'white' }}>Errores en automatización</Typography.Text>
                        <Typography.Text style={{ fontSize: '10px', color: 'white' }}>{record.automationWarnings.map((a) => a + '' + a + '\n')}</Typography.Text>
                      </div>}>
                        <WarningOutlined className="" style={{ color: '#b7b7b7' }} />
                      </Tooltip>}
                    </div>
                  }
                },
                {
                  title: <TableTitle title="Facturas" />,
                  dataIndex: 'invoices',
                  key: generateCode(5),
                  width: '12%',
                  render: (text, record) => {
                    const receipts = (record.receipts ?? []).length
                    return <div className="d-flex flex-column">
                      <InvoicesRelations custom={true} items={record?.items} collection="payments" elementType="payment" changeState={(i) => {
                      }} client={record?.client} item={{ ...record, invoices: record.invoices }} id={record?.fid} userDocument={userDocument} readable="pago" />

                      {receipts > 0 && <div style={{ marginLeft: '10px' }}>
                        <span onClick={() => setShowReceiptRelations({
                          title: `Recibos de venta relacionados`,
                          type: 'invoices',
                          db: 'payments',
                          relatedWith: record,
                          count: record?.receiptsCount ?? 1,
                          elementID: record?.fid,
                          subdb: 'receipts',
                          item: record
                        })} style={{ color: '#757575', fontSize: '11px', cursor: 'pointer' }}>{record.receipts?.length} recibo{receipts > 1 ? 's' : ''} de venta</span>
                      </div>}
                    </div>
                  }
                },
                {
                  title: <TableTitle title="" />,
                  dataIndex: 'actions',
                  key: generateCode(5),
                  width: '13%',
                  render: (text, record, i) => {

                    const amounts = getItemsAmounts(record.items);

                    const items = [];

                    if (record?.token && record?.status !== 'succeeded' && record?.status !== 'canceled') {
                      items.push(
                        {
                          label: 'Copiar link de pago',
                          key: 'copyLink',
                          icon: <LinkOutlined />,
                          onClick: () => {
                            setactionOnPayment(record.id);
                            CopyToClipboard(record.shortURL ?? `https://beta.gigstack.pro/pay/${record.token}`);
                            setactionOnPayment(null);
                          },
                        },
                        {
                          label: 'Enviar link por correo',
                          key: 'sendEmail',
                          icon: <MailOutlined />,
                          onClick: () => {
                            setComposeEmail(record);
                          },
                        },
                      )
                    }

                    if (record?.shortURL && record.status !== 'succeeded' && record.status !== 'canceled') {
                      items.push({
                        label: 'Compartir vía WhatsApp',
                        key: 'shareWhatsApp',
                        icon: <WhatsAppOutlined />,
                        onClick: () => {
                          ShareViaWhatsapp(
                            `Puedes realizar el pago por ${amounts.totalString} ${record.currency} en el siguiente enlace! 😉 : ${record.shortURL}`
                          );
                        },
                      })
                    }

                    if (record.status === 'canceled') {
                      items.push({
                        label: (
                          <Popconfirm
                            showArrow={false}
                            title="¿Estás seguro de eliminar el pago?"
                            okText="Eliminar pago"
                            cancelText="No"
                            onConfirm={() => {
                              if (
                                (record.status === 'canceled' || record.object === 'manual_payment') &&
                                record.review_status !== 'succeeded'
                              ) {
                                DeleteRecord(record.fid, i);
                              }
                            }}
                          >
                            <Typography.Text type="danger">
                              Eliminar pago
                            </Typography.Text>
                          </Popconfirm>
                        ),
                        key: 'deletePayment',
                        icon: <DeleteOutlined style={{ color: 'red' }} className="clickable itemHoverDanger" />,
                      },)
                    }

                    if ((record.status === 'requires_payment_method' || record.status === 'requires_action') && (record.status !== 'canceled' && record.status !== 'succeeded')) {
                      items.push({
                        label: (
                          <Popconfirm
                            title="¿Estás seguro de cancelar el pago?"
                            okText="Cancelar pago"
                            cancelText="No"
                            onConfirm={() => {
                              if (
                                record.status === 'requires_payment_method' ||
                                record.status === 'requires_confirmation' ||
                                record.status === 'pending' ||
                                record.status === 'requires_action'
                              ) {
                                CancelPayment(record);
                                // message.success('Pago cancelado correctamente')
                              }
                            }}
                          >
                            <Typography.Text type="danger">
                              Cancelar pago
                            </Typography.Text>
                          </Popconfirm>
                        ),
                        key: 'cancelPayment',
                        icon: <StopOutlined style={{ color: 'red' }} className="itemHoverDanger clickable" />,

                      })
                    }
                    if (record.charges?.data?.length > 0) {
                      var URL = record.charges?.data[0]?.receipt_url;
                      items.push({
                        label: 'Ver Recibo de Stripe',
                        key: 'ViewReceipt',
                        icon: <BarcodeOutlined className="itemHoverPrimary" />,
                        onClick: () => {
                          window.open(URL, '_blank')
                        }
                      },)
                    }
                    const menu = (
                      <Menu>
                        {items.map((item) => (
                          <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
                            {item.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    );

                    return (
                      <div className="custom-actions">
                        <Dropdown overlay={menu} trigger={['hover']} mouseLeaveDelay={0.1}>
                          <Button
                            style={{
                              borderRadius: 0,
                              borderTopRightRadius: '0.25rem',
                              borderBottomRightRadius: '0.25rem',
                            }}
                          >
                            Acciones <DownOutlined />
                          </Button>
                        </Dropdown>
                      </div>
                    );
                  },
                },
              ]}
              onPageChange={(p) => {
                setpageActive(p)
              }}
              defaultPage={pageActive}
            />
          </Suspense>
        </ErrorBoundary>}
      </Col>
    </Row>
  }



  const RenderRecurringPayments = () => {
    const handleDownload = async () => {
      try {
        const querySnapshot = await getDocs(recurringRef);
        const data = querySnapshot.docs.map((doc) => doc.data());

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Recurring Events');

        const headers = ['Cliente', 'Compañia', 'Estado', 'Total', 'Creado y finalizado', 'Temporalidad', 'Eventos', 'Siguiente evento'];
        worksheet.addRow(headers);

        data.forEach((row) => {
          const active = row.status === 'active';
          console.log(row)
          const rowData = [
            row.client?.name,
            row.client?.company,
            row.status,
            getItemsAmounts(row?.replicateElement?.items, row?.paidIn === 'bank').totalString,
            `Iniciando ${moment(row.startDate).format('DD/MM/YY')} terminando ${moment(row.endDate).format('DD/MM/YY')}`,
            row.temporality === 'daily' ? `Diario a las ${row.onTime}` : row.temporality === 'weekly' ? `Semanal, los ${moment().day(row.onWeekday ?? '01').format('dddd')} ${row.onTime}` : `Mensual los días ${row.onDay} ${row.onTime}`,
            row?.relations?.length ?? '0',
            active ? row.nextRun ? moment(row.nextRun).format('ddd D MMMM YYYY HH:mm ') : row.pausedReason ? row.pausedReason : '-' : '-',
          ];
          worksheet.addRow(rowData);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'pagos_recurrentes.xlsx';
        link.click();

        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error al descargar los datos', error);
      }
    };
    return <Row style={{ width: '100%' }}>
      <Col xs={24} style={{ marginTop: '25px' }}>
        <ActionablePageHeader title="Solicitudes recurrentes" actions={
          <Space>
            <Button loading={downloadingXLSX} onClick={handleDownload}>Descargar</Button>
            {canCreatePayments && <Button onClick={() => { setcreateRecurring(true) }} type='primary'>Nuevo evento</Button>}
          </Space>}
        />
      </Col>
      <Col xs={24} style={{ marginTop: '25px' }}>
        <Row justify="end" style={{ marginBottom: '10px' }}>
          <Input.Search style={{ width: window.innerWidth <= 800 ? '100%' : '40%' }} placeholder="Buscar cliente" defaultValue={filterByClient} onSearch={setfilterByClient} allowClear />
        </Row>
        <DefaultTableItems empty='No tienes eventos recurrentes de pago'
          byClient={filterByClient}
          defaultPage={pageActive}
          expandable={{
            expandedRowRender: record => {
              return <div className="d-flex flex-column">

                <ul>
                  {record?.replicateElement?.items?.map((item, i) => {
                    return <li key={i}>{item.name} - {returnCurrencyValue(item.total)} {record.replicateElement.currency} {item.master && <span style={{ textDecoration: 'underline', fontSize: '10px', color: '#333' }}>{returnCurrencyValue(item.master)} sin comisión</span>}</li>
                  })}
                </ul>
                <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{(record?.addTransactionFee || record?.replicateElement?.items[0]?.master) && 'Se añadirá el costo de transacción al momento del cobro'}</Typography.Text>
              </div>
            },
            rowExpandable: record => record.replicateElement,
          }}
          columns={[
            {
              title: <TableTitle title="Cliente" />,
              dataIndex: 'client',
              key: generateCode(5),
              fixed: 'left',
              width: '16%',
              // sorter: (a, b) => ((a?.name).localeCompare(b?.name)),

              render: (text, record) => {

                return <Row align="middle">
                  <Tooltip title={record.status === 'active' ? 'Pausar' : 'Activar'}>
                    <div onClick={() => {
                      PauseRecurringEvent(record)
                    }} className='customCircledTag' style={{ width: '30px', height: '30px' }}>
                      {actionOnPayment === record.id ? <LoadingOutlined /> : record.status === 'active' ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                    </div>
                  </Tooltip>
                  <div className="d-flex flex-column" style={{ flex: 1 }} >
                    <Typography.Text className="primary">{record.client?.name ?? 'Sin Cliente'}</Typography.Text>
                    <Typography.Text className="" type="secondary" style={{ fontSize: '8px' }} copyable={{ text: record.id }} >{record.id}</Typography.Text>
                  </div>
                </Row>
              }
            },
            {
              title: <TableTitle title="Estado" />,
              dataIndex: 'status',
              key: generateCode(5),
              ellipsis: true,
              sorter: (a, b) => ((a?.status).localeCompare(b?.status)),
              render: (text, record) => {
                return <Tooltip title="">
                  <Tag className="statusTag" style={{ borderRadius: '10px', opacity: 0.7 }}>{text === 'active' ? 'Activo' : 'En pausa'}</Tag>
                </Tooltip>
              }
            },
            {
              title: <TableTitle title="Total" />,
              dataIndex: 'total',
              key: generateCode(5),
              ellipsis: true,
              render: (text, record) => {

                return <Row align="middle">
                  <Typography.Text className="" type="secondary" style={{ margin: 0, padding: 0 }} >{getItemsAmounts(record?.replicateElement?.items, record?.paidIn === 'bank').totalString} {record?.replicateElement?.currency}</Typography.Text>
                  {record?.replicateElement?.addTransactionFee && <Tooltip title="Se añadirá el fee de transacción">
                    <PlusCircleOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#333' }} />
                  </Tooltip>}
                </Row>

              }
            },
            {
              title: <TableTitle title="Creado" />,
              dataIndex: 'timestamp',
              key: generateCode(5),
              ellipsis: true,
              render: (text, record) => {

                return <div className="d-flex flex-column">
                  <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{record.temporality === 'daily' ? `Diario a las ${record.onTime}` : record.temporality === 'weekly' ? `Semanal, los ${moment().day(record.onWeekday ?? '01').format('dddd')} ${record.onTime}` : `Mensual los días ${record.onDay} ${record.onTime}`}</Typography.Text>
                  <Typography.Text className="secondary" style={{ fontSize: '8px', marginBottom: '0px' }} >{`Iniciando ${moment(record.startDate).format('DD/MM/YY')}`} terminando {moment(record.endDate).format('DD/MM/YY')}</Typography.Text>

                </div>
              }
            },
            {
              title: <TableTitle title="Temporalidad" />,
              dataIndex: 'timestamp',
              key: generateCode(5),

              render: (text, record) => {

                return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{record.temporality === 'daily' ? `Diario a las ${record.onTime}` : record.temporality === 'weekly' ? `Semanal, los ${moment().day(record.onWeekday ?? '01').format('dddd')} ${record.onTime}` : `Mensual los días ${record.onDay} ${record.onTime}`}</Typography.Text>
              }
            },
            {
              //TODO: MANAGE NEXT HIT
              title: <TableTitle title="Eventos" />,
              dataIndex: 'hits',


              render: (text, record) => {

                const relations = record.relations || []

                return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{relations?.length > 0 ? `${relations.length} creados` : ''} </Typography.Text>
              }
            },
            {
              //TODO: MANAGE NEXT HIT
              title: <TableTitle title="Siguiente evento" />,
              dataIndex: 'nextHit',


              render: (text, record) => {
                const active = record.status === 'active';
                return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{active ? record.nextRun ? moment(record.nextRun).format('ddd D MMMM YYYY HH:mm ') : record.pausedReason ? record.pausedReason : '-' : '-'}</Typography.Text>
              }
            },
            {
              //TODO: MANAGE NEXT HIT
              title: <TableTitle title="" />,
              dataIndex: 'actions',


              render: (text, record) => {
                return <Space>
                  {canCreatePayments && <EditOutlined onClick={() => {

                    console.log({
                      ...record, recordID: record.id, ...record.replicateElement, startEndDate: [moment(record.startDate), moment(record.endDate)], startDate: moment(record.startDate), endDate: moment(record.endDate), temporality: {
                        value: record.temporality,
                        label: record.temporality === 'daily' ? 'Diario' : record.temporality === 'weekly' ? 'Semanal' : 'Mensual'
                      },
                      onTime: moment().hour(record.onTime.split(':')[0]).minute(record.onTime.split(':')[1]),
                      // onTime: moment().hour(record.onTime.split(':')[0]).minute(record.onTime.split(':')[1])
                    })
                    seteditRecurring({
                      ...record, recordID: record.id, ...record.replicateElement, startEndDate: [moment(record.startDate), moment(record.endDate)], startDate: moment(record.startDate), endDate: moment(record.endDate), temporality: {
                        value: record.temporality,
                        label: record.temporality === 'daily' ? 'Diario' : record.temporality === 'weekly' ? 'Semanal' : 'Mensual'
                      },
                      onTime: moment().hour(record.onTime.split(':')[0]).minute(record.onTime.split(':')[1]),
                    })
                    setcreateRecurring(true)
                  }} />}
                  {canCreatePayments && <Popconfirm title="¿Eliminar solicitud recurrente?" okButtonProps={{ danger: true }} okText="Eliminar" onConfirm={async () => {
                    try {
                      await updateDoc(doc(fs, 'recurringEvents', record.id), { status: 'deleted', team: `old-${record?.team}` })
                    } catch (error) {
                      message.error(error.message ?? 'Error al pausar evento');
                    }

                  }}>
                    <DeleteOutlined />
                  </Popconfirm>}
                </Space>
              }
            },



          ]}
          query={recurringRef}
          // data={recurringData ?? []} 
          // loading={paymentsDBStatus === 'loading'} 
          onPageChange={(page, pageSize) => {
            setpageActive(page)
            setpageSize(pageSize)
          }}
          userDocument={userDocument} />

      </Col>
    </Row>
  }













  return <Row justify='center' >
    {registerPayment && <Suspense>
      <RegisterPaymentModal open={registerPayment} close={() => setRegisterPayment(false)} />
    </Suspense>}
    {notifyMultiple && <Suspense>
      <NotifyMultiple open={notifyMultiple} close={() => setNotifyMultiple(false)} userDocument={userDocument} />
    </Suspense>}
    {selectClient && <Suspense>
      <ClientsListModal importFromStripe={false} open={selectClient} userDocument={userDocument} close={() => setSelectClient(false)} onSelect={async (c) => {
        try {
          await updateDoc(doc(fs, 'payments', selectClient?.fid ?? selectClient?.id), {
            clientID: c.id,
            clientId: c.id,
            client: {
              ...c
            },
            clientEdited: true
          })
          message.success('Cliente asignado al pago')
          setSelectClient(null)
        } catch (error) {
          message.error(error?.message ?? 'Error al asignar cliente')
        }
      }} />
    </Suspense>}
    {confirmPayment && <ReviewPaymentModal open={confirmPayment} close={() => setconfirmPayment(false)} payment={confirmPayment} />}
    {createRequest && <Suspense fallback={<>loading..</>}>
      <NewPaymentRequest
        open={createRequest}
        close={(v) => {
          setcreateRequest(false)
        }}
        userDocument={userDocument}
        data={{}} />

      {/* <CreateElementHolder refreshUserDocument={true} open={createRequest} close={(v) => {
                setcreateRequest(false)
            }} data={{}} userDocument={userDocument} type="payments" /> */}
    </Suspense>}
    {createRecurring && <Suspense fallback={<>loading..</>}>
      <NewRecurringPayment edit={editRecurring?.id ? editRecurring : null} open={createRecurring} close={(v) => {
        if (v) {

        }
        setcreateRecurring(false)
        seteditRecurring(null)
      }} data={{}} userDocument={userDocument} type="recurringPayment" />
    </Suspense>}
    {composeEmail && <ComposeEmailModal close={() => setComposeEmail(null)} actionText={
      null
    } idRef={'payments'} data={{ ...composeEmail, payment: composeEmail }} userDocument={userDocument} open={composeEmail} element={composeEmail} subject={`Solicitud de pago por ${getItemsAmounts(composeEmail.items, false).totalString}`} readableType={'pago'} client={composeEmail.client ?? { name: 'Sin Cliente', email: '', }} />}
    <Col xs={24}>
      <DefaultHelpPageHeader userDocument={userDocument} title="Pagos" description={"Solicita pagos a tus clientes de manera fácil y rápida."} />
    </Col>
    {canCreatePayments && <RenderToReview userDocument={userDocument} setconfirmPayment={(v) => setconfirmPayment(v)} />}
    <Col xs={24} style={{ marginTop: '25px' }}>
      <Tabs onChange={() => { }} type="card">

        <Tabs.TabPane tab={<Row align="middle">
          <ClockCircleOutlined /><Typography.Text> Pagos</Typography.Text>
        </Row>} key="payments">
          <RenderPayments />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<Row align="middle">
          <ClockCircleOutlined /><Typography.Text> Solicitudes recurrentes</Typography.Text>
        </Row>} key="recurringEvents">
          <RenderRecurringPayments />
        </Tabs.TabPane>

      </Tabs>
    </Col>


  </Row >




}

export default PaymentsPage;