import { LoadingOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Row, Typography } from 'antd';
import React, { useContext, useState } from 'react'
import { useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { useMembership } from '../../customHooks/useMembership';




function ActivatePayAsYouGoModal({ open, close, authData }) {
    const { billingAccount } = useContext(BillingAccountContext)


    const { payAsYouGoAllowed, price_id } = useMembership({ uid: authData.uid })
    const auth = useAuth()
    const [loading, setLoading] = useState(false)



    const ActivatePayAsGo = async () => {
        try {
            setLoading(true)
            await SignedInternalAPIRequest({

            },
                'payAsYouGo/v1/activate',
                auth.currentUser,
                {},
                'POST'
            )
            setLoading(false)
            close();
        } catch (error) {
            message.error(error.message)
            setLoading(false)
        }
    }



    return (
        <Modal visible={open} onCancel={() => close()} footer={null} title={null}>
            {billingAccount ? <div className="d-flex flex-column">
                <Row align="middle">
                    <div className="d-flex flex-column">
                        <Typography.Title style={{ margin: '0', flex: 1 }} level={4}>Pay as you go</Typography.Title>
                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Asegúrate que tu cuenta nunca se quede en pausa.</Typography.Text>
                    </div>
                </Row>

                <Divider></Divider>
                <Row>
                    <div style={{ alignItems: 'center', justifyContent: 'center', justifyItems: 'center', backgroundColor: "#8666FF", marginRight: '20px', height: '50px', width: 'auto', borderRadius: '10px' }} className="d-flex flex-column">
                        <Typography.Title style={{ margin: 0, padding: '0px 10px', color: 'white' }} level={4}>$1.25 mxn</Typography.Title>
                    </div>
                    <div className="d-flex flex-column" style={{ alignItems: 'start', justifyContent: 'center', flex: 1 }}>
                        <Typography.Title style={{ margin: '0px', }} level={5}>Evento extra utilizado</Typography.Title>
                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Una vez que tus eventos se terminen, cobraremos $1.25mxn + IVA por cada evento utilizado.</Typography.Text>
                    </div>

                </Row>
                <Row justify="center" style={{ marginTop: '25px' }}>
                    <Button onClick={ActivatePayAsGo} disabled={!payAsYouGoAllowed} loading={loading} type="primary">Activar</Button>
                </Row>
                {!payAsYouGoAllowed && <Row justify="center">
                    <Typography.Text type="secondary" style={{ fontSize: '12px', marginTop: '10px' }}>La opción se encuentra desactivada para este plan,  <a href={`mailto:support@gigstack.io?subject=Cambio de plan&body=--------Por favor escribe debajo de esta línea-------%0d%0a%%0d%0a%%0d%0a%--------Por favor escribe encima de esta línea-------%0d%0aID:${authData.uid}%0d%0aPlan actual:${price_id}`} target="_blank" rel="noreferrer">solicita un cambio de plan</a></Typography.Text>
                </Row>}
            </div> : <LoadingOutlined />}
        </Modal>
    );
}

export default ActivatePayAsYouGoModal;