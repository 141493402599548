import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Row, Select, Switch, Typography } from 'antd';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { paymentForms, ProductsAvailableForSat } from '../../app/functions/helpers';
import CurrencyChip from '../cards/CurrencyChip';
import ClientsAndItemsSelect from '../forms/ClientsAndItemsSelect';
// import Select from 'react-select'
// import { generalreactSelectStyles } from '../../app/functions/styles';
import { Currencies } from '../../app/functions/datasets';
import { doc, getFirestore } from 'firebase/firestore';
import { useAnalytics, useAuth, useFirestoreDocDataOnce } from 'reactfire';
import moment from 'moment';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import LoadingAndResponse from '../states/LoadingAndResponse';
import ElementCreated from '../states/ElementCreated';
import { logEvent } from 'firebase/analytics';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';




function CreateReceipt({ close, userDocument, open, data, edit: createReceiptModalEdit, onlyContent = false }) {
    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const auth = useAuth()

    const ref = doc(getFirestore(), 'rates/rates')

    const { data: rateData } = useFirestoreDocDataOnce(ref)


    const [state, setstate] = useState({
        client: createReceiptModalEdit?.client ?? null,
        items: createReceiptModalEdit?.items ?? [],
        currency: createReceiptModalEdit?.currency ?? 'MXN',
        livemode: createReceiptModalEdit ? createReceiptModalEdit?.livemode : true,
        test: createReceiptModalEdit ? !createReceiptModalEdit?.livemode : false,
        exchange: 1,
        isEdit: createReceiptModalEdit ? true : false,
    })
    const [step, setstep] = useState(0);
    const [loading, setloading] = useState(false)
    const [apiloading, setapiloading] = useState(false)
    const [error, seterror] = useState(null)

    const [receiptOk, setreceiptOk] = useState(null)
    const analytics = useAnalytics();


    /**
     * It creates a receipt.
     */
    const GenerateReceipt = async () => {
        var validDays = validTime();
        const body = { ...state, ...form.getFieldsValue(), validDays, validUntil: validDays, currency: state.currency, type: 'create_receipt', livemode: state.livemode, team: team?.id, billingAccount: billingAccount?.id, periodicity: state.periodicity, forcedId: createReceiptModalEdit?.id ?? null, timestamp: createReceiptModalEdit?.timestamp ?? null }

        setapiloading(true)
        setstep(step + 1)
        try {




            setapiloading(true)
            logEvent(analytics, 'receiptCreated', {});
            const resp = await SignedInternalAPIRequest(body, 'receiptsApp/v1/create', auth.currentUser)

            setreceiptOk({ ...resp.receipt, hasError: false })
            setapiloading(false)
            setstep(step + 2)
        } catch (error) {
            seterror(error.message)
            setapiloading(false)
            setreceiptOk(null)
            setloading(false)
        }
    }

    /**
     * It returns an object with a disabled property and a message property
     * @returns {
     *         disabled: false,
     *         message: 'Crear recibo de venta'
     *     }
     */
    const HandleButtonState = () => {
        if (step === 0) {
            // const satAvailable = ProductsAvailableForSat(state.items).available

            // if (!satAvailable) {
            //     return { disabled: true, message: 'Tus servicios no cuentan con la información correcta' }
            // }
            // if (!ClientReadyForInvoice(state.client) && state.client) {
            //     return { disabled: true, message: 'Tu cliente no cuenta con los datos necesarios para generar una factura' }
            // }
            if (state.items.length === 0) {
                return { disabled: true, message: 'Agrega al menos un servicio' }
            }


            return { disabled: false, message: 'Continuar' }
        } else if (step === 1) {
            return { disabled: false, message: 'Crear recibo de venta' }
        } else {
            return { disabled: false, message: 'Crear recibo de venta' }
        }

    }
    const HandleButtonStateEdit = () => {
        if (step === 0) {
            // const satAvailable = ProductsAvailableForSat(state.items).available

            // if (!satAvailable) {
            //     return { disabled: true, message: 'Tus servicios no cuentan con la información correcta' }
            // }
            // if (!ClientReadyForInvoice(state.client) && state.client) {
            //     return { disabled: true, message: 'Tu cliente no cuenta con los datos necesarios para generar una factura' }
            // }



            return { disabled: false, message: 'Continuar' }
        } else if (step === 1) {
            return { disabled: false, message: 'Editar recibo de venta' }
        } else {
            return { disabled: false, message: 'Editar recibo de venta' }
        }

    }
    /**
     * It checks the current step, and if it's 0, it sets the step to 1, and sets the exchange rate to 1 if
     * the currency is MXN, or to the exchange rate of the currency if it's not
     * @returns if (step === 0) {
     *         setstep(1)
     *         var exchange = 1
     *         if (state.currency !== 'MXN') {
     *             exchange = rateData?.conversion_rates[state.currency?.toUpperCase()] ?? 1
     *         }
     *         form.setFieldsValue({ exchange, currency: {
     */
    const HandleButtonAction = () => {
        if (step === 0) {
            setstep(1)
            var exchange = 1
            if (state.currency !== 'MXN') {
                var rateD = rateData?.conversion_rates[state.currency?.toUpperCase()]
                exchange = rateD ? (1 / rateD) : 1
                setstate({ ...state, exchange: exchange })
            }
            form.setFieldsValue({ exchange, currency: { label: state.currency?.toUpperCase(), value: state.currency?.toUpperCase() } })
            return
        }
        if (step === 1) {
            setstep(2)
            return GenerateReceipt()
        }
        if (step === 2) {
            return
        }
    }

    const [form] = Form.useForm();

    useEffect(() => {
        if (createReceiptModalEdit) {
            form.setFieldsValue({ ...createReceiptModalEdit, payment_form: createReceiptModalEdit?.payment_form, test: !createReceiptModalEdit?.livemode })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createReceiptModalEdit])
    /**
     * It returns the number of milliseconds until the end of the current day, week, fortnight, month, or
     * two months, depending on the value of the state.periodicity variable
     * @returns the validDays variable.
     */
    const validTime = () => {
        var validDays = moment().endOf('day').valueOf();
        switch (state.periodicity) {
            case 'day':
                validDays = moment().endOf('day').valueOf()
                break;
            case 'week':
                validDays = moment().day(7).endOf('day').valueOf()
                break;
            case 'fortnight':
                //VALID DAYS IS EQUAL TO MIDDLE OR END OF THE CURRENT MONTH
                validDays = moment().day(15).endOf('day').valueOf()
                break;
            case 'month':
                validDays = moment().endOf('month').valueOf()

                break;
            case 'two_months':
                validDays = moment().endOf('month').add(1, 'month').valueOf()

                break;
            default:
                validDays = moment().endOf('day').valueOf()
                break;
        }

        return validDays
    }



    /**
     * `const ReceiptInfo = () => { return <Row style={{ width: '100%', marginTop: '15px' }} gutter={{ xs:
     * 0, md: 10, lg: 12 }}> <Col xs={24}> <Form.Item name="payment_form" label="Forma de pago" rules={[{
     * required: true, message: 'Por favor selecciona una forma de pago.' }]}> <Select
     * styles={generalreactSelectStyles} className="pro-select-input" options={paymentForms}
     * isLoading={false} onChange={(v) => { }} placeholder="Forma de pago" /> </Form.Item> </Col> <Col
     * xs={24} md={12}> <Form.Item name="currency" label="Moneda" rules={[{ required: true, message: 'Por
     * @returns A React component.
     */

    const ReceiptInfo = () => {
        return <Row style={{ width: '100%', marginTop: '15px' }} gutter={{ xs: 0, md: 10, lg: 12 }}>
            <Col xs={24}>
                <Form.Item name="payment_form" label="Forma de pago" rules={[{
                    required: true,
                    message: 'Por favor selecciona una forma de pago.'
                }]}>
                    {/* <Select
                        styles={generalreactSelectStyles}
                        className="pro-select-input"
                        options={paymentForms}
                        isLoading={false}
                        onChange={(v) => {

                        }}
                        placeholder="Forma de pago"
                    /> */}
                    <Select
                        placeholder="Forma de pago"
                        options={paymentForms}
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>

                <Form.Item name="currency" label="Moneda" rules={[{
                    required: true,
                    message: 'Por favor selecciona una moneda.',

                }]}>
                    <Select
                        // styles={generalreactSelectStyles}
                        // className="pro-select-input"
                        options={Currencies}
                        isLoading={false}
                        defaultChecked={createReceiptModalEdit ? state.livemode : !state.livemode}
                        onChange={(v) => {
                            if (!v) { return }
                            const rate = rateData?.conversion_rates?.[v.toUpperCase()] ?? 1
                            setstate({ ...state, currency: v, exchange: (1 / rate) })
                            form.setFieldValue('exchange', (1 / rate))
                        }}
                        placeholder="Moneda"
                    />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                {/* <Typography.Text type="secondary">El cambio debe ser: 1 MXN equivale a X USD</Typography.Text> */}
                <Form.Item name="exchange" label="Tipo de cambio" help={rateData?.time_last_update_utc ? <Typography.Text style={{ fontSize: '11px', }} type="secondary"> Última actualización {moment(rateData?.time_last_update_utc).format('ddd D MMM HH:mm')}</Typography.Text> : ''} rules={[{
                    required: true,
                    message: 'Por favor ingresa el tipo de cambio.'
                }]}>
                    <Input
                        disabled={(state?.currency ?? '').toLowerCase() === 'mxn'}
                        className="pro-input"
                        placeholder="Tipo de cambio"
                        value={state.exchange}
                        type='number'
                        onChange={(e) => {
                            const newExchange = e.target.value;
                            setstate({ ...state, exchange: newExchange });
                            console.log(state, 'stateExchange')
                        }}
                    />
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item name="periodicity" label="Periodicidad del recibo" rules={[{
                    required: true,
                    message: 'Selecciona la periodicidad del recibo'
                }]} help={<Typography.Text type="secondary" style={{ fontSize: '11px' }}>{`Periodo en el que se realizará de forma automática al público en general (${moment(validTime()).format('ddd DD MMMM YYYY HH:mm')}).`}</Typography.Text>}>
                    <Select
                        // styles={generalreactSelectStyles}
                        // className="pro-select-input"
                        options={[{
                            value: 'day',
                            label: 'Día',
                        },
                        {
                            value: 'week',
                            label: 'Semana',
                        },
                        // {
                        //     value: 'fortnight',
                        //     label: 'Quincenal',
                        // },
                        {
                            value: 'month',
                            label: 'Mes',
                        },
                        {
                            value: 'two_months',
                            label: '2 meses',
                        }
                        ]}
                        // value={stateEdit ? stateEdit.periodicity : state.periodicity}
                        value={state.periodicity}
                        isLoading={false}
                        onChange={(v) => {
                            if (!v) { return }
                            setstate({ ...state, periodicity: v })
                        }}
                        placeholder="Periodicidad"
                    />
                </Form.Item>
            </Col>
            <Col xs={24} style={{ marginTop: '15px' }}>
                <Row gutter={{ xs: 12, lg: 12 }}>
                    <Col xs={8}>
                        <Form.Item label="¿Recibo en modo pruebas?" valuePropName="checked" name="test">
                            <Switch
                                checked={!state.livemode}
                                onChange={(v) => {
                                    setstate({ ...state, ...form.getFieldsValue(), currency: (form.getFieldValue('currency')?.value ?? state.currency), livemode: !v })
                                }} />
                        </Form.Item>
                    </Col>
                    <Col xs={16}>
                        {!state.livemode && <Typography.Text>El recibo se realizará en modo pruebas.</Typography.Text>}
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    /**
     * It returns a component based on the value of the step variable
     * @returns A function that returns a component based on the value of the step variable.
     */

    const ToRender = () => {
        switch (step) {
            case 0:
                return <ClientsAndItemsSelect userDocument={userDocument} changeState={(v) => setstate(v)} state={state} withAddFees={false} withAutomation={false} child={<Typography.Text style={{ whiteSpace: 'pre-wrap' }} type="danger">{ProductsAvailableForSat(state.items).messages.map((m) => m).join('\n')}</Typography.Text>} forSat={false} />

            case 1:
                return <ReceiptInfo />

            case 2:
                return <Suspense fallback={<></>}>
                    <LoadingAndResponse loading={apiloading} isError={!receiptOk && error} errorMessage={error} backAction={() => setstep(step - 1)} />

                </Suspense>

            case 3:
                return <ElementCreated idRef="receipts" actionText="Realiza tu factura" edit={createReceiptModalEdit ? true : false} userDocument={userDocument} message={createReceiptModalEdit ? `Recibo actualizado con exito` : "El recibo se creo con éxito"} actions={{ 'email': 'shortUrl', 'copy': 'url', 'whatsapp': 'url', 'qr': 'qr' }} close={() => {
                    if (!onlyContent) {
                        close()
                    } else {
                        window.location.href = '/receipts'
                    }
                }} element={receiptOk} shareMessage={``} subject="" />

            default:
                return <></>

        }
    }



    const Content = () => {
        return <Row style={{ width: '100%' }}>

            <Col xs={24}>
                <Form form={form} initialValues={state} layout="vertical" onFinish={HandleButtonAction}>
                    <div className="d-flex flex-column" style={{ padding: '5px' }}>
                        {!onlyContent && <div className="d-flex flex-column">
                            <Row justify="space-between">
                                <Typography.Title level={4}>{createReceiptModalEdit ? `Editar recibo` : `Crear nuevo recibo`}</Typography.Title>
                                {!onlyContent && <CloseOutlined onClick={() => close()} />}
                            </Row>
                        </div>}
                        {step < 3 && <Row style={{ marginTop: '10px' }} justify="end">
                            <CurrencyChip state={state} onUpdate={(v) => {
                                setstate(v)
                                var exchange = 1;
                                if (step === 1) {
                                    exchange = (1 / rateData?.conversion_rates[v.currency.toUpperCase() ?? 'MXN']).toFixed(2) ?? 1
                                    form.setFieldValue('exchange', exchange)
                                }
                                form.setFieldValue('currency', { label: v.currency.toUpperCase(), value: v.currency.toUpperCase(), })
                            }} />
                        </Row>}
                        <div className='d-flex flex-column'
                            style={{ flex: 1, maxHeight: '70vh', minHeight: '70vh', overflow: 'auto', padding: '5px' }}>
                            <ToRender />
                        </div>
                        {step < 2 && <Row style={{ marginBottom: '10px' }} gutter={{ xs: 1 }}>
                            {step > 0 && <Button style={{ flex: 1, marginRight: '5px' }} onClick={() => setstep(step - 1)}>Atrás</Button>}
                            <Button htmlType="submit" disabled={createReceiptModalEdit ? !HandleButtonStateEdit() : HandleButtonState().disabled} loading={loading} type="primary" style={{ flex: 1, }}>{createReceiptModalEdit ? HandleButtonStateEdit().message : HandleButtonState().message}</Button>


                            {/* <Button htmlType="submit" disabled={HandleButtonState().disabled} loading={loading} type="primary" style={{ flex: 1, }}>{HandleButtonState().message}</Button> */}
                        </Row>}
                    </div>
                </Form>
            </Col>
        </Row >
    }



    if (onlyContent) {
        return <Content />
    }


    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
            close()
            getCurrentTeam(team?.id)
        }} header={null} footer={null}>
            <Content />
        </Drawer>

    );
}

export default CreateReceipt;