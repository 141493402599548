import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, notification, Typography, Upload } from 'antd';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment';
import React, { useState } from 'react'
import { useAuth } from 'reactfire';
import { generateCode } from '../../app/functions/helpers';

function SingleImageUpload({ imageUrl: incomingImageUrl, withFileName, uploadTo, dbRef, disableCode, saveOnDB = true, defaultOnDb = {}, onSuccess }) {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(incomingImageUrl ?? '');
    const [fileList, setFileList] = useState([
    ]);
    const auth = useAuth()

    /**
     * It takes an image and a callback function, and then it calls the callback function with the base64
     * representation of the image
     * @param img - The image file that you want to convert to base64.
     * @param callback - a function that will be called when the image is loaded.
     */
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    /**
     * It checks if the file is an image and if it's less than 2MB
     * @param file - The file that is being uploaded.
     * @returns isJpgOrPng && isLt2M
     */
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Por favor sube una imagen en JPEG, JPG o PNG');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('La imagen debe de pesar menos de 2MB!');
        }

        return isJpgOrPng && isLt2M;
    };
    /**
     * It takes the fileList array and checks if the file name is already in the array. If it is, it
     * replaces the file with the new file. If it isn't, it adds the file to the array
     * @param info - The file object that is being uploaded.
     * @returns The image url is being returned.
     */
    const handleChange = (info) => {
        const index = fileList.findIndex(f => f.name === info.file.name);
        if (index !== -1) {
            fileList[index] = info.file;
        } else {
            fileList.push(info.file);
        }
        setFileList([...fileList]);

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };


    /**
     * It returns the index of the file in the fileList array that has the same name as the name property
     * of the object passed to the function
     * @returns The index of the file in the fileList array.
     */

    const fileIndex = ({ name }) => {

        fileList.forEach((file, index) => {
            if (file.name === name) {
                return index;
            }
        })
        return fileList.findIndex(file => file.name === name);
    }



    /**
     * It takes a file, uploads it to the storage location specified by the `uploadTo` parameter, and
     * returns a download URL
     */
    const customUpload = async ({ file, onProgress, uploadTo: upto, onError, onSuccess }) => {
        const st = getStorage()
        const uref = ref(st, upto);
        const upTask = uploadBytesResumable(uref, file)

        upTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case 'paused':
                    break;
                case 'running':
                    onProgress({ progress, file });
                    break;
                default:
                    break;
            }
        }, (error) => {
            onError(error, file);
        }, () => {
            getDownloadURL(upTask.snapshot.ref).then((downloadURL) => {
                onSuccess(downloadURL, file, upTask.snapshot.ref.fullPath);
            });
        })
    };
    /**
     * It takes a file, uploads it to the specified path, and returns a file object with the url, path, and
     * other properties
     */
    const customRequest = e => {
        const code = generateCode(10)
        customUpload(
            {
                file: e.file,
                uploadTo: withFileName ? `${uploadTo}/${code}-${e.file.name.replace(/ /g, '_')}` : uploadTo,
                onProgress: (percent) => {
                    setLoading(true);
                    const index = fileList.findIndex(f => f.name === e.file.name);
                    fileList[index].percent = percent;
                    setFileList([...fileList]);
                },
                onError: (e, file) => {
                    notification.error({ message: 'Ocurrio un error', description: e.message })
                    return
                },
                onSuccess: async (url, file, path) => {
                    // searchAndUpdateFileOnList(file.uid, { status: 'done', url: url, path: path, dbref: withFileName ? `${dbRef}${disableCode ? '' : `/${code}`}` : dbRef, ...file, owner: props.user.uid })


                    const index = fileList.findIndex(f => f.name === file.name);
                    const preparedFile = fileList[index]
                    preparedFile.status = 'done';
                    preparedFile.url = url;
                    preparedFile.path = path;
                    preparedFile.dbref = withFileName ? `${dbRef}${disableCode ? '' : `/${code}`}` : dbRef;
                    preparedFile.owner = auth.currentUser.uid;
                    preparedFile.code = disableCode ? '' : code;
                    preparedFile.createdAt = moment().valueOf();
                    preparedFile.updatedAt = moment().valueOf();
                    preparedFile.originFileObj = null;

                    setFileList([...fileList]);
                    setImageUrl(url);
                    setLoading(false);
                    onSuccess(preparedFile)
                    return fileIndex[index]
                },
                code
            }
        );
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                <Typography.Text type="secondary">Seleccionar</Typography.Text>
            </div>
        </div>
    );


    return (

        <Upload
            name="singleImageUpload"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            fileList={fileList}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            onChange={handleChange}
        >
            {(imageUrl && !loading) ? (
                <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                />
            ) : (
                uploadButton
            )}
        </Upload>
    );
}

export default SingleImageUpload;