import React from 'react';
import Lottie from "lottie-react";
import empty from '../../assets/lotties/not-found.json';
import { Button, Typography } from 'antd';

function EmptyTable({ customMessage, parentAction, elementName }) {
    return (
        <div className='d-flex flex-column' style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Lottie animationData={empty} style={{ height: '100px' }} loop={false} />
            <Typography.Text className='' type="secondary">{customMessage}</Typography.Text>
            {parentAction && <Button onClick={parentAction} type="primary" size="small" style={{ marginTop: '10px' }}>Crear {elementName}</Button>}
        </div>
    );
}

export default EmptyTable;