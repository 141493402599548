import { CloseOutlined } from '@ant-design/icons';
import { Button, Col/*, DatePicker*/, Drawer, Form, message, Row, Typography } from 'antd';
import { getAuth } from 'firebase/auth';
import moment from 'moment';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { FeesAmounts, generateCode, getItemsAmounts, RemoveUndefined, } from '../../app/functions/helpers';
import ElementCreated from '../states/ElementCreated';
import LoadingAndResponse from '../states/LoadingAndResponse';
// import Select from 'react-select';
// import { generalreactSelectStyles } from '../../app/functions/styles';
// import WeeklyRecurring from '../calendarize/WeeklyRecurring';
// import MonthlyRecurring from '../calendarize/MonthlyRecurring';
// import DailyRecurring from '../calendarize/DailyRecurring';
import { SummarizeMessage } from '../calendarize/SummarizeRecurring';
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore';
import CurrencyChip from '../cards/CurrencyChip';
import ClientsAndItemsSelect from '../forms/ClientsAndItemsSelect';
import AvailPaymentOptions from './AvailPaymentOptions';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import CalendarOptions from '../cards/CalendarOptions';




function NewRecurringPayment({ userDocument, data, close, edit, open }) {
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const auth = getAuth();
    const [loading, setloading] = useState(false)
    const [step, setstep] = useState(0)
    const [state, setstate] = useState({ currency: team?.currency ?? 'mxn', custom_method_types: [], ...(edit ?? {}), temporality: typeof edit?.temporality === 'object' ? edit?.temporality.value : edit?.temporality, onTime: edit?.onTime, limitDaysToPay: edit?.limitDaysToPay ?? null, feeAdded: edit?.discount, clabe: edit?.client?.metadata?.clabe && (edit?.client?.metadata?.clabe === edit?.replicateElement?.metadata?.clabe) })

    const [paymentOk, setpaymentOk] = useState(true)
    const [errorMessage, seterrorMessage] = useState(null)


    const [form] = Form.useForm();

    const { items } = state;

    const analytics = useAnalytics();

    useEffect(() => {
        if (edit) {
            form.setFieldsValue({ ...edit, onTime: edit.onTime })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //FUNCTIONS
    /**
     * It returns an object with two properties, disabled and message, which are used to set the state of
     * the button
     * @returns const HandleButtonState = () => {
     *         if (step === 0) {
     *             if ((items ?? []).length <= 0) {
     *                 return { disabled: true, message: 'Añade al menos un servicio' }
     *             } else return { disabled: false, message: 'Siguiente' }
     *         }
     */
    const HandleButtonState = () => {
        if (step === 0) {
            if ((items ?? []).length <= 0) {
                return { disabled: true, message: 'Añade al menos un servicio' }
            } else return { disabled: false, message: 'Siguiente' }
        }
        if (step === 1) {
            if (state.custom_method_types?.length <= 0) {
                return { disabled: true, message: 'Selecciona al menos un método' }
            }
            if (!state.temporality || !state.startDate || !state.endDate) {
                return { disabled: true, message: 'Selecciona una fecha de inicio y una de finalización' }
            }
            if (state.temporality === 'daily') {
                if (!state.onTime) {
                    return { disabled: true, message: 'Selecciona una hora' }
                }
            }
            if (state.temporality === 'weekly') {
                if (!state.onTime) {
                    return { disabled: true, message: 'Selecciona una hora' }
                }
                if (state.onWeekday === null) {
                    return { disabled: true, message: 'Selecciona el día de la semana' }
                }
            }
            if (state.temporality === 'monthly') {
                if (!state.onTime) {
                    return { disabled: true, message: 'Selecciona una hora' }
                }
                if (!state.onDay) {
                    return { disabled: true, message: 'Selecciona el día del mes' }
                }
            }


            else return { disabled: false, message: edit ? 'Actualizar evento' : 'Crear evento' }
        }

        return { disabled: false, message: edit ? 'Actualizar evento' : 'Crear evento' }
    }
    /**
     * It removes the fee from the total of each item in the cart
     */
    const RemoveFeesFromTotal = () => {


        items?.forEach((item, index) => {
            items[index].total = item.total - (item.feeAdded ?? 0)
            items[index].feeAdded = null
            items[index].feeInfo = null
        })

        setstate({ ...state, feeAdded: null, items: [...items] })
        message.success('Hemos actualizado los servicios')
    }
    /**
     * It creates a recurring event in the database
     */
    const RequestPayment = async () => {
        setloading(true)
        seterrorMessage(null)

        const code = generateCode(15, 'rpayment')
        const payment = {
            team: team?.id,
            billingAccount: billingAccount?.id,
            livemode: true,
            charges: null,
            review: null,
            automations: state.automations,
            amount_capturable: 0,
            customer: state.client ?? null,
            created: moment().valueOf(),
            timestamp: moment().valueOf(),
            shipping: null,
            amount_received: 0,
            capture_method: "automatic",
            transfer_group: null,
            amount: (getItemsAmounts(state.items, state.feeAdded).total) * 100,
            discount: Number(state.feeAdded),
            status: "pending",
            next_action: null,
            viewed: 0,
            sms: (state.client.phone && userDocument.twilio),
            receipt_email: state.client?.email ?? null,
            last_payment_error: null,
            confirmation_method: "",
            statement_descriptor: "",
            owner: userDocument.uid,
            payment_method: null,
            application: null,
            automatic_payment_methods: null,
            description: '',
            lastViewed: null,
            on_behalf_of: null,
            currency: state?.currency ?? 'mxn',
            items: state.items,
            clientID: state.client?.id ?? null,
            clientId: state.client?.id ?? null,
            metadata: { items: 1, owner: userDocument.uid, internalID: code },
            internalStatus: "pending",
            object: "payment",
            cancellation_reason: null,
            source: null,
            token: null,
            id: code,
            payment_method_types: [],
            custom_method_types: state.custom_method_types,
            client: state.client ?? null,
            clabe: state.clabe ? state.client.metadata.clabe : null,
            application_fee_amount: null,
            canceled_at: null,
            fid: code,
            isManual: false,
            processor: '',
            proposals: data?.proposal ? [data.proposal?.id] : null,
            invoices: data?.invoice ? [data?.invoice?.id] : null,
            v: 2,
            payment_intent: null,
            limitDaysToPay: state?.limitDaysToPay ?? null,

        }
        if (state.addTransactionFee) {
            payment.addTransactionFee = true
            payment.transactionFeeInfo = FeesAmounts(userDocument, team);
        }
        if (state.custom_method_types.findIndex(v => v.id === 'bank') > -1) {
            payment.bank = {
                ...userDocument.bank
            }
        }

        setstate({ ...state, step: state.step + 1 })
        setstep(step + 1)

        var summMessage = SummarizeMessage({ state })


        const recurringEvent = {
            team: team?.id,
            billingAccount: billingAccount?.id,
            owner: userDocument.uid,
            replicateElement: RemoveUndefined(payment),
            elementType: 'payments',
            startDate: moment(state.startDate).valueOf(),
            startDateUTC: moment(state.startDate).utc().valueOf(),
            startDateString: moment(state.startDate).format('D MMMM YYYY HH:mm'),
            endDate: moment(state.endDate).valueOf(),
            endDateUTC: moment(state.endDate).utc().valueOf(),
            endDateString: moment(state.endDate).format('D MMMM YYYY HH:mm'),
            onWeekday: state.onWeekday ?? null,
            onDay: state.onDay ?? null,
            onTime: typeof state?.onTime === 'string' ? state.onTime : state.onTime?.format('HH:mm') ?? state?.onTime ?? null,
            temporality: state.temporality ?? null,
            timestamp: edit?.timestamp ? edit?.timestamp : moment().valueOf(),
            timestampUTC: edit ? edit?.timestampUTC : moment().utc().valueOf(),
            client: state.client ?? null,
            clientID: state.client?.id ?? null,
            clientId: state.client?.id ?? null,
            nextRun: summMessage.nextHit,
            nextRunUTC: summMessage.nextHit ? moment(summMessage.nextHit).utc().valueOf() : null,
            nextRunString: summMessage.nextHit ? moment(summMessage.nextHit).format('D MMMM YYYY HH:mm') : null,
            status: !SummarizeMessage({ state }).nextHit ? 'paused' : 'active',
            type: 'payment',
            processed: false,
        }






        if (edit?.id) {
            recurringEvent.lastUpdate = moment().valueOf()
            try {

                const d = doc(getFirestore(), 'recurringEvents', edit.recordID)

                await updateDoc(d, { ...recurringEvent, updateList: arrayUnion(moment().valueOf()) })
                setpaymentOk(true)
                setloading(false)
                RemoveFeesFromTotal()
                setstep(step + 2)
                return
            } catch (error) {
                setpaymentOk(false)
                setloading(false)
                seterrorMessage(error.message)
                message.error(error.message)
                return
            }
        }

        try {
            const resp = await SignedInternalAPIRequest({ ...recurringEvent }, 'createRecurringEventSeats', auth.currentUser)
            if (resp.error) {
                setpaymentOk(false)
                setloading(false)
                seterrorMessage(resp.message)
                return message.error(resp.message)
            }

            setpaymentOk(true)
            setloading(false)
            RemoveFeesFromTotal()
            setstep(step + 2)
            logEvent(analytics, 'recurringPaymentCreated', {});
        } catch (error) {
            setpaymentOk(false)
            setloading(false)
            seterrorMessage(error.message)
            message.error(error.message)
        }


    }















    /**
     * It renders the calendar and the payment methods
     * @returns A div with a title, a calendar, and a payment option.
     */

    const PaymentMethods = () => {
        return <div style={{ width: '97%', padding: '10px 0px' }}>
            <Typography.Title level={5} style={{ marginTop: '0px' }} >4. Calendario</Typography.Title>
            <CalendarOptions edit={edit} changeState={(s) => {
                setstate({ ...state, ...s })
            }} state={{ ...state }} />
            <Typography.Title level={5} style={{ marginTop: '25px' }} >5. Métodos disponibles</Typography.Title>
            <AvailPaymentOptions state={state} setstate={(v) => setstate(v)} userDocument={userDocument} />
            <div style={{ marginTop: '20px' }}></div>
        </div>


    }

    /**
     * It returns a component based on the value of the step variable
     * @returns A component
     */
    const ToRender = () => {
        switch (step) {
            case 0:
                return <ClientsAndItemsSelect userDocument={userDocument} changeState={(v) => setstate(v)} state={state} withAddFees={true} withAutomation={true} />
            case 1:
                return <Suspense fallback={<></>}>
                    <PaymentMethods />
                </Suspense>
            case 2:
                return <Suspense fallback={<></>}>
                    <LoadingAndResponse loading={loading} isError={paymentOk === null || !paymentOk} errorMessage={errorMessage} backAction={() => setstep(step - 1)} />

                </Suspense>

            case 3:
                return <ElementCreated idRef="payments" actionText="Realizar pago" userDocument={userDocument} message={edit != null ? 'Evento actualizado con éxito' : "El evento recurrente se creó con éxito"} actions={{ 'email': 'shortURL', 'copy': 'shortURL', 'whatsapp': 'shortURL' }} close={() => close(true)} element={paymentOk} shareMessage={`Ya puedes realizar el pago por ${getItemsAmounts(items).totalString} ${state.currency} desde el siguiente enlace:`} subject="Realiza el pago correspondiente." title={edit != null ? '¡Actualizado!' : ' ¡Creado con éxito!'} />

            default:
                return <></>
        }
    }
    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} footer={null} >
            <Row style={{ width: '100%' }}>

                <Col xs={24}>
                    <Form form={form} initialValues={edit ? edit : {}} layout="vertical" onFinish={async () => {
                        if (step === 0) {
                            setstep(1)
                        } else if (step === 1) {
                            RequestPayment()
                        }
                    }}>
                        <div className="d-flex flex-column" style={{ padding: '5px' }}>
                            <div className="d-flex flex-column">
                                <Row justify="space-between">
                                    <Typography.Title level={4}>{edit != null ? 'Editar evento recurrente' : 'Nuevo evento recurrente'}</Typography.Title>
                                    <CloseOutlined onClick={() => {
                                        close()
                                    }} />
                                </Row>
                            </div>
                            {step < 2 && <Row style={{ marginTop: '10px' }} justify="end">
                                <CurrencyChip onUpdate={(v) => setstate(v)} state={state} />
                            </Row>}
                            <div className='d-flex flex-column'
                                style={{ flex: 1, maxHeight: '70vh', overflow: 'auto', padding: '5px' }}>
                                <ToRender />
                            </div>
                            {step < 2 && <Row style={{ marginBottom: '10px' }} gutter={{ xs: 1 }}>
                                {step > 0 && <Button style={{ flex: 1, marginRight: '5px' }} onClick={() => setstep(step - 1)}>Atrás</Button>}
                                <Button htmlType="submit" disabled={HandleButtonState().disabled} loading={loading} type="primary" style={{ flex: 1, }}>{HandleButtonState().message}</Button>
                            </Row>}
                        </div>
                    </Form>
                </Col>
            </Row >
        </Drawer>
    );
}

export default NewRecurringPayment;