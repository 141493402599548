import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import React, { useState, useContext } from 'react'
import Select from 'react-select';
import { generalreactSelectStyles } from '../../app/functions/styles';
import moment from 'moment';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { useAuth } from 'reactfire';
import ModalContext from '../../context/modalcontext/ModalContext';
import HelperCard from '../cards/HelperCard';
import logoGigstack from '../../assets/images/logogigstack.png'

function InviteMember() {
    const { modalTeam: team, modalBillingAccount: billingAccount, modalUser: user } = useContext(ModalContext)

    const [formRef] = Form.useForm();

    const auth = useAuth()
    //TODO - Review Element Holder Modal
    const {
        inviteModalOpen,
        inviteType,
        handleCloseInviteModal
    } = useContext(ModalContext)

    const [loading, setLoading] = useState(false)

    const handleInviteMember = async (v) => {
        try {
            setLoading(true)
            const inviteObj = {
                email: v.email,
                rol: inviteType === 'team' ? v.rol.value : 'admin',
                status: "pending",
                teamId: inviteType === 'team' ? team?.id : null,
                billingId: billingAccount.id,
                expiring: moment().add(7, 'day').valueOf(),
                accepted: false,
                teamName: team?.alias ?? null,
                emailOwner: user?.email ?? auth?.currentUser?.email ?? '',
                billingName: inviteType === 'billingAccount' ? billingAccount?.legalName : null,
                type: inviteType
            }
            const res = await SignedInternalAPIRequest(inviteObj, 'handleSeats/v1/create', auth.currentUser)
            message.success(res.message)
            setLoading(false)
            formRef.resetFields()
            handleCloseInviteModal()
        } catch (error) {
            message.error(error.message)
            setLoading(false)
        }
    }

    return (
        <Modal visible={inviteModalOpen} onCancel={() => handleCloseInviteModal()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 50 }} >
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4}>Invita a un {inviteType === 'team' ? "miembro" : "administrador"} a {inviteType === 'team' ? team?.alias : billingAccount?.legalName}</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => handleCloseInviteModal()} />
                </Row>
                {inviteType === 'billingAccount' && <Typography.Text type="secondary" >El nuevo administrador prodrá ver toda la información legal de tu cuenta de facturación.</Typography.Text>}
                <HelperCard squareImg={true} supportImageUrl={logoGigstack} title="Agrega a personas de tu equipo a tu cuenta de Gigstack" description='Estamos probando esta nueva funcionalidad, nos puedes enviar tus comentarios a soporte@gigstack.pro' marginTop='5px' />
                <Form form={formRef} layout="vertical" onFinish={handleInviteMember} >
                    <Row gutter={{ xs: 12, md: 12 }}>
                        <Col xs={24} md={inviteType === 'team' ? 12 : 24} lg={inviteType === 'team' ? 12 : 24}>
                            <Form.Item label="Email" name="email" rules={[
                                { type: 'email', message: 'El correo electrónico no es válido' },
                                { required: true, message: 'Añade el correo electrónico' }]}
                                style={{ marginTop: '20px' }}
                            >
                                <Input className="pro-input" placeholder="" />
                            </Form.Item>
                        </Col>
                        {inviteType === 'team' &&
                            <Col xs={24} md={12} lg={12}>
                                <Form.Item label="Rol del nuevo miembro" name="rol" rules={[
                                    { required: true, message: 'Añade el rol del nuevo miembro' }]}
                                    style={{ marginTop: '20px' }}
                                >
                                    <Select
                                        styles={generalreactSelectStyles}
                                        className="pro-select-input"
                                        options={[
                                            { label: 'Editor', value: 'editor' },
                                            { label: 'Visor', value: 'viewer' },
                                            { label: 'Administrador', value: 'admin' },
                                        ]}
                                        isLoading={false}
                                        placeholder="Selecciona un rol"

                                    />
                                </Form.Item>
                            </Col>
                        }
                    </Row>

                    <Row justify='center'>
                        <Button loading={loading} type="primary" htmlType="submit">Enviar</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default InviteMember;