import { ReadOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import React from 'react';


function DefaultHelpPageHeader({ userDocument, title, description, helpResourse, extraInSub }) {
    return (
        <Row>
            <Col xs={24}>
                <Row justify='start'>
                    <Typography.Title level={4}>{title}</Typography.Title>
                </Row>
            </Col>
            <Col xs={24}>
                <div className='pageHeaderHelperContainer'>
                    <Row align='middle' justify="space-between">
                        <Typography.Text className="" style={{ color: '#757575' }}>{description}</Typography.Text>
                        {extraInSub && extraInSub}
                    </Row>

                    {helpResourse &&
                        <Row align="middle" style={{ marginTop: '10px' }}>
                            <ReadOutlined className="primary" style={{ padding: '0px 5px 0px 0px' }} />
                            <a href={helpResourse} target="_blank" rel="noreferrer"> Recursos de ayuda</a>
                        </Row>
                    }

                </div>
            </Col>
        </Row >
    );
}

export default DefaultHelpPageHeader;