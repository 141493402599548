import { Button, Form, Input, message, Modal, Row, Typography } from 'antd';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react'


function ForgotPasswordModal({ open, close, email }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    /**
     * It sends a password reset email to the user's email address
     * @param vals - The values of the form.
     */
    const RecoverAccount = async (vals) => {
        try {
            setLoading(true)
            await sendPasswordResetEmail(getAuth(), vals.email);
            setLoading(false)
            message.success('Hemos enviado un correo para re-establecer tu contraseña')
            close()
        } catch (error) {
            setError(error)
            setLoading(false)
        }
    }
    return (
        <Modal title={null} footer={null} visible={open} onCancel={() => close()}>
            <div className="d-flex flex-column" style={{ padding: '10px' }}>
                <Typography.Title level={5}>Recupera tu contraseña</Typography.Title>
                <Typography.Text type="secondary">Ingresa tu correo para que enviemos un enlace para recuperar tu contraseña.</Typography.Text>
                <Form onFinish={RecoverAccount} style={{ marginTop: '20px' }} layout="vertical" initialValues={{ email }}>
                    <Form.Item label="Correo electrónico" name={"email"} rules={[{ required: true, message: 'Por favor escribe tu correo', type: "email" }]}>
                        <Input placeholder="Correo electrónico" />
                    </Form.Item>
                    {error && <Typography.Text type="danger">{error}</Typography.Text>}
                    <Row justify="center">
                        <Button htmlType="submit" loading={loading} type="primary">Recuperar cuenta</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default ForgotPasswordModal;