import { Col, Form, Input, Row, Select, Table, Typography } from "antd";
import { doc, getFirestore } from "firebase/firestore";
import { Suspense, useContext, useState } from "react";
import TeamContext from "../../context/teamcontext/TeamContext";
import { useFirestoreDoc } from "reactfire";
import { UseUserDefaults } from "../../customHooks/useUserDefaults";
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { generalreactSelectStyles } from "../../app/functions/styles";
import { invoiceUsage } from "../../app/functions/helpers";
import EmptyTable from "../states/EmptyTable";
import TableTitle from "../tables/TableTitle";
import ServiceTaxModal from "../services/ServiceTaxModal";
import SearchSATProductkey from "../services/SearchSATProductkey";

const FiscalDefaults = ({ setTaxes, setProductKey, setTaxesInUSD }) => {
    const { team } = useContext(TeamContext)


    const [searchPK, setsearchPK] = useState(false)
    const ref = doc(getFirestore(), "teams", team?.id)
    const { data } = useFirestoreDoc(ref);
    const { defaults } = UseUserDefaults({ userDocument: data.data(), team: data.data() })
    // const [fiscalForm] = Form.useForm();
    const [addTax, setAddTax] = useState(false);
    const [addTaxInUSD, setAddTaxInUSD] = useState(false);
    const [taxesUSDDefault, setTaxesUSDDefault] = useState(defaults?.taxesUSDDefaults ?? [])
    const [defaultTaxesState, setDefaultTaxesState] = useState(defaults?.defaultTaxes)
    const [reLoadTable, setReLoadTable] = useState(false);



    const SectionDivider = ({ title }) => {
        return <div className='d-flex flex-row' style={{ backgroundColor: '#fafbfd', padding: '10px 0px', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', width: '100%', alignContent: 'center', borderRadius: '10px' }}>
            <Typography.Text style={{ alignSelf: 'center' }} className="strong">{title}</Typography.Text>
        </div>
    }
    return <>
        {searchPK && <Suspense fallback={<>loading</>}>
            <SearchSATProductkey open={searchPK} close={() => setsearchPK(false)} onSelected={(k) => {
                // console.log(k);
                // fiscalForm?.setFieldValue('product_key', k)
                setProductKey(k)
                setsearchPK(false)
            }} />
        </Suspense>}
        {addTax && <Suspense>
            <ServiceTaxModal open={addTax} add={(tax) => {
                // var txs = defaultTaxes
                // txs.push(tax)
                // setTaxes(txs)
                setDefaultTaxesState([...defaultTaxesState, tax])
                setTaxes([...defaultTaxesState, tax])
                setAddTax(false)
            }} close={() => setAddTax(false)} />
        </Suspense>}
        {addTaxInUSD && <Suspense>
            <ServiceTaxModal open={addTaxInUSD} add={(tax) => {
                setTaxesUSDDefault([...taxesUSDDefault, tax])
                setTaxesInUSD([...taxesUSDDefault, tax])
                setAddTaxInUSD(false)
            }} close={() => setAddTaxInUSD(false)} />
        </Suspense>}

        <SectionDivider title="Facturas" />
        <div style={{ marginTop: '15px' }}></div>
        <Row gutter={10}>
            <Col xs={24} lg={12}>
                <Form.Item label="Nombre de la unidad" name="unit_name" style={{ flex: 1, margin: 0, padding: 0 }} >
                    <Input className="pro-input" placeholder='Unidad de servicio' defaultValue={team?.default?.unit_name} />
                </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
                <Form.Item label="Código de unidad" name="unit_key" style={{ flex: 1, margin: 0, padding: 0 }} >
                    <Input className="pro-input" placeholder='ej: E48' defaultValue={team?.default?.unit_key} />
                </Form.Item>
            </Col>
        </Row>
        <Typography.Text type="secondary" style={{ marginBottom: '10px', marginTop: '15px' }}>Revisa el nombre de la unidad y código aqui que corresponde antes de crear el servicio <a href="http://pys.sat.gob.mx/PyS/catUnidades.aspx" target="_blank" rel="noreferrer">http://pys.sat.gob.mx/PyS/catUnidades.aspx</a>.</Typography.Text>
        <Row style={{ width: '100%', marginBottom: '10px', marginTop: '15px' }
        } align="middle" justify="space-between" >
            <Typography.Text className='cLabel' style={{ flex: 1 }}>Clave del catálogo del SAT</Typography.Text>
            <Row style={{ padding: '5px 10px', border: '1px solid #cecece', borderRadius: '10px' }} align="middle" className="hoverPrimaryBg" onClick={() => {
                setsearchPK(true)
            }}>
                <Typography.Text type="secondary" >Buscar clave</Typography.Text>
                <SearchOutlined className="secondary" style={{ marginLeft: '10px' }} />
            </Row>
        </Row >
        <Form.Item name="product_key"
        // help={<Row align="middle" style={{ marginTop: '5px' }}>
        //     <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece', marginTop: '5px' }} />
        //     <Typography.Text style={{ fontSize: '10px', marginLeft: '5px', flex: 1 }} type="secondary">Si la venta no contiene en metadata "product_key" utilizaremos esta clave del catalogo por default para todas las facturas y los productos que contengan</Typography.Text>
        // </Row>}
        >
            <Input placeholder='Clave del catalogo' />
        </Form.Item>

        <Form.Item label="Uso de la factura" name="usage"
        //  help={<Row align="middle" style={{ marginTop: '5px' }}>
        //     <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece', marginTop: '5px' }} />
        //     <Typography.Text style={{ fontSize: '10px', marginLeft: '5px', flex: 1 }} type="secondary">El uso que le daremos a la factura en caso de que no venga "usage" como metadata del pago.</Typography.Text>
        // </Row>}
        >
            <Select
                styles={generalreactSelectStyles}
                className="pro-select-input"
                options={invoiceUsage}
                isLoading={false}
                placeholder="Uso"
            />
        </Form.Item>

        {/* <Form.Item label="Emitir factura a tus clientes de" name="availNationality" help={<Row align="middle" style={{ marginTop: '5px' }}>
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece', marginTop: '5px' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px', flex: 1 }} type="secondary">En los países que no estén en la lista emitiremos un recibo de venta <strong>NO</strong> facturable. Dejar en blanco para emitir facturas sin importar el país</Typography.Text>
        </Row>}>
            <Select
                styles={generalreactSelectStyles}
                className="pro-select-input"
                isMulti
                options={countryLabeledList}
                isLoading={false}
                placeholder="Todo el mundo"
            />
        </Form.Item> */}
        <div style={{ paddingTop: '10px' }}></div>
        <Row style={{ marginTop: '25px' }} align="middle" justify="space-between">
            <Typography.Text className="cLabel">Impuestos predefinidos</Typography.Text>
            <PlusCircleOutlined className="clickable hoverPrimary" onClick={() => {
                setAddTax(true)
            }} />
        </Row>
        <div style={{ paddingTop: '15px' }}></div>
        {
            reLoadTable ? <Row justify="center">
                <LoadingOutlined />
            </Row> : <Table loading={reLoadTable}
                locale={
                    {
                        emptyText: <EmptyTable customMessage="No hay impuestos definidos" elementName="impuesto" />
                    }
                }
                dataSource={defaultTaxesState}
                columns={[
                    {
                        title: <TableTitle title='Tipo' />,
                        dataIndex: 'type',
                        key: 'type',
                        render: (text, record, index) => <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{text}</Typography.Text>
                    },
                    {
                        title: <TableTitle title='Tasa' />,
                        dataIndex: 'rate',
                        key: 'rate',
                        render: (text, record, index) => <Typography.Text type="secondary">{text}</Typography.Text>
                    },
                    {
                        title: <TableTitle title='Incluído' />,
                        dataIndex: 'inclusive',
                        key: 'inclusive',
                        render: (text, record, index) => <Typography.Text type="secondary">{text ? 'Incluído' : 'Excluído'}</Typography.Text>
                    },
                    {
                        title: <TableTitle title='' />,
                        dataIndex: 'actions',
                        key: 'actions',
                        render: (text, record, index) => <DeleteOutlined className="hoverDanger" onClick={() => {
                            setReLoadTable(true)

                            var txs = defaultTaxesState
                            txs.splice(index, 1)


                            setTaxes(txs)
                            setTimeout(() => {
                                setReLoadTable(false)
                            }, 10);
                        }} />
                    },
                ]}

            />
        }
        <Typography.Text type="secondary" style={{ fontSize: '12px' }}>Cuando recibamos los pagos <strong>sin impuestos</strong>, añadiremos los que hayas definido aquí.</Typography.Text>
        <div style={{ marginTop: '10px' }}></div>
        {defaults?.forcedTaxesOnUSD && <><Row style={{ marginTop: '25px' }} align="middle" justify="space-between">
            <Typography.Text className="cLabel">Impuestos predefinidos en USD</Typography.Text>
            <PlusCircleOutlined className="clickable hoverPrimary" onClick={() => {
                setAddTaxInUSD(true)
            }} />
        </Row>
            <div style={{ paddingTop: '15px' }}></div>
            {
                reLoadTable ? <Row justify="center">
                    <LoadingOutlined />
                </Row> : <Table loading={reLoadTable}
                    locale={
                        {
                            emptyText: <EmptyTable customMessage="No hay impuestos definidos en dólares" elementName="impuestoUSD" />
                        }
                    }
                    dataSource={taxesUSDDefault}
                    columns={[
                        {
                            title: <TableTitle title='Tipo' />,
                            dataIndex: 'type',
                            key: 'type',
                            render: (text, record, index) => <Typography.Text type="secondary" style={{ fontSize: '12px' }}>{text}</Typography.Text>
                        },
                        {
                            title: <TableTitle title='Tasa' />,
                            dataIndex: 'rate',
                            key: 'rate',
                            render: (text, record, index) => <Typography.Text type="secondary">{text}</Typography.Text>
                        },
                        {
                            title: <TableTitle title='Incluído' />,
                            dataIndex: 'inclusive',
                            key: 'inclusive',
                            render: (text, record, index) => <Typography.Text type="secondary">{text ? 'Incluído' : 'Excluído'}</Typography.Text>
                        },
                        {
                            title: <TableTitle title='' />,
                            dataIndex: 'actions',
                            key: 'actions',
                            render: (text, record, index) => <DeleteOutlined className="hoverDanger" onClick={() => {
                                setReLoadTable(true)

                                var txs = taxesUSDDefault
                                txs.splice(index, 1)


                                setTaxesInUSD(txs)
                                setTimeout(() => {
                                    setReLoadTable(false)
                                }, 10);
                            }} />
                        },
                    ]}

                />
            }
            <Typography.Text type="secondary" style={{ fontSize: '12px' }}>Cuando recibamos los pagos en dólares<strong> sin impuestos</strong>, añadiremos los que hayas definido aquí.</Typography.Text>

            <div style={{ marginTop: '10px' }}></div></>}
    </>
}

export default FiscalDefaults