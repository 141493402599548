import { Typography, Modal, Row, Button, Space, message, Image } from 'antd';
import { logEvent } from 'firebase/analytics';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useState } from 'react';
import { useAnalytics, useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { getItemsAmounts } from '../../app/functions/helpers';





function ReviewPaymentModal({ open, close, payment }) {
    const auth = useAuth();
    //const [sendEmail, setsendEmail] = useState(false)
    const [loading, setloading] = useState(false)

    const analytics = useAnalytics();


    /**
     * It updates the payment document with the status 'succeeded' and the review_status 'succeeded' and
     * the manualSuccess field to true
     */
    const ReceivePayment = async () => {
        try {
            setloading(true)
            await updateDoc(doc(getFirestore(), `payments/${payment.fid}`), { review_status: 'succeeded', status: 'succeeded', manualSuccess: true, succeededTimestamp: moment().valueOf(), payment_form: '03' })
            message.success('Pago confirmado')
            close()
        } catch (error) {
            message.error(error.message)
            setloading(false)
        }
    }
    /**
     * This function sends an email to the client asking them to pay the payment that was marked as paid
     * but the payment was not received
     */
    const RetryPayment = async () => {
        try {
            setloading(true)
            const body = {
                "emailSubject": 'Pago no recibido',//required
                "emailBody": `Hola ${payment.client?.name ?? ''}, te contactamos ya que el pago que fue marcado como realizado por la cantidad de ${getItemsAmounts(payment.items, payment.discount).totalString}, ${moment(payment.review_created_at).fromNow(true)}.`,//required
                "emails": [payment.client?.email],//required
                "message": `Hola ${payment.client?.name ?? ''}, te contactamos ya que el pago que marcaste como completado hace ${moment(payment.review_created_at).fromNow(true)} por un total de ${getItemsAmounts(payment.items, payment.discount).totalString}, no ha sido recibido.`,
                "messageTwo": `Si tienes dudas por favor responde a este correo.`,
                "messageThree": "",
                "goodbye": "Por favor realiza el pago cuanto antes.",
                "actiondisplay": "display:flex;",
                "action": "Realizar pago",//BUTTON TEXT
                "link": `${payment.shortURL}`,//BUTTON ACTION LINK
                "type": "payments",//payments,proposals, invoices,notes, etc.
                "hrType": "pagos",//Human readable type ex: Payment
                "elementID": payment.fid,//ID of the element, ex: payment id.
            }
            if (payment.client) {
                await SignedInternalAPIRequest(body, 'requestSendEmailSeats', auth.currentUser)
            }
            logEvent(analytics, 'reviewPaymentAction', {});

            await updateDoc(doc(getFirestore(), `payments/${payment.fid}`), { review_status: null, status: 'requires_payment_method' })


            close()
        } catch (error) {
            message.error(error.message)
            setloading(false)
        }
    }

    /**
     * It returns a div with a bunch of text and buttons
     * @returns A div with a bunch of text and buttons.
     */
    const PaidInBank = () => {

        // const itemsAmounts = getItemsAmounts(payment.items, payment?.paidIn === 'bank')

        return <div className='d-flex flex-column'>

            <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} className='strong'>Nombre</Typography.Text>
            <Typography.Paragraph style={{ fontSize: '12px', margin: '0px' }} >{payment.bank?.holder_name}</Typography.Paragraph>
            <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} className='strong'>Banco</Typography.Text>
            <Typography.Paragraph style={{ fontSize: '12px', margin: '0px' }} >{payment.bank?.tag}</Typography.Paragraph>
            <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} className='strong'>CLABE</Typography.Text>
            <Typography.Paragraph style={{ fontSize: '12px', margin: '0px' }} copyable>{payment.bank?.clabe || payment?.bank?.swift || payment?.bank?.iban}</Typography.Paragraph>
            <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} className='strong'>Concepto</Typography.Text>
            <Typography.Paragraph style={{ fontSize: '12px', margin: '0px' }} copyable>{payment.conceptReference ?? payment.fid?.replace('payment_', '')}</Typography.Paragraph>
            <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} className='strong'>Monto</Typography.Text>
            <Typography.Paragraph style={{ fontSize: '12px', margin: '0px' }} className='strong primary'> {getItemsAmounts(payment.items, payment?.paidIn === 'bank').total}</Typography.Paragraph>
            {/* <Typography.Paragraph style={{ fontSize: '12px', margin: '0px' }} className='strong primary'> {returnCurrencyValue(itemsAmounts.total - itemsAmounts.feeAdded)}</Typography.Paragraph> */}

            {payment.clientVoucher && <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} className='strong'>Comprobante de transferencia</Typography.Text>}
            {payment.clientVoucher && <div>
                <Image
                    height={250}
                    src={payment.clientVoucher}
                /></div>}

            <Row style={{ marginTop: '15px' }} justify="center">
                <Space>
                    <Button type="" danger onClick={RetryPayment} loading={loading}>No recibí el pago</Button>
                    <Button hidden type="ghost" onClick={() => { }} disabled={loading}>Enviar recordatorio</Button>
                    <Button type="primary" onClick={ReceivePayment} loading={loading}>Pago recibido</Button>
                </Space>
            </Row>


        </div>
    }



    return (
        <Modal visible={open} onCancel={() => close()} style={{ top: 10 }} footer={null} className={'pro-modal pro-modal-transp'}>
            {/* {sendEmail && <SendPaymentModal payment={payment} open={sendEmail} close={() => setsendEmail(false)} cancel={() => setsendEmail(false)} />} */}
            <div className={'pro-modal-header'}>
                <Typography.Title level={4} style={{ marginBottom: '0px' }}>Revisa el pago</Typography.Title>
                <Typography.Text style={{ fontSize: '11px', marginBottom: '20px' }} type='secondary'>Confirma que recibiste el pago en tu cuenta {payment.paidIn === 'bank' ? 'de banco' : ''}</Typography.Text>

                {payment.paidIn === 'bank' ? <PaidInBank /> : <></>}

            </div>
        </Modal>
    );
}

export default ReviewPaymentModal;