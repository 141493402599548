import { Input, Modal, Row, Typography, Button } from 'antd';
import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { generateCode } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';

// import CreateElementHolder from '../drawers/CreateElementHolder';
import DefaultSimpleTable from '../tables/DefaultTableClients';
import TableTitle from '../tables/TableTitle';
import { PlusOutlined } from '@ant-design/icons';
import AddNewClientForm from './AddNewClientForm';


function ClientsListModal({ open, close, onSelect, importFromStripe = false }) {

    const auth = useAuth()

    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)

    const [search, setsearch] = useState('')
    const [addClient, setAddClient] = useState(false)
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(true)

    const fs = getFirestore();

    const clientsRef = query(collection(fs, `clients`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));

    const getCustomersFromStripe = async () => {
        const clients = await SignedInternalAPIRequest({
            type: 'customers'
        }, 'retriveDataToImportFromStripe', auth.currentUser)
        setdata(clients?.customers ?? [])
        setloading(false)
    }


    useEffect(() => {
        if (importFromStripe) {
            getCustomersFromStripe()
        } else {
            setloading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importFromStripe])

    return (
        <Modal style={{ top: 10 }} width={window.innerWidth < 780 ? '100%' : "50%"} visible={open} title={null} footer={null} onCancel={() => close()}>
            {addClient && <Suspense fallback={<div>Loading...</div>}>
                <AddNewClientForm open={addClient} edit={null} close={() => {
                    setAddClient(false)
                }} userDocument={user} type="clients" />
            </Suspense>}
            <Row justify='end' style={{ marginTop: '20px', marginBottom: '10px' }}>
                <Input placeholder="Buscar cliente" onChange={(v) => setsearch(v.target.value)} style={{ width: window.innerWidth < 800 ? '100%' : '30%' }} />
                <Button shape='circle' style={{ width: '35px', height: '35px', borderRadius: '50%', marginLeft: '10px' }} icon={<PlusOutlined />} onClick={() => setAddClient(true)} ></Button>
            </Row>
            <DefaultSimpleTable onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        onSelect(record)
                    }, // click row
                    //STYLE
                    className: 'clickable',
                };
            }}
                //data={filteredClients} 
                query={clientsRef}
                importFromStripe={importFromStripe}
                data={data ?? null}
                filterSearch={search}
                loading={loading}
                filterSearchKey="name"
                empty="No hay clientes"
                elementName="cliente"
                emptyAction={() => setAddClient(true)}
                columns={[
                    {
                        title: <TableTitle title="Nombre" />,
                        dataIndex: 'name',
                        key: generateCode(5),
                        fixed: 'left',
                        width: '50%',
                        sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
                        render: (text, record) => {

                            return <Typography.Text className="primary" >{text}</Typography.Text>
                        }
                    },
                    {
                        title: <TableTitle title="Email" />,
                        dataIndex: 'email',
                        key: generateCode(5),
                        sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                        render: (text, record) => {

                            return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                        }
                    },
                ]} />
            {importFromStripe && data.length > 0 ? <Row justify="center" style={{ marginTop: '25px' }}>
                <Button onClick={() => {
                }} type="primary" style={{ alignSelf: 'center' }}>Importar Clientes</Button>
            </Row> : null}
        </Modal>
    );
}

export default ClientsListModal;