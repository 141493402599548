import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocData } from "reactfire";

export const useTeamData = ({ teamId }) => {

    const ref = doc(getFirestore(), "teams", teamId ?? "team");

    const {
        status,
        data: team,
    } = useFirestoreDocData(ref);

    if (status === "error") return { loaded: false, team: null, brand: null };


    const loaded = status === "success";
    const brand = team?.brand ?? {
        alias: team?.alias ?? team?.alias ?? team?.legalName,
        logo: team?.logo ?? team?.brand?.logo,
    };

    return {
        loaded,
        team,
        brand,
    }
}