import { Button, Col, Row, Space, message } from "antd"
import ActionablePageHeader from "../headers/ActionablePageHeader"
import { useContext, useState } from "react"
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests"
import { useAuth } from "reactfire"
import TeamContext from "../../context/teamcontext/TeamContext"
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext"
// import { useMembershipOnce } from "../../customHooks/useMembershipOnce"
import { useUserPermissions } from "../../customHooks/userPermissions"
import moment from "moment"
// import { LoadingOutlined, PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons"
// import DefaultTableItems from "../tables/DefaultTableItems"
// import { doc, getFirestore, updateDoc } from "firebase/firestore"

function RecurrentPayments({ userDocument }) {
    const { team } = useContext(TeamContext)
    // const fs = getFirestore();
    const { billingAccount } = useContext(BillingAccountContext)
    const auth = useAuth()
    const [downloadingXLSX, setdownloadingXLSX] = useState(false)
    // const { credits, isPaidUser, payAsYouGo } = useMembershipOnce({ uid: userDocument?.uid, billingAccountId: billingAccount?.id })
    // const [createRecurring, setcreateRecurring] = useState(false)
    // const [actionOnPayment, setactionOnPayment] = useState(null)
    const { canCreatePayments } = useUserPermissions({ user: userDocument, team, billingAccount })
    const DownloadXLSX = async ({ objectUrl, fname = "pagos", type = "payments" }) => {


        try {
            setdownloadingXLSX(true)
            await SignedInternalAPIRequest({
            }, `exportFiles/v1/${type}`, auth.currentUser, { isFile: true, fileName: `${fname}-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
            //DOWNLOAD FILE FROM readableStream
            setdownloadingXLSX(false)
        } catch (error) {
            message.error('Error al descargar el archivo')
            setdownloadingXLSX(false)
        }
    }
    // const PauseRecurringEvent = async (event) => {
    //     // setactionOnPayment(event.id)

    //     try {
    //         await updateDoc(doc(fs, `recurringEvents/${event.id}`), { status: event?.status === 'paused' ? 'active' : 'paused', pausedReason: null })
    //         // setactionOnPayment(null)
    //     } catch (error) {
    //         message.error(error.message ?? 'Error al pausar evento');
    //         // setactionOnPayment(null)
    //     }
    // }
    const RenderRecurringPayments = () => {
        return <Row style={{ width: '100%' }}>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <ActionablePageHeader title="Solicitudes recurrentes" actions={
                    <Space>
                        <Button loading={downloadingXLSX} onClick={() => DownloadXLSX({ objectUrl: '`gigstackpro.pro.cleaned_recurring_events_view`', fname: "recurrentes" })}>Descargar</Button>
                        {canCreatePayments && <Button onClick={() => {
                            // setcreateRecurring(true) 
                        }} type='primary'>Nuevo evento</Button>}
                    </Space>}
                />
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <Row justify="end" style={{ marginBottom: '10px' }}>
                    {/* <Input.Search style={{ width: window.innerWidth <= 800 ? '100%' : '40%' }} placeholder="Buscar cliente" defaultValue={filterByClient} onSearch={setfilterByClient} allowClear /> */}
                </Row>
                {/* <DefaultTableItems empty='No tienes eventos recurrentes de pago'
                    byClient={filterByClient}
                    defaultPage={pageActive}
                    columns={[
                        {
                            title: <TableTitle title="Cliente" />,
                            dataIndex: 'client',
                            key: generateCode(5),
                            fixed: 'left',
                            width: '16%',
                            sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
                            render: (text, record) => {

                                return <Row align="middle">
                                    <Tooltip title={record.status === 'active' ? 'Pausar' : 'Activar'}>
                                        <div onClick={() => {
                                            PauseRecurringEvent(record)
                                        }} className='customCircledTag' style={{ width: '30px', height: '30px' }}>
                                            {actionOnPayment === record.id ? <LoadingOutlined /> : record.status === 'active' ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                        </div>
                                    </Tooltip>
                                    <div className="d-flex flex-column" style={{ flex: 1 }} >
                                        <Typography.Text className="primary">{record.client?.name ?? 'Sin Cliente'}</Typography.Text>
                                        <Typography.Text className="" type="secondary" style={{ fontSize: '8px' }} copyable={{ text: record.id }} >{record.id}</Typography.Text>
                                    </div>
                                </Row>
                            }
                        },
                        {
                            title: <TableTitle title="Estado" />,
                            dataIndex: 'status',
                            key: generateCode(5),
                            ellipsis: true,
                            sorter: (a, b) => ((a?.status).localeCompare(b?.status)),
                            render: (text, record) => {
                                return <Tooltip title="">
                                    <Tag className="statusTag" style={{ borderRadius: '10px', opacity: 0.7 }}>{text === 'active' ? 'Activo' : 'En pausa'}</Tag>
                                </Tooltip>
                            }
                        },
                        {
                            title: <TableTitle title="Total" />,
                            dataIndex: 'total',
                            key: generateCode(5),
                            ellipsis: true,
                            render: (text, record) => {

                                return <Typography.Text className="" type="secondary" >{getItemsAmounts(record?.replicateElement?.items).totalString} {record?.replicateElement?.currency}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Creado" />,
                            dataIndex: 'timestamp',
                            key: generateCode(5),
                            ellipsis: true,
                            render: (text, record) => {

                                return <Typography.Text className="" type="secondary" >{moment(record.timestamp).format('d MMMM yyyy HH:mm ')}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Temporalidad" />,
                            dataIndex: 'timestamp',
                            key: generateCode(5),

                            render: (text, record) => {

                                return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{record.temporality === 'daily' ? `Diario a las ${record.onTime}` : record.temporality === 'weekly' ? `Semanal, los ${moment().day(record.onWeekday).format('dddd')} ${record.onTime}` : `Mensual los días ${record.onDay} ${record.onTime}`}</Typography.Text>
                            }
                        },
                        {
                            //TODO: MANAGE NEXT HIT
                            title: <TableTitle title="Eventos" />,
                            dataIndex: 'hits',


                            render: (text, record) => {

                                const relations = record.relations || []

                                return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{relations?.length > 0 ? `${relations.length} creados` : ''} </Typography.Text>
                            }
                        },
                        {
                            //TODO: MANAGE NEXT HIT
                            title: <TableTitle title="Siguiente evento" />,
                            dataIndex: 'nextHit',


                            render: (text, record) => {
                                const active = record.status === 'active';
                                return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{active ? record.nextRun ? moment(record.nextRun).format('ddd D MMMM YYYY HH:mm ') : record.pausedReason ? record.pausedReason : '-' : '-'}</Typography.Text>
                            }
                        },
                        {
                            //TODO: MANAGE NEXT HIT
                            title: <TableTitle title="" />,
                            dataIndex: 'actions',


                            render: (text, record) => {
                                return <Space>
                                    {canCreatePayments && <EditOutlined onClick={() => {
                                        seteditRecurring({
                                            ...record, recordID: record.id, ...record.replicateElement, startEndDate: [moment(record.startDate), moment(record.endDate)], startDate: moment(record.startDate), endDate: moment(record.endDate), temporality: {
                                                value: record.temporality,
                                                label: record.temporality === 'daily' ? 'Diario' : record.temporality === 'weekly' ? 'Semanal' : 'Mensual'
                                            },
                                            onTime: moment().hour(record.onTime.split(':')[0]).minute(record.onTime.split(':')[1])
                                        })
                                        setcreateRecurring(true)
                                    }} />}
                                    {canCreatePayments && <Popconfirm title="¿Eliminar solicitud recurrente?" okButtonProps={{ danger: true }} okText="Eliminar" onConfirm={async () => {
                                        try {
                                            await updateDoc(doc(fs, 'recurringEvents', record.id), { status: 'deleted', team: `old-${record?.team}` })
                                        } catch (error) {
                                            message.error(error.message ?? 'Error al pausar evento');
                                        }

                                    }}>
                                        <DeleteOutlined />
                                    </Popconfirm>}
                                </Space>
                            }
                        },



                    ]}
                    query={recurringRef}
                    // data={recurringData ?? []} 
                    // loading={paymentsDBStatus === 'loading'} 
                    onPageChange={(page, pageSize) => {
                        setpageActive(page)
                        setpageSize(pageSize)
                    }}
                    userDocument={userDocument} /> */}

            </Col>
        </Row>
    }
    return RenderRecurringPayments;
}

export default RecurrentPayments;