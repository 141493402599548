import { Row, Space, Typography } from "antd"
import logoGigstack from '../../assets/images/logogigstack.png'
import { VscArrowSwap } from "react-icons/vsc"

const FirstViewIntregration = ({ appLogo, appName, title, description, permissions = [], showBorder = false }) => {

    return (
        <Row align="middle" style={{ marginTop: '25px', marginBottom: '35px' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Row align="middle" justify="center" style={{ width: '100%' }}>
                    <div style={{ backgroundImage: `url(${logoGigstack})`, width: '60px', height: '60px', borderRadius: '100px', backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}></div>
                    <VscArrowSwap style={{ fontSize: '28px', color: '#7d7d7d', margin: '0px 20px' }} />
                    <div style={{ backgroundImage: `url(${appLogo})`, width: '60px', height: '60px', borderRadius: '100px', backgroundRepeat: 'no-repeat', backgroundSize: '100%', border: showBorder ? '1px solid #c9c9c9' : 'none' }}></div>
                </Row>
                <Row justify="center" style={{ marginTop: '20px' }}>
                    <Typography.Title level={4}>{title}</Typography.Title>
                </Row>
                <Row justify="center" style={{ marginTop: '0px' }}>
                    <Typography.Text >{description}</Typography.Text>
                </Row>
                <div className="integration-permissions--container" style={{ marginTop: '25px' }}>
                    <Row justify="center">
                        <Typography.Text style={{ fontWeight: 'bold' }}>{appName} podrá realizar las siguientes acciones dentro de Gigstack</Typography.Text>
                    </Row>
                    <Space direction="vertical" style={{ margin: '15px 50px' }}>
                        <ul>
                            {permissions.map((permission, index) => (
                                <Row key={index} justify="start" style={{ marginTop: '5px' }}>
                                    <li>
                                        <Typography.Text>{permission}</Typography.Text>
                                    </li>
                                </Row>
                            ))}
                        </ul>
                    </Space>
                </div>
            </Space>
        </Row>
    )
}

export default FirstViewIntregration