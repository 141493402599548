import React, { useContext, useState } from 'react'
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import { Button, Checkbox, Col, Input, Modal, Row, Switch, Tooltip, Typography, message } from 'antd';
import ClientsAndItemsSelect from '../forms/ClientsAndItemsSelect';
import { QuestionCircleOutlined } from '@ant-design/icons';

import Select from 'react-select'
import { generalreactSelectStyles } from '../../app/functions/styles';
import { InternalPayment, generateCode, getItemsAmounts, paymentForms } from '../../app/functions/helpers';
import moment from 'moment';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { useFirestoreDocDataOnce } from 'reactfire';
function RegisterPaymentModal({ open, close }) {
    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const ref = doc(getFirestore(), 'rates/rates')

    const { data: rateData } = useFirestoreDocDataOnce(ref)
    const [state, setState] = useState({
        owner: user.uid,
        automations: [{
            action: 'create',
            from: 'payments',
            ref: 'payments',
            type: 'invoice',
        }],
        billingAccount: team.billingAccount,
        team: team.id,
        client: null,
        items: [],
        payment_method: 'PUE',
        livemode: true,
        currency: team?.currency ?? 'MXN',
        exchange: 1,

    })


    const ActivateAutomation = () => {
        return <div className="d-flex flex-column" style={{ width: '100%', backgroundColor: '#fafbfd', borderRadius: '10px', padding: '10px' }}>
            <Row align="middle">
                <Typography.Text style={{ fontSize: '13px', }} className='strong' level={5}>¿Crear factura automática?</Typography.Text>
                <Tooltip title="En caso de no contar con la información realizaremos un recibo de venta para iniciar el proceso de auto-factura.">
                    <QuestionCircleOutlined style={{ marginLeft: '7px' }} />
                </Tooltip>
            </Row>
            <div style={{ marginTop: '5px' }}></div>
            <Checkbox checked={state.automations} onChange={(v) => {
                if (v.target.checked) {
                    var automations = [{
                        action: 'create',
                        from: 'payments',
                        ref: 'payments',
                        type: 'invoice',
                    }]
                    setState({ ...state, automations })
                } else {
                    delete state.automations
                    setState({ ...state })
                }
            }}><Typography.Text type="secondary">Activar facturación automática para este pago</Typography.Text></Checkbox>
        </div>
    }

    return (
        <Modal visible={open} onCancel={() => close()} title={null} footer={null} width={window.innerWidth < 800 ? '90%' : '45%'} style={{ top: '10px' }}>
            <div className="d-flex flex-column" style={{ width: '100%', marginTop: '30px' }}>
                <Row justify="space-between" align="middle">
                    <Typography.Title level={4}>Registrar nuevo pago</Typography.Title>
                    <div className='d-flex flex-column'>
                        <Row justify='end'>
                            <Switch checked={!state.livemode} style={{ margin: 0 }} onChange={() => {
                                setState({ ...state, livemode: !state.livemode })
                            }} />
                        </Row>
                        <Typography.Text style={{ margin: 0, fontSize: '10px', marginTop: '5px' }}>Modo Prueba</Typography.Text>
                    </div>
                </Row>
                {/* <Row style={{ marginTop: "10px" }} justify="end">
                    <CurrencyChip
                        onUpdate={(v) => {
                            setState({ ...state, currency: v.currency });
                        }}
                        state={state}
                    />
                </Row> */}
                <Row style={{ width: '100%' }}>
                    <ClientsAndItemsSelect child={
                        <Row>
                            <Col xs={24}>
                                <div className="d-flex flex-column" style={{ marginBottom: '20px' }}>
                                    <Typography.Title level={5} style={{ marginTop: '0px' }} >3. Forma de pago</Typography.Title>
                                    <Select
                                        styles={generalreactSelectStyles}
                                        className="pro-select-input"
                                        options={paymentForms}
                                        isLoading={false}
                                        onChange={(v) => {
                                            setState({ ...state, paymentForm: v.value })
                                        }}
                                        placeholder="Forma de pago"
                                    />
                                </div>

                            </Col> <Col xs={24}>
                                <div className="d-flex flex-column" style={{ marginBottom: '20px' }}>

                                    <Typography.Title level={5} style={{ marginTop: '0px' }} >4. Tipo de cambio</Typography.Title>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={12}>
                                            <Typography.Text level={5} style={{ marginTop: '0px' }} >Moneda</Typography.Text>
                                            <Select
                                                styles={generalreactSelectStyles}
                                                className="pro-select-input"
                                                options={[
                                                    { label: 'USD', value: 'usd' },
                                                    { label: 'EUR', value: 'eur' },
                                                    { label: 'MXN', value: 'mxn' },
                                                    { label: 'BRL', value: 'brl' },
                                                ]}
                                                defaultValue={{ label: state.currency, value: state.currency }}
                                                isLoading={false}
                                                onChange={(v) => {
                                                    const exch = rateData?.conversion_rates?.[v.value.toUpperCase()] ?? 1
                                                    setState({ ...state, currency: v?.value, exchange: (1 / exch) })

                                                    // console.log((1 / exch), 'exchange')

                                                }}
                                                placeholder="Selecciona una moneda"

                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Typography.Text level={5} style={{ marginTop: '0px' }} >Tipo de cambio</Typography.Text>

                                            {/* <Typography.Text type="secondary" >El cambio debe ser: 1 MXN equivale a X USD</Typography.Text> */}
                                            <Input
                                                disabled={state.currency === 'mxn' || state.currency === 'MXN' || team?.currency === 'MXN' || team?.currency === 'mxn'}
                                                className="pro-input"
                                                placeholder="Tipo de cambio"
                                                value={state.exchange}
                                                type='number'
                                                onChange={(e) => {
                                                    const newExchange = e.target.value;
                                                    setState({ ...state, exchange: newExchange });
                                                }}
                                            />

                                        </Col>
                                    </Row>
                                </div>

                            </Col>
                            <Col xs={24}>
                                <ActivateAutomation />
                            </Col>
                        </Row>
                    } withAutomation={false} hidePaymentSettings={true} state={state} changeState={(newState) => {
                        setState({ ...state, ...newState })
                    }} />

                </Row>
                <Row justify="center" style={{ marginTop: '20px' }}>
                    <Button type="primary" disabled={
                        !state.client ||
                        !state.items ||
                        !state.paymentForm ||
                        state.items.length === 0
                    } onClick={async () => {
                        setLoading(true)
                        const id = generateCode(10, 'payment')

                        // var rate = 1
                        // if (state.currency?.toUpperCase() !== 'MXN') {
                        //     const exchange = await getDoc(doc(getFirestore(), 'rates', 'rates'))
                        //     rate = exchange.data().conversion_rates[team.currency?.toUpperCase()]
                        // }

                        // console.log({
                        //     amount: getItemsAmounts(state.items, true).total * 100,
                        //     status: 'succeeded',
                        //     succeededTimestamp: moment().valueOf(),
                        //     internalStatus: 'succeeded',
                        //     internalItems: state.items,
                        //     custom_method_types: [],
                        //     payment_form: state.paymentForm,
                        //     payment_method: 'PUE',
                        //     exchange: Number(state.exchange),
                        //     currency: state?.currency ?? 'MXN',
                        //     paidId: "manual",
                        //     id: id,
                        //     fid: id,
                        //     v: 2,
                        //     from: "manual",
                        //     processor: 'manual',
                        //     relatedTo: '',
                        //     invoices: null,
                        //     payments: null,
                        //     client: state.client ?? null,
                        //     discount: 0, /*+ (payment?.pre_payment_credit_notes_amount/100 ?? 0) +( Math.abs(payment?.starting_balance ?? 0)/100)*/
                        //     user: user,
                        //     team: team.id,
                        //     billingAccount: team.billingAccount,
                        //     timestamp: moment().valueOf(),
                        //     created: moment().valueOf(),
                        //     binnacle: `Payment created manually by ${user?.name} ${user?.lastName}`,
                        //     payment: {
                        //         totalItemsAmounts: getItemsAmounts(state.items, true),
                        //         automations: state.automations,
                        //         livemode: state.livemode,
                        //     }
                        // })

                        const p = InternalPayment({
                            amount: getItemsAmounts(state.items, true).total * 100,
                            status: 'succeeded',
                            succeededTimestamp: moment().valueOf(),
                            internalStatus: 'succeeded',
                            internalItems: state.items,
                            custom_method_types: [],
                            payment_form: state.paymentForm,
                            payment_method: 'PUE',
                            exchange: Number(state.exchange),
                            currency: state?.currency ?? 'MXN',
                            paidId: "manual",
                            id: id,
                            fid: id,
                            v: 2,
                            from: "manual",
                            processor: 'manual',
                            relatedTo: '',
                            invoices: null,
                            payments: null,
                            client: state.client ?? null,
                            discount: 0, /*+ (payment?.pre_payment_credit_notes_amount/100 ?? 0) +( Math.abs(payment?.starting_balance ?? 0)/100)*/
                            user: user,
                            team: team.id,
                            billingAccount: team.billingAccount,
                            timestamp: moment().valueOf(),
                            created: moment().valueOf(),
                            binnacle: `Payment created manually by ${user?.name} ${user?.lastName}`,
                            payment: {
                                totalItemsAmounts: getItemsAmounts(state.items, true),
                                automations: state.automations,
                                livemode: state.livemode,
                            }
                        })
                        if (!state.automations) {
                            delete p.automations
                        }


                        try {
                            await setDoc(doc(getFirestore(), 'payments', id), p)
                            setTimeout(() => {
                                setLoading(false)
                                close();
                            }, 500);
                        } catch (error) {
                            message.error('Ocurrió un error al registrar el pago')
                            setTimeout(() => {
                                setLoading(false)
                            }, 500);
                        }




                    }} loading={loading}>{
                            state.items.length === 0 ? 'Agrega al menos un producto' : !state.client ? 'Selecciona un cliente' : 'Registrar pago'
                        }</Button>
                </Row>
            </div>
        </Modal>
    );
}

export default RegisterPaymentModal;