import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Row, Typography, message } from "antd";
import { useContext, useState } from "react";
import TeamContext from "../../context/teamcontext/TeamContext";
import { useFirestoreDoc } from "reactfire";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { UseUserDefaults } from "../../customHooks/useUserDefaults";
import moment from "moment";
import { TranslatePeriodicityToObject } from "../../app/functions/helpers";
import ReceiptsDefaults from "./ReceiptsDefaults";

const GeneralSettingsReceiptsModal = ({ open, close, edit, userDocument }) => {
    const { team, getCurrentTeam } = useContext(TeamContext);
    const ref = doc(getFirestore(), "teams", team?.id)
    const { data } = useFirestoreDoc(ref);
    const { defaults } = UseUserDefaults({ userDocument: data.data(), team: data.data() })

    const [saving, setSaving] = useState(false)





    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "40%"} style={{ top: 20 }} >
            <Form layout="vertical" initialValues={{ ...defaults, periodicity: TranslatePeriodicityToObject(defaults?.periodicity ?? ''), limitHourToPay: moment().hour(defaults?.limitHourToPay) }} onFinish={async (v) => {
                try {
                    setSaving(true)
                    var toUpdate = {

                        ...defaults,
                        ...v,
                    }
                    await updateDoc(ref, {
                        defaults: toUpdate,
                        integrationsSetup: team?.integrationsSetup?.filter((v) => v !== 'setDefaults')
                    })
                    message.success('Datos guardados')
                    setSaving(false)
                    getCurrentTeam(team?.id)
                    close()
                } catch (error) {
                    setSaving(false)
                }
            }}>
                <Row justify="space-between" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>

                    <Typography.Title level={4} style={{ marginBottom: '0px' }}>Configura tus recibos de venta</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />

                </Row>
                <Row align="middle" style={{ marginTop: '25px', marginBottom: '35px' }}>
                    <ReceiptsDefaults />
                </Row>
                <Row style={{ paddingTop: '25px', width: '100%' }} justify="center">
                    <Button htmlType="submit" loading={saving} type="primary">Guardar</Button>
                </Row>
            </Form>
        </Modal >
    );
}


export default GeneralSettingsReceiptsModal;