import { useContext, useState } from "react"
import { useTeamsData } from "../../customHooks/useTeams"
import { useAuth, useFirestore } from "reactfire"
import UserContext from "../../context/usercontext/UserContext"
import TeamContext from "../../context/teamcontext/TeamContext"
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext"
import { Avatar, Divider, Input, Row, Tooltip, Typography, message } from "antd"
import { doc, updateDoc } from "firebase/firestore"
import { LoadingOutlined, LogoutOutlined, PlusCircleOutlined, UsergroupAddOutlined } from "@ant-design/icons"
import ModalContext from "../../context/modalcontext/ModalContext"

const signOut = auth => auth.signOut().then(() => window.location.replace('/login'));

function TeamsSelectorPopover({ closePop }) {

    const auth = useAuth()
    const { teams } = useTeamsData({ uid: auth.currentUser.uid })
    const { handleOpenCreateTeamModal } = useContext(ModalContext)

    const [loading, setLoading] = useState([])
    const [search, setSearch] = useState("")
    const [showAll, setShowAll] = useState(false)
    const { getCurrentTeam } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    const { billingAccount, getBillingAccount } = useContext(BillingAccountContext)
    const fs = useFirestore()
    const [messageApi, contextHolder] = message.useMessage();

    var teamsFiltered = teams.filter((team) => team.brand?.alias?.toLowerCase().includes(search.toLowerCase()))
    if (!showAll) {
        teamsFiltered = teamsFiltered.slice(0, 3)
    } else {
        teamsFiltered = teamsFiltered.slice(0, teamsFiltered.length)
    }

    console.log(billingAccount);

    var paidAccount = (billingAccount?.subscription?.plan?.amount ?? 0) > 0
    console.log(paidAccount);


    return (
        <div className='d-flex flex-column' style={{ minWidth: '200px', maxWidth: '200px' }}>
            {contextHolder}
            <Row justify="space-between" align="middle" style={{ borderBottom: '0.5px solid transparent', paddingBottom: '5px' }}>
                <Typography.Text style={{ color: '#474D66', fontSize: '12px', fontWeight: 600 }}>Cambiar de cuenta</Typography.Text>
            </Row>
            <Input.Search
                size="small"
                styleProps={{ margin: 0, padding: 0, marginBottom: '10px' }}
                name='search' placeholder='Busca un equipo' label={null} onChange={(v) => {
                    setSearch(v.target.value)
                }} />
            <div className='d-flex flex-column' style={{ marginTop: '5px', marginBottom: '6px', maxHeight: '420px', overflow: 'auto' }}>

                {teamsFiltered?.map((team) => {
                    return (
                        <Row key={team.id} style={{ marginTop: '8px', cursor: 'pointer' }} className='teamRowSelector' align="middle" onClick={async (_) => {
                            try {
                                setLoading([...loading, team.id])
                                // GetBATeamsHelper(user?.uid, dispatch)
                                await updateDoc(doc(fs, 'users', user?.uid), {
                                    lastTeamViewed: team.id,
                                    lastBillingAccountViewed: team?.billingAccount
                                })
                                // GetTeamHelper(team.id, dispatch)
                                getCurrentTeam(team.id)
                                getBillingAccount(team?.billingAccountId)
                                // GetBillingAccountHelper(team?.billingAccount, dispatch)
                                //wait 2 seconds
                                // await new Promise(r => setTimeout(r, 500));
                                setLoading(loading.filter((id) => id !== team.id))
                                messageApi.open({
                                    type: 'success',
                                    content: 'Cambiaste de cuenta',
                                });
                                return window.location.reload();
                            } catch (error) {
                                console.log(error)
                            }
                        }}>
                            {loading.includes(team?.id) ? <LoadingOutlined size={18} color={team?.brand?.primaryColor} loading={loading.includes(team?.id)} /> : <Avatar size={18} src={team.brand?.logo} />}
                            <Tooltip title={(team.brand?.alias ?? '')?.length > 26 ? team.brand?.alias : null}>
                                <Typography.Text style={{
                                    fontSize: '12px', paddingLeft: '10px', flex: 1, maxLines: 1,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}>{team.brand?.alias ?? '¿'}</Typography.Text>
                            </Tooltip>
                            {team.membersIds?.length - 1 > 1 && <Tooltip
                                title={`
                                ${team.membersIds.length} miembros
                                `}
                            >
                                <Row align="middle">
                                    <UsergroupAddOutlined />
                                    <Typography.Text style={{ fontSize: '10px', padding: 0, margin: 0, paddingLeft: '1px', color: '#333' }}>{team.membersIds?.length}</Typography.Text>
                                </Row>

                            </Tooltip>}
                        </Row>
                    )
                })}
            </div>
            {teams.length > 3 ? <Typography.Link style={{ textAlign: 'left', padding: 0, fontSize: '12px' }} onClick={() => setShowAll(!showAll)}>{showAll ? 'Mostrar menos' : `Mostrar todos (${teams.length})`}</Typography.Link> : <></>}
            <Row justify="start" align="middle" className="clickable" style={{ paddingTop: '7px' }} onClick={() => handleOpenCreateTeamModal()}>
                {/* <LogoutOutlined style={{ color: '#474D66', fontSize: '12px', fontWeight: 600, marginRight: '5px' }} /> */}
                <PlusCircleOutlined style={{ color: '#474D66', fontSize: '12px', fontWeight: 600, marginRight: '5px' }} />
                <Typography.Link style={{ color: '#474D66', fontSize: '12px', fontWeight: 600 }}>Crear marca</Typography.Link>
            </Row>
            <Divider style={{ margin: '8px 0px 0px 0px' }} />
            <Row justify="start" align="middle" className="clickable" style={{ paddingTop: '7px' }} onClick={() => signOut(auth)}>
                <LogoutOutlined style={{ color: '#474D66', fontSize: '12px', fontWeight: 600, marginRight: '5px' }} />
                <Typography.Text style={{ color: '#474D66', fontSize: '12px', fontWeight: 600 }}>Cerrar sesión</Typography.Text>
            </Row>
        </div>
    )
}

export default TeamsSelectorPopover

