import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import TeamContext from '../../context/teamcontext/TeamContext';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { useAuth } from 'reactfire';

function CustomerPortal() {
    const [loading, setLoading] = useState(false);
    const { team } = useContext(TeamContext);
    const [portalId, setPortalId] = useState('');
    const [changeState, setChangeState] = useState(false)
    const auth = useAuth();



    const handleUpdateInformation = async (v) => {
        try {
            if (!v.portalId) return
            setLoading(true);
            const trimmedValue = v.portalId.replace(/\s+/g, '');
            const response = await SignedInternalAPIRequest(
                { portalId: trimmedValue },
                'customerPortal/v1/Validate',
                auth.currentUser,
            );
            setChangeState(true)
            if (response.status === 'success') {
                setPortalId(trimmedValue);
                message.success('Creado correctamente.');
            } else {
                message.error(response.message);
            }

            setLoading(false);
        } catch (error) {
            console.log(error)
            message.error(error.message);
            setLoading(false);
        }
    };

    return (

        <Col xs={24}>
            <Form className="settingsContainer" onFinish={handleUpdateInformation}>
                <Typography.Title style={{ marginBottom: '2px' }} level={5}>
                    Portal del cliente
                </Typography.Title>
                <Typography.Text type="secondary">
                    Un portal donde tu cliente podrá ver toda su información (facturas, pagos, recibos) sin necesidad de crear una cuenta.
                </Typography.Text>
                <Divider />
                <Row style={{ width: '100%', marginBottom: '20px' }} justify="center">
                    <Row justify="center" align="middle" style={{ marginBottom: '15px' }}>
                        {team.customerPortalId || changeState ? <></> : <Form.Item
                            name="portalId"
                            rules={[{ required: true, message: 'Por favor ingresa el nombre del portal' },
                            // Add a rule that checks that the field doen't have spaces and accented characters
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || !value.match(/^[a-zA-Z0-9]+$/)) {
                                        return Promise.reject(
                                            new Error(
                                                'El nombre del portal no puede contener espacios ni caracteres especiales',
                                            ),
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                            ]}
                            help={`El nombre del portal será la URL a la que tus clientes tendrán que entrar ej: portal.gigstack.pro/${team?.brand?.alias?.replace(' ', '').toLowerCase()}`} >
                            <Input
                                placeholder="Nombre del portal"
                                // onChange={(e) => handlePortalIdChange(e.target.value)}
                                value={portalId}
                            />
                        </Form.Item>}
                    </Row>
                    <Col span={24}>
                        {(team.customerPortalId || changeState) && <div style={{ borderRadius: '10px', backgroundColor: '#fafbfd', padding: '10px' }} onClick={() => {
                            navigator.clipboard.writeText(`https://portal.gigstack.pro/${team.customerPortalId ? team.customerPortalId : portalId}`)
                            message.success('Copiado al portapapeles')
                        }}>
                            <Row justify='center'>
                                <Typography.Text style={{ fontWeight: 'bold' }} >{`https://portal.gigstack.pro/${team.customerPortalId ? team.customerPortalId : portalId}`}</Typography.Text>
                            </Row>
                        </div>}
                        <Row justify="center">

                            {/* <Typography.Text >{`https://portal.gigstack.pro/${team.customerPortalId ? team.customerPortalId : portalId}`} {team.customerPortalId || changeState ? <Typography.Text copyable={{
                                icon: <CopyOutlined />,
                                tooltips: ['Copiar', 'Copiado'],
                                text: `https://portal.gigstack.pro/${team.customerPortalId ? team.customerPortalId : portalId}`
                            }}></Typography.Text> : <></>}</Typography.Text> */}
                        </Row>
                        <Row justify="center" style={{ marginTop: '20px' }}>
                            {team.customerPortalId || changeState ? (
                                <Typography.Text>Si deseas cambiar la URL de tu portal por favor escríbenos.</Typography.Text>
                            ) : (
                                <></>
                            )}
                        </Row>
                    </Col>
                </Row>

                <Row style={{ width: '100%' }} justify="center">
                    {team.customerPortalId || changeState ? (
                        <></>
                    ) : (
                        <Button htmlType="submit" loading={loading}>
                            Actualizar información
                        </Button>
                    )}
                </Row>
            </Form>
        </Col>



    );
}

export default CustomerPortal;
