import { ApiOutlined, BookOutlined, CloseOutlined, DollarCircleOutlined, InfoCircleFilled, MailOutlined, SendOutlined } from '@ant-design/icons';
import { Drawer, Form, Row, Typography, Button, message, Tabs, Divider, DatePicker, Switch, } from 'antd';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { useFirestoreDoc } from 'reactfire';
// import { countryLabeledList } from '../../app/functions/datasets';
import { TranslatePeriodicityToObject } from '../../app/functions/helpers';
import { generalreactSelectStyles } from '../../app/functions/styles';
import TeamContext from '../../context/teamcontext/TeamContext';
import { UseUserDefaults } from '../../customHooks/useUserDefaults';
import FiscalDefaults from './FiscalDefaults';
import ReceiptsDefaults from '../modals/ReceiptsDefaults';
import EmailsTemplatesDefaults from '../email/EmailsTemplatesDefaults';
import TextArea from 'antd/lib/input/TextArea';
import EmailsAttachmentsDefaults from '../email/AttachmentEmail';

function DefaultsConfigDrawer({ open, close, userDocument }) {

    const { team, getCurrentTeam } = useContext(TeamContext)

    const [periodicity, setPeriodicity] = useState(moment())
    const [fiscalForm] = Form.useForm();

    const ref = doc(getFirestore(), "teams", team?.id)
    const { data } = useFirestoreDoc(ref);
    const { defaults } = UseUserDefaults({ userDocument: data.data(), team: data.data() })

    const [tab, settab] = useState('fiscal')
    const [saving, setSaving] = useState(false)
    const [invoicePerReceiptState, setInvoicePerReceiptState] = useState(defaults?.invoicePerReceipt ?? false)

    const [defaultTaxes, setDefaultTaxes] = useState(defaults?.defaultTaxes)
    const [selectedTemplates, setSelectedTemplates] = useState({
        invoicesEmailsTemplate: defaults?.invoicesEmailsTemplate ?? null,
        paymentsEmailsTemplate: defaults?.paymentsEmailsTemplate ?? null,
        receiptsEmailsTemplate: defaults?.receiptsEmailsTemplate ?? null,
    })
    const [reminderPayments, setReminderPayments] = useState(defaults?.sendPaymentsReminder ?? false)
    const [reminderPaymentsDays, setReminderPaymentsDays] = useState(defaults?.paymentsReminderDays ?? false)

    const [taxesUSDDefault, setTaxesUSDDefault] = useState(defaults?.forcedTaxesOnUSD ?? false)
    const [defaultTaxesInUSD, setDefaultTaxesInUSD] = useState(defaults?.taxesUSDDefaults)
    const defaultOption = team?.defaults?.limitDaysToPay
        ? {
            value: team?.defaults?.limitDaysToPay,
            label: `${team?.defaults?.limitDaysToPay} día${team?.defaults?.limitDaysToPay > 1 ? 's' : ''}`,
        }
        : null;



    useEffect(() => {

        setPeriodicity(defaults?.periodicity ?? '')

        //eslint-disable-next-line
    }, [])
    const [emailAttachment, setEmailAttachment] = useState(team.defaults?.emailAttachments ?? []);

    // const handleEmailAttachmentChange = (newEmail) => {
    //     console.log(newEmail, 'newEmail')
    //     setEmailAttachment(newEmail);
    // };

    const PaymentDefaults = () => {
        return <>
            <Divider />
            <Form.Item label="Días límite de pago" name="limitDaysToPay" help={<Row align="middle" style={{ marginTop: '5px' }}>
                <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece', marginTop: '5px' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px', flex: 1 }} type="secondary">Define un límite de tiempo en el que el pago se marcará como atrasado para que puedas accionar sobre los clientes.</Typography.Text>
            </Row>}>
                <Select
                    defaultValue={defaultOption}
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    isMulti={false}
                    options={
                        Array.from(Array(31).keys()).map((v) => { v = v + 1; return { value: v, label: `${v} día${v > 1 ? 's' : ''}` } })
                    }
                    isLoading={false}
                    placeholder="Días límite de pagos"
                />
            </Form.Item>
            <Form.Item label="Hora límite" valuePropName='date' name="limitHourToPay" style={{ marginTop: '5px' }}>
                <DatePicker picker='time' format='H' />

            </Form.Item>
            <Divider />
            <Form.Item label="Envia correos de recordatorio de tu pago" help={<Row align="middle" style={{ marginTop: '5px' }}>
            </Row>}>
                <Row style={{ marginTop: '5px', marginBottom: '20px' }} align="middle">
                    {<Switch defaultChecked={reminderPayments} checked={reminderPayments} onChange={(v) => {
                        updateDoc(ref, {
                            defaults: {
                                ...data.data()?.defaults,
                                sendPaymentsReminder: v
                            },
                        })
                        getCurrentTeam(team?.id)
                        message.success('Datos guardados')
                        setReminderPayments(v)
                    }} />}
                    <Typography.Text type="secondary" style={{ fontSize: '13px', flex: 1, marginLeft: '15px' }}>Activa enviar recordatorios de tus pagos.</Typography.Text>
                </Row>
                {defaults.sendPaymentsReminder && <Select
                    styles={generalreactSelectStyles}
                    value={{ value: reminderPaymentsDays, label: `${reminderPaymentsDays} día${reminderPaymentsDays > 1 ? 's' : ''}` }}
                    className="pro-select-input"
                    isMulti={false}
                    options={
                        Array.from(Array(5).keys()).map((v) => { v = v + 1; return { value: v, label: `${v} día${v > 1 ? 's' : ''}` } })
                    }
                    onChange={(v) => {
                        updateDoc(ref, {
                            defaults: {
                                ...data.data()?.defaults,
                                paymentsReminderDays: v.value
                            },
                        })
                        getCurrentTeam(team?.id)
                        message.success('Dias guardados')
                        setReminderPaymentsDays(v.value)
                    }
                    }
                    isLoading={false}
                    placeholder="Envia correos de recordatorio de tu pago"
                />}
            </Form.Item>
            <Divider />
            <Form.Item label="Activar impuestos definidos en dólares" help={<Row align="middle" style={{ marginTop: '5px' }}>
            </Row>}>
                <Row style={{ marginTop: '5px', marginBottom: '20px' }} align="middle">
                    {<Switch defaultChecked={taxesUSDDefault} checked={taxesUSDDefault} onChange={(v) => {
                        updateDoc(ref, {
                            defaults: {
                                ...defaults,
                                forcedTaxesOnUSD: v
                            },
                        })
                        getCurrentTeam(team?.id)
                        message.success('Datos guardados')
                        setTaxesUSDDefault(v)
                    }} />}
                    <Typography.Text type="secondary" style={{ fontSize: '13px', flex: 1, marginLeft: '15px' }}>Activar poder definir impuestos en USD.</Typography.Text>
                </Row>

            </Form.Item>

        </>
    }

    const InvoicesDefaults = () => {

        return (<>
            <Divider />
            <Form.Item label="Mensaje personalizado en facturas de Ingreso" name="invoicePDFExtra" help={<Row align="middle" style={{ marginTop: '5px' }}>
                <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece', marginTop: '5px' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px', flex: 1 }} type="secondary">Este mensaje se mostrará en todas las facturas que envíes a tus clientes.</Typography.Text>
            </Row>}>
                <TextArea rows={4} />
            </Form.Item>
            <Divider />
            <Form.Item label="Addenda" name="addenda" help={<Row align="middle" style={{ marginTop: '5px' }}>
                <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece', marginTop: '5px' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px', flex: 1 }} type="secondary"> Elemento opcional que se incorpora en el Comprobante Fiscal Digital por Internet (CFDI) en su formato XML</Typography.Text>
            </Row>}>
                <TextArea rows={4} />
            </Form.Item>
            <Row style={{ marginTop: '5px', marginBottom: '20px' }} align="middle">
                {<Switch defaultChecked={invoicePerReceiptState} checked={invoicePerReceiptState} onChange={(v) => {
                    updateDoc(ref, {
                        defaults: {
                            ...data.data()?.defaults,
                            invoicePerReceipt: v
                        },
                    })
                    getCurrentTeam(team?.id)
                    message.success('Datos guardados')
                    setInvoicePerReceiptState(v)
                }} />}
                <Typography.Text type="secondary" style={{ fontSize: '13px', flex: 1, marginLeft: '15px' }}>Emitir una factura global por cada recibo de venta abierto. De lo contrario haremos la emisión englobando todos los recibos abiertos.</Typography.Text>
            </Row>
        </>
        )
    }

    return (
        <Drawer title={null} footer={null} closable={false} visible={open} onClose={() => close()} width={window.innerWidth < 780 ? '90%' : '60%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }}   >
            <Form layout="vertical" form={fiscalForm} initialValues={{ ...defaults, periodicity: TranslatePeriodicityToObject(defaults?.periodicity ?? ''), limitHourToPay: moment().hour(defaults?.limitHourToPay) }} onFinish={async (v) => {
                try {
                    setSaving(true)
                    var toUpdate = {
                        ...defaults,
                        ...v,
                        invoice_folio: Number(v.invoice_folio) ?? null,
                        invoice_folio_egress: Number(v.invoice_folio_egress) ?? null,
                        invoice_folio_payments: Number(v.invoice_folio_payments) ?? null,
                        invoice_serie: v.invoice_serie ?? null,
                        invoice_serie_egress: v.invoice_serie_egress ?? null,
                        invoice_serie_payments: v.invoice_serie_payments ?? null,
                        product_key: v.product_key ?? null,
                        unit_key: v.unit_key ?? null,
                        unit_name: v.unit_name ?? null,
                        usage: v.usage ?? null,
                        invoicePDFExtra: v.invoicePDFExtra ?? null,
                        addenda: v.addenda ?? null,
                        limitHourToPay: v.limitHourToPay ? moment(v.limitHourToPay).format('H') : null,
                        emailAttachments: emailAttachment,
                    }
                    // console.log(toUpdate, 'emailAttachment')
                    if (!toUpdate?.limitDaysToPay) {
                        delete toUpdate.limitDaysToPay
                    }
                    if (!toUpdate?.limitHourToPay) {
                        delete toUpdate.limitHourToPay
                    }
                    if (tab === 'fiscal') {
                        toUpdate.periodicity = periodicity

                        toUpdate.defaultTaxes = defaultTaxes ?? []
                        toUpdate.taxesUSDDefaults = defaultTaxesInUSD ?? []
                    } else if (tab === "payments") {
                        toUpdate.limitHourToPay = v.limitHourToPay ? moment(v.limitHourToPay).format('H') : null;
                        // toUpdate.limitHourToPay = v.limitHourToPay ? v.limitHourToPay?.format('H') : null
                    }
                    await updateDoc(ref, {
                        defaults: { ...toUpdate, ...selectedTemplates },
                        integrationsSetup: team?.integrationsSetup?.filter((v) => v !== 'setDefaults') ?? []
                    })
                    getCurrentTeam(team?.id)
                    message.success('Datos guardados')
                    setSaving(false)
                } catch (error) {
                    setSaving(false)
                    message.error(error?.message ?? 'Ocurrió un error al guardar los datos')
                }
            }}>

                <Row justify="space-between">
                    <Typography.Title level={4} style={{ flex: 1 }}>Predeterminados</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                </Row>
                <Row style={{ marginBottom: '15px' }}>
                    <Typography.Text className='' type="secondary" style={{ flex: 1, fontSize: '11px' }}>Utilizaremos esta información por defecto cuando no recibamos todos los detalles para las automatizaciones</Typography.Text>
                </Row>
                <Tabs onChange={(k) => { settab(k) }} defaultActiveKey={tab} type="card">
                    <Tabs.TabPane tab={<Row align="middle">
                        <ApiOutlined /><Typography.Text> Fiscal</Typography.Text>
                    </Row>} key="fiscal">
                        <FiscalDefaults setTaxes={(taxes) => {
                            setDefaultTaxes(taxes)
                        }} setTaxesInUSD={(taxes) => {
                            setDefaultTaxesInUSD(taxes)
                        }} setProductKey={(k) => {
                            fiscalForm?.setFieldValue('product_key', k)
                        }} />
                    </Tabs.TabPane>
                    {/* {defaults?.allowPaymentsSetting && <Tabs.TabPane tab={<Row align="middle">
                        <DollarCircleOutlined /><Typography.Text> Pagos</Typography.Text>
                    </Row>} key="payments">
                        <PaymentDefaults />
                    </Tabs.TabPane>} */}
                    <Tabs.TabPane tab={<Row align="middle">
                        <DollarCircleOutlined /><Typography.Text> Pagos</Typography.Text>
                    </Row>} key="payments">
                        <PaymentDefaults />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Row align="middle">
                        <BookOutlined /><Typography.Text>Facturas</Typography.Text>
                    </Row>} key="invoices">
                        <InvoicesDefaults />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Row align="middle">
                        <SendOutlined /><Typography.Text> Recibos de venta</Typography.Text>
                    </Row>} key="receipts">
                        <ReceiptsDefaults />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Row align="middle">
                        <MailOutlined /><Typography.Text> Correos</Typography.Text>
                    </Row>} key="emails">
                        <EmailsAttachmentsDefaults
                            initialEmail={team?.defaults?.emailAttachments}
                            onEmailChange={(v) => setEmailAttachment(v)} />
                        <EmailsTemplatesDefaults onSelectedTemplates={(templates) => setSelectedTemplates(templates)} />
                    </Tabs.TabPane>
                </Tabs>


                <Row style={{ paddingTop: '25px', width: '100%' }} justify="center">
                    <Button htmlType="submit" loading={saving} type="primary">Guardar predeterminados</Button>
                </Row>
            </Form>
        </Drawer>
    );
}

export default DefaultsConfigDrawer;