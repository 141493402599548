import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

const searchClient = ({ team, indexName }) => {
    let queryBy = "client.name,client.email,client.legal_name,fid";

    switch (indexName) {
        case 'invoices':
            queryBy = "client.name,client.email,client.legal_name,uuid";
            break;
        case 'receipts':
            queryBy = "client.name,client.email,client.legal_name,url";
            break;
        default:
            break;
    }

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: team?.typesense?.keys?.live_key ?? "",
            nodes: [
                {
                    host: 'gd25i0pqarmlxytsp-1.a1.typesense.net',// 'typesense.gigstack.pro',
                    port: 443,
                    protocol: 'https'
                }
            ]
        },
        additionalSearchParameters: {
            infix: 'fallback',
            query_by: queryBy,
            facet_by: `team:${team?.id}`,
            sort_by: 'timestamp:desc',
        }
    });

    return typesenseInstantsearchAdapter.searchClient;
};

export default searchClient
