import { Typography } from "antd";
import moment from "moment";



/**
 * It takes a state object and returns a message and a nextHit date
 * @returns An object with a message and a nextHit.
 */
export const SummarizeMessage = ({ state = {} }) => {


    var startDate = moment(state.startDate);
    if (startDate.isBefore(moment())) {
        startDate = moment();
    }
    const amountOfDays = moment(moment(state.endDate).add(1, 'day')).diff(moment(state.startDate), 'days');
    var nextHit = null


    var ontime = typeof state?.onTime === 'string' ? (state?.onTime) : state?.onTime?.format('HH:mm');


    if (!state.onTime) {
        return { message: <Typography.Text type="secondary" style={{ fontSize: '12px' }}></Typography.Text> }
    }

    if (state.temporality === 'daily') {
        [...Array(amountOfDays).keys()].forEach((d) => {
            const a = moment(startDate).add(d, 'days').hours(ontime.split(':')[0]).minutes(ontime.split(':')[1])
            if (!nextHit && a.isAfter(moment().add(0, 'minutes'))) {
                nextHit = a.valueOf();
            }
        })
        const data = { nextHit, message: <Typography.Text style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }} className="secondary">De acuerdo a las reglas.<br />Siguiente solicitud el {moment(nextHit).format('dddd D MMMM YYYY HH:mm')}</Typography.Text> }
        return data;


    } else if (state.temporality === 'weekly') {
        [...Array(amountOfDays).keys()].forEach((d) => {
            const a = moment(startDate).add(d, 'days').hours(ontime.split(':')[0]).minutes(ontime.split(':')[1])
            if (!nextHit && a.isAfter(moment().add(0, 'minutes')) && state.onWeekday === Number(a.day())) {
                nextHit = a.valueOf();
            }
        })
        const weeklyData = { nextHit, message: <Typography.Text style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }} className="secondary">De acuerdo a las reglas, enviaremos 1 solicitud de pago los días {(state.onWeekday != null) ? moment().day(state.onWeekday).format('dddd') : ''}.<br />Siguiente solicitud el {moment(nextHit).format('dddd D MMMM YYYY HH:mm')}</Typography.Text> }
        return weeklyData


    } else if (state.temporality === 'monthly') {
        [...Array(amountOfDays).keys()].forEach((d) => {
            const a = moment(startDate).add(d, 'days').hours(ontime.split(':')[0]).minutes(ontime.split(':')[1])
            if (!nextHit && a.isAfter(moment().add(0, 'minutes')) && state.onDay === Number(a.date())) {
                nextHit = a.valueOf();
            }
        })

        const monthlyData = { nextHit, message: <Typography.Text style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }} className="secondary">De acuerdo a las reglas, enviaremos 1 solicitud de pago el día {state.onDay} de cada mes.<br />Siguiente solicitud el {moment(nextHit).format('dddd D MMMM YYYY HH:mm')}</Typography.Text> }

        return monthlyData

    } else {
        return { message: <Typography.Text type="secondary" style={{ fontSize: '12px' }}></Typography.Text>, nextHit: null }
    }
}
