import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Switch, Typography, message } from 'antd';
import { logEvent } from 'firebase/analytics';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useAnalytics, useAuth } from 'reactfire';
import { SignedCustomInternalAPIRequest, SignedInternalAPIRequest } from '../functions/APIRequests';
import { SearchParamInURL } from '../functions/helpers';
import startupIcon from '../../assets/images/Startup.gif';
import growingIcon from '../../assets/images/Growing.gif';
// import { useMembershipOnce } from '../../customHooks/useMembershipOnce';


function MembershipsPage({ authData }) {
    const [billing, setbilling] = useState(SearchParamInURL('billing') ?? 'monthly')
    const [membershipDoc, setmembershipDoc] = useState(null)
    // const [activePriceID, setactivePriceID] = useState(null)
    const [loading, setloading] = useState(null)
    const [coupon, setcoupon] = useState(null)
    const [hasCoupon, setHasCoupon] = useState(false)
    const billingAccountTrial = true
    const auth = useAuth()
    /* Setting the price id for the plan based on the billing cycle from stripe*/
    // const team = billing === 'monthly' ? 'price_1MdFL1Ir7OeAkMH90lF6M7WW' : 'price_1MdFMPIr7OeAkMH9ycbou9qy'
    // const startup = billing === 'monthly' ? 'price_1MdFUpIr7OeAkMH9P1jN0w4l' : 'price_1MdFV9Ir7OeAkMH9rzavHnPQ'
    // const growing = billing === 'monthly' ? 'price_1MdFTaIr7OeAkMH9osbaflaq' : 'price_1MdFU3Ir7OeAkMH9pKiu92HU'

    const team = billing === 'monthly' ? 'price_1NdcDbIr7OeAkMH906SHWsbx' : 'price_1OWr2kIr7OeAkMH9S0t48yru'
    const startup = billing === 'monthly' ? 'price_1NIgCwIr7OeAkMH9YGAN7KPs' : 'price_1OWr22Ir7OeAkMH92q2MyXPB'
    const growing = billing === 'monthly' ? 'price_1NQvVrIr7OeAkMH9x2nfczbi' : 'price_1ORzFqIr7OeAkMH9qiUQ4gPS'
    const customPriceId = SearchParamInURL('customPriceId')

    const analytics = useAnalytics();


    const price_id = 'free'
    /**
     * It gets the coupon data from the server and sets the coupon state to the data
     */
    const GetCData = async () => {
        const d = await SignedCustomInternalAPIRequest({ code: SearchParamInURL('code') }, 'getCouponData', null)

        setcoupon(d)
    }

    useEffect(() => {
        if (authData) {
            // getDoc(doc(getFirestore(), 'billing/' + authData?.uid)).then(user => {
            //     if (user.data()?.price_id) {
            //         // setactivePriceID(user.data().price_id)
            //     }
            //     setmembershipDoc(user.data())
            // })

            getDoc(doc(getFirestore(), 'users/' + authData?.uid)).then(user => {
                if (user.data()?.lastBillingAccountViewed) {
                    getDoc(doc(getFirestore(), 'billing/' + user.data()?.lastBillingAccountViewed)).then(billing => {
                        // setBillingAccountTrial(billing.data().trialUsed)
                        setmembershipDoc(billing.data())
                    })
                }
            })
        }
        if (SearchParamInURL('code')) {
            setHasCoupon(true)
            GetCData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    /**
     * It checks if the user is logged in, if not, it redirects them to the signup page with the billing
     * type and the current url as query params
     * @returns const manage_session = await SignedInternalAPIRequest({
     *         type: 'manage', price, coupon: SearchParamInURL('code'), redirect:
     * 'https://app.gigstack.pro/settings?subtab=membership'
     *     }, 'manageSubscriptionWithCustomerPortal', auth.currentUser)
     *     return window.open(man
     */
    const ManageSuscription = async ({ price }) => {
        if (!authData) {
            window.location.href = '/register?redirectUrl=' + window.location.href + '&billing=' + billing + '&price_id=' + price + '&code=' + SearchParamInURL('code')
        }
        logEvent(analytics, 'subscribe', { price_id: price })
        setloading(price)
        if ((membershipDoc?.price_id !== null && membershipDoc?.price_id !== 'free') && membershipDoc) {

            //TODO: GO TO MANAGE SUBS
            try {
                const manage_session = await SignedInternalAPIRequest({
                    type: 'manage', price, coupon: SearchParamInURL('code'), redirect: 'https://app.gigstack.pro/settings?subtab=membership'
                }, 'manageSubscriptionWithCustomerPortal', auth.currentUser)
                return window.open(manage_session.session, '_self')
            } catch (error) {
                message.error(error.message ?? 'Ocurrió un error al procesar tu solicitud')
                setloading(null)
            }
        }
        else {

            //TODO: CREATE PORTAL 
            try {
                const session = await SignedInternalAPIRequest({ type: 'subscribe', price, coupon: billing === 'anual' ? null : SearchParamInURL('code'), cancelUrl: window.location.href, successUrl: 'https://app.gigstack.pro/settings?subtab=membership', allowTrial: true }, 'manageSubscriptionWithCustomerPortal', auth.currentUser)
                return window.open(session.session, '_self')
            } catch (error) {
                setloading(null)
            }
        }
    }



    const CheckedItem = ({ text, strong }) => {
        return <Row style={{ width: '100%', marginTop: '10px' }}>
            <CheckOutlined />
            <Typography.Text style={{
                marginLeft: '10px', flex: 1,
                fontWeight: strong ? 'bold' : 'normal'
            }}>{text}</Typography.Text>
        </Row>
    }
    const UncheckedItem = ({ text }) => {
        return <Row style={{ width: '100%', marginTop: '10px' }}>
            <CloseOutlined />
            <Typography.Text style={{ marginLeft: '10px', flex: 1, textDecorationLine: 'line-through' }} type="secondary">{text}</Typography.Text>
        </Row>
    }


    /**
     * It returns a column with a card that has a title, a description, a price, and a button
     * @returns The return is a function that returns a component.
     */
    const TeamPlan = () => {
        return <Col xs={24} lg={12} xl={8}>
            <div style={{ padding: '30px', border: `1px solid ${team === price_id ? 'red' : 'white'}` }} className="d-flex flex-column pricingCard">
                <div> <img alt="Personal" src={startupIcon} height="60px" /></div>
                <Typography.Title style={{ marginTop: '10px' }} level={3}>Team</Typography.Title>
                <Typography.Text type="secondary" style={{ textAlign: 'center', minHeight: '32.4px' }} className="">Un plan que te ayudará a impulsar tu negocio.</Typography.Text>
                <Typography.Title style={{ marginTop: '10px', marginBottom: 0, paddingBottom: 0 }} level={2}>{billing === 'monthly' ? '$99 usd' : '$83 usd'} <span style={{ fontSize: '11px', color: "#cecece" }}>+ IVA</span></Typography.Title>
                <Typography.Text type='secondary'>facturado {billing === 'monthly' ? 'mensualmente' : 'anualmente'}</Typography.Text>
                <Button loading={loading === team} onClick={() => ManageSuscription({ price: team })} style={{ width: '100%', marginTop: '30px' }}>{billingAccountTrial ? 'Suscríbete ahora' : 'Prueba 15 días'}</Button>
                <Typography.Title style={{ marginTop: '15px', marginBottom: 0, paddingBottom: 0, width: '100%', textAlign: 'start' }} level={4}></Typography.Title>
                <CheckedItem text="150 eventos al mes (automatización)" strong={true} />
                <CheckedItem text="100 clientes" />
                <CheckedItem text="Portal de pago co-brandeado" />
                <CheckedItem text="Portal de auto-factura co-brandeado" />
                <CheckedItem text="Todas las integraciones incluidas" />
                <UncheckedItem text="Pago por evento extra" />
                <UncheckedItem text="Soporte por Slack" />






            </div>
        </Col>
    }

    const StartupPlan = () => {
        return <Col xs={24} lg={12} xl={8}>
            <div style={{ padding: '30px', border: `1px solid ${startup === price_id ? 'red' : 'white'}` }} className="d-flex flex-column pricingCard bestOptCard">
                <div> <img alt="Creciendo" src={growingIcon} height="60px" /></div>
                <Typography.Title style={{ marginTop: '10px' }} level={3}>Startup</Typography.Title>
                <Typography.Text type="secondary" style={{ textAlign: 'center', minHeight: '32.4px' }} className="">Un plan para empresas que están en crecimiento.</Typography.Text>
                <Typography.Title style={{ marginTop: '10px', marginBottom: 0, paddingBottom: 0 }} level={2}>{billing === 'monthly' ? '$149 usd' : '$124 usd'} <span style={{ fontSize: '11px', color: "#cecece" }}>+ IVA</span></Typography.Title>
                <Typography.Text type='secondary'>facturado {billing === 'monthly' ? 'mensualmente' : 'anualmente'}</Typography.Text>

                <Button loading={loading === startup} onClick={() => ManageSuscription({ price: startup })} type="primary" style={{ width: '100%', marginTop: '30px' }}>{billingAccountTrial ? 'Suscríbete ahora' : 'Prueba 15 días'}</Button>
                <Typography.Title style={{ marginTop: '15px', marginBottom: 0, paddingBottom: 0, width: '100%', textAlign: 'start' }} level={4}></Typography.Title>
                <CheckedItem text="500 eventos al mes (automatización)" strong={true} />
                <CheckedItem text="250 clientes" />
                <CheckedItem text="Portal de pago co-brandeado" />
                <CheckedItem text="Portal de auto-factura co-brandeado" />
                <CheckedItem text="Todas las integraciones incluidas" />
                <CheckedItem text="Ayuda con las integraciones" />
                <UncheckedItem text="Pago por evento extra" />
                <UncheckedItem text="Soporte por Slack" />



            </div>
        </Col>
    }

    const GrowingPlan = () => {
        return <Col xs={24} lg={12} xl={8}>
            <div style={{ padding: '30px', border: `1px solid ${growing === price_id ? 'red' : 'white'}` }} className="d-flex flex-column pricingCard">
                <div> <img alt="Creciendo" src={growingIcon} height="60px" /></div>
                <Typography.Title style={{ marginTop: '10px' }} level={3}>Growing</Typography.Title>
                <Typography.Text type="secondary" style={{ textAlign: 'center', minHeight: '32.4px' }} className="">Un plan para empresas que están en crecimiento.</Typography.Text>
                <Typography.Title style={{ marginTop: '10px', marginBottom: 0, paddingBottom: 0 }} level={2}>{billing === 'monthly' ? '$199 usd' : '$166 usd'} <span style={{ fontSize: '11px', color: "#cecece" }}>+ IVA</span></Typography.Title>
                <Typography.Text type='secondary'>facturado {billing === 'monthly' ? 'mensualmente' : 'anualmente'}</Typography.Text>
                <Button loading={loading === growing} onClick={() => ManageSuscription({ price: growing })} style={{ width: '100%', marginTop: '30px' }}>{billingAccountTrial ? 'Suscríbete ahora' : 'Prueba 15 días'}</Button>
                <Typography.Title style={{ marginTop: '15px', marginBottom: 0, paddingBottom: 0, width: '100%', textAlign: 'start' }} level={4}></Typography.Title>
                <CheckedItem text="1000 eventos al mes (automatización)" strong={true} />
                <CheckedItem text="Clientes ilimitados" />
                <CheckedItem text="Portal de auto-factura co-brandeado" />
                <CheckedItem text="Portal de pago co-brandeado" />
                <CheckedItem text="Todas las integraciones incluidas" />
                <CheckedItem text="Ayuda con las integraciones" />
                <CheckedItem text="Ayuda técnica con las integraciones" />
                <CheckedItem text="Acceso a la API de Gigstack" />
                <CheckedItem text="Pago por evento extra" />
                <CheckedItem text="Soporte por Slack" />


            </div>
        </Col>
    }


    /**
     * It returns a column with a custom plan if the customPriceId is defined, otherwise it returns an
     * empty component
     * @returns A component
     */
    const CustomPlan = () => {
        if (customPriceId) {
            return <Col xs={24} lg={18} style={{ marginBottom: '20px' }}>
                <div className='d-flex flex-column' style={{ padding: '20px 30px', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', border: '2px solid #7247FE' }}>
                    <Typography.Title style={{ marginTop: '10px' }} level={3}>Plan Personalizado</Typography.Title>
                    <Typography.Text type="secondary" style={{ textAlign: 'center', minHeight: '32.4px' }} className="">Hemos creado un plan personalizado perfecto para ti.</Typography.Text>
                    <Button type="primary" loading={loading === customPriceId} onClick={() => ManageSuscription({ price: customPriceId })}>Contratar plan</Button>
                </div>
            </Col>
        } else return <></>
    }

    /**
     * It returns a div with a text inside it, if the coupon exists and the billing is not anual
     * @returns A function that returns a div with a text inside.
     */
    const RenderCoupon = () => {
        if (!coupon || billing === 'anual') {
            return <></>
        }
        const { percent_off, name, amount_off, currency } = coupon;
        return <div className="pill">
            <Typography.Text type="secondary">Promoción: <span className="primary strong">{name} {amount_off ? '$' : ''}{percent_off ?? (amount_off / 100)}{percent_off ? '%' : currency} de descuento.</span></Typography.Text>
        </div>
    }

    return (
        <Layout>
            <Layout.Header style={{ backgroundColor: 'transparent' }}>
                <Row justify="space-between" style={{ margin: 0, height: '100%' }} align="middle">
                    <p style={{ margin: 0, height: 'auto', fontSize: '17px' }} className="russo primary">gigstack pro</p>
                    {authData ? <div className="pill" onClick={() => {
                        window.location.href = '/'
                    }}>
                        <Typography.Text style={{ marginRight: '10px' }}>Ir a mi Dashboard</Typography.Text>
                        <div className='customCircledTag' style={{ height: '20px', width: '20px' }}   ><Typography.Text className='russo primary' style={{ margin: 0, padding: 0, fontSize: '10px' }}>g</Typography.Text></div>
                    </div> : <div className="pill" onClick={() => {
                        window.location.href = '/signup?redirect=' + window.location.href
                    }}>
                        <Typography.Text style={{
                        }}>Registrarme</Typography.Text>
                    </div>}
                </Row>
            </Layout.Header>
            <Layout.Content style={{ height: window.innerHeight - 74, width: '100%', padding: '20px 15%' }}>
                <Row gutter={{ xs: 10, md: 20 }} justify="center" style={{ paddingBottom: '12px' }}>
                    <CustomPlan />

                    <Col xs={24} style={{ paddingBottom: '12px' }}>
                        <Row justify="center">
                            <div>
                                <RenderCoupon />
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ margin: '20px 20px' }}>
                        <Row justify="center">
                            <Typography.Text style={{ textAlign: 'center', fontSize: '19px' }}><span className='brand primary strong'>Automatiza la administración financiera</span> de tu empresa, asegurándote de que TODO pase en tiempo, forma y sin errores, <span className='brand primary strong'>ahorra hasta 65 horas a la semana</span> .</Typography.Text>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Row justify="center" style={{ padding: '0px 0px 40px 0px' }}>
                            <Typography.Text style={{ marginRight: '10px' }} className="strong">Mensual</Typography.Text>
                            <Switch disabled={hasCoupon} defaultChecked={billing === 'anual'} onChange={(v) => setbilling(v ? 'anual' : 'monthly')} />
                            <Typography.Text style={{ marginLeft: '10px' }} className="strong">Anual | 16% de descuento </Typography.Text>
                        </Row>
                    </Col>
                    {(SearchParamInURL('code') && !coupon) && <Col xs={24} style={{ marginBottom: '10px' }}>
                        <Row justify='center'>
                            <div style={{ border: '1px solid #cecece', borderRadius: '10px', padding: '10px' }}>
                                <Typography.Text> Aplicaremos el cupón <strong>{SearchParamInURL('code')}</strong> al momento de la suscripción</Typography.Text>
                            </div>
                        </Row>
                    </Col>}
                    <TeamPlan />
                    <StartupPlan />
                    <GrowingPlan />
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default MembershipsPage;