import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { useAuth } from 'reactfire';
import { countryLabeledList, tz } from '../../app/functions/datasets';
import { RemoveUndefined } from '../../app/functions/helpers';
import { generalreactSelectStyles } from '../../app/functions/styles';
import UserContext from '../../context/usercontext/UserContext';

function PersonalSettings() {
    const auth = useAuth()
    const [loading, setloading] = useState(false)

    const [form] = Form.useForm();

    const { user: userDocument } = useContext(UserContext)

    useEffect(() => {
        form.setFieldsValue({
            ...userDocument,
            country: userDocument?.country ? { label: userDocument?.country, value: userDocument?.country } : null,
            timezone: userDocument?.timezone ? userDocument?.timezone : null,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])








    const PersonalInfo = () => {
        return <Row style={{ width: '100%' }}>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Información Personal</Typography.Text>

            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Nombre" name="firstName" rules={[{ required: true, message: 'Añade tu nombre' }]} >
                            <Input placeholder="Nombre(s)" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Apellidos" name="lastName" rules={[{ required: true, message: 'Añade tu apellido' }]} >
                            <Input placeholder='Apellido(s)' />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Correo electrónico" name="email" rules={[{ type: "email", required: true, message: 'Añade un correo' }]} >
                            <Input placeholder='Correo electrónico' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="País de residencia" name="country" rules={[{ required: true, message: 'Por favor añade tu país.' }]}>
                            <Select
                                styles={generalreactSelectStyles}
                                className="pro-select-input"
                                options={countryLabeledList}
                                isLoading={false}
                                placeholder="País de residencia.."

                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item name="timezone" label="Zona horaria" style={{ marginBottom: '0px' }}>
                            <Select
                                styles={generalreactSelectStyles}
                                className="pro-select-input"
                                options={tz}
                                placeholder="Selecciona tu zona horaria"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Teléfono celular" name="phone" >
                            <Input placeholder='+52' />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    return (
        <Col xs={24}>
            <Form form={form} initialValues={userDocument} onFinish={async (v) => {
                try {
                    setloading(true);
                    await updateDoc(doc(getFirestore(), `users/${auth.currentUser.uid}`), {
                        ...RemoveUndefined(v),
                        name: `${v?.firstName} ${v.lastName}`,
                        timezone: v?.timezone ?? null,
                        country: v?.country?.label ?? null,
                    })
                    message.success('Actualizado correctamente.')
                    setloading(false);
                } catch (error) {
                    message.error(error.message)
                    setloading(false);
                }
            }} layout='vertical' style={{ width: '100%' }}>
                <div className="settingsContainer">
                    <Typography.Title style={{ marginBottom: '2px' }} level={5}>Información de tu cuenta</Typography.Title>
                    <Typography.Text type="secondary">Por favor añade tu información personal.</Typography.Text>
                    <Divider />
                    <PersonalInfo />
                    <Row style={{ width: '100%' }} justify="center">
                        <Button loading={loading} htmlType="submit">Actualizar información</Button>
                    </Row>

                </div>
            </Form>


        </Col>
    );
}

export default PersonalSettings;