import { Button, message, Modal, Row, Table, Tag, Tooltip, Typography } from 'antd';
import { arrayUnion, collection, doc, getFirestore, orderBy, query, where, writeBatch } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useFirestoreCollectionData } from 'reactfire';
import { getItemsAmounts, paymentIntentsStatusMessage, returnColorByPaymentStatus, returnTextColorByPaymentStatus } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';


function RelatePayments({ open, close, element, userDocument, addOn, elementType }) {
    const { team } = useContext(TeamContext)
    const q = query(collection(getFirestore(), "payments"), where("team", "==", team.id), orderBy('timestamp', 'desc'))
    const { status, data } = useFirestoreCollectionData(q,
        {
            idField: 'id'
        })

    //const [selectedPayments, setselectedPayments] = useState([]);
    const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(false)

    /* A useEffect hook that is being used to set the payments state. */
    useEffect(() => {
        if (status === "success") {

            setPayments(data.map((c) => {
                const as = ((element['payments'] ? element['payments'].findIndex((i) => i === c.id) > -1 : false) || (c[addOn]?.findIndex((p) => p === element.fid) > -1))
                return { ...c, fid: c.id, alreadySelected: as, blocked: as }
            }))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])



    /* A rowSelection object that is being used to set the selectedRowKeys and onChange props of the Table
    component. */
    const rowSelection = {
        selectedRowKeys: payments.filter((c) => c.alreadySelected).map((c) => c.fid),
        onChange: (selectedRowKeys, selectedRows) => {
            payments.map((c) => {
                if (selectedRowKeys.findIndex((i) => i === c.fid) > -1) {
                    c.alreadySelected = true
                } else {
                    c.alreadySelected = false
                }
                return c
            })
            setPayments([...payments])
        },
        getCheckboxProps: (record) => ({
            disabled: record.blocked,
            checked: record.alreadySelected,
            name: record.name,
        }),
    };




    return (
        <Modal visible={open} onCancel={() => close()} footer={null} title={null} width={
            window.innerWidth > 600 ? '50%' : '90%'
        }>
            <div className="d-flex flex-column">
                <Row>
                    <div className="d-flex flex-column">
                        <Typography.Title style={{ margin: 0 }} level={4}>Asociar un pago</Typography.Title>
                        {payments.filter((p) => p.alreadySelected && !p.blocked).length > 0 && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{payments.filter((p) => p.alreadySelected && !p.blocked).length} pagos seleccionados <span onClick={() => {
                            payments.map((c) => {
                                if (!c.blocked) {
                                    c.alreadySelected = false
                                }
                                return c
                            })
                            setPayments([...payments])
                        }} className="clickable danger">{payments.filter((p) => p.alreadySelected).length > 0 ? 'restablecer' : ''}</span></Typography.Text>}
                    </div>
                </Row>
                <Table
                    className="pro-table"
                    pagination={{ hideOnSinglePage: true }}
                    style={{ marginTop: '25px' }}
                    loading={status === "loading"}
                    locale="es"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={[
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Cliente</Typography.Text>,
                            dataIndex: 'client',
                            render: (text, record) => {
                                return <Typography.Text style={{
                                    fontSize: '11px', marginBottom: '0px',
                                    color: record.client?.name === element.client?.name ? '#1890ff' : 'inherit'
                                }}>{record.client?.name ?? 'Sin cliente'}</Typography.Text>
                            },
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Estado</Typography.Text>,
                            dataIndex: 'status',
                            render: (text, record) => <Tooltip title={paymentIntentsStatusMessage(record)}>
                                <Tag className="statusTag" color={returnColorByPaymentStatus(record)} style={{ color: returnTextColorByPaymentStatus(record), borderRadius: '10px', opacity: 0.7 }}>{paymentIntentsStatusMessage(record)}</Tag>
                            </Tooltip>
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Fecha</Typography.Text>,
                            dataIndex: 'timestamp',
                            render: (text, record) => {
                                const betweenDates = moment(record.timestamp).diff(moment(element.timestamp), 'days')

                                return <Typography.Text className={(betweenDates > -31 && betweenDates < 31) ? 'primary' : ''} style={{ fontSize: '11px', marginBottom: '0px' }}>{moment(text).format('dddd D MMM YYYY')}</Typography.Text>
                            },
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Total</Typography.Text>,
                            dataIndex: 'total',
                            render: (text, record) => {
                                const closeAmounts = (getItemsAmounts(record.items).total * 100) === getItemsAmounts(element.items).total || ((getItemsAmounts(record.items).total * 100) / 2) === getItemsAmounts(element.items).total
                                return <Typography.Text className={closeAmounts ? 'primary' : ''} style={{ fontSize: '11px', marginBottom: '0px' }} >{getItemsAmounts(record.items).totalString}</Typography.Text>
                            },
                        },]}
                    rowKey={(record) => record.fid}
                    dataSource={payments}
                />
                <Row justify='end'>
                    <Button onClick={async () => {
                        try {
                            const batch = writeBatch(getFirestore())
                            const ids = payments.filter((p) => p.alreadySelected).map((p) => p.fid)
                            batch.update(doc(getFirestore(), addOn, element.id), { payments: arrayUnion(...ids) })

                            payments.filter((p) => p.alreadySelected)?.map((pay) => {
                                return batch.update(doc(getFirestore(), "payments", pay.fid), { [addOn]: arrayUnion(element.id) })
                            })
                            await batch.commit()
                            message.success('Se han asociado los pagos.')
                            setLoading(false)
                        } catch (error) {
                            message.error(error.message)
                            setLoading(false)
                        }
                        close(null)
                    }} loading={loading} type="primary">
                        Asociar
                    </Button>
                </Row>
            </div>
        </Modal>
    );
}

export default RelatePayments;