import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row, Switch, Typography } from 'antd';
import React, { useContext, useState } from 'react'
import Select from 'react-select';
import { countryLabeledList } from '../../app/functions/datasets';
import { generalreactSelectStyles } from '../../app/functions/styles';
import { clabe } from 'clabe-validator';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import TeamContext from '../../context/teamcontext/TeamContext'
import { useAnalytics, useAuth, useFirestore } from 'reactfire';
import { RemoveUndefined } from '../../app/functions/helpers';



function BankModal({ open, close, userDocument }) {




    const analytics = useAnalytics();
    const { team, getCurrentTeam } = useContext(TeamContext)
    const auth = useAuth();




    const ref = doc(useFirestore(), 'users', auth.currentUser.uid)



    const [state, setstate] = useState({
        country: team?.country,
        ...team?.bank
    })
    const [formRef] = Form.useForm();
    const [loading, setLoading] = useState(false)



    /**
     * It saves the bank information to the user's document in Firestore
     * @param v - The values from the form
     * @returns return 
     */

    const SaveBank = async (v) => {
        var clabeCheck = {}
        if (state.country === 'Mexico') {
            clabeCheck = clabe.validate(v.clabe)

            if (!clabeCheck.ok) {
                return message.error(clabeCheck.message);
            }
            Object.keys(v).forEach(key => v[key] === undefined ? delete v[key] : {});
            Object.keys(clabeCheck).forEach(key => clabeCheck[key] === null ? delete clabeCheck[key] : {});
        }
        try {

            setLoading(true)
            await updateDoc(doc(getFirestore(), 'teams', team.id), {
                bank: {
                    ...RemoveUndefined(v),
                    country: v?.country?.label ?? team?.address?.countryObj?.label,
                    completed: true,
                    ...clabeCheck
                }
            })
            try {
                const integrationsToSetup = (await getDoc(ref))?.data()?.integrationsSetup?.filter(int => int !== "bank") ?? []
                await updateDoc(ref, { integrationsSetup: integrationsToSetup })
            } catch (error) {
            }
            getCurrentTeam(team?.id)
            logEvent(analytics, 'bankSaved', {});
            message.success('Cuenta guardada correctamente')
            close()
        } catch (error) {
            message.error(error.message)

            setLoading(false)
        }

    }

    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4}>Agregar cuenta de banco</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                </Row>
                <Form form={formRef} initialValues={{ holder_name: team?.legalName, ...team?.bank, country: { label: team?.bank?.country ?? team?.address?.countryObj?.label, value: team?.bank?.country ?? team?.address?.countryObj?.value }, }} layout="vertical" onFinish={SaveBank} >
                    <Form.Item name="country" label="País">
                        <Select
                            styles={generalreactSelectStyles}
                            onChange={(v) => {
                                setstate({ ...state, country: v?.label })
                            }}
                            options={countryLabeledList.map((country) => {
                                return { label: country.label, value: country.code }
                            })}
                            isLoading={false}
                            placeholder="País"

                        />
                    </Form.Item>
                    <Form.Item label="Beneficiario" name="holder_name" rules={[{ required: true, message: 'Añade nombre de beneficiario' }]}>
                        <Input className="pro-input" placeholder="" />
                    </Form.Item>
                    <Form.Item label="Banco" name="bank">
                        <Input readOnly className="pro-input" placeholder="Banco de ..." />
                    </Form.Item>
                    <Form.Item label="Cuenta CLABE" name="clabe" rules={[{ required: state.country === 'Mexico', message: 'Añade una cuenta clabe' }, { min: 18, max: 18, message: '18 digitos' }]}>
                        <Input className='pro-input' minLength="18" maxLength="18" onChange={(v) => {
                            const clabeCheck = clabe.validate(v.target.value)
                            if (clabeCheck.ok) {
                                formRef.setFieldsValue({ bank: clabeCheck.tag })
                            } else {
                                formRef.setFieldsValue({ bank: '' })
                            }
                        }} />
                    </Form.Item>
                    <Form.Item label="Código SWIFT" name="swift" help={<Typography.Text type='secondary' style={{ fontSize: '10px' }}>Society for Worldwide Interbank Financial Telecommunication</Typography.Text>}>
                        <Input className='pro-input' />
                    </Form.Item>
                    <Form.Item label="Código IBAN" name="iban" help={<Typography.Text type='secondary' style={{ fontSize: '10px' }}>Society for Worldwide Interbank Financial Telecommunication</Typography.Text>}>
                        <Input className='pro-input' />
                    </Form.Item>
                    <Form.Item label="Código BIC" name="bic" help={<Typography.Text type='secondary' style={{ fontSize: '10px' }}>Bank Identifier Code</Typography.Text>}>
                        <Input className='pro-input' />
                    </Form.Item>
                    <Form.Item label="¿Requerir captura de la transferencia?" name="voucherRequired">
                        <Switch onChange={(v) => setstate({ ...state, voucherRequired: v })} checked={state.voucherRequired} />
                    </Form.Item>

                    <Form.Item label="Instrucciones extra" name="help">
                        <Input.TextArea className="pro-TextArea" />
                    </Form.Item>

                    <Row justify='center'>

                        <Button loading={loading} type="primary" htmlType="submit">Guardar</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default BankModal;