import { Col, Row, Table } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import moment from 'moment';

import React, { Suspense, useContext } from 'react';
import { useFirestoreCollectionData } from 'reactfire';
import TeamContext from '../../context/teamcontext/TeamContext';

import EmptyTable from '../states/EmptyTable';

function DefaultTableItems({ onRow, avoidContainer, userDocument, updateParent, data: forcedData, columns, customFilter, loading, empty = "Aún no tienes clientes", emptyAction, elementName, onPageChange = () => { }, defaultPage = 1, onChange = () => { }, query, filterSearch = "", filterSearchKey = "", filterDataKey, filterBy, pageSize = 10, complexQuery = false, secondQuery, secondQueryKey, isPendingPayments, removeTransactionsDuplicate, byClient, showHidden, byTestMode = true, expandable }) {


    const { team } = useContext(TeamContext)
    const { status: dataStatus, data } = useFirestoreCollectionData(query, {
        //ADD DOCUMENT ID
        idField: 'id',
    })

    const fil = data.filter(c => c[filterSearchKey]?.toString()?.toLowerCase().includes(filterSearchKey?.toLowerCase()))





    const filteredData = data.filter((a) => {
        if (complexQuery) {
            return a?.client?.[filterDataKey]?.toString()?.toLowerCase().indexOf(filterSearch.toLocaleLowerCase()) !== -1 && a?.client?.[secondQueryKey]?.toString()?.toLowerCase().indexOf(secondQuery.toLocaleLowerCase()) < 0
        } else {
            return a?.client?.[filterDataKey]?.toString()?.toLowerCase().indexOf(filterSearch.toLocaleLowerCase()) !== -1
        }

    })
    const filterByType = filteredData.filter((a) => {
        if (filterBy === 'toReview') {
            return a.review_status === 'pending'
        }
        return filterBy ? a?.status === filterBy : true
    })





    var defaultD = (removeTransactionsDuplicate ?
        data.filter((a) => !a.duplicated) :
        (filterSearch && filterSearchKey) ?
            fil :
            isPendingPayments ?
                data.map((payment) => {
                    return {
                        ...payment,
                        status: (payment.status === 'pending' || payment.status === 'requires_confirmation' || payment.status === 'requires_payment_method') ? 'pending' : payment.status

                    }
                }).filter((a) => {
                    return a?.status === 'pending' || a?.status === 'requires_confirmation' || a?.status === 'requires_payment_method'
                }) : filterByType);




    //IF showHidden is true, show all the data IF NOT REMOVE THE HIDDEN DATA FROM DEFAULTD
    var defaultData = showHidden ? defaultD : defaultD.filter((a) => !a.hidden)
    if (team?.defaults?.allowPaymentsSetting) {

        if (filterBy === 'past') {
            defaultData = data.filter((p) => {
                var limitDate = p?.limitDaysToPay ? moment(p?.timestamp).add(p?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
                return limitDate?.isBefore(moment()) && (p?.status === 'pending' || p?.status === 'requires_confirmation' || p?.status === 'requires_payment_method')
            })
        }
    }
    if (!byTestMode) {
        defaultData = defaultData.filter((a) => a.livemode)
    }
    return (
        <Row style={{ padding: 0, margin: 0, width: '100%' }}>
            <Col xs={24}>
                <div className={avoidContainer ? '' : 'tableContainer'}>
                    <Row style={{ width: '100%' }}>
                        {customFilter && <Col xs={24}>
                            <Row justify='end'>
                                {customFilter}
                            </Row>
                        </Col>}
                        <Col xs={24} style={{ marginTop: customFilter ? '25px' : '0px' }}>
                            <ErrorBoundary>
                                <Suspense>
                                    <Table
                                        expandable={expandable}
                                        onChange={onChange}
                                        onRow={onRow ? onRow : (record, rowIndex) => { }}
                                        rowKey={record => record.id}
                                        loading={dataStatus === 'loading'}
                                        scroll={{ x: 500 }}
                                        columns={columns}
                                        dataSource={byClient ?
                                            defaultData.filter((a) => {
                                                //search by a.client name or a.client email with byClient
                                                return a?.client?.name?.toString()?.toLowerCase().indexOf(byClient.toLocaleLowerCase()) > -1 || a?.client?.email?.toString()?.toLowerCase().indexOf(byClient?.toLocaleLowerCase()) > -1 || a?.id?.toString()?.toLowerCase().indexOf(byClient.toLocaleLowerCase()) > -1
                                            })
                                            : defaultData}
                                        bordered={false}
                                        showSorterTooltip={false}
                                        pagination={{
                                            pageSize: pageSize ?? 10,
                                            hideOnSinglePage: true,
                                            onChange: (p) => onPageChange(p),
                                            pageSizeOptions: [10, 20, 50, 100],
                                            defaultCurrent: defaultPage,
                                            defaultPageSize: pageSize ?? 10
                                        }}
                                        className='pro-table'

                                        locale={{
                                            emptyText: <EmptyTable customMessage={empty} parentAction={emptyAction} elementName={elementName} />,
                                            filterCheckall: 'Todos',
                                        }}


                                    />
                                </Suspense>
                            </ErrorBoundary>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default DefaultTableItems;