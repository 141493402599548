import { EditOutlined, InfoCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Row, Switch, Tooltip, Typography } from 'antd';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { ClientReadyForInvoice, FeesAmounts, getItemsAmounts, taxRegimes } from '../../app/functions/helpers';
import SelectClientRowCard from '../clients/SelectClientRowCard';
import ServicesViewTable from '../services/ServicesViewTable';
import Select from 'react-select'
import { generalreactSelectStyles } from '../../app/functions/styles';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { UseUserDefaults } from '../../customHooks/useUserDefaults';
import PaymentLimitComponent from '../payments/PaymentLimitComponent';
import TeamContext from '../../context/teamcontext/TeamContext';


function ClientsAndItemsSelect({ withAutomation, withAddFees, state = { items: [] }, changeState, userDocument, forSat, child, hidePaymentSettings = false }) {
    const { team } = useContext(TeamContext)
    const [localState, setlocalState] = useState({ viewSAT: false })
    const [loading, setloading] = useState(false)
    const { currency, items } = state;
    const [form] = Form.useForm();
    const { defaults } = UseUserDefaults({ userDocument, team })
    /**
     * It takes the subtotal of each item, adds the fees to it, and then adds the total to the item
     */

    // const AddFeesToTotal = () => {
    //     var feeAdded = 0
    //     items?.forEach((item, index) => {
    //         const sub = parseFloat(item.total)
    //         const { fee, feeA } = FeesAmounts(userDocument, team)
    //         const feeTotal = ((sub + feeA) / (1 - (fee / 100)) - sub) * 1.16
    //         const total = Number(parseFloat(sub + feeTotal).toFixed(2))
    //         const remaining = total - sub
    //         feeAdded += remaining
    //         items[index].total = total
    //         items[index].feeAdded = remaining
    //         items[index].feeInfo = FeesAmounts(userDocument, team)
    //         items[index].master = sub
    //     })

    //     changeState({ ...state, feeAdded, items: [...items] })
    // }
    // /**
    //  * It removes the fee from the total of each item in the cart
    //  */
    // const RemoveFeesFromTotal = () => {

    //     items?.forEach((item, index) => {
    //         items[index].total = item.total - (item.feeAdded ?? 0)
    //         items[index].feeAdded = null
    //         items[index].feeInfo = null
    //     })

    //     changeState({ ...state, feeAdded: null, items: [...items] })
    //     message.success('Hemos actualizado los servicios')
    // }


    useEffect(() => {
        if (state.client) {
            if (!state.client?.rfc || !state?.client.legal_name || !state?.client.address || !state?.client.address.zip || !state?.client.tax_system) {
                setlocalState({ ...localState, viewSAT: true })
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    //CLIENT AND ITEMS
    /**
     * It takes in a type, action, and ref, and then either adds or removes an automation from the state
     */
    const manageAutomation = async ({ type, action, ref }) => {
        const index = (state.automations || []).findIndex((e) => e.type === type);

        var tempAutom = state.automations || []
        if (index > -1) {
            tempAutom.splice(index, 1)
        } else {
            tempAutom.push({ type, action, ref, from: 'payments' })
        }

        changeState({ ...state, automations: tempAutom })

    }
    /**
     * It's a function that returns a div with a text and a switch. The text is a question that asks the
     * user if he wants to add the fees to the total amount of the order. The switch is a toggle that
     * allows the user to add or remove the fees from the total amount of the order
     * @returns A div with a row and a switch.
     */
    const AddFees = () => {
        return <div className='d-flex flex-column' style={{ backgroundColor: '#F5F5F5', padding: '15px', borderRadius: '10px', marginTop: '15px' }}>
            {(FeesAmounts(userDocument, team).type) && <Typography.Text type="secondary" style={{ fontSize: '12px' }}>¿Añadir la comisión de transacciones de tu orden? <Tooltip title="Algunas pasarelas de pago tienen fee's por transacción, esto es externo a gigstack.pro"><QuestionCircleOutlined /></Tooltip></Typography.Text>}
            {(FeesAmounts(userDocument, team).type) &&
                <Row style={{ marginTop: '10px', marginBottom: '15px' }} align="middle">
                    <Switch checked={state.addTransactionFee} onChange={(v) => {
                        changeState({ ...state, addTransactionFee: v })
                    }} />
                    <Row style={{ flex: 1, borderRadius: '10px', border: '0.5px solid #DDDDDD', padding: '5px 10px', marginLeft: '10px' }} className="" align="middle">
                        <InfoCircleOutlined style={{ color: '#757575' }} />
                        <Typography.Text style={{ marginLeft: '5px', color: '#757575', flex: 1 }}>{state.feeAdded ? `Hemos añadido un total de  ${getItemsAmounts(items).feeAddedString} a tu solicitud de pago.` : `Añade el costo de la pasarela de mayor costo (${FeesAmounts(userDocument, team).type}) a tus servicios `}</Typography.Text>
                    </Row>
                </Row>
            }
            {state?.client?.metadata?.clabe && (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography.Text className="cLabel" style={{ marginBottom: '10px' }}>
                    Deseas añadir la Clabe interbancaria numero {state?.client?.metadata?.clabe} para que el pago se reciba en esa cuenta?{" "}
                    <Tooltip title=""></Tooltip>
                </Typography.Text>
                <Switch
                    style={{ marginBottom: '10px' }}
                    checked={state.clabe}
                    onChange={(v) => {
                        console.log("State: ", state);
                        console.log("CLABE: ", state?.client?.metadata?.clabe && (state?.client?.metadata?.clabe === state?.replicateElement?.clabe));
                        changeState({ ...state, clabe: v })
                    }}
                />
                {state.clabe && (
                    <Typography.Text
                        type="secondary"
                        className="clickable itemHoverPrimary"
                        style={{ fontSize: "12px" }}
                    >
                        *El pago se recibirá en la cuenta {state?.client?.metadata?.clabe}
                    </Typography.Text>
                )}
            </div>)}

        </div>
    }
    /**
     * It creates a checkbox that allows the user to create an invoice when the payment is complete.
     * @returns A div with a text and a checkbox.
     */
    const AutomationAdd = () => {
        return <div className="d-flex flex-column" style={{ backgroundColor: '#F5F5F5', padding: '15px', borderRadius: '10px', marginTop: '15px' }}>
            <Typography.Text type="secondary" style={{ fontSize: '12px', }}>Automatizaciones disponibles <Tooltip title="Nuestra meta es que tu te enfoques en crecer mientras gigstack.pro se enfoca en todo lo demás."><QuestionCircleOutlined /></Tooltip></Typography.Text>
            <Checkbox checked={(state.automations || []).length > 0} disabled={!getItemsAmounts(items, true).canSend} style={{ marginTop: '10px' }} onChange={(v) => manageAutomation({ type: 'invoice', action: 'create', ref: 'payments' })}>
                <div className="d-flex flex-column">
                    <Typography.Text type="secondary" className='strong' style={{ fontSize: '13px' }}>Crear una factura cuando el pago esté completo</Typography.Text>
                    {(!getItemsAmounts(items, true).canSend) && <Typography.Text type="secondary " className="clickable itemHoverPrimary" style={{ fontSize: '12px' }}>*Revisa que los servicios/productos tengan los elementos para emitir una factura (clave de producto e impuestos). </Typography.Text>}
                    {!state.client && <Typography.Text type="secondary" className="clickable itemHoverPrimary" style={{ fontSize: '12px' }}>Tu solicitud no tiene un cliente, generaremos un recibo de venta que se convertirá en una factura al público en general el día que se complete a las 11:59 pm.</Typography.Text>}
                </div>
            </Checkbox>
        </div>
    }


    /**
     * It's a function that returns a form that updates the client's information
     * @returns A form with the client information
     */


    var taxSys = typeof state.client?.tax_system === 'object' ? state.client?.tax_system : taxRegimes.find((a) => a.value === state.client?.tax_system)
    // console.log(taxSys);
    const SATClient = () => {
        return <Form form={form}
            onFinish={async (v) => {
                try {
                    setloading(true)



                    await updateDoc(doc(getFirestore(), 'clients', state.client?.id), {
                        ...v,
                        rfc: v.rfc.toUpperCase(),
                        legalName: v.legal_name?.toUpperCase(),
                        tax_id: v.rfc.toUpperCase(),
                        address: {
                            ...(state.client?.address ?? {}),
                            zip: v.zip,

                        }
                    })
                    changeState({
                        ...state, client: {
                            ...state.client,
                            ...v,
                            address: {
                                ...(state.client?.address ?? {}),
                                zip: v.zip,

                            }
                        }
                    })
                    message.success(`Cliente actualizado`)
                    setloading(false)
                } catch (error) {
                    message.error(error.message ?? 'Error al actualizar el cliente')
                    setloading(false)
                }
            }}
            layout="vertical" initialValues={{ ...state.client, zip: (state.client?.address?.zip || state.client?.zip), tax_system: taxSys }} style={{ marginTop: '10px' }}>
            {forSat && <Row gutter={{ xs: 10, md: 10 }}>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Añade el nombre legal' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} name="legal_name" label="Nombre legal">
                        <Input placeholder='Nombre legal' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Añade el RFC' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} name="rfc" label="RFC">
                        <Input placeholder='Registro de contribuyentes' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Añade el código postal' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} name="zip" label="Código Postal">
                        <Input placeholder='Código postal de facturación' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Selecciona el régimen fiscal' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} label="Régimen Fiscal" name="tax_system" >
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"

                            options={taxRegimes}
                            isLoading={false}
                            placeholder="Cuál es el régimen fiscal"

                        />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Row justify="end">
                        <Suspense fallback={<LoadingOutlined />}>
                            <Button loading={loading} type="primary" htmlType="button" onClick={() => {
                                form.submit()
                            }}>Actualizar información del cliente</Button>
                        </Suspense>
                    </Row>
                </Col>
            </Row>}
        </Form>
    }



    return (
        <div style={{ padding: '10px 0px', width: '100%' }}>
            <Typography.Title level={5} style={{ marginTop: '0px' }} >1. Cliente</Typography.Title>
            <Row align="middle">
                <SelectClientRowCard onSelect={(c) => {
                    changeState({ ...state, client: c })

                    if (!c.rfc || !c.legal_name || !c.address || !c.address.zip || !c.tax_system) {
                        setlocalState({ ...localState, viewSAT: true })
                    }
                }} userDocument={userDocument} incomingClient={state.client} />
                {forSat && <EditOutlined className="hoverPrimary clickable" onClick={() => setlocalState({ ...localState, viewSAT: !localState.viewSAT })} style={{ marginLeft: '15px' }} />}
            </Row>
            {((forSat && state.client && localState.viewSAT) || (!ClientReadyForInvoice(state.client) && state.client && forSat)) && <SATClient />}

            <Typography.Title level={5} style={{ marginTop: '30px' }}>2. Productos y servicios</Typography.Title>
            <Suspense fallback={<LoadingOutlined />}>
                <ServicesViewTable forSat={forSat} onSelect={(v) => { }} onItemsChange={(it) => {
                    changeState({ ...state, items: it })
                }} selectedItems={state.items} userDocument={userDocument} currency={currency} />
            </Suspense>
            <Row justify="end">
                <ul style={{ listStyle: 'none' }}>
                    <li><Typography.Text type="secondary">Subtotal: {getItemsAmounts(state.items).subtotalString}</Typography.Text></li>
                    <li><Typography.Text type="secondary">Retenciones: {getItemsAmounts(state.items).retentionsString}</Typography.Text></li>
                    <li><Typography.Text type="secondary">Traslados: {getItemsAmounts(state.items).taxesString}</Typography.Text></li>
                    {/*ADD DISCOUNT */}
                    <li><Typography.Text type="secondary">Descuentos: {getItemsAmounts(state.items).masterDiscountString}</Typography.Text></li>
                    <li><Typography.Text className='strong primary'>Total: {getItemsAmounts(state.items).totalString} {state.currency}</Typography.Text></li>
                </ul>
            </Row>
            {child}
            {(withAddFees || withAutomation) && <Typography.Title level={5} style={{ marginTop: '30px' }}>3. Configura</Typography.Title>}
            {(withAddFees || withAutomation) && <div style={{ marginTop: '20px' }}></div>}
            {(defaults?.allowPaymentsSetting && !hidePaymentSettings) && <PaymentLimitComponent forceDefault={state?.limitDaysToPay ? state?.limitDaysToPay : false} updateState={(v) => changeState({ ...state, ...v })} userDocument={userDocument} />}
            {withAutomation && < AddFees />}
            {withAutomation && <AutomationAdd />}
        </div>
    );
}

export default ClientsAndItemsSelect;