import { Input, Typography } from "antd"
import { useState } from "react";


const EmailsAttachmentsDefaults = ({ onEmailChange, initialEmail }) => {

    const [email, setEmail] = useState(initialEmail || "");
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        onEmailChange(newEmail); // Llamamos a la función proporcionada en la prop con el nuevo valor
    };


    return <>
        <Typography.Title style={{ fontSize: '15px', marginBottom: '15px', marginTop: '15px' }}>Adjunta todos tus correos a un correo predeterminado por default</Typography.Title>
        <Input style={{ marginBottom: '35px' }}
            type="email"
            placeholder="Correo electronico"
            value={email}
            onChange={handleEmailChange}
        />
    </>
}

export default EmailsAttachmentsDefaults