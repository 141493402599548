import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Typography, message } from "antd";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import TeamContext from "../../context/teamcontext/TeamContext";
import moment from 'moment'
import { generateCode } from "../../app/functions/helpers";

const CreateWebhookModal = ({ open, close, webhookCreated }) => {

    const availableEvents = [
        { label: 'payment.created', value: 'payment.created' }, // check
        { label: 'payment.updated', value: 'payment.updated' }, // check
        { label: 'payment.succeeded', value: 'payment.succeeded' }, // check
        { label: 'payment.canceled', value: 'payment.canceled' }, // check
        { label: 'payment.deleted', value: 'payment.deleted' }, // check
        { label: 'payment.upcoming_due_date', value: 'payment.upcoming_due_date' }, // check
        { label: 'invoice.created', value: 'invoice.created' },  // check
        { label: 'invoice.canceled', value: 'invoice.canceled' }, // check
        { label: 'invoice.failed', value: 'invoice.failed' }, // check
        { label: 'receipt.created', value: 'receipt.created' }, // check
        { label: 'receipt.updated', value: 'receipt.updated' }, // check
        { label: 'receipt.completed', value: 'receipt.completed' }, // check
        { label: 'receipt.deleted', value: 'receipt.deleted' }, // check
        { label: 'client.created', value: 'customer.created' }, // check
        { label: 'client.updated', value: 'customer.updated' }, // check
        { label: 'client.deleted', value: 'customer.deleted' }, // check
    ]
    const [eventsSelected, setEventsSelected] = useState([])
    const [loading, setloading] = useState(false)

    const { team, getCurrentTeam } = useContext(TeamContext);

    const handleCreateWebhook = async (values) => {
        try {
            setloading(true)
            const webhook = {
                id: generateCode(5, 'wh'),
                events: eventsSelected,
                description: values?.description ?? null,
                url: values.enpoint,
                status: 'active',
                created: moment().valueOf(),
            }
            await updateDoc(doc(getFirestore(), 'teams', team.id), {
                webhooks: [
                    ...(team?.webhooks ?? []),
                    webhook
                ]
            })
            message.success('Webhook creado correctamente.')
            setloading(false)
            getCurrentTeam(team?.id)
            close()
        } catch (error) {
            message.error(error?.message ?? 'Ocurrió un error al crear el webhook.')
            setloading(false)
        }
    }


    return <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "40%"} style={{ top: 20 }} >
        <Row justify="space-between">
            <Typography.Title level={4}>Crea un Webhook</Typography.Title>
            <CloseOutlined className="clickable" onClick={() => close()} />
        </Row>
        <Typography.Text type="secondary" >Los webhooks te permiten recibir notificaciones de eventos en tiempo real.</Typography.Text>
        <Form layout='vertical' onFinish={handleCreateWebhook}>
            <Col xs={24}>
                <Form.Item label="URL" name="enpoint" style={{ marginTop: '20px' }} rules={[{ required: true, message: 'La URL es requerida' }, {
                    validator: (rule, value) => {
                        const pattern = /^(https?|ftp):\/\/([^\s/$.?#].[^\s]*)$/i
                        if (pattern.test(value) === false) {
                            return Promise.reject('Debes agregar una url válida')
                        } else {
                            return Promise.resolve()
                        }
                    }
                }]}>
                    <Input className="pro-input" placeholder="URL del punto de conexión" />
                </Form.Item>
                <Form.Item label="Descripción" name="description" style={{ marginTop: '20px' }}>
                    <Input className="pro-input" placeholder="Descripción" />
                </Form.Item>
                <Form.Item label='Eventos' name='events' rules={[{
                    validator: () => {
                        if (eventsSelected.length === 0) {
                            return Promise.reject('Debes seleccionar al menos un evento')
                        } else {
                            return Promise.resolve()
                        }
                    }
                }]}>
                    <Select mode="multiple" defaultValue={eventsSelected} options={availableEvents} onChange={(evts) => setEventsSelected(evts)} />
                </Form.Item>
                <Row justify="center" style={{ width: '100%' }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Crear webhook
                    </Button>
                </Row>
            </Col>
        </Form>
    </Modal>
}

export default CreateWebhookModal;