import { CloseOutlined, InfoCircleFilled, LoadingOutlined, QuestionCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Modal, notification, Row, Tabs, Typography } from 'antd';
import { collection, deleteDoc, doc, getFirestore, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { RemoveUndefined, taxRegimes } from '../../app/functions/helpers';
import { generalreactSelectStyles } from '../../app/functions/styles';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import EmptyTable from '../states/EmptyTable';
import { useAnalytics, useAuth, useFirestore, useFirestoreDocData } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import FileUploader from '../uploads/FileUploader';
import moment from 'moment';
import { logEvent } from 'firebase/analytics';
import TeamContext from '../../context/teamcontext/TeamContext';
import { deleteObject } from 'firebase/storage';
import HelperCard from '../cards/HelperCard';
import facturapiLogo from '../../assets/images/facturapi.png';
import infoGrad from '../../assets/images/information-grad.png';

const APIK = 'AIzaSyA9JDop22AY_lamrn2dKtwhC31LfzWHE5I';

/**
     * `DefaultsForm` is a function that returns a `<>` tag with a `<Divider>` tag, a `<Typography.Text>`
     * tag, a `<Form.Item>` tag, an `<Input>` tag, a `<Form.Item>` tag, and an `<Input>` tag
     * @returns A function that returns a JSX element.
     */
export const DefaultsForm = () => {
    return <>
        <Divider><Typography.Text style={{ marginBottom: '0px', fontSize: '14px' }} type="secondary">Predeterminados</Typography.Text></Divider>
        <Form.Item id="invoice_folio" label="Siguiente folio a utilizar en ingresos" name="invoice_folio" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Si emitiste una factura antes, añade el último folio que utilizaste, nosotros continuaremos a partir de ese</Typography.Text>
        </Row>}>
            <Input className="pro-input" type="number" step="1" min="1" />
        </Form.Item>
        <Form.Item id="invoice_folio_test" label="Siguiente folio a utilizar en ingresos modo TEST" name="invoice_folio_test" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Utilizaremos este folio solo para las facturas en modo prueba o prefacturas</Typography.Text>
        </Row>}>
            <Input className="pro-input" type="number" step="1" min="1" />
        </Form.Item>
        <Form.Item label="Siguiente folio a utilizar en complementos de pago" name="invoice_folio_payments" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Utilizaremos este valor para los complementos de pago.</Typography.Text>
        </Row>}>
            <Input className="pro-input" type="number" step="1" min="1" />
        </Form.Item>
        <Form.Item label="Siguiente folio a utilizar en facturas de egreso" name="invoice_folio_egress" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Utilizaremos este valor para los complementos de egreso.</Typography.Text>
        </Row>}>
            <Input className="pro-input" type="number" step="1" min="1" />
        </Form.Item>
        <Form.Item label="Serie por defecto" name="invoice_serie" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Ayudará a identificar tus facturas</Typography.Text>
        </Row>}>
            <Input className="pro-input" />
        </Form.Item>
        <Form.Item label="Serie de factura de complemento de pago" name="invoice_serie_payments" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Ayudará a identificar tus facturas de pago</Typography.Text>
        </Row>}>
            <Input className="pro-input" />
        </Form.Item>
        <Form.Item label="Serie de factura de egreso" name="invoice_serie_egress" help={<Row align="middle">
            <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
            <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Ayudará a identificar tus facturas de egreso</Typography.Text>
        </Row>}>
            <Input className="pro-input" />
        </Form.Item>
    </>

}

function FacturapiModal({ open, close, userDocument: incomingUser, edit }) {

    const { team, getCurrentTeam } = useContext(TeamContext)


    const analytics = useAnalytics();
    Geocode.setApiKey(APIK);
    const [step, setStep] = useState(0)
    const [loading, setloading] = useState(false)
    const [adLoading, setadLoading] = useState(false)
    const [existingFilesKey, setexistingFilesKey] = useState([])
    const [existingFiles, setexistingFiles] = useState([])
    const [filesLoadedKey, setfilesLoadedKey] = useState(false)
    const [filesLoaded, setfilesLoaded] = useState(false)
    const [error, seterror] = useState(null)
    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();
    // const [updateStamps, setUpdateStamps] = useState(false)
    const [facturapiData, setFacturapiData] = useState(null)



    const userRef = doc(useFirestore(), 'teams', team?.id);

    //const [userDocument, setUserDocument] = useState(incomingUser)

    const { status, data: userDocument } = useFirestoreDocData(userRef);


    const auth = useAuth()


    useEffect(() => {
        const col = collection(fs, 'teams', team.id, 'privateFiles')
        onSnapshot(col, (files) => {

            //const fileCer = 
            const fileKey = files.docs.forEach(s => {
                const archivoKey = ((s.data().name).split(".").slice(-1))[0].toUpperCase()
                if (archivoKey === "KEY") {
                    return {
                        ...s.data(), id: s.id, forFilePond: {
                            source: s.data().path,
                            options: {
                                type: 'limbo',
                                file: {
                                    name: s.data().name,
                                    size: s.data().size,

                                },
                            }
                        }
                    }
                } else {
                    return null
                }
            })

            const fileCer = files.docs.forEach(s => {
                const archivoCer = ((s.data().name).split(".").slice(-1))[0].toUpperCase()
                if (archivoCer === "CER") {
                    return {
                        ...s.data(), id: s.id, forFilePond: {
                            source: s.data().path,
                            options: {
                                type: 'limbo',
                                file: {
                                    name: s.data().name,
                                    size: s.data().size,

                                },
                            }
                        }
                    }
                } else {
                    return null
                }
            })
            setfilesLoadedKey(fileKey)
            setfilesLoaded(fileCer)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const getFtpinfo = async () => {

            try {
                if (team?.defaults?.useFtpiFolio) {
                    //DISABLE invoice_folio and invoice_folio_test until loads from id
                    window.document.getElementById('invoice_folio').disabled = true
                    window.document.getElementById('invoice_folio_test').disabled = true

                }
                const endpoint = 'facturapiAppHandlerInfo/data'
                const resp = await SignedInternalAPIRequest({

                }, endpoint, auth.currentUser, {}, 'GET')
                console.log(resp);
                setFacturapiData(resp)
                formEdit.setFieldValue('invoice_folio', resp?.customization?.next_folio_number)
                formEdit.setFieldValue('invoice_folio_test', resp?.customization?.next_folio_number_test)
                window.document.getElementById('invoice_folio').disabled = true
                window.document.getElementById('invoice_folio_test').disabled = true
            } catch (error) {

                console.log(error);
            }
        }
        if (!facturapiData && team?.defaults?.useFtpiFolio) {
            getFtpinfo()
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const fs = getFirestore()


    /**
     * It takes an address as a parameter, and returns an object with the address components
     * @returns {
     *         city,
     *         state,
     *         country,
     *         zip,
     *         exterior,
     *         street
     */
    const Geo = async ({ address }) => {
        try {
            setadLoading(true)
            const response = await Geocode.fromAddress(address)
            let city, state, country, zip, exterior, street, municipality, neighborhood;
            for (let i = 0; i < response.results[0].address_components.length; i++) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                    switch (response.results[0].address_components[i].types[j]) {
                        case "locality":
                            city = response.results[0].address_components[i].long_name;
                            break;
                        case "sublocality_level_1":
                            municipality = response.results[0].address_components[i].long_name;
                            break;
                        case "administrative_area_level_1":
                            state = response.results[0].address_components[i].long_name;
                            break;
                        case "country":
                            country = response.results[0].address_components[i].long_name;
                            break;
                        case "postal_code":
                            zip = response.results[0].address_components[i].long_name;
                            break;
                        case "street_number":
                            exterior = response.results[0].address_components[i].long_name;
                            break;
                        case "route":
                            street = response.results[0].address_components[i].long_name;
                            break;
                        default: break;

                    }
                }
            }
            form.setFieldsValue({
                city,
                state,
                country,
                zip,
                exterior,
                street,
                municipality,
                neighborhood: neighborhood ?? '',

            })
            setadLoading(false)
            return {
                city,
                state,
                country,
                zip,
                exterior,
                street

            }
        } catch (error) {
        }
    }


    const ToRender = () => {

        if (status === 'loading' || status === 'error') {
            return <LoadingOutlined />
        }

        switch (step) {
            case 0:
                return <div className="d-flex flex-column">
                    <Typography.Text type="secondary">Añade tu información para completar tu perfil de facturación</Typography.Text>
                    <HelperCard openIntercom={true} title={"¿Necesitas ayuda?"} description="Nuestro equipo estará encantado de ayudarte a conectarte." supportImageUrl={facturapiLogo} />
                    <Row gutter={{ xs: 10, md: 12, lg: 15 }} style={{ marginTop: '25px' }}>
                        <Col xs={24} lg={12}>
                            <Form.Item name="legal_name" label="Nombre legal" help={<Typography.Text style={{ fontSize: '10px' }} type='secondary'>Sin acentos ni régimen societario (SA de CV, SAPI, etc.)</Typography.Text>} rules={[{ required: true, message: 'Por favor añade el nombre legal' }]}>
                                <Input placeholder="Nombre legal" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item name="rfc" label="RFC" help={<Typography.Text style={{ fontSize: '10px' }} type='secondary'>RFC con homo clave</Typography.Text>} rules={[{ required: true, message: 'Por favor añade el RFC' }]}>
                                <Input placeholder="ej: DLE180323RU9" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item name="tax_system_m" help={<Typography.Text style={{ fontSize: '10px' }} type='secondary'>Puedes encontrarlo en tu constancia de situación fiscal</Typography.Text>} label="Régimen fiscal" rules={[{ required: true, message: 'Por favor añade el régimen fiscal' }]}>
                                <Select
                                    styles={generalreactSelectStyles}
                                    options={taxRegimes}
                                    isLoading={false}
                                    placeholder="Cuál es tu régimen fiscal"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ marginTop: '20pmx' }}>
                            <Row justify='center'>
                                <Button loading={loading} type="primary" onClick={() => {

                                    form.submit()
                                }}>Siguiente</Button>
                            </Row>
                        </Col>
                    </Row>


                </div>
            case 1:
                return <div className="d-flex flex-column">
                    <Typography.Text type="secondary" style={{ marginBottom: '15px' }}>Añade la dirección fiscal, tal como aparece en tu constancia de situación fiscal</Typography.Text>
                    <GooglePlacesAutocomplete
                        apiKey={APIK}
                        onLoadFailed={(error) => { message.error('Ocurrió un error.') }}
                        apiOptions={{ language: 'es', region: 'mx' }}
                        minLengthAutocomplete={10}

                        selectProps={{
                            styles: generalreactSelectStyles, placeholder: 'Buscar dirección', onChange: (e) => {

                                Geo({ address: e.label })
                            },
                            noOptionsMessage: () => {
                                return <EmptyTable customMessage="No hemos encontrado resultados" parentAction={null} elementType="dirección" />
                            },
                            loadingMessage: () => {
                                return 'Buscando...'
                            },


                        }}
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ['mx'],
                            }
                        }}
                    />
                    {adLoading ? <Row justify="center">
                        <LoadingOutlined />
                    </Row> : <Row gutter={{ xs: 0, lg: 12 }} style={{ marginTop: '15px' }}>
                        <Col xs={24}>
                            <Form.Item label="Nombre de la calle" name="street" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="# Exterior" name="exterior" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="# Interior" name="interior" >
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={16}>
                            <Form.Item label="Colonia" name="neighborhood" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>

                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item label="Código postal" name="zip" rules={[{ required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item label="Municipio / Delegación" name="municipality" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Ciudad" name="city" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Estado" name="state" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                                <Input className="pro-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Row justify='center'>
                                <Button loading={loading} type="primary" onClick={() => {

                                    form.submit()
                                }}>Siguiente</Button>
                            </Row>
                        </Col>
                    </Row>}

                </div>
            case 2:
                return <div className="d-flex flex-column">


                    <HelperCard supportImageUrl={infoGrad} squareImg={true} title='Dudas sobre tu CSD o ¿cómo obtenerlos?' description='El Sello de Certificado Digital (CSD) es un archivo que contiene la información de tu empresa y que te permite firmar electrónicamente tus documentos fiscales. Es un requisito obligatorio para poder emitir facturas electrónicas. Haz click aquí para leer una guía de como obtenerlos' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/6902738-genera-tu-csd-certificado-de-sello-digital', '_blank')} />

                    <div style={{ marginTop: '25px' }}></div>
                    <Typography.Text className='cLabel labelRequired'>Añade los certificados de tu sello digital</Typography.Text>
                    <div style={{ marginTop: '10px' }}></div>
                    <FileUploader
                        title=' <p style="margin-bottom:0px; font-size:13px;" class="strong">Selecciona el archivo .KEY de tus sellos CSD</p>'
                        descriptions='<p style="font-size:12px;">Puedes arrastrar tu archivo .KEY o <span class="strong"">buscarlo</span> en tus archivos.<br/></p>'
                        maxFiles={1}
                        setStep={setStep} step={step}
                        defaultFilesKey={filesLoadedKey ? existingFilesKey?.map((f) => f.forFilePondKey) : []}
                        storageReference={`teams/${team.id}/privateFiles`}
                        dbref={`teams/${team.id}/privateFiles`}
                        setexistingFilesKey={setexistingFilesKey}
                        toDelete={async (ref) => {
                            const index = existingFilesKey.findIndex((file) => file.path === ref)
                            await deleteDoc(doc(fs, `${existingFilesKey[index].dbref}/${existingFilesKey[index].code}`))
                            try {
                                await deleteObject(existingFilesKey[index].path)
                            } catch (e) { }
                            message.success('Archivo eliminado')
                        }}
                        onUpload={async (file) => {
                            try {
                                await setDoc(doc(fs, `${file.dbref}/${file.name.includes('key') ? 'csdkey' : 'csdcert'}`), {
                                    ...file,
                                    invoicingKey: true,
                                    code: file.name.includes('key') ? 'csdkey' : 'csdcert',
                                    type: file.name.includes('key') ? 'key' : 'cert',
                                })
                                logEvent(analytics, 'invoicingCertUploaded', { type: file.name.includes('key') ? 'key' : 'cert', });
                            } catch (error) {
                            }

                        }}
                        acceptedFileTypes={[]}
                        existingFilesKey={existingFilesKey} />

                    <FileUploader
                        title=' <p style="margin-bottom:0px; font-size:13px;" class="strong">Selecciona el archivo .CER de tus sellos CSD</p>'
                        descriptions='<p style="font-size:12px;">Puedes arrastrar tu archivo .CER o <span class="strong"">buscarlo</span> en tus archivos.<br/></p>'
                        maxFiles={1}
                        setStep={setStep} step={step}
                        defaultFiles={filesLoaded ? existingFiles?.map((f) => f.forFilePond) : []}
                        storageReference={`teams/${team.id}/privateFiles`}
                        dbref={`teams/${team.id}/privateFiles`}
                        setexistingFiles={setexistingFiles}
                        toDelete={async (ref) => {
                            const index = existingFiles.findIndex((file) => file.path === ref)
                            await deleteDoc(doc(fs, `${existingFiles[index].dbref}/${existingFiles[index].code}`))
                            try {
                                await deleteObject(existingFiles[index].path)
                            } catch (e) { }
                            message.success('Archivo eliminado')
                        }}
                        onUpload={async (file) => {
                            try {
                                await setDoc(doc(fs, `${file?.dbref}/${file.name.includes('cer') ? 'csdcert' : 'csdkey'}`), {
                                    ...file,
                                    invoicingKey: true,
                                    code: file.name.includes('cer') ? 'csdcert' : 'csdkey',
                                    type: file.name.includes('cer') ? 'cert' : 'key',
                                })
                                logEvent(analytics, 'invoicingCertUploaded', { type: file.name.includes('cer') ? 'cert' : 'key' });
                            } catch (error) {
                            }

                        }}
                        acceptedFileTypes={[]}
                        existingFiles={existingFiles} />

                    <Row gutter={{ xs: 10, md: 12, lg: 14 }}>
                        <Col xs={24} lg={12}>
                            <Form.Item name="rfc" label="RFC" rules={[{ required: true, message: 'Por favor añade el RFC' }]}>
                                <Input placeholder="ej: DLE180323RU9" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Contraseña del certificado"
                                help={<Typography.Text type='secondary' style={{ fontSize: '10px' }}></Typography.Text>}
                                className="" name="password" rules={[{ required: true, message: 'Por favor añade tu contraseña' }]}>
                                <Input.Password className="pro-input" />
                            </Form.Item>

                        </Col>
                    </Row>




                    {error && <Row justify='center'>
                        <div className="d-flex flex-column" style={{
                            alignItems: 'start',
                            justifyContent: 'start',
                            border: '1px solid #f7f7f7',
                            borderRadius: '10px',
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#fafbfd',
                        }}>
                            <Typography.Title level={5} style={{ fontSize: '12px' }} type="">Ocurrió un error al conectar la cuenta</Typography.Title>
                            <Typography.Text type="danger" style={{ fontSize: '12px' }}>Error: {error}</Typography.Text>
                        </div>
                    </Row>}

                    <Row justify='center' style={{ marginTop: '20px' }}>
                        <Button loading={loading} type="primary" onClick={() => {

                            form.submit()
                        }}>Conectar mi cuenta</Button>
                    </Row>
                </div>



            default:
                return <></>

        }
    }



    /**
     * It updates the user's data in the database
     * @param vals - The values of the form
     */
    const UpdateTeam = async (vals) => {
        // if ({ ...userDocument, ...vals, invoice_folio: parseInt(vals.invoice_folio, 10), invoice_serie: vals.invoice_serie, tax_system: vals.tax_system_m.value } === userDocument) {
        //     message.info('No se ha detectado ningún cambio')
        //     return
        // }
        setloading(true)
        const upd = {
            ...vals,
        }
        console.log(upd);
        if (vals.zip) {
            upd.address = {
                exterior: vals.exterior ?? '',
                interior: vals.interior ?? '',
                zip: vals.zip ?? '',
                neighborhood: vals.neighborhood ?? '',
                city: vals.city ?? '',
                municipality: vals.municipality ?? '',
                state: vals.state ?? '',
                country: 'México',
                street: vals.street ?? '',
            }
        }

        delete upd.exterior
        delete upd.interior
        delete upd.zip
        delete upd.neighborhood
        delete upd.city
        delete upd.municipality
        delete upd.state
        delete upd.street

        if (team?.defaults?.useFtpiFolio) {
            delete upd.invoice_folio
            delete upd.invoice_folio_test
        } else {
            upd.invoice_folio = parseInt((vals.invoice_folio ?? 2) - 1)
            upd.invoice_folio_test = parseInt((vals.invoice_folio_test ?? 2) - 1)
        }






        // logEvent(useAnalytics(), 'updateInvoicing', {});
        try {
            await updateDoc(doc(fs, `/teams/${team.id}`), {
                ...RemoveUndefined(upd),
                legalName: vals?.legal_name?.toUpperCase(),
                legal_name: vals.legal_name?.toUpperCase(),
                invoice_serie: vals?.invoice_serie,
                tax_system: vals?.tax_system_m?.value,
                tax_system_m: vals?.tax_system_m,
                invoice_folio_payments: parseInt((vals.invoice_folio_payments ?? 2) - 1),
                invoice_serie_payments: vals?.invoice_serie_payments ?? 'GIG',
                invoice_folio_egress: parseInt((vals?.invoice_folio_egress ?? 2) - 1),
                invoice_serie_egress: vals?.invoice_serie_egress ?? 'GIG',
            })
            message.success('Datos actualizados')
            setloading(false)
            close()
        } catch (error) {
            message.error(error?.message ?? 'Ocurrió un error al actualizar tus datos')
            setloading(false)
        }

    }


    const { certificate } = userDocument?.invoiceProfile ?? {}
    const { expires_at } = certificate ?? {}





    /**
     * It returns a <> component with a Divider, a Form.Item, a Select, and a Row
     * @returns A React component
     */
    const FiscalInformation = () => {
        return <>
            <Divider><Typography.Text style={{ marginBottom: '0px', fontSize: '14px' }} type="secondary">Información Fiscal</Typography.Text></Divider>
            <Form.Item label="Nombre Legal (Fiscal)" name="legal_name" rules={[{ required: true, message: 'Por favor añade tu nombre legal' }]} help={<Row align="middle">
                <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Añade el nombre sin acentos, en mayúscula y sin el regimen societario (SA DE CV, SAPI, etc)</Typography.Text>
            </Row>}>
                <Input className="pro-input" />
            </Form.Item>
            <Form.Item label="RFC" name="rfc" rules={[{ required: true, message: 'Por favor añade una tu RFC' }]}>
                <Input className="pro-input" readOnly />
            </Form.Item>
            <Form.Item label="Régimen Fiscal" name="tax_system_m" rules={[{ required: true, message: 'Por favor añade tu régimen' }]} help={<Row align="middle">
                <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Puedes encontrar el régimen en tu constancia de situación fiscal.</Typography.Text>
            </Row>}>
                <Select
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    options={taxRegimes}
                    isLoading={false}
                    placeholder="Cuál es tu régimen fiscal"
                />
            </Form.Item>
            <Form.Item label="Sitio web" name="website" rules={[{ type: "url", message: 'Por favor añade una url válida (http://)' }]} help={<Row align="middle">
                <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Opcional, lo añadiremos en las facturas en caso de existir.</Typography.Text>
            </Row>}>
                <Input className="pro-input" />
            </Form.Item>
            <Row align="middle" style={{ paddingTop: '25px' }}>
                <QuestionCircleOutlined style={{ fontSize: '10px', color: '#cecece' }} />
                <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Busca tu información en tu <a href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal" target="_blank" rel="noreferrer"> constancia de situación fiscal</a> </Typography.Text>
            </Row>
        </>
    }

    /**
     * It returns a divider, a row, and a bunch of columns with form items inside
     * @returns A React component
     */
    const AddressInformation = () => {
        return <>
            <Divider><Typography.Text style={{ marginBottom: '0px', fontSize: '14px' }} type="secondary">Dirección Fiscal</Typography.Text></Divider>
            <Row gutter={{ xs: 0, lg: 24 }}>
                <Col xs={24}>
                    <Form.Item label="Nombre de la calle" name="street" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item label="# Exterior" name="exterior" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item label="# Interior" name="interior" >
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={16}>
                    <Form.Item label="Colonia" name="neighborhood" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>

                </Col>
                <Col xs={24} lg={8}>
                    <Form.Item label="Código postal" name="zip" rules={[{ required: true, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} hidden >
                    <Form.Item label="Municipio / Delegación" name="municipality" rules={[{ type: "string", required: false, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item label="Ciudad" name="city" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item label="Estado" name="state" rules={[{ type: "string", required: true, message: 'Por favor revisa el campo' }]}>
                        <Input className="pro-input" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    }


    const EditFacturapi = () => {
        const searchTax_sistem = taxRegimes.find((tax) => tax.value === team?.facturapi?.legal?.tax_system ?? team?.tax_system_m ?? null)

        console.log(team?.tax_system_m)
        return <Form form={formEdit} onFinish={UpdateTeam} layout="vertical" initialValues={status === 'success' ? { ...team, ...(team?.facturapi?.legal?.address ?? team?.address ?? {}), tax_system_m: searchTax_sistem, tax_system: team?.facturapi?.legal?.tax_system ?? team?.tax_system ?? null, invoice_folio_payments: (team?.invoice_folio_payments ?? 1) + 1, invoice_folio: (team.invoice_folio ?? team.invoicesCreated) + 1, RFC: team?.facturapi?.legal.tax_id ?? team?.tax_id ?? null, invoice_serie: team.invoice_serie ?? 'gigstack', legal_name: team?.facturapi?.legal?.legal_name ?? team?.legalName, invoice_serie_payments: team?.invoice_serie_payments, invoice_serie_egress: team?.invoice_serie_egress, invoice_folio_egress: team?.invoice_folio_egress } : { ...team, tax_system: team?.facturapi?.legal?.tax_system ?? team?.tax_system_m, invoice_folio: (team?.invoice_folio ?? team?.invoicesCreated) + 1, invoice_folio_payments: (team?.invoice_folio_payments ?? 0) + 1, invoice_serie: team?.invoice_serie ?? 'gigstack', invoice_serie_payments: team?.invoice_serie_payments, invoice_folio_egress: team?.invoice_folio_egress, invoice_serie_egress: team?.invoice_serie_egress }}>
            {expires_at && <Typography.Text type="secondary">Tu certificado es válido hasta el día {moment(expires_at).format('DD/MM/YYYY')}</Typography.Text>}
            <DefaultsForm />
            <FiscalInformation />
            <AddressInformation />









            <Row justify="center">
                <Button type="primary" loading={loading} onClick={() => {
                    formEdit.validateFields();
                    formEdit.submit()
                }} >Actualizar datos</Button>
            </Row>
        </Form>
    }

    const UploadStamps = () => {
        return <Form form={form} layout='vertical' initialValues={{ ...team, ...(team?.address ?? {}) }} onFinish={async (v) => {
            setloading(true)
            if (step === 0) {
                try {
                    await updateDoc(doc(fs, `teams/${team?.id}`), { ...v, tax_system: v.tax_system_m.value, tax_system_m: v.tax_system_m, legalName: v.legal_name.toUpperCase(), legal_name: v.legal_name?.toUpperCase(), rfc: v.rfc?.toUpperCase() })
                    setStep(1)
                    setloading(false)
                } catch (error) {
                    setloading(false)
                }
            } else if (step === 1) {
                try {
                    const upd = {
                        ...v,
                    }
                    if (v.zip) {
                        upd.address = {
                            exterior: v.exterior ?? '',
                            interior: v.interior ?? '',
                            zip: v.zip ?? '',
                            neighborhood: v.neighborhood,
                            city: v.city ?? '',
                            municipality: v.municipality ?? '',
                            state: v.state ?? '',
                            country: 'México',
                            street: v.street ?? ''
                        }

                        delete upd.exterior
                        delete upd.interior
                        delete upd.zip
                        delete upd.neighborhood
                        delete upd.city
                        delete upd.municipality
                        delete upd.state
                        delete upd.street
                    }
                    await updateDoc(doc(fs, `teams/${team.id}`), { ...RemoveUndefined(upd) })
                    setloading(false)
                    setStep(2)
                } catch (error) {
                    setloading(false)
                }
            } else if (step === 2) {
                try {
                    seterror(null)
                    await SignedInternalAPIRequest({
                        password: v.password,
                        rfc: userDocument.rfc,
                        invoicingIntegration: 'facturapi',
                        type: 'connect'
                    }, 'invoicing', auth.currentUser)
                    notification.success({
                        message: '¡Listo! Tu cuenta está lista para emitir facturas',
                    })

                    if (team.integrationsSetup?.includes('invoices')) {
                        await updateDoc(doc(fs, `teams/${team.id}`), {
                            integrationsSetup: team?.integrationsSetup?.filter(i => i !== 'invoices')
                        })
                    }
                    getCurrentTeam(team?.id)
                    close()
                    logEvent(analytics, 'invoicingProfileCreated', {});

                    setloading(false)

                } catch (error) {
                    // console.log(error)
                    logEvent(analytics, 'invoicingProfileError', {
                        error: error.message ?? ''
                    });
                    seterror(error?.message ?? '')
                    setloading(false)
                }
            }
        }}>
            <ToRender />
        </Form>
    }

    const EditSellosDigitales = () => {
        const [formCSD] = Form.useForm()
        const [loadingButton, setLoadingButton] = useState(false)
        return <div className="d-flex flex-column" style={{ width: '100%' }}>
            <Form form={formCSD} layout="vertical" style={{ width: '100%' }} onFinish={async (v) => {
                try {
                    seterror(null)
                    setLoadingButton(true)
                    const resp = await SignedInternalAPIRequest({
                        password: v.password,
                        rfc: team.rfc,
                        invoicingIntegration: 'facturapi',
                        type: 'connect'
                    }, 'invoicing', auth.currentUser)
                    message.success(resp?.message ?? '¡Listo! Tus sellos están actualizados')
                    await updateDoc(fs, 'teams', team.id, {
                        integrationsSetup: team.integrationsSetup.filter(i => i !== 'invoices')
                    })
                    getCurrentTeam(team?.id)
                    setLoadingButton(false)
                    close()
                    logEvent(analytics, 'invoicingProfileCreated', {});
                } catch (error) {
                    setLoadingButton(false)
                }
            }}>
                <Row gutter={{ xs: 10, md: 12, lg: 14 }} style={{ width: '100%' }}>
                    <Col xs={24} >
                        <Typography.Text type='secondary' style={{ marginBottom: '0px' }}>Actualiza tus sellos para emitir facturas válidas ante el SAT</Typography.Text>
                        <HelperCard supportImageUrl={infoGrad} squareImg={true} title='Dudas sobre tu CSD o ¿cómo obtenerlos?' description='El Sello de Certificado Digital (CSD) es un archivo que contiene la información de tu empresa y que te permite firmar electrónicamente tus documentos fiscales. Es un requisito obligatorio para poder emitir facturas electrónicas. Haz click aquí para leer una guía de como obtenerlos' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/6902738-genera-tu-csd-certificado-de-sello-digital', '_blank')} />

                        <div style={{ marginTop: '25px' }}>
                            <Typography.Text className='cLabel labelRequired'>Actualizalos certificados de tu sello digital</Typography.Text></div>
                        <div style={{ marginTop: '10px' }}>
                            <FileUploader
                                title=' <p style="margin-bottom:0px; font-size:13px;" class="strong">Selecciona el archivo .KEY de tus sellos CSD</p>'
                                descriptions='<p style="font-size:12px;">Puedes arrastrar tu archivo .KEY o <span class="strong"">buscarlo</span> en tus archivos.<br/></p>'
                                maxFiles={1}
                                setStep={setStep} step={step}
                                defaultFilesKey={filesLoadedKey ? existingFilesKey?.map((f) => f.forFilePondKey) : []}
                                storageReference={`teams/${team.id}/privateFiles`}
                                dbref={`teams/${team.id}/privateFiles`}
                                setexistingFilesKey={setexistingFilesKey}
                                toDelete={async (ref) => {
                                    const index = existingFilesKey.findIndex((file) => file.path === ref)
                                    await deleteDoc(doc(fs, `${existingFilesKey[index].dbref}/${existingFilesKey[index].code}`))
                                    try {
                                        await deleteObject(existingFilesKey[index].path)
                                    } catch (e) { }
                                    message.success('Archivo eliminado')
                                }}
                                onUpload={async (file) => {
                                    try {
                                        await updateDoc(doc(fs, `${file.dbref}/${file.name.includes('key') ? 'csdkey' : 'csdcert'}`), {
                                            ...file,
                                            invoicingKey: true,
                                            code: file.name.includes('key') ? 'csdkey' : 'csdcert',
                                            type: file.name.includes('key') ? 'key' : 'cert',
                                        })
                                        logEvent(analytics, 'invoicingCertUploaded', { type: file.name.includes('key') ? 'key' : 'cert', });
                                    } catch (error) {
                                    }

                                }}
                                acceptedFileTypes={[]}
                                existingFilesKey={existingFilesKey} />

                            <FileUploader
                                title=' <p style="margin-bottom:0px; font-size:13px;" class="strong">Selecciona el archivo .CER de tus sellos CSD</p>'
                                descriptions='<p style="font-size:12px;">Puedes arrastrar tu archivo .CER o <span class="strong"">buscarlo</span> en tus archivos.<br/></p>'
                                maxFiles={1}
                                setStep={setStep} step={step}
                                defaultFiles={filesLoaded ? existingFiles?.map((f) => f.forFilePond) : []}
                                storageReference={`teams/${team.id}/privateFiles`}
                                dbref={`teams/${team.id}/privateFiles`}
                                setexistingFiles={setexistingFiles}
                                toDelete={async (ref) => {
                                    const index = existingFiles.findIndex((file) => file.path === ref)
                                    await deleteDoc(doc(fs, `${existingFiles[index].dbref}/${existingFiles[index].code}`))
                                    try {
                                        await deleteObject(existingFiles[index].path)
                                    } catch (e) { }
                                    message.success('Archivo eliminado')
                                }}
                                onUpload={async (file) => {
                                    try {
                                        await updateDoc(doc(fs, `${file.dbref}/${file.name.includes('cer') ? 'csdcert' : 'csdkey'}`), {
                                            ...file,
                                            invoicingKey: true,
                                            code: file.name.includes('cer') ? 'csdcert' : 'csdkey',
                                            type: file.name.includes('cer') ? 'cert' : 'key',
                                        })
                                        logEvent(analytics, 'invoicingCertUploaded', { type: file.name.includes('cer') ? 'cert' : 'key' });
                                    } catch (error) {
                                    }

                                }}
                                acceptedFileTypes={[]}
                                existingFiles={existingFiles} /></div>
                    </Col>

                    <Col xs={24} lg={12}>
                        <Form.Item name="rfc" label="RFC" rules={[{ required: true, message: 'Por favor añade el RFC' }]}>
                            <Input placeholder="ej: DLE180323RU9" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Contraseña del certificado"
                            help={<Typography.Text type='secondary' style={{ fontSize: '10px' }}></Typography.Text>}
                            className="" name="password" rules={[{ required: true, message: 'Por favor añade tu contraseña' }]}>
                            <Input.Password className="pro-input" />
                        </Form.Item>
                    </Col>

                </Row>




                {error && <Row justify='center'>
                    <div className="d-flex flex-column" style={{
                        alignItems: 'start',
                        justifyContent: 'start',
                        border: '1px solid #f7f7f7',
                        borderRadius: '10px',
                        width: '100%',
                        padding: '10px',
                        backgroundColor: '#fafbfd',
                    }}>
                        <Typography.Title level={5} style={{ fontSize: '12px' }} type="">Ocurrió un error al editar mis sellos</Typography.Title>
                        <Typography.Text type="danger" style={{ fontSize: '12px' }}>Error: {error}</Typography.Text>
                    </div>
                </Row>}

                <Row justify='center' style={{ marginTop: '20px' }}>
                    <Button loading={loadingButton} justify='center' type="primary" onClick={() => {
                        formCSD.submit()
                    }}>Editar mis Sellos</Button>
                </Row>
            </Form>
        </div>
    }

    return (
        <Modal visible={open} onCancel={() => close()} footer={null} title={null} closable={false} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }}>

            <Row justify="space-between" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <Typography.Title level={4}>Emite facturas</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close()} />
            </Row>

            <div className="d-flex flex-column">
                {edit ? <Tabs type='card'>
                    <Tabs.TabPane
                        tab={
                            <Row align="middle" >
                                <SendOutlined />
                                <Typography.Text> Información Fiscal</Typography.Text>

                            </Row>
                        }
                        key="fiscal"
                    >
                        <EditFacturapi />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={
                            <Row align="middle">
                                <SendOutlined />
                                <Typography.Text> Sellos digitales</Typography.Text>
                            </Row>
                        }
                    >
                        <EditSellosDigitales />
                    </Tabs.TabPane>

                </Tabs> : <UploadStamps />}
            </div>
        </Modal>
    );
}

export default FacturapiModal;