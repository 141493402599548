import { CopyOutlined, DeleteOutlined, DownloadOutlined, FilePdfOutlined, FileZipOutlined, LeftOutlined, LoadingOutlined, MailOutlined, PlusOutlined, QrcodeOutlined, ReloadOutlined } from '@ant-design/icons';
import { Col, Divider, message, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { collection, deleteDoc, doc, getFirestore, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useAuth, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import TeamContext from '../../context/teamcontext/TeamContext';
import ComposeEmailModal from '../../widgets/email/ComposeEmailModal';

import ReviewPaymentModal from '../../widgets/payments/ReviewPaymentModal';


import PaymentRelations from '../../widgets/relations/PaymentsRelations';
import { SignedInternalAPIRequest } from '../functions/APIRequests';
import { DownloadInvoice, DuplicateInvoice, getItemsAmounts, HandleEmailStatus, InvoiceStatusReadable, paymentForms, paymentIntentsStatusMessage, returnColorByPaymentStatus, returnCurrencyValue, returnTextColorByPaymentStatus, SearchParamInURL } from '../functions/helpers';
import UserContext from '../../context/usercontext/UserContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { useUserPermissions } from '../../customHooks/userPermissions';
import TableTitle from '../../widgets/tables/TableTitle';
import { CiMail } from 'react-icons/ci';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';


function InvoiceDetails({ userDocument, changeRender }) {
    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const auth = useAuth()
    const fs = getFirestore()
    const ref = doc(fs, 'invoices', SearchParamInURL('id'))
    const { status, data: invoiceData } = useFirestoreDocData(ref)
    const { status: relStatus, data: relationData } = useFirestoreCollectionData(query(collection(fs, 'payments'), where('team', '==', team?.id ?? ''), where('invoices', 'array-contains-any', [SearchParamInURL('id')])), {
        idField: 'id',
    })
    /* Using the useFirestoreCollectionData hook to get the data from the emails collection. */
    const { status: emailStatus, data: emailsData } = useFirestoreCollectionData(query(collection(fs, 'emails'), where('related', '==', `invoices/${SearchParamInURL('id')}`)))

    const [loading, setloading] = useState(false)
    const [confirmPayment, setconfirmPayment] = useState(null)
    const [composeEmail, setcomposeEmail] = useState(null)
    const [downloading, setdownloading] = useState(null)

    const { canViewInvoices, canCreatePayments, canCreateInvoices } = useUserPermissions({ user, team, billingAccount })
    // console.log(status);

    /* Checking if the status is loading, if it is, it will return a div with the text "Cargando..." */
    if (status === 'loading') {
        return <div>Cargando...</div>
    }
    if (status === 'error') {
        return <div>Hubo un error</div>
    }



    /**
     * It closes the invoice form and returns the user to the invoices page
     * @returns the window.history.replaceState() function.
     */
    const Close = () => {
        changeRender('invoices')
        return window.history.replaceState(null, `tab`, `/invoices?pageActive=${SearchParamInURL('pageActive') ?? '1'}`)
    }


    /**
     * It deletes the invoice with the id in the URL
     * @returns The function DeleteInvoice is being returned.
     */

    const DeleteInvoice = async () => {
        try {
            setloading('delete')
            await deleteDoc(doc(fs, 'invoices', SearchParamInURL('id')))
            Close()
            setloading(null)
        } catch (error) {
            message.error('No se pudo eliminar la factura')
            return error
        }
    }



    /**
     * It returns a column with a row that contains a row with a left arrow icon and a title, and a row
     * with a pill with the invoice status
     * @returns A React component
     */
    const Header = () => {
        return <Col xs={24}>
            <div style={{

            }} className="d-flex flex-column">
                <Row justify="space-between" align="middle">
                    <Row align="middle">
                        <LeftOutlined onClick={Close} />
                        <Typography.Title style={{ margin: 0, padding: 0, marginLeft: '10px' }} level={3}>Detalles de la factura por {returnCurrencyValue(invoiceData?.total)} {invoiceData?.currency?.toUpperCase()}</Typography.Title>
                    </Row>
                    <div className="pill" onClick={async () => {

                        try {
                            setloading('reload')
                            await SignedInternalAPIRequest({
                                type: 'update_invoice',
                                invoice: invoiceData,
                                invoicingIntegration: 'facturapi',
                                test: !invoiceData?.livemode,
                            }, 'invoicing', auth.currentUser)
                            message.success('Factura actualizada de forma correcta.', 3)
                            setloading(null)
                        } catch (error) {
                            setloading(null)
                        }
                    }} style={{ marginLeft: '10px', backgroundColor: invoiceData?.cancellation_status === 'pending' ? '#FEE9E9' : !invoiceData?.livemode ? '#E6BEFF' : InvoiceStatusReadable(invoiceData?.status) === 'Válida' ? '#CCF6DB' : (InvoiceStatusReadable(invoiceData?.status) === 'Inválida') ? '#E6BEFF' : (InvoiceStatusReadable(invoiceData?.status) === 'Cancelada') ? '#FFB9B9' : '#CCF6DB' }}>
                        {loading === 'reload' ? <LoadingOutlined style={{ marginRight: '10px' }} /> : <ReloadOutlined style={{ marginRight: '10px' }} />}
                        <Typography.Text style={{ color: '#333' }}>{invoiceData?.cancellation_status === 'pending' ? 'En proceso de cancelación' : InvoiceStatusReadable(invoiceData?.status)} {invoiceData?.livemode ? '' : 'sin timbrar'}</Typography.Text>
                    </div>
                </Row>
                <Row justify="start" style={{ marginLeft: '25px' }}>
                    <Typography.Text type="secondary">Solicitado  el {moment(invoiceData?.timestamp).format('DD/MM/YYYY HH:mm')} ({moment(invoiceData?.timestamp).fromNow()})</Typography.Text>
                </Row>
            </div>
        </Col>
    }

    /**
     * It returns a column with a title, a table with a row with a circle with the first letter of the
     * client's name, and a column with the client's name, email, RFC, and zip code
     * @returns A function that returns a Col component with a div inside it.
     */
    // const Client = () => {
    //     return <Col xs={24} md={12} lg={8}>
    //         <div className="d-flex flex-column">
    //             <Typography.Title level={5}>Cliente</Typography.Title>
    //             <div className='tableContainer d-flex flex-column'>

    //                 <Row align="top">
    //                     <div className="customCircledTag" style={{ height: '30px', width: '30px' }}>
    //                         <Typography.Text>{invoiceData?.client?.name?.substring(0, 1) ?? "?"}</Typography.Text>
    //                     </div>
    //                     <div className="d-flex flex-column" style={{ marginLeft: '10px' }}>
    //                         <Typography.Text style={{ margin: 0, padding: 0 }}>{invoiceData?.client?.name ?? "?"}</Typography.Text>
    //                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px' }} type="secondary">{invoiceData?.client?.email ?? "?"}</Typography.Text>
    //                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px' }} type="secondary">{invoiceData?.client?.rfc ?? "?"}</Typography.Text>
    //                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px' }} type="secondary">C.P.{invoiceData?.client?.address?.zip ?? "?"}</Typography.Text>
    //                     </div>
    //                 </Row>
    //             </div>
    //         </div>

    //     </Col>
    // }

    /**
     * It returns a column with a title, a loading icon, and a table with the related payments
     * @returns A Col component with a div with a title and a div with a loading icon or a table with the
     * related payments.
     */
    // const Payments = () => {
    //     return <Col xs={24} md={12} lg={10}>
    //         <div className="d-flex flex-column">
    //             <Typography.Title level={5}>Pagos relacionados</Typography.Title>
    //             <div className='tableContainer d-flex flex-column'>

    //                 {relStatus === 'loading' ? <LoadingOutlined /> : <Suspense fallback={<LoadingOutlined />}>
    //                     {relationData?.length > 0 ? <PaymentsRelatedTo relatedTo={invoiceData ?? {}} payments={relationData ?? {}} userDocument={userDocument} showCards={false} /> : <EmptyTable customMessage={'No hay pagos relacionadas'} parentAction={null} elementName={'pagos'} />}
    //                 </Suspense>}
    //             </div>
    //         </div>

    //     </Col>;
    // }

    /**
     * It returns a column with a title, and two rows with two columns each
     * @returns A Col component with a div inside.
     */
    // const FullDetails = () => {
    //     return <Col xs={24} lg={12}>
    //         <div className="d-flex flex-column ">
    //             <Typography.Title level={5}>Detalles de la factura</Typography.Title>
    //             <Row className="tableContainer">
    //                 <Col xs={24} md={12}>
    //                     <div className=' d-flex flex-column'>
    //                         <InfoWithLeftIcon marginTop='10px' title="Monto" subtitle={`${returnCurrencyValue(invoiceData?.total)} ${invoiceData?.currency?.toUpperCase()}`} icon={<DollarOutlined />} />
    //                         <InfoWithLeftIcon marginTop='10px' title="Folio Fiscal" copyableSubtitle={true} subtitle={`# ${invoiceData?.uuid}`} icon={<InfoOutlined />} />
    //                     </div>

    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <div className="d-flex flex-column">
    //                         <InfoWithLeftIcon marginTop='10px' title="Folio" subtitle={`# ${invoiceData?.folio_number}`} icon={<NumberOutlined />} />
    //                         <InfoWithLeftIcon marginTop='10px' title="Serie" subtitle={invoiceData?.series} icon={<CiBarcode />} />
    //                         <InfoWithLeftIcon marginTop='10px' title="Tipo" subtitle={invoiceData?.type === 'I' ? 'Ingreso' : invoiceData?.type === 'P' ? 'Pago' : invoiceData?.type === 'E' ? 'Egreso' : ''} icon={<EyeOutlined />} />
    //                     </div>
    //                 </Col>
    //                 <Col xs={24}>
    //                     <div className='d-flex flex-column'>
    //                         <InfoWithLeftIcon marginTop='10px' title="Productos/Servicios" subtitle={` ${invoiceData?.internalItems?.length} item${invoiceData?.internalItems?.length > 1 ? 's' : ''}`} icon={<BsBasket />} />
    //                         <Table
    //                             style={{
    //                                 marginTop: '10px'
    //                             }}
    //                             showHeader={false}
    //                             pagination={{
    //                                 hideOnSinglePage: true
    //                             }}
    //                             dataSource={invoiceData?.internalItems
    //                             }
    //                             columns={[{
    //                                 title: <TableTitle title="Concepto" />,
    //                                 dataIndex: 'name',
    //                                 render: (text, record) => {
    //                                     return <div className="d-flex flex-column">
    //                                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{record?.name}</Typography.Text>
    //                                     </div>
    //                                 }
    //                             },
    //                             {
    //                                 title: <TableTitle title="Cantidad" />,
    //                                 dataIndex: 'quantity',
    //                                 render: (text, record) => {
    //                                     return <div className="d-flex flex-column">
    //                                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{record?.quantity}x</Typography.Text>
    //                                     </div>
    //                                 }
    //                             },
    //                             {
    //                                 title: <TableTitle title="Subtotal" />,
    //                                 dataIndex: 'subtotal',
    //                                 render: (text, record) => {
    //                                     return <div className="d-flex flex-column">
    //                                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{returnCurrencyValue(record?.total)}</Typography.Text>
    //                                     </div>
    //                                 }
    //                             },
    //                             {
    //                                 title: <TableTitle title="Total" />,
    //                                 dataIndex: 'total',
    //                                 render: (text, record) => {
    //                                     return <div className="d-flex flex-column">
    //                                         <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{returnCurrencyValue(record?.total * record?.quantity)}</Typography.Text>
    //                                     </div>
    //                                 }
    //                             }
    //                             ]}

    //                         />
    //                     </div>
    //                 </Col>
    //             </Row>
    //         </div>


    //     </Col>
    // }

    /**
     * It returns a column with a title, a loading icon, an empty table component, or a list of emails
     * @returns A Col component with a div with a title and a div with a table.
     */
    // const SentEmails = () => {
    //     return <Col xs={24} lg={12} >
    //         <div className="d-flex flex-column">
    //             <Typography.Title level={5}>Correos enviados</Typography.Title>
    //             <div className='tableContainer d-flex flex-column'>
    //                 {emailStatus === 'loading' ? <LoadingOutlined /> : emailsData?.length <= 0 ? <EmptyTable customMessage={'No hay correos enviados'} parentAction={null} elementName={'emails'} /> : emailsData.map((email, inx) => {
    //                     return <Row key={email?.id} style={{ marginTop: inx === 0 ? '0px' : '10px' }}>
    //                         <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
    //                             <MailOutlined type="secondary" />
    //                         </div>
    //                         <div className="d-flex flex-column">
    //                             <Typography.Text >"{email?.subject}"</Typography.Text>
    //                             <Row wrap={true}>
    //                                 {email?.to?.map((to) => {
    //                                     return <Tag key={to}>{to}</Tag>
    //                                 })}
    //                             </Row>
    //                             <Typography.Text type="secondary" style={{ fontSize: '11px', marginTop: '5px' }}>Enviado {moment(email.timestamp).fromNow()}, último evento recibido: {email.lastEvent ? `${email?.lastEvent === 'open' ? '"abierto"' : email?.lastEvent} ${moment(email?.lastEventAt * 1000).fromNow()}` : 'sin evento.'}</Typography.Text>
    //                         </div>
    //                     </Row>
    //                 })}
    //             </div>
    //         </div>
    //     </Col>
    // }

    /**
     * It returns a column with a title and a list of actions that can be performed on the invoice
     * @returns A column with the actions that can be performed on the invoice
     */
    const Actions = () => {
        return <div className="d-flex flex-column">
            <div className='tableContainer d-flex flex-column'>
                <Row justify="space-between " align="middle" style={{ marginBottom: '15px', marginLeft: '0px' }}>
                    <Typography.Text style={{ fontWeight: 'bold', fontSize: '12px' }}>Acciones</Typography.Text>
                </Row>
                {canCreatePayments && <PaymentRelations customElement={<Row align="middle" className="clickable" style={{ marginTop: '0px' }}>
                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        <PlusOutlined type="secondary" />
                    </div>

                    <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Crear pago</Typography.Text>
                </Row>} custom={true} items={invoiceData?.items} collection="payments" elementType="payment" changeState={(i) => {

                }}
                    client={invoiceData?.client}
                    item={{ ...invoiceData, payments: invoiceData?.payments }}
                    id={invoiceData?.fid}
                    userDocument={userDocument}
                    readable="pago" />}
                {!invoiceData?.livemode && <Popconfirm title="¿Timbrar la factura actual?" okText="Timbrar" okButtonProps={{ type: "primary" }} cancelText="Cancelar" onConfirm={() => {
                    if (!canCreateInvoices) return message.warning('No tienes permisos para timbrar facturas');
                    DuplicateInvoice({
                        invoice: invoiceData,
                        setloading: (i) => {
                            setdownloading(i)
                        },
                        currentUser: auth.currentUser,
                        onSuccess: (inv) => {
                            window.location.replace(`/invoiceDetails?id=${inv?.id}&pageActive=${SearchParamInURL('pageActive')}`);
                        }
                    })
                }} >
                    <Row align="middle" className="clickable" style={{ marginTop: '10px' }} >
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            {downloading === invoiceData?.id ? <LoadingOutlined /> : <QrcodeOutlined type="secondary" />}
                        </div>

                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Timbrar Factura</Typography.Text>
                    </Row>
                </Popconfirm>}
                <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => {

                    setcomposeEmail(true)
                }}>
                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        <MailOutlined type="secondary" />
                    </div>

                    <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Enviar por correo</Typography.Text>
                </Row>
                <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => {

                    DownloadInvoice({ invoice: invoiceData, type: 'pdf', setdownloading: (v) => setdownloading(v), authUser: auth.currentUser, customDownloading: 'PDF' })
                }}>
                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {downloading === 'PDF' ? <LoadingOutlined /> : <FilePdfOutlined type="secondary" />}
                    </div>

                    <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Descargar PDF</Typography.Text>
                </Row>
                <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => {

                    DownloadInvoice({ invoice: invoiceData, type: 'xml', setdownloading: (v) => setdownloading(v), authUser: auth.currentUser, customDownloading: 'XML' })
                }}>
                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {downloading === 'XML' ? <LoadingOutlined /> : <DownloadOutlined type="secondary" />}
                    </div>

                    <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Descargar XML</Typography.Text>
                </Row>
                <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => {

                    DownloadInvoice({ invoice: invoiceData, type: 'zip', setdownloading: (v) => setdownloading(v), authUser: auth.currentUser, customDownloading: 'ZIP' })
                }}>
                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {downloading === 'ZIP' ? <LoadingOutlined /> : <FileZipOutlined type="secondary" />}
                    </div>

                    <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Descargar ZIP</Typography.Text>
                </Row>

                {canCreateInvoices && (!invoiceData?.livemode) && <Popconfirm title="¿Eliminar la factura?" onConfirm={DeleteInvoice} okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar">
                    <Row align="middle" className="clickable" style={{ marginTop: '10px' }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            {loading === 'delete' ? <LoadingOutlined /> : <DeleteOutlined type="secondary" />}
                        </div>

                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Eliminar la factura</Typography.Text>
                    </Row>
                </Popconfirm>}


                {/* {canCreateInvoices && <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={async () => {

                    try {
                        setloading('reload')
                        await SignedInternalAPIRequest({
                            type: 'update_invoice',
                            invoice: invoiceData,
                            invoicingIntegration: 'facturapi',
                            test: !invoiceData?.livemode,
                        }, 'invoicing', auth.currentUser)
                        message.success('Factura actualizada de forma correcta.', 3)
                        setloading(null)
                    } catch (error) {
                        setloading(null)
                    }
                }}>
                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {loading === 'reload' ? <LoadingOutlined /> : <ReloadOutlined />}
                    </div>

                    <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Actualizar estado</Typography.Text>
                </Row>} */}


            </div>
        </div>
    }

    if (!canViewInvoices) return <div></div>




    const HeaderV2 = () => {
        return <Row justify="space-between" align="middle">
            <Row align="top">
                <div className="customCircledTag" style={{ height: '30px', width: '30px' }}>
                    <Typography.Text>{invoiceData?.client?.name?.substring(0, 1) ?? "?"}</Typography.Text>
                </div>
                <div className="d-flex flex-column" style={{ marginLeft: '10px' }}>
                    <Typography.Text style={{ margin: 0, padding: 0, fontWeight: 'bold' }}>{invoiceData?.client?.name ?? "?"}</Typography.Text>
                    <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px' }} type="secondary">{invoiceData?.client?.email ?? "?"}</Typography.Text>
                </div>
            </Row>
            <Row align="top">
                <div className="d-flex flex-column" style={{ marginLeft: '10px' }}>
                    <Typography.Text style={{ margin: 0, padding: 0, fontSize: '10px', textAlign: 'right' }} className='cLabel'>Información Fiscal</Typography.Text>
                    <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px', textAlign: 'right' }} type="secondary">{invoiceData?.client?.rfc ?? "?"}</Typography.Text>
                    <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px', textAlign: 'right' }} type="secondary">C.P.{invoiceData?.client?.address?.zip ?? "?"}</Typography.Text>
                    <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px', textAlign: 'right' }} type="secondary">{invoiceData?.client?.address?.address ?? ""}</Typography.Text>
                </div>
            </Row>
        </Row>
    }



    var createDate = moment(invoiceData?.timestamp).format('DD MMMM YYYY HH:mm')
    var certificateDate = moment(invoiceData?.stamp?.date).format('DD MMMM YYYY HH:mm')
    const InvoiceInfoV2 = () => {
        return <div className='d-flex flex-column' style={{ marginTop: '20px', width: '100%', padding: '10px', backgroundColor: '#faf9ff', borderRadius: '10px' }}>
            <Row justify="space-between " align="middle">
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '12px' }}>Información de la factura</Typography.Text>
                <Tag style={{ fontSize: '11px', marginLeft: 0 }}>{invoiceData?.series} - {invoiceData?.folio_number}</Tag>
            </Row>
            <Divider />
            <Space direction="vertical" size="small">
                <Typography.Text style={{ fontSize: '12px', margin: 0, padding: 0, color: 'black' }}><span style={{ fontWeight: "normal", color: 'gray' }}>Tipo de factura:</span> {
                    invoiceData?.type === 'I' ? 'Ingreso' :
                        invoiceData?.type === 'E' ? 'Egreso' :
                            invoiceData?.type === 'P' ? 'Pago' :
                                invoiceData?.type === 'N' ? 'Nómina' :
                                    invoiceData?.type === 'T' ? 'Traslado' :
                                        ''
                }</Typography.Text>
                <Typography.Text style={{ fontSize: '12px', margin: 0, padding: 0, color: 'black' }}><span style={{ fontWeight: "normal", color: 'gray' }}>Fecha de creación {createDate === certificateDate ? 'y timbrado' : ''}:</span> {createDate}</Typography.Text>
                {createDate !== certificateDate && <Typography.Text style={{ fontSize: '12px' }}><strong>Fecha de timbrado:</strong> { }</Typography.Text>}
                <Typography.Text copyable={{
                    icon: <CopyOutlined />,
                    tooltips: ['Copiar', 'Copiado'],
                    text: invoiceData?.uuid
                }} style={{ fontSize: '12px', color: 'black' }}><span style={{ fontWeight: "normal", color: 'gray' }}>Folio Fiscal:</span> {invoiceData?.uuid}</Typography.Text>
                <Typography.Text style={{ fontSize: '12px', color: 'black' }}><span style={{ fontWeight: "normal", color: 'gray' }}>Uso:</span> {invoiceData?.use}</Typography.Text>
                <Typography.Text style={{ fontSize: '12px', color: 'black' }}><span style={{ fontWeight: "normal", color: 'gray' }}>Forma de pago:</span> {invoiceData?.payment_method}</Typography.Text>
                <Typography.Text style={{ fontSize: '12px', color: 'black' }}><span style={{ fontWeight: "normal", color: 'gray' }}>Método de pago:</span> {paymentForms.find((a) => a.value === invoiceData?.payment_form)?.label} ({invoiceData?.payment_form})</Typography.Text>
            </Space>
        </div>
    }




    const InvoiceItemsV2 = () => {
        return <div className='d-flex flex-column' style={{ marginTop: '20px' }}>
            <Row justify="space-between " align="middle">
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '12px', marginLeft: '10px' }}>Productos / Servicios</Typography.Text>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '12px', marginRight: '20px' }}>Total</Typography.Text>
            </Row>
            <Table
                style={{
                    marginTop: '10px'
                }}
                showHeader={false}
                pagination={{
                    hideOnSinglePage: true
                }}
                dataSource={invoiceData?.internalItems
                }
                columns={[{
                    title: <TableTitle title="Concepto" />,
                    dataIndex: 'name',
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{record?.name}</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Cantidad" />,
                    dataIndex: 'quantity',
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{record?.quantity}x</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Subtotal" />,
                    dataIndex: 'subtotal',
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{returnCurrencyValue(record?.total)}</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Impuestos" />,
                    dataIndex: 'taxes',
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            {record?.taxes?.map((tax) => {
                                return <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{tax.type} {tax.withholding ? 'RET' : ''} {Number(tax.rate * 100)}% {tax.inclusive ? '(inc)' : ''}</Typography.Text>
                            })}
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Total" />,
                    dataIndex: 'total',
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px', textAlign: 'end' }}>{getItemsAmounts([record], false).totalString}</Typography.Text>
                        </div>
                    }
                }
                ]}
            />
        </div>
    }


    const PaymentsTable = () => {
        return <div className="d-flex flex-column" style={{ marginTop: '5px' }}>
            <Row justify="space-between " align="middle" style={{ marginBottom: '15px', marginLeft: '10px' }}>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '12px' }}>Pagos relacionados</Typography.Text>
                {/* <Tag style={{ fontSize: '11px', marginLeft: 0 }}>{invoiceData?.series} - {invoiceData?.folio_number}</Tag> */}
            </Row>
            <Table
                pagination={
                    {
                        hideOnSinglePage: true
                    }
                }
                loading={relStatus === 'loading'}
                dataSource={relationData}
                columns={[
                    {
                        title: <TableTitle title="Pago" />,
                        dataIndex: 'id',
                        width: '20%',
                        ellipsis: true,
                        render: (text, record) => {
                            return <div className="d-flex flex-column">
                                <Typography.Text copyable style={{ margin: 0, padding: 0, fontSize: '10px' }}>{record?.id}</Typography.Text>
                            </div>
                        }
                    },
                    {
                        title: <TableTitle title="Estado" />,
                        dataIndex: 'status',
                        width: '15%',
                        ellipsis: true,
                        sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
                        render: (text, record) => {
                            var limitDate = record?.limitDaysToPay ? moment(record?.timestamp).add(record?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null

                            return <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                <Tooltip title={paymentIntentsStatusMessage(record)}>
                                    <div> <Tag className="statusTag" color={returnColorByPaymentStatus(record)} style={{
                                        color: returnTextColorByPaymentStatus(record), borderRadius: '10px', opacity: 0.9, padding: '0px 10px', fontWeight: 500
                                    }}>{paymentIntentsStatusMessage(record)}</Tag></div>
                                </Tooltip>
                                {(team?.defaults?.allowPaymentsSetting && limitDate?.valueOf() < moment().valueOf() && record?.paymentLimit !== null && record.status !== 'succeeded') &&
                                    <div style={{ marginTop: '5px' }}>  <Tag color="red" >{(limitDate?.valueOf() < moment().valueOf()) ? `PAGO ATRASADO ${moment(record?.timestamp).add(record?.limitDaysToPay, 'days').hours(team?.defaults?.limitHourToPay ?? '17').fromNow()}` : ''}</Tag></div>
                                }
                            </Row>

                        }
                    },
                    {
                        title: <TableTitle title="Fechas" />,
                        dataIndex: 'timestamp',
                        render: (text, record) => {

                            const isSucceeded = record.status === 'succeeded'
                            const succeededTimestamp = isSucceeded && !record.succeededTimestamp ? record["event-payment_intent-succeeded"]?.timestamp ?? record?.timestamp : record.succeededTimestamp

                            return <div className="d-flex flex-column">
                                <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px', color: 'black' }}><span style={{ color: 'gray' }}>Solicitud:</span> {moment(record.timestamp).format('DD MMM YYYY HH:mm')}</Typography.Text>
                                {succeededTimestamp && <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px', color: 'black' }}><span style={{ color: 'gray' }}>Pago:</span> {moment(succeededTimestamp).format('DD MMM YYYY HH:mm')}</Typography.Text>}
                            </div>
                        }
                    },
                    {
                        title: <TableTitle title="Total Pagado" />,
                        dataIndex: 'amount',
                        render: (text, record) => {
                            var itemsAmounts = getItemsAmounts(record?.items, record.paidIn === 'bank')
                            return <div className="d-flex flex-column">
                                <Typography.Text style={{ margin: 0, padding: 0, fontSize: '12px' }}>{itemsAmounts.totalString}</Typography.Text>
                            </div>
                        }
                    },
                    {
                        title: <TableTitle title="" />,
                        dataIndex: 'actions',
                        render: (text, record) => {
                            return <Space>
                                {record?.shortURL && <Typography.Text copyable={{
                                    icon: <CopyOutlined />,
                                    tooltips: ['Copiar', 'Copiado'],
                                    text: record?.shortUrl ?? record?.shortURL ?? ''
                                }}></Typography.Text>}
                            </Space>
                        }
                    },
                ]}

            />
        </div>
    }



    const EmailsTable = () => {
        return <div className="d-flex flex-column" style={{ marginTop: '5px' }}>
            <Row justify="space-between " align="middle" style={{ marginBottom: '15px', marginLeft: '10px' }}>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '12px' }}>Correos enviados</Typography.Text>
            </Row>

            {emailStatus === 'loading' ? <LoadingOutlined /> : emailsData.map((email, index) => {
                return <div className="d-flex flex-column" style={{ marginTop: '5px', marginLeft: '0px', backgroundColor: '#fafbfd', borderRadius: '10px', padding: '10px' }}>
                    <Row align="middle" justify="space-between" key={email.id} className="d-flex flex-row" >
                        <Row align='middle' style={{ flex: 1 }}>
                            <Row align="middle" style={{ flex: 1 }}>
                                <CiMail />
                                <Divider type="vertical" />
                                <div style={{ alignItems: 'start', marginLeft: '5px' }} className='d-flex flex-column' >
                                    <Typography.Text style={{ margin: 0, padding: 0, marginTop: '10px', fontSize: '8px' }}>{moment(email.timestamp).format('DD MMM YYYY HH:mm')}</Typography.Text>
                                    <Typography.Text style={{ margin: 0, padding: 0, marginBottom: '5px', marginTop: '5px', fontSize: '12px' }}>"{email.subject}"</Typography.Text>

                                </div>
                            </Row>

                        </Row>
                        <Tag color={HandleEmailStatus(email.lastEvent).color} style={{ fontSize: '11px', marginLeft: 0, }}>{HandleEmailStatus(email.lastEvent).message} {moment(email.lastEventAt * 1000).fromNow(false)}</Tag>
                    </Row>
                    <Row wrap>
                        {email.to?.map((to, index) => {
                            return <Tag style={{ fontSize: '10px', marginTop: '5px' }} key={index}>{to}</Tag>
                        })}
                    </Row>
                </div>
            })}
        </div>
    }



    return (
        <ErrorBoundary>
            <Row justify='center' >
                {confirmPayment && <ReviewPaymentModal open={confirmPayment} close={() => setconfirmPayment(false)} payment={invoiceData} />}
                {composeEmail && <ComposeEmailModal
                    body={`Hola ${(invoiceData?.client ?? invoiceData?.internalClient)?.name ?? ''}, adjunto encontrarás los archivos relacionados a la factura ${invoiceData?.series} ${invoiceData?.folio_number}.\n\n\n-${userDocument?.brand?.alias ?? userDocument?.firstName}`}
                    close={() => setcomposeEmail(false)}
                    actionText={'Realizar pago'}
                    idRef={'invoices'}
                    data={invoiceData}
                    userDocument={userDocument}
                    open={composeEmail}
                    element={invoiceData}
                    subject={`Archivos de la factura ${invoiceData?.series} ${invoiceData?.folio_number}`}
                    readableType={'factura'}
                    client={(invoiceData?.client || invoiceData?.internalClient) ?? { name: 'Sin Cliente', email: '' }} />}
                <Header />

                <Col xs={24} style={{ marginTop: '50px' }}>
                    <Row gutter={{ xs: 10, lg: 14 }}>
                        <Col xs={23} lg={16}>
                            <div className='d-flex flex-column'>
                                <div style={{ width: '100%', backgroundColor: 'white', padding: '10px', borderRadius: '10px' }}>
                                    <div className='d-flex flex-column'>
                                        <HeaderV2 />
                                        <InvoiceInfoV2 />
                                        <InvoiceItemsV2 />
                                    </div>
                                </div>
                                <div style={{ width: '100%', backgroundColor: 'white', padding: '10px', borderRadius: '10px', marginTop: '20px' }}>
                                    <div className='d-flex flex-column'>
                                        <PaymentsTable />
                                    </div>
                                </div>
                                <div style={{ width: '100%', backgroundColor: 'white', padding: '10px', borderRadius: '10px', marginTop: '20px' }}>
                                    <div className='d-flex flex-column'>
                                        <EmailsTable />
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xs={23} lg={8}>
                            <div className='d-flex flex-column'>
                                <Actions />
                            </div>
                        </Col>
                    </Row>
                    {/* <Row gutter={{ xs: 12, md: 15, lg: 20 }}>
                    <Client />
                    <Payments />
                    <Actions />
                    <Col xs={24} style={{ marginBottom: '25px' }}></Col>
                    <FullDetails />
                    <SentEmails />
                </Row> */}
                </Col>

            </Row>
        </ErrorBoundary>

    );
}

export default InvoiceDetails;