import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Typography } from 'antd';
import { logEvent } from 'firebase/analytics';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { useAnalytics } from 'reactfire';
import { generateCode } from '../../app/functions/helpers';
import FileUploader from '../uploads/FileUploader';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';

/**
 * It's a modal that allows the user to upload a file to the firebase storage and then creates a
 * document in the firestore with the file's metadata
 */
function ImportThings({ open, close, type, readableType, templateUrl }) {

    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)

    const [correctFiles, setCorrectFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const analytics = useAnalytics();
    return (
        <Modal visible={open} onCancel={() => close()} title={null} footer={null}>
            <div className='d-flex flex-column'>
                <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>Importar {readableType}</Typography.Title>
                <Typography.Text type="secondary">Selecciona un archivo de excel para continuar,  {templateUrl ? <a href={templateUrl} rel="noreferrer" target="_blank">descarga esta plantilla para comenzar</a> : <></>}.</Typography.Text>
                <Row>
                    {type === "clients" && <Typography.Text style={{ fontSize: '12px', marginTop: '10px' }} type="secondary">Los clientes que no tengan nombre o correo electrónico, no serán importados.</Typography.Text>}
                </Row>
                <div style={{ width: '100%', height: '40px' }}></div>
                <Row>
                    <Col xs={24}>
                        {correctFiles.length <= 0 ? <FileUploader
                            title=' <p style="margin-bottom:0px; font-size:13px;" class="strong">Subir documento</p>'
                            descriptions='<p style="font-size:12px;">Puedes arrastrar tus archivos o <span class="strong"">buscarlos</span>.</p>'
                            maxFiles={1}

                            acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                            defaultFiles={correctFiles}
                            storageReference={`teams/${team.id}/files`}
                            dbref={`/files`}
                            toDelete={async (ref) => {
                                message.success('Archivo eliminado')
                            }}
                            updateFiles={async (file) => {

                            }}
                            onUpload={async (file) => {
                                setCorrectFiles([...correctFiles, { ...file, status: 5 }])

                            }}
                        /> : <div style={{ padding: '10px 15px', borderRadius: '10px', backgroundColor: '#fafbfd', marginBottom: '15px' }} className="d-flex flex-column">
                            <Row justify="space-between">
                                <Typography.Text>{correctFiles[0].name}</Typography.Text>
                                <Row wrap align="middle">
                                    <Typography.Text>{Number(correctFiles[0].size / 1000000) >= 1 ? `${Number(correctFiles[0].size / 1000000).toFixed(0)} MB` : `${Number(correctFiles[0].size / 1000).toFixed(0)} KB`}</Typography.Text >
                                    <CloseOutlined onClick={async () => {
                                        const reference = ref(getStorage(), correctFiles[0].path);
                                        await deleteObject(reference)
                                        setCorrectFiles([])
                                    }} style={{ marginLeft: '15px', color: 'red' }} />
                                </Row>
                            </Row>

                        </div>}
                    </Col>
                    <Col xs={24}>
                        <Row justify="center">
                            <Button onClick={async () => {
                                setLoading(true)
                                await setDoc(doc(getFirestore(), `imports/${generateCode(15)}`), {

                                    path: correctFiles[0].path,
                                    ...correctFiles[0],
                                    fileType: correctFiles[0].type ?? '',
                                    owner: user?.uid ?? team?.owner,
                                    status: 'processing',
                                    timestamp: moment().valueOf(),
                                    type,
                                    team: team.id
                                })
                                logEvent(analytics, 'importStarted', {});
                                message.success('Importación iniciada')
                                setLoading(false)
                                close()
                            }} disabled={correctFiles.length <= 0} loading={loading} type="primary">Importar</Button>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Modal >
    );
}

export default ImportThings;