const integrationsCompleted = ({ team }) => {
    if (!team) return {}
    const availableIntegrations = [
        'stripe',
        'facturapi',
        'paypal',
        'conekta',
        'hilos',
        'bank'
    ]
    var integrations = {}
    availableIntegrations.forEach(integration => {
        if (team[integration]?.completed) {
            integrations[integration] = team[integration]
        }
    })

    return integrations
}


export const useUserPermissions = ({ user, team, billingAccount }) => {

    let canCreatePayments = false
    let canCreateInvoices = false
    let canCreateServices = false
    let canCreateInvoicesRecurring = false

    let canViewPayments = false
    let canViewInvoices = false
    let canViewServices = false

    let isBillingAccountAdmin = false
    let isTeamAdmin = false
    let isBillingAccountOwner = false
    let automaticInvoicing = false

    let memberObject = team?.members?.find(m => m.id === user?.uid)

    if (memberObject) {
        switch (memberObject.invoices) {
            case 'editor':
                canCreateInvoices = true
                canCreateInvoicesRecurring = true
                canViewInvoices = true
                break
            case 'viewer':
                canViewInvoices = true
                break
            default:
                break
        }

        switch (memberObject.payments) {
            case 'editor':
                canCreatePayments = true
                canViewPayments = true
                break
            case 'viewer':
                canViewPayments = true
                break
            default:
                break
        }

        switch (memberObject.services) {
            case 'editor':
                canCreateServices = true
                canViewServices = true
                break
            case 'viewer':
                canViewServices = true
                break
            default:
                break
        }
    }

    if (billingAccount?.admins?.includes(user?.uid)) {
        isBillingAccountAdmin = true
    }

    if (team?.admins?.includes(user?.uid)) {
        isTeamAdmin = true
    }

    if (team?.members?.find(m => m.id === user?.uid)?.isAdmin) {
        isTeamAdmin = true
    }

    if (billingAccount?.owner === user?.uid) {
        isBillingAccountOwner = true
    }

    if (team?.stripe?.automaticInvoicing) {
        automaticInvoicing = true
    }

    return {
        canCreatePayments,
        canCreateInvoices,
        canCreateInvoicesRecurring,
        canCreateServices,
        canViewPayments,
        canViewInvoices,
        canViewServices,
        isBillingAccountAdmin,
        isTeamAdmin,
        isBillingAccountOwner,
        automaticInvoicing,
        integrationsCompleted: integrationsCompleted({ team })
    }
}