import { Col, Row, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import { useMembershipOnce } from '../../customHooks/useMembershipOnce';
import { useUserAbilities } from '../../customHooks/userAbilities';
import { useUserPermissions } from '../../customHooks/userPermissions';
import BanksPage from '../pages/BanksPage';
import ClientDetails from '../pages/ClientDetails';
import ClientsPage from '../pages/ClientsPage';
import ExpensesPage from '../pages/ExpensesPage';
//import Dashboard from '../pages/Dashboard';
import InsigthsPage from '../pages/InsigthsPage';
import InvoiceDetails from '../pages/InvoicesDetails';
import InvoicesPage from '../pages/InvoicesPage';
import PaymentDetails from '../pages/PaymentDetails';
import PaymentsPage from '../pages/PaymentsPage';
import ServicesPage from '../pages/ServicesPage';
import SettingsPage from '../pages/SettingsPage';
import ReceiptsPage from '../pages/ReceiptsPage';
import Onbording from '../pages/Onbording';
import Recurrences from '../pages/Recurrences';
import CollectionsPayments from '../pages/CollectionsPayments';
// import { useTeamData } from '../../customHooks/useTeamData';


/**
 * It returns a component based on the render prop
 * @returns A component
 */
function MainLayoutRender({ render = "home", userDocument, subtab = "", changeRender }) {

    const { user } = useContext(UserContext)
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    // const { team: teamRt } = useTeamData({ teamId: team?.id ?? 'team' })

    const { canViewInvoices, canViewPayments, canViewServices } = useUserPermissions({ user, team: team, billingAccount })

    const { canSendInvoices,
        isStripeConnected,
        isWoocommerceConnected,
        stripeAutoInvoiceActive
    } = useUserAbilities({ userDocument })
    const { credits,
        membership,
        payAsYouGo } = useMembershipOnce({ uid: user?.uid, billingAccountId: billingAccount?.id })

    useEffect(() => {
        window.Intercom('update', {
            app_id: 'acgd4n2q',
            email: userDocument?.email,
            user_id: userDocument?.uid,
            user_hash: userDocument?.intercomHash,
            Cansendinvoices: canSendInvoices,
            Isstripeconnected: isStripeConnected,
            isWoocommerceConnected: isWoocommerceConnected ?? false,
            StripeAutoInvoiceActive: stripeAutoInvoiceActive ?? false,
            Credits: credits,
            Payasyougo: payAsYouGo,
            Membership: membership?.price_id,
        })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDocument])

    const AccessBlocked = () => {
        return <Col xs={24}>
            <Row justify='middel' align='middle'>
                <Typography.Title level={2}>No tienes acceso</Typography.Title>
            </Row>
        </Col>
    }


    switch (render) {
        case 'home':
            if (!team?.setupComplete) {
                return <Onbording userDocument={userDocument} changeRender={(n) => changeRender(n)} />
            } else {
                return <InsigthsPage userDocument={userDocument} changeRender={(n) => changeRender(n)} />
            }
        case 'clients':
            return <ClientsPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'services':
            if (!canViewServices) {
                return <AccessBlocked />
            }
            return <ServicesPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'payments':
            if (!canViewPayments) {
                return <AccessBlocked />
            }
            return <PaymentsPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'invoices':
            if (!canViewInvoices) {
                return <AccessBlocked />
            }
            return <InvoicesPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'receipts':
            return <ReceiptsPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'settings':
            return <SettingsPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'paymentDetails':
            return <PaymentDetails userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'invoiceDetails':
            return <InvoiceDetails userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'clientDetails':
            return <ClientDetails userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'insights':
            return <InsigthsPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'expenses':
            return <ExpensesPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'banks':
            return <BanksPage userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'recurrences':
            return <Recurrences userDocument={userDocument} subtab={subtab} changeRender={(n) => changeRender(n)} />
        case 'collections':
            return <CollectionsPayments />

        default:
            if (!team?.setupComplete) {
                return <Onbording userDocument={userDocument} changeRender={(n) => changeRender(n)} />
            } else {
                return <InsigthsPage userDocument={userDocument} changeRender={(n) => changeRender(n)} />
            }
    }
}

export default MainLayoutRender;