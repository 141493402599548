
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import LoadingFullScreen from '../../app/appStates/LoadingFullScreen';
import { SearchParamInURL } from '../../app/functions/helpers';

/**
 * It redirects to the beta version of the app
 * @returns a LoadingFullScreen component.
 */
function RedirectToBeta({ autoInvoice }) {


    //GET PARAM code
    //GET PARAM CODE from URL
    const { code } = useParams();
    /* Redirecting to the beta version of the app. */

    useEffect(() => {
        if (autoInvoice) {
            const id = SearchParamInURL('id');
            const getFullUrl = window.location.href;
            const getFullUrlArray = getFullUrl.split('?');

            if (id) {
                return window.location.href = `https://beta-gigstack-pro.firebaseapp.com/autofactura?${getFullUrlArray[1]}`;
            }
        } else {
            if (!code) {
                return;
            }
            return window.location.href = `https://beta-gigstack-pro.firebaseapp.com/pay/${code}`;
        }

        //eslint-disable-next-line
    }, [])


    return (
        <LoadingFullScreen />
    );
}

export default RedirectToBeta;