import { Button, Col, message, Modal, Row, Space, Typography } from 'antd';
import React, { useContext, useState } from 'react';

import zapierLogo from '../../assets/images/zapier.png';
import FirstViewIntregration from '../integrations/FirstScreenIntregration';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import TeamContext from '../../context/teamcontext/TeamContext';


function ModalZapier({ open, close, userDocument }) {

    const fs = getFirestore()

    const [loading, setLoading] = useState(null)
    const [step, setStep] = useState(0)
    const { team, getCurrentTeam } = useContext(TeamContext)

    const connectZapier = async () => {
        if (loading === "zapier") return
        try {
            setLoading("zapier")
            const link = 'https://zapier.com/app/login?next=/developer/public-invite/179156/ad9fa12230dc271823f48d9f0287e6fc/'
            // eslint-disable-next-line
            return window.open(link, '_blank')
        } catch (error) {
            message.error(error?.message ?? 'Ocurrio un error al contectar la cuenta.')
        } finally {
            setLoading(null)
        }
    }

    const finishZapierConnection = async () => {
        try {
            setLoading("connectZapier")
            await updateDoc(doc(fs, 'teams', team?.id), {
                zapier: {
                    ...(team?.zapier ?? {}),
                    completed: true,
                    completedAt: moment().valueOf()
                }
            })
            message.success('Cuenta conectada correctamente.')
            getCurrentTeam(team?.id)
            close()
            setLoading(null)
        } catch (error) {
            setLoading(null)
            message.error(error?.message ?? 'Ocurrio un error al contectar la cuenta.')
        }
    }
    const handleClickOnButton = async () => {
        switch (step) {
            case 0:
                return setStep(step + 1)
            case 1:
                return finishZapierConnection()
            default:
                return
        }
    }

    const ConnectZapierView = () => {
        return (
            <Space direction='vertical' style={{ width: '100%', margin: '5px 10px 35px 10px' }}>
                <Row>
                    <Typography.Text style={{ fontSize: '16px' }}>Para conectar tu cuenta de Zapier con Gigstack, debes seguir los siguientes pasos:</Typography.Text>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col xl={6}>
                        <Typography.Text strong>Paso 1</Typography.Text>
                    </Col>
                    <Col xl={18}>
                        <Typography.Text>Da click en el siguiente botón</Typography.Text>
                        <Row align="middle" justify="start" style={{ width: '100%', marginTop: '10px' }}>
                            <Button type="primary" onClick={() => connectZapier()} loading={loading === "zapier"}>Obtener integración</Button>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                    <Col xl={6}>
                        <Typography.Text strong>Paso 2</Typography.Text>
                    </Col>
                    <Col xl={18}>
                        <Typography.Text>Dentro de Zapier debes aceptar la invitación</Typography.Text>
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px', paddingRight: '20px' }}>
                    <Col xl={6}>
                        <Typography.Text strong>Paso 3</Typography.Text>
                    </Col>
                    <Col xl={18}>
                        <Typography.Text>Crea un nuevo Zap y podrás comenzar automatizar tus pagos desde Zapier</Typography.Text>
                    </Col>
                </Row>
            </Space>
        )
    }

    const ZapierConnectedView = () => {

        return (
            <div style={{ width: '100%', padding: '30px 20px' }}>
                <Row justify='center' align='middle'>
                    <Typography.Text style={{ fontSize: '16px' }}>Tu cuenta de Zapier ya está conectada con Gigstack</Typography.Text>
                </Row>
            </div>
        )
    }

    const ToRender = () => {
        switch (step) {
            case 0:
                return <div>
                    {team.zapier?.completed ? <ZapierConnectedView /> : <FirstViewIntregration appLogo={zapierLogo} appName='Zapier' title="Conecta Gigstack con Zapier" description="La integración con Zapier te permitirá crear pagos en Gigstack" permissions={['Crear pagos automatizados', 'Crear clientes']} />}
                </div>
            case 1:
                return <ConnectZapierView />
            default:
                return <></>
        }
    }

    const textByStep = () => {
        switch (step) {
            case 0:
                return 'Aceptar y continuar'
            case 1:
                return 'Finalizar'
            default:
                return "Siguiente"
        }
    }


    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "70%" : "35%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">

                <ToRender />

            </div>
            <Row justify='center'>
                <Button loading={loading === "connectZapier"} type='primary' onClick={() => {
                    if (team.zapier?.completed) return close()
                    handleClickOnButton()
                }}>
                    {team?.zapier?.completed ? 'Cerrar' : textByStep()}
                </Button>
            </Row>
        </Modal >
    );
}

export default ModalZapier;