import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row, Typography } from 'antd';
import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';
import Lottie from 'lottie-react';
import React, { Suspense, useContext, useState } from 'react'
import { useFirestoreCollectionData } from 'reactfire';
import loading from '../../assets/lotties/loading.json'
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import EmptyTable from '../states/EmptyTable';
import NewTemplate from './NewTemplate';


/**
 * This function is a React component that renders a list of templates that the user has created
 */
function TemplateElements({ selected, userDocument, setEdit }) {

    const { team } = useContext(TeamContext)

    const accountsRef = query(collection(getFirestore(), `templates`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));
    const { status: templatesStatus, data: templatesData } = useFirestoreCollectionData(accountsRef, {
        idField: 'id'
    })




    if (templatesStatus === 'loading') {
        return <Row>
            <Lottie animationData={loading} style={{ height: '150px', }} loop={true} />
        </Row>
    }

    if (templatesData.length === 0) {
        return <Row>
            <EmptyTable customMessage="Crea tu primer plantilla" elementName="plantilla" />
        </Row>
    }


    return <Row>

        {templatesData.map((template) => {
            return <Col xs={24} key={template.id}>
                <Row align="middle">
                    <div onClick={() => selected(template)} className="settingsContainer d-flex flex-column hoverBorderPrimary" style={{ padding: '10px 15px', marginTop: '10px', flex: 1 }} >
                        <Typography.Title level={5} style={{ fontSize: '14px' }}>{template.name}</Typography.Title>
                        <Typography.Text style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }} type="secondary">{template.content}</Typography.Text>
                    </div>
                    <EditOutlined style={{ marginLeft: '10px' }} onClick={() => {
                        setEdit(template)
                    }} />
                </Row>
            </Col>
        })}
    </Row>

}


function ViewTemplates({ open, close, selected, userDocument }) {
    const { user } = useContext(UserContext)
    const [newTemplate, setNewTemplate] = useState(false)
    const [edit, setEdit] = useState(null)

    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => close()} header={null} footer={null}>
            {edit && <Suspense>
                <NewTemplate open={edit} close={() => setEdit(null)} userDocument={user} edit={edit} />
            </Suspense>}
            {newTemplate && <NewTemplate close={() => setNewTemplate(false)} userDocument={user} open={newTemplate} />}
            <div style={{ maxHeight: '100%', width: '100%', marginBottom: '15px' }} className="d-flex flex-column">
                <Row align="middle" justify="space-between">
                    <Typography.Title level={5}>Selecciona una plantilla</Typography.Title>
                    <Button type="primary" onClick={() => setNewTemplate(true)}>Nueva</Button>
                </Row>
                <TemplateElements selected={(template) => selected(template)} setEdit={(t) => setEdit(t)} userDocument={userDocument} />
                <div style={{ height: '120px' }}></div>
            </div>
        </Drawer>
    );
}

export default ViewTemplates;