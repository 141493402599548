import { Button, Form, Input, message, Modal, Row, Tag, Typography } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useState } from 'react'
import { generateCode } from '../../app/functions/helpers';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';

function NewTemplate({ open, close, userDocument, edit }) {
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    /**
     * `AddReplacer` is a function that takes in a `replacer` prop and returns a button that, when clicked,
     * adds the `replacer` prop to the `content` field in the form
     */
    const AddReplacer = ({ replacer }) => {
        const content = form.getFieldValue('content');
        form.setFieldsValue({ content: (content ?? '') + ' ' + replacer })
    }

    /**
     * It creates a new document in the templates collection, with a random id, and the data provided by
     * the user
     * @param v - The values of the form
     */
    const CreateTemplate = async (v) => {
        try {

            setLoading(true);
            if (edit) {
                const upDoc = doc(getFirestore(), 'templates', edit.id);
                await updateDoc(upDoc, {
                    editedTimestamp: moment().valueOf(),
                    name: v.name,
                    content: v.content
                })
            } else {
                const d = doc(getFirestore(), 'templates', generateCode(10, 'temp'));
                await setDoc(d, {
                    team: team?.id,
                    billingAccount: billingAccount?.id,
                    owner: userDocument.uid,
                    timestamp: moment().valueOf(),
                    name: v.name,

                    content: v.content
                })
            }


            setLoading(false);
            close();
        } catch (error) {
            message.error('Error al crear la plantilla');
            console.log(error);
            setLoading(false);
        }
    }
    return (
        <Modal footer={null} title={null} visible={open} onCancel={() => close()} width={window.innerWidth < 780 ? '95%' : '50%'} >
            <div style={{ padding: '15px 20px' }} className="d-flex flex-column">
                <Typography.Title level={5}>Nueva plantilla</Typography.Title>
                <Form onFinish={CreateTemplate} form={form} initialValues={edit ? edit : {}} layout="vertical" style={{ marginTop: '25px' }}>
                    <Form.Item label="Título" name="name" rules={[{ required: true, message: 'Identifica tu plantilla' }]}>
                        <Input />
                    </Form.Item>
                    <Row justify="space-between">
                        <Typography.Text className="cLabel labelRequired">Mensaje</Typography.Text>
                        <Row wrap={true}>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{cliente}' })}>Nombre del cliente</Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{total}' })}>Total</Tag>
                            <Tag className="clickable" onClick={() => AddReplacer({ replacer: '{moneda}' })}>Moneda</Tag>

                        </Row>
                    </Row>
                    <Form.Item style={{ marginTop: '15px' }} label="" name="content" rules={[{ required: true, message: 'Añade un mensaje' }]}>
                        <Input.TextArea rows={5} showCount />
                    </Form.Item>

                    <Row justify="center" style={{ marginTop: '25px' }}>
                        <Button loading={loading} htmlType="submit" type="primary">{edit ? "Actualizar" : "Guardar"}</Button>
                    </Row>
                </Form>

            </div>
        </Modal>
    );
}

export default NewTemplate;