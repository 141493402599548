import { Button, Row, Tag, Typography } from "antd"
import { BiDownload } from "react-icons/bi"
import { returnColorOnStatist } from "../../app/pages/InsigthsPage"
import { FiExternalLink, FiSettings } from "react-icons/fi"

const AppCard = ({ integration, setupIntegration, externalLink = true }) => {

    const { name, logo, completed, description, id } = integration

    return <div className="shadowOnHover" style={{ border: '1px solid #E9E9E9', width: '100%', borderRadius: '10px', margin: '7px 0px' }}>
        <div style={{ padding: '15px 20px' }}>
            <Row justify="space-between">
                <div style={{ height: '55px', width: '55px', backgroundImage: `url(${logo})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', marginBottom: '10px', borderRadius: '10px' }}></div>
                {externalLink && <FiExternalLink className="clickable" style={{ color: "#ababab", fontSize: '16px' }} />}
            </Row>

            <div>
                <Typography.Title level={5} style={{ margin: 0 }}>{name}</Typography.Title>
                <Typography.Text type="secondary" style={{ margin: 0 }}>{description}</Typography.Text>
            </div>
        </div>
        <div style={{ borderTop: '1px solid #E9E9E9', padding: '10px 20px' }}>
            <Row align="middle" justify="space-between">
                <Button disabled={integration?.disabled} type={completed ? 'default' : 'primary'} onClick={() => setupIntegration(id)} icon={completed ? <FiSettings style={{ marginRight: '5px' }} /> : <BiDownload style={{ marginRight: '5px' }} />}>
                    {integration?.disabled ? 'Proximamente' : completed ? 'Configurar' : 'Instalar'}
                </Button>
                <Tag color={returnColorOnStatist(completed ? 'activeLight' : 'inactiveLight')}>
                    <Typography.Text style={{ color: returnColorOnStatist(completed ? 'active' : 'inactive') }}>{completed ? 'Activada' : 'Inactiva'}</Typography.Text>
                </Tag>
            </Row>
        </div>
    </div>
}

export default AppCard