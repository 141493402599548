import { CloseOutlined } from "@ant-design/icons"
import { Drawer, Row, Space, Typography } from "antd"
import AppCard from "../cards/AppCard"
import { useContext, useState } from "react"
import TeamContext from "../../context/teamcontext/TeamContext"
import stripeLogo from '../../assets/images/stripeSquare.png'
import satLogo from '../../assets/images/logoSatSquare.png'
import speiLogo from '../../assets/images/speiSquare.png'
import hilosLogo from '../../assets/images/hilosSquare.png'
import ConectModal from "../modals/ConectModal"
import FacturapiModal from "../integrations/FacturapiModal"
import BankModal from "../integrations/BankModal"
import HilosModal from "../integrations/HilosModal"
import openpayLogo from '../../assets/images/logoopenpaySquare.jpg'
import UserContext from "../../context/usercontext/UserContext"
import OpenpayConnectModal from "../modals/OpenpayConnectModal"

const DrawerApps = ({ open, close }) => {

    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    // const { billingAccount } = useContext(BillingAccountContext)

    const availableIntegrations = [
        { id: 'stripe', name: 'Stripe', description: 'Cobra a tus clientes con stripe', logo: stripeLogo, logoHeight: 30, completed: team?.stripe?.completed },
        { id: 'openpay', name: 'Openpay', description: 'Cobra a tus clientes con Openpay', logo: openpayLogo, logoHeight: 30, completed: team?.stripe?.completed },
        { id: 'facturapi', name: 'SAT', description: 'Emite facturas desde Gigstack', logo: satLogo, logoHeight: 20, completed: team?.facturapi?.completed },
        { id: 'spei', name: 'SPEI', description: 'Recibe transferencias vía SPEI', logo: speiLogo, logoHeight: 18, completed: team?.bank?.completed },
        { id: 'hilos', name: 'Hilos', description: 'Envía mensajes de Whatsapp a tus clientes', logo: hilosLogo, logoHeight: 20, completed: team?.hilos?.completed },
    ]

    const [integrationOpen, setIntegrationOpen] = useState(null)

    const setupIntegration = (integrationId) => {
        switch (integrationId) {
            case 'stripe':
                setIntegrationOpen('stripe')
                break;
            case 'facturapi':
                setIntegrationOpen('facturapi')
                break;
            case 'spei':
                setIntegrationOpen('spei')
                break;
            case 'hilos':
                setIntegrationOpen('hilos')
                break;
            default:
                break;
        }
    }

    return <Drawer visible={open} closable={false} drawerStyle={{ borderRadius: '5px' }} width={window.innerWidth < 780 ? '60%' : '30%'} contentWrapperStyle={{ backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
        close()
    }} footer={null}>
        {integrationOpen === 'stripe' && <ConectModal open={integrationOpen === 'stripe'} close={() => setIntegrationOpen(null)} />}
        {integrationOpen === 'openpay' && <OpenpayConnectModal open={integrationOpen === 'openpay'} close={() => setIntegrationOpen(null)} />}
        {integrationOpen === 'facturapi' && <FacturapiModal open={integrationOpen === 'facturapi'} close={() => setIntegrationOpen(null)} edit={availableIntegrations.find(i => i.id === 'facturapi')?.completed} />}
        {integrationOpen === 'spei' && <BankModal open={integrationOpen === 'spei'} close={() => setIntegrationOpen(null)} />}
        {integrationOpen === 'hilos' && <HilosModal open={integrationOpen === 'hilos'} close={() => setIntegrationOpen(null)} userDocument={user} />}
        <Row justify="space-between">
            <Typography.Title level={4}>Aplicaciones</Typography.Title>
            <CloseOutlined onClick={() => close()} />
        </Row>
        <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text type="secondary">Instala las aplicaciones para que la administración de tu negocio corra en automático</Typography.Text>
            {availableIntegrations?.map((integration, index) => {
                if (integration.id === 'stripe' && (team?.integrationsBlocked ?? []).includes('stripe')) {
                    return <></>
                }
                if (integration.id === 'openpay') {
                    return <></>
                }
                if (integration.id === 'hilos' && (team?.integrationsBlocked ?? []).includes('hilos')) {
                    return <></>
                }
                if (integration.id === 'spei' && (team?.integrationsBlocked ?? []).includes('bank')) {
                    return <></>
                }
                return <AppCard key={index} integration={integration} setupIntegration={setupIntegration} />
            })}
        </Space>
    </Drawer>
}

export default DrawerApps