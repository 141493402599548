import { Col, Form, Row, TimePicker, Tooltip, Typography } from 'antd';

import React from 'react';

/**
 * It renders a calendar with 30 days, and when you click on a day, it updates the state of the parent
 * component
 * @returns A function that returns a react component.
 */
function MonthlyRecurring({ onDay = null, updateState = () => { } }) {

    const CalendarDay = ({ day = 0 }) => {
        const isActive = onDay === day;
        return <Tooltip title={null}>
            <div onClick={() => {
                if (isActive) {
                    updateState({ onDay: null });
                } else {
                    updateState({ onDay: day });
                }
            }} className="clickable itemHoverPrimaryBorder" style={{ height: '25px', width: '25px', borderRadius: '50%', backgroundColor: isActive ? '#8666FF' : 'white', margin: '0px 5px', border: '1px solid #f7f7f7', marginTop: '1px' }}>
                <Row justify='center' align='middle'>
                    <Typography.Text style={{ color: isActive ? 'white' : 'black' }}>{day}</Typography.Text>
                </Row>
            </div>
        </Tooltip>
    }
    return (
        <>
            <Col xs={24} lg={12}>
                <Form.Item label="Día del mes">
                    <Row wrap>
                        {[...Array(30).keys()].map(i => <CalendarDay key={i + 1} day={i + 1} />)}



                    </Row>
                </Form.Item>

            </Col>
            <Col xs={24} lg={12}>
                <Form.Item name="onTime" label="Hora" style={{ width: '100%' }}>
                    <TimePicker className="pro-input" format={'HH:mm'} placement="bottomLeft" onChange={(v) => {
                        if (v) {
                            updateState({ onTime: v.format('HH:mm') })
                        } else {
                            updateState({ onTime: null })
                        }
                    }} />
                </Form.Item>
            </Col>
        </>
    );
}

export default MonthlyRecurring;