import {
    EditOutlined, InfoCircleOutlined,
    LoadingOutlined,// PlusOutlined ,CheckOutlined,
} from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import { doc, getFirestore } from 'firebase/firestore';
import React, { useContext } from 'react'
import { useFirestoreDocData } from 'reactfire';
import TeamContext from '../../context/teamcontext/TeamContext';
import { useUserAbilities } from '../../customHooks/userAbilities';


function StripeAutomaticInvoicingSetting({ userDocument, customElementOn, openConfigure }) {

    // TODO Review Element Holder
    const { team } = useContext(TeamContext)

    const { canSendInvoices, isStripeConnected } = useUserAbilities({ userDocument: userDocument, team })
    const ref = doc(getFirestore(), 'teams', team?.id)
    const { status, data } = useFirestoreDocData(ref)


    if (status === 'loading') {
        return <LoadingOutlined />
    }




    if (customElementOn) {
        return customElementOn
    }

    return (
        <Col xs={24} style={{ marginTop: '20px', paddingLeft: '30px' }}>

            <Row align="middle">
                {/* <Row align='middle'>
                    <div onClick={() => {
                        if (!isStripeConnected || !canSendInvoices) {
                            return
                        }
                        // updateDoc(ref, {
                        //     stripeAutomaticInvoicing: !data.data().stripeAutomaticInvoicing
                        // })

                        openConfigure(true)
                    }} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px', cursor: ((!isStripeConnected || !canSendInvoices) ? 'not-allowed' : 'pointer') }}>
                        {userDocument?.stripeAutomaticInvoicing ? <EditOutlined /> : <EditOutlined style={{ display: '' }} />}
                    </div>
                    <Switch defaultChecked={data?.stripeAutomaticInvoicing} checked={data?.stripeAutomaticInvoicing} onChange={(v) => {
                        updateDoc(ref, {
                            stripeAutomaticInvoicing: v
                        })
                    }} />
                </Row> */}
                <div onClick={() => {
                    if (!isStripeConnected || !canSendInvoices) {
                        return
                    }
                    // updateDoc(ref, {
                    //     stripeAutomaticInvoicing: !data.data().stripeAutomaticInvoicing
                    // })

                    openConfigure(true)
                }} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px', cursor: ((!isStripeConnected || !canSendInvoices) ? 'not-allowed' : 'pointer') }}>
                    {team?.stripe?.automaticInvoicing ? <EditOutlined /> : <EditOutlined style={{ display: '' }} />}
                </div>

                <div className='d-flex flex-column' style={{ flex: 1, paddingLeft: '0px' }}>
                    <Typography.Text style={{ fontSize: '14px' }}>Facturar pagos automáticamente ({data?.stripe?.automaticInvoicing ? 'Activado' : 'No activado'})</Typography.Text>
                    <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>{data?.stripe?.automaticInvoicing ? 'Se realizará una factura o recibo de venta en cada pago que recibas en Stripe en la cuenta conecta.' : "Factura los pagos recibidos fuera de gigstack de forma automática."}</Typography.Text>
                    {(!isStripeConnected || !canSendInvoices) &&
                        <Row align='middle' style={{ marginTop: '5px', color: '#cecece' }}>
                            <InfoCircleOutlined style={{ marginRight: '5px' }} />
                            <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'> {`Para activar esta opción, primero debes ${!isStripeConnected ? 'conectar tu cuenta de Stripe' : ''} ${!canSendInvoices ? 'conectar tus certificados para facturar en "Emitir facturas"' : ''}`} .</Typography.Text>
                        </Row>}
                </div>
            </Row>
        </Col>
        // <Row align="middle">
        //     <Switch
        //         defaultChecked={userDocument?.stripeAutomaticInvoicing ?? false}
        //         onChange={async (v) => {
        //             updateDoc(ref, {
        //                 stripeAutomaticInvoicing: v
        //             })
        //         }}
        //     />
        //     <Typography.Text style={{ marginLeft: '10px' }}>Activar facturación automática de los pagos de stripe</Typography.Text>
        // </Row>
    );
}

export default StripeAutomaticInvoicingSetting;