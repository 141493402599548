import { BarcodeOutlined, BellOutlined, BookOutlined, BuildOutlined, ContainerOutlined, DollarOutlined, DownOutlined, HomeOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusCircleOutlined, SendOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, Layout, Menu, message, Popover, Row, Typography } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { doc, getDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, useFirestore, useFirestoreDocDataOnce } from 'reactfire';
import { useMembershipOnce } from '../../customHooks/useMembershipOnce';
// import { useUserAbilities } from '../../customHooks/userAbilities';

import SetupAccount from '../../widgets/auth/SetupAccount';
import CreditsCard from '../../widgets/cards/CreditsCard';
import PayAsYouCard from '../../widgets/cards/PayAsYouCard';
import DynamicModalForm from '../../widgets/modals/DynamicModalForm';
import { FinishStripeConnection, generateCode, SearchParamInURL } from '../functions/helpers';
import MainLayoutRender from './MainLayoutRender';

import UserContext from '../../context/usercontext/UserContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';

import { useUserPermissions } from '../../customHooks/userPermissions';
import { LoadingFullScreenFirstTime } from '../appStates/LoadingFullScreen';
import ModalContext from '../../context/modalcontext/ModalContext';
import SessionBlocked from './SessionBlocked';
import ReviewModal from '../../widgets/modals/ReviewModal';
import ReviewModalOnboarding from '../../widgets/modals/ModalReviewOnboarding';
import SearchModal from '../../widgets/search/SearchModal';
import BannerConvert from '../../widgets/modals/BannerConvert'
import TeamsSelectorPopover from '../../widgets/teams/TeamsSelectPopover';
// import SessionBlocked from './SessionBlocked';

// const signOut = auth => auth.signOut().then(() => window.location.replace('/login'));

/**
 * It returns a `<div>` with a `<Row>` inside, which contains a `<MenuUnfoldOutlined>` and a
 * `<Dropdown>` component
 * @returns A function that returns a div with a row that contains a menu icon and a dropdown with the
 * user's name and avatar.
 */
const Header = ({ setCollapseSider, collapseSider, auth: authData, changeRender }) => {

    // const auth = useAuth();
    // const fs = useFirestore();

    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const { user: userDocument } = useContext(UserContext)
    // const { user: userDocument } = useContext(UserContext)
    // const { handleOpenCreateTeamModal } = useContext(ModalContext)
    // console.log(teamsList)
    const { logo, alias } = team?.brand ?? { logo: team?.logo, alias: team?.brand?.alias }

    const [setshowSearch, setSetshowSearch] = useState(false)

    const [/*items*/, setItems] = useState([])

    // const handleMenuClick = async (e) => {
    //     if (e.key === 'logout') {
    //         await signOut(auth);
    //         window.location.reload();
    //     } else if (e.key === 'createTeam') {
    //         // return notification.info({
    //         //     message: 'Muy pronto',
    //         // })

    //         handleOpenCreateTeamModal()
    //     } else {

    //         getCurrentTeam(e.key)
    //         var lastTeam = [].find((team) => team?.id === e.key)
    //         await updateDoc(doc(fs, 'users', userDocument?.uid), {
    //             lastTeamViewed: e.key,
    //             lastBillingAccountViewed: lastTeam?.billingAccount
    //         })
    //         getBillingAccount(team?.billingAccountId)
    //         return window.location.reload();
    //     }
    // }

    useEffect(() => {
        //ADD LISTENER FOR COMAND F TO OPEN SEARCH
        var config = {
            selector: "#newFeats",
            account: "xaVPV7"
        };
        window?.Headway?.init(config);
        const handleKeyDown = (e) => {
            if ((e.keyCode === 114 || e.keyCode === 70) && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                setSetshowSearch(true)
            }
        }
        window?.addEventListener('keydown', handleKeyDown);
        let newItems = [].map((team) => {
            return {
                label: <Typography.Text>{team?.brand?.alias}</Typography.Text>,
                key: team?.id,
                icon: <Avatar size={15} src={team?.brand?.logo} />,
            }
        })
        if (billingAccount?.admins?.includes(userDocument?.uid) && ((billingAccount?.subscription?.plan?.amount ?? 0) / 100) > 0) {
            newItems.push({
                label: <Typography.Text style={{ color: "#8666FF", fontWeight: 600 }}>Crear marca</Typography.Text>,
                key: 'createTeam',
                icon: <PlusCircleOutlined style={{ color: "#8666FF" }} />,
            })
        }
        newItems.push({
            label: <Typography.Text>Cerrar sesión</Typography.Text>,
            key: 'logout',
            icon: <LogoutOutlined />,
        })
        setItems(newItems)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const untilDate = billingAccount?.manualPaid?.until;

    const oneWeekBeforeUntil = moment(untilDate).subtract(7, 'days').valueOf();
    const [showBanner, setShowBanner] = useState(false);
    const isLessThan7Days = (moment().valueOf() > oneWeekBeforeUntil) && (moment().valueOf() < untilDate)

    useEffect(() => {
        if (isLessThan7Days) {
            setShowBanner(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [untilDate]);

    return <div style={{ width: '100%', height: '100%', }}>
        {setshowSearch && <SearchModal open={setshowSearch} close={() => setSetshowSearch(false)} changeRender={(v) => changeRender(v)} />}
        <div style={showBanner ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } : { margin: '15px 0px' }}>
            {showBanner && <BannerConvert authData={authData} billingAccountId={billingAccount?.id} />}

            <Row justify={window.innerWidth < 780 ? 'space-between' : "end"} align="middle" style={{ height: '100%', padding: '0px 10px' }}>

                {window.innerWidth < 780 && <MenuUnfoldOutlined onClick={() => setCollapseSider(!collapseSider)} style={{ marginLeft: '20px' }} />}

                <Row align='middle' id="newFeats">
                    <div className='d-flex flex-column clickable hoverBorderPrimary' id="HW_badge_cont " style={{ borderRadius: '50%', border: '0.5px solid #cecece', height: '28px', width: '28px', justifyContent: 'center', alignItems: 'center' }}>

                        <span id="">
                            <BellOutlined id="HW_badge" className='secondary' />
                        </span>

                    </div>
                    {/* <div className='d-flex flex-column clickable hoverBorderPrimary' onClick={() => {
                        setSetshowSearch(true)
                    }} style={{ borderRadius: '50%', border: '0.5px solid #cecece', height: '28px', width: '28px', justifyContent: 'center', alignItems: 'center' }}>

                        <SearchOutlined className='secondary' />
                    </div> */}
                    <Divider type="vertical" />
                    {/* <Dropdown onClick={() => { }} overlay={<Menu
                        onClick={handleMenuClick}
                        items={items}
                    />}>
                        
                    </Dropdown> */}

                    <Popover
                        content={<TeamsSelectorPopover />}
                        trigger="hover"
                        placement="bottomRight"
                        open={false}
                        onOpenChange={(e) => console.log(e)}
                        arrow={false}
                    >
                        <div className="d-flex flex-column clickable" style={{
                            backgroundColor: 'white',
                            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.05)",
                            padding: '5px 10px',
                            borderRadius: '10px'
                        }} >
                            <Row align="middle">
                                <Avatar size={30} src={logo ?? ''} >{alias?.substring(0, 1) ?? team?.legalName?.substring(0, 1)}</Avatar>
                                <div className='d-flex flex-column' style={{ marginLeft: '5px' }}>
                                    <Typography.Text style={{ margin: 0 }}>{team?.brand?.alias}</Typography.Text>
                                    <Typography.Text type='secondary' style={{ fontSize: '10px' }}>{user?.name}</Typography.Text>
                                </div>
                                <DownOutlined className='secondary' style={{ fontSize: '10px', marginLeft: '5px' }} />

                            </Row>

                        </div>
                    </Popover>
                </Row>

            </Row>
        </div>
    </div>




}

function LoggedInLayout({ auth: authData, children }) {
    const { getCurrentTeam, team, getTeamsList } = useContext(TeamContext);
    const { user, getUserData } = useContext(UserContext);

    //PARAMS 
    const { tab } = useParams();
    const subtab = SearchParamInURL('subtab');

    // const fechaActual = moment();
    const fechaHaceUnMes = moment().add(-1, 'month').valueOf();
    const fechaSetup = team?.setupAt;

    const fechaHaceUnaSemana = moment().add(-7, 'days').valueOf();
    const fechaHaceMasDeUnaSemana = moment().add(-28, 'days').valueOf();
    const fechaFinishOnboarding = team?.setupCompleteAt
    //CONTEXTS

    // const { data: showReviewModal } = useRemoteConfigValue("showNPSModal");

    const [review, setReview] = useState(false)
    // const { data: reviewModal } = useRemoteConfigValue("showNPSModalOnboarding");

    const [reviewOnboarding, setReviewOnbiarding] = useState(false)
    const { getBillingAccount, billingAccount } = useContext(BillingAccountContext);
    const { handleSetModalTeam, handleSetModalBillingAccount, handleSetModalUser } = useContext(ModalContext);

    const [viewLoading, setViewLoading] = useState(true)
    useEffect(() => {

        if (billingAccount && team) {
            setViewLoading(false)
        }


    }, [billingAccount, team])



    useEffect(() => {
        const showModalValidation = !user?.review && team?.setupComplete && (fechaSetup < fechaHaceUnMes)
        const showModalValidationOnboarding = !user?.reviewOnboarding && team?.setupComplete && (fechaFinishOnboarding < fechaHaceUnaSemana) && (fechaFinishOnboarding > fechaHaceMasDeUnaSemana)
        setReview(showModalValidation)
        setReviewOnbiarding(showModalValidationOnboarding)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, team?.id])



    const {
        credits,

        membership,
        payAsYouGo } = useMembershipOnce({ uid: authData.uid, billingAccountId: billingAccount?.id })







    /**
     * It takes the message from the server, parses it, and then checks if the user has a document. If they
     * do, it loops through the modals in the message, and if the modal is not already in the modalsMap, it
     * adds it
     */
    // const ManageDynamicModal = () => {
    //     const d = JSON.parse(messageValue.asString())
    //     if (d) {
    //         if (userDocument) {
    //             (d ?? {}).modals?.forEach((modal) => {

    //                 if (modalsMap.findIndex((m) => m.key === modal.keyOnUser) === -1) {
    //                     modalsMap.push({
    //                         closed: false,
    //                         showable: userDocument[(modal?.keyOnUser)] === modal?.valueIs || (modal?.valueIs === null && !userDocument[(modal?.keyOnUser)]),
    //                         key: modal.keyOnUser,
    //                         data: modal
    //                     })
    //                 }

    //             })
    //         }
    //     }
    // }


    //STATES

    const [collapseSider, setCollapseSider] = useState(false)
    const [render, setrender] = useState(tab ?? 'home');
    const [loaded, setLoaded] = useState(false)
    const auth = useAuth();


    //USER DOCUMENTS
    const ref = doc(useFirestore(), 'users', authData?.uid ?? '-');
    // const membership = doc(useFirestore(), 'memberships', authData?.uid ?? '-');
    const { data: userDocument } = useFirestoreDocDataOnce(ref);
    // const { data: membershipDocument } = useFirestoreDocData(membership);
    // const { credits } = membershipDocument ?? { credits: 0 };

    // const { data: messageValue } = useRemoteConfigValue("initQuestionsForm");

    const [modalsMap, setmodalsMap] = useState([])

    const fs = useFirestore();







    // const { canSendInvoices,
    //     isStripeConnected,
    //     isWoocommerceConnected,
    //     stripeAutoInvoiceActive
    // } = useUserAbilities({ userDocument })


    useEffect(() => {
        window.Intercom('boot', {
            app_id: 'acgd4n2q',
            email: userDocument?.email,
            created_at: moment(userDocument?.timestamp).unix(),
            name: userDocument?.name,
            user_id: userDocument?.uid,
            user_hash: userDocument?.intercomHash,
            avatar: {
                'type': 'avatar',
                'image_url': userDocument?.brand?.logo ?? userDocument?.profilePic
            },
            billing_account_id: billingAccount?.id,
            billing_account_name: billingAccount?.legalName ?? billingAccount?.legal_name ?? billingAccount?.name,
            team_id: team?.id,
            team_name: team?.brand?.alias,
            facturapi_completed: team?.facturapi?.completed ?? false,
            stripe_completed: team?.stripe?.completed ?? false,
            openpay_completed: team?.openpay?.completed ?? false,
            whmcs_completed: team?.whmcs?.completed ?? false,
            stripe_auto_invoice_active: team?.stripe?.automaticInvoicing ?? false,
            Credits: credits,
            payasyougo: payAsYouGo,
            Membership: membership?.price_id,
            custom_launcher_selector: '#intercomButtonOpen',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team, billingAccount])





    useEffect(() => {
        //initQuestionsForm



        // ManageDynamicModal()

        // window.Headway.init({
        //     selector: ".headwayapp", // CSS selector where to inject the badge
        //     account: "xaVPV7",
        //     translations: {
        //         title: "Registro de eventos",
        //         readMore: "Leer más",
        //         labels: {
        //             "new": "Novedades",
        //             "improvement": "Actualizaciones",
        //             "fix": "Correcciones"
        //         },
        //         footer: "Ver todos los regzstros 👓"
        //     }

        // }); 

        FinishStripeConnection({
            setLoading: () => {
                message.loading('Conectando cuenta de Stripe...', 10000)
            }, authUser: auth.currentUser,
            teamId: team?.id, billingAccountId: billingAccount?.id
        })

        const getD = async () => {
            const currentUser = await getDoc(doc(fs, 'users', auth.currentUser.uid))
            const billingAccId = currentUser.data()?.lastBillingAccountViewed ?? (currentUser.data()?.billingAccounts ? currentUser.data()?.billingAccounts[0] : '') ?? ''
            const teamId = currentUser.data()?.lastTeamViewed ?? (currentUser.data()?.teams ? currentUser.data()?.teams[0] : '') ?? ''
            getUserData(auth.currentUser.uid)
            getBillingAccount(billingAccId)
            getCurrentTeam(teamId)
            getTeamsList()

            if (billingAccId) {
                const currentBillingAccount = (await getDoc(doc(fs, 'billingAccounts', billingAccId))).data()
                handleSetModalBillingAccount(currentBillingAccount)
            }

            if (teamId) {
                const currentTeam = (await getDoc(doc(fs, 'teams', teamId))).data()
                handleSetModalTeam(currentTeam)
            }


            handleSetModalUser(currentUser.data())
        }
        getD()



        setTimeout(() => {
            setLoaded(true)
        }, 400)


        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDocument])




    /* The above code is checking if the user has a first name, last name, email, uid, country, and
    setupAt. If the user does not have any of these, then the user will be redirected to the
    SetupAccount page. */
    if (!user) {
        return <LoadingFullScreenFirstTime uid={auth.currentUser.uid} />
    }
    if ((user?.timestamp < moment('2023-03-28').valueOf() && !user?.lastBillingAccountViewed)) {
        return <SessionBlocked />
    }
    if (!user?.firstName || !user?.lastName || !user?.email || !user?.uid || !user?.setupAt) {
        return !loaded ? <LoadingFullScreenFirstTime uid={auth.currentUser.uid} /> : <SetupAccount userDocument={userDocument} />
    }

    if (viewLoading) {
        return <LoadingFullScreenFirstTime uid={auth.currentUser.uid} />
    }
    if (!team?.id) {
        return <LoadingFullScreenFirstTime uid={auth.currentUser.uid} />
    }







    /**
     * It returns a div with a sidebar menu
     * @returns A div with a class of sider.
     */
    const SiderMenu = () => {

        const {
            canViewInvoices,
            canViewPayments,
            canViewServices,
        } = useUserPermissions({ user, team, billingAccount })

        let itemsToShow = [
            {
                key: 'home',
                icon: <HomeOutlined />,
                label: 'Dashboard',
            },
            {
                key: 'clients',
                icon: <UserOutlined />,
                label: 'Clientes',
            },
            {
                key: 'services',
                icon: <BarcodeOutlined />,
                label: 'Servicios',
            },
            {
                key: 'payments',
                icon: <DollarOutlined />,
                label: 'Pagos',
            },
            {
                key: 'invoices',
                icon: <BookOutlined />,
                label: 'Facturas',
            },
            {
                key: 'receipts',
                icon: <SendOutlined />,
                label: 'Recibos de venta',
            },
            {
                key: 'collections',
                icon: <ContainerOutlined />,
                label: 'Cobranza',
            },
            // {
            //     key: 'recurrences',
            //     icon: <ReloadOutlined />,
            //     label: 'Recurrencias',
            // },
            // {
            //     key: 'expenses',
            //     icon: <WalletOutlined />,
            //     label: 'Gastos',
            // },
            {
                key: 'settings',
                icon: <BuildOutlined />,
                label: 'Aplicaciones',
            },
        ]

        if (!canViewInvoices) {
            itemsToShow = itemsToShow.filter(item => item.key !== 'invoices')
        }

        if (!canViewPayments) {
            itemsToShow = itemsToShow.filter(item => item.key !== 'payments')
        }

        if (!canViewServices) {
            itemsToShow = itemsToShow.filter(item => item.key !== 'services')
        }

        return <div className="sider" style={{ height: window.innerHeight - 20, maxHeight: window.innerHeight - 20 }}>
            <div style={{ padding: '5px 10px', justifyContent: 'center', alignItems: collapseSider ? 'center' : 'start', width: '100%' }} className="d-flex flex-column">
                <div className='d-flex flex-row' style={{ alignItems: 'center', justifyContent: 'left', width: '100%' }}>
                    <div style={{ width: collapseSider ? '100%' : '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar size={24} src={team?.brand?.logo ?? team?.logo ?? ''} >{team?.brand?.alias?.substring(0, 1) ?? team?.brand?.alias?.substring(0, 1)}</Avatar>
                    </div>
                    <Typography.Title style={{ margin: 0, marginLeft: '5px' }} ellipsis level={5}>{collapseSider ? '' : team?.brand?.alias}</Typography.Title>
                </div>
                <p style={{ margin: 0, fontSize: 9, marginTop: 5, color: '#cecece' }}>{collapseSider ? '' : team?.id ?? ''}</p>
            </div>
            <div style={{ flex: 1, width: '100%', overflow: 'auto', height: 'auto' }} className="d-flex flex-column">
                <Menu
                    theme="light"
                    mode="inline"
                    onClick={(v) => {
                        setrender(v.key ?? 'dashboard')
                        window.history.replaceState(null, `tab`, `/${v.key}`)
                        if (v.key === 'settings') {
                            window.history.replaceState(null, `tab`, `/${v.key}?subtab=integrations`)
                        }
                    }}
                    defaultSelectedKeys={[tab ?? 'home']}
                    selectedKeys={[render]}
                    items={itemsToShow}
                />
                <div align="middle" id="headwayapp" className='headwayapp'>
                    <span id="HW_badge_cont">
                        <span id="HW_badge"></span>
                    </span>
                </div>
            </div>
            <PayAsYouCard authData={authData} collapseSider={collapseSider} billingAccountId={billingAccount?.id} />
            <CreditsCard authData={authData} collapseSider={collapseSider} billingAccountId={billingAccount?.id} />


            <div style={{ padding: '0px 10px', width: '100%', alignItems: 'center', justifyContent: "space-between" }} className="d-flex flex-row" >
                {collapseSider ? <MenuUnfoldOutlined onClick={() => setCollapseSider(false)} /> : <MenuFoldOutlined onClick={() => setCollapseSider(true)} />}
                {!collapseSider ? <div className='d-flex flex-row' style={{ marginLeft: '5px' }} >
                    <div className={`customTag ${render === 'settings' ? 'customTagActive' : ''}`} onClick={() => {
                        setrender('settings')
                        window.history.replaceState(null, `tab`, `/settings`)
                    }} style={{ width: '100%' }}>
                        <SettingOutlined />
                        <Typography.Text style={{ marginLeft: '5px' }}>Ajustes</Typography.Text>
                    </div>
                </div> : <SettingOutlined onClick={() => setrender('settings')} className="clickable" style={{ marginLeft: '10px' }} />}
            </div>
        </div>
    }


    /* Rendering the main layout of the application. */
    return (
        <ErrorBoundary>
            <Layout>
                {review && (<ReviewModal open={review} close={() => setReview(false)} />)}

                {reviewOnboarding && (<ReviewModalOnboarding open={reviewOnboarding} close={() => setReviewOnbiarding(false)} />)}
                {modalsMap ? modalsMap?.map((m) => {
                    return <DynamicModalForm userDocument={userDocument} key={m?.key ?? generateCode(5)} open={m.showable && !m.closed} data={m.data} close={async () => {
                        const newModalsMap = modalsMap.filter((a) => a.key !== m.key)
                        setmodalsMap(newModalsMap)


                    }} />
                }) : null}
                {/* <OnboardingModal userDocument={userDocument} open={(!onboarded && !userDocument?.onboarded)} close={async () => {
                    await updateDoc(ref, { onboarded: moment().valueOf() })
                    setOnboarded(true)
                }} /> */}
                <Layout.Sider trigger={null} collapsible collapsedWidth={window.innerWidth < 780 ? 0 : 100} collapsed={collapseSider} style={{ height: '100vh', width: '200px', position: 'fixed', zIndex: 20, padding: '10px', backgroundColor: '#F8F8F8', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'auto' }}>
                    <SiderMenu />
                </Layout.Sider>

                <Layout className="" style={{ marginLeft: collapseSider ? window.innerWidth < 780 ? '0px' : '100px' : '200px', minHeight: '100vh' }}>

                    <Layout.Header
                        className=""
                        style={{
                            padding: 0,
                            marginTop: 0,
                            position: '',
                            width: collapseSider ? '100%' : window.innerWidth - 200,
                            right: 0,
                            backgroundColor: 'transparent',
                            zIndex: 10
                        }}
                    >

                        <Header auth={authData} setCollapseSider={setCollapseSider} collapseSider={collapseSider} changeRender={(v) => setrender(v)} />

                    </Layout.Header>

                    <Layout.Content
                        className="layoutContent"
                        style={{
                            padding: '10px 24px',
                            minHeight: window.innerHeight - 75,
                            marginTop: '10px'
                        }}
                    >

                        {/*MAIN CONTENT TO RENDER */}

                        <MainLayoutRender userDocument={userDocument} render={render} subtab={subtab} changeRender={(n) => setrender(n)} />
                    </Layout.Content>
                </Layout>
            </Layout>
        </ErrorBoundary>
    );
}

export default LoggedInLayout;