import { PartitionOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd';
import { arrayUnion, doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react'
import { RemoveUndefined, SearchParamInURL } from '../../../app/functions/helpers';
import TeamContext from '../../../context/teamcontext/TeamContext';
import UserContext from '../../../context/usercontext/UserContext';
import SingleImageUpload from '../../uploads/SingleImageUpload';
import { SignedInternalAPIRequest } from '../../../app/functions/APIRequests';
import moment from 'moment';
import { useAuth } from 'reactfire';
import BillingAccountContext from '../../../context/billingaccountcontext/BillingAccountContext';

function TeamOnboarding({ step, setStep }) {
    const fs = getFirestore();
    const { team, getCurrentTeam } = useContext(TeamContext)
    const { user, getUserData } = useContext(UserContext)
    const { getBillingAccount } = useContext(BillingAccountContext)
    // const [brandLogo, setBrandLogo] = useState(null)
    const [loading, setLoading] = useState(false)
    const redirectUrl = SearchParamInURL('redirectUrl');
    const billing = SearchParamInURL('billing');
    const priceId = SearchParamInURL('price_id');
    const code = SearchParamInURL('code');

    const auth = useAuth();
    const [form] = Form.useForm()
    const [formValues, setFormValues] = useState(team || {});

    const SaveTeamData = async (v) => {
        setLoading(true)
        try {
            if (!formValues?.brand?.logo) {
                message.error('Debes subir el logo de tu marca')
                form.setFieldsValue({ logo: null })
                form.setFieldsValue({ primaryColor: v.primaryColor ?? '#8666FF', secondaryColor: v.secondaryColor ?? '#9AEDB6', alias: v.alias ?? null, website: v.website ?? '', instagram: v.instagram ?? '', linkedin: v.linkedin ?? '' })
                setLoading(false)
                return
            }
            const teamObj = {
                ...RemoveUndefined(v),
                id: user?.lastTeamViewed,
                contactEmails: arrayUnion(user?.email),
                supportEmail: user?.email,
                social: {
                    website: v.website ?? '',
                    instagram: v.instagram ?? '',
                    linkedin: v.linkedin ?? ''
                },
                brand: {
                    ...(team?.brand ?? {}),
                    alias: v?.alias ?? null,
                    primaryColor: v.primaryColor ?? '#8666FF',
                    secondaryColor: v.secondaryColor ?? '#9AEDB6',
                    logo: formValues?.brand?.logo ?? null
                }
            }
            await updateDoc(doc(fs, `users/${user.uid}`), {
                onboarded: arrayUnion('teamSetup', 'finishSetup'),
                setupAt: moment().valueOf(),

            }, { merge: true })

            await updateDoc(doc(fs, `teams/${user?.lastTeamViewed}`), {
                ...teamObj,
                setupAt: moment().valueOf(),
            })

            await updateDoc(doc(fs, 'billingAccounts', user.lastBillingAccountViewed), {
                setupAt: moment().valueOf()
            })

            if (redirectUrl && billing && priceId) {
                const session = await SignedInternalAPIRequest({ type: 'subscribe', price: priceId, coupon: billing === 'anual' ? null : code === 'null' ? '' : code, cancelUrl: window.location.href, successUrl: 'https://app.gigstack.pro/settings?subtab=membership' }, 'manageSubscriptionWithCustomerPortal', auth.currentUser)
                window.open(session.session, '_self')
            }

            getUserData(auth?.currentUser.uid)
            getCurrentTeam(user?.lastTeamViewed)
            getBillingAccount(user?.lastBillingAccountViewed)
            setStep(step + 1)
            setLoading(false)
        } catch (error) {
            message.error(error?.message ?? 'Ocurrió un error al guardar los datos')
        }
    }
    return (
        <Form form={form} layout="vertical" initialValues={team} onFinish={SaveTeamData}>
            <Row justify="space-between">
                <p className='russo primary'>gigstack pro</p>
                <Typography.Text type="secondary" style={{ fontSize: '10px' }} className="secondary">4/4</Typography.Text>
            </Row>
            <Row align="middle">
                <PartitionOutlined className="primary" style={{ marginRight: '20px', fontSize: '30px' }} />
                <div className="d-flex flex-column">
                    <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>¡Información de la marca!</Typography.Title>
                    <Typography.Text level={4} type="secondary">Mostraremos esta información a todos tus clientes, podrás crear diferentes marcas y cada una podrá manejar diferentes integraciones.</Typography.Text>
                </div>

            </Row>
            <Divider />

            <Row style={{ width: '100%', marginTop: '20px' }} gutter={{ xs: 12, md: 12 }}>
                <Col xs={24} style={{ marginBottom: '10px' }}>
                    <Typography.Text className='cLabel' >Añade el logo de la empresa</Typography.Text>
                    <div style={{ marginTop: '5px' }}></div>
                    <SingleImageUpload imageUrl={team?.brand?.logo} withFileName={false} uploadTo={`/teams/${user?.lastTeamViewed}/files/logo`} dbRef={`/brands/${user?.lastTeamViewed}`} disableCode={true} saveOnDB={true} defaultOnDb={team?.brand ?? { alias: user?.name }} onSuccess={async (file) => {
                        try {
                            updateDoc(doc(fs, `teams/${user?.lastTeamViewed}`), {
                                ...team,
                                brand: {
                                    ...team.brand,
                                    logo: file.url
                                }
                            }).then(() => {
                                setFormValues(prevValues => ({
                                    ...prevValues,
                                    brand: {
                                        ...prevValues?.brand,
                                        logo: file.url
                                    }
                                }));
                            });
                        } catch (error) {
                            console.log(error)
                        }

                    }} />
                    <Typography.Text type="secondary" style={{ marginTop: '5px', fontSize: '10px' }}>Imagen en jpg, jpeg o png de máximo 2MB</Typography.Text>
                </Col>
                <Col xs={24} >
                    <Form.Item label="Nombre comercial" name="alias" rules={[{ required: true, message: 'Ingresa el nombre de la marca' }]}>
                        <Input placeholder='Mi marca' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} >
                    <Form.Item label="Color primario" name="primaryColor" rules={[{ required: true, message: 'Ingresa el color principal' }]}>
                        <Input type="color" placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}  >
                    <Form.Item label="Color secundario" name="secondaryColor" rules={[{ required: false, message: '' }]}>
                        <Input type="color" placeholder='' />
                    </Form.Item>
                </Col>

                <Col xs={24} lg={24} md={24} style={{ marginBottom: '10px' }}>
                    <Typography.Text className='cLabel'>¿Cuáles son las redes sociales de tu marca?</Typography.Text>
                </Col>
                <Col xs={24} lg={8} md={6}>
                    <Form.Item label="Website" name="website" rules={[{ required: false }]}>
                        <Input placeholder='https://...' type="url" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8} md={6}>
                    <Form.Item label="Instagram" name="instagram" rules={[{ required: false }]}>
                        <Input placeholder='Instagram' type="url" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={8} md={6}>
                    <Form.Item label="LinkedIn" name="linkedin" rules={[{ required: false }]}>
                        <Input placeholder='LinkedIn' type="url" />
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Row justify="end">
                        <Button style={{ marginRight: '15px' }} onClick={() => {
                            setStep(step - 1)

                        }}>Cuenta de facturación</Button>
                        <Button loading={loading} type="primary" htmlType="submit">{(redirectUrl && billing && priceId) ? 'Iniciar suscripción' : 'Continuar al dashboard'}</Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default TeamOnboarding;