import { Table, Typography, Tooltip, Drawer, Row, Col, Tabs, Button, message, Popconfirm } from "antd";
import {
    CopyToClipboard,
    ShareViaWhatsapp,
    getItemsAmounts,
    returnCurrencyValue,
} from "../../app/functions/helpers";
import moment from "moment";
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    LinkOutlined,
    LoadingOutlined,
    MailOutlined,
    UserOutlined,
    WhatsAppOutlined,
} from "@ant-design/icons";
import { useContext, useState } from "react";
import ComposeEmailModal from "../email/ComposeEmailModal";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useAuth, useFirestoreDocData } from "reactfire";
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests";
import TeamContext from "../../context/teamcontext/TeamContext";
import ReviewPaymentModal from "../payments/ReviewPaymentModal";
const PaymentDueForClient = ({ payment, open, close, userDocument }) => {
    const [downloadFile, setDownloadFile] = useState(null)
    const [confirmPayment, setconfirmPayment] = useState(null)

    const fs = getFirestore()
    const auth = useAuth()
    const ref = doc(fs, 'clients', payment.key)
    const { team } = useContext(TeamContext);
    const { data: clientData } = useFirestoreDocData(ref)
    const downloadAccountBalance = async () => {
        try {
            setDownloadFile('accountBalance')
            const resp = await SignedInternalAPIRequest({ clientId: clientData.id, teamId: team.id }, "generateClientAccountBalance", auth.currentUser, { isFile: true, fileName: `${clientData?.rfc ?? "Balance"}-${moment().format('DD/MM/YYYY')}`, fileExtension: 'pdf' }, 'POST')
            console.log("RESP: ", resp)

            setDownloadFile(false)
        } catch (error) {
            console.log("Error: ", error)
            message.error('Error al descargar el archivo')
            setDownloadFile(false)
        }
    }

    const calculateTotalPayment = (payments) => {
        const paymentSums = {};
        for (const payment of payments.filter(p => p.limitDateToPay < moment().valueOf())) {
            const currency = payment.currency.toLowerCase();
            const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

            if (!paymentSums[currency]) {
                paymentSums[currency] = amount;
            } else {
                paymentSums[currency] += amount;
            }
        }

        const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
            return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
        });

        return currencySumStrings.join(', ');
    };
    const totalPayment = calculateTotalPayment(payment.payments);

    const [composeEmail, setComposeEmail] = useState(null);
    // const { team } = useContext(TeamContext);

    const columns = [
        {
            title: <span style={{ color: 'gray' }}>CREACIÓN</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => text ? moment(text).format('DD MMM YYYY H:mm') : null,

        },
        {
            title: <span style={{ color: 'gray' }}>ID DEL PAGO</span>,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <>
                    <Typography.Text copyable style={{ fontSize: "13px" }}>{record.fid}</Typography.Text>
                </>
            ),
        },
        {
            title: <span style={{ color: 'gray' }}>FECHA DE VENCIMIENTO</span>,
            dataIndex: 'limitDateToPay',
            key: 'limitDateToPay',
            render: (text, record) => {
                const limitDateToPay = moment(record.limitDateToPay);
                const today = moment();
                const diasVencidos = today.diff(limitDateToPay, 'days');

                return (
                    <span style={{ fontSize: '13px' }}>
                        {record.limitDateToPay ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography.Text style={{ color: 'red' }}>
                                    Vencido hace {diasVencidos} {diasVencidos === 1 ? 'día' : 'días'}
                                </Typography.Text>

                                <Typography.Text style={{ display: 'block' }}>
                                    {limitDateToPay.format('DD MMM YYYY H:mm')}
                                </Typography.Text>
                            </div>
                        ) : (
                            ''
                        )}
                    </span>

                );
            },
        },

        {
            title: <span style={{ color: 'gray' }}>TOTAL</span>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text, record) => (
                <span style={{ fontSize: '13px' }}>
                    {returnCurrencyValue(
                        getItemsAmounts(
                            record.items ?? record.internalItems,
                            record.paidIn === 'bank'
                        ).total
                    )}{' '}
                    {record.currency}
                </span>
            ),
        },
        {
            title: <span style={{ color: 'gray' }}>ACCIONES</span>,
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Tooltip title="Copiar link de pago">
                        <LinkOutlined
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                                CopyToClipboard(
                                    record.shortURL ?? `https://beta.gigstack.pro/pay/${record.token}`
                                );
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Enviar link por correo">
                        <MailOutlined
                            style={{ marginRight: '5px' }}
                            onClick={() => setComposeEmail(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Enviar por Whatsapp">
                        <WhatsAppOutlined
                            style={{ marginRight: '5px' }}
                            onClick={() =>
                                ShareViaWhatsapp(
                                    `Puedes realizar el pago por ${returnCurrencyValue(
                                        getItemsAmounts(
                                            record.items ?? record.internalItems,
                                            record.paidIn === 'bank'
                                        ).total
                                    )} ${record.currency} en el siguiente enlace! 😉 : ${record.shortURL
                                    }`
                                )
                            }
                        />
                    </Tooltip>

                </div>
            ),
        },
    ];




    const columnsForMonth = [
        {
            title: <span style={{ color: 'gray' }}>CREACIÓN</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => text ? moment(text).format('DD MMM YYYY H:mm') : null,

        },
        {
            title: <span style={{ color: 'gray' }}>ID DEL PAGO</span>,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <Typography.Text copyable style={{ fontSize: "13px" }}>{record.fid}</Typography.Text>
            ),
        },
        {
            title: <span style={{ color: 'gray' }}>de 1 a 30 días</span>,
            dataIndex: 'limitDateToPay',
            key: 'limitDateToPay',
            render: (text, record) => {
                const limitDateToPay = moment(record.limitDateToPay);
                const today = moment();
                const diasVencidos = today.diff(limitDateToPay, 'days');

                return (
                    <span style={{ fontSize: '13px' }}>
                        {record.limitDateToPay && diasVencidos < 30 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography.Text style={{ color: 'red' }}>
                                    Vencido hace {diasVencidos} {diasVencidos === 1 ? 'día' : 'días'}
                                </Typography.Text>

                                <Typography.Text style={{ display: 'block' }}>
                                    {limitDateToPay.format('DD MMM YYYY H:mm')}
                                </Typography.Text>
                            </div>
                        ) : (
                            ''
                        )}
                    </span>
                );

            },
        },
        {
            title: <span style={{ color: 'gray' }}>de 30 a 60 días</span>,
            dataIndex: 'limitDateToPay',
            key: 'limitDateToPay',
            render: (text, record) => {
                const limitDateToPay = moment(record.limitDateToPay);
                const today = moment();
                const diasVencidos = today.diff(limitDateToPay, 'days');

                return (
                    <span style={{ fontSize: '13px' }}>
                        {record.limitDateToPay && diasVencidos >= 30 && diasVencidos <= 60 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography.Text style={{ color: 'red' }}>
                                    Vencido hace {diasVencidos} {diasVencidos === 1 ? 'día' : 'días'}
                                </Typography.Text>

                                <Typography.Text style={{ display: 'block' }}>
                                    {limitDateToPay.format('DD MMM YYYY H:mm')}
                                </Typography.Text>
                            </div>
                        ) : (
                            ''
                        )}
                    </span>
                );
            },
        },
        {
            title: <span style={{ color: 'gray' }}>más de 90 días</span>,
            dataIndex: 'limitDateToPay',
            key: 'limitDateToPay',
            render: (text, record) => {
                const limitDateToPay = moment(record.limitDateToPay);
                const today = moment();
                const diasVencidos = today.diff(limitDateToPay, 'days');

                return (
                    <span style={{ fontSize: '13px' }}>
                        {record.limitDateToPay && diasVencidos > 60 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography.Text style={{ color: 'red' }}>
                                    Vencido hace {diasVencidos} {diasVencidos === 1 ? 'día' : 'días'}
                                </Typography.Text>

                                <Typography.Text style={{ display: 'block' }}>
                                    {limitDateToPay.format('DD MMM YYYY H:mm')}
                                </Typography.Text>
                            </div>
                        ) : (
                            ''
                        )}
                    </span>
                );
            },
        },

        {
            title: <span style={{ color: 'gray' }}>TOTAL</span>,
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount - b.amount,
            render: (text, record) => (
                <span style={{ fontSize: '13px' }}>
                    {returnCurrencyValue(
                        getItemsAmounts(
                            record.items ?? record.internalItems,
                            record.paidIn === 'bank'
                        ).total
                    )}{' '}
                    {record.currency}
                </span>
            ),
        },
        {
            title: <span style={{ color: 'gray' }}>ACCIONES</span>,
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Tooltip title="Copiar link de pago">
                        <LinkOutlined
                            style={{ marginRight: '5px' }}
                            onClick={() => {
                                CopyToClipboard(
                                    record.shortURL ?? `https://beta.gigstack.pro/pay/${record.token}`
                                );
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Enviar link por correo">
                        <MailOutlined
                            style={{ marginRight: '5px' }}
                            onClick={() => setComposeEmail(record)}
                        />
                    </Tooltip>
                    <Tooltip title="Enviar por Whatsapp">
                        <WhatsAppOutlined
                            style={{ marginRight: '5px' }}
                            onClick={() =>
                                ShareViaWhatsapp(
                                    `Puedes realizar el pago por ${returnCurrencyValue(
                                        getItemsAmounts(
                                            record.items ?? record.internalItems,
                                            record.paidIn === 'bank'
                                        ).total
                                    )} ${record.currency} en el siguiente enlace! 😉 : ${record.shortURL
                                    }`
                                )
                            }
                        />
                    </Tooltip>
                    {(record?.status !== 'succeeded' && record?.status !== 'paid') && <Popconfirm okText="Pagado" cancelText="Cancelar" icon={<CheckCircleOutlined />} title="Marcar esta transacción como pagada con efectivo?" onConfirm={() => {
                        updateDoc(doc(getFirestore(), 'payments', record?.fid), {
                            status: 'succeeded',
                            internalStatus: 'succeeded',
                            paidIn: 'bank',
                            binnacle: (record.binnacle ?? '') + `\nPayment marked as paid with cash but use bank to add discount`,
                            payment_form: '01'
                        })
                    }}>
                        <CheckOutlined />

                    </Popconfirm>}
                </div>
            ),
        },
    ];


    const data = payment.payments
        .filter((paymentItem) => paymentItem.limitDateToPay < moment().valueOf())
        .map((paymentItem, index) => ({
            ...paymentItem,
            amount: paymentItem,
            id: paymentItem.id,
            timestamp: paymentItem.timestamp,
            limitDateToPay: paymentItem.limitDateToPay,
            items: paymentItem.items,
            currency: paymentItem.currency,
            shortURL: paymentItem.shortURL,
            token: paymentItem.token,
        }));
    console.log('aca: ', data)
    const ForClient = () => {

        return (<><Table columns={columns} dataSource={data} pagination={false} />
            <Typography.Text style={{ color: 'red', float: 'right', marginRight: '10%', fontSize: '20px' }}>
                Monto Total   {totalPayment}
            </Typography.Text></>)
    }
    const ForMonth = () => {
        return (<>
            {confirmPayment && <ReviewPaymentModal open={confirmPayment} close={() => setconfirmPayment(false)} payment={confirmPayment} />}
            <Table columns={columnsForMonth} dataSource={data} pagination={false} />  <Typography.Text style={{ color: 'red', float: 'right', marginRight: '10%', fontSize: '20px' }}>
                Monto Total   {totalPayment}
            </Typography.Text></>)
    }
    return (
        <Drawer
            visible={open}
            closable={false}
            width={window.innerWidth < 780 ? '100%' : '80%'}
            drawerStyle={{ borderRadius: '20px' }}
            contentWrapperStyle={{
                padding: '10px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            header={null}
            footer={null}
        >

            {composeEmail && (
                <ComposeEmailModal
                    close={() => setComposeEmail(null)}
                    actionText={null}
                    idRef={'payments'}
                    data={{ ...composeEmail, payment: composeEmail }}
                    open={composeEmail}
                    element={composeEmail}
                    subject={`Solicitud de pago por ${getItemsAmounts(
                        composeEmail.items,
                        composeEmail.paidIn === 'bank'
                    ).totalString}`}
                    userDocument={userDocument}
                    readableType={'pago'}
                    client={composeEmail.client ?? { name: 'Sin Cliente', email: '' }}
                />
            )}
            <div style={{ maxHeight: '100%', width: '100%' }} className="d-flex flex-column">
                <Row>
                    <Col xs={24}>
                        <Row justify="space-between">
                            <Row>
                                <Typography.Title level={5}>
                                    {`Información de los pagos vencidos de ${payment.payments[0].client.name}`}
                                </Typography.Title>
                                <Button style={{ margin: '0px 15px 20px' }} onClick={() => {
                                    downloadAccountBalance()
                                    // DownloadXLSX({ endpoint: `gigstackpro.pro.cleaned_payments_view`, type: "payments" })
                                }}  >
                                    <Row align="middle">
                                        {downloadFile === 'accountBalance' ? <LoadingOutlined /> : <UserOutlined />}
                                        <Typography.Text style={{ marginLeft: '5px' }}>Balance</Typography.Text>
                                    </Row>
                                </Button>
                            </Row>
                            <CloseOutlined className="clickable" onClick={() => close()} />
                        </Row>

                        <Tabs onChange={() => { }} type="card">
                            <Tabs.TabPane tab={<Row align="middle">
                                <Typography.Text>{`Cobranza de ${payment.payments[0].client.name}`}</Typography.Text>
                            </Row>} key="forClient">
                                <ForClient />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<Row align="middle">
                                <Typography.Text> {`Cobranza de ${payment.payments[0].client.name} Dividido en meses`}</Typography.Text>
                            </Row>} key="forMonth">
                                <ForMonth />
                            </Tabs.TabPane>
                        </Tabs>

                    </Col>
                </Row>
            </div>
        </Drawer>
    );
};

export default PaymentDueForClient;
