
import React from 'react'
import 'filepond/dist/filepond.min.css';
import { FilePond, registerPlugin } from 'react-filepond';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { message } from 'antd';
import { generateCode } from '../../app/functions/helpers';
import { useAuth } from 'reactfire';
import moment from 'moment';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Register the plugin
registerPlugin(FilePondPluginFileValidateType);


function FileUploader({ maxFiles = 1, storageReference = "", title = '  <p style="margin-bottom:0px; font-size:13px;" class="strong">Selecciona tus archivos</p>', descriptions = '<p style="font-size:12px;">Puedes arrastrar tus archivos o <span class="strong"">buscarlos</span> en tus archivos.</p>', defaultFiles = [], onUpload = () => { }, toDelete = () => { }, dbref = "", acceptedFileTypes = [], updateFiles }) {
    const auth = useAuth()
    const storage = getStorage();

    const server = {
        instantUpload: false,
        /* A function that is called when the FilePond instance has been mounted. */
        load: (source, load) => {
            fetch(source)
                .then(res => res.blob())
                .then(load);
        },
        /* A callback that is called when the FilePond instance has been mounted. */
        mounted: (source) => {
        },
        /* This is the process function of the FilePond server. It is called when a file is added to the
        FilePond instance. */
        process: (fileName, file, metadata, load, error, progress, abort) => {
            //EXTRACT FILE END EXTENSION

            const code = generateCode(5)
            const ext = file.name.split('.').pop() ?? 'jpg'
            const path = `${storageReference}/${code}-${file.name}`
            const reference = ref(storage, path)
            const uploadTask = uploadBytesResumable(reference, file, {
                contentType: file.type,
            });
            uploadTask.on(
                'state_changed',
                snap => {
                    progress(true, snap.bytesTransferred, snap.totalBytes)
                },
                err => {
                    message.error(err.message)
                },
                async () => {
                    try {

                        const url = await getDownloadURL(reference)
                        onUpload({
                            "status": "done",
                            "originFileObj": null,
                            "documentPDF": false,
                            "clientID": null,
                            "path": path,
                            "code": code,
                            "owner": auth.currentUser.uid,
                            "related": null,
                            "type": file.type ?? ext,
                            "timestamp": moment().valueOf(),
                            "lastModified": moment().valueOf(),
                            "dbref": dbref,
                            "percent": "100.00",
                            "url": url,
                            "lastModifiedDate": moment().valueOf(),
                            "uid": `editorjs-upload-${code}`,
                            "name": file.name,
                            "size": file.size,
                            "hasOlder": null
                        })
                        load(path)

                    } catch (error) {
                        message.error(error.message)
                    }
                }
            )
        },
        /* A callback that is called when a file is removed from the FilePond instance. */
        removed: (source, load, error) => {

        },

        /* Deleting the file from the storage. */
        revert: (id, load) => {
            const reference = ref(storage, id)
            toDelete(id)
            deleteObject(reference)
                .then(() => {
                    message.info('Eliminado')
                })
                .catch(err => {
                    message.error(err.message)
                })
        },
    }
    /* Creating a FilePond component that allows multiple files to be uploaded. It also has a label that
    shows the title and descriptions of the question. */

    return (

        <FilePond
            allowMultiple={true}
            acceptedFileTypes={acceptedFileTypes}
            allowFileTypeValidation={acceptedFileTypes.length > 0}
            labelFileTypeNotAllowed={'Archivo no permitido'}
            files={defaultFiles || []}
            maxFiles={maxFiles}
            onupdatefiles={updateFiles}
            server={server}
            labelMaxFileSize="Tamaño máximo de la imágen: {filesize}"
            labelMaxTotalFileSizeExceeded="El tamaño total de las imágenes supera el límite"
            labelMaxTotalFileSize="Tamaño máximo total de las imágenes: {filesize}"
            labelIdle={`<div class="d-flex flex-column">
              ${title}
                ${descriptions}
                </div>`}

        />
    );
}

export default FileUploader;
