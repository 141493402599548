import { Col, Form, Row, TimePicker, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React from 'react';

/**
 * It renders a row of 7 circles, each representing a day of the week, and a time picker
 * @returns A React component.
 */
function WeeklyRecurring({ onWeekday = null, updateState = () => { } }) {

    const CalendarDay = ({ day = 0 }) => {
        const isActive = onWeekday === day;
        const dayString = day === 0 ? 'D' : day === 1 ? 'L' : day === 2 ? 'M' : day === 3 ? 'M' : day === 4 ? 'J' : day === 5 ? 'V' : day === 6 ? 'S' : 'D';
        const fullDayName = moment().day(day).format('dddd');
        return <Tooltip title={fullDayName}>
            <div onClick={() => {
                if (isActive) {
                    updateState({ onWeekday: null });
                } else {
                    updateState({ onWeekday: day });
                }
            }} className="clickable itemHoverPrimaryBorder" style={{ height: '25px', width: '25px', borderRadius: '50%', backgroundColor: isActive ? '#8666FF' : 'white', margin: '0px 5px', border: '1px solid #f7f7f7' }}>
                <Row justify='center' align='middle'>
                    <Typography.Text style={{ color: isActive ? 'white' : 'black' }}>{dayString}</Typography.Text>
                </Row>
            </div>
        </Tooltip>
    }
    return (
        <>
            <Col xs={24} lg={12}>
                <Form.Item label="Día de la semana">
                    <Row>

                        <CalendarDay day={0} />
                        <CalendarDay day={1} />
                        <CalendarDay day={2} />
                        <CalendarDay day={3} />
                        <CalendarDay day={4} />
                        <CalendarDay day={5} />
                        <CalendarDay day={6} />

                    </Row>
                </Form.Item>

            </Col>
            <Col xs={24} lg={12}>
                <Form.Item name="onTime" label="Hora" style={{ width: '100%' }}>
                    <TimePicker className="pro-input" format={'HH:mm'} placement="bottomLeft" onChange={(v) => {
                        if (v) {
                            updateState({ onTime: v.format('HH:mm') })
                        } else {
                            updateState({ onTime: null })
                        }
                    }} />
                </Form.Item>
            </Col>
        </>
    );
}

export default WeeklyRecurring;