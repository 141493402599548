import { CloseOutlined, CopyOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { IoChevronBack } from 'react-icons/io5';
import { Col, Divider, Form, message, Row, Switch, Tag, Typography } from 'antd';
import { collection, doc, getFirestore, query, updateDoc, where } from 'firebase/firestore';

import React, { Suspense, useContext, useState } from 'react'
import { useAuth, useFirestoreCollectionData } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { CopyToClipboard } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import EmptyTable from '../states/EmptyTable';
import CreateWebhookModal from '../modals/CreateWebhook';
import moment from 'moment';
import { returnColorOnStatist } from '../../app/pages/InsigthsPage';
import { useMembership } from '../../customHooks/useMembership';


function APISettings({ userDocument }) {
    const auth = useAuth()
    const { team, getCurrentTeam } = useContext(TeamContext);
    const { payingAmount, currency, canCallAPI } = useMembership({ uid: auth.currentUser?.uid, billingAccountId: team?.billingAccount });

    const haveAPIAccess = (payingAmount >= 19900 && currency === 'usd') || (payingAmount >= 350000 && currency === 'mxn') || canCallAPI

    const q = query(collection(getFirestore(), "apikeys"), where("team", "==", team.id), where('valid', '==', true),);
    const { status: apikeysDBStatus, data: apikeysData } = useFirestoreCollectionData(q, {
        idOnDB: "id",
    })
    const [form] = Form.useForm();

    const [loading, setloading] = useState(false)
    const [showCreateWebhook, setShowCreateWebhook] = useState(false)
    const [viewWebhookDetails, setViewWebhookDetails] = useState(null)
    /**
     * It requests the API keys from the server.
     * @returns const RequestAPIKeys = async () => {
     *         //generateAPIKeys  
     *         return await SignedInternalAPIRequest({}, 'generateAPIKeys', auth.currentUser)
     */

    const RequestAPIKeys = async () => {
        //generateAPIKeys  
        try {
            await SignedInternalAPIRequest({
                team: team?.id
            }, 'generateAPIKeys', auth.currentUser)
        } catch (error) {
            message.error(error?.message ?? 'Ocurrió un error.')
        }

    }


    /**
     * It cancels the API keys for the current user.
     * @returns a promise that resolves to the response from the server.
     */
    const RequestCancelAPIKeys = async () => {
        //generateAPIKeys  
        return await SignedInternalAPIRequest({
            team: team?.id
        }, 'cancelAPIKeys', auth.currentUser)

    }




    const ApiData = () => {
        return <Row style={{ width: '100%' }}>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>API Keys</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                {apikeysDBStatus === 'loading' ? <LoadingOutlined /> : <Row gutter={{ xs: 10, md: 12, lg: 17 }} style={{ width: '100%' }}>
                    <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                        {apikeysData.map((a, inx) => {
                            return <Row align="middle" style={{ width: '100%', marginTop: inx === 0 ? '0px' : '10px' }} key={a.keyid}>
                                <div onClick={() => {
                                    if (!a.valid) { return }
                                    CopyToClipboard(a.apikey)
                                }} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                    {a.valid ? <CopyOutlined /> : <CloseOutlined />}
                                </div>
                                <div className='d-flex flex-column' style={{ flex: 1 }}>
                                    <Typography.Text style={{ fontSize: '14px' }}>{a.keyid}</Typography.Text>

                                </div>
                            </Row>
                        })}
                        <Row align="middle" style={{ width: '100%', marginTop: '30px' }} >
                            <div onClick={async () => {
                                try {
                                    setloading(true)
                                    if (apikeysData.length > 0) {
                                        await RequestCancelAPIKeys()
                                    }
                                    await RequestAPIKeys()

                                    return setloading(false)
                                } catch (error) {
                                    message.error('Ocurrió un error.')
                                    return setloading(false)
                                }

                            }} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Generar nuevas llaves</Typography.Text>
                                <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Las claves activas serán eliminadas</Typography.Text>
                            </div>
                        </Row>
                    </Col>
                </Row>}
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}></Col>



        </Row>
    }

    const WebhooksView = () => {

        return <Row style={{ width: '100%' }}>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Webhooks</Typography.Text>
            </Col>

            {!haveAPIAccess ? <Row>
                <Typography.Text style={{ fontSize: '14px', marginLeft: '10px' }}>Para poder usar webhooks necesitas tener un plan de pago superior a 199 USD.</Typography.Text>
            </Row> : viewWebhookDetails ? <WebhookDetails /> : <WebhooksList />}
        </Row>
    }
    // const handleSwitchChange = async () => {
    //     try {            
    //      await updateDoc(doc(getFirestore(), 'teams', team?.id), {
    //         status: 'inactive'    
    //      })
    //       console.log('Estado actualizado correctamente en Firestore');
    //     } catch (error) {
    //       console.error('Error al actualizar el estado en Firestore:', error);
    //     }
    //   };
    const handleSwitchChange = async (wh) => {
        try {
            let webhooks = [...team?.webhooks]
            webhooks = webhooks.map((w) => {
                if (w.id === wh.id) {
                    return {
                        ...w,
                        status: wh.status === 'active' ? 'inactive' : 'active'
                    }
                }
                return w
            })
            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                webhooks: webhooks
            })
            getCurrentTeam(team?.id)
        }
        catch (error) {
            console.log(error)
        }
    }




    const WebhooksList = () => {
        return <Col xs={24} md={12} lg={12}>
            {(team?.webhooks ?? [])?.length > 0 ? <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                <Row justify='start' align='middle'>
                    <div className='customCircledTag' style={{ width: '30px', height: '30px' }} onClick={() => setShowCreateWebhook(true)}>
                        <PlusOutlined />
                    </div>
                    <Typography.Text style={{ fontSize: '14px', marginLeft: '10px' }}>Crear webhook</Typography.Text>
                </Row>
                {(team?.webhooks ?? []).map((wh, i) => {
                    return <div key={i} style={{ padding: '10px 20px', backgroundColor: '#FAFBFD', borderRadius: '10px', margin: '15px 0px' }}>
                        <Row style={{}} align='middle'>
                            <Col xs={2}>
                                <div style={{ width: '20%' }}>
                                    <Switch checked={wh.status === 'active'} size='small' onChange={() => handleSwitchChange(wh)} />
                                </div>
                            </Col>
                            <Col xs={22} onClick={() => setViewWebhookDetails(wh)} justify="space-between">
                                <Row>
                                    <Col xs={8} >
                                        <div style={{ width: '60%' }}>
                                            <Typography.Text ellipsis style={{ fontSize: '14px', marginLeft: '10px' }}>{wh.url}</Typography.Text>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <Typography.Text style={{ fontSize: '14px', marginLeft: '10px' }}>{moment(wh.created).format('DD/MM/YYYY')}</Typography.Text>
                                    </Col>
                                    <Col xs={6} style={{ textAlign: 'right' }}>
                                        <Typography.Text style={{ fontSize: '14px', marginLeft: '10px' }}>{(wh.events ?? []).length} evento{(wh.events ?? []).length > 1 ? 's' : ''}</Typography.Text>
                                    </Col>
                                    <Col xs={4} style={{ textAlign: 'right' }}>
                                        <Tag color={returnColorOnStatist(wh.status === 'active' ? 'activeLight' : 'inactiveLight')}>
                                            <Typography.Text style={{ fontSize: '14px', marginLeft: '10px', color: returnColorOnStatist(wh.status === 'active' ? 'active' : 'inactive') }}>{wh.status === 'active' ? 'Activo' : 'Inactivo'}</Typography.Text>
                                        </Tag>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                })}
            </Col> : <EmptyTable customMessage="No tienes webhooks configurados" parentAction={() => setShowCreateWebhook(true)} elementName="webhook" />}
        </Col>
    }

    const WebhookDetails = () => {

        const { status, url, events, created, id } = viewWebhookDetails ?? {}

        return <Col xs={24} md={12} lg={12}>
            <Row justify='start' align='middle' style={{ width: 'fit-content' }} onClick={() => setViewWebhookDetails(null)}>
                <IoChevronBack />
                <Typography.Text style={{ fontSize: '14px', marginLeft: '10px' }}>Regresar</Typography.Text>
            </Row>
            <Row align='middle' style={{ marginTop: '15px' }} >
                <Col xs={4}>
                    <Tag color={returnColorOnStatist(status === 'active' ? 'activeLight' : 'inactiveLight')}>
                        <Typography.Text style={{ fontSize: '14px', color: returnColorOnStatist(status === 'active' ? 'active' : 'inactive') }}>{status === 'active' ? 'Activo' : 'Inactivo'}</Typography.Text>
                    </Tag>
                </Col>
                <Col xs={6}>
                    <Typography.Text ellipsis copyable style={{ fontSize: '14px' }}>{url}</Typography.Text>
                </Col>
                <Col xs={6}>
                    <Typography.Text style={{ fontSize: '14px' }}>{moment(created).format('DD MMMM YYYY HH:mm')}</Typography.Text>
                </Col>
                <Col xs={4}>
                    <Typography.Text style={{ fontSize: '14px' }}>{(events ?? []).length} evento{(events ?? []).length > 1 ? 's' : ''}</Typography.Text>
                </Col>
                <Col xs={4}>
                    <Typography.Text style={{ fontSize: '14px' }}>ID: {id}</Typography.Text>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
                {events?.map((e, i) => {
                    return <Tag key={i}>{e}</Tag>
                })}
            </Row>
        </Col>
    }

    return (
        <Col xs={24}>
            <Form form={form} initialValues={userDocument} onFinish={async (v) => {

            }} layout='vertical' style={{ width: '100%' }}>
                {showCreateWebhook && <Suspense fallback={<></>}>
                    <CreateWebhookModal open={showCreateWebhook} close={() => setShowCreateWebhook(false)} />
                </Suspense>}
                <div className="settingsContainer">
                    <Typography.Title style={{ marginBottom: '2px' }} level={5}>API Gigstack Pro </Typography.Title>
                    <Typography.Text type="secondary">Crea tu propia integración conectándote a nuestros servicios vía API,  <a href="https://gigstack.xyz/api-docs" rel="noreferrer" target="_blank">visita nuestra documentación</a></Typography.Text>
                    <Divider />
                    <ApiData />
                    <Row justify="center" style={{ width: '100%', marginTop: '10px' }}>
                        <Typography.Text type='secondary' style={{ fontSize: '11px' }}>Por favor mantén tus claves privadas y si crees que fueron comprometidas genera nuevas.</Typography.Text>
                    </Row>
                    <Divider />
                    <WebhooksView />
                </div>
            </Form>


        </Col>
    );
}

export default APISettings;