import { BehanceOutlined, FacebookOutlined, GithubOutlined, HeartOutlined, InstagramOutlined, LinkedinOutlined, LinkOutlined, MailOutlined, MobileOutlined, TwitterOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd';
import { doc, getFirestore, updateDoc, getDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';

import React, { useContext, useEffect, useState } from 'react'
import { RemoveUndefined } from '../../app/functions/helpers';
import SingleImageUpload from '../uploads/SingleImageUpload';
import TeamContext from '../../context/teamcontext/TeamContext';
import CreatableSelect from 'react-select/creatable';
import { reactSelectMultiStyles } from '../../app/functions/styles';


function BrandingSettings({ userDocument }) {

    const { team, getCurrentTeam } = useContext(TeamContext)

    const ref = doc(useFirestore(), 'teams', team?.id)
    const fs = getFirestore();


    // const auth = useAuth()
    const [loading, setloading] = useState(false)


    const [form] = Form.useForm();


    useEffect(() => {
        form.setFieldsValue({
            primaryColor: team?.brand?.primaryColor,
            alias: team?.brand?.alias,
            secondaryColor: team?.brand?.secondaryColor,
            logo: team?.brand?.logo,
            social: team?.social,
            supportEmail: team?.supportEmail,
            supportPhone: team?.supportPhone,
            contactEmails: team?.contactEmails ? team?.contactEmails.map((e) => ({ value: e, label: e })) : [],
            instagram: team?.social?.instagram ?? '',
            facebook: team?.social?.facebook ?? '',
            twitter: team?.social?.twitter ?? '',
            linkedin: team?.social?.linkedin ?? '',
            behance: team?.social?.behance ?? '',
            github: team?.social?.github ?? '',
            website: team?.social?.website ?? '',
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team])









    const ApiData = () => {
        return <Row style={{ width: '100%' }}>

            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Nombre de comercial</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }} style={{ width: '100%' }}>
                    <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <HeartOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>

                                <Form.Item name="alias" label="Nombre comercial" style={{ margin: '0px', padding: '0px' }} rules={[{ required: true, message: 'El nombre comercial es requerido.' }]}>
                                    <Input type="text" placeholder='ej. Gigstack' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}></Col>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Logotipo</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    <Col xs={24}>
                        <SingleImageUpload imageUrl={team?.brand?.logo} withFileName={false} uploadTo={`/teams/${team?.id}/files/logo`} dbRef={`/teams/${team?.id}`} disableCode={true} saveOnDB={true} defaultOnDb={team?.id} onSuccess={async (file) => {
                            try {

                                updateDoc(doc(fs, `teams/${team.id}`), { brand: { ...team?.brand, logo: file?.url } })
                                getCurrentTeam(team?.id)
                            } catch (error) {
                                message.error(error.message)
                            }
                        }} />
                        <Typography.Text type="secondary">Imagen en jpg, jpeg o png de máximo 2MB</Typography.Text>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}></Col>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Colores</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    <Col xs={24}>
                        <Row>
                            <Col xs={24}>
                                <Row align="middle">
                                    <Form.Item name="primaryColor" style={{ margin: 0, padding: 0 }}>
                                        <Input type="color" placeholder='' style={{ width: '90px', height: '30px', marginRight: '15px', position: 'relative', padding: '0px' }} />

                                    </Form.Item>
                                    <div className='d-flex flex-column'>
                                        <Typography.Text style={{ fontSize: '14px' }}>Primario</Typography.Text>
                                        <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Color que aparece en los botones y elementos a resaltar</Typography.Text>
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={24} style={{ marginTop: '20px' }}>
                                <Row align="middle">
                                    <Form.Item name="secondaryColor" style={{ margin: 0, padding: 0 }}>
                                        <Input type="color" placeholder='' style={{ width: '90px', height: '30px', marginRight: '15px', position: 'relative', padding: '0px' }} />
                                    </Form.Item>
                                    <div className='d-flex flex-column'>
                                        <Typography.Text style={{ fontSize: '14px' }}>Secundario</Typography.Text>
                                        <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Utilizado para elementos de la pantalla</Typography.Text>
                                    </div>
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} md={12} lg={6} style={{ marginTop: '25px' }}>
                <Typography.Text className='cLabel'>Correos</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18} style={{ marginTop: '25px' }}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }} style={{ width: '100%' }}>
                    <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <MailOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Correo de soporte</Typography.Text>
                                <Form.Item name="supportEmail" rules={[{ type: 'email', required: false, message: 'Añade el correo' }]}>
                                    <Input placeholder='ej: team@correo.com' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <MailOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Correos de contacto</Typography.Text>
                                <Form.Item name="contactEmails" >
                                    <CreatableSelect
                                        isMulti
                                        styles={reactSelectMultiStyles}
                                        noOptionsMessage={(v) => "Escribe un correo"}
                                        className="pro-select-input pro-select-input-multi"
                                        placeholder="Añadir correos"
                                        formatCreateLabel={(userInput) => `Añadir correo secundario "${userInput}"`}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} md={12} lg={6} style={{ marginTop: '25px' }}>
                <Typography.Text className='cLabel'>Teléfono</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18} style={{ marginTop: '25px' }}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }} style={{ width: '100%' }}>
                    <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <MobileOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Teléfono de soporte</Typography.Text>
                                <Form.Item name="supportPhone" rules={[{ message: 'Añade el Teléfono de contacto' }]}>
                                    <Input type='number' placeholder='ej: +52 123456' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} md={12} lg={6} style={{ marginTop: '25px' }}>
                <Typography.Text className='cLabel'>Redes sociales</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18} style={{ marginTop: '25px' }}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }} style={{ width: '100%' }}>
                    <Col xs={24} style={{ marginTop: '20px', width: '100%' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <FacebookOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Facebook</Typography.Text>
                                <Form.Item name="facebook" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.facebook.com/gigstack.pro' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <InstagramOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Instagram</Typography.Text>
                                <Form.Item name="instagram" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.instagram.com/gigstack.pro' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <TwitterOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Twitter</Typography.Text>
                                <Form.Item name="twitter" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.twitter.com' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <GithubOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Github</Typography.Text>
                                <Form.Item name="github" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.github.com' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <LinkedinOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>LinkedIn</Typography.Text>
                                <Form.Item name="linkedin" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.linkedin.com' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <BehanceOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Behance</Typography.Text>
                                <Form.Item name="behance" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.behance.com' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ marginTop: '10px' }}>
                        <Row align="middle" style={{ width: '100%' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <LinkOutlined />
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Sitio web</Typography.Text>
                                <Form.Item name="website" style={{ margin: '0px', padding: '0px' }}>
                                    <Input type="url" placeholder='ej. https://www.tusitio.com' />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>

                </Row>
            </Col>
        </Row>
    }

    return (
        <Col xs={24}>
            <Form form={form} initialValues={{
                ...team, ...(team?.social ?? {}), contactEmails: (team?.contactEmails ?? [])?.map(e => {
                    return {
                        label: e,
                        value: e
                    }
                })
            }} onFinish={async (v) => {
                try {
                    setloading(true)
                    const data = { ...v, contactEmails: (v?.contactEmails ?? [])?.map(e => e.value) }
                    // console.log(data)
                    delete data.instagram
                    delete data.behance
                    delete data.facebook
                    delete data.github
                    delete data.linkedin
                    delete data.twitter
                    delete data.website
                    await updateDoc(doc(getFirestore(), 'teams', team.id), {
                        ...RemoveUndefined(data),
                        brand: {
                            ...team.brand,
                            logo: team?.brand?.logo,
                            alias: v.alias,
                            primaryColor: v.primaryColor || '#000000',
                            secondaryColor: v.secondaryColor || '#000000'
                        },
                        social: {
                            ...team?.social,
                            behance:
                                v.behance ?? '',
                            facebook:
                                v.facebook ?? '',
                            github:
                                v.github ?? '',
                            instagram:
                                v.instagram ?? '',
                            linkedin:
                                v.linkedin ?? '',
                            twitter:
                                v.twitter ?? '',
                            website:
                                v.website ?? ''

                        }
                    })
                    setloading(false)
                    getCurrentTeam(team?.id)
                    message.success('Marca actualizada')
                    try {
                        const integrationsToSetup = (await getDoc(ref))?.data()?.integrationsSetup?.filter(int => int !== "setupBrand") ?? []
                        await updateDoc(ref, { integrationsSetup: integrationsToSetup })
                    } catch (error) {
                    }
                } catch (error) {
                    message.error(error.message)
                    setloading(false)
                }

            }} layout='vertical' style={{ width: '100%' }}>
                <div className="settingsContainer">
                    <Typography.Title style={{ marginBottom: '2px' }} level={5}>Marca</Typography.Title>
                    <Typography.Text type="secondary">Añade la información comercial de la empresa.</Typography.Text>
                    <Divider />
                    <ApiData />

                    <Row style={{ width: '100%', marginTop: '25px' }} justify="center">
                        <Button loading={loading} htmlType="submit">Actualizar información</Button>
                    </Row>


                </div>
            </Form>


        </Col>
    );
}

export default BrandingSettings;