import { Button, Form, Modal, Row, Select, Typography, message } from "antd"
import { useContext, useState } from "react"
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests"
import { useAuth } from "reactfire"
import TeamContext from "../../context/teamcontext/TeamContext"
import { countryLabeledListWithCodeAlpha3 } from "../../app/functions/datasets"



const CreateInvoiceFromReceiptModal = ({ open, close, receipt }) => {



    const auth = useAuth()
    const [form] = Form.useForm()
    const { team } = useContext(TeamContext)
    const [state, setState] = useState({
        typeOfGeneralPublic: 'national',
        country: 'MEX'
    })
    const [loading, setLoading] = useState(false)

    const GENERALPUBLICS = {
        national: {
            legal_name: "PUBLICO EN GENERAL",
            rfc: "XAXX010101000",
            address: {
                zip: team?.address?.zip,
            },
            tax_system: "616",
        },
        foreign: {
            legal_name: team?.legal_name ?? team?.legalName,
            rfc: "35-2771456",
            address: {
                zip: team?.address?.zip,
            },
            tax_system: "616",
        }
    }
    const handleCreateInvoice = async (v) => {
        try {
            setLoading(true)

            // console.log(v.typeOfGeneralPublic);

            var client = v.typeOfGeneralPublic === 'national' ? { ...GENERALPUBLICS.national, address: { zip: team?.address?.zip ?? '10200', country: state?.country ?? 'MEX' } } : { ...GENERALPUBLICS.foreign, address: { zip: team?.address?.zip ?? '10200', country: state?.country ?? 'MEX' } }

            const receiptObj = {
                ...receipt,
                client,
                customer: client,
                internalClient: client,
            }


            // console.log(receiptObj);

            await SignedInternalAPIRequest(receiptObj, 'receiptsApp/v1/generalpublicinvoice', auth.currentUser)
            setLoading(false)
            message.success('Factura creada con éxito')
            close()

        } catch (error) {
            setLoading(false)
            message.error(error?.message ?? 'Ocurrió un error al crear la factura')
        }
    }

    return (
        <Modal visible={open} onCancel={() => close()} title={null} footer={null}>
            <Form form={form} layout="vertical" onFinish={(v) => handleCreateInvoice(v)}>
                <Typography.Title level={5}>Crear factura desde recibo de venta</Typography.Title>
                <Typography.Text type="secondary">Puedes emitir una factura a público en general de este recibo</Typography.Text>

                <Row style={{ marginTop: '20px' }}>
                    <Form.Item initialValue={'national'} name="typeOfGeneralPublic" label="Tipo de público general" style={{ width: '100%' }} rules={[{ required: true, message: "Debes elegir una opción" }]}>
                        <Select
                            options={[
                                { label: 'Público general nacional', value: 'national' },
                                { label: 'Público general extranjero', value: 'foreign' },
                            ]}
                            style={{ width: '100%' }}
                            onSelect={(v) => {
                                setState({ ...state, typeOfGeneralPublic: v })
                            }}
                        />
                    </Form.Item>
                </Row>

                {state?.typeOfGeneralPublic === 'foreign' && (<Row style={{}}>
                    <Form.Item name="country" label="País" style={{ width: '100%' }} rules={[{ required: true, message: "Debes elegir una opción" }]}>
                        <Select
                            showSearch
                            options={countryLabeledListWithCodeAlpha3.map((c) => ({ label: c.name, value: c.code }))}
                            style={{ width: '100%' }}
                            onSelect={(v) => {
                                setState({ ...state, country: v })
                            }}
                            filterOption={(input, option) => {
                                return option.label.toLowerCase().includes(input.toLowerCase())
                            }}
                        />
                    </Form.Item>
                </Row>)}

                <Row gutter={12} justify="end">
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Crear factura
                    </Button>
                </Row>
            </Form>
        </Modal>
    )
}


export default CreateInvoiceFromReceiptModal