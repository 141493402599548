import { Button, Checkbox, Col, Descriptions, Divider, Empty, Form, Input, message, Modal, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { generateCode, getItemsAmounts, InvoiceStatusReadable, invoiceUsage, paymentForms, returnCurrencyValue, SearchParamInURL } from '../../app/functions/helpers';
import { generalreactSelectStyles, reactSelectMultiStyles } from '../../app/functions/styles';
import TableTitle from '../tables/TableTitle';
import Select from 'react-select'
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import CreatableSelect from 'react-select/creatable';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { useAuth } from 'reactfire';
import ServiceTaxModal from '../services/ServiceTaxModal';
import ServiceListModal from '../services/ServicesListModal';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import UserContext from '../../context/usercontext/UserContext';
import moment from 'moment';

function SubstituteInvoice({ open, close, userDocument, pastInvoiceDocument, openCancelInvoice, fullPage }) {
    const [idIK, /*setdate*/] = useState(`${pastInvoiceDocument.id ? pastInvoiceDocument.id : generateCode(10, 'ik_')}_${moment().valueOf()}`)

    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const { user, setUserData } = useContext(UserContext)


    const [pastInvoice, setPastInvoice] = useState(pastInvoiceDocument);
    const [loading, setLoading] = useState(false);
    const [fullLoading, setFullLoading] = useState(false)
    const [form] = Form.useForm()
    const [addTax, setaddTax] = useState(null);
    const [addServices, setaddServices] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState("")
    const auth = useAuth();

    var initialItems = pastInvoiceDocument?.internalItems

    var items = pastInvoice?.internalItems

    useEffect(() => {
        if (!user) {
            const getUser = async () => {
                const u = await getDoc(doc(getFirestore(), 'users', auth.currentUser.uid))
                // const t = await getDoc(doc(getFirestore(), 'teams', u.data()?.lastTeamViewed))
                // console.log(t.data());
                setUserData(u.data())
                getCurrentTeam(u.data()?.lastTeamViewed)

            }
            getUser();
        }

        //eslint-disable-next-line
    }, [])



    const SubstituteInvoiceFunction = async (values) => {
        //TODO: VALUES ARE NOT MUTATING
        setSearch('')

        const ninv = {
            ...pastInvoice,
            client: pastInvoice.client,
            customer: pastInvoice.client,
            type: 'create_invoice',
            items: items,
            internalItems: items,
            test: !pastInvoice?.livemode,
            payment_form: values.payment_form?.value,
            series: values.series || 'gigstack',
            folio: values.folio_number,
            folio_number: values.folio_number,
            use: values.use?.value,
            relation: pastInvoice.relation ?? null,
            payment_method: values.payment_method?.value,
            idempotency_key: idIK

        }
        console.log('ninv: ', ninv)
        if (pastInvoice?.isGlobal) {
            ninv.customer = {
                ...pastInvoice.client
            }
        }
        // return console.log(ninv);
        const body = {
            pastInvoice: pastInvoiceDocument,
            newInvoice: ninv,
            test: !pastInvoice?.livemode,
            type: 'substitute',
            billingAccountId: billingAccount?.id ?? pastInvoice?.billingAccount,
            teamId: team?.id ?? pastInvoice?.team,
            invoicingIntegration: 'facturapi',
            v2: true,
            idempotency_key: idIK
        }
        // return console.log(body);

        setFullLoading(true)
        try {
            const data = await SignedInternalAPIRequest(body, 'invoicing', auth.currentUser)
            setFullLoading(false)
            if (data?.action === 'requires_manual_cancellation') {
                Modal.confirm({
                    title: 'Factura sustituida',
                    content: 'La factura sustituida requiere ser cancelada manualmente',
                    okText: 'Aceptar',
                    onOk: () => {
                        close()
                        openCancelInvoice({ ...pastInvoice, actionType: 'substitute' })
                    }
                })
            }
            if (fullPage) {
                message.success('La factura ha sido sustituida')
                await new Promise((resolve) => setTimeout(resolve, 1000));
                window.history.replaceState(null, `tab`, `/invoices?pageActive=${SearchParamInURL('pageActive')}`)

                window.history.go()
            } else {
                close()
            }
        } catch (error) {
            message.error(error?.message ?? 'Ocurrió un error al sustituir la factura, por favor intente de nuevo')
            setFullLoading(false)
        }
        //substitute

    }



    const showItems = pastInvoice?.internalItems?.filter((f) => {
        if (!search) return true;
        return f.name.toLowerCase().includes(search.toLowerCase()) || f.description?.toLowerCase().includes(search.toLowerCase()) || f?.id?.toLowerCase().includes(search.toLowerCase())
    })
    const TableItems = () => {
        return loading ? <LoadingOutlined /> :

            <>
                <Row style={{ marginTop: '30px', marginBottom: '10px' }} align="middle" justify="space-between">
                    <Typography.Text level={5} style={{ margin: 0, fontSize: '14px', fontWeight: 'bold', flex: 1 }}>Productos / Servicios ({pastInvoice?.internalItems?.length})</Typography.Text>
                    <div style={{}} className="d-flex flex-row">
                        <Button type="ghost" onClick={() => setaddServices(true)}>Añadir servicio</Button>
                        <Input.Search defaultValue={search} onSearch={(v) => {
                            setSearch(v)
                        }} placeholder="Buscar por nombre del servicio" />
                    </div>
                </Row>
                <Table locale={
                    {
                        emptyText: <Empty description="No hay productos o servicios" />
                    }
                }
                    pagination={{
                        hideOnSinglePage: true,
                        onChange: (page, ps) => {
                            setCurrentPage(page)
                            setPageSize(ps)
                        },
                        defaultPageSize: pageSize,
                        current: currentPage
                    }}
                    dataSource={showItems} columns={[
                        {
                            title: <TableTitle title='Producto' />,
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record, index) => (
                                <div className='d-flex flex-column'>
                                    <Typography.Text style={{ fontSize: '12px' }} editable={{
                                        onChange: (value) => {
                                            var internalItems = pastInvoice.internalItems;

                                            var itemToUpdate = internalItems[index];
                                            itemToUpdate.name = value;
                                            internalItems.splice(index, 1, itemToUpdate);
                                            var newInvoice = pastInvoice;
                                            newInvoice.internalItems = internalItems;

                                            setLoading(true)
                                            setPastInvoice(newInvoice)

                                            setTimeout(() => {
                                                setLoading(false)
                                            }, 100);
                                        }
                                    }}>{text}</Typography.Text>
                                    <Typography.Text style={{ fontSize: '10px', color: '#cecece' }}>{record.id}</Typography.Text>

                                </div>
                            )
                        },
                        {
                            title: <TableTitle title='Cantidad' />,
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text, record) => (
                                <div>
                                    <Typography.Text style={{ fontSize: '12px' }}
                                        editable={{
                                            onChange: (value) => {
                                                if (!value || isNaN(value)) return;
                                                var itemToUpdate = record;
                                                itemToUpdate.quantity = Number(value);
                                                var internalItems = pastInvoice.internalItems;

                                                internalItems[record.key] = record;
                                                var newInvoice = pastInvoice;
                                                newInvoice.internalItems = internalItems;

                                                setLoading(true)
                                                setPastInvoice(newInvoice)

                                                setTimeout(() => {
                                                    setLoading(false)
                                                }, 100);
                                            }
                                        }}
                                    >{text}</Typography.Text>
                                    <Typography.Text className='secondary' style={{ fontSize: '12px' }}>{record.unit_key}</Typography.Text>
                                </div>
                            )
                        },
                        {
                            title: <TableTitle title='Precio' />,
                            dataIndex: 'total',
                            key: 'total',
                            render: (text, record, index) => (
                                <div>
                                    <Typography.Text style={{ fontSize: '12px' }} editable={{
                                        onChange: (nv) => {
                                            if (!nv) return;
                                            var value = nv.replace(/,/g, '').replace(/ /g, '').replace("$", '');
                                            if (isNaN(value)) return;


                                            var internalItems = pastInvoice.internalItems;
                                            var itemToUpdate = internalItems[index];
                                            itemToUpdate.total = Number(value);
                                            internalItems.splice(index, 1, itemToUpdate);
                                            var newInvoice = pastInvoice;
                                            newInvoice.internalItems = internalItems;

                                            setLoading(true)
                                            setPastInvoice(newInvoice)
                                            setTimeout(() => {
                                                setLoading(false)
                                            }, 100);
                                        }
                                    }}>{returnCurrencyValue(text)}</Typography.Text>
                                    <Typography.Text className='secondary' style={{ fontSize: '12px' }}>{pastInvoiceDocument?.currency}</Typography.Text>
                                </div>
                            )
                        },
                        {
                            title: <TableTitle title='Impuestos' />,
                            dataIndex: 'taxes',
                            key: 'taxes',
                            render: (text, record, index) => (
                                //RETURN AS UNORDER LIST RECORD.TAXES
                                <Row align="middle">
                                    <ul style={{ marginRight: '15px' }}>
                                        {record.taxes?.map((tax, index) => (
                                            <li key={index}>
                                                <Typography.Text style={{ fontSize: '12px' }}>{tax.type} </Typography.Text>
                                                <Typography.Text className='secondary' style={{ fontSize: '12px' }} editable={{
                                                    onChange: (value) => {
                                                        if (!value || isNaN(value)) return;
                                                        var taxToUpdate = tax;
                                                        taxToUpdate.rate = Number(value);
                                                        var taxes = record.taxes;
                                                        taxes[index] = taxToUpdate;
                                                        var internalItems = pastInvoice.internalItems;

                                                        internalItems[record.key] = record;
                                                        record.taxes = taxes;
                                                        var newInvoice = pastInvoice;
                                                        newInvoice.internalItems = internalItems;

                                                        setLoading(true)
                                                        setPastInvoice(newInvoice)

                                                        setTimeout(() => {
                                                            setLoading(false)
                                                        }, 100);
                                                    }
                                                }}>{tax.rate} </Typography.Text>
                                                <Typography.Text className='secondary' style={{ fontSize: '12px' }}> {tax.inclusive ? 'incluído' : ''} </Typography.Text>
                                            </li>
                                        ))}
                                    </ul>

                                </Row>
                            )

                        },
                        {
                            title: <TableTitle title='' />,
                            dataIndex: 'taxes',
                            key: 'taxes',
                            fixed: 'right',
                            render: (text, record, index) => (
                                //RETURN AS UNORDER LIST RECORD.TAXES
                                <Space>
                                    <Tooltip title="Añadir nuevo impuesto">
                                        <PlusCircleOutlined className="primary clickable" onClick={() => {
                                            setaddTax(index);
                                        }} />
                                    </Tooltip>
                                    <DeleteOutlined className="hoverDanger clickable" onClick={() => {
                                        var internalItems = pastInvoice.internalItems;
                                        var newIndx = search !== '' ? internalItems.findIndex((i) => i.id === record.id) : index;

                                        newIndx = (currentPage * pageSize) - pageSize + newIndx;
                                        console.log(newIndx);
                                        internalItems.splice(newIndx, 1);
                                        // internalItems.filter((item, indx) => indx !== newIndx)
                                        var newInvoice = pastInvoice;
                                        newInvoice.internalItems = internalItems;

                                        setLoading(true)
                                        setPastInvoice(newInvoice)
                                        //setSearch("")

                                        setTimeout(() => {
                                            setLoading(false)
                                        }, 50);
                                    }} />

                                </Space>
                            )

                        }
                    ]} />
            </>
    }


    const InvoiceForm = () => {
        return <>


            <Row gutter={10}>
                <Col xs={12}>
                    <Form.Item label="Serie" name="series" rules={[{ required: true, message: 'Por favor añade la serie' }]}>
                        <Input className="pro-input" placeholder="" />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item label="Folio" name="folio_number" rules={[{ required: true, message: 'Por favor añade el folio' }]}>
                        <Input className="pro-input" placeholder="" type="number" step='0.00000001' />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item label="Método de pago" name="payment_method" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={[{ label: 'Pago en una sola exhibición', value: 'PUE' }, { label: 'Pago en parcialidades', value: 'PPD' }]}
                            isLoading={false}

                            placeholder="Método de pago"
                        />
                    </Form.Item>
                </Col>
                <Col xs={12}>
                    <Form.Item label="Forma de pago" name="payment_form" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={paymentForms}
                            isLoading={false}

                            placeholder="Forma de pago"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={10}>
                <Col xs={24}>
                    <Form.Item label="Uso de la factura" name="use" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={invoiceUsage}
                            isLoading={false}

                            placeholder="Método de pago"
                        />
                    </Form.Item>
                </Col>

            </Row>



        </>
    }
    const SustituteContent = () => {
        return <>
            {(addTax !== null) && <ServiceTaxModal withBase={false} open={addTax != null} close={() => setaddTax(null)} add={(tax) => {

                items[addTax].taxes.push(tax)
                // for (const item of items) {
                //     item.taxes?.push(tax)
                // }
                setPastInvoice({ ...pastInvoice, internalItems: items })
                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                }, 100);
                setaddTax(null)
            }} />
            }
            {
                addServices && <ServiceListModal open={addServices} close={() => setaddServices(false)} userDocument={userDocument} onSelect={(s) => {
                    var i = items
                    i.push(s)


                    setPastInvoice({ ...pastInvoice, internalItems: i })
                    setaddServices(false)
                    setLoading(true)
                    setTimeout(() => {
                        setLoading(false)
                    }, 300);

                }} />
            }
            <Form form={form} style={{ marginTop: '15px' }} layout="vertical" onFinish={(v) => {
                form.validateFields();
                SubstituteInvoiceFunction(v);
                // close()
            }} initialValues={{
                ...pastInvoice,
                payment_method: pastInvoice.payment_method === 'PUE' ? { label: 'Pago en una sola exhibición', value: 'PUE' } : { label: 'Pago en parcialidades', value: 'PPD' },
                use: invoiceUsage.find(p => p.value === pastInvoice.use),
                payment_form: paymentForms.find(p => p.value === pastInvoice.payment_form),
                // emails: (pastInvoice?.emails ?? [])?.map(p => ({ label: p, value: p })),
            }}>
                <div className='d-flex flex-column'>
                    {fullPage && <Row justify="start" style={{ marginBottom: '15px' }} align="middle">
                        <div className='d-flex flex-row' style={{
                            backgroundColor: '#f7f7f7', padding: '5px 10px', borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                window.history.replaceState(null, `tab`, `/invoices?pageActive=${SearchParamInURL('pageActive')}`)

                                window.history.go()
                            }}
                        >
                            <IoChevronBackCircleOutline style={{ fontSize: '18px', color: '#333' }} />
                            <Typography.Text style={{ fontSize: '12px', color: '#333', marginLeft: '5px', padding: 0 }} >Regresar</Typography.Text>
                        </div>
                    </Row>}
                    <Typography.Title level={4} style={{ margin: 0 }}>Sustituir factura con nuevo CFDI</Typography.Title>
                    <Typography.Text className='secondary' style={{ fontSize: '12px' }}>Generaremos una nueva factura para sustituir el folio {pastInvoiceDocument?.uuid} con un nuevo CFDI.</Typography.Text>

                    <Descriptions layout="horizontal" column={1}>
                        <Descriptions.Item label="Estado actual de la factura" labelStyle={{

                            color: '#c7c7c7'
                        }}><Tag color={pastInvoice?.status === 'valid' ? 'blue' : 'orange'} style={{ fontSize: '12px', }}>{InvoiceStatusReadable(pastInvoice?.status)}</Tag>
                            <Tooltip title="La factura actual será cancelada y sustituida por una nueva con los nuevos detalles">
                                <QuestionCircleOutlined style={{ color: '#c7c7c7' }} />
                            </Tooltip>
                        </Descriptions.Item>
                        <Descriptions.Item label="Cliente" labelStyle={{

                            color: '#c7c7c7'
                        }}><Row>
                                <Tag color={'blue'} style={{ fontSize: '12px', }}>{pastInvoice?.internalClient?.legal_name}</Tag>
                                <Tag color={'blue'} style={{ fontSize: '12px', }}>{pastInvoice?.internalClient?.rfc}</Tag>
                            </Row>
                            <Tooltip title="La nueva factura se emitirá al mismo cliente">
                                <QuestionCircleOutlined style={{ color: '#c7c7c7' }} />
                            </Tooltip>
                        </Descriptions.Item>
                    </Descriptions>

                    <Divider />

                    <Typography.Text level={5} style={{ margin: 0, fontSize: '14px', fontWeight: 'bold', marginTop: '0px', marginBottom: '10px' }}>Detalles</Typography.Text>


                    <InvoiceForm />


                    <Divider />

                    <TableItems />
                    <div className="d-flex flex-column" style={{ alignItems: 'end', width: '100%' }}>
                        <Typography.Text>Subtotal: {getItemsAmounts(initialItems, false).subtotalString} </Typography.Text>
                        <Typography.Text>Impuestos: {getItemsAmounts(initialItems, false).taxesString}</Typography.Text>
                        <Typography.Text>Total: {getItemsAmounts(initialItems, false).totalString}</Typography.Text>
                    </div>

                    <Divider />

                    <Form.Item name="emails" label="¿Quieres enviar la nueva factura por correo?" extra={pastInvoice.emails?.length < 1 ? <Typography.Text type="secondary" style={{ fontSize: '10px' }}>No enviaremos ningún correo</Typography.Text> : ''}>
                        <CreatableSelect
                            isMulti

                            options={[{ label: pastInvoice.internalClient?.email, value: pastInvoice.internalClient?.email }, { label: userDocument?.email, value: userDocument?.email }, ...(pastInvoice?.internalClient?.bcc?.map((e) => { return { label: e, value: e } }) ?? [])]}
                            styles={reactSelectMultiStyles}
                            noOptionsMessage={(v) => "Añade un correo"}
                            className="pro-select-input pro-select-input-multi"
                            placeholder="Selecciona los correos"
                            formatCreateLabel={(userInput) => `Añadir relación con "${userInput}"`}
                        />
                    </Form.Item>

                    <Form.Item name="actionConfirmed" valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Por favor confirma la acción.')),
                            },
                        ]}>
                        {/*ADD A CHECKBOX TO CONFIRM THE CHANGE */}
                        <Checkbox>Confirmo que los datos de la factura son correctos</Checkbox>
                    </Form.Item>

                    <Row justify="center">
                        <Button loading={fullLoading} type="primary" htmlType='submit'>Sustituir factura</Button>
                    </Row>
                </div>
            </Form>
        </>
    }
    if (fullPage) {
        return <SustituteContent />
    }
    return (
        <Modal visible={open} footer={null} style={{ top: 5 }} onCancel={() => close()} maskClosable={false} width={window.innerWidth < 800 ? '60%' : '50%'}  >
            <SustituteContent />
        </Modal>
    );
}

export default SubstituteInvoice;