import React, { useContext } from 'react';
import { Typography } from 'antd';
// import { useMembership } from '../../customHooks/useMembership';
import moment from 'moment';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';

const BannerConvert = ({ authData, billingAccountId }) => {
    const { billingAccount } = useContext(BillingAccountContext);

    // const { credits } = useMembership({ uid: authData.uid, billingAccountId });
    const untilDate = billingAccount?.manualPaid?.until;

    return (
        <div
            style={{
                background: '#EFECFE',
                borderRadius: '0.5cm',
                margin: '10px 0',
                padding: '10px',
                display: 'flex',
                marginLeft: '20px',
                alignItems: 'center',
                width: '75%',
                justifyContent: 'center', // Center the content horizontally
            }}
        >
            <Typography.Text
                level={4}
                style={{
                    color: '#8666FD',
                    textAlign: 'center',
                }}
            >
                Contrata gigstack, tu periodo de prueba vence {moment(untilDate).fromNow()}! <br />
                <a
                    style={{
                        color: '#8666FF',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                    rel="noreferrer"
                    href="https://app.gigstack.pro/memberships"
                    target="_blank"
                >
                    Contrata Gigstack.pro →
                </a>
            </Typography.Text>
        </div>
    );
};

export default BannerConvert;
