import { ApiOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { Col, message, Row, Typography } from 'antd'
import React, { Suspense, useContext, useState } from 'react'
import { useAuth } from 'reactfire'
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests'
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext'
import TeamContext from '../../context/teamcontext/TeamContext'
// import { useUserAbilities } from '../../customHooks/userAbilities'
import BankModal from '../integrations/BankModal'
import ConektaModal from '../integrations/ConektaModal'
import PayPalModal from '../integrations/PayPalModal'
import StripeAutomaticInvoicingSetting from './StripeAutomaticInvoicingSetting'
import StripeAutomationDrawer from './StripeAutomationDrawer'
// import StripeAutomationDrawer from './StripeAutomationDrawer'


export const handleStripeLink = async ({ userDocument, auth, teamId, billingAccountId, team }) => {
    try {
        // TODO Change refresh url
        var link;
        if (!team?.stripe?.completed) {
            link = { url: 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_G17KI523sJrtpFv6vEiBTeggOTaGUWBG&scope=read_write' }
        } else if (team?.stripe?.requirements?.past_due.length > 0 || team?.stripe) {
            link = await SignedInternalAPIRequest({
                teamId, billingAccountId, type: 'onboarding_link', redirectUrl: window.location.href.includes('https') ? window.location.href : 'https://app.gigstack.pro', refreshUrl: window.location.href.includes('https') ? window.location.href : 'https://app.gigstack.pro', cancelUrl: window.location.href.includes('https') ? window.location.href : 'https://app.gigstack.pro'
            }, 'stripeActions', auth.currentUser)
        }
        return link
    } catch (error) {
        console.error(error)
        throw error
    }
}

// TODO: Review Element Holder

function PaymentOptions({ userDocument, onlyStripe, stripeButton }) {

    const auth = useAuth()
    const [loading, setloading] = useState(null)
    const [addBank, setaddBank] = useState(false)
    const [addPayPal, setaddPayPal] = useState(false)
    const [addConekta, setAddConekta] = useState(false)
    const [configureAutomation, setConfigureAutomation] = useState(false)

    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    // const { canSendInvoices, isStripeConnected } = useUserAbilities({ userDocument })


    /**
     * `HaveStripe` returns an object with three properties: `hasStripe` (boolean), `pastDue` (array), and
     * `activeStripe` (string)
     * @returns A

     */
    const HaveStripe = () => {
        var stripeAccount = (team?.stripe ?? {})
        var pastDue = (stripeAccount?.requirements?.past_due ?? [])
        return { hasStripe: stripeAccount?.completed, pastDue: pastDue, activeStripe: (stripeAccount?.stripe_user_id) }
    }


    /**
     * > If the user has a bank account, return true, otherwise return false
     */
    const HasBank = () => {
        var bank = (team?.bank ?? {})
        return { hasBank: (bank?.bank || bank?.iban || bank?.swift || bank?.bic) }
    }

    /**
     * If the user has a paypal secret key, return true, otherwise return false.
     * @returns const HasPaypal = () => {
     *         var paypal = (userDocument.paypal ?? {})
     *         return { hasPaypal: (paypal?.secret_key) }
     *     }
     */
    const HasPaypal = () => {
        var paypal = (userDocument.paypal ?? {})
        return { hasPaypal: (paypal?.secret_key) }
    }

    /**
     * > If the user has a conekta object, and the conekta object has a private key, then return true
     * @returns an object with a property called hasConekta.
     */
    const HasConekta = () => {
        var conekta = (userDocument.conekta ?? {})
        return { hasConekta: (conekta?.private_key) }
    }

    /**
     * It opens a new window with a link to Stripe's OAuth flow
     * @returns {
    
     */
    const ConnectStripe = async () => {
        setloading('stripe')
        try {
            const link = await handleStripeLink({ userDocument, auth, teamId: team.id, billingAccountId: billingAccount.id, team })
            setloading(null)
            getCurrentTeam(team?.id)
            return window.open(link.url, '_blank')
        } catch (error) {
            setloading(null)
            message.error(error?.error?.raw?.message ?? 'Ocurrio un error al contectar la cuenta.')
        }

    }

    if (onlyStripe) {
        return <div onClick={ConnectStripe}>
            {stripeButton}
        </div>
    }
    return (
        <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
            {configureAutomation && <StripeAutomationDrawer open={configureAutomation} close={() => setConfigureAutomation(false)} userDocument={userDocument} />}
            {addBank && <Suspense fallback={<LoadingOutlined />}>
                <BankModal open={addBank} close={() => setaddBank(false)} userDocument={userDocument} />
            </Suspense>}
            {addPayPal && <Suspense fallback={<LoadingOutlined />}>
                <PayPalModal open={addPayPal} close={() => setaddPayPal(false)} userDocument={userDocument} />
            </Suspense>}
            {addConekta && <Suspense fallback={<LoadingOutlined />}>
                <ConektaModal close={() => setAddConekta(false)} userDocument={userDocument} open={addConekta} />
            </Suspense>}
            <Col xs={24} style={{ backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                <Row>
                    <Col xs={24}>
                        <Row align="middle">
                            <div onClick={ConnectStripe} className="customCircledTag clickable" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                {loading === 'stripe' ? <LoadingOutlined /> : HaveStripe().activeStripe ? <CheckOutlined /> : <ApiOutlined />}
                            </div>
                            <div className='d-flex flex-column' style={{ flex: 1 }}>
                                <Typography.Text style={{ fontSize: '14px' }}>Stripe <span style={{ fontSize: '11px' }}>{HaveStripe().activeStripe ? `- ${team?.stripe?.accountId ?? team?.stripe?.stripe_user_id}` : ''}</span></Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Cobra con tarjeta de crédito o débito {userDocument?.country === 'Mexico' && ' y en tiendas OXXO'}</Typography.Text>
                            </div>
                        </Row>
                    </Col>
                    <StripeAutomaticInvoicingSetting userDocument={userDocument} openConfigure={(v) => setConfigureAutomation(v)} />
                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '20px', backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                <Row align="middle">
                    <div onClick={() => setAddConekta(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {HasConekta().hasConekta ? <CheckOutlined /> : <ApiOutlined />}
                    </div>
                    <div className='d-flex flex-column' style={{ flex: 1 }}>
                        <Typography.Text style={{ fontSize: '14px' }}>Conekta</Typography.Text>
                        <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Recibe pagos en SPEI que se confirman automáticamente.</Typography.Text>
                    </div>
                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '20px', backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                <Row align="middle">
                    <div onClick={() => setaddBank(true)} className="customCircledTag clickable" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {HasBank().hasBank ? <CheckOutlined /> : <ApiOutlined />}
                    </div>
                    <div className='d-flex flex-column' style={{ flex: 1 }}>
                        <Typography.Text style={{ fontSize: '14px' }}>Banco <span style={{ fontSize: '11px' }}>{userDocument?.bank ? `- ${userDocument?.bank?.bank ?? userDocument?.bank?.country}` : ''}</span></Typography.Text>
                        <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Recibe transferencia electrónica de fondos</Typography.Text>
                    </div>
                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '20px', backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
                <Row align="middle">
                    <div onClick={() => setaddPayPal(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                        {HasPaypal().hasPaypal ? <CheckOutlined /> : <ApiOutlined />}
                    </div>
                    <div className='d-flex flex-column' style={{ flex: 1 }}>
                        <Typography.Text style={{ fontSize: '14px' }}>PayPal</Typography.Text>
                        <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Cobra con tu cuenta de PayPal</Typography.Text>
                    </div>
                </Row>
            </Col>

        </Row>
    );
}

export default PaymentOptions;