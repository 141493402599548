import React, { useContext, useEffect, useReducer } from "react";
import { getDoc, getFirestore, doc, query, collection, where, updateDoc, getDocs } from "firebase/firestore";

import {
    GET_TEAM_DATA,
    GET_TEAMLIST_DATA
} from "../../types";

import TeamContext from "./TeamContext";
import TeamReducer from "./TeamReducer";
import { useAuth } from "reactfire";
import BillingAccountContext from "../billingaccountcontext/BillingAccountContext";

const TeamState = props => {

    const initialState = {
        team: null,
        teamsList: []
    }

    const fs = getFirestore()

    const [state, dispatch] = useReducer(TeamReducer, initialState);
    const { getBillingAccount } = useContext(BillingAccountContext)

    const auth = useAuth()

    const q = query(collection(fs, "teams"), where("membersIds", "array-contains", auth.currentUser.uid));
    // const { status, data: teamsData } = useFirestoreCollection(q)
    //    const { status: statusData, data: teamsData } =  useFirestoreCollection(q)


    // const GetInitialData = async () => {
    //     const docs = await getDocs(q)
    //     dispatch({
    //         type: GET_TEAMLIST_DATA,
    //         payload: docs.docs.map((doc) => doc.data())
    //     });

    // }


    useEffect(() => {
        // GetInitialData();
    }, [])

    const updateCurrentUser = async (team) => {
        try {
            await updateDoc(doc(fs, "users", auth.currentUser.uid), {
                lastTeamViewed: team.id,
                lastBillingAccountViewed: team.billingAccount
            });
        } catch (error) {
        }
    }

    /**
     * It get the team data from the database and dispatch it to the reducer
     * @param id 
     */
    const getCurrentTeam = async (id) => {
        if (!id) return
        try {
            await getDoc(doc(fs, "teams", id)).then((doc) => {
                dispatch({
                    type: GET_TEAM_DATA,
                    payload: doc.data()
                });
                getBillingAccount(doc.data().billingAccount)
                updateCurrentUser(doc.data())
            });

        } catch (error) {
        }
    }

    const getTeamsList = async () => {
        try {
            // let  = [];
            // ids.forEach(async (id) => {
            //     await getDoc(onSnapshot(doc(fs, "teams", id), (doc) => {
            //         .push(doc.data());
            //     }));
            // });
            await getDocs(q).then((docs) => {
                dispatch({
                    type: GET_TEAMLIST_DATA,
                    payload: docs.docs.map((doc) => doc.data())
                })
            });
            // const docs = await getDocs(q)
            // dispatch({
            //     type: GET_TEAMLIST_DATA,
            //     payload: docs.docs.map((doc) => doc.data())
            // });
            // dispatch({
            //     type: GET_TEAMLIST_DATA,
            //     payload: []
            // })
        } catch (error) {
        }
    }

    return (
        <TeamContext.Provider
            value={{
                team: state.team,
                teamsList: state.teamsList,
                getCurrentTeam,
                getTeamsList
            }}
        >
            {props.children}
        </TeamContext.Provider>
    )
}

export default TeamState