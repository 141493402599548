import { saveAs } from 'file-saver'
/**
 * It takes in a body, an endpoint, an authUser, and extra, and returns a promise that resolves to the
 * data from the API
 * @param [body] - The body of the request.
 * @param [endPoint] - The API endpoint you want to hit.
 * @param authUser - This is the user object that is returned from the auth0 authentication.
 * @param extra - This is an object that can contain the following properties:
 * @returns An object with a data property that is a wrapped promise.
 */
export const fetchData = (body = {}, endPoint = "", authUser, extra) => {
    const apiPromise = SignedInternalAPIRequest(body, endPoint, authUser, extra);

    return {
        data: wrapPromise(apiPromise),
    }
}

/**
 * It takes a promise and returns an object with a read method that returns the result of the promise
 * if it's resolved, or throws the promise if it's still pending, or throws the error if it's rejected
 * @param promise - The promise that you want to wrap.
 * @returns A promise that is wrapped in a function that returns an object with a read method.
 */
const wrapPromise = (promise) => {
    let status = "pending";
    let result = null;
    let error = null;
    let suspender = promise.then(
        res => {
            status = "success";
            result = res;
        },
        err => {
            status = "error";
            error = err;
        }
    );

    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw error;
            } else {
                return result;
            }
        }
    }
}


/**
 * It takes in a body, an endpoint, an authUser, and an extra object. It then returns a promise that
 * resolves to the response from the endpoint
 * @param [body] - The body of the request.
 * @param [endPoint] - The name of the function you want to call.
 * @param authUser - The user object from firebase.auth()
 * @param extra - is an object that contains the following properties:
 */
export const SignedInternalAPIRequest = async (body = {}, endPoint = "", authUser, extra, type = "POST", useHadersClass = false) => {

    const getInfo = async () => {
        const token = await authUser.getIdToken()

        const controller = new AbortController();
        const timeout = 30000

        const timeoutId = setTimeout(() => controller.abort(), timeout);
        // console.log();

        let headers = {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        var options = {
            method: type,
            json: true,
            headers,
            body: JSON.stringify(body),
            timeout,
            signal: controller.signal
        };

        if (type === 'GET') {
            options = {
                method: 'GET',
                json: true,
                headers,
                timeout,
                signal: controller.signal
            };
        }

        clearTimeout(timeoutId);



        try {
            const request = new Request(`https://us-central1-gigstackpro.cloudfunctions.net/${endPoint}`, options)
            // const request = new Request(`http://127.0.0.1:5001/gigstackpro/us-central1/${endPoint}`, options)

            console.log(request)
            const response = await fetch(request);
            var res;





            if (response.status >= 200 && response.status < 400) {
                if (extra?.isFile) {
                    try {
                        //DOWNLOAD BUFFER FROM RESPONSE
                        var blob = await response.blob();
                        saveAs(blob, extra.fileName)

                        return blob
                    } catch (error) {
                    }
                } else {
                    try {
                        const json = await response.json()

                        res = json;
                        return res;
                    } catch (error) {
                        res = response.body
                        throw res

                    }
                }
                return res
            } else {
                try {
                    const json = await response.json()
                    res = json;
                    throw res;
                } catch (error) {

                    res = error

                    throw res
                }
            }
        } catch (error) {

            throw error
        }

    }
    return getInfo()
}
export const SignedInternalAPIRequestTest = async (body = {}, endPoint = "", authUser, extra, type = "POST") => {

    const getInfo = async () => {
        const token = await authUser.getIdToken()
        const headers = {
            'cache-control': 'no-cache',
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        var options = {
            method: type,
            json: true,
            headers,
            body: JSON.stringify(body)
        };

        if (type === 'GET') {
            options = {
                method: 'GET',
                json: true,
                headers,

            };
        }

        const request = new Request(`https://us-central1-gigstackpro.cloudfunctions.net/${endPoint}`, options)
        // const request = new Request(`http://localhost:5001/gigstackpro/us-central1/${endPoint}`, options)

        const response = await fetch(request);
        var res;

        if (extra?.isFile) {

            try {
                var blob = await response.blob();
                return blob
            } catch (error) {
            }
        }
        try {
            const json = await response.json()
            res = json;
        } catch (error) {


            res = response.body
            throw res

        }

        if (response.status >= 200 && response.status < 300) {
            return res
        } else {
            throw res
        }

    }
    return getInfo()
}
/**
 * It takes a body, an endpoint, and a token, and returns a promise that resolves to the response from
 * the endpoint
 * @param [body] - The body of the request.
 * @param [endPoint] - The endpoint you want to hit.
 * @param token - The token that has been signed by the server.
 * @returns A promise
 */
export const SignedCustomInternalAPIRequest = async (body = {}, endPoint = "", token, type = "POST") => {

    return new Promise(async (resolve, reject) => {
        try {
            const headers = {
                'cache-control': 'no-cache',
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            var options = {
                method: type,
                json: true,
                headers,
                body: JSON.stringify(body)
            };

            const request = new Request(`https://us-central1-gigstackpro.cloudfunctions.net/${endPoint}`, options)
            const response = await fetch(request);
            var res;
            try {
                const json = await response.json()
                res = json;
            } catch (error) {
                res = response.body
            }

            if (response.status >= 200 && response.status < 300) {

                resolve(res)
            } else {

                reject(res)
            }

        } catch (error) {
            reject(error)
        }
    })
}