import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal, Row, Typography } from 'antd';
import { collection, doc, getDoc, getFirestore, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useAnalytics, useAuth, useFirestore } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { generateCode, returnCurrencyValue } from '../../app/functions/helpers';
// import CreateElementHolder from '../drawers/CreateElementHolder';
import DefaultSimpleTable from '../tables/DefaultTableClients';
import TableTitle from '../tables/TableTitle';
import ServiceTaxModal from './ServiceTaxModal';
import { logEvent } from 'firebase/analytics';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import AddNewService from './AddNewService';



function ServiceListModal({ open, close, onSelect, importFromStripe = false }) {


    const { team } = useContext(TeamContext)
    // console.log(team);
    const { billingAccount } = useContext(BillingAccountContext)
    const { user } = useContext(UserContext)
    const auth = useAuth()
    const analytics = useAnalytics()
    const ref = doc(useFirestore(), 'teams', team?.id)

    const [search, setsearch] = useState('')
    const [addService, setAddService] = useState(false)
    const [createTax, setCreateTax] = useState(false)
    const [createService, setCreateService] = useState(false)
    const [data, setdata] = useState([])
    const [taxes, settaxes] = useState([
        { label: `IVA 16% (Incluido)`, value: { factor: 'Tasa', rate: '0.16', withholding: false, inclusive: true, type: 'IVA' } },
        { label: `IVA 16% (No Incluido)`, value: { factor: 'Tasa', rate: '0.16', withholding: false, inclusive: false, type: 'IVA' } }
    ])
    const [loading, setloading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)


    const fs = getFirestore();
    const servicesRef = query(collection(fs, `services`), where('team', '==', team?.id ?? ''));

    const getServicesFromStripe = async () => {
        const services = await SignedInternalAPIRequest({
            type: 'import_data',
            typeOfData: 'products'
        }, 'stripeActions', auth.currentUser)
        setdata(services?.products ?? [])
        setloading(false)
    }


    useEffect(() => {

        if (importFromStripe) {
            getServicesFromStripe()
        } else {
            setloading(false)
        }

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columnsImport = [
        {
            title: <TableTitle title="Nombre" />,
            dataIndex: 'name',
            key: generateCode(5),
            fixed: 'left',
            width: importFromStripe ? '30%' : '50%',
            sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
            render: (text, record) => {
                return <Typography.Text className="primary" editable={importFromStripe ? {
                    onChange: (e) => {
                        setdata(data.map(d => {
                            if (d.id === record.id) {
                                return {
                                    ...d,
                                    name: e,
                                }
                            }
                            return d
                        }))
                    },
                } : false} >{text}</Typography.Text>
            }
        },
        {
            title: <TableTitle title="Total" />,
            dataIndex: 'total',
            key: generateCode(5),
            render: (text, record) => {
                return <Typography.Text className="" editable={importFromStripe ? {
                    onChange: (e) => {
                        setdata(data.map(d => {
                            if (d.id === record.id) {
                                return {
                                    ...d,
                                    total: Number(e.replace(/\$/g, '')),
                                }
                            }
                            return d
                        }))
                    },
                } : false} type="secondary" ellipsis>{returnCurrencyValue(text)}</Typography.Text>
            }
        },
        // {
        //     title: <TableTitle title="Impuestos" />,
        //     dataIndex: 'taxes',
        //     key: generateCode(5),
        //     width: '40%',
        //     render: (text, record) => {
        //         return <Select
        //             styles={generalreactSelectStyles}
        //             className="pro-select-input"
        //             isMulti={true}
        //             options={taxes}
        //             defaultValue={record.taxes.map(tax => {
        //                 let prefix = '+'
        //                 if (tax?.inclusive) { prefix = '' }
        //                 return {
        //                     label: `${prefix}${tax?.value?.type ?? tax?.type} ${(tax?.rate ?? tax.value.rate) * 100}%`,
        //                     value: tax,
        //                 }
        //             })
        //             }
        //             isLoading={false}
        //             onChange={(e, action) => {
        //                 let recordIndex = data.findIndex((r) => {
        //                     return r.id === record.id
        //                 })
        //                 data[recordIndex].taxes = e.map((t) => t.value)
        //                 setdata([...data])
        //             }}
        //         />
        //     }
        // },
    ]


    const handleImportServices = async () => {
        setLoadingButton(true);
        const batch = writeBatch(getFirestore());
        data.map((service) => {
            return batch.set(doc(getFirestore(), `services/${service.id}}`,), {
                ...service,
                team: team?.id,
                billingAccount: billingAccount?.id,
                quantity: 1
            })
        })

        try {
            await batch.commit();
            setLoadingButton(false);
            message.success('Servicios agregados correctamente')
            try {
                const integrationsToSetup = (await getDoc(ref))?.data()?.integrationsSetup?.filter(int => int !== "importStripeProducts") ?? []
                await updateDoc(ref, { integrationsSetup: integrationsToSetup })
            } catch (error) {
            }
            logEvent(analytics, 'importStripeProducts')
            close()
        } catch (error) {
            setLoadingButton(false);
            message.error(error?.message ?? 'Error al agregar los servicios');
        }
    }


    // const filteredServices = servicesData.filter(c => c.name?.toLowerCase().includes(search?.toLowerCase()))
    return (
        <Modal style={{ top: 10 }} width={window.innerWidth < 780 ? '100%' : importFromStripe ? "60%" : "50%"} visible={open} title={null} footer={null} onCancel={() => close()}>
            <ServiceTaxModal open={createTax} close={() => setCreateTax(false)} add={(tax) => {
                settaxes([...taxes, { label: `${tax?.inclusive ? '' : '+'}${tax.type} ${Number(tax.rate) * 100}%`, value: { ...tax, rate: Number(tax.rate), withholding: tax.withholding ?? false } }])
                setCreateTax(false)
                message.success('Impuesto registrado correctamente')
            }} />
            {addService &&
                <AddNewService open={addService} edit={null} close={() => {
                    setAddService(false)
                }} userDocument={user} type="services" />
            }
            {
                createService && <AddNewService open={createService} edit={null} close={() => {
                    setCreateService(false)


                }} userDocument={user} type="services" />
            }
            <Row>
                <Typography.Title level={5}>Importa y agrega impuestos a tus productos</Typography.Title>
            </Row>

            <Suspense fallback={<LoadingOutlined />}>
                <Row justify='end' style={{ marginTop: '20px', marginBottom: '10px' }} align="middle">
                    {/* <Button type="secondary" onClick={() => { setCreateTax(true) }} style={{ marginRight: '5px' }}> Crear impuesto </Button> */}
                    <Input placeholder="Buscar servicio" onChange={(v) => setsearch(v.target.value)} style={{ width: window.innerWidth < 800 ? importFromStripe ? '70%' : '100%' : '30%' }} />
                    <div style={{ height: '30px', width: '30px', borderRadius: '50%', border: '1px solid #fafbfd', marginLeft: '15px', alignItems: 'center', justifyContent: 'center' }} className="d-flex flex-column" shape="circle">
                        {!importFromStripe && <PlusOutlined className="primary" onClick={() => setCreateService(true)} />}
                    </div>
                </Row>
                <DefaultSimpleTable onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {

                            onSelect(record)
                        }, // click row
                    };
                }}
                    from='services'
                    //data={filteredServices} 
                    query={servicesRef}
                    empty="No hay servicios"
                    importFromStripe={importFromStripe}
                    elementName="servicio"
                    data={data ?? null}
                    emptyAction={() => setAddService(true)}
                    loading={loading}
                    filterSearch={search}
                    filterSearchKey="name"
                    columns={importFromStripe ? columnsImport : [
                        {
                            title: <TableTitle title="Nombre" />,
                            dataIndex: 'name',
                            key: generateCode(5),
                            fixed: 'left',
                            width: importFromStripe ? '30%' : '50%',
                            sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
                            render: (text, record) => {
                                return <Typography.Text className="primary" editable={importFromStripe ? {
                                    onChange: (e) => {
                                        setdata(data.map(d => {
                                            if (d.id === record.id) {
                                                return {
                                                    ...d,
                                                    name: e,
                                                }
                                            }
                                            return d
                                        }))
                                    },
                                } : false} >{text}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Total" />,
                            dataIndex: 'total',
                            key: generateCode(5),
                            render: (text, record) => {
                                return <Typography.Text className="" editable={importFromStripe ? {
                                    onChange: (e) => {
                                        setdata(data.map(d => {
                                            if (d.id === record.id) {
                                                return {
                                                    ...d,
                                                    total: Number(e.replace(/\$/g, '')),
                                                }
                                            }
                                            return d
                                        }))
                                    },
                                } : false} type="secondary" ellipsis>{returnCurrencyValue(text)}</Typography.Text>
                            }
                        },
                    ]} />
            </Suspense>
            {importFromStripe && data.length > 0 ? <Row justify="center" style={{ marginTop: '25px' }}>
                <Button loading={loadingButton} onClick={() => {
                    handleImportServices()
                }} type="primary" style={{ alignSelf: 'center' }}>Importar Servicios</Button>
            </Row> : null}
        </Modal>
    );
}

export default ServiceListModal;