
import { BarcodeOutlined, BookOutlined, CloseOutlined, DeleteOutlined, DownloadOutlined, FilePdfOutlined, LinkOutlined, LoadingOutlined, MailOutlined, QrcodeOutlined, ReloadOutlined, SearchOutlined, StopOutlined, UserAddOutlined, WarningOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Dropdown, Empty, Form, Input, Menu, Modal, Popconfirm, Row, Skeleton, Space, Tag, Tooltip, Typography, message } from 'antd';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { getAuth } from 'firebase/auth';

import { CopyToClipboard, DownloadFile, DownloadInvoice, DuplicateInvoice, InvoiceStatusReadable, ShareViaWhatsapp, generateCode, getItemsAmounts, paymentByStatus, returnCurrencyValue } from '../../app/functions/helpers';

import moment from 'moment';
import { AiOutlinePaperClip, AiOutlineBook, AiOutlineUserSwitch } from 'react-icons/ai';
import { CiMoneyBill } from 'react-icons/ci'
import { deleteDoc, doc, getFirestore, updateDoc } from 'firebase/firestore';
import ComposeEmailModal from '../email/ComposeEmailModal';
import CancelInvoiceModal from '../invoices/CancelInvoiceModal';
import { ConfirmSignInvoiceModal } from '../invoices/ConfirmSignInvoiceModal';
import SubstituteInvoice from '../invoices/SubstituteInvoice';
import DeleteInvoiceGlobal from '../modals/DeleteInvoiceGlobalModal';
import { DeleteRecord } from '../../app/pages/PaymentsPage';
import UserContext from '../../context/usercontext/UserContext';
import ClientsListModal from '../clients/ClientsListModal';
import RelationsDrawer from '../relations/RelationsDrawer';
import { useAuth } from 'reactfire';
import InvoicesRelations from '../relations/InvoicesRelations';
// import { BiWorld } from 'react-icons/bi';


export const PaymentActions = ({ item, rawRecord, showCreateInvoice, payments, setPayments, setSmallPayments, smallPayments }) => {

    // const [actionOnPayment, setactionOnPayment] = useState(null)
    const fs = getFirestore()
    const auth = useAuth()
    const { user } = useContext(UserContext)

    const [composeEmail, setComposeEmail] = useState(null)
    const [selectClient, setSelectClient] = useState(null)
    const record = item ? item : JSON.parse(rawRecord.rawData)

    const amounts = getItemsAmounts(record.items, record.paidIn === 'bank');

    const CancelPayment = async (payment) => {

        message.loading('Cancelando pago...')
        try {
            const resp = await SignedInternalAPIRequest({ type: 'payment_request_cancel', id: payment.paymentIntent, paymentID: payment.id }, 'paymentsHandler', auth.currentUser)
            // console.log(resp)
            if (resp.error) {
                return message.error('Error al cancelar pago')
            }
            if (smallPayments && payments) {
                const newPayments = payments.map((p) => {
                    if (p.fid === payment.fid) {
                        p.status = 'canceled'
                        p.rawData = JSON.stringify({ ...JSON.parse(p.rawData), status: 'canceled' })
                    }
                    return p
                })
                setPayments(newPayments)
                const newSmallPayments = smallPayments.map((p) => {
                    if (p.fid === payment.fid) {
                        p.status = 'canceled'
                        p.rawData = JSON.stringify({ ...JSON.parse(p.rawData), status: 'canceled', internalSatus: 'canceled' })
                    }
                    return p
                })
                setSmallPayments(newSmallPayments)
            }
            message.success('Pago cancelado')
        } catch (error) {
            // console.log(error)
            message.error('Error al cancelar pago')
        }

    }


    return (
        <>
            {composeEmail && <ComposeEmailModal
                close={() => setComposeEmail(null)}
                actionText={null}
                idRef={'payments'}
                data={{ ...composeEmail, payment: composeEmail }}
                userDocument={user}
                open={composeEmail}
                element={composeEmail}
                subject={`Solicitud de pago por ${getItemsAmounts(composeEmail.items, false).totalString}`}
                readableType={'pago'}
                client={composeEmail.client ?? { name: 'Sin Cliente', email: '', }}
            />}
            {selectClient && <Suspense>
                <ClientsListModal importFromStripe={false} open={selectClient} userDocument={user} close={() => setSelectClient(false)} onSelect={async (c) => {
                    try {
                        await updateDoc(doc(fs, 'payments', selectClient?.fid ?? selectClient?.id), {
                            clientID: c.id,
                            clientId: c.id,
                            client: {
                                ...c
                            },
                            clientEdited: true
                        })
                        message.success('Cliente asignado al pago')
                        setSelectClient(null)
                    } catch (error) {
                        message.error(error?.message ?? 'Error al asignar cliente')
                    }
                }} />
            </Suspense>}
            <Space style={{ marginTop: '5px' }}>
                {showCreateInvoice && <InvoicesRelations customElement={
                    <Tooltip title="Crear factura">
                        <BookOutlined type="secondary" style={{ color: '#b7b7b7' }} />
                    </Tooltip>
                } custom={true} items={record?.items} collection="payments" elementType="payment" changeState={(i) => {
                }} client={record?.client} item={{ ...record, invoices: record?.invoices }} id={record?.fid} userDocument={user} readable="pago" />}
                {!record.client && <Tooltip title="Asignar cliente">
                    <UserAddOutlined onClick={() => setSelectClient(record)} style={{ color: '#b7b7b7', fontSize: '14px', margin: '0px 5px' }} />
                </Tooltip>}
                {(record.status === 'requires_payment_method' || record.status === 'requires_action') && (record.status !== 'canceled' && record.status !== 'succeeded') && (
                    <Popconfirm
                        title="¿Estás seguro de cancelar el pago?"
                        okText="Cancelar pago"
                        cancelText="No"
                        onConfirm={() => {
                            if (
                                record.status === 'requires_payment_method' ||
                                record.status === 'requires_confirmation' ||
                                record.status === 'pending' ||
                                record.status === 'requires_action'
                            ) {
                                CancelPayment(record);
                                // message.success('Pago cancelado correctamente')
                            }
                        }}
                    >
                        <StopOutlined style={{ color: '#b7b7b7' }} className="itemHoverDanger clickable" />
                    </Popconfirm>
                )}

                {record.charges?.data?.length > 0 && <Tooltip title="Ver recibo">
                    <BarcodeOutlined style={{ color: '#b7b7b7' }} className="itemHoverPrimary" onClick={() => window.open(record.charges?.data[0]?.receipt_url, '_blank')} />
                </Tooltip>}

                {(record?.token && record?.status !== 'succeeded' && record?.status !== 'canceled') && (
                    <>
                        <Tooltip title="Copiar link de pago">
                            <LinkOutlined style={{ color: '#b7b7b7' }} onClick={() => CopyToClipboard(record.shortURL ?? `https://beta.gigstack.pro/pay/${record.token}`)} />
                        </Tooltip>
                        <Tooltip title="Enviar por correo">
                            <MailOutlined style={{ color: '#b7b7b7' }} onClick={() => setComposeEmail(record)} />
                        </Tooltip>
                    </>
                )}

                {(record?.shortURL && record.status !== 'succeeded' && record.status !== 'canceled') && (
                    <Tooltip title="Enviar por Whatsapp">
                        <WhatsAppOutlined style={{ color: '#b7b7b7' }} onClick={() => ShareViaWhatsapp(
                            `Puedes realizar el pago por ${amounts.totalString} ${record.currency} en el siguiente enlace! 😉 : ${record.shortURL}`
                        )} />
                    </Tooltip>
                )}

                {record.status === 'canceled' && <Popconfirm
                    showArrow={false}
                    title="¿Estás seguro de eliminar el pago?"
                    okText="Eliminar pago"
                    cancelText="No"
                    onConfirm={() => {
                        if (
                            (record.status === 'canceled' || record.object === 'manual_payment') &&
                            record.review_status !== 'succeeded'
                        ) {
                            DeleteRecord(record.fid, 0);
                        }
                    }}
                >
                    <DeleteOutlined style={{ color: '#b7b7b7' }} className="clickable itemHoverDanger" />
                </Popconfirm>}
            </Space>
        </>
    );
}

function SearchModal({ open, close, action, localTypeData = null, localData = [], userDocument, changeRender }) {

    const { user } = useContext(UserContext)

    const [filterByStatus, setFilterByStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [payments, setPayments] = useState([])
    const [smallPayments, setSmallPayments] = useState([])
    const [paymentsAmount, setPaymentsAmount] = useState(3)
    const [invoices, setInvoices] = useState([])
    const [smallInvoices, setSmallInvoices] = useState([])
    const [invoicesAmount, setInvoicesAmount] = useState(3)
    const [receipts, setReceipts] = useState([])
    const [smallReceipts, setSmallReceipts] = useState([])
    const [receiptsAmount, setReceiptsAmount] = useState(3)
    const [clients, setClients] = useState([])
    const [smallClients, setSmallClients] = useState([])
    const [clientsAmount, setClientsAmount] = useState(3)
    const [searchTermState, setSearchTerm] = useState('')
    const [makeGlobalQuery, setMakeGlobalQuery] = useState(false)
    const [tab, setTab] = useState(localTypeData ? localTypeData : 'payments')


    const auth = getAuth()




    const filterData = (data = [], searchTerm) => {

        if (searchTerm === 'pending') {
            return data.filter((d) =>
                d.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                d.status?.toLowerCase().includes('requires_payment_method') ||
                d.status?.toLowerCase().includes('requires_action') ||
                d.status?.toLowerCase().includes('requires_confirmation') ||
                d.status?.toLowerCase().includes('open')
            )
        }

        return data.filter((d) =>
            d.clientName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.clientEmail?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.client?.rfc?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.company?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.id?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.fid?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.token?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            d.status?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
            Number(d.amount) === Number(searchTerm)
        )
    }

    const makeQ = async (status = null) => {
        setLoading(true)
        var searchTerm = document.getElementById('searchinput').value;
        if (status) {
            let searchItem = document.getElementById('searchinput');
            searchItem.value = status
            searchTerm = status
        }
        setSearchTerm(searchTerm)

        let query = {}

        if (localTypeData && localData.length > 0 && !makeGlobalQuery) {
            query = {
                payments: localTypeData === 'payments' ? filterData(localData, searchTerm) : [],
                invoices: localTypeData === 'invoices' ? filterData(localData, searchTerm) : [],
                receipts: localTypeData === 'receipts' ? filterData(localData, searchTerm) : [],
            }
            if (query.payments.length <= 0 && query.invoices.length <= 0 && query.receipts.length <= 0) {
                query = await SignedInternalAPIRequest({}, `performSearch/v1/search?searchValue=${searchTerm}`, auth.currentUser, {}, 'GET');
            }
        } else if (makeGlobalQuery || localData.length <= 0) {
            query = await SignedInternalAPIRequest({}, `performSearch/v1/search?searchValue=${searchTerm}`, auth.currentUser, {}, 'GET');
        }

        // console.log('QUERY: ', query)

        setPayments(query?.payments ?? [])
        setSmallPayments(query?.payments?.slice(0, 3) ?? [])
        setPaymentsAmount(3)
        setInvoices(query?.invoices ?? [])
        setSmallInvoices(query?.invoices?.slice(0, 3) ?? [])
        setInvoicesAmount(3)
        setReceipts(query?.receipts ?? [])
        setSmallReceipts(query?.receipts?.slice(0, 3) ?? [])
        setReceiptsAmount(3)
        setClients(query?.clients ?? [])
        setSmallClients(query?.clients?.slice(0, 3) ?? [])
        setClientsAmount(3)
        setLoading(false)

    }
    useEffect(() => {
        setTimeout(() => {
            document?.getElementById('searchinput')?.focus();
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const PaymentElement = ({ payment }) => {
        var items;
        try {
            items = JSON.parse(payment.items)
        } catch (error) {
            items = []
        }

        const rawPayment = JSON.parse(payment?.rawData)

        return <Row key={generateCode(10, 'tempPay')} style={{ padding: '10px 0px', borderBottom: '1px solid #fafbfd', cursor: 'pointer' }} onClick={() => {
            changeRender('paymentDetails')
            close()
            return window.history.replaceState(null, `tab`, `/paymentDetails?id=${rawPayment.fid}`)
        }}>

            <div className="d-flex flex-column" style={{ flex: 1 }}>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#333' }}>{payment?.clientName}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{payment?.company ?? payment?.clientEmail}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{payment?.id ?? ''}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{moment(rawPayment?.succeededTimestamp ? rawPayment?.succeededTimestamp : rawPayment?.timestamp).format('DD/MM/YYYY HH:mm')}</Typography.Text>
            </div>
            <div className="d-flex flex-column">
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#cecece', textAlign: 'end' }}>{getItemsAmounts(items, payment?.paidIn === 'bank').totalString} {payment?.currency}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece', textAlign: 'end' }}>{paymentByStatus(payment?.status)}</Typography.Text>
                <PaymentActions rawRecord={payment} payments={payments} setPayments={(p) => setPayments(p)} smallPayments={smallPayments} setSmallPayments={(p) => setSmallPayments(p)} />
            </div>
        </Row>
    }

    const InvoiceActions = ({ record }) => {

        const fs = getFirestore()

        const [loadingSignInvoice, setloadingSignInvoice] = useState(false)
        const [downloading, setDownloading] = useState(null)
        const [composeEmail, setcomposeEmail] = useState(null)
        const [cancelInvoice, setcancelInvoice] = useState(null)
        const [signInvoiceModal, setSignIvoiceModal] = useState(false)
        const [substituteInvoice, setSubstituteInvoice] = useState(false)
        const [invoiceToSign, setInvoiceToSign] = useState([])
        const [pageSize, setPageSize] = useState(10)
        const [pageActive, setpageActive] = useState(1)
        const [deleteInvoiceGlobal, setDeleteInvoiceGlobal] = useState(null)


        const canCancelInvoice = record.status === 'valid' && record.livemode && record.cancellation_status === 'none' && !record.withoutKeys
        let items = [
            {
                label: <Typography.Text className="itemHoverPrimary">Descargar PDF</Typography.Text>,
                onClick: () => {
                    DownloadInvoice({ invoice: record, type: 'pdf', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                },
                key: 'PDF',
            },
            {
                label: <Typography.Text className="itemHoverPrimary">Descargar XML</Typography.Text>,
                onClick: () => {
                    DownloadInvoice({ invoice: record, type: 'xml', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                },
                key: 'XML',
            },
            {
                label: <Typography.Text className="itemHoverPrimary">Descargar zip</Typography.Text>,
                onClick: () => {
                    DownloadInvoice({ invoice: record, type: 'zip', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                },
                key: 'ZIP',
            },
        ]

        if (record.status === 'canceled') {
            items.push({
                label: <Typography.Text className="itemHoverPrimary">Descargar acuse de cancelación</Typography.Text>,
                onClick: () => {
                    DownloadInvoice({ invoice: { invoice: record, cancelation: true }, type: 'zip', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                },
                key: 'CANCELATION',
            },)
        }

        const menu = (inv) => (
            <Menu items={items} />
        );

        return downloading === record.id ? <LoadingOutlined /> : <Space>
            {downloading === record.id ? <LoadingOutlined /> : record.withoutKeys ? <></> :
                <Dropdown overlay={() => menu(record)} placement="bottom">
                    <DownloadOutlined className="clickable itemHoverPrimary" style={{ color: '#b7b7b7' }} />
                </Dropdown>
            }
            {(record.status === 'valid' && !record.livemode) && <Tooltip title="Timbrar la factura">
                <QrcodeOutlined className={`${record.withoutKeys ? 'disabled' : 'secondaryItem itemHoverPrimary'} `} style={{ color: '#b7b7b7', cursor: 'pointer' }} onClick={() => {
                    setSignIvoiceModal(true)
                    setInvoiceToSign(JSON.parse(record.rawData))
                }} />
            </Tooltip>}

            {composeEmail && <ComposeEmailModal
                close={() => setcomposeEmail(null)}
                actionText={null}
                idRef={"invoices"}
                data={{ ...composeEmail, invoice: composeEmail }}
                open={composeEmail}
                element={composeEmail}
                subject={`Factura ${composeEmail?.series} ${composeEmail?.folio_number}`}
                readableType={"factura"}
                client={
                    composeEmail.internalClient ?? { name: "Sin Cliente", email: "" }
                }
            />}

            {cancelInvoice && <CancelInvoiceModal
                open={cancelInvoice}
                close={() => setcancelInvoice(null)}
                invoice={cancelInvoice}
                cancelInvoice={(inv) => {
                    const nv = [];
                    invoices?.forEach((i) => {
                        if (i.id !== inv.id) {
                            nv.push(i);
                        }
                    });
                }}
            />}
            {signInvoiceModal && <Suspense fallback={<LoadingOutlined />}>
                <ConfirmSignInvoiceModal
                    loading={loadingSignInvoice}
                    open={signInvoiceModal}
                    close={() => setSignIvoiceModal(false)}
                    data={invoiceToSign}
                    add={(v) => {
                        if (v.withoutKeys) {
                            return;
                        }
                        DuplicateInvoice({ invoice: v, setdownloading: (v) => setloadingSignInvoice(v), authUser: auth.currentUser });
                    }}
                />
            </Suspense>}
            {substituteInvoice && <Suspense fallback={<LoadingOutlined />}>
                <SubstituteInvoice
                    open={substituteInvoice}
                    close={() => setSubstituteInvoice(null)}
                    pastInvoiceDocument={substituteInvoice}
                    openCancelInvoice={(inv) => setcancelInvoice(inv)}
                />
            </Suspense>}
            {deleteInvoiceGlobal && (<DeleteInvoiceGlobal open={deleteInvoiceGlobal} close={() => setDeleteInvoiceGlobal(null)} onAccept={() => setcancelInvoice(deleteInvoiceGlobal)} />)}
            {(canCancelInvoice && record?.isGlobal) ? <><Tooltip title="Cancelar factura">
                <StopOutlined className="secondaryItem itemHoverPrimary"
                    onClick={() => setDeleteInvoiceGlobal(JSON.parse(record.rawData))}
                    style={{ color: '#b7b7b7' }} />

            </Tooltip></> : (canCancelInvoice) ?
                <> <Tooltip title="Cancelar factura">
                    <StopOutlined className="secondaryItem itemHoverPrimary"
                        onClick={() => setcancelInvoice(JSON.parse(record.rawData))}
                        style={{ color: '#b7b7b7' }} />

                </Tooltip> </> : <></>}
            {!record.withoutKeys && <MailOutlined disabled={record.withoutKeys} onClick={() => {
                setcomposeEmail(JSON.parse(record.rawData))
                setPageSize(pageSize)
                setpageActive(pageActive)
            }} className="secondaryItem itemHoverPrimary" style={{ color: '#b7b7b7' }} />}
            <Tooltip title="Sustituir factura por una nueva">
                <ReloadOutlined onClick={() => setSubstituteInvoice(JSON.parse(record.rawData))} style={{ color: '#b7b7b7' }} className="clickable" />
            </Tooltip>
            {!record.livemode && <Tooltip>
                <Popconfirm title="¿Eliminar factura sin timbrar?" okText="Eliminar" okButtonProps={{ danger: true }} onConfirm={async () => {
                    try {
                        setDownloading(record.id)
                        await deleteDoc(doc(fs, `invoices/${record.id}`))

                        setDownloading(null)
                    } catch (error) {
                        message.error(error.message)
                    }
                }} ><DeleteOutlined className="itemHoverDanger" style={{ color: '#b7b7b7' }} /></Popconfirm>
            </Tooltip>}

            <Typography.Text hidden style={{ fontSize: '7px', marginBottom: '0px' }} className="secondary">V{record.cfdi_version ? record.cfdi_version : '3.3'}</Typography.Text>
        </Space>
    }

    const InvoiceElement = ({ invoice }) => {

        const rawInvoice = JSON.parse(invoice?.rawData)

        return <Row key={generateCode(10, 'tempInv')} style={{ padding: '10px 0px', borderBottom: '1px solid #fafbfd', cursor: 'pointer' }} onClick={() => {
            changeRender('invoiceDetails')
            close()
            return window.history.replaceState(null, `tab`, `/invoiceDetails?id=${rawInvoice.id}`)
        }}>

            <div className="d-flex flex-column" style={{ flex: 1 }}>

                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#333' }}>{invoice?.clientLegalName ?? invoice?.clientName ?? 'Sin Nombre'}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{invoice?.company ?? invoice?.clientEmail}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{invoice?.id ?? ''}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{moment(rawInvoice?.timestamp).format('DD/MM/YYYY HH:mm')}</Typography.Text>
            </div>
            <div className="d-flex flex-column">
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#cecece', textAlign: 'end' }}>{returnCurrencyValue(invoice?.total)} {invoice?.currency}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece', textAlign: 'end' }}>{InvoiceStatusReadable(invoice?.status)}</Typography.Text>
                <InvoiceActions record={invoice} />
            </div>
        </Row>
    }
    const ReceiptActions = ({ rawReceipt }) => {



        const [downloading, setdownloading] = useState(null)
        const [composeEmail, setComposeEmail] = useState(null)
        const record = JSON.parse(rawReceipt.rawData)
        const [showRelacion, setShowRelation] = useState(false);

        const deleteReceipt = async (record) => {
            try {
                setdownloading(record.id);
                try {
                    const d = doc(getFirestore(), "receipts", record.id);
                    await deleteDoc(d);

                    message.success("Recibo eliminado");
                    setdownloading(null);
                } catch (error) {
                    setdownloading(null);
                    message.error(error.message);
                }
            } catch (error) { }
        };

        return <Space>
            {showRelacion && (
                <Suspense fallback={<>Loading...</>}>
                    <RelationsDrawer open={showRelacion} relation={showRelacion} render='invoicesGlobal' close={() => setShowRelation(false)} />
                </Suspense>
            )}
            {composeEmail && <ComposeEmailModal close={() => setComposeEmail(null)} actionText={
                null
            } idRef={'receipts'} data={{ ...composeEmail, receipts: composeEmail }} userDocument={user} open={composeEmail} element={composeEmail} subject={`Recibo de venta por ${getItemsAmounts(composeEmail.items, false).totalString}`} readableType='Recibo de venta' client={composeEmail.client ?? { name: 'Sin Cliente', email: '', }} showEmailsView={true} />}
            {record.status === "pending" && (
                <>
                    <Tooltip title="Copiar URL de auto-factura">
                        <Typography.Text
                            copyable={{
                                icon: <LinkOutlined />,
                                text: record.url,
                                tooltips: false,
                            }}
                        ></Typography.Text>
                    </Tooltip>
                    <Tooltip title='Enviar por correo'>
                        <MailOutlined className='secondary clickable itemHoverPrimary' onClick={() => setComposeEmail(record)} />
                    </Tooltip>
                </>
            )}
            {(record?.invoiceError) && <Tooltip title={<div className="d-flex flex-column">
                <Typography.Text className='strong' style={{ color: 'white' }}>Errores en automatización</Typography.Text>
                <Typography.Text style={{ fontSize: '10px', color: 'white' }}>{record?.invoiceError}</Typography.Text>
            </div>}>
                <WarningOutlined className="" style={{ color: '#b7b7b7' }} />
            </Tooltip>}
            {record.pdf &&
                (downloading === record.pdf ? (
                    <LoadingOutlined />
                ) : (
                    <FilePdfOutlined
                        className="secondary clickable itemHoverPrimary"
                        onClick={() => {
                            DownloadFile({
                                type: "pdf",
                                url: record.pdf,
                                name: "Recibo-de-venta",
                                setdownloading: (r) => setdownloading(r),
                            });
                        }}
                    />
                ))}
            {record.status === "completed" &&
                record.invoice &&
                !record.isGlobal &&
                (downloading === record.invoice ? (
                    <LoadingOutlined />
                ) : (
                    <DownloadOutlined
                        className="secondary clickable itemHoverPrimary"
                        onClick={() => {
                            DownloadInvoice({
                                type: "pdf",
                                invoice: {
                                    ...record,
                                    id: record.invoice,
                                    uuid: record.invoice,
                                },
                                authUser: auth.currentUser,
                                setdownloading: (v) => setdownloading(v),
                            });
                        }}
                    />
                ))}
            {record.status === "pending" ? (
                downloading === record.id ? (
                    <LoadingOutlined />
                ) : (
                    <Popconfirm onConfirm={async () => deleteReceipt(record)} title="¿Eliminar el recibo?" okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar" cancelButtonProps={{ type: 'ghost' }}>
                        <DeleteOutlined

                            className="secondary itemHoverDanger clickable"
                        />
                    </Popconfirm>
                )
            ) : (
                <></>
            )}
        </Space>
    }
    // const payments = (record?.payments ?? []).length;
    // const hasGlobalInvoice = globalInvoicesId?.includes(record?.invoice)
    // if (!payments && !hasGlobalInvoice) return <></>
    // return (
    // <div className="d-flex flex-column">
    //     <div style={{ marginTop: "4px" }}>
    //         <Tag onClick={() => {
    //             setpageActive(pageActive)
    //             if (!hasGlobalInvoice) return
    //             setShowRelation({
    //                 title: `Facura Global`,
    //                 type: 'invoices',
    //                 db: 'receipts',
    //                 relatedWith: record,
    //                 count: record?.receiptsCount ?? 1,
    //                 elementID: record?.id,
    //                 subdb: 'invoices',
    //                 item: record,
    //                 related: receiptsData.filter((r) => r.invoice === record.invoice),
    //             })
    //         }} >
    //             {payments ? `${payments} pago${payments > 1 ? "s" : ""}` : null}
    //             {hasGlobalInvoice && <Tooltip title={'Recibo con factura global'}><BiWorld className='clickable' style={{ margin: '3px' }} /></Tooltip>}
    //         </Tag>
    //     </div>
    // </div>
    // );

    const ReceiptElement = ({ receipt }) => {

        const rawReceipt = JSON.parse(receipt?.rawData)

        return <Row key={generateCode(10, 'tempRec')} style={{ padding: '10px 0px', borderBottom: '1px solid #fafbfd' }}>

            <div className="d-flex flex-column" style={{ flex: 1 }}>
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#333' }}>{receipt?.clientName ?? 'Sin nombre'}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{receipt?.company ?? receipt?.clientEmail}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{receipt?.id ?? ''}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{moment(rawReceipt?.timestamp).format('DD/MM/YYYY HH:mm')}</Typography.Text>
            </div>
            <div className="d-flex flex-column">
                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#cecece', textAlign: 'end' }}>{returnCurrencyValue(receipt?.total)} {receipt?.currency}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece', textAlign: 'end' }}>{receipt?.status === 'completed' ? 'Completado' : 'En espera'}</Typography.Text>
                <ReceiptActions rawReceipt={receipt} />
            </div>
        </Row>
    }

    const ClientElement = ({ client }) => {

        const rawClient = JSON.parse(client.rawData)

        return <Row key={generateCode(10, 'tempPay')} style={{ padding: '10px 0px', borderBottom: '1px solid #fafbfd', cursor: 'pointer' }} onClick={() => {
            changeRender('clientDetails')
            close()
            return window.history.replaceState(null, `tab`, `/clientDetails?id=${rawClient.fid}`)
        }}>

            <div className="d-flex flex-column" style={{ flex: 1 }}>


                <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', color: '#333' }}>{client?.name}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{client?.company ?? client?.email}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{client?.id ?? ''}</Typography.Text>
                <Typography.Text style={{ fontWeight: 'normal', fontSize: '11px', color: '#cecece' }}>{moment(rawClient?.timestamp).format('DD/MM/YYYY HH:mm')}</Typography.Text>
            </div>
            <div className="d-flex flex-column">

            </div>
        </Row>
    }







    const PaymentsContainer = () => {
        return (
            <div className="d-flex flex-column" style={{ padding: '10px 10px', border: '0.1 px solid #fafbfd' }}>
                <div className="d-flex flex-column" style={{ padding: '0px 10px' }}>
                    {(paymentsAmount <= 3 ? smallPayments : payments).map((payment, index) => {
                        return <PaymentElement key={generateCode(10, 'search')} payment={payment} />
                    })}
                </div>
                <Row justify="space-between" style={{ padding: '10px 0px' }}>
                    {localData.length > 0 && <Typography.Link style={{ fontSize: '12px', fontWeight: 'bold' }}
                        onClick={() => {
                            setMakeGlobalQuery(true)
                            makeQ()
                        }}
                    >Buscar en toda la cuenta</Typography.Link>}

                    {payments.length > 3 && <Typography.Link style={{ fontSize: '12px', fontWeight: 'bold' }}
                        onClick={() => {
                            if (paymentsAmount === 3) {
                                setPaymentsAmount(payments.length)
                            } else {
                                setPaymentsAmount(3)
                            }
                        }}
                    >{paymentsAmount === 3 ? 'Cargar todo' : 'Menos'}</Typography.Link>}
                </Row>
            </div>
        )
    }


    const InvoiceContainer = () => {
        return <div className="d-flex flex-column" style={{ padding: '10px 10px', border: '1px solid #fafbfd' }}>
            <div className="d-flex flex-column" style={{ padding: '0px 10px' }}>
                {(invoicesAmount <= 3 ? smallInvoices : invoices).map((invoice, index) => {
                    return <InvoiceElement key={generateCode(10, 'search')} invoice={invoice} />
                })}

            </div>
            <Row justify="end" style={{ padding: '10px 0px' }}>
                {invoices.length > 3 && <Typography.Link style={{ fontSize: '12px', fontWeight: 'bold' }}
                    onClick={() => {
                        if (invoicesAmount === 3) {
                            setInvoicesAmount(invoices.length)
                        } else {
                            setInvoicesAmount(3)
                        }
                    }}
                >{invoicesAmount === 3 ? 'Cargar todo' : 'Menos'}</Typography.Link>}
            </Row>
        </div>
    }

    const ReceiptsContainer = () => {
        return <div className="d-flex flex-column" style={{ padding: '10px 10px', border: '1px solid #fafbfd' }}>
            <div className="d-flex flex-column" style={{ padding: '0px 10px' }}>
                {(receiptsAmount <= 3 ? smallReceipts : receipts).map((receipt, index) => {
                    return <ReceiptElement key={generateCode(10, 'search')} receipt={receipt} />
                })}

            </div>
            <Row justify="end" style={{ padding: '10px 0px' }}>
                {receipts.length > 3 && <Typography.Link style={{ fontSize: '12px', fontWeight: 'bold' }}
                    onClick={() => {
                        if (receiptsAmount === 3) {
                            setReceiptsAmount(receipts.length)
                        } else {
                            setReceiptsAmount(3)
                        }
                    }}
                >{receiptsAmount === 3 ? 'Cargar todo' : 'Menos'}</Typography.Link>}
            </Row>
        </div>
    }

    const ClientsContainer = () => {
        return <div className="d-flex flex-column" style={{ padding: '10px 10px', border: '1px solid #fafbfd' }}>
            <div className="d-flex flex-column" style={{ padding: '0px 10px' }}>
                {(clientsAmount <= 3 ? smallClients : clients).map((client, index) => {
                    return <ClientElement key={generateCode(10, 'search')} client={client} />
                })}

            </div>
            <Row justify="end" style={{ padding: '10px 0px' }}>
                {clients.length > 3 && <Typography.Link style={{ fontSize: '12px', fontWeight: 'bold' }}
                    onClick={() => {
                        if (clientsAmount === 3) {
                            setClientsAmount(receipts.length)
                        } else {
                            setClientsAmount(3)
                        }
                    }}
                >{clientsAmount === 3 ? 'Cargar todo' : 'Menos'}</Typography.Link>}
            </Row>
        </div>
    }


    const Element = ({ title, useKey, icon, amount }) => {
        return <>
            <div className='d-flex flex-row'
                onClick={() => {
                    if (amount <= 0) return
                    setTab(useKey)
                }}
                style={{
                    borderBottom: useKey === tab ? '2px solid #8672FD' : 'none',
                    padding: '0px 0px 5px 0px',
                    alignItems: 'center',
                    cursor: amount <= 0 ? 'default' : 'pointer',
                    margin: '0px 5px'
                }}
            >
                {icon ?? <></>}
                <Typography.Text style={{ fontSize: '13px', color: '#9D9D9D', padding: 0 }}>{title}</Typography.Text>
                <Tag color={amount <= 0 ? "#F5F5F5" : '#F5F5F5'} style={{ marginLeft: '5px', fontSize: '11px', padding: '1px 3px', color: '#9D9D9D' }}>{amount ?? 0}</Tag>
            </div>
        </>
    }

    const ElementSelection = () => {
        if ((payments.length <= 0 && invoices.length <= 0 && receipts.length <= 0) || loading) return <></>
        return <Row justify="space-between" style={{
            padding: '0px 10px',
            borderBottom: '2px solid #F5F5F5',
        }}>
            <Row wrap >
                <Element title={"Pagos"} useKey={"payments"} icon={<CiMoneyBill style={{ marginRight: '5px', color: '#9D9D9D' }} />} amount={payments.length} />
                <Element title={"Facturas"} useKey={"invoices"} icon={<AiOutlineBook style={{ marginRight: '5px', color: '#9D9D9D' }} />} amount={invoices.length} />
                <Element title={"Recibos"} useKey={"receipts"} icon={<AiOutlinePaperClip style={{ marginRight: '5px', color: '#9D9D9D' }} />} amount={receipts.length} />
                <Element title={"Clientes"} useKey={"clients"} icon={<AiOutlineUserSwitch style={{ marginRight: '5px', color: '#9D9D9D' }} />} amount={clients.length} />

            </Row>
            {/* <Typography.Text style={{ fontSize: '10px', color: '#cecece' }}>nueva búsqueda</Typography.Text> */}
        </Row>
    }


    const SearchContainer = () => {
        return <Form initialValues={{
            searchinput: searchTermState
        }} onKeyUp={(event) => {
            //HANDLE ENTER BUTTON TO PERFOM SEARCH
            if (event.keyCode === 13 && document.getElementById('searchinput').value !== '') {
                if (loading) return
                makeQ()
            }

        }} layout='vertical' className='d-flex flex-column' style={{ backgroundColor: "transparent", width: '100%', borderBottom: '0px solid #787878', borderRadius: 0 }}>
            <Row align="middle" style={{ height: '100%', padding: '10px 15px 10px 15px' }} onClick={() => {
                //START FOCUS ON SEARCH INPUT
                document.getElementById('searchinput').focus();

            }}>
                {loading ? <LoadingOutlined style={{ fontSize: '17px', color: "#cecece" }} /> : <SearchOutlined style={{ fontSize: '17px', color: "#cecece" }} />}
                <Form.Item name="searchinput" className="removeBorder" label="" style={{ flex: 1, margin: '0px 5px', backgroundColor: 'transparent', border: 'none' }}>
                    <Input id="searchinput" className='searchModalInput' style={{ border: '1px solid ' }} placeholder='Buscar...' />
                </Form.Item>
                <CloseOutlined className="clickable" onClick={close} style={{ fontSize: '17px', color: "#cecece" }} />
            </Row>
            <ElementSelection />
        </Form>
    }



    const LoadingSkeleton = () => {
        return <div className="d-flex flex-column" style={{ padding: '10px', borderTop: '1px solid #F5F5F5' }}>
            <div style={{ borderBottom: '0.1px solid #f7f7f7', padding: '5px 0px' }}>
                <Skeleton active paragraph={{ rows: 0 }} avatar style={{ margin: 0, padding: 0 }} />
            </div>
            <div style={{ borderBottom: '0.1px solid #f7f7f7', padding: '5px 0px' }}>
                <Skeleton active paragraph={{ rows: 0 }} avatar style={{ margin: 0, padding: 0 }} />
            </div>
            <div style={{ borderBottom: '0.1px solid #f7f7f7', padding: '5px 0px' }}>
                <Skeleton active paragraph={{ rows: 0 }} avatar style={{ margin: 0, padding: 0 }} />
            </div>
        </div>
    }

    const Content = () => {

        if (payments.length <= 0 && invoices.length <= 0 && receipts.length <= 0 && !searchTermState) return <></>
        if (payments.length <= 0 && invoices.length <= 0 && receipts.length <= 0 && searchTermState) return <Row justify="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No tenemos recursos con la búsqueda" />
        </Row>
        else
            return <>
                {tab === 'payments' ? <PaymentsContainer /> : tab === 'invoices' ?
                    <InvoiceContainer /> : tab === 'clients' ? <ClientsContainer /> : <ReceiptsContainer />}
            </>
    }

    const FilterByStatus = () => {

        const handleFilter = (status) => {
            if (filterByStatus === status) {
                setFilterByStatus(null)
            } else {
                setFilterByStatus(status)
            }

            makeQ(status)

        }

        return <div style={{ padding: '10px 10px', borderBottom: '1px solid #fafbfd' }}>
            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold', color: '#9D9D9D' }}>Filtrar por estado</Typography.Text>
            <Row style={{ padding: '10px 0px' }}>
                <Tag onClick={() => {
                    if (loading) return
                    handleFilter('succeeded')
                }} className={filterByStatus === 'succeeded' ? 'activeTag clickable' : 'clickable'} style={{ fontSize: '11px', padding: '1px 3px', color: '#9D9D9D' }}>Completado</Tag>
                <Tag onClick={() => {
                    if (loading) return
                    handleFilter('pending')
                }} className={filterByStatus === 'pending' ? 'activeTag clickable' : 'clickable'} style={{ fontSize: '11px', padding: '1px 3px', color: '#9D9D9D' }}>En espera</Tag>
                <Tag onClick={() => {
                    if (loading) return
                    handleFilter('review_requested')
                }} className={filterByStatus === 'review_requested' ? 'activeTag clickable' : 'clickable'} style={{ fontSize: '11px', padding: '1px 3px', color: '#9D9D9D' }}>Por revisar</Tag>
                <Tag onClick={() => {
                    if (loading) return
                    handleFilter('canceled')
                }} className={filterByStatus === 'canceled' ? 'activeTag clickable' : 'clickable'} style={{ fontSize: '11px', padding: '1px 3px', color: '#9D9D9D' }}>Cancelado</Tag>
                <Tag onClick={() => {
                    if (loading) return
                    handleFilter('valid')
                }} className={filterByStatus === 'valid' ? 'activeTag clickable' : 'clickable'} style={{ fontSize: '11px', padding: '1px 3px', color: '#9D9D9D' }}>Válida</Tag>
            </Row>
        </div>
    }

    return (
        <Modal closable={false} visible={open} onCancel={close}
            closeIcon={<></>} style={{ padding: 0, top: 20, borderRadius: '10px', border: 'none' }} bodyStyle={{
                padding: 0,
                borderRadius: '10px',
                border: 'none',
                overflow: 'hidden',
            }} width={window.innerWidth < 800 ? '80%' : '45%'} className='removeBorder' footer={null} title={null} >
            <div className='d-flex flex-column' style={{ padding: 0, borderRadius: '10px' }}>
                <SearchContainer />
                <FilterByStatus />
                {loading ? <LoadingSkeleton /> : <Content />}

            </div>
        </Modal>
    );
}

export default SearchModal;