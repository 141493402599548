import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Row, Typography, message } from "antd";
import { Suspense, useContext, useEffect, useState } from "react";
import TeamContext from "../../context/teamcontext/TeamContext";
import { useFirestoreDoc } from "reactfire";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import moment from "moment";
import { UseUserDefaults } from "../../customHooks/useUserDefaults";
import { TranslatePeriodicityToObject } from "../../app/functions/helpers";
import ServiceTaxModal from "../services/ServiceTaxModal";
import FiscalDefaults from "../drawers/FiscalDefaults";
import { DefaultsForm } from "../integrations/FacturapiModal";



const InvoiceSettings = ({ open, close, edit, userDocument }) => {
    const { team, getCurrentTeam } = useContext(TeamContext)
    const [periodicity, setPeriodicity] = useState(moment())
    const [fiscalForm] = Form.useForm();
    const ref = doc(getFirestore(), "teams", team?.id)
    const { data } = useFirestoreDoc(ref);
    const { defaults } = UseUserDefaults({ userDocument: data.data(), team: data.data() })
    const [saving, setSaving] = useState(false)
    const [addTax, setAddTax] = useState(false);
    const [defaultTaxes, setDefaultTaxes] = useState(defaults?.defaultTaxes)


    useEffect(() => {
        setPeriodicity(defaults?.periodicity ?? '')
        getCurrentTeam(team?.id)
        //eslint-disable-next-line
    }, [])

    // const DefaultsForm = () => {
    //     return <>
    //         <SectionDivider title="Folio y Serie" />

    //         <Form.Item label="Siguiente folio a utilizar en ingresos" name="invoice_folio" help={<Row align="middle">
    //             <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
    //             <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Si emitiste una factura antes, añade el último folio que utilizaste, nosotros continuaremos a partir de ese</Typography.Text>
    //         </Row>}>
    //             <Input className="pro-input" type="number" step="1" min="1" />
    //         </Form.Item>
    //         <Form.Item label="Siguiente folio a utilizar en complementos de pago" name="invoice_folio_payments" help={<Row align="middle">
    //             <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
    //             <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Utilizaremos este valor para los complementos de pago.</Typography.Text>
    //         </Row>}>
    //             <Input className="pro-input" type="number" step="1" min="1" />
    //         </Form.Item>
    //         <Form.Item label="Siguiente folio a utilizar en facturas de egreso" name="invoice_folio_egress" help={<Row align="middle">
    //             <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
    //             <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Utilizaremos este valor para los complementos de egreso.</Typography.Text>
    //         </Row>}>
    //             <Input className="pro-input" type="number" step="1" min="1" />
    //         </Form.Item>
    //         <Form.Item label="Serie por defecto" name="invoice_serie" help={<Row align="middle">
    //             <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
    //             <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Ayudará a identificar tus facturas</Typography.Text>
    //         </Row>}>
    //             <Input className="pro-input" />
    //         </Form.Item>
    //         <Form.Item label="Serie de facturas de complemento de pago" name="invoice_serie_payments" help={<Row align="middle">
    //             <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
    //             <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Ayudará a identificar tus facturas</Typography.Text>
    //         </Row>}>
    //             <Input className="pro-input" />
    //         </Form.Item>
    //         <Form.Item label="Serie de factura de egreso" name="invoice_serie_egress" help={<Row align="middle">
    //             <InfoCircleFilled style={{ fontSize: '10px', color: '#cecece' }} />
    //             <Typography.Text style={{ fontSize: '10px', marginLeft: '5px' }} type="secondary">Ayudará a identificar tus facturas</Typography.Text>
    //         </Row>}>
    //             <Input className="pro-input" />
    //         </Form.Item>
    //     </>
    // }


    const SectionDivider = ({ title }) => {
        return <div className='d-flex flex-row' style={{ backgroundColor: '#fafbfd', padding: '10px 0px', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', width: '100%', alignContent: 'center', borderRadius: '10px' }}>
            <Typography.Text style={{ alignSelf: 'center' }} className="strong">{title}</Typography.Text>
        </div>
    }



    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "40%"} style={{ top: 20 }} >
            <Row justify="space-between" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                {addTax && <Suspense>
                    <ServiceTaxModal open={addTax} add={(tax) => {
                        var txs = defaultTaxes
                        txs.push(tax)
                        setDefaultTaxes(txs)
                        setAddTax(false)
                    }} close={() => setAddTax(false)} />
                </Suspense>}
                <Form form={fiscalForm} layout="vertical" initialValues={{ ...defaults, periodicity: TranslatePeriodicityToObject(defaults?.periodicity ?? ''), limitHourToPay: moment().hour(defaults?.limitHourToPay), invoice_folio: (team?.defaults?.invoice_folio ?? team.invoicesCreated) + 1, invoice_serie: team?.defaults?.invoice_serie ?? 'gigstack', invoice_folio_payments: (team?.defaults?.invoice_folio_payments ?? 0) + 1, invoice_serie_payments: team?.invoice_serie_payments ?? 'GIG', invoice_folio_egress: team?.invoice_folio_egress ?? 1, invoice_serie_egress: team?.invoice_serie_egress ?? 'GIG' }} onFinish={async (v) => {
                    try {
                        setSaving(true)
                        var toUpdate = {
                            ...defaults,
                            ...v,
                        }
                        toUpdate.periodicity = periodicity
                        toUpdate.defaultTaxes = defaultTaxes ?? []
                        toUpdate.invoice_folio = parseInt(v.invoice_folio - 1)
                        toUpdate.invoice_folio_payments = parseInt(v.invoice_folio_payments - 1)
                        await updateDoc(ref, {
                            defaults: toUpdate,
                            invoice_folio: parseInt(v.invoice_folio - 1),
                            invoice_folio_payments: parseInt(v.invoice_folio_payments - 1),
                            invoice_folio_egress: parseInt(v.invoice_folio_egress - 1),
                            invoice_serie: v.invoice_serie,
                            invoice_serie_payments: v.invoice_serie_payments,
                            invoice_serie_egress: v.invoice_serie_egress,
                            integrationsSetup: team?.integrationsSetup?.filter((v) => v !== 'setDefaults') ?? []
                        })
                        message.success('Datos guardados')
                        setSaving(false)
                        getCurrentTeam(team?.id)
                    } catch (error) {
                        setSaving(false)
                        message.error(error?.message ?? 'Ocurrió un error al guardar los datos')
                    }
                }}>

                    <Row justify="space-between">
                        <Typography.Title level={4} style={{ flex: 1 }}>Configura tus facturas</Typography.Title>
                        <CloseOutlined className="clickable" onClick={() => close()} />
                    </Row>
                    <Row style={{ marginBottom: '15px' }}>
                        <Typography.Text className='' type="secondary" style={{ flex: 1, fontSize: '11px' }}>Utilizaremos esta información por defecto cuando no recibamos todos los detalles para las automatizaciones</Typography.Text>
                    </Row>
                    <FiscalDefaults setTaxes={(taxes) => {
                        setDefaultTaxes(taxes)
                        setAddTax(false)
                    }} setProductKey={(k) => {
                        fiscalForm?.setFieldValue('product_key', k)
                    }} />
                    <SectionDivider title="Folio y Serie" />
                    <DefaultsForm />
                    <Row style={{ paddingTop: '25px', width: '100%' }} justify="center">
                        <Button htmlType="submit" loading={saving} type="primary">Guardar predeterminados</Button>
                    </Row>
                </Form>
            </Row>
        </Modal >
    );
}


export default InvoiceSettings;