import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react'


/**
 * It's a function that returns a div with a clickable class, that has a background image, a title, and
 * a description
 * @returns A React component
 */
function HelperCard({ squareImg = false, onClick, circularImg = false ,supportImageUrl = "", openIntercom = false, title = "¿Necesitas ayuda para obtener tus sellos?", description = "Agenda una llamada conmigo y puedo ayudarte, prometo que no tardaremos ni 15 min.", marginTop = '20px' }) {
    return <div id={openIntercom ? "intercomButtonOpen" : ''} onClick={onClick} style={{ width: '100%', padding: '15px 15px', border: '1px solid #E7E7E7', backgroundColor: '#fafbfd', borderRadius: '10px', marginTop: marginTop, alignItems: 'center' }} className="d-flex flex-row clickable">
        {circularImg ? <div> <img style={{ height: '50px', width: '50px', borderRadius: '50%' }} src={supportImageUrl} alt="" /></div> : null}
        {(supportImageUrl !== null && !circularImg)  && <div style={squareImg ? { height: '50px', width: '50px', backgroundImage: `url(${supportImageUrl})`, backgroundSize: 'cover' } : { height: '20px', width: '50px', backgroundImage: `url(${supportImageUrl})`, backgroundSize: 'cover' }}></div>}
        <div className="d-flex flex-column" style={{ marginLeft: '20px', flex: 1 }}>
            <Typography.Title style={{ fontSize: '13px', margin: 0, padding: 0 }} strong>{title}</Typography.Title>
            <Typography.Text style={{ fontSize: '12px', margin: 0, padding: 0, marginTop: '5px' }} type="secondary">{description}</Typography.Text>
        </div>
        <RightOutlined style={{ color: '#cecece' }} />
    </div>
}

export const GeneralInfoCard = ({ marginTop, title, description }) => {
    return <div style={{ width: '100%', padding: '15px 15px', border: '1px solid #E7E7E7', backgroundColor: '#fafbfd', borderRadius: '10px', marginTop: marginTop, alignItems: 'center' }} className="d-flex flex-row">
        <div className="d-flex flex-column" style={{ marginLeft: '20px', flex: 1 }}>
            <Typography.Title style={{ fontSize: '13px', margin: 0, padding: 0 }} strong>{title}</Typography.Title>
            <Typography.Text style={{ fontSize: '12px', margin: 0, padding: 0, marginTop: '5px' }} type="secondary">{description}</Typography.Text>
        </div>
        <InfoCircleOutlined style={{ color: '#cecece' }} />
    </div>
}

export default HelperCard;