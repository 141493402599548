import { Button, Form, Input, message, Modal, notification, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { generalreactSelectStyles } from '../../app/functions/styles';
import { useForm } from 'antd/lib/form/Form';

function CancelInvoiceModal({ open, close, invoice, cancelInvoice }) {

    const [loading, setloading] = useState(false)
    const auth = useAuth();
    const [form] = useForm()
    /**
     * It cancels an invoice.
     * @param invoice - The invoice object.
     */
    const CancelInvoice = async (invoice) => {

        try {
            setloading(true)
            const req = { invoicingIntegration: 'facturapi', type: 'cancel_invoice', test: !invoice.livemode, id: invoice.id, ...invoice }


            await SignedInternalAPIRequest(req, 'invoicing', auth.currentUser)
            notification.success({ placement: "bottomRight", description: 'Hemos solicitado la cancelación', })
            cancelInvoice(invoice)
            setloading(false)
            close()
        } catch (error) {
            message.error(error.message)
            setloading(false)
        }
    }

    useEffect(() => {
        // console.log("CANCEL INVOICE MODAL: ", invoice)
        form.setFieldValue('substitution', invoice?.uuid ?? null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (<Modal visible={open} onCancel={() => close()} footer={null} className={'pro-modal pro-modal-transp'}>
        <div className={'pro-modal-header'}>
            <Typography.Title level={4} style={{ padding: 0, margin: 0, }}>Cancelar factura <span style={{ color: '#b7b7b7', fontSize: '16px', margin: 0, padding: 0 }}></span></Typography.Title>
            <Typography.Text style={{ padding: 0, margin: 0, marginBottom: '15px' }}>{invoice.series}-{invoice.folio_number}</Typography.Text>
            <Form layout="vertical" name="cancelInvoice" style={{ marginTop: '25px' }} onFinish={(obj) => {


                Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
                if (!obj.motive) {
                    return message.error('Debes especificar el motivo de la cancelación')
                }

                CancelInvoice({ id: invoice.id, livemode: invoice.livemode, ...obj, motive: obj.motive.value })
            }}>
                <Form.Item label="Motivo de la cancelación" name="motive" required={[{ required: true, message: 'Por favor selecciona un motivo.' }]} >
                    <Select
                        styles={generalreactSelectStyles}
                        className="pro-select-input"
                        //onChange={setSelectedOption}
                        options={[{ value: '01', label: 'Comprobante emitido con errores con relación' }, { value: '02', label: 'Comprobante emitido con errores sin relación' }, { value: '03', label: 'No se llevó a cabo la operación' }, { value: '04', label: 'Operación nominativa relacionada en la factura global' }]}
                        isLoading={false}
                        placeholder="Motivo de la cancelación"

                    />

                </Form.Item>
                <Form.Item label="Sustitución" name="substitution" style={{ marginTop: '20px' }} help={<Typography.Text type="secondary" style={{ fontSize: '10px' }}>ID de la factura que sustituye a la factura que se está cancelando.</Typography.Text>}>
                    <Input placeholder="UUID" className="pro-input" defaultValue={invoice?.actionType === 'substitute' ? (invoice?.uuid ?? null) : null} />
                </Form.Item>

                <Row justify='center' style={{ marginTop: '55px' }}>
                    <Button loading={loading} danger htmlType="submit">Cancelar factura</Button>
                </Row>
            </Form>
        </div>
    </Modal>);
}

export default CancelInvoiceModal;