import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react'


import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';



function PayPalModal({ open, close, userDocument }) {


    const [formRef] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const analytics = useAnalytics()

    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4}>Agrega tu cuenta de PayPal</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                </Row>
                <Typography.Text type="secondary" >Consigue tus credenciales en <a href="https://developer.paypal.com/home" target="_blank" rel="noreferrer">https://developer.paypal.com/home</a>.</Typography.Text>
                <a href="http://help.gigstack.pro/es/articles/5872073-comienza-a-cobrar-con-paypal" target="_blank" rel="noreferrer" style={{ fontSize: '13px', marginTop: '15px' }} className="strong">Aprende a configurar PayPal haciendo click aquí</a>
                <Form form={formRef} initialValues={{ ...userDocument?.paypal }} layout="vertical" onFinish={async (v) => {
                    try {
                        setLoading(true)
                        await updateDoc(doc(getFirestore(), 'users', userDocument?.uid), { paypal: { ...v, lastUpdated: moment().valueOf() } })
                        setLoading(false)
                        message.success('PayPal actualizado')
                        logEvent(analytics, 'paypalConfigured', {});
                        close()
                    } catch (error) {
                        message.error(error.message)
                        setLoading(false)
                    }
                }} >

                    <Form.Item label="ID del cliente" name="client_id" style={{ marginTop: '20px' }} rules={[{ required: true, message: 'Añade el ID del cliente' }]}>
                        <Input className="pro-input" placeholder="ID del cliente" />
                    </Form.Item>
                    <Form.Item label="Clave secreta" name="secret_key" rules={[{ required: true, message: 'Clave secreta' }]}>
                        <Input className="pro-input" placeholder="Clave secreta" />
                    </Form.Item>


                    <Typography.Text className="custom-label">URL para webhook</Typography.Text>
                    <Typography.Paragraph style={{ border: '1px dotted #b7b7b7', textAlign: 'center', alignSelf: 'center', width: '100%', fontSize: '11px', padding: '10px 15px', color: '#b7b7b7', borderRadius: '10px' }} copyable>https://us-central1-gigstackpro.cloudfunctions.net/paypalWebhookPayment</Typography.Paragraph>
                    <div className="d-flex flex-column">
                        <Typography.Text className="custom-label">Lista de eventos</Typography.Text>
                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Selecciona todos estos eventos en el portal de PayPal</Typography.Text>
                    </div>
                    <Form.Item name="PaymentCaptureCompleted" valuePropName="checked" style={{ marginBottom: '0px' }}>
                        <Checkbox>Payment capture completed</Checkbox>
                    </Form.Item>
                    <Form.Item name="PaymentCaptureDenied" valuePropName="checked" style={{ marginBottom: '0px' }}>
                        <Checkbox>Payment capture denied</Checkbox>
                    </Form.Item>
                    <Form.Item name="PaymentCapturePending" valuePropName="checked" style={{ marginBottom: '0px' }}>
                        <Checkbox>Payment capture pending</Checkbox>
                    </Form.Item>
                    <Form.Item name="PaymentCaptureRefunded" valuePropName="checked" style={{ marginBottom: '0px' }}>
                        <Checkbox>Payment capture refunded</Checkbox>
                    </Form.Item>
                    <Form.Item name="PaymentCaptureReversed" valuePropName="checked" >
                        <Checkbox>Payment capture reversed</Checkbox>
                    </Form.Item>
                    <Row justify='center'>

                        <Button loading={loading} type="primary" htmlType="submit">Guardar</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default PayPalModal;