import { Layout, Row } from "antd";
import React, { useState } from "react";
import LoginWidget from "../../../widgets/auth/LoginWidget";
import RegisterWidget from "../../../widgets/auth/RegisterWidget";
import { useParams } from "react-router-dom";
import { SearchParamInURL } from "../../../app/functions/helpers";

/**
 * It renders a layout with a row and two columns, the first one renders a login or signup widget
 * depending on the url parameter, the second one renders a background image with a quote
 */
function OAuthLayout({ type = "login" }) {
  const { tab } = useParams();
  const [toRender, settoRender] = useState(tab ?? "login");
  const integration = SearchParamInURL("integration") ?? 'taxo';
  const password = SearchParamInURL("password");
  const email = SearchParamInURL("email");
  return (
    <Layout style={{ margin: 0, padding: 0 }}>
      <Layout.Content style={{ margin: 0, padding: 0 }}>
        <Row>
          {toRender === "login" ? (
            <LoginWidget changeRender={() => settoRender("signup")} integration={integration} password={password} email={email} allowLoginProviders={false} allowRegister={false} />
          ) : (
            <RegisterWidget changeRender={() => settoRender("login")} />
          )}
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default OAuthLayout;
