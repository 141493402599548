import { QuestionCircleOutlined } from '@ant-design/icons';
import { Row, Tooltip, Typography } from 'antd';
import React from 'react';


function TableTitle({ title, tooltip }) {
    return <Row align="middle">
        <Typography.Text className="strong" style={{ fontSize: '13px' }}>{title}</Typography.Text>
        {tooltip && <Tooltip title={tooltip}>
            <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#c7c7c7', fontSize: '13px' }} />
        </Tooltip>}
    </Row>
}

export default TableTitle;