import { Button, Form, Modal, Radio, Row, Select, Space, Typography, message } from "antd"
import moment from "moment"
import { useState } from "react"
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests"
import { DownloadXLSX } from "../../app/pages/ClientDetails"
import { useAuth } from "reactfire"


export const DownloadFilesModal = ({ open, close, data = {} }) => {

    const auth = useAuth()

    const { client, readableType } = data

    const description = client ? `Descarga las facturas de ${client.name} en diferentes formatos.` : 'Descarga las facturas de tu cuenta en diferentes formatos.'
    const today = [moment().startOf('day'), moment()]
    const lastSevenDays = [moment().startOf('day').subtract(7, 'days'), moment()]
    const currentMonth = [moment().startOf('month'), moment()]
    const lastMonth = [moment().startOf('month').subtract(1, 'month'), moment().endOf('month').subtract(1, 'month')]
    const lastThreeMonths = [moment().startOf('month').subtract(3, 'month'), moment()]

    const [type, setType] = useState('excel')
    const [period, setPeriod] = useState('today')
    const [loading, setLoading] = useState(false)

    const handlePeriod = () => {
        switch (period) {
            case 'today':
                return [today[0].valueOf(), today[1].valueOf()]
            case 'thisWeek':
                return [lastSevenDays[0].valueOf(), lastSevenDays[1].valueOf()]
            case 'thisMonth':
                return [currentMonth[0].valueOf(), currentMonth[1].valueOf()]
            case 'lastMonth':
                return [lastMonth[0].valueOf(), lastMonth[1].valueOf()]
            case 'lastThreeMonths':
                return [lastThreeMonths[0].valueOf(), lastThreeMonths[1].valueOf()]
            default:
                return [today[0].valueOf(), today[1].valueOf()]
        }
    }

    const handleDownload = async () => {
        try {
            setLoading(true)

            const p = handlePeriod()

            if (type === 'excel') {
                await DownloadXLSX({ endpoint: `gigstackpro.pro.cleaned_invoices_view`, type: "invoices", setDownload: () => { }, auth })
            } else {
                await SignedInternalAPIRequest({
                    clientId: client?.id ?? null,
                    startDate: p[0],
                    endDate: p[1],
                    fileType: type
                }, `exportFiles/v1/invoices/files`, auth.currentUser, { isFile: true, fileName: `Facturas-${moment().format('DD/MM/YYYY')}`, fileExtension: type === 'excel' ? 'xlsx' : 'zip' }, 'POST')
                message.success(`Se ha descargado el archivo correctamente`)
            }

            setLoading(false)
            close()
        } catch (error) {
            setLoading(false)
            message.error(error.message ?? 'Ocurrió un error al descargar los archivos')
        }
    }

    return <Modal visible={open} onCancel={() => close()} title={null} footer={null} width={window.innerWidth < 900 ? '70%' : '800px'}>
        <Typography.Title level={4}>Descargar {readableType}</Typography.Title>
        <Typography.Text type="secondary">{description} </Typography.Text>

        <Form layout="vertical" style={{ marginTop: '15px' }} onFinish={() => {
            handleDownload()
        }}>
            <Form.Item label="Tipo de descarga" name="type" rules={[{ required: true, message: "El tipo de factura es necesario" }]}>
                <Select
                    options={[
                        { label: 'Excel', value: 'excel' },
                        { label: 'Zip con arhivos PDF', value: 'pdf' },
                        { label: 'Zip con archivos XML', value: 'xml' },
                        // { label: 'Zip con archivos PDF y XML', value: 'pdf-xml' },
                    ]}
                    onSelect={(value) => setType(value)}
                />
            </Form.Item>
            {type !== 'excel' && <div style={{ width: '100%', marign: '15px' }}>
                <Form.Item label="Intervalo de fechas" rules={[{ required: true, message: "La contraseña es necesaria" }]} style={{ width: '100%' }}>
                    <Radio.Group value={period} buttonStyle="solid" style={{ width: '100%' }} onChange={(e) => {
                        console.log(e.target.value)
                        setPeriod(e.target.value)
                    }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Radio value={'today'} style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>
                                    <Typography.Text style={{ marginRight: '20px' }}>Hoy</Typography.Text>
                                    {period === 'today' && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{today[0].format('LLL')} - {today[1].format('LLL')}</Typography.Text>}
                                </div>
                            </Radio>
                            <Radio value={'thisWeek'}>
                                <div>
                                    <Typography.Text style={{ marginRight: '20px' }}>Últimos 7 días</Typography.Text>
                                    {period === 'thisWeek' && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{lastSevenDays[0].format('LLL')} - {lastSevenDays[1].format('LLL')}</Typography.Text>}
                                </div>
                            </Radio>
                            <Radio value={'thisMonth'}>
                                <div>
                                    <Typography.Text style={{ marginRight: '20px' }}>Este mes</Typography.Text>
                                    {period === 'thisMonth' && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{currentMonth[0].format('LLL')} - {currentMonth[1].format('LLL')}</Typography.Text>}
                                </div>
                            </Radio>
                            <Radio value={'lastMonth'}>
                                <div>
                                    <Typography.Text style={{ marginRight: '20px' }}>Mes anterior</Typography.Text>
                                    {period === 'lastMonth' && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{lastMonth[0].format('LLL')} - {lastMonth[1].format('LLL')}</Typography.Text>}
                                </div>
                            </Radio>
                            <Radio value={'lastThreeMonths'}>
                                <div>
                                    <Typography.Text style={{ marginRight: '20px' }}>Últimos 3 meses</Typography.Text>
                                    {period === 'lastThreeMonths' && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{lastThreeMonths[0].format('LLL')} - {lastThreeMonths[1].format('LLL')}</Typography.Text>}
                                </div>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </div>}

            <Row justify="center" style={{ marginTop: '30px' }}>
                <Button loading={loading} type="primary" htmlType="submit" style={{ width: '30%' }}>
                    Descargar
                </Button>
            </Row>
        </Form>
    </Modal >
}