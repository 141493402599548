import { message, Modal, Typography } from 'antd';
import { logEvent } from 'firebase/analytics';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useAnalytics, useAuth } from 'reactfire';


function OpenBankingModal({ open, close, hideBanksBy = "sat", updateSatKey = false }) {

    const auth = useAuth()
    const analytics = useAnalytics()
    /**
     * It creates a script tag, adds an event listener to it, and appends it to the body
     * @returns the function UseScript.
     */
    const UseScript = async () => {
        const node = document.createElement('script');
        node.src = 'https://cdn.finerio.mx/widget/1.4.0/fc-bank-aggregation.js';
        node.type = 'text/javascript';
        node.async = true;
        const component = document.querySelector("fc-bank-aggregation");
        if (!component) {
            return;
        }
        component.addEventListener("onCallback", async (event) => {
            try {
                await setDoc(doc(getFirestore(), `OBUsers/${auth.currentUser.uid}`),
                    event.detail ? { ...event.detail, owner: auth.currentUser.uid, lastUpdate: moment().valueOf() } : {}
                    , { merge: true })
                logEvent(analytics, 'openBankingAdded', {});
            } catch (error) {
                message.error("Error al conectar con tu cuenta");
            }
        });

        //node.onload = () => CreateWidget(rec)
        document.body.appendChild(node);
    }
    useEffect(() => {
        UseScript();
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Modal visible={open} style={{ top: 10 }} onCancel={() => close()} footer={null} className={'pro-modal pro-modal-transp'}>
            <div className={'pro-modal-header'} style={{ backgroundColor: '#FCFDFC' }}>
                <Typography.Title level={4}></Typography.Title>
                <fc-bank-aggregation
                    client-logo="https://gigstack.s3.us-east-2.amazonaws.com/Logos/gigstack_icon-svg.svg"
                    welcome-head-string="Automatiza tu administración"
                    welcome-string="¡Conecta tus cuentas y facilita tu administración!"
                    show-bank-types={!updateSatKey}
                    hide-banks-by-code={hideBanksBy}
                    background-color="#fafbfd"
                    terms-text-color="#cecece"
                    bank-type={updateSatKey ? 'fiscal' : ''}
                    environment="production"
                    show-zendesk={true}
                    automatic-fetching={true}
                    widget-id="n9hmtgejzCQg3kpLbT4ZFFphX88BG7vCURTybvxYP8KQKXmpbC"
                    customer-name={auth.currentUser.uid}
                    submit-label="Conectar mi cuenta"
                    welcome-text1="Completamente seguro"
                    welcome-text2="Visualiza y descarga tus transacciones" >
                </fc-bank-aggregation>
            </div>
        </Modal>
    );
}

export default OpenBankingModal;