import { Button, Row, Typography, Card } from 'antd';
import React from 'react'
import Lottie from "lottie-react";
import loading from '../../assets/lotties/loading.json'
import error from '../../assets/lotties/error.json'
import success from '../../assets/lotties/success.json'

function LoadingAndResponse({ loading: isLoading = true, isError, errorMessage, backAction }) {
    const errorString = typeof errorMessage === 'string' ? errorMessage : errorMessage?.message ?? 'Ocurrió un error';

    return (<Row justify='center' >
        <div className="d-flex flex-column" style={{ width: '100%' }}>
            <Lottie animationData={
                isLoading ? loading : isError ? error : success
            } style={{ height: '150px', }} loop={isLoading} />
            {(!isError && !errorMessage) ? <Typography.Text style={{ textAlign: 'center', fontSize: '12px' }} type="secondary">{isLoading ? '' : isError ? errorString : ''}</Typography.Text> : isLoading ? '' :
                <Card style={{ borderRadius: '10px', marginTop: '15px', width: '100%' }}>
                    <div className="d-flex flex-column">
                        <Typography.Title level={5}>Ocurrió un error</Typography.Title>
                        <Typography.Text style={{ textAlign: 'start', fontSize: '12px' }} type="secondary">{errorString}</Typography.Text>
                        <div> <Typography.Text className="primary clickable" id="intercomButtonOpen" style={{ fontSize: '10px', marginTop: '10px' }}>Necesito ayuda</Typography.Text></div>
                    </div>
                </Card>
            }
            {(!isLoading && backAction && isError) && <Button style={{ marginTop: '40px' }} onClick={backAction}>Atrás</Button>}
        </div>
    </Row>);
}

export default LoadingAndResponse;