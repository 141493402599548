import { MailOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Modal, Row, Tabs, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import { useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { getItemsAmounts } from '../../app/functions/helpers';
import { reactSelectMultiStyles } from '../../app/functions/styles';
import TeamContext from '../../context/teamcontext/TeamContext';
import ViewTemplates from '../templates/ViewTemplates';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import EmptyTable from '../states/EmptyTable';


//TODO: CHECK MODALS
/**
 * It sends an email
 * @returns A modal that allows you to send an email to a client.
 */
function ComposeEmailModal({ open, close, userDocument, element, subject: incomingSubject = "", client, readableType = "Email", actionText, idRef, data, body: incomingBody, showEmailsView = false }) {
    const { team, billingAccount } = useContext(TeamContext);
    const auth = useAuth();
    let allEmails = [...(client?.bcc ?? []), client.email]
    const emails = [...(allEmails ?? []).map(e => ({ label: e, value: e }))].filter(e => e.label && e.label !== '')
    // console.log("EMAILS: ", emails)
    const [state, setstate] = useState({
        subject: incomingSubject,
        // emails: [
        //     { label: client?.email, value: client?.email }
        // ],
        emails,
        body: incomingBody ?? `Hola ${client?.name}, \n\n\n\n - ${team?.brand?.alias ?? userDocument?.name}`
    })
    const [loading, setloading] = useState(false)
    const [templateLibrary, setTemplateLibrary] = useState(false);
    const [form] = Form.useForm();
    // const { items, currency } = data;
    /**
     * It sends an email
     * @param v - the form values
     * @returns the result of the async function SendEmail.
     */
    const SendEmail = async (v) => {
        try {


            const prepEmails = v.emails.map((e) => e.value)
            const body = {
                "emailSubject": state.subject ?? incomingSubject,//required
                "emailBody": v.body,//required
                "emails": prepEmails,//required
                "message": v.body,
                "messageTwo": "",
                "messageThree": "",
                "goodbye": "",
                "actiondisplay": element.shortURL ? "display:block;" : "display:none;",
                "action": actionText ?? "Continuar",//BUTTON TEXT
                "link": element?.shortURL ?? ``,//BUTTON ACTION LINK
                "type": idRef,//payments,proposals, invoices,notes, etc.
                "hrType": readableType ?? 'Email',//Human readable type ex: Payment
                "elementID": (element?.fid ?? element?.id) || '',
                "attachInvoice": idRef === 'invoices' ? true : null,//ID of the element, ex: payment id.
                "attachPdf": idRef === 'invoices' ? true : null,
                "note": null,
                "invoice": idRef === 'invoices' ? element : null,
                "receipt": idRef === 'receipts' ? data : null,
            }



            if (data?.invoice) {
                body.invoice = data.invoice
            }
            if (data?.note) {
                body.note = data.note
            }


            setloading(true)
            const r = await SignedInternalAPIRequest({ ...body, team: team?.id, billingAccount: billingAccount?.id }, 'requestSendEmailSeats', auth.currentUser);

            setloading(false)

            message.success(r?.message ?? 'Correo enviado correctamente.')
            return close()

        } catch (error) {
            message.error(error?.message ?? 'Error al enviar el correo.')
            setloading(false)
        }
    }


    const SendEmailForm = () => {

        return <Form onFinish={SendEmail} layout='vertical' style={{ width: '100%' }} initialValues={state} form={form}>
            <div className='d-flex flex-column' style={{ width: '100%' }}>
                <Row style={{ marginTop: '10px' }}>
                    <Tag style={{ backgroundColor: '#EFECFE', color: '#8666FF' }}>{readableType}</Tag>
                </Row>
                <Divider style={{ margin: '8px 0px' }} />
                <Row justify="start">
                    <Typography.Text style={{ fontSize: '10px' }} type="secondary">{moment().format('dddd D MMMM')}</Typography.Text>
                </Row>
                <Row justify="start" style={{ marginTop: '5px' }}>
                    <Typography.Title level={4} editable={{
                        onChange: (value) => {
                            setstate({ ...state, subject: value })
                        }
                    }}>{state.subject ?? 'Asunto'}</Typography.Title>
                </Row>
                <Row style={{ marginTop: '5px', width: '100%' }}>
                    <div style={{ borderTop: '1px solid #f7f7f7', width: '100%', padding: '5px 0px' }}>

                        <Form.Item name="emails" label="Para">
                            <CreatableSelect
                                isMulti
                                onChange={(newValue) => {
                                    setstate({ ...state, emails: newValue })

                                }}
                                // options={[{ label: userDocument?.email, value: userDocument?.email }, { label: client?.email, value: client?.email }]}
                                options={[...(emails), { label: userDocument?.email, value: userDocument?.email }]}
                                styles={reactSelectMultiStyles}
                                noOptionsMessage={(v) => "Escribe el correo del destinatario"}
                                className="pro-select-input pro-select-input-multi"
                                placeholder="A quien debemos enviar el correo."
                                formatCreateLabel={(userInput) => `Añadir destinatario: "${userInput}"`}
                            />
                        </Form.Item>

                        <Row align="middle" justify="space-between" style={{ marginBottom: '10px' }}>
                            <Typography.Text className='cLabel labelRequired'>Mensaje</Typography.Text>
                            <Button style={{ fontSize: '12px' }} type="link" onClick={() => setTemplateLibrary(true)}>Plantillas</Button>
                        </Row>
                        <Form.Item rules={[{ required: true, message: 'Añade el cuerpo del correo' }]} name="body" label="">
                            <Input.TextArea rows={5} />
                        </Form.Item>

                        <Row justify="end">
                            <Button htmlType="submit" loading={loading} icon={<SendOutlined />} type="primary" >Enviar</Button>
                        </Row>
                    </div>

                </Row>
            </div>
        </Form>
    }


    const EmailsView = () => {

        const fs = getFirestore();
        const [emails, setEmails] = useState([]);

        const receiptsRef = query(
            collection(fs, `emails`),
            where("related", "==", `receipts/${element?.id}`),
        );

        // console.log("RECEIPTS REF: ", `receipts/${element?.id}`)

        const getReciptEmails = async () => {
            const receiptsData = await getDocs(receiptsRef);
            // console.log("RECEIPTS: ", receiptsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            setEmails(receiptsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }

        useEffect(() => {
            getReciptEmails()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return <div className='tableContainer d-flex flex-column'>
            {emails?.length <= 0 ? <EmptyTable customMessage={'No hay correos enviados'} parentAction={null} elementName={'emails'} /> : emails.map((email, inx) => {
                return <Row key={email?.id} style={{ marginTop: inx === 0 ? '0px' : '20px' }}>
                    <Col xs={2}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <MailOutlined type="secondary" />
                        </div>
                    </Col>
                    <Col xs={22}>
                        <div className="d-flex flex-column">
                            <Typography.Text ellipsis>"{email?.subject}"</Typography.Text>
                            <Row wrap={true}>
                                {email?.to?.map((to) => {
                                    return <Tag key={to}>{to}</Tag>
                                })}
                            </Row>
                            <Typography.Text type="secondary" style={{ fontSize: '11px', marginTop: '5px' }}>Enviado {moment(email.timestamp).fromNow()}, último evento recibido: {email.lastEvent ? `${email?.lastEvent === 'open' ? '"abierto"' : email?.lastEvent} ${moment(email?.lastEventAt * 1000).fromNow()}` : 'sin evento.'}</Typography.Text>
                        </div>
                    </Col>
                </Row>
            })}
        </div>
    }

    /* A modal that allows you to send an email to a client. */
    return (
        <Modal visible={open} onCancel={() => close(null)} footer={null} title={null} >

            {templateLibrary && <Suspense fallback={<></>}>
                <ViewTemplates userDocument={userDocument} open={templateLibrary} close={() => setTemplateLibrary(false)} selected={(template) => {
                    const templateBody = template.content ?? ""
                    const totals = getItemsAmounts(data?.internalItems ?? data.items)

                    const newBody = templateBody.replace(/{cliente}/g, client.name).replace(/{total}/g, totals.totalString).replace(/{moneda}/g, data.currency)
                    form.setFieldsValue({ body: newBody })
                    setTemplateLibrary(false);
                }} /></Suspense>}
            {<Tabs
                defaultActiveKey="1"
                size="small"
                type="card">
                <Tabs.TabPane tab="Enviar correo" key="1">
                    <SendEmailForm />
                </Tabs.TabPane>
                {showEmailsView && <Tabs.TabPane tab="Ver correos" key="2">
                    <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                        <EmailsView />
                    </div>
                </Tabs.TabPane>}
            </Tabs>}

        </Modal>

    );
}

export default ComposeEmailModal;