import { Dropdown, Menu, Popconfirm, Space, Tooltip, Typography, message } from "antd"
import { DownloadInvoice, DuplicateInvoice } from "../../app/functions/helpers"
import { useAuth } from "reactfire"
import { DeleteOutlined, DownloadOutlined, LoadingOutlined, MailOutlined, QrcodeOutlined, ReloadOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons"
import { Suspense, useContext, useState } from "react"
import { deleteDoc, doc, getFirestore } from "firebase/firestore"
import SubstituteInvoice from "../invoices/SubstituteInvoice"
import UserContext from "../../context/usercontext/UserContext"
import { ConfirmSignInvoiceModal } from "../invoices/ConfirmSignInvoiceModal"
import ComposeEmailModal from "../email/ComposeEmailModal"
import CancelInvoiceModal from "../invoices/CancelInvoiceModal"
import DeleteInvoiceGlobal from "../modals/DeleteInvoiceGlobalModal"

export const InvoiceActions = ({ record, pageSize, setPageSize, pageActive, setpageActive }) => {
    const auth = useAuth()
    const fs = getFirestore()
    const { user } = useContext(UserContext)

    const [downloading, setDownloading] = useState(null)
    const [loadingSignInvoice, setloadingSignInvoice] = useState(false)
    const [signInvoiceModal, setSignIvoiceModal] = useState(false)
    const [invoiceToSign, setInvoiceToSign] = useState(null)
    const [composeEmail, setcomposeEmail] = useState(null)
    const [cancelInvoice, setcancelInvoice] = useState(null)
    const [deleteInvoiceGlobal, setDeleteInvoiceGlobal] = useState(null)
    const [substituteInvoice, setSubstituteInvoice] = useState(null)

    const canCancelInvoice = record.status === 'valid' && record.livemode && record.cancellation_status === 'none' && !record.withoutKeys
    let items = [
        {
            label: <Typography.Text className="itemHoverPrimary">Descargar PDF</Typography.Text>,
            onClick: () => {
                DownloadInvoice({ invoice: record, type: 'pdf', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
            },
            key: 'PDF',
        },
        {
            label: <Typography.Text className="itemHoverPrimary">Descargar XML</Typography.Text>,
            onClick: () => {
                DownloadInvoice({ invoice: record, type: 'xml', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
            },
            key: 'XML',
        },
        {
            label: <Typography.Text className="itemHoverPrimary">Descargar zip</Typography.Text>,
            onClick: () => {
                DownloadInvoice({ invoice: record, type: 'zip', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
            },
            key: 'ZIP',
        },
    ]

    if (record.status === 'canceled') {
        items.push({
            label: <Typography.Text className="itemHoverPrimary">Descargar acuse de cancelación </Typography.Text>,
            onClick: () => {
                // console.log('aomse');
                DownloadInvoice({ invoice: { ...record, invoice: { id: record.id, cancelation: true }, cancelation: true }, type: 'zip', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                // console.log('aomse x2');
                // DownloadCanceledReceipt({ invoice: record, setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
            },
            key: 'CANCELATION',
        },)
    }

    return downloading === record.id ? <LoadingOutlined /> : <Space>

        {substituteInvoice && (
            <Suspense fallback={<LoadingOutlined />}>
                <SubstituteInvoice
                    open={substituteInvoice}
                    close={() => setSubstituteInvoice(null)}
                    pastInvoiceDocument={substituteInvoice}
                    userDocument={user}
                    openCancelInvoice={(inv) => setcancelInvoice(inv)}
                />
            </Suspense>
        )}
        {signInvoiceModal && (
            <Suspense fallback={<LoadingOutlined />}>
                <ConfirmSignInvoiceModal
                    loading={loadingSignInvoice}
                    open={signInvoiceModal}
                    close={() => setSignIvoiceModal(false)}
                    data={invoiceToSign}
                    add={(v) => {
                        if (v.withoutKeys) {
                            return;
                        }
                        DuplicateInvoice({
                            invoice: v,
                            setloading: (v) => setloadingSignInvoice(v),
                            onSuccess: () => {
                                message.success('Factura timbrada correctamente')
                                setSignIvoiceModal(false)
                            },
                            currentUser: auth.currentUser
                        });
                    }}
                />
            </Suspense>
        )}

        {composeEmail && (
            <ComposeEmailModal
                close={() => setcomposeEmail(null)}
                actionText={null}
                idRef={"invoices"}
                data={{ ...composeEmail, invoice: composeEmail }}
                userDocument={user}
                open={composeEmail}
                element={composeEmail}
                subject={`Factura ${composeEmail?.series} ${composeEmail?.folio_number}`}
                readableType={"factura"}
                client={
                    composeEmail.internalClient ?? { name: "Sin Cliente", email: "" }
                }
            />
        )}

        {cancelInvoice && (
            <CancelInvoiceModal
                open={cancelInvoice}
                close={() => setcancelInvoice(null)}
                invoice={cancelInvoice}
                cancelInvoice={(inv) => {
                    //console.log(inv);
                }}
            />
        )}

        {deleteInvoiceGlobal && (<DeleteInvoiceGlobal open={deleteInvoiceGlobal} close={() => setDeleteInvoiceGlobal(null)} onAccept={() => setcancelInvoice(deleteInvoiceGlobal)} />)}


        {downloading === record.id ? <LoadingOutlined /> : record.withoutKeys ? <></> :
            <Dropdown overlay={<Menu items={items} />} placement="bottom">
                <DownloadOutlined className="clickable itemHoverPrimary" style={{ color: '#b7b7b7' }} />
            </Dropdown>
        }
        {(record.status === 'valid' && !record.livemode) && <Tooltip title="Timbrar la factura">
            <QrcodeOutlined className={`${record.withoutKeys ? 'disabled' : 'secondaryItem itemHoverPrimary'} `} style={{ color: '#b7b7b7', cursor: 'pointer' }} onClick={() => {
                setSignIvoiceModal(true)
                if (setPageSize && setpageActive) {
                    setPageSize(pageSize)
                    setpageActive(pageActive)
                }
                window.history.replaceState(null, `tab`, `?pageActive=${pageActive ?? 1}`)
                setInvoiceToSign(record)
            }} />
        </Tooltip>}
        {(canCancelInvoice && record?.isGlobal) ? <><Tooltip title="Cancelar factura">
            <StopOutlined className="secondaryItem itemHoverPrimary"
                onClick={() => {
                    if (setPageSize && setpageActive) {
                        setPageSize(pageSize)
                        setpageActive(pageActive)
                    }
                    window.history.replaceState(null, `tab`, `?pageActive=${pageActive ?? 1}`)
                    setDeleteInvoiceGlobal(record)
                }}
                style={{ color: '#b7b7b7' }} />

        </Tooltip></> : (canCancelInvoice) ?
            <> <Tooltip title="Cancelar factura">
                <StopOutlined className="secondaryItem itemHoverPrimary"
                    onClick={() => {
                        if (setPageSize && setpageActive) {
                            setPageSize(pageSize)
                            setpageActive(pageActive)
                        }
                        window.history.replaceState(null, `tab`, `?pageActive=${pageActive ?? 1}`)
                        setcancelInvoice(record)
                    }}
                    style={{ color: '#b7b7b7' }} />

            </Tooltip> </> : <></>}
        {!record.withoutKeys && <MailOutlined disabled={record.withoutKeys} onClick={() => {
            if (setPageSize && setpageActive) {
                setPageSize(pageSize)
                setpageActive(pageActive)
            }
            window.history.replaceState(null, `tab`, `?pageActive=${pageActive ?? 1}`)
            setcomposeEmail(record)
        }} className="secondaryItem itemHoverPrimary" style={{ color: '#b7b7b7' }} />}
        <Tooltip title="Sustituir factura por una nueva">
            <ReloadOutlined onClick={() => {
                //change url to /substituteInvoice/:id?pageActive=${pageActive ?? 1}
                window.history.replaceState(null, `tab`, `/substituteInvoice/${record?.id}?pageActive=${pageActive ?? 1}`)

                window.history.go()
            }} style={{ color: '#b7b7b7' }} className="clickable" />
        </Tooltip>
        {!record.livemode && <Tooltip>
            <Popconfirm title="¿Eliminar factura sin timbrar?" okText="Eliminar" okButtonProps={{ danger: true }} onConfirm={async () => {
                try {
                    setDownloading(record.id)
                    await deleteDoc(doc(fs, `invoices/${record.id}`))

                    setDownloading(null)
                } catch (error) {
                    message.error(error.message)
                }
            }} ><DeleteOutlined className="itemHoverDanger" style={{ color: '#b7b7b7' }} /></Popconfirm>
        </Tooltip>}
        {['bounce', 'deferred', 'blocked'].includes(record?.emails?.lastEvent) && <Tooltip title='Error al enviar el correo'>
            <WarningOutlined style={{ color: '#b7b7b7' }} />
        </Tooltip>}

        <Typography.Text hidden style={{ fontSize: '7px', marginBottom: '0px' }} className="secondary">V{record.cfdi_version ? record.cfdi_version : '3.3'}</Typography.Text>
    </Space>
}