


/**
 * This function returns an object with boolean values that represent the actions that the user can perform.
 */
export const useUserAbilities = ({ userDocument, team = {} }) => {


    var canSendInvoices = false;
    if (userDocument?.invoiceProfile || team?.facturapi?.completed) {
        canSendInvoices = userDocument?.invoiceProfile?.is_production_ready || team?.facturapi?.completed;
    }

    var isStripeConnected = false;
    if (userDocument?.stripeAccountID || team?.stripe?.accountId) {
        isStripeConnected = true;
    }

    var isWoocommerceConnected = false;

    if (userDocument?.woocommerce) {
        if (userDocument?.woocommerce?.consumerKey && userDocument?.woocommerce?.consumerSecret) {
            isWoocommerceConnected = true;
        }
    }


    var stripeAutoInvoiceActive = false;
    if (isStripeConnected && (userDocument?.stripeAutoInvoice || team?.stripe?.automaticInvoicing)) {
        stripeAutoInvoiceActive = true;
    }



    return {
        canSendInvoices,
        isStripeConnected,
        isWoocommerceConnected,
        stripeAutoInvoiceActive
    }


}