import {
    LOADING_INTEGRATION_CARD,
    NOT_LOADING_INTEGRATION_CARD,
    HANDLE_OPEN_INTEGRATION_MODAL,
    HANDLE_CLOSE_INTEGRATION_MODAL
} from '../../types';

function IntegrationsReducer(state, action) {
    const loadingC = [...(state.loadingCards ?? [])]

    switch (action.type) {
        case LOADING_INTEGRATION_CARD:
            loadingC.push(action.payload);
            return {
                ...state,
                loadingCards: loadingC
            };
        case NOT_LOADING_INTEGRATION_CARD:
            const indexCard = loadingC.indexOf(action.payload)
            loadingC.splice(indexCard, 1)
            return {
                ...state,
                loadingCards: loadingC
                //[...(state.loadingCards ?? [])].filter(card => card.id !== action.payload)
            }
        case HANDLE_OPEN_INTEGRATION_MODAL:
            state[action.payload] = true;
            return {
                ...state
            };
        case HANDLE_CLOSE_INTEGRATION_MODAL:
            state[action.payload] = false;
            return {
                ...state
            };
        default:
            return state;
    }
}

export default IntegrationsReducer