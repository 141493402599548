import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Typography } from 'antd';
import React from 'react';

function PaymentMethodCard({ state, name, type, details, available, checked, disabledMessage = "", showConfigure = true, configureAction = () => { }, changeState = () => { } }) {

    return <Col xs={24} md={12} >
        <div style={{ minHeight: '120px', padding: '5px 20px', alignItems: 'center', justifyContent: 'center', border: '0.5px solid #DDDDDD', marginBottom: '10px', borderRadius: '10px', position: 'relative' }} className="d-flex flex-column"
            onClick={() => {
                if (!available) {
                    message.error(disabledMessage)
                    return
                }
                const index = state?.custom_method_types?.findIndex(x => x.id === type.id);
                if (index > -1) {
                    state.custom_method_types.splice(index, 1);
                } else {
                    state.custom_method_types.push(type);
                }
                changeState({
                    ...state,
                    custom_method_types: [...state.custom_method_types]
                })

            }}
        >
            <Row justify="space-between" style={{ width: '100%', position: 'absolute', top: 8, left: 10 }}>
                {checked ? <CheckCircleOutlined className="primary" /> : <div style={{ border: '0.1px solid #cecece', height: '13px', width: '13px', borderRadius: '50%' }}></div>}
                <div></div>
            </Row>
            <img alt="Método de pago" src={type.logo} height="25px" style={!available ? { filter: 'grayscale(1)' } : {}} />
            <Typography.Text style={{ textAlign: 'center', fontSize: '12px' }} type="secondary">{type.details}</Typography.Text>
            {!available && <Typography.Text style={{ textAlign: 'center', fontSize: '10px', color: 'orange' }} type="secondary">{disabledMessage}</Typography.Text>}
            {(!available && showConfigure) && <Button onClick={configureAction} type="text">configurar</Button>}
        </div>
    </Col>

}

export default PaymentMethodCard;