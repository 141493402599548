import { CheckCircleOutlined, CloseOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Drawer, Form, Input, message, Row, Switch, Tooltip, Typography } from 'antd';
import React, { useState, Suspense, useContext, useEffect } from 'react'
import { ClientReadyForInvoice, generateCode, getItemsAmounts, invoiceUsage, paymentForms, ProductsAvailableForSat, relationOptions, RemoveUndefined, taxRegimes } from '../../app/functions/helpers';
import { generalreactSelectStyles, reactSelectMultiStyles } from '../../app/functions/styles';
import CurrencyChip from '../cards/CurrencyChip';
import ClientsAndItemsSelect from '../forms/ClientsAndItemsSelect';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import LoadingAndResponse from '../states/LoadingAndResponse';
import moment from 'moment';
import ElementCreated from '../states/ElementCreated';
import { useAnalytics, useAuth, useFirestoreDocDataOnce } from 'reactfire';
import { collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where, writeBatch, increment, getDoc, arrayUnion } from 'firebase/firestore';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { logEvent } from 'firebase/analytics';
import AddPaymentToInvoiceModal from './AddPaymentToInvoiceModal';
import AddRelatedFolioEgressInvoice from './AddRelatedFolioEgressInvoice';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import CalendarOptions from '../cards/CalendarOptions';
import { SummarizeMessage } from '../calendarize/SummarizeRecurring';
import UserContext from '../../context/usercontext/UserContext';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
// import { useTeamData } from '../../customHooks/useTeamData';




function CreateInvoice({ close, data, userDocument, open, recurring, edit: editRecurring }) {
    const { team, getCurrentTeam } = useContext(TeamContext)
    const { user } = useContext(UserContext)
    const { billingAccount } = useContext(BillingAccountContext)
    // const { team: teamRt } = useTeamData({ teamId: team?.id })

    const ref = doc(getFirestore(), 'rates/rates')

    const { data: rateData } = useFirestoreDocDataOnce(ref)
    const auth = useAuth()
    const fs = getFirestore()
    const [state, setstate] = useState({
        currency: team?.currency ?? 'mxn',
        items: [],
        series: team?.invoice_serie ?? '',
        exchange: 1,
        test: (data?.onlyTest || !team?.facturapi?.completed) ?? false,
        folio_number: team?.invoice_folio ? (team?.invoice_folio + 1) : ((team?.invoicesCreated || 0) + 1),
        ...data,
        temporality: typeof editRecurring?.temporality === 'object' ? editRecurring?.temporality.value : editRecurring?.temporality,
        onTime: editRecurring?.onTime ?? null,
        client: editRecurring ? editRecurring?.client : data.client,
        automations: null,
        isThirdParty: false,
        third_party: {},
        orderId: data?.metadata?.orderId ?? null,
        metadata: {
            orderId: data?.metadata?.orderId ?? null,
        },
        idempotency_key: null

    })
    const [idIK, /*setdate*/] = useState(`${data.id ? data.id : generateCode(10, 'ik_')}_${moment().valueOf()}`)
    const [invoiceOk, setinvoiceOk] = useState(false)
    const [createdInv, setcreatedInv] = useState(null)
    const [invoiceError, setinvoiceError] = useState('')
    const [apiloading, setapiloading] = useState(false)
    const [addInvoiceModal, setAddInvoiceModal] = useState(false)
    const [addRelatedFolioEgress, setAddRelatedFolioEgress] = useState(false)
    const [step, setstep] = useState(data.step ?? 0)
    const [loading, setloading] = useState(false)
    const [lastTeamData, setLastTeamData] = useState({})
    const [form] = Form.useForm();
    const analytics = useAnalytics()
    const [/*errorMessage*/, seterrorMessage] = useState('')
    const [folioNumerRepeated, setFolioNumerRepeated] = useState(false)

    const getCurrentTeamDetails = async () => {
        const t = (await getDoc(doc(fs, 'teams', team.id))).data()
        setLastTeamData(t)
    }

    // function disabledDate(current) {
    //     return (current.valueOf() < moment().add(-72, 'hours').valueOf()) && (current.valueOf() > moment().endOf('day').valueOf())
    // }


    // console.log(data, 'LOGGING DATA')
    useEffect(() => {

        if (team?.id) {
            getCurrentTeamDetails()
        }

        const fields = form.getFieldsValue()
        form.setFieldsValue({
            ...fields,
            metadata: {
                orderId: state.orderId ?? data?.metadata?.orderId ?? null,
            },
            folio_number: state.type === 'P' ? (team?.invoice_folio_payments ? Number(team?.invoice_folio_payments) : 0) + 1 : (team?.invoice_folio ? Number(team?.invoice_folio) : 0) + 1,
            ...(editRecurring ?? {}),
            onTime: typeof editRecurring?.onTime === 'string' ? moment(editRecurring?.onTime ?? '00:01', 'HH:mm') : editRecurring?.onTime,
            emails: editRecurring ? editRecurring?.emails?.map((e) => ({ label: e, value: e })) : null,

        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.invoice_folio])

    useEffect(() => {
        if (editRecurring) {
            form.setFieldsValue({
                ...editRecurring,
                temporality: typeof editRecurring.temporality === 'object' ? editRecurring.temporality : editRecurring.temporality.value,
                onTime: editRecurring.onTime.format('HH:mm'),
                limitDaysToPay: editRecurring?.limitDaysToPay ?? null,
                feeAdded: editRecurring.discount,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
 * It takes in an object with a type, action, and ref, and then either adds or removes the object from
 * the automations array in state
 */
    const manageAutomation = async ({ type, action, ref }) => {
        const index = (state.automations || []).findIndex((e) => e.type === type);

        var tempAutom = state.automations || []
        if (index > -1) {
            tempAutom.splice(index, 1)
        } else {
            tempAutom.push({ type, action, ref, from: 'invoices' })
        }

        setstate({ ...state, automations: tempAutom })

    }
    /**
     * It takes the state of the form, and sends it to the API to create the invoice
     * @param v - The invoice type. It can be I, E, P
     */
    const GenerateInvoice = async (v) => {
        const { client, type, items } = state

        if (state.emails?.length > 0) {
            try {
                //REMOVE DUPLICATE EMAILS ON BCC ON CLIENT COMPARED WITH STATE.EMAILS
                var newEmails = [...(state.emails?.map((e) => e.value)), ...(client?.bcc ?? [])];

                var principalEmail = client?.email || newEmails[0] || null

                await updateDoc(doc(fs, 'clients', client.id), {
                    email: typeof principalEmail === 'string' ? principalEmail : principalEmail[0],
                    bcc: newEmails.filter((item,
                        index) => newEmails.indexOf(item) === index)
                })
            } catch (error) {
                console.log(error);
            }
        }
        //SET CONST EMAIL REMOVING DUPLICATES EVEN IF THEY ARE UPPERCASED AS ARRAY OF STRINGS
        var fieldEmails = form.getFieldValue('emails') ?? []
        // console.log(fieldEmails, 'fieldEamils');
        var emails = [...new Set((state.emails ?? fieldEmails)?.map((em) => (em.value ?? em)?.toLowerCase()))]



        const RemoveFeesFromTotal = () => {


            items?.forEach((item, index) => {
                items[index].total = item.total - (item.feeAdded ?? 0)
                items[index].feeAdded = null
                items[index].feeInfo = null
            })

            setstate({ ...state, feeAdded: null, items: [...items] })
            message.success('Hemos actualizado los servicios')
        }



        var it = state?.items?.map((i) => {

            if (i?.paymentType?.value === 'hour') {
                return {
                    quantity: parseInt(i.hours, 10),
                    product: {
                        description: i.name,
                        product_key: i.product_key,
                        price: i.total,
                        tax_included: false,
                        taxes: i.taxes?.map((t) => {
                            return {
                                type: t.type,
                                factor: t.factor,
                                rate: t.rate,
                                withholding: t.withholding,
                            }
                        }),
                        unit_key: i.unit_key ?? 'E48',
                    }
                }
            } else {
                return {
                    quantity: i.quantity,
                    discount: i.discount || 0,
                    product: {
                        description: i.name,

                        product_key: i.product_key,
                        price: i.total,
                        tax_included: false,
                        taxes: i.taxes?.map((t) => {
                            return {
                                type: t.type,
                                factor: t.factor,
                                rate: t.rate,
                                withholding: t.withholding,
                            }
                        }),
                        unit_key: i.unit_key ?? 'E48',
                    }
                }
            }
        })

        const rel = state.related ? state.related?.map((r) => r.value) : []
        const clientName = (client?.legal_name ?? client?.name)
        var invoice = {
            ...(editRecurring?.replicateElement ?? {}),
            team: team?.id,
            billingAccount: billingAccount?.id,
            type: 'create_invoice',
            invoicingIntegration: 'facturapi',
            invoiceType: type ?? 'I',
            ...form.getFieldsValue(),
            currency: state?.currency ?? 'mxn',
            automations: state.automations ?? null,
            internalClient: client,
            from: 'manual',
            related: rel,
            orderId: state?.orderId ?? null,
            metadata: {
                orderId: state?.orderId ?? null,
            },
            test: state.test,
            use: state.use?.value,
            proposal: data?.proposal?.id ?? null,
            payment: data?.payment?.fid ?? null,
            invoice: data?.invoice?.id ?? null,
            date: moment(v.invoiceDate?.valueOf()).toISOString(),
            owner: user.uid,
            invoiceSingleExtra: form.getFieldValue('invoiceSingleExtra') ?? null,
            v4: true,
            client: {
                ...client,
                tax_system: client?.tax_system?.value ?? client?.tax_system,
                tax_id: client.rfc /*? client?.rfc?.length < 8 ? "XAXX010101000" : client.rfc : "XAXX010101000", */,
                rfc: client.rfc /*? client?.rfc?.length < 8 ? "XAXX010101000" : client.rfc : "XAXX010101000", */,
                address: client?.address ?? null,
                legal_name: clientName.toUpperCase(),
            },
            clientID: client.id ?? null,
            clientId: client.id ?? null,
            emails: emails?.length > 0 ? emails : null,
            folio_number: parseInt(form.getFieldValue('folio_number'), 10) ?? 1,
            idempotency_key: idIK
        }
        if ((invoice?.related ?? []).length > 0) {
            invoice.related_documents = [{
                documents: invoice?.related ?? [],
                relationship: state.relation?.value ?? null
            }]
        }


        // console.log(invoice, 'INVOICE');
        // return

        if (recurring) {
            delete invoice.startEndDate
            delete invoice.onWeekday
            delete invoice.onDay
            delete invoice.onTime
            delete invoice.temporality
        }





        // return console.log(items, it)
        if (type === 'I') {
            invoice.items = it
            invoice.internalItems = items
            invoice.payment_form = state.payment_form.value
            invoice.payment_method = state.payment_method.value
            invoice.exchange = state.currency.toLowerCase() === 'mxn' ? 1 : (v.exchange ?? 1)
            invoice.orderId = state.orderId ?? null
            invoice.metadata = {
                orderId: state.orderId ?? null,
            }
            invoice.currency = state.currency.toLowerCase() ?? 'mxn'
            invoice.use = typeof state.use === 'object' ? state.use.value : state.use
            invoice.totalItemsAmounts = getItemsAmounts(items)

            if (state?.whmcsItems && (state.whmcsItems ?? [])?.length > 0) {
                invoice.whmcsItems = state.whmcsItems
            }

            if (state.whmcsInvoiceId) {
                invoice.whmcsInvoiceId = state.whmcsInvoiceId
            }
        }
        if (type === 'E') {
            invoice.items = it
            invoice.internalItems = items
            invoice.payment_form = state.payment_form.value
            //invoice.payment_method = state.payment_method.value
            invoice.exchange = state.currency.toLowerCase() === 'mxn' ? 1 : (v.exchange ?? 1)
            invoice.currency = state.currency.toLowerCase() ?? 'mxn'
            invoice.use = state.use.value
            invoice.related_documents = state.related_documents
        }
        if (type === 'P') {
            invoice.complements = [{ type: 'pago', data: [...(state.payments ?? [])] }]
        }

        if (type === 'P' && state?.isThirdParty) {
            invoice.third_party = {
                legal_name: form.getFieldValue('tp_legal_name'),
                tax_id: form.getFieldValue('tp_tax_id'),
                tax_system: form.getFieldValue('tp_tax_system').value,
                zip: form.getFieldValue('tp_zip')
            }
            delete invoice.tp_legal_name
            delete invoice.tp_tax_id
            delete invoice.tp_tax_system
            delete invoice.tp_zip
        }






        if (rel.length > 0) {
            invoice['relation'] = state.relation?.value ?? null
        }


        setapiloading(true)
        setstep(step + 1)
        const amounts = getItemsAmounts(items)
        if (data.onlyTest || !team?.facturapi?.completed) {
            const id = generateCode(12, 'inv')
            invoice.uuid = generateCode(20)
            invoice.invoicingIntegration = 'facturapi'
            invoice.status = 'valid'
            invoice.type = invoice.invoiceType
            invoice.withoutKeys = true
            invoice.internalItems = items
            invoice.id = id
            invoice.fid = id
            invoice.total = amounts.total
            invoice.team = team?.id
            invoice.billingAccount = billingAccount?.id
            invoice.organization = auth.currentUser.uid
            invoice.timestamp = moment().valueOf()
            invoice.customer = state.client
            logEvent(analytics, 'invoiceCreated', {});

            // setDoc(doc(fs, 'recurringEvents', invoice.id), invoice)
            //     .then(async () => {

            //         setstep(step + 2)
            //         setcreatedInv(invoice)
            //         setinvoiceOk(true)
            //         return setapiloading(false)
            //     })
            //     .catch((e) => {
            //         setinvoiceError(e.message ?? e.error)
            //         setapiloading(false)
            //         setinvoiceOk(false)
            //     })


            return setDoc(doc(fs, 'invoices', invoice.id), invoice)
                .then(async () => {

                    setstep(step + 2)
                    setcreatedInv(invoice)
                    setinvoiceOk(true)
                    return setapiloading(false)
                })
                .catch((e) => {
                    setinvoiceError(e.message ?? e.error)
                    setapiloading(false)
                    setinvoiceOk(false)
                })




        } else {
            try {
                if (recurring) {
                    var summMessage = SummarizeMessage({ state })
                    // console.log(summMessage);
                    const recurringEvent = {
                        team: team?.id,
                        billingAccount: billingAccount?.id,
                        owner: user.uid,
                        replicateElement: {
                            ...RemoveUndefined(invoice),
                            livemode: !state.test,
                        },
                        elementType: 'invoice',
                        startDate: moment(state.startDate).valueOf(),
                        startDateUTC: moment(state.startDate).utc().valueOf(),
                        startDateString: moment(state.startDate).format('D MMMM YYYY HH:mm'),
                        endDate: moment(state.endDate).valueOf(),
                        endDateUTC: moment(state.endDate).utc().valueOf(),
                        endDateString: moment(state.endDate).format('D MMMM YYYY HH:mm'),
                        onWeekday: state.onWeekday ?? null,
                        onDay: state.onDay ?? null,
                        onTime: typeof state.onTime === 'string' ? state.onTime : state?.onTime?.format('HH:mm') ?? null,
                        temporality: state.temporality ?? null,
                        timestamp: state?.timestamp ? state?.timestamp : moment().valueOf(),
                        timestampUTC: moment().utc().valueOf(),
                        client: state.client ?? null,
                        clientID: state.client?.id ?? null,
                        clientId: state.client?.id ?? null,
                        nextRun: summMessage.nextHit,
                        nextRunUTC: summMessage.nextHit ? moment(summMessage.nextHit).utc().valueOf() : null,
                        nextRunString: summMessage.nextHit ? moment(summMessage.nextHit).format('D MMMM YYYY HH:mm') : null,
                        status: !summMessage.nextHit ? 'paused' : 'active',
                        type: 'invoice',
                        processed: false,
                        livemode: !state.test,

                    }
                    // console.log(recurringEvent);
                    if (editRecurring) {
                        recurringEvent.lastUpdate = moment().valueOf()
                        try {
                            const d = doc(getFirestore(), 'recurringEvents', editRecurring.recordID)
                            await updateDoc(d, { ...recurringEvent, updateList: arrayUnion(moment().valueOf()) })
                            setinvoiceOk(true)
                            setapiloading(false)
                            RemoveFeesFromTotal()
                            setstep(step + 2)
                            return
                        } catch (error) {
                            setinvoiceError(error.message)
                            setinvoiceOk(false)
                            setapiloading(false)
                            message.error(error.message)
                            return
                        }
                    }

                    if (recurring && !editRecurring) {
                        const resp = await SignedInternalAPIRequest({ ...recurringEvent }, 'createRecurringEventSeats', auth.currentUser)
                        if (resp.error) {
                            setinvoiceOk(false);
                            setloading(false)
                            seterrorMessage(resp.message)
                            return message.error(resp.message)
                        }

                        setinvoiceOk(true)
                        setapiloading(false)
                        RemoveFeesFromTotal()
                        setstep(step + 2)
                        logEvent(analytics, 'recurringPaymentCreated', {});
                    }

                } else {
                    //MAKE NORMAL INVOICE
                    if ((invoice?.related ?? []).length === 0) {
                        delete invoice.related
                    }

                    invoice.related_documents = (invoice.related_documents ?? [])?.map((r) => {
                        return {
                            documents: r.documents?.map((d) => d),
                            relationship: r.relationship
                        }
                    })

                    invoice.related_documents = (invoice?.related_documents ?? [])?.filter((r) => r.documents?.length > 0)

                    if ((invoice?.related_documents ?? [])?.length === 0) {
                        delete invoice.related_documents
                    }
                    invoice.livemode = !state.test
                    setinvoiceError(null)


                    if (!team?.defaults?.useFtpiFolio) {
                        const folioExist = await getDocs(query(collection(getFirestore(), 'invoices'), where('status', '==', 'valid'), where('team', '==', team?.id ?? ''), where('folio_number', '==', invoice?.folio_number), where('series', '==', invoice?.series?.toUpperCase() ?? ''), where('type', '==', invoice?.invoiceType ?? '')))

                        if (!folioExist.empty) {
                            if (!folioNumerRepeated) {
                                setFolioNumerRepeated(true)
                                if (type === 'P') {
                                    updateDoc(doc(fs, 'teams', team?.id), {
                                        invoice_folio_payments: increment(1)
                                    })
                                }
                                if (type === 'E') {
                                    updateDoc(doc(fs, 'teams', team?.id), {
                                        invoice_folio_egress: increment(1)
                                    })
                                }
                                if (type === 'I') {
                                    updateDoc(doc(fs, 'teams', team?.id), {
                                        invoice_folio: increment(1)
                                    })
                                }
                            }
                            setapiloading(false)
                            setinvoiceOk(false)
                            return setinvoiceError('El folio de la factura ya existe')
                        }
                    }
                    // if (!folioExist.empty) {
                    //     updateDoc(doc(fs, 'teams', team?.id), {
                    //         invoice_folio: increment(1)
                    //     })
                    //     setapiloading(false)
                    //     setinvoiceOk(false)
                    //     return setinvoiceError('El folio de la factura ya existe')
                    // }
                    console.log(invoice, 'INVOICE');
                    invoice.avoidGlobal = true
                    const resp = await SignedInternalAPIRequest(invoice, 'invoicing', auth.currentUser)



                    if (resp.error) {
                        setinvoiceError(resp?.message ?? resp?.error)
                        setapiloading(false)
                        setinvoiceOk(false)
                        return
                    } else {
                        let toUpdate
                        if (!folioNumerRepeated) {
                            if (state.type === 'P') {
                                toUpdate = { invoice_folio_payments: increment(1) }
                            }
                            if (state.type === 'I') {
                                toUpdate = { invoice_folio: increment(1) }
                            }

                            if (state.type === 'E') {
                                toUpdate = { invoice_folio_egress: increment(1) }
                            }
                        }
                        await updateDoc(doc(fs, 'teams', team?.id), {
                            ...toUpdate
                        })
                        if (state.type === 'P') {
                            const batch = writeBatch(fs)
                            state.payments.forEach(p => {
                                p.data?.forEach(d => {
                                    d.related_documents.forEach(r => {
                                        if (r.id) {
                                            batch.update(doc(fs, 'invoices', r.id), { installment: r.installment, last_balance: r.last_balance - r.amount })
                                        }
                                    })
                                })
                            })
                            try {
                                await batch.commit()
                            } catch (error) {
                            }
                        }
                        message.success('Factura creada correctamente')
                        setstep(step + 2)
                        setcreatedInv(resp)
                        setinvoiceOk(true)
                        setinvoiceError(null)
                        setapiloading(false)
                        return


                    }
                }

            } catch (error) {
                setinvoiceError(error?.message ?? error?.error ?? 'Ocurrio un error')
                setapiloading(false)
                setinvoiceOk(false)
            }
        }
    }



    /**
     * It returns an object with a disabled property and a message property. The disabled property is a
     * boolean that tells us if the button should be disabled or not. The message property is a string that
     * tells us what the button should say
    
     */
    const HandleButtonState = () => {
        if (step === 0) {
            // const satAvailable = ProductsAvailableForSat(state.items).available
            if (!state.client) {
                return { disabled: true, message: 'Selecciona un cliente' }
            }
            // if (!satAvailable) {
            //     return { disabled: true, message: 'Tus servicios no cuentan con la información correcta' }
            // }
            if (!ClientReadyForInvoice(state.client)) {
                return { disabled: true, message: 'Tu cliente no cuenta con los datos necesarios para generar una factura' }
            }

            return { disabled: false, message: 'Continuar' }
        } else if (step === 1) {
            if (!state.type) {
                return { disabled: true, message: 'Selecciona el tipo de factura' }
            } else return { disabled: false, message: 'Continuar' }
        } else {
            return { disabled: false, message: recurring ? editRecurring ? 'Editar recurrencia' : 'Crear recurrencia' : 'Crear factura' }
        }

    }
    /**
     * If the step is 0, set the step to 1. If the step is 1, set the step to 2 and set the currency field
     * to the value of the state. If the step is 2, generate the invoice
     */
    const HandleButtonAction = () => {
        if (step === 0) {
            return setstep(1)
        }
        if (step === 1) {
            setstep(2)
            var emails = [{ label: state.client?.email, value: state.client?.email }, ...(state?.client?.bcc ?? []).map((e) => ({ label: e, value: e })), ...(editRecurring?.emails ?? []).map((e) => ({ label: e, value: e }))]
            emails = emails.filter((e, i) => emails.findIndex((e2) => e2.value === e.value) === i)
            console.log(state.client?.use);
            return form.setFieldsValue({
                currency: {
                    label: state.currency?.toUpperCase(), value: state.currency?.toUpperCase(),
                },
                folio_number: state.type === 'P' ? (lastTeamData?.invoice_folio_payments ? Number(lastTeamData?.invoice_folio_payments) : 0) + 1 : state.type === 'E' ? (team?.invoice_folio_egress ? Number(lastTeamData?.invoice_folio_egress) : 0) + 1 : (lastTeamData?.invoice_folio ? Number(lastTeamData?.invoice_folio) : 0) + 1,
                series: state.type === 'P' ? lastTeamData?.invoice_serie_payments ?? 'GIG' : state.type === 'E' ? lastTeamData?.invoice_serie_egress ?? 'GIG' : lastTeamData?.invoice_serie ?? 'GIG',
                emails,
                onTime: editRecurring?.onTime ?? null,
                orderId: state.orderId ?? null,
                metadata: {
                    orderId: state.orderId ?? data?.metadata?.orderId ?? null,
                },
                use: {
                    label: invoiceUsage.find((e) => e.value === state.client?.use)?.label ?? state.client?.use,
                    value: state.client?.use
                } || state.use || null
            })
        }
        if (step === 2) {
            return GenerateInvoice(form.getFieldsValue())
        }
    }



    const InvoiceTypeCard = ({ type, title, selected, details, blocked }) => {
        return <Col xs={24} md={12} lg={8}>
            <div className={blocked ? 'blockedInvoiceTypeCard' : `invoiceTypeCard ${selected ? 'invoiceTypeCardSelected' : ''}`} onClick={() => {
                if (blocked) return
                if (type === state.type) return setstate({
                    ...state,
                    type: null,
                    folio_number: state.type === 'P' ? (lastTeamData?.invoice_folio_payments ? (Number(lastTeamData?.invoice_folio_payments)) : 0) + 1 : (lastTeamData?.invoice_folio ? (lastTeamData?.invoice_folio) : 0) + 1
                });
                var emails = [{ label: state.client?.email, value: state.client?.email }, ...(state?.client?.bcc ?? []).map((e) => ({ label: e, value: e })), ...(editRecurring?.emails ?? []).map((e) => ({ label: e, value: e }))]

                //REMOVE EMAILS THAT ARE ALREADY IN THE EMAILS ARRAY
                emails = emails.filter((e, i) => emails.findIndex((e2) => e2.value === e.value) === i)
                form.setFieldsValue({
                    folio_number: type === 'P' ? (lastTeamData?.invoice_folio_payments ? (Number(lastTeamData?.invoice_folio_payments)) : 0) + 1 : (lastTeamData?.invoice_folio ? Number(lastTeamData?.invoice_folio) : 0) + 1,
                    emails: emails
                })
                setstate({ ...state, type })
            }}>
                {selected ? <CheckCircleOutlined className="primary" /> : <div style={{ minHeight: '14px', minWidth: '14px', borderRadius: '50%', border: '1px solid #cecece' }}></div>}
                <div className='d-flex flex-column' style={{ marginLeft: '10px' }}>
                    <Typography.Text type={blocked ? 'secondary' : ''} >{title}</Typography.Text>
                    <Typography.Text style={{ fontSize: '12px' }} type='secondary'>{details}</Typography.Text>
                </div>
            </div>
        </Col>
    }

    //SHOWS THE INVOICE TYPE SELECTION
    const InvoiceTypeData = () => {
        const selected = state.type
        const satAvailable = ProductsAvailableForSat(state.items).available

        return <div style={{ padding: '10px 0px', maxWidth: '100%', width: '100%' }}  >
            <Row
                gutter={{ xs: 8, sm: 16 }}
                style={{ maxWidth: '100%', width: '100%', flex: 'wrap' }}
            >
                <InvoiceTypeCard type={'I'} blocked={state.items.length <= 0 || !satAvailable} title="Ingreso" selected={selected === 'I'} details="Factura de ingreso, generalmente se usa cuando recibes un pago de un cliente." />
                <InvoiceTypeCard type={'P'} title="Pago" selected={selected === 'P'} details="Comprobante de pago, se usa cuando tu cliente realiza el pago en más de 1 exhibiciones." />
                <InvoiceTypeCard type={'E'} title="Egreso" blocked={!satAvailable || state.items.length <= 0} selected={selected === 'E'} details="Sirve, para dejar constancia de todos los movimientos efectuados en una transacción comercial" />

            </Row>
        </div>
    }



    const InvoiceDate = () => {
        const defaultDate = (data?.timestamp && moment(data?.timestamp).diff(-72, 'hours') >= -72) ? moment(data.timestamp) : moment()
        // console.log(data.timestamp, 'tiemstamo');
        // console.log(moment(data.timestamp).diff(moment(), 'hours'));
        // console.log(defaultDate.format('DD/MM/YYYY'), 'defaultDate');
        return <Col xs={24}>
            <Form.Item label="Fecha de la factura" style={{ width: '100%' }} name="invoiceDate" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                <DatePicker
                    defaultValue={defaultDate}
                    style={{ width: '100%' }}
                    placeholder='Fecha de la factura'
                    //change format to DD/MM/YYYY
                    format='DD/MM/YYYY'
                    disabledDate={(current) => {
                        //DISABLE DATE IF IT IS GREATER THAN NOW AND BEFORE THAN 72 HOURS BY THE END OF TODAY
                        return (
                            current &&
                            (current <= moment().add(-73, "hours") || current > moment().add(1, 'minute')
                            ))

                    }}
                />
            </Form.Item>
        </Col>
    }


    const InvoicePaymentMethod = () => {
        return <Col xs={24} md={12}>
            <Form.Item label="Método de pago" name="payment_method" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                <Select
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    options={[{ label: 'Pago en una sola exhibición', value: 'PUE' }, { label: 'Pago en parcialidades', value: 'PPD' }]}
                    isLoading={false}
                    onChange={(v) => {
                        setstate({ ...state, ...form.getFieldsValue(), payment_method: v, currency: (form.getFieldValue('currency')?.value ?? state.currency) })
                    }}
                    placeholder="Método de pago"
                />
            </Form.Item>
        </Col>
    }

    const InvoicePaymentType = () => {
        return <Col xs={24} md={state.type !== 'E' ? 12 : 24}>
            <Form.Item label="Forma de pago" name="payment_form" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                <Select
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    // options={state.type === 'E' ? paymetFormForEgress : paymentForms}
                    options={paymentForms}
                    isLoading={false}
                    onChange={(v) => {
                        setstate({ ...state, ...form.getFieldsValue(), payment_form: v, currency: (form.getFieldValue('currency')?.value ?? state.currency) })
                    }}

                    placeholder="Forma de pago"
                />
            </Form.Item>
        </Col>
    }


    const InvoiceUsage = () => {
        return <Col xs={24} md={12} lg={8}>
            <Form.Item label="Uso de la factura" name="use" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                <Select
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    options={invoiceUsage}
                    isLoading={false}
                    onChange={(v) => {
                        setstate({ ...state, ...form.getFieldsValue(), use: v, currency: (form.getFieldValue('currency')?.value ?? state.currency) })
                    }}
                    placeholder="Uso"
                />
            </Form.Item>
        </Col>
    }

    const InvoiceFolioNumber = () => {
        //invoice_folio_payments
        return <Col xs={24} lg={12} xl={8}>
            <Form.Item label="Folio" name={"folio_number"} rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                <Input className="pro-input" id="serieID" placeholder="Series" />
            </Form.Item>
        </Col>
    }

    // const InvoiceDate = () => {

    //     return (
    //         <Col xs={24}>
    //             <Form.Item
    //                 label="Fecha de la factura"
    //                 name={"invoiceDate"}
    //                 rules={[
    //                     { required: true, message: "Por favor añade este campo" },
    //                 ]}
    //             >
    //                 {/* <DatePicker
    //                     disabledDate={(current) => {
    //                         //DISABLE DATE IF IT IS GREATER THAN NOW AND BEFORE THAN 72 HOURS BY THE END OF TODAY
    //                         return (
    //                             current &&
    //                             (current <= moment().endOf("day").add(-72, "hours") ||
    //                                 current < moment().endOf("day"))
    //                         );
    //                         return (
    //                             current &&
    //                             current >= moment().endOf("day").add(-72, "hours")
    //                         );
    //                     }}
    //                 /> */}
    //                 <DatePicker
    //                     disabledDate={disabledDate}
    //                     style={{ width: "100%" }}
    //                 />
    //             </Form.Item>
    //         </Col>
    //     );
    // }

    const InvoiceSerie = () => {
        return <Col xs={24} lg={12} xl={8}>
            <Form.Item label="Serie" name="series" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                <Input className="pro-input" id="serieID" placeholder="Series" />
            </Form.Item>
        </Col>
    }

    const InvoiceCurrency = () => {
        return <Col xs={24}>
            <Row gutter={{ xs: 5, md: 10, lg: 12 }}>
                <Col xs={24} md={12}>
                    <Form.Item label="Moneda" name="currency" rules={[{ required: true, message: 'Por favor añade una moneda.' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={[
                                { label: 'USD', value: 'usd' },
                                { label: 'EUR', value: 'eur' },
                                { label: 'MXN', value: 'mxn' },
                                { label: 'BRL', value: 'brl' },
                            ]}
                            isLoading={false}
                            onChange={(v) => {
                                const exch = rateData?.conversion_rates?.[v.value.toUpperCase()] ?? 1
                                setstate({ ...state, ...form.getFieldsValue(), currency: v?.value, exchange: (1 / exch) })

                                form.setFieldValue('exchange', (1 / exch))
                            }}
                            placeholder="Selecciona una moneda"

                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Tipo de cambio" name="exchange" rules={[{ required: true, message: 'Por favor añade un tipo de cambio.' }]}>
                        {/* <Typography.Text type="secondary" >El cambio debe ser: 1 MXN equivale a X USD</Typography.Text> */}
                        <Input className="pro-input" placeholder="Tipo de cambio" disabled={(state?.currency ?? '').toLowerCase() === 'mxn'}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    }


    const InvoiceRelations = () => {
        return <Col xs={24}>
            <Row gutter={{ xs: 5, md: 10, lg: 12 }}>
                <Col xs={24} lg={state.related?.length > 0 ? 12 : 24}>
                    <Form.Item name="orderId" label="Order ID">
                        <Input rows={2} placeholder="Order Id" />
                    </Form.Item>
                    <Form.Item name="related" label="Facturas relacionadas">
                        <CreatableSelect
                            isMulti
                            onChange={(v) => {
                                const currency = typeof (form.getFieldValue('currency') ?? state.currency) === 'string' ? (form.getFieldValue('currency') ?? state.currency) : (form.getFieldValue('currency') ?? state.currency).value
                                setstate({ ...state, ...form.getFieldsValue(), currency, related: v })
                            }}
                            styles={reactSelectMultiStyles}
                            noOptionsMessage={(v) => "Añade UUID de las facuras relacionadas"}
                            className="pro-select-input pro-select-input-multi"
                            placeholder="Facturas relacionadas"
                            formatCreateLabel={(userInput) => `Añadir relación con "${userInput}"`}
                        />
                    </Form.Item>
                </Col>
                {state.related?.length > 0 && <Col xs={24} lg={12}>
                    <Form.Item label="Tipo de relación de las facturas relacionadas" name="relation" rules={[{ required: state.related?.length > 0, message: 'Por favor añade este campo' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={relationOptions}
                            isLoading={false}
                            onChange={(v) => {
                                if (!v) { return }
                                const currency = typeof (form.getFieldValue('currency') ?? state.currency) === 'string' ? (form.getFieldValue('currency') ?? state.currency) : (form.getFieldValue('currency') ?? state.currency).value

                                setstate({ ...state, ...form.getFieldsValue(), currency: currency, relation: v, })
                            }}
                            placeholder="Tipo de relación"
                        />
                    </Form.Item>
                </Col>}
            </Row>
        </Col>
    }


    const SendToEmail = () => {
        var emails = [state.client?.email, team.supportEmail, ...(state.client?.bcc ?? [])].filter((e) => e !== null && e !== undefined && e !== '')

        let emailOptions = [...new Set(emails)]?.map((e) => { return { label: e, value: e } })

        return <Col xs={24}>
            <Form.Item name="emails" label="¿Quieres enviar la factura por correo?" extra={state.emails?.length < 1 ? <Typography.Text type="secondary" style={{ fontSize: '10px' }}>No enviaremos ningún correo</Typography.Text> : ''}>
                <CreatableSelect
                    isMulti
                    onChange={(v) => {
                        setstate({ ...state, ...form.getFieldsValue(), currency: (form.getFieldValue('currency')?.value ?? state.currency), emails: v })
                    }}
                    options={emailOptions}
                    styles={reactSelectMultiStyles}
                    noOptionsMessage={(v) => "Añade un correo"}
                    className="pro-select-input pro-select-input-multi"
                    placeholder="Selecciona los correos"
                    formatCreateLabel={(userInput) => `Añadir relación con "${userInput}"`}
                />
            </Form.Item>
        </Col>
    }

    const InvoiceExtraNote = () => {
        return <Col xs={24}>
            <Row gutter={{ xs: 12, lg: 12 }}>
                <Col xs={24}>
                    <Form.Item label="Notas adicionales" name="invoiceSingleExtra">
                        <Input.TextArea rows={2} placeholder="Notas adicionales" />
                    </Form.Item>
                </Col>
            </Row>

        </Col>
    }


    const InvoiceTestMode = () => {
        return <Col xs={24}>
            <Row gutter={{ xs: 12, lg: 12 }}>
                <Col xs={8}>
                    <Form.Item label="¿Factura sin timbrar?" valuePropName="checked" name="test">
                        <Switch disabled={data?.onlyTest || !team?.facturapi?.completed} onChange={(v) => {
                            setstate({ ...state, ...form.getFieldsValue(), currency: (form.getFieldValue('currency')?.value ?? state.currency), test: !state.test })
                        }} />
                    </Form.Item>
                </Col>
                <Col xs={16}>
                    {state.test && <Typography.Text>Tu factura se realizará sin timbrar, lo que por lo que no tendrá validez fiscal.</Typography.Text>}
                </Col>
            </Row>
        </Col>
    }

    const ExternalId = () => {
        return <Col xs={24}>
            <Form.Item label="ID externo" name="externalId" rules={[{ required: false, message: 'Por favor añade este campo' }]}>
                <Input className="pro-input" placeholder="ID externo" />
            </Form.Item>
        </Col>
    }

    const RelatedEgressInvoices = () => {
        return <>
            <Col xs={24}>
                <div style={{ borderRadius: '10px', border: '1px solid #8666FF', padding: '10px', marginBottom: '10px' }} className="d-flex flex-column clickable" onClick={() => setAddRelatedFolioEgress(true)}>
                    <Row justify="space-between">
                        <Typography.Text style={{ fontSize: '11px', color: '#8666FF' }} type="">Relacionar Folio Fiscal</Typography.Text>
                        <PlusOutlined type="" className="primary" />
                    </Row>
                </div>
            </Col>
            <Col xs={24}>
                {state?.related_documents?.map((v, i) => <div key={i} style={{ borderRadius: '10px', border: '1px solid #f1f1f1', padding: '10px', marginBottom: '10px' }} className="d-flex flex-column">
                    <Row justify="space-between">
                        <div className="d-flex flex-column">
                            <Typography.Text style={{ fontSize: '12px' }}>{v?.documents?.length} Folios Relacionados</Typography.Text>
                            <Typography.Text style={{ fontSize: '11px' }} type="secondary">Relación: {relationOptions.find(val => val.value === v.relationship).label}</Typography.Text>
                        </div>
                        <DeleteOutlined type="secondary" className="hoverDanger clickable" onClick={() => {
                            var arr = state.related_documents;
                            arr.splice(i, 1);
                            setstate({ ...state, related_documents: arr })
                        }} />
                    </Row>
                </div>)}
            </Col>
        </>
    }

    const InvoicePaymentThirdParty = () => {
        return <Col xs={24}>
            <Row style={{ marginBottom: '10px' }}>
                <Typography.Text>¿Es un pago por cuenta de terceros?</Typography.Text>
            </Row>
            <Switch checked={state.isThirdParty} onChange={(v) => {
                setstate({ ...state, ...form.getFieldsValue(), currency: (form.getFieldValue('currency')?.value ?? state.currency), isThirdParty: !state.isThirdParty })
            }} />

            {state.isThirdParty && <Row style={{ marginTop: '15px' }} gutter={{ xs: 10, md: 10 }}>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Añade el nombre legal' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} name="tp_legal_name" label="Nombre legal">
                        <Input placeholder='Nombre legal' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Añade el RFC' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} name="tp_tax_id" label="RFC">
                        <Input placeholder='Registro de contribuyentes' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Añade el código postal' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} name="tp_zip" label="Código Postal">
                        <Input placeholder='Código postal de facturación' />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item rules={[{ required: true, message: 'Selecciona el régimen fiscal' }]} style={{ margin: 0, padding: 0, paddingBottom: '10px' }} label="Régimen Fiscal" name="tp_tax_system" >
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={taxRegimes}
                            isLoading={false}
                            placeholder="Cuál es el régimen fiscal"

                        />
                    </Form.Item>
                </Col>
            </Row>}
        </Col>

    }

    const SuggestedUUID = () => {
        return <Alert type="info" message={<Typography.Text copyable={{
            text: data.toRelate
        }}>UUID sugerido: {data.toRelate}</Typography.Text>} style={{ borderRadius: '10px', margin: '0px auto 10px auto', backgroundColor: '#EFECFE', border: '1px solid #8666FF' }} />
    }

    const InvoicePaymentForm = () => {
        return (
            <Col xs={24} style={{ marginBottom: "25px" }}>
                {addInvoiceModal && (
                    <Suspense>
                        <AddPaymentToInvoiceModal
                            userDocument={userDocument}
                            open={addInvoiceModal}
                            close={() => {
                                setAddInvoiceModal(false);
                                getCurrentTeam(team?.id);
                            }}
                            updateState={(v) => {
                                setstate({
                                    ...state,
                                    payments: [...(state?.payments ?? []), v],
                                });
                                setAddInvoiceModal(false);
                            }}
                        />
                    </Suspense>
                )}
                <Row>
                    <Col xs={24}>
                        <Divider>
                            <Typography.Text
                                style={{ fontSize: "11px" }}
                                type="secondary"
                            >
                                Pagos
                            </Typography.Text>
                        </Divider>
                    </Col>
                    {data.toRelate && <SuggestedUUID />}
                    <Col xs={24}>
                        <div
                            style={{
                                borderRadius: "10px",
                                border: "1px solid #8666FF",
                                padding: "10px",
                                marginBottom: "10px",
                            }}
                            className="d-flex flex-column clickable"
                            onClick={() => setAddInvoiceModal(true)}
                        >
                            <Row justify="space-between">
                                <Typography.Text
                                    style={{ fontSize: "11px", color: "#8666FF" }}
                                    type=""
                                >
                                    Añadir pago
                                </Typography.Text>
                                <PlusOutlined type="" className="primary" />
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {state.payments?.map((v, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        borderRadius: "10px",
                                        border: "1px solid #cecece",
                                        padding: "10px",
                                        marginBottom: "10px",
                                    }}
                                    className="d-flex flex-column"
                                >
                                    <Row justify="space-between">
                                        <Typography.Text
                                            style={{ fontSize: "11px", color: "" }}
                                            type=""
                                        >
                                            {i + 1}) Pago - {v.data?.length} relaciones
                                        </Typography.Text>
                                        <DeleteOutlined
                                            type=""
                                            className="hoverDanger"
                                            onClick={() => {
                                                setstate({
                                                    ...state,
                                                    payments: state.payments.filter(
                                                        (v2, i2) => i2 !== i
                                                    ),
                                                });
                                            }}
                                        />
                                    </Row>
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </Col>
        );
    }
    const InvoiceMoreData = () => {
        return <div style={{ padding: '10px 0px', maxWidth: '100%', width: '100%' }}>
            <Row style={{ width: '100%' }} gutter={{ xs: 5, md: 10, lg: 12 }} >
                {recurring && <Col xs={24} style={{ backgroundColor: '#fafbfd', padding: '10px', marginBottom: '10px', borderRadius: '10px' }}>
                    <Typography.Text className='cLabel' style={{ marginTop: '30px', marginBottom: '15px' }}>Elije la fecha en a que quieres que corran tus recurrencias</Typography.Text>
                    <div style={{ height: '20px' }}></div>
                    <ErrorBoundary>
                        <CalendarOptions changeState={(s) => {
                            setstate(s)

                        }} state={state} />
                    </ErrorBoundary>
                </Col>
                }
                {!recurring && <InvoiceDate />}
                {(state.type !== 'E' && state.type !== 'P') && <InvoicePaymentMethod />}
                {state.type !== 'P' && <InvoicePaymentType />}
                {state.type !== 'P' && <InvoiceUsage />}
                <InvoiceSerie />
                {recurring ? <></> : (team?.defaults?.useFtpiFolio && state.type === 'I') ? <></> : <InvoiceFolioNumber />}
                {state.type !== 'P' && <InvoiceCurrency />}
                {state.type === "E" && <ExternalId />}
                {state.type === 'E' && <RelatedEgressInvoices />}
                {/* <InvoiceDate /> */}
                {state.type === "I" && <InvoiceRelations />}
                {state.type === 'P' && <InvoicePaymentThirdParty />}
                {state.type === 'P' && <InvoicePaymentForm />}
                <InvoiceExtraNote />
                <SendToEmail />
                <InvoiceTestMode />

            </Row>
        </div>

    }




    const ToRender = () => {
        switch (step) {
            case 0:
                return <>
                    <ClientsAndItemsSelect userDocument={userDocument} changeState={(v) => {
                        setstate(v)

                    }} state={state} withAddFees={false} withAutomation={false} child={<Typography.Text style={{ whiteSpace: 'pre-wrap' }} type="danger">{ProductsAvailableForSat(state.items).messages?.map((m) => m).join('\n')}</Typography.Text>} forSat={true} />
                    <Row align="middle">
                        <Typography.Text type="secondary" style={{ fontSize: '12px', margin: 0 }}>Automatizaciones disponibles <Tooltip title="Nuestra meta es que tu te enfoques en crecer mientras gigstack.pro se enfoca en todo lo demás."><QuestionCircleOutlined style={{ paddingBotom: '5px' }} /></Tooltip></Typography.Text>
                    </Row>
                    <Checkbox checked={(state.automations || []).findIndex((e) => e.type === 'payment') > -1} style={{ marginTop: '10px' }} onChange={(v) => manageAutomation({ type: 'payment', action: 'create', ref: 'invoices' })}>
                        <div className="d-flex flex-column">
                            <Typography.Text type="secondary" className='strong' style={{ fontSize: '13px' }}>Solicitar un pago junto con la factura</Typography.Text>
                        </div>
                    </Checkbox>
                </>

            case 1:
                return <InvoiceTypeData />

            case 2:
                return <InvoiceMoreData />


            case 3:
                return <Suspense fallback={<></>}>
                    <LoadingAndResponse loading={apiloading} isError={!invoiceOk} errorMessage={invoiceError} backAction={() => {
                        setstep(step - 1)
                        if (editRecurring) {
                            form.setFieldValue('onTime', moment(state.onTime ?? editRecurring.onTime, 'HH:mm') ?? editRecurring.onTime)
                        }
                    }
                    } />

                </Suspense>

            case 4:
                return <Suspense fallback={<LoadingOutlined />}>
                    <ElementCreated idRef="payments" actionText="Realizar pago" userDocument={userDocument} message={"La factura se creo con éxito"} actions={{ 'email': 'shortURL', 'copy': 'shortURL', 'whatsapp': 'shortURL' }} close={async () => {

                        close(createdInv)
                    }} element={invoiceOk} shareMessage={``} subject="" />
                </Suspense>
            default:
                return <></>
        }
    }
    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '75%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
            close()
            getCurrentTeam(team?.id)
        }} header={null} footer={null}>
            <Row style={{ width: '100%' }}>

                <Col xs={24}>
                    {addRelatedFolioEgress && <Suspense>
                        <AddRelatedFolioEgressInvoice userDocument={userDocument} open={addRelatedFolioEgress} close={() => setAddRelatedFolioEgress(false)} updateState={(v) => {
                            setstate({ ...state, related_documents: [...(state?.related_documents ?? []), v.related_documents] })
                            setAddRelatedFolioEgress(false)
                        }} />
                    </Suspense>}
                    <Form form={form} initialValues={{
                        ...state,
                        invoiceDate: (data?.timestamp && moment(data?.timestamp).diff(-72, 'hours') >= -72) ? moment(data.timestamp) : moment(),
                        folio_number: state.type === 'P' ? (lastTeamData?.invoice_folio_payments ? lastTeamData?.invoice_folio_payments : 0) + 1 : (lastTeamData?.invoice_folio ? lastTeamData?.invoice_folio : 0) + 1,

                        // folio_number: state.type === 'P' ? (lastTeamData?.invoice_folio_payments ? lastTeamData?.invoice_folio_payments : 0) + 1 : (lastTeamData?.invoice_folio ? lastTeamData?.invoice_folio : 0) + 1,
                        ...(editRecurring ?? {}),
                        emails: [...new Set([state.client?.email, team.supportEmail, ...(state.client?.bcc ?? [])].filter((e) => e !== null && e !== undefined && e !== ''))]
                    }} layout="vertical" onFinish={HandleButtonAction}>
                        <div className="d-flex flex-column" style={{ padding: '5px' }}>
                            <div className="d-flex flex-column">
                                <Row justify="space-between">
                                    <Typography.Title level={4}>{data.customTitle ? data.customTitle : editRecurring ? 'Editar factura recurrente' : (recurring && !editRecurring) ? 'Crear factura recurrente' : 'Crear nueva factura'}</Typography.Title>
                                    <CloseOutlined onClick={() => {
                                        close()
                                        getCurrentTeam(team?.id)
                                    }} />
                                </Row>
                            </div>
                            {step < 3 && <Row style={{ marginTop: '10px' }} justify="end">
                                <CurrencyChip state={state} onUpdate={(v) => {
                                    setstate(v)
                                    form.setFieldValue('currency', { label: v.currency.toUpperCase(), value: v.currency.toUpperCase() })
                                    const exch = rateData?.conversion_rates?.[v.currency.toUpperCase()] ?? 1

                                    form.setFieldValue('exchange', (1 / exch))
                                }} />
                            </Row>}
                            <div className='d-flex flex-column'
                                style={{ flex: 1, maxHeight: '70vh', minHeight: '70vh', overflow: 'auto', padding: '5px' }}>
                                <Suspense fallback={<LoadingOutlined />}>
                                    <ToRender />
                                </Suspense>
                            </div>
                            {step < 3 && <Row style={{ marginBottom: '10px' }} gutter={{ xs: 1 }}>
                                {step > 0 && <Button style={{ flex: 1, marginRight: '5px' }} onClick={() => setstep(step - 1)}>Atrás</Button>}
                                <Button htmlType="submit" disabled={HandleButtonState().disabled} loading={loading} type="primary" style={{ flex: 1, }}>{HandleButtonState().message}</Button>
                            </Row>}

                        </div>
                    </Form>
                </Col>
            </Row >

        </Drawer>



    );
}

export default CreateInvoice;