
import { Button, Col, Form, Input, message, Modal, Row, Space, Switch, Typography } from 'antd';
import React, { useContext, useState } from 'react';

import airtableLogo from '../../assets/images/airtableSquare.png';
import FirstViewIntregration from '../integrations/FirstScreenIntregration';
import { collection, doc, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import TeamContext from '../../context/teamcontext/TeamContext';
import { CopyBlock, a11yDark } from 'react-code-blocks';
import { useFirestoreCollectionData } from 'reactfire';
import airtableIntegrationCode from '../integrations/airtableCode';
import { useForm } from 'antd/lib/form/Form';


function AirtableModal({ open, close }) {

    const fs = getFirestore()
    const { team, getCurrentTeam } = useContext(TeamContext)
    const [form] = useForm()

    const q = query(collection(getFirestore(), "apikeys"), where("team", "==", team.id), where('valid', '==', true),);
    const { data: apikeysData } = useFirestoreCollectionData(q, {
        idOnDB: "id",
    })

    const [loading, setLoading] = useState(null)
    const [step, setStep] = useState(0)
    const [livemode, setlivemode] = useState(true)
    const [tableName, setTableName] = useState(null)

    const finishAirtableConnection = async () => {
        try {
            setLoading("connectAirtable")
            await updateDoc(doc(fs, 'teams', team?.id), {
                airtable: {
                    ...(team?.airtable ?? {}),
                    completed: true,
                    tableName,
                    livemode,
                    completedAt: moment().valueOf()
                }
            })
            message.success('Cuenta conectada correctamente.')
            getCurrentTeam(team?.id)
            close()
            setLoading(null)
        } catch (error) {
            setLoading(null)
            message.error(error?.message ?? 'Ocurrió un error al contectar la cuenta.')
        }
    }
    const handleClickOnButton = async () => {
        switch (step) {
            case 0:
                return setStep(step + 1)
            case 1:
                await form.validateFields()
                form.submit()
                return setStep(step + 1)
            case 2:
                return finishAirtableConnection()
            default:
                return
        }
    }

    const AirtableInitialConfiguration = () => {

        return (
            <Space direction='vertical' style={{ width: '100%', margin: '5px 10px 35px 10px' }}>
                <Form layout='vertical' form={form} onFinish={(v) => setTableName(v.tableName)}>
                    <Row>
                        <Typography.Text style={{ fontSize: '16px' }}>Para conectar tu cuenta de Airtable con Gigstack, debes seguir los siguientes pasos:</Typography.Text>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <Col xl={3}>
                            <Typography.Text strong>Paso 1</Typography.Text>
                        </Col>
                        <Col xl={21}>
                            <Typography.Text>Ingresa el nombre exacto de la tabla de Airtable que desear utilizar: </Typography.Text>
                            <Form.Item name='tableName' rules={[{ required: true, message: 'Ingresa el nombre de la tabla' }]} style={{ marginTop: '10px' }}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <Col xl={3}>
                            <Typography.Text strong>Paso 2</Typography.Text>
                        </Col>
                        <Col xl={21}>
                            <Typography.Text>Debes crear las siguientes columnas en la tabla:</Typography.Text>
                            <Row style={{ marginTop: '10px' }} align='middle'>
                                <Typography.Text> <strong>amount</strong>(Number),  <strong>description</strong>(String),  <strong>customerEmail</strong>(String),  <strong>automaticInvoice</strong>(Checkbox),  <strong>paid</strong>(Checkbox), <strong>gigstackId</strong>(String) y <strong>PaymentUrl</strong>(String)</Typography.Text>
                                <Row style={{ marginTop: '5px' }}>
                                    <Typography.Text type='secondary' style={{ fontSize: '12px' }}>Conóce más sobre como crear esta tabla <span className='clickable primary' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/7971292-airtable-gigstack')}>aquí</span></Typography.Text>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <Col xl={3}>
                            <Typography.Text strong>Paso 3</Typography.Text>
                        </Col>
                        <Col xl={21}>
                            <Typography.Text>Modo de conexión: </Typography.Text>
                            <Row style={{ marginTop: '10px' }} align='middle'>
                                <Col xl={4} xs={4} md={4} lg={4}>
                                    <Switch checkedChildren="Producción" unCheckedChildren="Pruebas" checked={livemode} onChange={(v) => setlivemode(v)} />
                                </Col>
                                <Col xl={18} xs={18} md={18} lg={18}>
                                    <Typography.Text type='secondary'>Si la conexión está en modo pruebas todas las automatizaciones se crearán en este modo</Typography.Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Space>
        )
    }

    const ConnectAirtableView = () => {

        return (
            <Space direction='vertical' style={{ width: '100%', margin: '5px 10px 35px 10px' }}>
                <Row>
                    <Typography.Text style={{ fontSize: '16px' }}>Falta poco, ahora debes continuar con los siguientes pasos:</Typography.Text>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col xl={3}>
                        <Typography.Text strong>Paso 4</Typography.Text>
                    </Col>
                    <Col xl={21}>
                        <Typography.Text>Copia el siguiente código: </Typography.Text>
                        <div style={{ width: '100%', height: '300px' }}>
                            <CopyBlock
                                text={airtableIntegrationCode(apikeysData.filter(k => k.livemode === livemode)[0]?.apikey, tableName)}
                                language="javascript"
                                showLineNumbers={false}
                                wrapLongLines={true}
                                theme={a11yDark}
                                customStyle={{ width: '100%', marginTop: '10px', height: '100%', overflow: 'auto' }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                    <Col xl={3}>
                        <Typography.Text strong>Paso 5</Typography.Text>
                    </Col>
                    <Col xl={21}>
                        <Typography.Text>Termina la configuración siguiendo <span className='primary clickable' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/7971292-airtable-gigstack')}>estos pasos</span></Typography.Text>
                    </Col>
                </Row>
            </Space>
        )
    }

    const ZapierConnectedView = () => {

        return (
            <div style={{ width: '100%', padding: '30px 20px' }}>
                <Row justify='center' align='middle'>
                    <Typography.Text style={{ fontSize: '16px' }}>Tu cuenta de Airtable ya está conectada con Gigstack</Typography.Text>
                </Row>
            </div>
        )
    }

    const ToRender = () => {
        switch (step) {
            case 0:
                return <div>
                    {team.airtable?.completed ? <ZapierConnectedView /> : <FirstViewIntregration appLogo={airtableLogo} appName='Airtable' title="Conecta Gigstack con Airtable" description="La integración con Airtable te permitirá crear pagos en Gigstack desde una tabla en Airtable" permissions={['Crear pagos automatizados', 'Crear clientes']} />}
                </div>
            case 1:
                return <AirtableInitialConfiguration />
            case 2:
                return <ConnectAirtableView />
            default:
                return <></>
        }
    }

    const textByStep = () => {
        switch (step) {
            case 0:
                return 'Aceptar y continuar'
            case 1:
                return 'Continuar'
            case 2:
                return 'Finalizar'
            default:
                return "Siguiente"
        }
    }


    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "70%" : "45%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">

                <ToRender />

            </div>
            <Row justify='center'>
                {step === 2 && <Button style={{ marginRight: '10px' }} onClick={() => setStep(step - 1)}>Atrás</Button>}
                <Button loading={loading === "connectAirtable"} type='primary' onClick={() => {
                    if (team.airtable?.completed) return close()
                    handleClickOnButton()
                }}>
                    {team?.airtable?.completed ? 'Cerrar' : textByStep()}
                </Button>
            </Row>
        </Modal >
    );
}

export default AirtableModal;