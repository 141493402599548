import React, { useReducer } from 'react'

import {
    LOADING_INTEGRATION_CARD,
    NOT_LOADING_INTEGRATION_CARD,
    HANDLE_OPEN_INTEGRATION_MODAL,
    HANDLE_CLOSE_INTEGRATION_MODAL
} from '../../types';

import IntegrationsReducer from './IntegrationsReducer';
import IntegrationsContext from './IntegrationsContext';

const IntegrationsState = props => {

    const initalState = {
        stripeModalOpen: false,
        satModalOpen: false,
        automaticInvoicingModalOpen: false,
        importProductsModalOpen: false,
        importPaymentsModalOpen: false,
        bankAccountModalOpen: false,
        testInvoiceModalOpen: false,
        defaultsModalOpen: false,
        loadingCards: []
    }

    const [state, dispatch] = useReducer(IntegrationsReducer, initalState)

    const handleLoadingCards = (id, loading) => {
        if (loading) {
            dispatch({
                type: LOADING_INTEGRATION_CARD,
                payload: id
            })
        } else {
            dispatch({
                type: NOT_LOADING_INTEGRATION_CARD,
                payload: id
            })
        }
    }

    const handleOpenIntegrationModal = (card) => {
        switch (card) {
            case 'sat':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'satModalOpen'
                })
                break;
            case 'importProducts':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'importProductsModalOpen'
                })
                break;
            case 'importPayments':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'importPaymentsModalOpen'
                })
                break;
            case 'bankAccount':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'bankAccountModalOpen'
                })
                break;
            case 'testInvoice':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'testInvoiceModalOpen'
                })
                break;
            case 'defaults':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'defaultsModalOpen'
                })
                break;
            case 'automaticInvoicing':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'automaticInvoicingModalOpen'
                })
                break;
            case 'stripe':
                dispatch({
                    type: HANDLE_OPEN_INTEGRATION_MODAL,
                    payload: 'stripeModalOpen'
                })
                break;
            default:
                break;
        }
    }

    const handleCloseIntegrationModal = (card) => {
        switch (card) {
            case 'sat':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'satModalOpen'
                })
                break;
            case 'importProducts':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'importProductsModalOpen'
                })
                break;
            case 'importPayments':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'importPaymentsModalOpen'
                })
                break;
            case 'bankAccount':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'bankAccountModalOpen'
                })
                break;
            case 'testInvoice':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'testInvoiceModalOpen'
                })
                break;
            case 'defaults':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'defaultsModalOpen'
                })
                break;
            case 'automaticInvoicing':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'automaticInvoicingModalOpen'
                })
                break;
                case 'stripe':
                dispatch({
                    type: HANDLE_CLOSE_INTEGRATION_MODAL,
                    payload: 'stripeModalOpen'
                })
                break;
            default:
                break;
        }
    }

    return (
        <IntegrationsContext.Provider
            value={{
                // cardStripeLoading: state.cardStripeLoading,
                // cardSatLoading: state.cardSatLoading,
                // cardImportProductsLoading: state.cardImportProductsLoading,
                // cardImportPayementsLoading: state.cardImportPayementsLoading,
                // cardBankAccountLoading: state.cardBankAccountLoading,
                // cardBrandLoading: state.cardBrandLoading,
                // cardTestInvoiceLoading: state.cardTestInvoiceLoading,
                // cardLoading: state.cardLoading,
                stripeModalOpen: state.stripeModalOpen,
                satModalOpen: state.satModalOpen,
                importProductsModalOpen: state.importProductsModalOpen,
                importPaymentsModalOpen: state.importPaymentsModalOpen,
                bankAccountModalOpen: state.bankAccountModalOpen,
                defaultsModalOpen: state.defaultsModalOpen,
                testInvoiceModalOpen: state.testInvoiceModalOpen,
                loadingCards: state.loadingCards,
                automaticInvoicingModalOpen: state.automaticInvoicingModalOpen,

                handleOpenIntegrationModal,
                handleCloseIntegrationModal,
                handleLoadingCards
            }}
        >
            {props.children}
        </IntegrationsContext.Provider>
    )

}

export default IntegrationsState