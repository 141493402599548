import { RightOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Typography } from 'antd';
import React, { Suspense, useState } from 'react'
import ClientsListModal from './ClientsListModal';

function SelectClientRowCard({ onSelect, incomingClient, userDocument }) {
    const [client, setclient] = useState(incomingClient)
    const [selectClient, setselectClient] = useState(false)
    return (
        <Row justify="start" style={{ flex: 1 }}>
            {selectClient && <Suspense>
                <ClientsListModal importFromStripe={false} open={selectClient} userDocument={userDocument} close={() => setselectClient(false)} onSelect={(c) => {
                    setclient(c)
                    setselectClient(false)
                    onSelect(c)
                }} />
            </Suspense>}
            <div className='d-flex flex-row clientSeclectRow' onClick={() => setselectClient(true)}  >
                <div className={`customAvatar ${client ? 'primarybck' : ''}`}>
                    <UserOutlined />
                </div>
                <div className='' style={{ flex: 1, marginLeft: '10px' }}>
                    <Typography.Text style={{ margin: 0, padding: 0 }} type={client ? '' : 'secondary'}>{client ? client?.name : 'Selecciona un cliente'}</Typography.Text>
                </div>
                <RightOutlined style={{ color: '#c7c7c7' }} />
            </div>

        </Row>
    );
}

export default SelectClientRowCard;