import { Button, Col, Form, Input, Modal, Row, Switch, Space, Table, Typography } from 'antd';
import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';
import React, { Suspense, useContext, useState } from 'react'
import { useFirestoreCollectionData } from 'reactfire';
import { generalreactSelectStyles } from '../../app/functions/styles';

import Select from 'react-select';
import { returnCurrencyValue } from '../../app/functions/helpers';

import EmptyTable from '../states/EmptyTable';
import moment from 'moment';
import TableTitle from '../tables/TableTitle';
import { DeleteOutlined } from '@ant-design/icons';
import ServiceTaxModal from '../services/ServiceTaxModal';
import DefaultTableItemsLocal from '../tables/DefaultTableItemsLocal';
import TeamContext from '../../context/teamcontext/TeamContext';



function AddRelatedInvoiceToPayment({ open, close, userDocument, updateState }) {
    const { team } = useContext(TeamContext);
    const fs = getFirestore();
    const q = query(collection(fs, `invoices`), where('team', '==', team?.id ?? ''), where('livemode', '==', true), orderBy('timestamp', 'desc'))
    const { data: invoices } = useFirestoreCollectionData(q, { idField: 'id' });
    const [state, setState] = useState({
        taxes: []
    })
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState('');

    const [addTax, setaddTax] = useState(false)


    const [form] = Form.useForm();

    //USING react-select CREATE A CUSTOM COMPONENT FOR THE SELECTABLE OPTION




    const SelectedInvoice = () => {
        return <div className="d-flex flex-column" style={{ marginTop: '15px', border: '1px solid #cecece', borderRadius: '10px', padding: '12px' }}>
            <Row justify="end">
                <DeleteOutlined onClick={() => {
                    setState({})
                }} className="clickable hoverDanger" />
            </Row>

            <Row justify="space-between" align="start">
                <Col xs={24} md={12}>
                    <div className='d-flex flex-column'>
                        <Typography.Text style={{ fontSize: '13px', margin: '0' }}>UUID</Typography.Text>
                        <Typography.Text style={{ fontSize: '11px', margin: '0' }} type="secondary">{state.uuid}</Typography.Text>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='d-flex flex-column' style={{ marginTop: '10px' }}>
                        <Typography.Text style={{ fontSize: '13px', margin: '0' }}>Timbrado</Typography.Text>
                        <Typography.Text style={{ fontSize: '11px', margin: '0' }} type="secondary">{!state.date ? '-' : moment(state.date).format('D MMMM YYYY')}</Typography.Text>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='d-flex flex-column' style={{ marginTop: '10px' }}>
                        <Typography.Text style={{ fontSize: '13px', margin: '0' }}>Cliente</Typography.Text>
                        <Typography.Text style={{ fontSize: '11px', margin: '0' }} type="secondary">{!state.internalClient ? '-' : state.internalClient?.legal_name ?? state.client?.legal_name}</Typography.Text>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='d-flex flex-column' style={{ marginTop: '10px' }}>
                        <Typography.Text style={{ fontSize: '13px', margin: '0' }}>Total</Typography.Text>
                        <Typography.Text style={{ fontSize: '11px', margin: '0' }} type="secondary">{!state.total ? '-' : returnCurrencyValue(state.total)} {typeof state.currency === 'string' ? state.currency : state.currency?.label}</Typography.Text>
                    </div>
                </Col>
            </Row>
        </div>
    }


    const ManualSwitch = () => {
        return <Row>
            <Switch style={{ marginRight: '10px' }} defaultChecked={state.manualUUID} onChange={() => {
                setState({ ...state, manualUUID: !state.manualUUID })
            }} />
            <Typography.Text type="secondary" style={{ marginBottom: '10px' }}>¿Añadir UUID de forma manual?</Typography.Text>
        </Row>
    }


    const ReturnInvoiceSelector = () => {
        const queriedInvoices = invoices?.filter((a) => a.uuid?.toString()?.toLowerCase()?.includes(filter?.toLowerCase()) || a.client?.legal_name?.toString()?.toLowerCase()?.includes(filter?.toLowerCase()))
        return <div className="d-flex flex-column">
            <Typography.Text className="cLabel labelRequired" style={{ marginTop: '25px' }}>Factura Seleccionada</Typography.Text>
            {!state.uuid && <Row justify="end" >

                <Input.Search defaultValue={filter} onSearch={(v) => {
                    setFilter(v)
                }} onBlur={(v) => {
                    setFilter(v.target.value)
                }} placeholder="Buscar por UUID ó cliente" style={{ width: window.innerWidth > 800 ? '50%' : '100%' }} />
            </Row>}
            {state.uuid ?
                <SelectedInvoice />
                : <div style={{ width: '100%' }}>
                    {state.manualUUID ? <Row style={{ width: '100%', padding: '15px', border: '1px solid #f7f7f7', marginTop: '10px', borderRadius: '10px' }} justify="space-between">
                        <ManualSwitch />
                        <Form.Item name="manualUUID" style={{ width: '80%', margin: 0 }}>
                            <Input placeholder="UUID Manual" />
                        </Form.Item>
                        <Button style={{}} onClick={() => {
                            setState({ ...state, uuid: form.getFieldValue('manualUUID') })
                        }}>Añadir</Button>
                    </Row> : <ManualSwitch />}
                    <Table
                        rowKey={record => record.id}
                        scroll={{ x: 500 }}
                        bordered={false}
                        showSorterTooltip={false}
                        pagination={{
                            pageSize: 5,
                            hideOnSinglePage: true,
                            onChange: (p) => setPage(p),
                            pageSizeOptions: [10, 20, 50, 100],
                            defaultCurrent: page,
                            defaultPageSize: 5
                        }}
                        className='pro-table'

                        locale={{
                            emptyText: <EmptyTable customMessage={"No hay facturas"} parentAction={() => { }} elementName={"Facturas"} />,
                            filterCheckall: 'Todos',
                        }}
                        defaultPage={page}
                        onPageChange={(page) => setPage(page)}
                        pageSize={5} dataSource={filter ? queriedInvoices : invoices}
                        rowSelection={{
                            type: 'radio',
                            defaultSelectedRowKeys: [state.id],
                            onChange: (selectedRowKeys, selectedRows) => {

                                setState({ ...state, ...selectedRows[0], invoiceCurrency: selectedRows[0].currency })
                                form.setFieldsValue({ ...selectedRows[0], installment: ((selectedRows[0]?.installment ?? 0) + 1), amount: null })
                            }
                        }}
                        columns={
                            [
                                {
                                    title: <TableTitle title="UUID" />,
                                    dataIndex: 'uuid',
                                    key: 'uuid',
                                    render: (text, record) =>
                                        <div style={{}} className="d-flex flex-column">
                                            <Typography.Text type=''>{record.internalClient?.legal_name ?? record.client?.legal_name}</Typography.Text>
                                            <Typography.Text style={{ fontSize: '11px' }} type="secondary">{text}</Typography.Text>
                                        </div>,
                                },
                                {
                                    title: <TableTitle title="Fecha" />,
                                    dataIndex: 'date',
                                    key: 'date',
                                    render: (text, record) => <Typography.Text style={{ fontSize: '11px' }} type="secondary">{moment(text).format('D MMMM YYYY')}</Typography.Text>,
                                },
                                {
                                    title: <TableTitle title="Total" />,
                                    dataIndex: 'total',
                                    key: 'total',
                                    render: (text, record) => <Typography.Text style={{ fontSize: '11px' }} type="secondary">{returnCurrencyValue(text)} {record.currency}</Typography.Text>,
                                },
                            ]
                        } />
                </div>}
        </div>

    }


    const { taxes } = state
    const missingBaseTaxes = taxes?.filter(tax => (!tax.base || tax.base === 0))

    return (<Modal visible={open} onCancel={() => close()} footer={null} title={null} width={window.innerWidth > 800 ? "60%" : "100%"} style={{ top: 10, }}>
        <Typography.Title level={3}>Seleccionar una factura</Typography.Title>
        <Form layout="vertical" form={form} onFinish={(v) => {
            const related = { ...state, currency: state.currency?.label, uuid: state.uuid, installment: Number(v.installment), last_balance: Number(v.last_balance), amount: Number(v.amount), exchange: Number(v.exchange) }
            // const stateCurrency = typeof state.currency === 'object' ? state.currency?.value?.toLowerCase() : state?.currency?.toLowerCase()
            // if (stateCurrency === state?.invoiceCurrency?.toLowerCase()) {
            //     delete related.exchange
            // }
            updateState(related)
        }}>
            <Suspense>
                <ReturnInvoiceSelector />
            </Suspense>


            {state.uuid && <Row gutter={{ xs: 10, lg: 12 }} style={{ marginTop: '25px' }}>
                <Col xs={24}>
                    <Form.Item help={<Typography.Text style={{ fontSize: '11px', color: '#b7b7b7' }}>Cantidad del pago recibido. (no puede ser mayor al último balance)</Typography.Text>} label="Monto recibido en este pago" name="amount" rules={[{ required: true, message: 'Por favor añade un monto.' }]}>
                        <Input onBlur={(v) => {
                            if (!state.last_balance) {
                                form.setFieldValue('last_balance', state.total);
                            } else {

                            }
                        }} type="number" max={!state.last_balance ? state.total : state.last_balance} placeholder="$" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ marginTop: '10px' }}>
                    <Form.Item help={<Typography.Text style={{ fontSize: '11px', color: '#b7b7b7' }}>Cuántos pagos has recibido</Typography.Text>} label="Número de parcialidad del pago" name="installment" rules={[{ required: true, message: 'Por favor añade un monto.' }]}>
                        <Input type="number" placeholder="#" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ marginTop: '10px' }}>
                    <Form.Item help={<Typography.Text style={{ fontSize: '11px', color: '#b7b7b7' }}>Cantidad que estaba pendiente por pagar antes de recibir este pago</Typography.Text>} label="Último balance" name="last_balance" rules={[{ required: true, message: 'Por favor añade un monto.' }]}>
                        <Input type="number" placeholder="$" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12} style={{ marginTop: '10px' }}>
                    <Form.Item label="Moneda" name="currency" rules={[{ required: true, message: 'Por favor añade una moneda.' }]}>
                        <Select

                            styles={generalreactSelectStyles}
                            className="pro-select-input"

                            options={[
                                { label: 'USD', value: 'usd' },
                                { label: 'EUR', value: 'eur' },
                                { label: 'MXN', value: 'mxn' },
                                { label: 'BRL', value: 'brl' },

                            ]}
                            isLoading={false}
                            onChange={(v) => {
                                setState({ ...state, currency: v })
                            }}
                            placeholder="Selecciona una moneda"

                        />
                    </Form.Item>
                </Col>
                {(state.currency?.label?.toUpperCase() !== 'MXN') && <Col xs={24} lg={12} style={{ marginTop: '10px' }}>
                    <Form.Item label="Tipo de cambio" name="exchange" rules={[{ required: true, message: 'Por favor añade un tipo de cambio.' }]}>
                        {/* <Typography.Text>El cambio debe ser: 1 MXN equivale a X USD</Typography.Text> */}
                        <Input className="pro-input" placeholder="Tipo de cambio" />
                    </Form.Item>
                </Col>}

                <Col xs={24}>
                    <Row>
                        <Form.Item label="Impuestos" name="taxes" rules={[{ required: false, message: '' }]}>
                            <Button type='secondary' onClick={() => setaddTax(true)} >Añadir impuestos</Button>
                        </Form.Item>
                        <Form.Item label="IVA 16%" name="taxes" rules={[{ required: false, message: '' }]}>
                            <Button type='secondary' onClick={() => setState({ ...state, taxes: [...(state?.taxes ?? []), { type: 'IVA', rate: 0.16, factor: 'Tasa', inclusive: false, withholding: false, base: form.getFieldValue('amount') / 1.16 ?? 0 }] })} >Añadir IVA 16%</Button>
                        </Form.Item>
                    </Row>
                </Col>

                {addTax && <ServiceTaxModal withBase={true} open={addTax} close={() => setaddTax(false)} add={(tax) => {
                    setState({ ...state, taxes: [...(state?.taxes ?? []), { ...tax, withholding: tax?.withholding ?? false }] })
                    setaddTax(false)
                }} />}

                <Col xs={24} style={{ marginBottom: '15px' }}>
                    <DefaultTableItemsLocal avoidContainer={true} empty="Registra un impuesto" elementName="impuesto" emptyAction={() => setaddTax(true)} data={taxes} columns={[{
                        title: 'Impuesto',
                        dataIndex: 'type',
                        render: (text, record) => {

                            return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                        }
                    },
                    {
                        title: 'Factor',
                        dataIndex: 'factor',
                        render: (text, record) => {

                            return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                        }
                    },
                    {
                        title: 'Tarifa',
                        dataIndex: 'rate',
                        render: (text, record) => {

                            return <Typography.Text className="" type="secondary" ellipsis>{text}</Typography.Text>
                        }
                    },
                    {
                        title: 'Base',
                        dataIndex: 'base',
                        render: (text, record, i) => {

                            return <Typography.Text
                                editable={{
                                    onChange: (e) => {
                                        if (!e) return
                                        if (isNaN(e)) return
                                        setState({
                                            ...state, taxes: [...state?.taxes?.map((tax, index) => {
                                                if (index === i) {
                                                    return { ...tax, base: Number(e.replace(/\$/g, '').replace(/,/g, '')) ?? 0 }
                                                }
                                                return tax
                                            })]
                                        })
                                    }
                                }}
                                className=""
                                type="secondary"
                            >{returnCurrencyValue(record.base)}</Typography.Text>
                        }
                    },
                    {
                        title: '',
                        dataIndex: 'rate',
                        render: (text, record, i) => {
                            return <>
                                <Space>
                                    <DeleteOutlined onClick={() => {
                                        // let newTaxes = state.taxes.splice(i, 1)
                                        // if (i === 0) newTaxes = state.taxes.splice(i, 1)
                                        let newTaxes = state?.taxes?.filter((tax, index) => index !== i)
                                        setState({ ...state, taxes: newTaxes })
                                    }} />
                                </Space>
                            </>
                        }
                    },

                    ]} />
                </Col>

                <Col xs={24}>
                    {taxes?.filter(tax => !tax.base || tax.base === 0).length > 0 && taxes?.length !== 0 ? <Typography.Text style={{ fontSize: '11px', color: '#ff5b4f' }}>La base de los impuestos debe ser mayor a cero</Typography.Text> : null}
                </Col>

                <Col xs={24} style={{ marginTop: '15px' }}>
                    <Row justify="center">
                        <Button disabled={missingBaseTaxes.length > 0 ? (missingBaseTaxes.length > 0 && taxes.length > 0) : false} htmlType='submit' type="primary">Añadir</Button>
                    </Row>
                </Col>
            </Row>}
        </Form>
    </Modal>);
}

export default AddRelatedInvoiceToPayment;