import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row, Typography, Timeline, Button, message } from 'antd';
import Lottie from 'lottie-react';
import loading from '../../assets/lotties/loader.json';
import check from '../../assets/lotties/check.json';
import { useEffect, useState } from 'react';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { FaClock } from 'react-icons/fa';
import Select from 'react-select'
import { generalreactSelectStyles } from '../../app/functions/styles';
import { VscError } from 'react-icons/vsc';
import { useAuth, useFirestore } from 'reactfire';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { paymentForms } from '../../app/functions/helpers';
// import { useAnalytics, useAuth } from 'reactfire';
// import TeamContext from '../../context/teamcontext/TeamContext';
// import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';

function CreateReceiptProcess({ open, close, data, endpoint }) {
    const auth = useAuth()
    const [step, setStep] = useState(0)
    const [error, setError] = useState(null)
    const [missingData, setMissingData] = useState(false)
    const [state, setState] = useState(data)
    const [next, setNext] = useState(false)

    const fs = useFirestore()

    const handleRequest = async () => {
        try {
            const res = await SignedInternalAPIRequest({ ...state }, endpoint, auth.currentUser)
            setStep(step + 1)
            try {
                await updateDoc(doc(fs, 'payments', data.paymentId), {
                    receipts: arrayUnion(res.receipt.id)
                })
            } catch (error) {
            }
        } catch (error) {
            setError(error)
            message.error('Ocurrio un error en la automatización')
        }
    }

    useEffect(() => {
        if (step === 0) {
            if (!state.payment_form) {
                setMissingData(true)
            } else {
                handleRequest()
            }
        } else {
            setTimeout(() => {
                setStep(step + 1)
            }, 4000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, next])

    const Header = () => {
        return <>
            <Row justify="space-between">
                <Typography.Title level={4}>Iniciando el proceso de autofactura</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close()} />
            </Row>
            <Typography.Text type='secondary'>Cónoce más sobre el proceso de autofactura <a href='https://helpcenter.gigstack.pro/es/articles/6946723-facturacion-automatica' target='_bank'>aquí</a></Typography.Text>
        </>
    }

    const TimelineReceipts = () => {
        return (
            <Row style={{ margin: '30px 20px 0px 20px' }}>
                <Timeline mode="left">
                    {step >= 0 && <Timeline.Item
                        dot={error ? <VscError style={{ fontSize: '22px', color: 'red' }} /> : <Lottie animationData={step === 0 ? loading : check} style={{ height: '30px' }} loop={step === 0 ? true : false} />}
                    >
                        {step === 0 ? 'Creando el recibo de venta...' : 'Recibo de venta creado'}</Timeline.Item>
                    }
                    {step >= 1 && <Timeline.Item
                        dot={<Lottie animationData={step === 1 ? loading : check} style={{ height: '30px' }} loop={step === 1 ? true : false} />}
                        color="red">
                        {step === 1 ? 'Enviando el recibo de venta por correo...' : 'Recibo de venta enviado al cliente'}
                    </Timeline.Item>}
                    {step >= 2 && <Timeline.Item dot={<FaClock style={{ fontSize: '22px' }} />} color='#6F75FF'>
                        Esperando que el cliente realice la autofactura</Timeline.Item>}
                </Timeline>
            </Row>)

    }


    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "40%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Header />
                {missingData && <Row justify='center' style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <Typography.Text style={{ margin: '15px 0px 5px 0px' }}>Debes agregar el método de pago</Typography.Text>
                    <Select
                        styles={generalreactSelectStyles}
                        className="pro-select-input"
                        options={paymentForms}
                        isLoading={false}
                        onChange={(v) => {
                            setState({ ...state, payment_form: v })
                        }}
                        placeholder="Método de pago"
                    />
                </Row>}
                {!missingData && <TimelineReceipts />}
                <Row justify='center'>
                    <Button type="primary" onClick={() => {
                        if (missingData) {
                            setMissingData(false)
                            setNext(true)
                        } else {
                            close()
                        }
                    }} htmlType="submit" disabled={state?.payment_form ? false : true}>{missingData ? "Continuar" : "Cerrar"}</Button>
                </Row>
            </div >

        </Modal >
    );
}

export default CreateReceiptProcess;