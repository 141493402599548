import React, { useContext, Suspense, useState, useEffect } from "react";
import { Button, Col, Divider, Popconfirm, Row, Table, Tag, Tooltip, Typography, message, Select, Breadcrumb } from 'antd';
// import Select from 'react-select';
import TeamContext from "../../context/teamcontext/TeamContext";
import UserContext from "../../context/usercontext/UserContext";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import TableTitle from "../tables/TableTitle";
import { generateCode, returnColorByUserRole, returnTextColorByUserRole, userRoleMessage } from "../../app/functions/helpers";
import { collection, deleteDoc, doc, getDocs, getFirestore, orderBy, query, updateDoc, where } from "firebase/firestore";
import { generalreactSelectStyles } from "../../app/functions/styles";
import ModalContext from "../../context/modalcontext/ModalContext";
import { useAuth, useFirestore } from "reactfire";
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests";
import EmptyTable from "../states/EmptyTable";
import moment from "moment";


function Teams() {

    const auth = useAuth();
    const fs = useFirestore();

    // TODO: Review Element Holder

    const { team } = useContext(TeamContext);
    const { user } = useContext(UserContext)
    const { handleOpenInviteModal, handleSetInviteType, handleSetModalTeam } = useContext(ModalContext);
    const [members, setMembers] = useState(team?.members ?? [])
    const [loading, setLoading] = useState(true)
    const [render, setRender] = useState('seats')

    const getMembers = async () => {
        try {
            setLoading(true)
            const rawMembers = await SignedInternalAPIRequest({
            }, `handleSeats/v1/teams/${team.id}/members`, auth.currentUser, {}, 'GET')
            setMembers(rawMembers)
            setLoading(false)
        } catch (error) {
            setMembers(team?.members ?? [])
            message.error('Error al obtener los miembros del equipo');
        }
    }
    useEffect(() => {
        getMembers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.id])

    const RolSelect = (text, record, column) => {

        let firstOptionLabel = {}

        switch (text) {
            case 'editor':
                firstOptionLabel = {
                    label: 'Editor',
                    value: 'editor'
                }
                break;
            case 'viewer':
                firstOptionLabel = {
                    label: 'Visor',
                    value: 'viewer'
                }
                break;
            case 'blocked':
                firstOptionLabel = {
                    label: 'Bloqueado',
                    value: 'blocked'
                }
                break;
            default:
                firstOptionLabel = {
                    label: '',
                    value: ''
                }
                break;
        }

        return <Select
            styles={generalreactSelectStyles}
            style={{ minWidth: '120px' }}
            disabled={record.id === user?.uid}
            className="pro-select-input"
            options={[{ label: 'Editor', value: 'editor' }, { label: 'Visor', value: 'viewer' }, { label: 'Bloqueado', value: 'blocked' }]}
            isLoading={false}
            defaultValue={firstOptionLabel}
            onChange={async (e) => {

                await updateDoc(doc(getFirestore(), `teams/${team.id}`), {
                    members: team.members.map(m => {
                        if (m.id === record.id) {
                            m[column] = e
                        }
                        return m
                    })
                })
            }}
        />
    }

    const TeamsPermissionsTable = () => {

        const [pendingInvites, setPendingInvites] = useState([])

        const handleDeleteMember = async (member) => {
            updateDoc(doc(getFirestore(), `teams/${team.id}`), {
                members: team.members.filter(m => m.id !== member.id),
                membersIds: team.membersIds.filter(m => m !== member.id)
            })
            // setMembers(team.members.filter(m => m.id !== member.id))
            getMembers()
        }

        const handleDeletePendingInvite = async (invite) => {
            try {
                await deleteDoc(doc(getFirestore(), `invites/${invite.id}`))
                message.success('Invitación eliminada correctamente')
                getPendingInvites()
            } catch (error) {
                message.error('Error al eliminar la invitación')
            }
        }

        const getPendingInvites = async () => {
            try {
                const inv = await getDocs(query(collection(fs, 'invites'), where("teamId", "==", team?.id), where("status", "==", "pending"), orderBy("timestamp", "desc")))
                setPendingInvites(inv.docs.map(i => ({ ...i.data(), id: i.id })))
            } catch (error) {
                message.error('Error al obtener las invitaciones pendientes')
            }
        }
        useEffect(() => {
            if (render === 'pendingInvites') {
                // console.log("teamId", "==", team?.id)
                getPendingInvites()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [render])

        let columns = []

        if (render === 'seats') {
            columns = [
                {
                    title: <TableTitle title="Usuario" />,
                    dataIndex: 'email',
                    key: generateCode(5),
                    fixed: 'left',
                    width: '25%',
                    sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            <Typography.Text className="primary" type="primary" >{record?.firstName ?? ''} {record?.lastName ?? ''}</Typography.Text>
                            <Typography.Text className="secondary" type="secondary" >{text}</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Rol" />,
                    dataIndex: 'rol',
                    key: generateCode(5),
                    width: '15%',
                    sorter: (a, b) => ((a?.rol).localeCompare(b?.rol)),
                    render: (text, record) => {
                        return <Tooltip title={userRoleMessage(text)}>
                            <div> <Tag className="statusTag" color={returnColorByUserRole(text)} style={{ color: returnTextColorByUserRole(text), borderRadius: '10px', opacity: 0.7 }}>{userRoleMessage(text)}</Tag></div>
                        </Tooltip>
                    }
                },
                {
                    title: <TableTitle title="Pagos" />,
                    dataIndex: 'payments',
                    key: generateCode(5),
                    width: '16%',
                    sorter: (a, b) => ((a?.payments).localeCompare(b?.payments)),
                    render: (text, record) => {
                        return RolSelect(text, record, 'payments')
                    }
                },
                {
                    title: <TableTitle title="Facturación" />,
                    dataIndex: 'invoices',
                    key: generateCode(5),
                    width: '16%',
                    sorter: (a, b) => ((a?.invoices).localeCompare(b?.invoices)),
                    render: (text, record) => {
                        return RolSelect(text, record, 'invoices')
                    }
                },
                {
                    title: <TableTitle title="Servicios" />,
                    dataIndex: 'services',
                    key: generateCode(5),
                    width: '16%',
                    sorter: (a, b) => ((a?.services).localeCompare(b?.services)),
                    render: (text, record) => {
                        //ROL SELECT DOS NOT SHOW COMPLETE, HOW TO FIX IT?

                        return RolSelect(text, record, 'services')
                    }
                },
                {
                    title: <TableTitle title="" />,
                    dataIndex: 'actions',
                    key: generateCode(5),
                    width: '5%',
                    render: (text, record) => {
                        if (record.id === user.uid || record.isSuperAdmin) return null
                        return <Popconfirm
                            title="¿Estás seguro de eliminar este miembro?"
                            onConfirm={() => handleDeleteMember(record)}
                            okText="Sí"
                            cancelText="No"
                        >
                            <Button type="link" style={{ color: '#FF4D4F' }} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    }
                }
            ]
        } else {
            columns = [
                {
                    title: <TableTitle title="Correo" />,
                    dataIndex: 'email',
                    key: generateCode(5),
                    fixed: 'left',
                    width: '25%',
                    sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                    render: (text, record) => {
                        return <div className="d-flex flex-column">
                            <Typography.Text className="secondary" type="secondary" >{text}</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Estado" />,
                    dataIndex: 'status',
                    key: generateCode(5),
                    fixed: 'left',
                    width: '25%',
                    sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                    render: (text, record) => {
                        const isExpired = record.expiring < moment().valueOf()
                        return <div>
                            <Tag color={isExpired ? "#FFECE1" : "#DDEEF8"} style={{ color: isExpired ? "#FF9458" : "#2FAEFF", borderRadius: '10px' }}>{isExpired ? 'Expirada' : 'Pendiente'}</Tag>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Fecha de expiración" />,
                    dataIndex: 'expiring',
                    key: generateCode(5),
                    fixed: 'left',
                    width: '25%',
                    sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                    render: (text, record) => {
                        return <div >
                            <Typography.Text type="secondary">{moment(text).format('DD MMM YYYY HH:mm')}</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="Fecha de creación" />,
                    dataIndex: 'timestamp',
                    key: generateCode(5),
                    fixed: 'left',
                    width: '25%',
                    sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                    render: (text, record) => {
                        return <div >
                            <Typography.Text type="secondary">{moment(text).format('DD MMM YYYY HH:mm')}</Typography.Text>
                        </div>
                    }
                },
                {
                    title: <TableTitle title="" />,
                    dataIndex: 'actions',
                    key: generateCode(5),
                    width: '5%',
                    render: (text, record) => {
                        return <Popconfirm
                            title="¿Estás seguro de eliminar esta invitación?"
                            onConfirm={() => handleDeletePendingInvite(record)}
                            okText="Sí"
                            cancelText="No"
                        >
                            <Button type="link" style={{ color: '#FF4D4F' }} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    }
                }
            ]
        }


        return (
            <Suspense fallback={<LoadingOutlined />}>
                <div style={{ width: "100%" }}>
                    <Table
                        loading={loading}
                        rowKey={record => record.id}
                        scroll={{ x: 500 }}
                        dataSource={render === 'seats' ? members : pendingInvites}
                        bordered={false}
                        showSorterTooltip={false}
                        pagination={{
                            pageSize: 10,
                            hideOnSinglePage: true,
                            pageSizeOptions: [10, 20, 50, 100],
                            // defaultCurrent: defaultPage,
                            // defaultPageSize: pageSize ?? 10
                        }}
                        className='pro-table'
                        locale={{
                            emptyText: <EmptyTable customMessage={"No hay miembros"} parentAction={() => { }} elementName={"miembro"} />,
                            filterCheckall: 'Todos',
                        }}
                        columns={columns}
                    />
                </div>
            </Suspense>
        )
    }

    const handleOpenInvitesModal = () => {
        handleOpenInviteModal()
        handleSetInviteType('team')
        handleSetModalTeam(team)
    }

    return (
        <Col sx={24}>
            <div className="settingsContainer">
                <Row sx={24} justify="space-between" style={{ width: '100%' }}>
                    <Col>
                        <Typography.Title style={{ marginBottom: '2px' }} level={5}>Usuarios con acceso a {team?.brand?.alias}</Typography.Title>
                        {/* <Typography.Text type="secondary">Gestiona quien tiene acceso a las cuentas</Typography.Text> */}
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => setRender('seats')}>
                                {render === 'seats' ? "Asientos" : <span style={{ color: "#8666FF" }}>Asientos</span>}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => setRender('pendingInvites')}>
                                {render === 'pendingInvites' ? "Invitaciones pendientes" : <span style={{ color: "#8666FF" }}>Invitaciones pendientes</span>}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col>
                        <Button style={{ marginTop: 2 }} type="primary" onClick={() => handleOpenInvitesModal()}> Invitar nuevo miembro </Button>
                    </Col>
                </Row>
                <Divider />
                <TeamsPermissionsTable />
            </div>
        </Col>
    )
}

export default Teams;