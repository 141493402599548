import { Row, Typography } from 'antd';
import React, { Suspense, useContext, useState } from 'react'
import { useMembership } from '../../customHooks/useMembership';
import ActivatePayAsYouGoModal from '../modals/ActivatePayAsYouGoModal';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import UserContext from '../../context/usercontext/UserContext';
import { useUserPermissions } from '../../customHooks/userPermissions';


function PayAsYouCard({ authData, collapseSider, billingAccountId }) {
    const { team } = useContext(TeamContext);
    const { billingAccount } = useContext(BillingAccountContext);
    const { user } = useContext(UserContext)

    const { isBillingAccountAdmin } = useUserPermissions({ user, team, billingAccount })

    const { isPaidUser, payAsYouGo } = useMembership({ uid: authData.uid, billingAccountId });

    const [activate, setActivate] = useState(false);


    const InviteToPayAsYouGo = () => {
        return <div onClick={() => {

            setActivate(true)

        }} style={{ width: '100%', padding: '10px 15px', backgroundColor: '#F9F3DE', borderRadius: '10px', alignItems: 'center', justifyContent: 'center' }} className="d-flex flex-column clickable">
            <Typography.Text className='strong' style={{ fontSize: '10px', color: '#333' }} >{collapseSider ? '' : 'Activa Pay As You Go'}</Typography.Text>
        </div>
    }

    const PayAsYouGoExists = () => {
        return <></>
    }

    if (!isBillingAccountAdmin) return <></>

    return (
        !isPaidUser ? <></> :
            <Row justify="center" style={{ width: '100%', padding: '0px 5px', marginBottom: '10px' }}>
                {activate && <Suspense>
                    <ActivatePayAsYouGoModal open={activate} close={() => setActivate(false)} authData={authData} />
                </Suspense>}
                {payAsYouGo ? <PayAsYouGoExists /> : <InviteToPayAsYouGo />}

            </Row>
    );
}

export default PayAsYouCard;