import { Button, Col, Popconfirm, Row, Space, Tooltip, Typography, message } from "antd";
import { DownloadFile, DownloadInvoice, returnCurrencyValue } from "../../app/functions/helpers";
import { useEffect, useState } from "react";
import { useAuth } from "reactfire";
import { DeleteOutlined, ExperimentOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined, LoadingOutlined, } from "@ant-design/icons";
import { deleteDoc, doc, getDoc, getFirestore } from "firebase/firestore";
import moment from "moment";
import infoGrad from '../../assets/images/information-grad.png';
import HelperCard from "../cards/HelperCard";

const InvoicesGlobalRelatedTo = ({ relatedTo,
    invoicesGlobal,
    item,
    userDocument,
    elementsRelated = [],
    showCards = true }) => {

    const auth = useAuth();
    const [downloading, setdownloading] = useState(null);
    const [invoiceGlobal, setInvoiceGlobal] = useState(null);
    const getInvoiceGlobal = async () => {
        try {
            const d = await getDoc(doc(getFirestore(), "invoices", item.invoice));
            setInvoiceGlobal(d.data());
        } catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        getInvoiceGlobal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteReceipt = async (record) => {
        try {
            setdownloading(record.id);
            try {
                const d = doc(getFirestore(), "receipts", record.id);
                await deleteDoc(d);

                message.success("Recibo eliminado");
                setdownloading(null);
            } catch (error) {
                setdownloading(null);
                message.error(error.message);
            }
        } catch (error) { }
    };
    var readableStatus = (invoiceGlobal?.status === 'completed' || invoicesGlobal?.invoices) ? 'Completo' : "En espera";
    var statusColor = readableStatus === 'Completo' ? '#CCF6DB' : '#8666FF';
    return (
        <>
            <Col justify="space-between" xs={24}>
                <HelperCard supportImageUrl={infoGrad} squareImg={true} title='¿Qué es una factura global?' description='Una factura global es un documento fiscal que agrupa todas las ventas que no pudieron ser facturadas individualmente. Esta factura se emite en la periodicidad que el cliente elija (Día, Semana, Mes, o cada dos meses) y es una manera eficiente de cumplir con las obligaciones fiscales y contables. Si un cliente no pudo autofacturarse y venció el plazo, entonces se puede crear una factura global para incluir todas esas ventas no facturadas.' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/7874509-que-es-una-factura-global', '_blank')} />
                <Row style={{ marginTop: '15px' }}>
                    <Col xs={24} key={invoiceGlobal?.id} style={{
                            marginTop: '15px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                            borderRadius: '10px',
                            padding: '10px 10px',
                            backgroundColor: 'white'
                        }} className="hoverBorderPrimary" >
                            <div className="relatedPaymentCard" style={{ position: 'relative' }}>
                                <Row justify="space-between" align="middle" style={{ padding: '10px 15px' }}>
                                    <div className="d-flex flex-column">
                                        <span style={{ fontSize: '11px', marginLeft: '0px', textDecoration: 'underline', textDecorationColor: statusColor, color: 'rgba(0, 0, 0, 0.45)' }}>{readableStatus}</span>
                                        <div className='d-flex flex-row'>
                                            <Typography.Text className='strong'>{invoiceGlobal?.client?.name ?? invoiceGlobal?.client?.legal_name ?? 'Sin cliente'} </Typography.Text>
                                            {!invoiceGlobal?.livemode && <ExperimentOutlined
                                                style={{
                                                    marginTop: 'auto',
                                                    marginBottom: 'auto',
                                                    marginLeft: "6px",
                                                    fontSize: "10px",
                                                    color: "#cecece",
                                                }}
                                            />}
                                        </div>
                                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{moment(invoiceGlobal?.timestamp).format('DD/MM/YYYY HH:mm')} - <Typography.Text type='secondary' copyable={true}>{invoiceGlobal?.id}</Typography.Text></Typography.Text>
                                    </div>
                                    <div className="d-flex flex-column" >

                                        <Space style={{ marginBottom: '15px' }}>

                                            {invoiceGlobal?.pdf &&
                                                (downloading === invoiceGlobal?.pdf ? (
                                                    <LoadingOutlined />
                                                ) :
                                                invoiceGlobal?.status === 'pending' && <FilePdfOutlined
                                                        className="secondary clickable itemHoverPrimary"
                                                        onClick={() => {
                                                            DownloadFile({
                                                                type: "pdf",
                                                                url: invoiceGlobal?.pdf,
                                                                name: "Recibo-de-venta",
                                                                setdownloading: (r) => setdownloading(r),
                                                            });
                                                        }}
                                                    />
                                                )}
                                            {invoiceGlobal && (
                                                <Button type='primary'
                                                    onClick={() => {
                                                        DownloadInvoice({
                                                            type: "pdf",
                                                            invoice: {
                                                                ...invoiceGlobal,
                                                                id: invoiceGlobal?.id,
                                                                uuid: invoiceGlobal?.uuid,
                                                            },
                                                            authUser: auth.currentUser,
                                                            setdownloading: (v) => setdownloading(v),

                                                        });

                                                    }}
                                                    loading={downloading === invoiceGlobal?.id}
                                                >Descargar factura</Button>
                                            )}
                                            {invoiceGlobal?.status === "pending" && (
                                                <Tooltip title="Copiar URL de auto-factura">
                                                    <Typography.Text
                                                        copyable={{
                                                            icon: <LinkOutlined />,
                                                            text: invoiceGlobal?.url,
                                                            tooltips: false,
                                                        }}
                                                    ></Typography.Text>
                                                </Tooltip>
                                            )}
                                            <EyeOutlined onClick={() => window.open(`/receipts`, '_blank')} style={{ color: '#b7b7b7' }} className="clickable" />
                                            {invoiceGlobal?.status === "pending" ? (
                                                downloading === invoiceGlobal?.id ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <Popconfirm onConfirm={async () => deleteReceipt(invoiceGlobal)} title="¿Eliminar el recibo?" okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar" cancelButtonProps={{ type: 'ghost' }}>
                                                        <DeleteOutlined
                                                            className="secondary itemHoverDanger clickable"
                                                        />
                                                    </Popconfirm>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </Space>
                                        <div className="pill">
                                            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{returnCurrencyValue(invoiceGlobal?.total)} {invoiceGlobal?.currency.toUpperCase()}</Typography.Text>
                                        </div>
                                    </div>
                                </Row>

                            </div>
                        </Col>
                </Row>

                <Row style={{ marginTop: '15px' }}>
                        <Typography.Title level={5}>
                            Recibos relacionados a la factura global
                        </Typography.Title>
                        <Typography.Text type="secondary">
                            Aquí puedes ver los recibos que se han incluido en esta factura global. La cantidad es {elementsRelated.length} recibos de venta.
                        </Typography.Text>
                    {elementsRelated.map((p) => {

                        var readableStatus = (p.status === 'completed' || p.invoices) ? 'Completo' : "En espera";
                        var statusColor = readableStatus === 'Completo' ? '#CCF6DB' : '#8666FF';
                        return <Col xs={24} key={p.id} style={{
                            marginTop: '15px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                            borderRadius: '10px',
                            padding: '10px 10px',
                            backgroundColor: 'white'
                        }} className="hoverBorderPrimary" >
                            <div className="relatedPaymentCard" style={{ position: 'relative' }}>
                                <Row justify="space-between" align="middle" style={{ padding: '10px 15px' }}>
                                    <div className="d-flex flex-column">
                                        <span style={{ fontSize: '11px', marginLeft: '0px', textDecoration: 'underline', textDecorationColor: statusColor, color: 'rgba(0, 0, 0, 0.45)' }}>{readableStatus}</span>
                                        <div className='d-flex flex-row'>
                                            <Typography.Text className='strong'>{p.client?.name ?? p.client?.legal_name ?? 'Sin cliente'} </Typography.Text>
                                            {!p.livemode && <ExperimentOutlined
                                                style={{
                                                    marginTop: 'auto',
                                                    marginBottom: 'auto',
                                                    marginLeft: "6px",
                                                    fontSize: "10px",
                                                    color: "#cecece",
                                                }}
                                            />}
                                        </div>
                                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{moment(p.timestamp).format('DD/MM/YYYY HH:mm')} - <Typography.Text type='secondary' copyable={true}>{p.id}</Typography.Text></Typography.Text>
                                    </div>
                                    <div className="d-flex flex-column" >

                                        <Space style={{ marginBottom: '15px' }}>

                                            {p.pdf &&
                                                (downloading === p.pdf ? (
                                                    <LoadingOutlined />
                                                ) :
                                                    p.status === 'pending' && <FilePdfOutlined
                                                        className="secondary clickable itemHoverPrimary"
                                                        onClick={() => {
                                                            DownloadFile({
                                                                type: "pdf",
                                                                url: p.pdf,
                                                                name: "Recibo-de-venta",
                                                                setdownloading: (r) => setdownloading(r),
                                                            });
                                                        }}
                                                    />
                                                )}
                                         
                                            {p.status === "pending" && (
                                                <Tooltip title="Copiar URL de auto-factura">
                                                    <Typography.Text
                                                        copyable={{
                                                            icon: <LinkOutlined />,
                                                            text: p.url,
                                                            tooltips: false,
                                                        }}
                                                    ></Typography.Text>
                                                </Tooltip>
                                            )}
                                            <EyeOutlined onClick={() => window.open(`/receipts`, '_blank')} style={{ color: '#b7b7b7' }} className="clickable" />
                                            {p.status === "pending" ? (
                                                downloading === p.id ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <Popconfirm onConfirm={async () => deleteReceipt(p)} title="¿Eliminar el recibo?" okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar" cancelButtonProps={{ type: 'ghost' }}>
                                                        <DeleteOutlined
                                                            className="secondary itemHoverDanger clickable"
                                                        />
                                                    </Popconfirm>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </Space>
                                        <div className="pill">
                                            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{returnCurrencyValue(p.total)} {p.currency.toUpperCase()}</Typography.Text>
                                        </div>
                                    </div>
                                </Row>

                            </div>
                        </Col>
                    })
                    }
                </Row>
            </Col>
        </>

    );
}

export default InvoicesGlobalRelatedTo;