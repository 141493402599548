import { Row, Typography } from 'antd';
import React from 'react'
import { useMembership } from '../../customHooks/useMembership';


/**
 * It's a component that displays the number of credits the user has left
 */
function CreditsCard({ authData, collapseSider, billingAccountId }) {
    const { credits, isPaidUser, payAsYouGo } = useMembership({ uid: authData.uid, billingAccountId });
    return (
        <Row justify="center" style={{ width: '100%', padding: '0px 5px', marginBottom: '10px' }}>
            <div onClick={() => {
                if (!isPaidUser) {
                    return window.open('https://app.gigstack.pro/memberships')
                }
            }} style={{ width: '100%', padding: '10px 15px', backgroundColor: '#efecfe', borderRadius: '10px', alignItems: 'center', justifyContent: 'center' }} className="d-flex flex-column">
                <Typography.Text className='strong' style={{ fontSize: '10px', color: '#8666ff' }} >{collapseSider ? (payAsYouGo && credits <= 0) ? `${credits * -1}PaG` : `${credits}` : (payAsYouGo && credits <= 0) ? `(${credits * -1}) Pay as you go ` : `${credits} eventos rest.`} </Typography.Text>
            </div>
        </Row>
    );
}

export default CreditsCard;