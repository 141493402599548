import {
    GET_TEAM_DATA,
    GET_TEAMLIST_DATA
} from '../../types';

function TeamReducer(state, action) {
    switch (action.type) {
        case GET_TEAM_DATA:
            return {
                ...state,
                team: action.payload
            };
        case GET_TEAMLIST_DATA:
            return {
                ...state,
                teamsList: action.payload
            };
        default:
            return state;
    }
}

export default TeamReducer;