import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import React, {  useState } from 'react'
import Select from 'react-select'
import { generalreactSelectStyles } from '../../app/functions/styles';
import { relationOptions } from '../../app/functions/helpers';



function AddRelatedFolioEgressInvoice({ open, close, userDocument, state: incomingState, updateState }) {
    const [state, setstate] = useState({
        documents: []
    })

    const [currentUuid, setCurrentUuid] = useState('')

    const [form] = Form.useForm();



    return (<Modal visible={open} onCancel={() => close()} footer={null} title={null}>
        <Typography.Title level={3}>Relacionar Facturas</Typography.Title>
        <Typography.Paragraph style={{ fontSize: '10px' }} type="secondary">Agrega los folios relacionados con la factura de egreso</Typography.Paragraph>
        <Form form={form} layout="vertical" onFinish={(v) => {
            updateState({
                related_documents: state
            })
            close()
        }}>
            <Row>
                <Col xs={24}>
                    <Row style={{ width: '100%', marginTop: '10px'}} justify="space-between">
                        <Form.Item name='related_folio' style={{ width: '80%' }} rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                            <Input placeholder="UUID" onChange={(v) => setCurrentUuid(v.target.value)} />
                        </Form.Item>
                        <Button onClick={() => {
                            setstate({ ...state, documents: [...state.documents, currentUuid]})
                        }}>Añadir</Button>
                    </Row>
                </Col>
                 <Col xs={24}>
                    {state?.documents?.map((v, i) => <div key={i} style={{ borderRadius: '10px', border: '1px solid #f1f1f1', padding: '10px', marginBottom: '10px' }} className="d-flex flex-column">
                        <Row justify="space-between">
                            <div className="d-flex flex-column">
                                <Typography.Text style={{ fontSize: '12px' }} >Folio: </Typography.Text>
                                <Typography.Text style={{ fontSize: '11px' }} type="secondary">{v}</Typography.Text>
                            </div>
                            <DeleteOutlined type="secondary" className="hoverDanger clickable" onClick={() => {
                                var arr = state.documents;
                                arr.splice(i, 1);
                                setstate({ ...state, documents: arr })
                            }} />
                        </Row>
                    </div>)}
                </Col>
                <Col xs={24}>
                    <Form.Item label="Relación entre facturas" name="relationship" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={relationOptions}
                            isLoading={false}
                            onChange={(v) => {
                                setstate({ ...state, relationship: v.value })

                            }}
                            placeholder="Relación entre facturas"
                        />
                    </Form.Item>

                </Col>
                <Col xs={24}>
                    <Row justify="center">
                        <Button htmlType='submit' type="primary">Añadir</Button>
                    </Row>
                </Col>
            </Row>

        </Form>
    </Modal>);
}

export default AddRelatedFolioEgressInvoice;