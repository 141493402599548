import React, { useContext, useState } from 'react';
import { Table, Typography, Tooltip, Drawer, Row, Col, Button, message } from 'antd';
import moment from 'moment';
import {
    CopyToClipboard,
    ShareViaWhatsapp,
    getItemsAmounts,
    returnCurrencyValue,
} from '../../app/functions/helpers';
import {
    CheckOutlined,
    CloseOutlined,
    CloudDownloadOutlined,
    LinkOutlined,
    LoadingOutlined,
    MailOutlined,
    WhatsAppOutlined,
} from '@ant-design/icons';
import ComposeEmailModal from '../email/ComposeEmailModal';
import TeamContext from '../../context/teamcontext/TeamContext';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { useAuth } from 'reactfire';

const PaymentTotalForClient = ({ payment, open, close, userDocument }) => {
    const [composeEmail, setComposeEmail] = useState(null);
    const { team } = useContext(TeamContext);
    const auth = useAuth()
    const [downloadFile, setDownloadFile] = useState(null)

    const DownloadXLSX = async ({ endpoint = ``, type = "forClients" }) => {
        try {
            setDownloadFile('forClients')
            await SignedInternalAPIRequest({
                clientId: payment.clientId,
            }, `exportFiles/v1/collectionPayments`, auth.currentUser, { isFile: true, fileName: `clientes-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
            setDownloadFile(false)

        } catch (error) {
            message.error('Error al descargar el archivo')
            setDownloadFile(false)
        }
    }
    const columns = [

        {
            title: <span style={{ color: 'gray' }}>CREACIÓN</span>,
            dataIndex: 'timestamp',
            key: 'timestamp',

            render: (text) => text ? moment(text).format('DD MMM YYYY H:mm') : null,
        },
        {
            title: <span style={{ color: 'gray' }}>ID DEL PAGO</span>,
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <Typography.Text copyable>{text}</Typography.Text>,
        },
        {
            title: <span style={{ color: 'gray' }}>VENCIMIENTO</span>,
            dataIndex: 'limitDateToPay',
            key: 'limitDateToPay',
            render: (text) =>
                text ? moment(text).format('DD MMM YYYY H:mm') : null,
        },
        {
            title: <span style={{ color: 'gray' }}>RECORDATORIO</span>,
            dataIndex: 'emailSend',
            key: 'emailSend',
            render: (text, record) => {
                const sendReminderMessage = 'Recordatorio enviado'
                const wohManyDaysMessage = 'Fecha en la que se envia el recordatorio del pago por correo a tu cliente'
                // console.log(record, 'record')
                const emailSend = record?.reminderEmailSended
                const limiteDateToPay = record?.limitDateToPay
                const defaultsDaysToSendEmail = team?.defaults?.paymentsReminderDays ?? 2
                const daysInReminderEmail = moment(limiteDateToPay).subtract(defaultsDaysToSendEmail, 'days').fromNow()
                return <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <div style={{ marginRight: '10px' }}>
                        {emailSend ?
                            <Tooltip title={sendReminderMessage}>
                                <CheckOutlined style={{ color: '#898989' }} />
                            </Tooltip> : <Typography.Text style={{ color: '#898989' }}>{!limiteDateToPay || (record.status === 'succeeded') ? null : <Tooltip title={wohManyDaysMessage}>{daysInReminderEmail ? `${daysInReminderEmail}` : null}</Tooltip>}</Typography.Text>}
                    </div>
                </div>
            },
        },
        {
            title: <span style={{ color: 'gray' }}>TOTAL</span>,
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                <span>
                    {returnCurrencyValue(
                        getItemsAmounts(
                            record.paymentItem.items ?? record.paymentItem.internalItems,
                            record.paymentItem.paidIn === 'bank'
                        ).total
                    )}{' '}
                    {record.paymentItem.currency}
                </span>
            ),
        },
        {
            title: <span style={{ color: 'gray' }}>ACCIONES</span>,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Tooltip title="Copiar link de pago">
                        <LinkOutlined
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                CopyToClipboard(
                                    record.paymentItem.shortURL ??
                                    `https://beta.gigstack.pro/pay/${record.paymentItem.token}`
                                );
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Enviar link por correo">
                        <MailOutlined
                            style={{ marginRight: '10px' }}
                            onClick={() => setComposeEmail(record.paymentItem)}
                        />
                    </Tooltip>
                    <Tooltip title="Enviar por Whatsapp">
                        <WhatsAppOutlined
                            style={{ marginRight: '10px' }}
                            onClick={() =>
                                ShareViaWhatsapp(
                                    `Puedes realizar el pago por ${returnCurrencyValue(
                                        getItemsAmounts(
                                            record.paymentItem.items ?? record.paymentItem.internalItems,
                                            record.paymentItem.paidIn === 'bank'
                                        ).total
                                    )} ${record.paymentItem.currency} en el siguiente enlace! 😉 : ${record.paymentItem.shortURL
                                    }`
                                )
                            }
                        />
                    </Tooltip>
                </span>
            ),
        },
    ];

    // const data = payment.payments.map((paymentItem) => ({
    //     key: paymentItem.id,
    //     paymentItem,
    //     amount: returnCurrencyValue(
    //         getItemsAmounts(
    //             paymentItem.items ?? paymentItem.internalItems,
    //             paymentItem.paidIn === 'bank'
    //         ).total
    //     ),
    //     id: paymentItem.id,
    //     create: paymentItem.create,
    //     limitDateToPay: paymentItem.limitDateToPay,
    //     emailSend: paymentItem.reminderEmailSended,
    // }));
    const data = payment.payments.map((paymentItem) => {
        const isPaymentExpired = paymentItem.limitDateToPay < moment().valueOf();

        if (isPaymentExpired) {
            return null;
        }

        const limiteDateToPay = paymentItem?.limitDateToPay;

        const defaultsDaysToSendEmail =
            team?.defaults?.paymentsReminderDays ?? 2;
        const daysInReminderEmail = moment(limiteDateToPay)
            .subtract(defaultsDaysToSendEmail, 'days')
            .fromNow();
        return {
            ...paymentItem,
            key: paymentItem.id,
            paymentItem,
            amount: returnCurrencyValue(
                getItemsAmounts(
                    paymentItem.items ?? paymentItem.internalItems,
                    paymentItem.paidIn === 'bank'
                ).total
            ),
            daysInReminderEmail: daysInReminderEmail,
            defaultsDaysToSendEmail: defaultsDaysToSendEmail,
            limiteDateToPay: limiteDateToPay,
            timestamp: paymentItem.timestamp,
            id: paymentItem.id,
            create: paymentItem.create,
            limitDateToPay: paymentItem.limitDateToPay,

        };
    }).filter(paymentItem => paymentItem !== null); // Filtrar los valores nulos (pagos vencidos)


    // console.log(data)
    const calculateTotalPayment = (payments) => {
        const paymentSums = {};

        // let expiredPaymentAmount = 0; // Inicializa el monto del pago vencido en cero

        for (const payment of payments) {
            const currency = payment.currency.toLowerCase();
            const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

            if (payment.limitDateToPay < moment().valueOf()) {
                // expiredPaymentAmount += amount;
            } else {
                if (!paymentSums[currency]) {
                    paymentSums[currency] = amount;
                } else {
                    paymentSums[currency] += amount;
                }
            }
        }

        const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
            return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
        });


        // const paymentSums = {};
        // for (const paymentItem of payments) {
        //     const currency = paymentItem.currency.toLowerCase();
        //     const amount = getItemsAmounts(paymentItem.items ?? paymentItem.internalItems, paymentItem.paidIn === 'bank').total;

        //     if (!paymentSums[currency]) {
        //         paymentSums[currency] = amount;
        //     } else {
        //         paymentSums[currency] += amount;
        //     }
        // }

        // const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
        //     return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
        // });

        return currencySumStrings.join(', ');
    };
    const totalPayment = calculateTotalPayment(payment.payments);

    return (
        <Drawer
            visible={open}
            closable={false}
            width={window.innerWidth < 780 ? '100%' : '80%'}
            drawerStyle={{ borderRadius: '20px' }}
            contentWrapperStyle={{
                padding: '10px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
            }}
            header={null}
            footer={null}
        >
            {composeEmail && (
                <ComposeEmailModal
                    close={() => setComposeEmail(null)}
                    actionText={null}
                    idRef={'payments'}
                    data={{ ...composeEmail, payment: composeEmail }}
                    open={composeEmail}
                    element={composeEmail}
                    subject={`Solicitud de pago por ${getItemsAmounts(
                        composeEmail.items,
                        composeEmail.paidIn === 'bank'
                    ).totalString}`}
                    userDocument={userDocument}
                    readableType={'pago'}
                    client={composeEmail.client ?? { name: 'Sin Cliente', email: '' }}
                />
            )}

            <div style={{ maxHeight: '100%', width: '100%' }} className="d-flex flex-column">
                <Row>
                    <Col xs={24}>
                        <Row justify="space-between">
                            <Typography.Title level={5}>
                                {`Información de los pagos vencidos de ${payment.payments[0].client.name}`}
                            </Typography.Title>
                            <CloseOutlined className="clickable" onClick={() => close()} />
                        </Row>
                        <div style={{ textAlign: 'right', marginBottom: '10px' }}>

                            <Button type="secondary" onClick={DownloadXLSX}>{downloadFile === 'forClients' ? <LoadingOutlined /> : <CloudDownloadOutlined />}Estado de cuenta</Button>
                        </div>
                        <Table columns={columns} dataSource={data} pagination={false} />
                        {/* <div style={{ borderTop: '1px solid gray', marginTop: '10px' }}> */}
                        <Typography.Text style={{ color: 'red', float: 'right', marginRight: '10%', fontSize: '20px' }}>
                            Monto Total   {totalPayment}
                        </Typography.Text>
                        {/* </div> */}
                    </Col>
                    {/* Línea divisoria y título de monto total */}
                </Row>
            </div>

        </Drawer >
    );
};

export default PaymentTotalForClient;
