import { CopyOutlined, DownloadOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import React, { useState } from 'react'
import success from '../../assets/lotties/success.json'
import Lottie from "lottie-react";
import { CopyToClipboard, ShareViaWhatsapp } from '../../app/functions/helpers';
import ComposeEmailModal from '../email/ComposeEmailModal';


function ElementCreated({ message, element = {}, close, actions = [], shareMessage = "", readableType = "Pago", userDocument, subject = "", idRef, actionText, data, edit, title = edit ? '¡Actualizado con éxito!' : "¡Creado con éxito!" }) {

    const [composeEmail, setcomposeEmail] = useState(false)

    const Email = () => {
        return <MailOutlined className='hoverPrimary clickable' style={{ fontSize: '20px', marginRight: '10px' }} />
    }
    const WhatsApp = () => {
        return <WhatsAppOutlined className='hoverPrimary clickable' style={{ fontSize: '20px', marginRight: '10px' }} />
    }
    const Copy = () => {
        return <CopyOutlined className='hoverPrimary clickable' style={{ fontSize: '20px', marginRight: '0px' }} />
    }


    return (
        <div className="d-flex flex-column" style={{ marginTop: '50px' }}>
            {composeEmail && <ComposeEmailModal close={() => setcomposeEmail(false)} actionText={actionText} idRef={idRef} data={data} userDocument={userDocument} open={composeEmail} element={element} subject={subject} readableType={readableType} client={element.client ?? { name: 'Sin Cliente', email: '' }} />}
            <Lottie animationData={success} style={{ height: '150px', }} loop={false} />
            <Typography.Title style={{ textAlign: 'center', }} level={3}>{title}</Typography.Title>
            <Typography.Text style={{ textAlign: 'center', fontSize: '12px' }} type="secondary">{message}</Typography.Text>
            {actions['qr'] && <Row justify="center">
                {/*BASE 64 TO IMAGE*/}
                <div style={{ position: 'relative' }} className="qrDownloadableContainer">
                    <div onClick={() => {
                        //DOWNLOAD IMAGE
                        const link = document.createElement('a');
                        link.href = element[actions['qr']];
                        link.download = 'qr.png';
                        link.click();
                    }} style={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: 'rgba(68, 68, 68,0.8)', borderRadius: '10px', alignItems: 'center', justifyItems: 'center', justifyContent: 'center' }} className="d-flex flex-column clickable qrDownloadableContainerChild">
                        <DownloadOutlined style={{ color: 'white', fontSize: '20px' }} />
                    </div>
                    <img src={element[actions['qr']]} alt="recibo" style={{ width: '80px' }} />

                </div>

            </Row>}
            <Row justify="center" style={{ marginTop: '25px' }}>
                {(actions['email'] && element && element[actions['email']]) && <div onClick={() => setcomposeEmail(true)}>
                    <Email onClick={() => { }} />
                </div>}
                {(actions['whatsapp'] && element && element[actions['whatsapp']]) && <div onClick={() => {
                    ShareViaWhatsapp(`${shareMessage} ${element[actions['whatsapp']]}`)

                }} >
                    <WhatsApp />
                </div>}
                {(actions['copy'] && element && element[actions['copy']]) && <div onClick={() => {
                    CopyToClipboard(element[actions['copy']])
                }} >
                    <Copy />
                </div>}
            </Row>
            {close && <Row justify="center" style={{ marginTop: '25px' }}>
                <Button onClick={() => close()} style={{ marginTop: '20px' }}>Cerrar</Button>
            </Row>}


        </div>
    );
}

export default ElementCreated;