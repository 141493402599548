import { Button, Col, Layout, Popconfirm, Row, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import CreateReceipt from '../../widgets/invoices/CreateReceipt';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useAuth } from 'reactfire';

function CreateReceiptPage() {
    const { team, getCurrentTeam } = useContext(TeamContext)
    const { billingAccount, getBillingAccount } = useContext(BillingAccountContext)
    const auth = useAuth();



    useEffect(() => {
        if (!team || !billingAccount) {
            const getData = async () => {
                const firstData = await getDoc(doc(getFirestore(), 'users', auth.currentUser?.uid))
                const firstTeam = await getDoc(doc(getFirestore(), 'teams', firstData?.data().lastTeamViewed))
                console.log(team, firstData?.data().lastTeamViewed, billingAccount, firstTeam?.data().billingAccount);
                console.log('BILLING ACCOUNT ');
                getCurrentTeam(firstData?.data()?.lastTeamViewed)
                getBillingAccount(firstTeam.data().billingAccount)
            }
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Layout>

            <Layout.Content>
                <Row justify="center">
                    <Col xs={23} lg={17} style={{ height: '100%', backgroundColor: '#fafbfd', padding: '15px 20px', borderRadius: '10px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', marginTop: '15px' }}>
                        <Row justify="space-between" style={{ height: '100%' }} align="middle">
                            <Popconfirm
                                onConfirm={() => {
                                    window.location.href = '/receipts'
                                }}
                                okText="Cerrar"
                                cancelText="Cancelar"
                                okButtonProps={{ danger: true }}
                                title="¿Estás seguro que deseas cerrar esta página?"


                            >

                                <Button type="ghost" onClick={() => {

                                }}>Cerrar</Button>
                            </Popconfirm>
                            <Typography.Title level={5}>Creando recibo de venta</Typography.Title>
                        </Row>
                    </Col>
                    <Col xs={23} lg={17} style={{ padding: '15px 20px', backgroundColor: 'white' }}>
                        <CreateReceipt onlyContent={true} />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default CreateReceiptPage;
