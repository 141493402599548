import { DeleteOutlined, DownloadOutlined, EditOutlined, FilePdfOutlined, LinkOutlined, LoadingOutlined, MailOutlined, QrcodeOutlined, StopOutlined, WarningOutlined } from "@ant-design/icons"
import { Popconfirm, Space, Tooltip, Typography, message } from "antd"
import { DownloadFile, DownloadInvoice, getItemsAmounts } from "../../app/functions/helpers"
import { Suspense, useContext, useState } from "react"
import { useAuth } from "reactfire"
import { deleteDoc, doc, getFirestore, updateDoc } from "firebase/firestore"
import CreateReceipt from "../invoices/CreateReceipt"
import UserContext from "../../context/usercontext/UserContext"
import ComposeEmailModal from "../email/ComposeEmailModal"
import CreateInvoiceFromReceiptModal from "../modals/CreateInvoiceFromReceiptModal"

export const ReceiptActions = ({ record }) => {

    const auth = useAuth()
    const fs = getFirestore()
    const { user } = useContext(UserContext)

    const [downloading, setdownloading] = useState(null)
    const [openEditReceiptModal, setopenEditReceiptModal] = useState(false)
    const [createReceiptModalEdit, setCreateReceiptModalEdit] = useState(null)
    const [showInvoiceFromReceiptModal, setShowInvoiceFromReceiptModal] = useState(null)
    const [composeEmail, setComposeEmail] = useState(null)
    const [/*actionOnReceipt*/, setactionOnReceipt] = useState(null)
    const [createReceipt, setcreateReceipt] = useState(false)


    /**
     * It deletes a receipt from the database
     * @param record - The record that was clicked on.
     */
    const deleteReceipt = async (record) => {
        try {
            setdownloading(record.id);
            try {
                const d = doc(fs, "receipts", record.id);
                await deleteDoc(d);

                message.success("Recibo eliminado");
                setdownloading(null);
            } catch (error) {
                setdownloading(null);
                message.error(error.message);
            }
        } catch (error) { }
    };

    const CancelReceipt = async (receipt) => {

        setactionOnReceipt(receipt.id)
        message.loading('Cancelando recibo...')
        try {
            await updateDoc(doc(fs, 'receipts', receipt.id), {
                status: 'cancelled',
                disallowInvoice: true
            })

            message.success('Recibo cancelado')
            setactionOnReceipt(null)
        } catch (error) {
            // console.log(error)
            message.error('Error al cancelar Recibo')
            setactionOnReceipt(null)
        }
    }

    return <Space>

        {showInvoiceFromReceiptModal && (<Suspense fallback={<>loading..</>}>
            <CreateInvoiceFromReceiptModal open={showInvoiceFromReceiptModal} close={(v) => {
                // if (v) {
                //     setpageActive(pageActive)
                // }
                setShowInvoiceFromReceiptModal(null)
            }} receipt={showInvoiceFromReceiptModal} />
        </Suspense>)}

        {openEditReceiptModal && (<Suspense fallback={<>loading..</>}>
            <CreateReceipt edit={createReceiptModalEdit} open={openEditReceiptModal} close={(v) => {
                if (v) {

                }
                setCreateReceiptModalEdit(false)
                setopenEditReceiptModal(null)
            }} data={{}} userDocument={user} />

        </Suspense>)}
        {composeEmail && <ComposeEmailModal close={() => setComposeEmail(null)} actionText={
            null
        } idRef={'receipts'} data={{ ...composeEmail, receipts: composeEmail }} userDocument={user} open={composeEmail} element={composeEmail} subject={`Recibo de venta por ${getItemsAmounts(composeEmail.items, false).totalString}`} readableType='Recibo de venta' client={composeEmail.client ?? { name: 'Sin Cliente', email: '', }} showEmailsView={true} />}
        {createReceipt && (
            <Suspense fallback={<>loading..</>}>
                <CreateReceipt edit={null} open={createReceipt} close={() => {
                    setcreateReceipt(false)
                }}
                    userDocument={user} data={{}} />
            </Suspense>
        )}

        {record.status === "pending" && (
            <>
                {<Tooltip title="Editar recibo de venta"><EditOutlined style={{ color: '#898989' }} onClick={() => {
                    setCreateReceiptModalEdit({
                        ...record, recordID: record.id, ...record.replicateElement,
                    })
                    setopenEditReceiptModal(true)
                }} /> </Tooltip>}
            </>
        )}
        {record.status === "pending" && (
            <Tooltip title="Cancelar recibo de venta">
                <Popconfirm
                    title="¿Estás seguro de cancelar el recibo?"
                    okText="Cancelar recibo"
                    cancelText="No"
                    onConfirm={() => {
                        if (
                            record.status === 'pending'
                        ) {
                            CancelReceipt(record)
                        }
                    }}
                >
                    <StopOutlined className="itemHoverDanger clickable" style={{ color: '#898989' }} />
                </Popconfirm>
            </Tooltip>
        )}


        {record.status === "pending" && (
            <>
                <Tooltip title="Copiar URL de auto-factura">
                    <Typography.Text
                        copyable={{
                            icon: <LinkOutlined />,
                            text: record.url,
                            tooltips: false,
                        }}
                    ></Typography.Text>
                </Tooltip>
                <Tooltip title='Enviar por correo'>
                    <MailOutlined className='secondary clickable itemHoverPrimary' onClick={() => setComposeEmail(record)} />
                </Tooltip>
            </>
        )}
        {(record?.invoiceError || ['bounce', 'deferred', 'blocked'].includes(record?.emails?.lastEvent)) && <Tooltip title={<div className="d-flex flex-column">
            <Typography.Text className='strong' style={{ color: 'white' }}>{record?.invoiceError ? 'Errores en automatización' : 'Errores en la entrega de correos'}</Typography.Text>
            <Typography.Text style={{ fontSize: '10px', color: 'white' }}>{record?.invoiceError}</Typography.Text>
        </div>}>
            <WarningOutlined className="" style={{ color: '#b7b7b7' }} />
        </Tooltip>}
        {record.pdf &&
            (downloading === record.pdf ? (
                <LoadingOutlined />
            ) : (
                <FilePdfOutlined
                    className="secondary clickable itemHoverPrimary"
                    onClick={() => {
                        DownloadFile({
                            type: "pdf",
                            url: record.pdf,
                            name: "Recibo-de-venta",
                            setdownloading: (r) => setdownloading(r),
                        });
                    }}
                />
            ))}
        {record.status === "completed" &&
            record.invoice &&
            !record.isGlobal &&
            (downloading === record.invoice ? (
                <LoadingOutlined />
            ) : (
                <DownloadOutlined
                    className="secondary clickable itemHoverPrimary"
                    onClick={() => {
                        DownloadInvoice({
                            type: "pdf",
                            invoice: {
                                ...record,
                                id: record.invoice,
                                uuid: record.invoice,
                            },
                            authUser: auth.currentUser,
                            setdownloading: (v) => setdownloading(v),
                        });
                    }}
                />
            ))}
        {record.status === "pending" && (
            <QrcodeOutlined style={{ color: '#b7b7b7', cursor: 'pointer' }} onClick={() => {
                setShowInvoiceFromReceiptModal(record)
            }} />
        )}
        {record.status === "pending" ? (
            downloading === record.id ? (
                <LoadingOutlined />
            ) : (
                <Popconfirm onConfirm={async () => deleteReceipt(record)} title="¿Eliminar el recibo?" okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar" cancelButtonProps={{ type: 'ghost' }}>
                    <DeleteOutlined

                        className="secondary itemHoverDanger clickable"
                    />
                </Popconfirm>
            )
        ) : (
            <></>
        )}
    </Space>
}