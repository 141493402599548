import { Button, Col, Layout, Row, Tooltip, Typography } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { CalendarOutlined, CustomerServiceOutlined, LoadingOutlined, LogoutOutlined } from '@ant-design/icons';
import FacturapiModal from '../integrations/FacturapiModal';
import { useUserAbilities } from '../../customHooks/userAbilities';

import BankModal from '../integrations/BankModal';

import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import PersonalInformation from './onboarding/PersonalInformation';
import BillingAccountOnboarding from './onboarding/BillingAccount';
import TeamOnboarding from './onboarding/TeamOnboarding';
import NoticeToStart from './onboarding/NoticeToStart';
import { signOut } from 'firebase/auth';
import { useAuth } from 'reactfire';



function SetupAccount({ userDocument: udoc = {} }) {

    const auth = useAuth();





    const [userDocument, setuserDocument] = useState({ ...udoc })
    const [addFacturapi, setAddFacturapi] = useState(false)



    const { canSendInvoices } = useUserAbilities({ userDocument })


    const [step, setStep] = useState(0)

    const [addBank, setaddBank] = useState(false)



    /**
     * It returns a number that represents the onboarding step
     * @param onboarded 
     * @returns number
     */
    const getOnboardingStep = (onboarded) => {
        if (onboarded.length === 0 || onboarded === undefined) {
            return 0
        }
        // if (onboarded.length >= 3) {
        //     return 4
        // }
        // if (onboarded.find(el => el === 'teamSetup')) {

        //     return 4
        // }
        if (onboarded.find(el => el === 'billingAccount')) {
            return 3
        }
        if (onboarded.find(el => el === 'personalInformation')) {
            return 2
        }
    }

    const getUser = async () => {
        try {
            if (udoc.uid) {
                setuserDocument(udoc)
                setStep(getOnboardingStep(udoc.onboarded))




            }

        } catch (error) {

        }


    }




    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])








    /**
     * It returns a component based on the value of the step variable
     * @returns A function that returns a component based on the value of the step variable.
     */
    const ToRenderByStep = () => {
        switch (step) {
            case 0:
                return <NoticeToStart setStep={(step) => setStep(step)} />
            case 1:

                return <PersonalInformation step={step} setStep={(step) => setStep(step)} />

            case 2:
                return <BillingAccountOnboarding step={step} setStep={(step) => setStep(step)} />

            case 3:
                return <TeamOnboarding step={step} setStep={(step) => setStep(step)} />

            //  case 4:
            //     return <ConnectAccounts setAddFacturapi={(a) => setAddFacturapi(a)} setaddBank={(a) => setaddBank(a)} step={step} setStep={(step) => setStep(step)} />
            default:
                return <PersonalInformation step={step} setStep={(step) => setStep(step)} />
        }
    }


    function GenerateCalendar() {

        (function (C, A, L) {

            let p = function (a, ar) { a.q.push(ar); };
            let d = C.document;
            C.Cal = C.Cal || function () {
                let cal = C.Cal;
                let ar = arguments;
                if (!cal.loaded) {
                    cal.ns = {};
                    cal.q = cal.q || [];
                    d.head.appendChild(d.createElement("script")).src = A;
                    cal.loaded = true;
                }
                if (ar[0] === L) {
                    const api = function () { p(api, arguments); };
                    const namespace = ar[1];
                    api.q = api.q || [];
                    typeof namespace === "string" ? (cal.ns[namespace] = api) && p(api, ar) : p(cal, ar);
                    return;
                }
                p(cal, ar);

            };

        })(window, "https://app.cal.com/embed/embed.js", "init");

        window.Cal("init", { origin: "https://app.cal.com" });

        // Important: Make sure to add `data-cal-link="scarranca/onboarding"` attribute to the element you want to open Cal on click
        window.Cal("ui", { "styles": { "branding": { "brandColor": "#000000" } }, "hideEventTypeDetails": false });

    }
    return (
        <Layout>
            {addFacturapi && <Suspense fallback={<LoadingOutlined />}>
                <FacturapiModal edit={canSendInvoices} open={addFacturapi} close={() => setAddFacturapi(false)} userDocument={userDocument} />
            </Suspense>}
            {addBank && <Suspense fallback={<LoadingOutlined />}>
                <BankModal open={addBank} close={() => setaddBank(false)} userDocument={userDocument} />
            </Suspense>}
            <Layout.Header style={{ backgroundColor: 'transparent', height: '50px', padding: '0px 30px', justifyContent: 'center' }} className="d-flex flex-column" >
                <Row justify="end">
                    <Row align="middle">
                        <Button type="primary" style={{ marginRight: '15px' }} id="intercomButtonOpen">Necesito ayuda</Button>
                        <Tooltip title="Cerrar sesión">
                            <LogoutOutlined className="hoverDanger clickable" onClick={() => {
                                signOut(auth)
                            }} />
                        </Tooltip>
                    </Row>
                </Row>
            </Layout.Header>
            <Layout.Content>
                <Row>
                    <Col xs={24} style={{ minHeight: '80vh', height: 'auto', maxHeight: 'auto', padding: '30px 0px', alignItems: 'center', justifyContent: 'center' }} className="d-flex flex-column">
                        <div style={{
                            backgroundColor: 'white', padding: '40px', width: '80%', borderRadius: '20px', marginTop: '0px', marginBottom: '5px', height: 'auto',
                            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)'
                        }} className="d-flex flex-column">
                            <ErrorBoundary fallback={<></>}>
                                <ToRenderByStep />
                            </ErrorBoundary>
                        </div>
                        <div hidden style={{ backgroundColor: '', width: '80%', borderRadius: '20px', marginTop: '20px', marginBottom: '5px' }} className="d-flex flex-column">
                            <Row justify="center">
                                <Typography.Title level={5}>¿Necesitas ayuda?</Typography.Title>
                            </Row>
                            <Row gutter={{ xs: 12, md: 15 }} justify="center">
                                <Col xs={24} lg={6}>
                                    <div style={{ backgroundColor: 'white', border: '1px solid #cecece', borderRadius: '10px', padding: '15px', alignItems: 'center' }} className="d-flex flex-column">
                                        <CustomerServiceOutlined style={{ fontSize: '25px' }} />
                                        <Typography.Text style={{ fontSize: '12px' }} className="strong">¿Necesitas ayuda?</Typography.Text>
                                        <Typography.Text style={{ fontSize: '11px', textAlign: 'center' }} type="secondary" className="">Nuestro equipo está disponible para ayudarte si lo necesitas.</Typography.Text>
                                        <Button size="small" style={{ marginTop: '10px' }} id="intercomButtonOpen">Chatear</Button>
                                    </div>
                                </Col>
                                <Col xs={24} lg={6}>
                                    <div style={{ backgroundColor: 'white', border: '1px solid #cecece', borderRadius: '10px', padding: '15px', alignItems: 'center' }} className="d-flex flex-column">
                                        <CalendarOutlined style={{ fontSize: '25px' }} />
                                        <Typography.Text style={{ fontSize: '12px' }} className="strong">Agendar onboarding</Typography.Text>
                                        <Typography.Text style={{ fontSize: '11px', textAlign: 'center' }} type="secondary" className="">Agenda una llamada para que nuestro equipo te ayude a integrarte.</Typography.Text>
                                        <Button size="small" style={{ marginTop: '10px' }} data-cal-link="scarranca/onboarding" onClick={GenerateCalendar}>Agendar</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Col>

                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default SetupAccount;