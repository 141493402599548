import { DeleteOutlined, EditOutlined, LoadingOutlined, PauseCircleOutlined, PlayCircleOutlined, WarningOutlined, } from '@ant-design/icons';
import { Col, Input, message, Popconfirm, Row, Space, Tag, Tooltip, Typography } from 'antd';
import { collection, doc, getFirestore, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { generateCode, invoiceUsage, paymentForms, SearchParamInURL } from '../../app/functions/helpers';

import TableTitle from '../tables/TableTitle';
import TeamContext from '../../context/teamcontext/TeamContext';

import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { useUserPermissions } from '../../customHooks/userPermissions';
import DefaultTableItems from '../tables/DefaultTableItems';
import UserContext from '../../context/usercontext/UserContext';
import CreateInvoice from './CreateInvoice';


const TableInvoicesRecurring = ({ userDocument }) => {



    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const { user } = useContext(UserContext)
    const fs = getFirestore();
    const invoiceRef = query(collection(fs, `invoice`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));
    // const paymentsToReviewRef = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), where('review_status', '==', 'pending'), where('type', '==', 'invoice'), orderBy('timestamp', 'desc'));
    // const pendingPaymentsRef = query(collection(fs, `payments`), where('owner', '==', userDocument?.uid), orderBy('timestamp', 'desc'));
    const recurringRef = query(collection(fs, `recurringEvents`), where('team', '==', team?.id ?? ''), where('type', '==', 'invoice'), orderBy('timestamp', 'desc'));
    const [actionOnPayment, setactionOnPayment] = useState(null)
    const [/*clients*/, setclients] = useState([])
    const [createRecurring, setcreateRecurring] = useState(false)
    const [editRecurring, seteditRecurring] = useState(null)
    const [pageActive, setpageActive] = useState(SearchParamInURL('pageActive') ?? 1)
    const [/*pageSize*/, setpageSize] = useState(10)
    const [filterByClient, setfilterByClient] = useState('')


    const { canCreateInvoicesRecurring } = useUserPermissions({ user, team, billingAccount })


    /**
     * It updates the status of a recurring event to paused or active
     * @param event - The event object that is being passed to the function.
     */
    const PauseRecurringEvent = async (event) => {
        setactionOnPayment(event.id)
        try {
            await updateDoc(doc(fs, `recurringEvents/${event.id}`), { status: event?.status === 'paused' ? 'active' : 'paused', pausedReason: null, tried: 0 })
            setactionOnPayment(null)
        } catch (error) {
            message.error(error.message ?? 'Error al pausar evento');
            setactionOnPayment(null)
        }
    }

    useEffect(() => {
        const GetClients = async () => {
            const cl = await getDocs(invoiceRef)
            setclients(cl.docs.map(d => d.data()))
        }
        GetClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])





    /* Creating a new array of objects with the client name as the key and the client name as the value. */

    // const filterableClients = RemoveDuplicatesFromObjectArray((clients ?? [])?.map((p) => ({
    //     text: (p?.client?.name ?? 'Sin cliente'), value: p?.client?.name ?? 'Sin cliente', key: (p?.client?.name ?? 'Sin cliente')
    // })), 'key')


    useEffect(() => {
        // console.log('invoice page')
    }, [])





    return <Row style={{ width: '100%' }}>
        {createRecurring && <Suspense fallback={<>loading..</>}>
            <CreateInvoice recurring={true} edit={editRecurring} open={createRecurring} close={(v) => {
                if (v) {

                }
                setcreateRecurring(false)
                seteditRecurring(null)
            }} data={editRecurring} userDocument={userDocument} />

        </Suspense>}
        <Col xs={24} style={{ marginTop: '25px' }}>
        </Col>
        <Col xs={24} style={{ marginTop: '25px' }}>
            <Row justify="end" style={{ marginBottom: '10px' }}>
                <Input.Search style={{ width: window.innerWidth <= 800 ? '100%' : '40%' }} placeholder="Buscar cliente" defaultValue={filterByClient} onSearch={setfilterByClient} allowClear />
            </Row>
            <DefaultTableItems empty='No tienes eventos recurrentes de Facturas'
                byClient={filterByClient}
                defaultPage={pageActive}
                key={(r) => r.id}
                columns={[
                    {
                        title: <TableTitle title="Cliente" />,
                        dataIndex: 'client',
                        key: generateCode(5),
                        fixed: 'left',
                        width: '16%',
                        sorter: (a, b) => ((a?.name)?.localeCompare(b?.name)),
                        render: (text, record) => {
                            return <Row align="middle">
                                <Tooltip title={record.status === 'active' ? 'Pausar' : 'Activar'}>
                                    <div onClick={() => {
                                        PauseRecurringEvent(record)
                                    }} className='customCircledTag' style={{ width: '30px', height: '30px' }}>
                                        {actionOnPayment === record.id ? <LoadingOutlined /> : record.status === 'active' ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                                    </div>
                                </Tooltip>
                                <div className="d-flex flex-column" style={{ flex: 1 }} >
                                    <Typography.Text className="primary">{record.client?.name ?? 'Sin Cliente'}</Typography.Text>
                                    <Typography.Text className="" type="secondary" style={{ fontSize: '10px' }} >{record?.replicateElement?.client?.company}</Typography.Text>
                                    <Typography.Text className="" type="secondary" style={{ fontSize: '8px' }} copyable={{ text: record.id }} >{record.id}</Typography.Text>
                                </div>
                            </Row>
                        }
                    },
                    {
                        title: <TableTitle title="Estado" />,
                        dataIndex: 'status',
                        key: generateCode(5),
                        ellipsis: true,
                        sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
                        render: (text, record) => {
                            const showErrors = (record.errorMessages ?? []).length > 0 && text !== 'active'
                            return <Tooltip title="">
                                <Tag className="statusTag" color={showErrors ? 'red' : '#E7F3FA'} style={{ borderRadius: '10px', opacity: 0.7, color: showErrors ? 'red' : '#0B9DF8' }}>{showErrors ? 'Con errores' : text === 'active' ? 'Activo' : 'En pausa'}</Tag>
                            </Tooltip>
                        }
                    },
                    //TODO: checar estoxs
                    // {
                    //     title: <TableTitle title="Total" />,
                    //     dataIndex: 'total',
                    //     key: generateCode(5),
                    //     ellipsis: true,
                    //     render: (text, record) => {

                    //         return <Typography.Text className="" type="secondary" >{getItemsAmounts(record?.replicateElement?.items).totalString} {record?.replicateElement?.currency}</Typography.Text>
                    //     }
                    // },
                    {
                        title: <TableTitle title="Creado" />,
                        dataIndex: 'timestamp',
                        key: generateCode(5),
                        ellipsis: true,
                        render: (text, record) => {
                            return (
                                <Tooltip title={moment(record.timestamp).format('D MMMM YYYY, HH:mm')}>
                                    <Typography.Text className="" type="secondary">
                                        {moment(record.timestamp).format('D MMMM YYYY, HH:mm')}
                                    </Typography.Text>
                                </Tooltip>)

                            // return <Typography.Text className="" type="secondary" >{moment(record.timestamp).format('d MMMM yyyy HH:mm ')}</Typography.Text>
                        }
                    },
                    {
                        title: <TableTitle title="Temporalidad" />,
                        dataIndex: 'timestamp',
                        key: generateCode(5),

                        render: (text, record) => {

                            return <div className="d-flex flex-column">
                                <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{record.temporality === 'daily' ? `Diario a las ${record.onTime}` : record.temporality === 'weekly' ? `Semanal, los ${moment().day(record.onWeekday).format('dddd')} ${record.onTime}` : `Mensual los días ${record.onDay} ${record.onTime}`}</Typography.Text>
                                <Typography.Text className="secondary" style={{ fontSize: '8px', marginBottom: '0px' }} >{`Iniciando ${moment(record.startDate).format('DD/MM/YY')}`} terminando {moment(record.endDate).format('DD/MM/YY')}</Typography.Text>

                            </div>
                        }
                    },
                    {
                        //TODO: MANAGE NEXT HIT
                        title: <TableTitle title="Eventos" />,
                        dataIndex: 'hits',


                        render: (text, record) => {

                            const relations = record.relations || []

                            return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{relations?.length > 0 ? `${relations.length} creados` : ''} </Typography.Text>
                        }
                    },
                    {
                        //TODO: MANAGE NEXT HIT
                        title: <TableTitle title="Siguiente evento" />,
                        dataIndex: 'nextHit',


                        render: (text, record) => {
                            const active = record.status === 'active';
                            return <Typography.Text className="secondary" style={{ fontSize: '12px', marginBottom: '0px' }} >{active ? record.nextRun ? moment(record.nextRun).format('ddd D MMMM YYYY HH:mm ') : record.pausedReason ? record.pausedReason : '-' : '-'}</Typography.Text>
                        }
                    },
                    {
                        //TODO: MANAGE NEXT HIT
                        title: <TableTitle title="" />,
                        dataIndex: 'actions',


                        render: (text, record) => {
                            const showErrors = (record.errorMessages ?? [])?.length > 0 && record.status !== 'active'
                            return <Space>

                                {canCreateInvoicesRecurring && <EditOutlined onClick={() => {
                                    seteditRecurring({
                                        ...record, recordID: record.id, ...record.replicateElement,
                                        startDate: moment(record.startDate), endDate: moment(record.endDate),
                                        temporality: {
                                            value: record.temporality,
                                            label: record.temporality === 'daily' ? 'Diario' : record.temporality === 'weekly' ? 'Semanal' : 'Mensual'
                                        },
                                        startEndDate: [moment(record.startDate), moment(record.endDate)],
                                        onTime: moment().hour(record.onTime.split(':')[0]).minute(record.onTime.split(':')[1]),
                                        items: record?.replicateElement?.internalItems,
                                        type: record?.replicateElement?.invoiceType,
                                        use: invoiceUsage.find((u) => u.value === record?.replicateElement?.use),
                                        payment_form: paymentForms.find((u) => u.value === record?.replicateElement?.payment_form),
                                        payment_method: [{ label: 'Pago en una sola exhibición', value: 'PUE' }, { label: 'Pago en parcialidades', value: 'PPD' }].find((u) => u.value === record?.replicateElement?.payment_method),
                                        currency: record?.replicateElement?.currency
                                    })
                                    setcreateRecurring(true)
                                }} />}

                                {showErrors && <Tooltip title={<div className="d-flex flex-column">
                                    <Typography.Text className='strong' style={{ color: 'white' }}>Errores en la recurrencia</Typography.Text>
                                    <Typography.Text style={{ fontSize: '10px', color: 'white', whiteSpace: 'pre-wrap' }}>{(record.errorMessages ?? []).map(m => m.message + ' - ' + moment(m.timestamp).format('DD/MM/YYYY HH:mm:ss') + '\n\n')}</Typography.Text>
                                </div>}>
                                    <WarningOutlined className="" />
                                </Tooltip>}

                                {canCreateInvoicesRecurring && <Popconfirm title="¿Eliminar solicitud recurrente?" okButtonProps={{ danger: true }} okText="Eliminar" onConfirm={async () => {
                                    try {
                                        await updateDoc(doc(fs, 'recurringEvents', record.id), { status: 'deleted', team: `old-${record?.team}` })
                                    } catch (error) {
                                        message.error(error.message ?? 'Error al pausar evento');
                                    }

                                }}>
                                    <DeleteOutlined />
                                </Popconfirm>}
                            </Space>
                        }
                    },



                ]}
                query={recurringRef}
                // data={recurringData ?? []} 
                // loading={paymentsDBStatus === 'loading'} 
                onPageChange={(page, pageSize) => {
                    setpageActive(page)
                    setpageSize(pageSize)
                }}
                userDocument={userDocument} />

        </Col>
    </Row>
}



export default TableInvoicesRecurring;