import { BulbFilled, EditOutlined, MinusCircleOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Tooltip, Typography, message } from 'antd';
import React, { useContext, useState } from 'react'
import { generateCode, ProductAvailableForSAT, returnCurrencyValue } from '../../app/functions/helpers';
// import CreateElementHolder from '../drawers/CreateElementHolder';
import ServiceListModal from './ServicesListModal';
import AddNewService from './AddNewService';
import { doc, getFirestore, setDoc, } from 'firebase/firestore';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';


function ServicesViewTable({ userDocument, selectedItems, onItemsChange, currency, forSat }) {
    const { team } = useContext(TeamContext)
    const { user } = useContext(UserContext)

    const consultItem = async (item) => {
        try {
            setCreateService(true);
            setEditService(item);

            const docRef = doc(getFirestore(), 'services', item.id);
            try {
                await setDoc(docRef, {
                    ...item,
                    id: item.id,
                    team: team.id,
                    billingAccount: team.billingAccount,
                    owner: user.uid
                }, {
                    merge: true
                });
            } catch (createError) {
                message.error('Error al crear o actualizar el documento:', createError);
            }
        } catch (error) {
            message.error('Error al consultar la base de datos:', error);
        }
    }

    const [items, setitems] = useState(selectedItems ? (selectedItems ?? []) : [])
    const [addServices, setaddServices] = useState(false)

    const [createService, setCreateService] = useState(false)
    const [editService, setEditService] = useState(null)

    return (
        <Row>
            {
                createService && <AddNewService refreshChild={(updateItem) => {

                    const index = items.findIndex(c => c.id === updateItem.id)
                    if (index > -1) {
                        items[index] = updateItem
                    }
                    setitems([...items])
                    onItemsChange([...items])
                }} open={createService} edit={editService} close={() => {
                    setCreateService(false)
                    setEditService(null)

                }} userDocument={userDocument} type="services" />
            }
            {addServices && <ServiceListModal importFromStripe={false} open={addServices} close={() => setaddServices(false)} userDocument={userDocument} onSelect={(s) => {
                setitems([...items, s])
                onItemsChange([...items, s])
                setaddServices(false)
            }} />}

            <Col xs={24}>
                <div className="d-flex flex-column" style={{ maxWidth: '100%', overflow: 'auto' }}>
                    <table>
                        <thead>
                            <tr>
                                <td> <Typography.Text style={{ fontSize: '12px' }} className='strong'>Servicio/Producto</Typography.Text></td>
                                <td> <Typography.Text style={{ fontSize: '12px' }} className='strong'>Cantidad</Typography.Text></td>
                                <td> <Typography.Text style={{ fontSize: '12px' }} className='strong'>Impuestos</Typography.Text></td>
                                <td> <Typography.Text style={{ fontSize: '12px' }} className='strong'>Precio</Typography.Text></td>
                                <td> <Typography.Text style={{ fontSize: '12px' }} className='strong'>Descuento</Typography.Text></td>
                                <td> <Typography.Text style={{ fontSize: '12px' }} className='strong'>Total</Typography.Text></td>
                            </tr>
                        </thead>
                        <tbody style={{ marginTop: '10px' }}>
                            {items?.map((item, index) => {
                                const qtty = item.paymentType?.value === 'hour' ? item.hours : item.quantity;
                                return <tr key={`tr-${index}`} >
                                    <td style={{ height: '55px', borderBottom: '1px solid #fafbfd', padding: '10px 0px', width: '25%' }}>
                                        <Row align='middle' style={{ paddingRight: '5px' }}>
                                            <Avatar size={20} style={{ marginRight: '5px', backgroundColor: '#9AEDB6' }} icon={<BulbFilled />} />
                                            <Typography.Text editable={{
                                                onChange: (v) => {
                                                    items[index].name = v;
                                                    setitems(items)
                                                    onItemsChange(items)
                                                }
                                            }} style={{ fontSize: '12px', padding: '7px 15px', backgroundColor: 'white', borderRadius: '10px', flex: 1 }}>{item.name}</Typography.Text>
                                        </Row>
                                    </td>
                                    <td style={{ height: '55px', borderBottom: '1px solid #fafbfd', padding: '10px 0px' }}>  <Typography.Text style={{ fontSize: '12px', padding: '7px 15px', backgroundColor: 'white', borderRadius: '10px', flex: 1 }}
                                        editable={{
                                            onChange: (v) => {
                                                var newNumber = Number(v);
                                                if (isNaN(newNumber)) {
                                                    newNumber = 1;
                                                }
                                                if (item.paymentType?.value === 'hour') {
                                                    items[index].hours = newNumber;
                                                } else {
                                                    items[index].quantity = newNumber;
                                                }
                                                setitems(items)
                                                onItemsChange(items)
                                            }
                                        }}
                                    >{qtty}</Typography.Text></td>
                                    <td style={{ height: '55px', borderBottom: '1px solid #fafbfd', padding: '10px 0px' }}>
                                        {item?.taxes?.length > 0 ? <div className="d-flex flex-column" style={{
                                            padding: '7px 15px', backgroundColor: 'white', borderRadius: '10px',
                                            marginRight: '5px'
                                        }}>
                                            {item.taxes?.map((tax, index) => {
                                                return <Row align="middle" key={`tax=${index}${generateCode(4)}`}>
                                                    <Typography.Text style={{ fontSize: '12px', paddingRight: '5px' }}>{tax.type}  </Typography.Text>
                                                    <Typography.Text style={{ fontSize: '12px' }}>{parseFloat(tax.rate * 100).toFixed(2)} </Typography.Text>
                                                    <Typography.Text style={{ fontSize: '12px' }}> %</Typography.Text>
                                                </Row>
                                            })}

                                            {(item?.localTaxes ?? [])?.length > 0 && item.localTaxes?.map((tax, index) => {
                                                return <Row align="middle" key={`tax=${index}${generateCode(4)}`}>
                                                    <Typography.Text style={{ fontSize: '12px', paddingRight: '5px' }}>{tax.type} (Local) </Typography.Text>
                                                    <Typography.Text style={{ fontSize: '12px' }}>{parseFloat(tax.rate * 100).toFixed(2)} </Typography.Text>
                                                    <Typography.Text style={{ fontSize: '12px' }}> % </Typography.Text>
                                                </Row>
                                            })}
                                        </div> : <></>}
                                    </td>
                                    <td style={{ height: '55px', borderBottom: '1px solid #fafbfd', padding: '5px 0px' }}>
                                        <Row align="middle" style={{ padding: '7px 15px', backgroundColor: 'white', borderRadius: '10px', marginRight: '5px' }}>
                                            <Typography.Text editable={{
                                                onChange: (v) => {
                                                    var nv = v.replaceAll('$', '').replaceAll(' ', '').replaceAll(',', '')
                                                    if (isNaN(nv)) {
                                                        nv = items[index].total;
                                                    }
                                                    items[index].total = nv;
                                                    setitems(items)
                                                    onItemsChange(items)
                                                }
                                            }} style={{ fontSize: '12px' }}>{returnCurrencyValue(item.total)} </Typography.Text>

                                        </Row>
                                    </td>
                                    {/*ADD DISCOUNT AS 0 AND MAKE IT EDITABLE*/}
                                    <td style={{ height: '55px', borderBottom: '1px solid #fafbfd', padding: '5px 0px' }}>
                                        <Row align="middle" style={{ padding: '7px 15px', backgroundColor: 'white', borderRadius: '10px', marginRight: '5px' }}>
                                            <Typography.Text editable={{
                                                onChange: (v) => {
                                                    //REGEX V AS NUMBER
                                                    const nv = v.replaceAll('$', '').replaceAll(' ', '').replaceAll(',', '')
                                                    console.log(nv);
                                                    if (isNaN(nv)) return
                                                    items[index].discount = Number(nv);
                                                    setitems(items)
                                                    onItemsChange(items)
                                                }
                                            }} style={{ fontSize: '12px' }}>{returnCurrencyValue(item.discount || 0)} </Typography.Text>
                                        </Row>
                                    </td>
                                    <td style={{ height: '55px', borderBottom: '1px solid #fafbfd', padding: '5px 0px' }}>
                                        <Row align="middle" style={{}}>
                                            <Typography.Text style={{ fontSize: '12px', padding: '7px 15px', backgroundColor: 'white', borderRadius: '10px', maxWidth: '80%' }}>{returnCurrencyValue(item.total * qtty)} {currency}</Typography.Text>
                                            {!ProductAvailableForSAT(item) && <Tooltip title="El servicio no cuenta con un id de producto en el SAT">
                                                <WarningOutlined />
                                            </Tooltip>}
                                            {/* <EditOutlined style={{ marginLeft: '5px' }} onClick={async () => {
                                                //buscar el servicio en la base de datos si existe

                                                // const service = (await getDoc(doc(getFirestore(), 'services', item.id))).data()
                                                // console.log(service)
                                                // if (!service) {
                                                //     console.log('no existe el servicio')
                                                //     return;
                                                // }



                                                console.log(item)
                                                // if(!item.id){


                                                setCreateService(true)
                                                setEditService(item)
                                                // handleSetModalPropsData('service', { edit: item })
                                                // handleOpenModal(item)
                                            }} /> */}
                                            <EditOutlined style={{ marginLeft: '5px' }} onClick={() => consultItem(item)} />

                                            <MinusCircleOutlined onClick={() => {
                                                items.splice(index, 1);
                                                setitems(items)
                                                onItemsChange(items)
                                            }} className="clickable" style={{ color: 'red', marginLeft: '5px' }} />

                                        </Row>

                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    <Row>
                        <Button type="text" onClick={() => setaddServices(true)} className="primary" style={{ padding: '0px 10px', marginTop: '20px' }} ><Row align="middle">
                            <PlusOutlined />
                            <Typography.Text className="primary" style={{ marginLeft: '5px' }}>Agregar servicio</Typography.Text>
                        </Row></Button>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default ServicesViewTable;