import { CloseOutlined, FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { Modal, Row, Typography, Button, Rate, Input, Col, message } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { generateCode } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import buenaCalificacion from '../../assets/images/buenaCalificacion.png';
import gracias from '../../assets/images/gracias.png';
import moment from 'moment';
const { TextArea } = Input;

function ReviewModalOnboarding({ open, close }) {

    const desc = ['Terrible', 'Malo', 'Normal', 'Bueno', 'Excelente'];
    const { team } = useContext(TeamContext);
    const { user } = useContext(UserContext);
    const [smile, setSmile] = useState(0);
    const [step, setStep] = useState(1);
    const [state, setState] = useState({
        whyNos: '',
        experience: '',
    })

    const handleNext = () => {
        setStep(step + 1);
    };
    const propBD = () => {
        updateDoc(doc(getFirestore(), 'users', user?.uid), {
            reviewOnboarding: moment().valueOf(),
        });
    }

    const handleReview = async () => {
        try {
            await setDoc(doc(getFirestore(), 'reviewsOboarding', generateCode(10, 'reviewOnb')), {
                name: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
                team: team?.id ?? '',
                alias: team?.brand?.alias ?? '',
                user: user?.uid ?? '',
                sesionOnb: smile ?? '',
                whyNos: state.whyNos ?? '',
                experience: state.experience ?? '',
                fecha: moment().valueOf() ?? '',
            });
            propBD()
            handleNext()
            message.success('Gracias por tu feedback');
        } catch (error) {
            console.log(error)
            message.error(error.message ?? 'Ocurrió un error al guardar tu respuesta')
        }
    };

    // const handleNext = () => {
    //     if (step === steps.length) {
    //         // Último paso, solo avanzar al siguiente
    //         setStep(step + 1);
    //     } else {
    //         form.validateFields().then(() => {
    //             setStep(step + 1);
    //         });
    //     }
    // };

    const customIcons = {
        1: <FrownOutlined />,
        2: <FrownOutlined />,
        3: <MehOutlined />,
        4: <SmileOutlined />,
        5: <SmileOutlined />,
    };

    const steps = [
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Row justify="center" style={{ margin: '10px' }}>
                                <img src={buenaCalificacion} alt="buenaCalificacion" style={{ width: '100px', height: '100px' }} />
                            </Row>
                            <Typography.Title level={4}>
                                Cuéntanos tu experiencia en gigstack
                            </Typography.Title>
                            <Typography.Text>
                                Te tomará menos de 2 minutos
                            </Typography.Text>
                            <Button type="primary" style={{ marginTop: '30px' }} onClick={handleNext}>
                                Comenzar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                ¿Qué tal te pareció tu sesión de onboarding con<br />  nuestros expertos, la recomendarías? </Typography.Title>
                            <Rate
                                tooltips={desc}
                                value={smile}
                                onChange={(val) => setSmile(val)}
                                character={({ index }) => customIcons[index + 1]}
                                style={{ color: '#8666fe', fontSize: '34px', marginBottom: '40px' }}
                            />
                            <Button type="primary" style={{ marginTop: '30px' }} disabled={smile === 0 ? true : false} onClick={handleNext}>
                                Siguiente
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                ¿Por qué has elegido trabajar con nosotros?
                            </Typography.Title>
                            {/* <Form form={form} onFinish={handleReview}> */}
                            {/* <Form.Item name="whiNos"> */}
                            <TextArea rows={4} placeholder="Escribe tu comentario aquí" value={state.whyNos} onChange={(v) => setState({ ...state, whyNos: v.target.value })} />
                            {/* </Form.Item> */}
                            <Button type="primary" style={{ marginTop: '30px' }} onClick={handleNext}>
                                Siguiente
                            </Button>
                            {/* </Form> */}
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                ¿Cómo podemos mejorar tu experiencia? </Typography.Title>
                            {/* <Form form={form} onFinish={handleReview}> */}
                            {/* <Form.Item name="experience"> */}
                            <TextArea rows={4} placeholder="Escribe tu comentario aquí" value={state.experience} onChange={(v) => setState({ ...state, experience: v.target.value })} />
                            {/* </Form.Item> */}
                            <Button type="primary" style={{ marginTop: '30px' }} onClick={handleReview}>
                                Enviar
                            </Button>
                            {/* </Form> */}
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();

                        }} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Row justify="center" style={{ margin: '10px' }}>
                                <img src={gracias} alt="gracias" style={{ width: '100px', height: '100px' }} />
                            </Row>
                            <Typography.Title level={4}>
                                ¡Gracias por tu respuesta!
                            </Typography.Title>
                            <Typography.Text style={{ marginBottom: '20px' }}>
                                Todas tus respuestas nos ayudan<br /> mucho a seguir creciendo y <br />mejorando
                            </Typography.Text>

                        </Row>
                    </Col>
                </Row>)
        }
    ];

    return (
        <Modal
            visible={open}
            footer={null}
            onCancel={() => close()}
            width={600}
            maskClosable={false}
            destroyOnClose
            closable={false} o
        >
            {steps[step - 1].content}
        </Modal>
    );
}

export default ReviewModalOnboarding;


