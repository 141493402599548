


/**
 * It takes a user document and returns an object with the user's defaults used in some features of the app.
 */
export const UseUserDefaults = ({ userDocument, team = {} }) => {


    const userDefauts = {
        product_key: '01010101',
        periodicity: 'month',
        emailBody: '',
        avoidReceipts: false,
        availNationality: [{ label: "Mexico", value: "Mexico", code: "MX" }],
        defaultTaxes: [],
        ...userDocument?.defaults,
        ...team?.defaults



    }


    return {
        defaults: userDefauts
    }


}