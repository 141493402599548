import { doc, getFirestore } from "firebase/firestore";
import { useFirestoreDocData } from "reactfire";


/**
 * It returns a membership object that contains the user's membership status and credit balance
 */
export const useMembership = ({ uid, billingAccountId }) => {

    let ref
    if (billingAccountId) {
        ref = doc(getFirestore(), "billingAccounts", billingAccountId);
    } else {
        ref = doc(getFirestore(), "memberships", uid);
    }

    const {

        data: membership,
    } = useFirestoreDocData(ref);



    const loaded = false;
    const credits = (membership?.credits ?? 0);
    const isPaidUser = (membership?.membership ?? 0) > 0;
    const payAsYouGoAllowed = membership?.subscription?.plan?.amount >= 99800
    const payingAmount = membership?.subscription?.plan?.amount;
    const currency = membership?.subscription?.plan?.currency;
    const canCallAPI = membership?.haveAPIAccess ?? false;
    return {
        isPaidUser,
        credits,
        loaded,
        membership,
        payAsYouGo: membership?.payAsYouGo ?? false,
        payAsYouGoAllowed,
        payingAmount,
        price_id: membership?.price_id,
        currency,
        canCallAPI
    }

}