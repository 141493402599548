import { ArrowDownOutlined, ArrowUpOutlined, LoadingOutlined, LoginOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Space, Tabs, Typography } from 'antd';
import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';
import React, { Suspense, useContext, useState } from 'react'
import { useFirestoreCollectionData } from 'reactfire';
import ActionablePageHeader from '../../widgets/headers/ActionablePageHeader';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import OpenBankingModal from '../../widgets/integrations/OpenBankingModal';
import DefaultTableItems from '../../widgets/tables/DefaultTableItems';
import { returnCurrencyValue } from '../functions/helpers';
import moment from 'moment';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useMembership } from '../../customHooks/useMembership';
import BlockedModal from '../../widgets/modals/BlockedModal';
import defaultOpb from '../../assets/images/openbblurred.png'
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';

function BanksPage({ userDocument }) {

    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const accountsRef = query(collection(getFirestore(), `OBAccounts`), where('team', '==', team?.id));
    const { status: accountsDBStatus, data: accountsData } = useFirestoreCollectionData(accountsRef, {})
    const [addOpenBankingAccount, setAddOpenBankingAccount] = useState(false)

    const { isPaidUser,
    } = useMembership({ uid: userDocument.uid, billingAccountId: billingAccount?.id })


    /* Getting the total balance of all the accounts that have MXN as currency. */
    const TotalBalanceMXN = accountsData?.map((account) => {
        if (account?.currency === 'MXN') {
            return account?.balance
        }
        else {
            return 0
        }
    }).reduce((a, b) => a + b, 0)


    /* Sorting the accounts by name. */
    accountsData?.sort((a, b) => {
        return a?.bank?.name > b?.bank?.name ? 1 : -1
    })


    /* Rendering the Open Banking page. with a conditional op paid user */

    return (!isPaidUser ?
        <Layout.Content style={{ paddingBottom: '0px', height: '70vh' }}>

            <div style={{ height: '90%', width: '100%', backgroundImage: `url(${defaultOpb})`, backgroundSize: '70%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', alignItems: 'center', justifyContent: 'center' }} className="d-flex flex-column">
                <BlockedModal title="Accede a los detalles de tus cuentas de banco" description="Obtén todos los beneficios exclusivos de gigstack.pro mejorando tu plan." />
            </div>

        </Layout.Content>
        :

        <Row justify='center' >
            {
                addOpenBankingAccount && <Suspense fallback={<LoadingOutlined />}>
                    <OpenBankingModal userDocument={userDocument} open={addOpenBankingAccount} close={() => setAddOpenBankingAccount(false)} />
                </Suspense>
            }

            <Col xs={24}>
                <DefaultHelpPageHeader userDocument={userDocument} title="Bancos" description={"Accede a tus transacciones de forma segura y ágil para tener tus finanzas al día."} />
            </Col>
            <Col hidden xs={24} style={{ marginTop: '25px' }}>
                <ActionablePageHeader title="Bancos" actions={
                    <Space>
                        <Button type='primary' onClick={() => setAddOpenBankingAccount(true)}>Nueva cuenta</Button>
                    </Space>}
                />
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <Row gutter={{ xs: 12, md: 15, lg: 20 }}>
                    <Col xs={24} style={{ marginBottom: '40px', marginTop: '25px' }}>
                        <Row justify='start' gutter={{ xs: 12, md: 15, lg: 20 }}>
                            <Col xs={12} lg={6}>
                                <div className="d-flex flex-column">
                                    <Typography.Title level={5}>Cuentas</Typography.Title>
                                    <div className='tableContainer d-flex flex-column' style={{ alignItems: 'center', justifyContent: 'center', minHeight: '125px' }}>
                                        {accountsDBStatus === 'loading' ? <LoginOutlined /> : <Typography.Title level={4}>{accountsData.length} cuentas</Typography.Title>}
                                        <Typography.Text type="secondary">Cuentas conectadas</Typography.Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className="d-flex flex-column">
                                    <Typography.Title level={5}>Balance total MXN</Typography.Title>
                                    <div className='tableContainer d-flex flex-column' style={{ alignItems: 'center', justifyContent: 'center', minHeight: '125px' }}>
                                        {accountsDBStatus === 'loading' ? <LoginOutlined /> : <Typography.Title level={4}>{returnCurrencyValue(TotalBalanceMXN)} MXN</Typography.Title>}
                                        <Typography.Text type="secondary">Balance en cuenta</Typography.Text>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={6}>
                                <div className="d-flex flex-column">
                                    <Typography.Title level={5}>Nueva cuenta</Typography.Title>
                                    <div className='tableContainer d-flex flex-column clickable hoverPrimary' style={{ alignItems: 'center', justifyContent: 'center', minHeight: '125px' }} onClick={() => setAddOpenBankingAccount(true)}>
                                        <PlusOutlined style={{ fontSize: '20px' }} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Tabs
                    defaultActiveKey={1}
                    size="small"
                    type="card"
                    style={{
                        marginBottom: 32,
                    }}>

                    {accountsData?.map((account, index) => {
                        const transactionsQuery = query(collection(getFirestore(), `OBTransactions`), where('team', '==', team?.id), where('account', '==', account?.id), orderBy('date', 'desc'));

                        return <Tabs.TabPane tab={account?.bank?.name} key={index}>
                            <Row gutter={{ xs: 12, md: 15, lg: 20 }}>
                                <Col xs={24} style={{ marginBottom: '20px', marginTop: '10px' }}>
                                    <Row justify="space-between" align="middle">
                                        <Typography.Title level={5} style={{ margin: 0, padding: 0 }}> {account?.type} <span style={{
                                            color: account?.balance > 0 ? '' : '#f5222d',
                                        }}>{returnCurrencyValue(account?.balance)}</span> {account?.currency}</Typography.Title>
                                        <div className="pill">
                                            <Typography.Text>{account.name}</Typography.Text>
                                        </div>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <ErrorBoundary fallback={<>algo</>} >

                                        <Suspense fallback={<LoadingOutlined />}>
                                            <DefaultTableItems
                                                isPendingPayments={false}
                                                // filterSearchKey="duplicated"
                                                // filterSearch={'false'}
                                                removeTransactionsDuplicate={true}
                                                query={transactionsQuery}
                                                elementName='transacciones'
                                                empty={`${account?.bank?.name} no tiene transacciones`}
                                                columns={[
                                                    {
                                                        title: 'Descripción',
                                                        dataIndex: 'description',
                                                        key: 'description',
                                                        render: (text, record) => {
                                                            return <Typography.Text>{text}</Typography.Text>
                                                        }
                                                    },
                                                    {
                                                        title: 'Monto',
                                                        dataIndex: 'amount',
                                                        key: 'amount',
                                                        render: (text, record) => {
                                                            return <Typography.Text>{returnCurrencyValue(text)} {record.isCharge ? <ArrowUpOutlined /> : <ArrowDownOutlined />}</Typography.Text>
                                                        }
                                                    },
                                                    // {
                                                    //     title: 'Duplicada',
                                                    //     dataIndex: 'duplicated',
                                                    //     key: 'duplicated',
                                                    //     render: (text, record) => {
                                                    //         return <Typography.Text>{text ? 'si' : 'no'}</Typography.Text>
                                                    //     }
                                                    // },
                                                    {
                                                        title: 'Fecha',
                                                        dataIndex: 'date',
                                                        key: 'date',
                                                        render: (text, record) => {
                                                            return <Typography.Text>{moment(record?.date).format('DD/MM/YYYY')}</Typography.Text>
                                                        }
                                                    },
                                                ]} />
                                        </Suspense>
                                    </ErrorBoundary>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    })}

                </Tabs>
            </Col>

        </Row >
    );
}

export default BanksPage;