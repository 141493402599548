import { Drawer } from "antd";
import { useContext } from "react";
import ModalContext from "../../context/modalcontext/ModalContext";


export const ClientsModalContext = () => {
    const { newClientModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newClientModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('client')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <AddNewClientForm />
                </div> */}
            </Drawer>
        </>
    )
}

export const InvoiceModalContext = () => {
    const { newInvoiceModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newInvoiceModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('invoice')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <CreateInvoice />
                </div> */}
            </Drawer>
        </>
    )
}

export const ServiceModalContext = () => {
    const { newServiceModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newServiceModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('service')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <AddNewService />
                </div> */}
            </Drawer>
        </>
    )
}

export const ExpenseModalContext = () => {
    const { newExpenseModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newExpenseModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('expense')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <AddNewExpense />
                </div> */}
            </Drawer>
        </>
    )
}

export const StripeAutomationModalContext = () => {
    return (
        <>
            {/* {stripeAutomationsOpen && <StripeAutomationDrawer />} */}
        </>
    )
}

export const PaymentRequestModalContext = () => {
    const { newPaymentRequestModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newPaymentRequestModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('paymentRequest')
            }} header={null} footer={null}>
                <div style={{ maxHeight: '100%', width: '100%' }}>
                    {/* <NewPaymentRequest /> */}
                </div>
            </Drawer>
        </>
    )
}


export const RecurringPaymentModalContext = () => {
    const { newRecurringPaymentModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newRecurringPaymentModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('recurringPayment')
            }} header={null} footer={null}>
                <div style={{ maxHeight: '100%', width: '100%' }}>
                    {/* <NewRecurringPayment /> */}
                </div>
            </Drawer>
        </>
    )
}


export const ReceiptModalContext = () => {
    const { newReceiptModalOpen, handleCloseDrawerModal } = useContext(ModalContext)

    return (
        <>
            <Drawer visible={newReceiptModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('receipt')
            }} header={null} footer={null}>
                <div style={{ maxHeight: '100%', width: '100%' }}>
                    {/* <CreateReceipt /> */}
                </div>
            </Drawer>
        </>
    )
}



function MainDrawer() {

    const {
        newClientModalOpen,
        handleCloseDrawerModal,
        newExpenseModalOpen,
        newServiceModalOpen,
        newPaymentRequestModalOpen,
        newRecurringPaymentModalOpen,
        newInvoiceModalOpen,
        newReceiptModalOpen,
    } = useContext(ModalContext)

    return (
        <>
            {newInvoiceModalOpen && <Drawer visible={newInvoiceModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('invoice')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>'55'
                    <CreateInvoice />
                </div> */}
            </Drawer>}



            {newClientModalOpen && <Drawer visible={newClientModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('client')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <AddNewClientForm />
                </div> */}
            </Drawer>}

            {newExpenseModalOpen && <Drawer visible={newExpenseModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('expense')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <AddNewExpense />
                </div> */}
            </Drawer>}

            {newServiceModalOpen && <Drawer visible={newServiceModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('service')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <AddNewService />
                </div> */}
            </Drawer>}

            {newPaymentRequestModalOpen && <Drawer visible={newPaymentRequestModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('paymentRequest')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <NewPaymentRequest />
                </div> */}
            </Drawer>}

            {newRecurringPaymentModalOpen && <Drawer visible={newRecurringPaymentModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('recurringPayment')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <NewRecurringPayment />
                </div> */}
            </Drawer>}

            {newReceiptModalOpen && <Drawer visible={newReceiptModalOpen} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
                handleCloseDrawerModal('receipt')
            }} header={null} footer={null}>
                {/* <div style={{ maxHeight: '100%', width: '100%' }}>
                    <CreateReceipt />
                </div> */}
            </Drawer>}
        </>
    )
}

export default MainDrawer;