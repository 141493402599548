import { Row } from 'antd';
import React, { useContext } from 'react'
import { getItemsAmounts, UserHasStripe } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import PaymentMethodCard from './PaymentMethodCard';


function AvailPaymentOptions({ state, setstate = (v) => { }, userDocument }) {

    const { team } = useContext(TeamContext);

    return (
        <Row gutter={{ xs: 0, md: 10 }} style={{ marginTop: '15px' }}>
            <PaymentMethodCard
                state={state}
                changeState={(v) => setstate(v)}
                name="Tarjeta de crédito/débito"
                details="Cobra con Stripe vía tarjeta de crédito y débito"
                type={{
                    id: 'card',
                    name: 'Tarjeta de crédito y débito',
                    details: 'Paga con tu tarjeta de crédito o débito',
                    logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/Cards2.svg',
                    manualConfirmation: false,
                    requiredKey: 'paymentIntent'
                }}
                configureAction={() => window.open('https://app.gigstack.pro/settings?subtab=integrations', '_blank')}
                available={UserHasStripe(userDocument, team)}
                checked={state.custom_method_types.findIndex((a) => a.id === 'card') > -1}

            />
            <PaymentMethodCard
                state={state}
                changeState={(v) => setstate(v)}
                name="Pago en OXXO"
                details="Pago en efectivo en tiendas OXXO"
                type={{
                    id: 'oxxo',
                    name: 'OXXO',
                    details: 'Pago en efectivo en tiendas OXXO',
                    logo: 'https://js.stripe.com/v3/fingerprinted/img/oxxo-96b6ab36d23607973cb466bec56d187b.svg',
                    manualConfirmation: false,
                    requiredKey: 'paymentIntent'
                }}
                configureAction={() => window.open('https://app.gigstack.pro/settings?subtab=integrations', '_blank')}
                available={UserHasStripe(userDocument, team) && state?.currency?.toLowerCase() === 'mxn' && getItemsAmounts(state.items).total <= 8000}
                checked={state.custom_method_types.findIndex((a) => a.id === 'oxxo') > -1}
                disabledMessage="Los pagos en OXXO solo son permitidos en MXN y por un máximo de $8,000"
                showConfigure={false}
            />
            <PaymentMethodCard
                state={state}
                changeState={(v) => setstate(v)}
                name={`Transferencia bancaria`}
                details={`${userDocument.bank && `Tu cuenta - ${userDocument.bank?.bank || userDocument.bank?.country} \n${userDocument.bank?.clabe ? `\n CLABE: ${userDocument.bank?.clabe}` : `- ${userDocument.bank?.iban || userDocument.bank?.swift || userDocument.bank?.bic || ''}`}`} (Manual)`}
                type={{
                    id: 'bank',
                    name: 'Transferencia bancaria',
                    details: 'Pago con transferencia desde tu cuenta bancaria',
                    logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png',
                    manualConfirmation: true
                }}
                configureAction={() => window.open('https://app.gigstack.pro/settings?subtab=integrations', '_blank')}
                available={team?.bank}
                checked={state.custom_method_types.findIndex((a) => a.id === 'bank') > -1}
                disabledMessage=""
                showConfigure={true}
            />
            {/* <PaymentMethodCard
                state={state}
                changeState={(v) => setstate(v)}
                name={`SPEI`}
                details={`Recibe transferencia bancaria manejado por conekta. (Automático)`}
                type={{
                    id: 'spei',
                    name: 'SPEI',
                    details: 'Pago vía SPEI desde tu cuenta de banco. (Automático)',
                    logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/SPEI.svg',
                    manualConfirmation: false
                }}
                configureAction={() => window.open('https://app.gigstack.pro/settings?subtab=integrations', '_blank')}
                available={team?.conekta?.private_key && state.currency?.toLowerCase() === 'mxn'}
                checked={state.custom_method_types.findIndex((a) => a.id === 'spei') > -1}
                disabledMessage=""
                showConfigure={true}
            /> */}
            {/* <PaymentMethodCard
                state={state}
                changeState={(v) => setstate(v)}
                name="PayPal"
                details="Recibe el pago vía PayPal"
                type={{
                    id: 'paypal',
                    name: 'PayPal',
                    details: 'Paga por medio de PayPal',
                    logo: 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/PayPal.png',
                    manualConfirmation: false
                }}
                configureAction={() => window.open('https://app.gigstack.pro/settings?subtab=integrations', '_blank')}
                available={team?.paypal}
                checked={state.custom_method_types.findIndex((a) => a.id === 'paypal') > -1}
            /> */}
        </Row>
    );
}

export default AvailPaymentOptions;