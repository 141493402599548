import { CheckCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Table, Tag, Typography } from 'antd';
import { arrayUnion, collection, doc, getFirestore, orderBy, query, where, writeBatch } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { useFirestoreCollectionData } from 'reactfire';
import { getItemsAmounts, InvoiceStatusReadable, returnCurrencyValue } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';


function RelateInvoices({ open, close, element, userDocument, addOn, elementType }) {
    const { team } = useContext(TeamContext)
    const q = query(collection(getFirestore(), "invoices"), where("team", "==", team.id), orderBy('timestamp', 'desc'))
    const { status, data } = useFirestoreCollectionData(q,
        {
            idField: 'id'
        })

    //const [selectedPayments, setselectedPayments] = useState([]);
    const [payments, setPayments] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (status === "success") {

            setPayments(data.map((c) => {
                const as = ((element['invoices'] ? element['invoices'].findIndex((i) => i === c.id) > -1 : false) || (c[addOn]?.findIndex((p) => p === element.fid) > -1))
                return { ...c, fid: c.id, alreadySelected: as, blocked: as }
            }))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])



    /* A row selection object that is used to select rows in a table. */
    const rowSelection = {
        selectedRowKeys: payments.filter((c) => c.alreadySelected).map((c) => c.fid),
        onChange: (selectedRowKeys, selectedRows) => {
            payments.map((c) => {
                if (selectedRowKeys.findIndex((i) => i === c.fid) > -1) {
                    c.alreadySelected = true
                } else {
                    c.alreadySelected = false
                }
                return c
            })
            setPayments([...payments])
        },
        getCheckboxProps: (record) => ({
            disabled: record.blocked,
            checked: record.alreadySelected,
            name: record.name,
        }),
    };

    /**
     * It returns a tag with a color and an icon that represents the status of the invoice
     * @returns A tag with a row inside of it.
     */
    const InvoiceStatusTag = ({ invoice }) => {
        const cancelling = invoice.cancellation_status !== 'none';
        const status = invoice.status;
        var readableStatus = InvoiceStatusReadable(status);
        var statusColor = !invoice.livemode ? '#E6BEFF' : readableStatus === 'Válida' ? '#CCF6DB' : (readableStatus === 'Inválida') ? '#E6BEFF' : (readableStatus === 'Cancelada' || cancelling) ? '#FEE9E9' : '#CCF6DB';
        var textColor = !invoice.livemode ? '#876ee4' : readableStatus === 'Válida' ? '#019049' : (readableStatus === 'Inválida') ? '#F15B5B' : (readableStatus === 'Cancelada' || cancelling) ? '#F15B5B' : '#000000';
        var icon = !invoice.livemode ? <InfoCircleOutlined style={{ color: textColor }} /> : readableStatus === 'Válida' ? <CheckCircleOutlined style={{ color: textColor }} /> : readableStatus === 'Inválida' ? <WarningOutlined style={{ color: textColor }} /> : <WarningOutlined style={{ color: textColor }} />;
        return <Tag style={{ backgroundColor: statusColor, border: 'none' }}>
            <Row align="middle">
                {icon}
                <Typography.Text style={{ marginLeft: '5px', color: textColor }}>{invoice.livemode ? readableStatus : 'Sin timbrar'}</Typography.Text>
            </Row>
        </Tag>

    }


    return (
        <Modal visible={open} onCancel={() => close()} footer={null} title={null} width={
            window.innerWidth > 600 ? '50%' : '90%'
        }>
            <div className="d-flex flex-column">
                <Row>
                    <div className="d-flex flex-column">
                        <Typography.Title style={{ margin: 0 }} level={4}>Asociar una factura</Typography.Title>
                        {payments.filter((p) => p.alreadySelected && !p.blocked).length > 0 && <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{payments.filter((p) => p.alreadySelected && !p.blocked).length} facturas seleccionadas <span onClick={() => {
                            payments.map((c) => {
                                if (!c.blocked) {
                                    c.alreadySelected = false
                                }
                                return c
                            })
                            setPayments([...payments])
                        }} className="clickable danger">{payments.filter((p) => p.alreadySelected).length > 0 ? 'restablecer' : ''}</span></Typography.Text>}
                    </div>
                </Row>
                <Table
                    className="pro-table"
                    pagination={{ hideOnSinglePage: true }}
                    style={{ marginTop: '25px' }}
                    loading={status === "loading"}
                    locale="es"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    columns={[
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Cliente</Typography.Text>,
                            dataIndex: 'client',
                            render: (text, record) => {
                                return <Typography.Text style={{
                                    fontSize: '11px', marginBottom: '0px',
                                    color: record.client?.name === element.client?.name ? '#1890ff' : 'inherit'
                                }}>{record.client?.name ?? record.legalName ?? 'PÚBLICO EN GENERAL'}</Typography.Text>
                            },
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Estado</Typography.Text>,
                            dataIndex: 'status',
                            render: (text, record) => {

                                return <InvoiceStatusTag invoice={record} />
                            }
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Fecha</Typography.Text>,
                            dataIndex: 'timestamp',
                            render: (text, record) => {
                                const betweenDates = moment(record.timestamp).diff(moment(element.timestamp), 'days')

                                return <Typography.Text className={(betweenDates > -31 && betweenDates < 31) ? 'primary' : ''} style={{ fontSize: '11px', marginBottom: '0px' }}>{moment(text).format('dddd D MMM YYYY')}</Typography.Text>
                            },
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Total</Typography.Text>,
                            dataIndex: 'total',
                            render: (text, record) => {
                                const closeAmounts = (getItemsAmounts(record.items).total * 100) === getItemsAmounts(element.items).total || ((getItemsAmounts(record.items).total * 100) / 2) === getItemsAmounts(element.items).total
                                return <Typography.Text className={closeAmounts ? 'primary' : ''} style={{ fontSize: '11px', marginBottom: '0px' }} >{returnCurrencyValue(record.total)}</Typography.Text>
                            },
                        },]}
                    rowKey={(record) => record.fid}
                    dataSource={payments}
                />
                <Row justify='end'>
                    <Button onClick={async () => {

                        try {
                            const batch = writeBatch(getFirestore())
                            const ids = payments.filter((p) => p.alreadySelected).map((p) => p.fid)

                            batch.update(doc(getFirestore(), addOn, element.id), { invoices: arrayUnion(...ids) })
                            payments.filter((p) => p.alreadySelected)?.map((pay) => {
                                return batch.update(doc(getFirestore(), "invoices", pay.fid), { [addOn]: arrayUnion(element.id) })
                            })
                            await batch.commit()
                            message.success('Se han asociado los pagos.')
                            setLoading(false)
                        } catch (error) {
                            message.error(error.message)
                            setLoading(false)
                        }
                        close(null)
                    }} loading={loading} type="primary">
                        Asociar
                    </Button>
                </Row>
            </div>
        </Modal>
    );
}

export default RelateInvoices;