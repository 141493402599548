import { Button, Divider, Form, Input, Layout, message, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import googl from "../../assets/images/googl.svg";
import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useAnalytics, useAuth } from "reactfire";
import { ReadableFirebaseErrorMessage, SearchParamInURL } from "../../app/functions/helpers";
import { logEvent } from "firebase/analytics";
import logo from "../../assets/images/logogigstack.png";
import { webAppVersion } from "../../VersionVariables";
import ForgotPasswordModal from '../modals/ForgotPasswordModal'
import taxoLogo from '../../assets/integrationsLogos/taxoLogo.png'



const integrationsLogos = (integration) => {
    switch (integration) {
        case 'taxo':
            return taxoLogo
        default:
            return taxoLogo
    }
}

function LoginWidget({ integration = null, password = null, email = null, allowLoginProviders = true, allowRegister = true }) {
    const auth = useAuth()
    const analytics = useAnalytics();
    const [loading, setLoading] = useState(null)
    let redirect = SearchParamInURL("redirect")
    const [recoverPassword, setRecoverPassword] = useState(null)
    const [form] = Form.useForm()


    const SocialLoginIcon = ({ img, action = () => { }, provider = "", title, top = "25px" }) => {
        return <div style={{
            justifyItems: 'center',
            alignContent: 'center',
            marginTop: '20px'
        }} className="d-flex flex-row">
            {(loading === provider && loading) ? <LoadingOutlined style={{ fontSize: "20px" }} /> : <img onClick={action} className="clickable" alt={provider} src={img} style={{ width: "35px", height: "35px", marginRight: "10px" }} />}
        </div>

        // <Row onClick={action} justify=" center" style={{ width: "100%", marginTop: top }}>
        //     <div className="d-flex flex-row clickable hoverPrimary" style={{ border: "1px solid #F6F7FD", padding: "7px 5px", borderRadius: "10px", width: window.innerWidth < 801 ? "90%" : "80%", alignItems: "center", justifyContent: "start", paddingLeft: "23%" }} >
        //         {(loading === provider && loading) ? <LoadingOutlined style={{ fontSize: "20px" }} /> : <img alt={provider} src={img} style={{ width: "25px", height: "25px", marginRight: "10px" }} />}
        //         <Typography.Text type="secondary" >{title}</Typography.Text>
        //     </div>
        // </Row >
    }

    const MakeGoogleSignIn = async () => {
        setLoading("google")

        setTimeout(() => {
            const provider = new GoogleAuthProvider();

            provider.addScope("profile");
            provider.addScope("email");
            // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
            logEvent(analytics, "auth", { type: "google" });
            signInWithPopup(auth, provider)
                .then(async (result) => {

                    const user = result.user;

                    if (redirect) {
                        window.location.replace(redirect)
                    }
                    return user
                }).catch((error) => {
                    setLoading(false)
                    return message.error(ReadableFirebaseErrorMessage(error))

                });
        }, 300)
    }

    const searchRedirect = () => {
        const token = SearchParamInURL("token")
        if (token) {
            redirect = `${window.location.origin}/invites?token=${token}`
        }
    }

    const decodeBase64 = (token) => {
        const decodedToken = atob(token)
        const email = decodedToken.split("email=")[1].split("&password=")[0]
        const password = decodedToken.split("password=")[1].split("email=")[0]
        // console.log(decodedToken)
        form.setFieldsValue({
            email,
            password
        })
        form.submit()
    }

    useEffect(() => {
        searchRedirect()
        const accessToken = SearchParamInURL("accessToken")
        if (accessToken) {
            decodeBase64(accessToken)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])


    // const MaketFBSignIn = async () => {
    //     setLoading("Facebook")
    //     const provider = new FacebookAuthProvider();

    //     provider.setCustomParameters({
    //         "allow_signup": "false"
    //     });
    //     setTimeout(() => {
    //         logEvent(analytics, 'auth', { type: 'facebook' });
    //         signInWithPopup(auth, provider)
    //             .then(async (result) => {
    //                 const user = result.user;


    //                 if (redirect) {
    //                     window.location.replace(redirect)
    //                 }
    //                 return user
    //             }).catch((error) => {
    //                 setLoading(false)
    //                 message.error(ReadableFirebaseErrorMessage(error))
    //                 return;
    //             });
    //     }, 300)

    // }
    // const MaketTWSignIn = async () => {
    //     setLoading('twitter')
    //     const provider = new TwitterAuthProvider();

    //     provider.setCustomParameters({
    //         'allow_signup': 'false'
    //     });
    //     logEvent(analytics, "auth", { type: "twitter" });
    //     signInWithPopup(auth, provider)
    //         .then(async (result) => {
    //             const user = result.user;


    //             if (redirect) {
    //                 window.location.replace(redirect)
    //             }
    //             return user
    //         }).catch((error) => {
    //             setLoading(false)
    //             message.error(ReadableFirebaseErrorMessage(error))
    //             return

    //         });


    // }

    return <Layout>
        <Layout.Content style={{ backgroundColor: "#F6F7FD", height: "100vh", width: "100hw", alignItems: "center", padding: "5%" }} className="d-flex flex-column">
            <div style={{
                width: window.innerWidth < 800 ? "90%" : "40%", backgroundColor: "white", padding: "35px", borderRadius: "10px",
                boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.05)",
                justifyContent: "center",
                alignItems: "center",

            }} className="d-flex flex-column">
                {recoverPassword && <ForgotPasswordModal open={recoverPassword} close={() => setRecoverPassword(null)} email={form.getFieldValue('email') ?? ''} />}
                <img src={integration ? integrationsLogos(integration) : logo} height="30px" alt="gigstack" />
                <Typography.Title level={4} style={{ margin: 0, paddingTop: "15px", }}>Inicia sesión</Typography.Title>
                <Typography.Text type="secondary">Accede a tu cuenta con tus credenciales</Typography.Text>
                {allowLoginProviders && < Space >
                    <SocialLoginIcon title="Inicia con Google" img={googl} action={MakeGoogleSignIn} provider="google" />
                    {/* <SocialLoginIcon title="Inicia con Twitter" img={twl} action={MaketFBSignIn} provider="twitter" top="10px" /> */}
                    {/* <SocialLoginIcon title="Inicia con Facebook" img={fbl} action={MaketTWSignIn} provider="Facebook" top="10px" /> */}
                </Space>}
                <Divider />

                <Form
                    form={form}
                    initialValues={{
                        email,
                        password
                    }}
                    onFinish={async (v) => {
                        if (!v.email || !v.password) {
                            return;
                        }
                        try {
                            setLoading("email")
                            await signInWithEmailAndPassword(auth, v.email, v.password)
                            if (redirect) {
                                return window.location.replace(redirect)
                            } else {
                                // return window.location.replace("/")
                            }
                        } catch (error) {
                            setLoading(null)
                            searchRedirect()
                            message.error(ReadableFirebaseErrorMessage(error))
                        }
                    }}
                    layout="vertical" style={{
                        width: window.innerWidth < 801 ? "90%" : "80%"
                    }}>
                    <Form.Item name="email" label="Correo electrónico" rules={[{
                        required: true,
                        type: "email",
                        message: "Ingresa con un correo válido",

                    }]}>
                        <Input className="showInputBorder" type="email" prefix={
                            <UserOutlined style={{ color: "#E7E7E7" }} />
                        } style={{ padding: '4px 10px' }} />
                    </Form.Item>
                    <Form.Item style={{ margin: 0 }} name="password" label="Contraseña" rules={[{
                        required: true,
                        message: "Ingresa tu contraseña",

                    }]}>
                        <Input.Password className="showInputBorder" type="email" prefix={
                            <LockOutlined style={{ color: "#E7E7E7" }} />
                        } style={{ padding: '4px 10px' }} />
                    </Form.Item>
                    <Row justify="end" style={{ marginTop: "5px" }}>
                        <Typography.Text className="clickable hoverPrimary" type="secondary" style={{ fontSize: "10px" }}
                            onClick={() => setRecoverPassword(true)}
                        >¿Olvidaste tu contraseña?</Typography.Text>
                    </Row >

                    <Row justify=" center">
                        <Button type="primary"
                            htmlType="submit"
                            style={{
                                marginTop: "25px",
                                width: "100%"
                            }}
                            loading={loading === "email"}
                            disabled={loading && loading !== "email"}
                        >Iniciar sesión</Button>
                    </Row>
                </Form >

                {allowRegister && (
                    <Row justify="center" style={{ width: "100%", marginTop: "25px" }}>
                        <Typography.Text type="secondary" style={{ textAlign: 'center', flex: 1 }}>
                            ¿Aún no tienes una cuenta?{' '}
                            <span
                                className="primary strong clickable"
                                onClick={() => {
                                    const token = SearchParamInURL("token")
                                    searchRedirect()
                                    window.location.href = `/register${redirect && token ? `?redirect=${redirect}` : redirect ? `?redirect=${window.location.href.split('redirect=')[1]}` : ""}`;
                                }}
                            >
                                Regístrate
                            </span>
                        </Typography.Text>
                    </Row>
                )}

            </div >
            <Typography.Text type="secondary" style={{ fontSize: "10px", marginTop: "5px" }}>v.{webAppVersion}</Typography.Text>
        </Layout.Content >
    </Layout >


}





// function LoginWidget({ changeRender }) {
//     const analytics = useAnalytics();

//     const [loading, setloading] = useState(null)

//     const auth = useAuth()

//     const [recoverPassword, setRecoverPassword] = useState(null)


//     let redirect = SearchParamInURL("redirect")

//     const [form] = Form.useForm();


//     const searchRedirect = () => {
//         const token = SearchParamInURL("token")
//         if (token) {
//             redirect = `http://localhost:3000/invites?token=${token}`
//         }
//     }

//     useEffect(() => {
//         searchRedirect()
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])


//     /**
//      * `MakeGoogleSignIn` is a function that uses the `GoogleAuthProvider` class to create a new instance
//      * of the Google authentication provider. It then adds the `profile` and `email` scopes to the
//      * provider, and then uses the `signInWithPopup` method to sign in the user with the Google provider
//      */
//     const MakeGoogleSignIn = async () => {
//         setloading("google")

//         setTimeout(() => {
//             const provider = new GoogleAuthProvider();

//             provider.addScope("profile");
//             provider.addScope("email");
//             // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
//             logEvent(analytics, "auth", { type: "google" });
//             signInWithPopup(auth, provider)
//                 .then(async (result) => {

//                     const user = result.user;
//                     if (redirect) {
//                         window.location.replace(redirect)
//                     }
//                     return user
//                 }).catch((error) => {
//                     return message.error(ReadableFirebaseErrorMessage(error))

//                 });
//         }, 300)
//     }
//     /**
//      * A function that allows the user to sign in with their github account.
//      */
//     const MaketGitSignIn = async () => {
//         setloading("github")
//         const provider = new GithubAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         setTimeout(() => {
//             logEvent(analytics, "auth", { type: "github" });
//             signInWithPopup(auth, provider)
//                 .then(async (result) => {

//                     const user = result.user;
//                     if (redirect) {
//                         window.location.replace(redirect)
//                     }
//                     return user
//                 }).catch((error) => {
//                     return message.error(ReadableFirebaseErrorMessage(error))

//                 });
//         }, 300)

//     }
//     /**
//      * A function that is called when the user clicks on the Facebook button. It sets the loading state to
//      * Facebook, creates a new FacebookAuthProvider, sets the custom parameters, and then calls the
//      * signInWithPopup function.
//      */
//     const MaketFBSignIn = async () => {
//         setloading("Facebook")
//         const provider = new FacebookAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         setTimeout(() => {
//             logEvent(analytics, "auth", { type: "Facebook" });
//             signInWithPopup(auth, provider)
//                 .then(async (result) => {
//                     const user = result.user;


//                     if (redirect) {
//                         window.location.replace(redirect)
//                     }
//                     return user
//                 }).catch((error) => {
//                     message.error(ReadableFirebaseErrorMessage(error))
//                     return;
//                 });
//         }, 300)

//     }
//     const MaketTWSignIn = async () => {
//         setloading("twitter")
//         const provider = new TwitterAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         logEvent(analytics, "auth", { type: "twitter" });
//         signInWithPopup(auth, provider)
//             .then(async (result) => {
//                 const user = result.user;


//                 if (redirect) {
//                     window.location.replace(redirect)
//                 }
//                 return user
//             }).catch((error) => {
//                 message.error(ReadableFirebaseErrorMessage(error))
//                 return

//             });


//     }
//     /**
//      * `MaketTWSignIn` is a function that uses the `TwitterAuthProvider` class to sign in a user with a
//      * popup window
//      */
//     const MaketTWSignIn = async () => {
//         setloading("twitter")
//         const provider = new TwitterAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         logEvent(analytics, "auth", { type: "twitter" });
//         signInWithPopup(auth, provider)
//             .then(async (result) => {
//                 const user = result.user;


//                 if (redirect) {
//                     window.location.replace(redirect)
//                 }
//                 return user
//             }).catch((error) => {
//                 message.error(ReadableFirebaseErrorMessage(error))
//                 return

//             });


//     }







//     /**
//      * It returns a row with a div that contains an image and a text
//      * @returns A React component that takes in an image, an action, a provider, and a title.
//      */

//     return (
//         <Row>
//             {(recoverPassword !== null) && <ForgotPasswordModal open={recoverPassword} close={() => setRecoverPassword(null)} email={recoverPassword} />}
//             <Col XS={24}>
//                 <div className="d-flex flex-column">
//                     <Typography.Title level={4} style={{ margin: 0, padding: 0, marginBottom: "5px" }}>Iniciar Sesión</Typography.Title>
//                     <Typography.Text type="secondary">Ingresa tus credenciales para entrar a tu cuenta.</Typography.Text>
//                 </div>
//             </Col>
//             <Col XS={24} style={{ marginTop: "15px" }}>
//                 <div className="d-flex flex-column">
//                     <SocialLoginIcon title="Inicia con Google" img={googl} action={() => MakeGoogleSignIn()} provider="google" />
//                     <SocialLoginIcon title="Inicia con Twitter" img={twl} provider="twitter" action={() => MaketTWSignIn()} />
//                     <SocialLoginIcon title="Inicia con Github" img={gitl} provider="github" action={() => MaketGitSignIn()} />
//                     <SocialLoginIcon title="Inicia con Facebook" img={fbl} provider="Facebook" action={() => MaketFBSignIn()} />
//                 </div>

//             </Col>
//             <Col XS={24}>
//                 <Divider />
//             </Col>

//             <Col XS={24}>
//                 <Form form={form} layout="vertical" onFinish={async (v) => {
//                     if (!v.email || !v.password) {
//                         return;
//                     }
//                     try {
//                         setloading("email")
//                         await signInWithEmailAndPassword(auth, v.email, v.password)
//                         if (redirect) {
//                             return window.location.replace(redirect)
//                         } else {
//                             return window.location.replace("/")
//                         }
//                     } catch (error) {
//                         setloading(null)

//                         message.error(ReadableFirebaseErrorMessage(error))
//                     }
//                 }}>
//                     <Form.Item name="email" label="Correo Electrónico" style={{ marginBottom: "10px", paddingBottom: "0" }}>
//                         <Input placeholder="Ingresa tu correo electrónico" />
//                     </Form.Item>
//                     <Form.Item name="password" label="Contraseña" style={{ marginBottom: "10px", paddingBottom: "0" }}>
//                         <Input.Password placeholder="Contraseña de tu cuenta" />
//                     </Form.Item>
//                     <Row justify="end">
//                         <Typography.Text className="clickable hoverPrimary" style={{ fontSize: "12px" }} onClick={() => {
//                             setRecoverPassword(form.getFieldValue("email") ?? "")
//                         }}>Olvide mi contraseña</Typography.Text>
//                     </Row>
//                     <Row style={{ marginTop: "10px" }}>
//                         <Button htmlType="submit" loading={loading === "email"} type="primary" style={{ width: "100%" }} >Iniciar Sesión</Button>
//                     </Row>
//                     <Row justify="start" style={{ marginTop: "10px" }}>
//                         <Typography.Text className="" style={{ fontSize: "13px" }}>¿Aun no tienes cuenta? <span onClick={() => {
//                             //REPLACE URL WITH /signup
//                             window.history.replaceState(null, ``, `/signup${redirect ? `?redirect=${redirect}` : ""}`)
//                             changeRender()
//                         }} className="strong primary clickable">Crear una cuenta gratis</span></Typography.Text>
//                     </Row>
//                 </Form>
//             </Col>


//         </Row>
//     );
// }
// */
export default LoginWidget;