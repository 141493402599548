import { ClockCircleOutlined, KeyOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Popconfirm, Row, Typography, message } from 'antd';
import { getAuth, unlink } from 'firebase/auth';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useAuth } from 'reactfire';


function SecuritySettings({ userDocument }) {
    const auth = useAuth()
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            ...userDocument,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    const SecurityInfo = () => {
        return <Row style={{ width: '100%' }}>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Proveedores de inicio de sesión</Typography.Text>

            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    {auth.currentUser.providerData.map((l, i) => {

                        return <Col xs={24} key={l.providerId} style={{ marginBottom: '20px' }}>
                            <Row align="middle">
                                <Popconfirm title="¿Deseas quitar este provedor de inicio de sesión?" okText="Si, eliminar proveedor" cancelText="Cancelar" onConfirm={() => {
                                    const auth = getAuth();
                                    unlink(auth.currentUser, l.providerId).then(() => {
                                        message.success('Proveedor eliminado correctamente')
                                    }).catch((error) => {
                                        message.error(error?.message ?? 'Ocurrió un error al eliminar el proveedor')
                                    });
                                }}>
                                    <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                        <KeyOutlined />
                                    </div>
                                </Popconfirm>
                                <div className='d-flex flex-column'>
                                    <Typography.Text style={{ fontSize: '14px' }}>{l.email ?? ''}</Typography.Text>
                                    <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>{l.providerId}</Typography.Text>
                                </div>
                            </Row>

                        </Col>
                    })}
                    <Col hidden xs={24} style={{ marginBottom: '20px' }}>
                        <Row align="middle">
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <PlusOutlined />
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>Asociar nuevo</Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'></Typography.Text>
                            </div>
                        </Row>

                    </Col>
                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}></Col>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Último inicio de sesión</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    <Col xs={24} >
                        <Row align="middle">
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <ClockCircleOutlined />
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>{moment(auth.currentUser.metadata?.lastSignInTime).format('dddd D MMMM YYYY H:mm') ?? ''}</Typography.Text>
                            </div>
                        </Row>
                    </Col>


                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}></Col>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Necesitas ayuda</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    <Col xs={24} >
                        <Row align="middle">
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <MailOutlined />
                            </div>
                            <div className='d-flex flex-column'>
                                <Button
                                    onClick={() => {
                                        window.open(`mailto:support@gigstack.io?subject=Ayuda con mi cuenta&body=\n\n\n\n\n\nPor favor no elimines esta sección > \n UID:${auth.currentUser.uid}\n vía:alpha`)
                                    }}
                                    size="small">Enviar un ticket a soporte</Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    return (
        <Col xs={24}>
            <Form form={form} initialValues={userDocument} onFinish={async (v) => {

            }} layout='vertical' style={{ width: '100%' }}>
                <div className="settingsContainer">
                    <Typography.Title style={{ marginBottom: '2px' }} level={5}>Seguridad de la cuenta</Typography.Title>
                    <Typography.Text type="secondary">Siempre mantén tu cuenta segura.</Typography.Text>
                    <Divider />
                    <SecurityInfo />


                </div>
            </Form>


        </Col>
    );
}

export default SecuritySettings;