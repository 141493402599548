import {
    GET_BILLINGACCOUNT_DATA,
} from '../../types';

function BillingAccountReducer (state, action) {
    switch (action.type) {
        case GET_BILLINGACCOUNT_DATA:
            return { 
                ...state,
                billingAccount: action.payload
            };
        default:
            return state;
    }
}

export default BillingAccountReducer;


