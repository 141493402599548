
import './App.css';
import { AnalyticsProvider, AuthProvider, FirestoreProvider, RemoteConfigProvider, StorageProvider, useFirebaseApp, AppCheckProvider, useInitRemoteConfig } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import MainRoutes from './app/routes/MainRoutes';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import moment from 'moment';
import 'moment/locale/es-mx';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
// import UserState from './context/usercontext/UserState';
// import BillingAccountState from './context/billingaccountcontext/BillingAccountState';
// import TeamState from './context/teamcontext/TeamState';


const APP_CHECK_TOKEN = '6LcN4cscAAAAAIRntjbXxgZyqzXEO5x2xBKDBezv';


function App() {
  moment.locale('es-MX')
  const app = useFirebaseApp();
  const firstore = getFirestore(app);
  //const remoteConf = getRemoteConfig(app)
  const storage = getStorage(app)
  const auth = getAuth(app);
  const analyticssdk = getAnalytics(app)

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: false,
  });
  const { data: remoteConfigInstance } = useInitRemoteConfig(async (firebaseApp) => {
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 10000,
      fetchTimeoutMillis: 10000,
    };
    try {
      await fetchAndActivate(remoteConfig);
      return remoteConfig;
    } catch (error) {
    }

  });







  return (
    <>
      <ErrorBoundary fallback={<></>}>
        <AppCheckProvider sdk={appCheck}>
          <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firstore}>
              <StorageProvider sdk={storage}>
                <RemoteConfigProvider sdk={remoteConfigInstance}>
                  <AnalyticsProvider sdk={analyticssdk}>
                    <div className="App">
                      <MainRoutes />
                    </div>
                  </AnalyticsProvider>
                </RemoteConfigProvider>
              </StorageProvider>
            </FirestoreProvider>
          </AuthProvider>
        </AppCheckProvider>
      </ErrorBoundary>
    </>

  );
}

export default App;


