
import { DeleteOutlined, DownloadOutlined, EyeOutlined, LoadingOutlined, MailOutlined, QrcodeOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Dropdown, Menu, message, Popconfirm, Row, Space, Statistic, Tooltip, Typography } from 'antd';
import { deleteDoc, doc } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useState } from 'react'
import { useAuth, useFirestore } from 'reactfire';
import { DownloadInvoice, DuplicateInvoice, getItemsAmounts, InvoiceStatusReadable, returnCurrencyValue } from '../../app/functions/helpers';
import ComposeEmailModal from '../email/ComposeEmailModal';
import { ConfirmSignInvoiceModal } from './ConfirmSignInvoiceModal';
import CancelInvoiceModal from './CancelInvoiceModal';

function InvoicesRelatedTo({
    relatedTo,
    invoices,
    userDocument,
    showCards = true
}) {
    const fs = useFirestore()
    const auth = useAuth();
    const [signInvoiceModal, setSignIvoiceModal] = useState(false)
    const [invoiceToSign, setInvoiceToSign] = useState([])
    const [composeEmail, setcomposeEmail] = useState(false)

    const [downloading, setDownloading] = useState(null);
    /* Getting the total of all the invoices. */
    const invoicesTotals = invoices?.filter((a) => a.status === 'valid').map((p) => {
        if (p.currency?.toLowerCase() === 'mxn') {
            return Number(p.total);
        } else {
            const amount = p.total;
            const rate = p.exchangeRate < 1 ? p.exchangeRate : (1 / p.exchangeRate);
            const currenciesFromInvoice = invoices?.map((p) => {
                return p.currency
            })
            const currenciesSet = new Set(currenciesFromInvoice)
            const currenciesArrayToCompare = [...currenciesSet]

            if (currenciesArrayToCompare.length > 1) {
                return amount / rate;
            } else {

                return amount
            }

        }

    })


    const invoiceTotalAcc = invoicesTotals.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const totalInMXN = invoicesTotals.reduce((accumulator, currentValue) => accumulator + currentValue, 0)




    const currenciesFromInvoice = invoices?.map((p) => {
        return p.currency
    })
    const currenciesSet = new Set(currenciesFromInvoice)
    const currenciesArrayToCompare = [...currenciesSet]

    const currency = currenciesArrayToCompare.length > 1 ? '' : currenciesArrayToCompare[0]

    const elementTotal = getItemsAmounts(relatedTo?.internalItems ?? relatedTo?.items, relatedTo?.paidIn === 'bank')[relatedTo?.paidIn === 'bank' ? 'total' : 'total']
    const total = elementTotal



    const [cancelInvoice, setCancelInvoice] = useState(false)

    const isGlobal = invoices.find(i => i.global) ? true : false

    return (
        <Col xs={24}>
            {cancelInvoice &&

                <CancelInvoiceModal
                    open={cancelInvoice}
                    close={() => setCancelInvoice(null)}
                    invoice={cancelInvoice}
                    cancelInvoice={(inv) => {
                    }}
                />
            }
            <div style={{ width: '100%', height: '1px', backgroundColor: '#fafbfd', marginBottom: '5px' }}></div>
            <Row>
                {showCards && <Col xs={24}>
                    <Row justify="end">
                        <Card bordered={false} size="small">
                            <Statistic
                                title="Total del pago"
                                value={total.toFixed(2) + ' ' + relatedTo?.currency.toUpperCase()}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix="$"
                                suffix=""
                            />
                        </Card>
                        <Divider type="vertical" />
                        {<><Card bordered={false} size="small">
                            <Statistic
                                title={<Row align="middle" justify="space-between">
                                    <Typography.Text type="secondary">Total facturado</Typography.Text>
                                    {isGlobal && <Tooltip title="Factura global">
                                        <WarningOutlined style={{ marginLeft: '5px' }} />
                                    </Tooltip>}
                                </Row>}
                                value={currenciesArrayToCompare.length > 1 ? totalInMXN.toFixed(2) + ' MXN' : invoiceTotalAcc.toFixed(2) + ' ' + currency}
                                precision={2}
                                valueStyle={{ color: '#cecece' }}
                                prefix="$"
                                suffimoex=""
                            />
                        </Card>
                            {currenciesArrayToCompare.length > 1 ? <></> : <>{(invoiceTotalAcc - total !== 0) && <Divider type="vertical" />}
                                {(invoiceTotalAcc - total !== 0) && <Card bordered={false} size="small">
                                    <Statistic
                                        title="Diferencia"
                                        value={Math.abs(invoiceTotalAcc - total).toFixed(2) + ' ' + currency}
                                        precision={2}
                                        valueStyle={{ color: 'red' }}
                                        prefix="$"
                                        suffimoex=""
                                    />
                                </Card>}</>}
                        </>}

                    </Row>
                </Col>}
                {invoices?.map((p) => {
                    let items = [
                        {
                            label: <Typography.Text className="itemHoverPrimary">Descargar PDF</Typography.Text>,
                            onClick: () => {
                                DownloadInvoice({ invoice: p, type: 'pdf', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                            },
                            key: 'PDF',
                        },
                        {
                            label: <Typography.Text className="itemHoverPrimary">Descargar XML</Typography.Text>,
                            onClick: () => {
                                DownloadInvoice({ invoice: p, type: 'xml', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                            },
                            key: 'XML',
                        },
                        {
                            label: <Typography.Text className="itemHoverPrimary">Descargar zip</Typography.Text>,
                            onClick: () => {
                                DownloadInvoice({ invoice: p, type: 'zip', setdownloading: (v) => setDownloading(v), authUser: auth.currentUser })
                            },
                            key: 'ZIP',
                        },
                    ]
                    const menu = (inv) => (
                        <Menu items={items} />
                    );
                    const cancelling = p.cancellation_status !== 'none';
                    var readableStatus = InvoiceStatusReadable(p.status);
                    var statusColor = !p.livemode ? '#E6BEFF' : readableStatus === 'Válida' ? '#CCF6DB' : (readableStatus === 'Inválida') ? '#E6BEFF' : (readableStatus === 'Cancelada' || cancelling) ? '#FFB9B9' : '#CCF6DB';
                    return <Col xs={24} key={p.id} style={{
                        marginTop: '15px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px',
                        padding: '10px 10px',
                        backgroundColor: 'white'
                    }} className="hoverBorderPrimary clickable" >
                        <div className="relatedPaymentCard" style={{ position: 'relative' }}>
                            <Row justify="space-between" align="middle" style={{ padding: '10px 15px' }}>
                                <div className="d-flex flex-column">
                                    <Row>
                                        <span style={{ fontSize: '11px', marginLeft: '0px', textDecoration: 'underline', textDecorationColor: statusColor, color: 'rgba(0, 0, 0, 0.45)' }}>{readableStatus}</span>

                                    </Row>
                                    <Typography.Text className='strong'>{p.client?.name ?? p.client?.legal_name ?? 'Sin cliente'} </Typography.Text>
                                    <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{moment(p.timestamp).format('DD/MM/YYYY HH:mm')} - {p.uuid}</Typography.Text>
                                </div>
                                <div className="d-flex flex-column" >


                                    <Space style={{ marginBottom: '15px' }}>
                                        {downloading === p.id ? <LoadingOutlined /> : p.withoutKeys ? <></> :
                                            <Dropdown overlay={() => menu()} placement="bottom">
                                                <DownloadOutlined className="clickable itemHoverPrimary" style={{ color: '#b7b7b7' }} />
                                            </Dropdown>
                                        }
                                        {(p.status === 'valid' && !p.livemode) && <Tooltip title="Timbrar la factura">
                                            <QrcodeOutlined className={`${p.withoutKeys ? 'disabled' : 'secondaryItem itemHoverPrimary'} `} style={{ color: '#b7b7b7', cursor: 'pointer' }} onClick={() => {
                                                setSignIvoiceModal(true)
                                                setInvoiceToSign(p)
                                            }} />
                                        </Tooltip>}
                                        {(p.status === 'valid' && p.livemode && p.cancellation_status === 'none' && !p.withoutKeys) && <Tooltip title="Cancelar factura">
                                            <Tooltip title="Cancelar factura">
                                                <StopOutlined className="secondaryItem itemHoverPrimary" onClick={() => {
                                                    setCancelInvoice(p)
                                                }} style={{ color: '#b7b7b7' }} />

                                            </Tooltip>
                                        </Tooltip>}
                                        {!p.withoutKeys && <MailOutlined disabled={p.withoutKeys} onClick={() => {
                                            setcomposeEmail(p)
                                        }} className="secondaryItem itemHoverPrimary" style={{ color: '#b7b7b7' }} />}
                                        {!p.livemode && <Tooltip>
                                            <Popconfirm title="¿Eliminar factura sin timbrar?" okText="Eliminar" okButtonProps={{ danger: true }} onConfirm={async () => {
                                                try {
                                                    setDownloading(p.id)
                                                    await deleteDoc(doc(fs, `invoices/${p.id}`))

                                                    setDownloading(null)
                                                } catch (error) {
                                                    message.error(error.message)
                                                }
                                            }} ><DeleteOutlined className="itemHoverDanger" style={{ color: '#b7b7b7' }} /></Popconfirm>
                                        </Tooltip>}
                                        <EyeOutlined onClick={() => window.open(`/invoiceDetails?id=${p.id}`, '_blank')} style={{ color: '#b7b7b7' }} className="clickable" />
                                    </Space>
                                    <div className="pill">
                                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{returnCurrencyValue(p.total)} {p.currency.toUpperCase()}</Typography.Text>
                                    </div>
                                    {!p.livemode && <div style={{ height: '13px', backgroundColor: '#f7f7f7', marginTop: '15px' }} className="pill">
                                        <Typography.Text style={{ fontSize: '10px' }} type="secondary">Sin timbrar</Typography.Text>
                                    </div>}
                                </div>
                            </Row>

                        </div>
                    </Col>
                })
                }
            </Row>
            {signInvoiceModal && <Suspense fallback={<LoadingOutlined />}>
                <ConfirmSignInvoiceModal open={signInvoiceModal} close={() => setSignIvoiceModal(false)} data={invoiceToSign} add={(v) => {
                    if (v.withoutKeys) {
                        return
                    }
                    DuplicateInvoice(v)
                }} />
            </Suspense>}
            {composeEmail && <ComposeEmailModal close={() => setcomposeEmail(null)} actionText={
                null
            } idRef={'invoices'} data={{ ...composeEmail, invoice: composeEmail }} userDocument={userDocument} open={composeEmail} element={composeEmail} subject={`Factura ${composeEmail?.series} ${composeEmail?.folio_number}`} readableType={'factura'} client={composeEmail.internalClient ?? { name: 'Sin Cliente', email: '', }} />}
        </Col>
    );
}

export default InvoicesRelatedTo;