import { Button, Col, message, Row, Space, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import ActionablePageHeader from '../../widgets/headers/ActionablePageHeader';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import { generateCode, returnCurrencyValue, SearchParamInURL } from '../functions/helpers';
import moment from 'moment';
import { collection, deleteDoc, doc, getFirestore, orderBy, query, where } from 'firebase/firestore';
import DefaultTableItems from '../../widgets/tables/DefaultTableItems';
import TableTitle from '../../widgets/tables/TableTitle';
import { DeleteOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import ImportThings from '../../widgets/modals/ImportThings';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';

import TeamContext from '../../context/teamcontext/TeamContext';
import CreateElementHolder from '../../widgets/drawers/CreateElementHolder';

function ExpensesPage({ userDocument }) {

    const { team } = useContext(TeamContext)

    const { alias } = team ?? {}
    const expensesRef = query(collection(getFirestore(), `expenses`), where('team', '==', team?.id ?? ''), orderBy('date', 'desc'));
    const [pageActive, setPageActive] = useState(SearchParamInURL('pageActive') ?? 1)
    const [pageSize, setPageSize] = useState(10)
    const [loading, setLoading] = useState(null)
    const [createExpense, setCreateExpense] = useState(null)

    const [addImport, setAddImport] = useState(false);

    //TODO:CREATE MODAL FOR TODO

    return (

        <Row justify='center' >
            {addImport && <ImportThings templateUrl={"https://firebasestorage.googleapis.com/v0/b/gigstackpro.appspot.com/o/public%2FPlantilla%20de%20gastos.xlsx?alt=media&token=a3c20ebf-113d-41be-a631-37717998224d"} userDocument={userDocument} open={addImport} close={() => setAddImport(false)} type="expenses" readableType={"gastos"} />}
            {createExpense && <CreateElementHolder edit={createExpense !== true ? createExpense : null} open={createExpense} close={() => setCreateExpense(null)} type='expense' userDocument={userDocument} />}
            <Col xs={24}>
                <DefaultHelpPageHeader userDocument={userDocument} title="Gastos" description={`Ingresa los gastos de ${alias}`} />
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <ActionablePageHeader title="Gastos de la empresa" actions={
                    <Space>
                        <Button onClick={() => setAddImport(true)} type="ghost">Importar gastos</Button>
                        <Button type='primary' onClick={() => {
                            setCreateExpense(true)
                        }}>Nuevo gasto</Button>
                    </Space>}
                />
            </Col>

            <Col xs={24} style={{ marginTop: '25px' }}>
                <ErrorBoundary>
                    <DefaultTableItems
                        empty='No hay gastos registrados'

                        pageSize={pageSize ?? 10}
                        defaultPage={pageActive}
                        onChange={(e) => {
                            setPageActive(e.current)
                            setPageSize(e.pageSize)

                        }}
                        columns={[
                            {
                                title: <TableTitle title="Concepto" />,
                                dataIndex: 'title',
                                key: generateCode(5),
                                fixed: 'left',
                                width: '20%',
                                sorter: (a, b) => ((a?.title)?.localeCompare(b?.title)),
                                render: (text, record) => {

                                    return <div className="d-flex flex-column">
                                        <Typography.Text type="secondary">{text}</Typography.Text>
                                        <Typography.Text type="secondary" style={{ fontSize: '10px' }} copyable={{ text: record.id }}>{record.id}</Typography.Text>
                                    </div>
                                }
                            },
                            {
                                title: <TableTitle title="Total" />,
                                dataIndex: 'amount',
                                key: generateCode(5),
                                fixed: 'left',

                                sorter: (a, b) => ((a?.amount) > (b?.amount)),
                                render: (text, record) => {

                                    return <Typography.Text type="secondary">{returnCurrencyValue(text / 100)} {record.currency}</Typography.Text>
                                }
                            },
                            {
                                title: <TableTitle title="Tipo de gasto" />,
                                dataIndex: 'expenseType',
                                key: generateCode(5),
                                fixed: 'left',

                                sorter: (a, b) => ((a?.expenseType)?.localeCompare(b?.expenseType)),
                                render: (text, record) => {

                                    return <Typography.Text type="secondary">{text}</Typography.Text>
                                }
                            },
                            {
                                title: <TableTitle title="Tipo de gasto" />,
                                dataIndex: 'expenseType',
                                key: generateCode(5),
                                fixed: 'left',

                                sorter: (a, b) => ((a?.expenseType)?.localeCompare(b?.expenseType)),
                                render: (text, record) => {

                                    return <Typography.Text type="secondary">{text}</Typography.Text>
                                }
                            },
                            {
                                title: <TableTitle title="Clasificación" />,
                                dataIndex: 'expenseClassification',
                                key: generateCode(5),
                                fixed: 'left',

                                sorter: (a, b) => ((a?.expenseClassification)?.localeCompare(b?.expenseClassification)),
                                render: (text, record) => {

                                    return <Typography.Text type="secondary">{text}</Typography.Text>
                                }
                            },

                            {
                                title: <TableTitle title="Fecha " />,
                                dataIndex: 'date',
                                key: generateCode(5),
                                fixed: 'left',

                                sorter: (a, b) => ((a?.date) > (b?.date)),
                                render: (text, record) => {

                                    return <Typography.Text type="secondary">{moment(text).format('DD/MM/YYYY')}</Typography.Text>
                                }
                            },
                            {
                                title: <TableTitle title=" " />,
                                dataIndex: 'actions',
                                key: generateCode(5),
                                fixed: 'left',


                                render: (text, record) => {

                                    return <Row wrap>
                                        <EyeOutlined className="clickable" onClick={() => {
                                            setCreateExpense({ ...record, date: moment(record.date), amount: record.amount / 100 })

                                        }} style={{ marginRight: '15px', color: '#b7b7b7' }} />
                                        {loading === record.id ? <LoadingOutlined /> : <DeleteOutlined style={{ color: '#b7b7b7' }} className="clickable hoverDanger" onClick={async () => {
                                            try {
                                                setLoading(record.id)
                                                await deleteDoc(doc(getFirestore(), `expenses/${record.id}`))
                                                setLoading(null)
                                            } catch (error) {
                                                message.error('No se pudo eliminar el gasto');
                                                setLoading(null)
                                            }

                                        }} />}

                                    </Row>
                                }
                            },

                        ]}
                        //data={clientsData ?? []} 
                        query={expensesRef}
                        // loading={clientsDBStatus === 'loading'} 
                        userDocument={userDocument}
                    />
                </ErrorBoundary>
            </Col>

        </Row >
    );
}

export default ExpensesPage;