import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Form, Modal, Typography, Table, Col, Row, Button, Space, Tooltip, message } from 'antd';

import React, { Suspense, useState } from 'react';
import { useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';



function SearchSATProductkey(props) {

    const [totalItems, settotalItems] = useState(0)
    const [loading, setloading] = useState(false)
    const [search, setsearch] = useState("")
    const [products, setproducts] = useState([])
    const auth = useAuth()




    /**
     * It searches for products in the catalog and sets the results in the state
     * @param v - is the value of the search input
     */
    const SearchKeys = async (v) => {
        try {
            setloading(true)
            const products = await SignedInternalAPIRequest({ invoicingIntegration: 'facturapi', query: v.search, type: 'search_product_catalog', test: true, page: 1 }, 'invoicing', auth.currentUser)
            setsearch(v.search)
            settotalItems(products.total_results)
            setproducts(products.data)
            setloading(false)
        } catch (error) {
            message.error(error.message)
            setloading(false)
        }
    }

    /**
     * It makes a request to the server to get the products that match the search query, and then it sets
     * the products and total items to the state
     * @param v - The page number
     */
    const ChangeCataloguePage = async (v) => {
        setloading(true)
        try {
            const products = await SignedInternalAPIRequest({ invoicingIntegration: 'facturapi', query: search, type: 'search_product_catalog', test: true, page: v }, 'invoicing', auth.currentUser)
            settotalItems(products.total_results)
            setproducts(products.data)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }
    /**
     * It takes a search query and returns a list of products
     * @param v - is the object that contains the search query and the page number.
     */
    const SearchUnitKey = async (v) => {
        setloading(true)
        setsearch(v.search)
        try {
            const products = await SignedInternalAPIRequest({ invoicingIntegration: 'facturapi', query: v.search, type: 'search_unit_key', test: true, page: 1 }, 'invoicing', auth.currentUser)

            settotalItems(products.total_results)
            setproducts(products.data)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }
    /**
     * It makes a request to the server to get the products that match the search query
     * @param v - page number
     */
    const ChangeSearchUnitKey = async (v) => {
        setloading(true)
        try {
            const products = await SignedInternalAPIRequest({ invoicingIntegration: 'facturapi', query: search, type: 'search_unit_key', test: true, page: v }, 'invoicing', auth.currentUser)

            settotalItems(products.total_results)
            setproducts(products.data)
            setloading(false)
        } catch (error) {
            setloading(false)
        }
    }






    return (

        <Modal wrapClassName="pro-modal" footer={null} onCancel={() => props.close()} style={{ top: 15 }} destroyOnClose={true} visible={props.open}>
            <Suspense fallback={<>loading </>}>
                <div style={{ padding: '20px 15px', display: 'flex', flexDirection: 'column' }}>
                    <Row>
                        <Col xs={24} lg={19}>
                            <Typography.Paragraph className="customEditableTitle" style={{ fontSize: '18px', fontWeight: '500' }}>{props.title ?? 'Busca la clave de productos en el SAT'}</Typography.Paragraph>
                        </Col>
                    </Row>
                    <Form onFinish={props.isUnit ? SearchUnitKey : SearchKeys}>
                        <Row style={{ width: '100%' }}>
                            <Form.Item style={{ flex: 1 }} name="search" rules={[{ required: true, message: 'Agrega un término para la busqueda' }]}>
                                <Input disabled={loading} className="pro-input" type="search" />
                            </Form.Item>
                            <Button style={{ marginLeft: '10px' }} type="primary" shape="circle" htmlType="submit" className="pro-button" loading={loading} icon={<SearchOutlined />} />
                        </Row>

                    </Form>


                    <Table key={(record) => record.id} loading={loading} className="pro-table" pagination={{ pageSizeOptions: [50], showSizeChanger: true, defaultPageSize: 50, hideOnSinglePage: true, onChange: props.isUnit ? ChangeSearchUnitKey : ChangeCataloguePage, total: totalItems }} rowKey={(record) => record.uuid} columns={[
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Descripción</Typography.Text>,
                            dataIndex: 'description',
                            key: 'uuid',
                            render: (text, record) => {
                                return <Typography.Text style={{ fontWeight: 'bold', fontSize: '11px', marginBottom: '0px' }} >{text}</Typography.Text>
                            }
                        },
                        {
                            title: <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Código</Typography.Text>,
                            dataIndex: 'key',
                            key: 'uuid',
                            render: (text, record) => {

                                return <Space>
                                    <Typography.Paragraph style={{ fontWeight: 'bold', fontSize: '11px', marginBottom: '0px' }} copyable>{text}</Typography.Paragraph>
                                    {props.onSelected && <Tooltip title="Seleccionar">
                                        <CheckCircleOutlined style={{ color: '#b7b7b7' }} className="itemHoverPrimary" onClick={() => {
                                            if (props.onSelected) {
                                                props.onSelected(record.key)
                                            }
                                        }
                                        } />
                                    </Tooltip>}
                                </Space>
                            }
                        },


                    ]
                    } dataSource={products} />

                </div>
            </Suspense>
        </Modal>


    );
}

export default SearchSATProductkey;