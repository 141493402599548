import { Button, Col, DatePicker, Form, Input, Modal, Row, Tooltip, Typography, } from 'antd';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Suspense, useState } from 'react'
import AddRelatedInvoiceToPayment from './AddRelatedInvoiceToPayment';
import Select from 'react-select'
import { generalreactSelectStyles } from '../../app/functions/styles';
import { RemoveUndefined, paymentForms } from '../../app/functions/helpers';
import moment from 'moment';
import 'moment/locale/es';
import esES from 'antd/es/locale/es_ES';

moment.locale('es');



function AddPaymentToInvoiceModal({ open, close, userDocument, state: incomingState, updateState }) {
    const [addInvoiceModal, setAddInvoiceModal] = useState(false)
    const [state, setstate] = useState(incomingState)

    function disabledDate(current) {
        return current && current > moment().endOf('day');
    }

    const TooltipInfo = ({ label, description }) => <>
        <label style={{ margin: 0 }}>{label}</label>
        <Tooltip title={description}>
            <InfoCircleOutlined style={{ marginLeft: '4px', cursor: 'pointer' }} />
        </Tooltip>
    </>

    return (<Modal visible={open} onCancel={() => close()} footer={null} title={null} style={{ width: window.innerWidth < 850 ? '90%' : '40%', top: 10 }} >
        {addInvoiceModal && <Suspense>
            <AddRelatedInvoiceToPayment userDocument={userDocument} open={addInvoiceModal} close={() => setAddInvoiceModal(false)} updateState={(v) => {
                setstate({ ...state, relations: [...(state?.relations ?? []), v] })
                setAddInvoiceModal(false)
            }} />
        </Suspense>}
        <Typography.Title level={3}>Añadir pago</Typography.Title>
        <Typography.Paragraph style={{ fontSize: '10px' }} type="secondary">Lo más común es incluir un sólo pago. Un caso en el que se debe de agregar más de uno es cuando el pago se realiza con 2 formas de pago distintas; por ejemplo, cuando se paga una parte con tarjeta y otra en efectivo.</Typography.Paragraph>
        <Form layout="vertical" onFinish={(v) => {
            updateState({
                ...(RemoveUndefined(v)),
                related_documents: state.relations,
                currency: state.relations[0].currency,
                exchange: state.relations[0].exchange,
                exchange_rate: state.relations[0].exchange,
                exchangeRate: state.relations[0].exchange,
                payment_form: v.payment_form.value,
                paymentForm: v.payment_form.label,
                date: v.date.toISOString(),
            })
        }}>
            <Row>
                <Col xs={24}>
                    <div style={{ borderRadius: '10px', border: '1px solid #f1f1f1', padding: '10px', marginBottom: '10px' }} className="d-flex flex-column clickable" onClick={() => setAddInvoiceModal(true)}>
                        <Row justify="space-between">
                            <Typography.Text style={{ fontSize: '11px' }} type="secondary">Añadir factura relacionada</Typography.Text>
                            <PlusOutlined type="secondary" />
                        </Row>
                    </div>
                </Col>
                <Col xs={24}>
                    {state?.relations?.map((v, i) => <div key={i} style={{ borderRadius: '10px', border: '1px solid #f1f1f1', padding: '10px', marginBottom: '10px' }} className="d-flex flex-column">
                        <Row justify="space-between">
                            <div className="d-flex flex-column">
                                <Typography.Text style={{ fontSize: '12px' }} >UUID</Typography.Text>
                                <Typography.Text style={{ fontSize: '11px' }} type="secondary">{v.uuid}</Typography.Text>
                            </div>
                            <DeleteOutlined type="secondary" className="hoverDanger clickable" onClick={() => {
                                var arr = state.relations;
                                arr.splice(i, 1);
                                setstate({ ...state, relations: arr })
                            }} />
                        </Row>
                    </div>)}
                </Col>
                <Col xs={24}>
                    <Form.Item label="Forma de pago" name="payment_form" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={paymentForms}
                            isLoading={false}
                            onChange={(v) => {
                                setstate({ ...state, payment_method: v })

                            }}
                            placeholder="Método de pago"
                        />
                    </Form.Item>

                </Col>
                <Row gutter={10}>
                    <Col xl={12}>
                        <Form.Item label="Fecha del pago" name="date">
                            <DatePicker locale={esES} disabledDate={disabledDate} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xl={12}>
                        <Form.Item label={<TooltipInfo label='Número de operación' description='Número de cheque, de autorización, de referencia, clave de rastreo SPEI, línea de captura o algún número de referencia que permita identificar la operación correspondiente al pago efectuado' />} name="numOperacion">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xl={12}>
                        <Form.Item label={<TooltipInfo label="RFC de la entidad emisora" description="RFC de la entidad emisora de la cuenta de origen, es decir, la operadora, banco, institución financiera, emisor de monedero electrónico, etc." />} name="rfcEmisorCtaOrd">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={12} >
                        <Form.Item label="Nombre del banco ordenante" name="nomBancoOrdExt">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xl={12}>
                        <Form.Item label={<TooltipInfo label="Número de cuenta" description="Número de cuenta con la que se realizó el pago" />} name="ctaOrdenante">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={12} >
                        <Form.Item label={<TooltipInfo label="RFC de la entidad operadora" description="RFC de la entidad de la cuenta operadora destino, es decir, la operadora, banco, institución financiera, emisor de monedero electrónico, etc." />} name="rfcEmisorCtaBen">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                    <Form.Item label={<TooltipInfo label="Número de cuenta donde se recibió el pago" description="Número de cuenta donde se recibió el pago" />} name="ctaBeneficiario" style={{ width: '100%' }}>
                        <Input />
                    </Form.Item>
                </Row>
                <Col xs={24}>
                    <Row justify="center">
                        <Button htmlType='submit' type="primary">Añadir</Button>
                    </Row>
                </Col>
            </Row>
        </Form>
    </Modal>);
}

export default AddPaymentToInvoiceModal;