import { FlagOutlined } from "@ant-design/icons"
import { Button, Layout, message, Row, Typography } from "antd"
import { doc, getDoc, getFirestore } from "firebase/firestore"
import { useContext, useState } from "react"
import { useAuth } from "reactfire"
import UserContext from "../../context/usercontext/UserContext"
import { SignedInternalAPIRequest } from "../functions/APIRequests"

const SessionBlocked = () => {

    const { setUserData } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const auth = useAuth()

    const migrateOldUser = async () => {
        try {
            setLoading(true)
            await SignedInternalAPIRequest({}, 'migrateOldUser', auth.currentUser, {})
            getDoc(doc(getFirestore(), 'users', auth.currentUser.uid)).then((doc) => {
                if (doc.exists) {
                    setUserData(doc.data())
                }
            }).catch((error) => {
            });
            setLoading(false)
            message.success('Tu cuenta ha sido migrada correctamente', 1000)
        } catch (error) {
            setLoading(false)
            message.error('Ha ocurrido un error, intenta de nuevo o escríbenos a soporte@gigstack.pro')
        }
    }

    return (
        <Layout>
            <Layout.Content>
                <Row justify="center" align="middle" style={{ marginTop: '50px' }}>
                    <div className="d-flex flex-column">
                        <Row justify="space-between">
                            <p className='russo primary'>gigstack pro</p>
                        </Row>
                        <FlagOutlined style={{ marginRight: '10px', fontSize: '25px', marginBottom: '15px' }} />
                        <div className="d-flex flex-column" style={{ margin: '0px 5%' }}>
                            <Typography.Title level={3} style={{ padding: 0, margin: 0, textAlign: 'center', marginBottom: '15px' }}>¡Hemos actualizado nuestra plataforma!</Typography.Title>
                            <Typography.Text level={4} style={{ textAlign: 'center', marginTop: '8px' }} type="secondary">Necesitas migrar tu cuenta a la nueva versión, solo debes dar click en el botón de abajo y nosotros migraremos tus datos, si tienes alguna duda, escribenos a soporte@gigstack.pro</Typography.Text>
                        </div>

                        <Row justify="center" style={{ marginTop: '35px' }}>
                            <Button loading={loading} onClick={migrateOldUser} type="primary">Migrar mi cuenta</Button>
                        </Row>
                    </div>
                </Row >
            </Layout.Content>
        </Layout>

    )
}

export default SessionBlocked