import { Button, Col, message, Popconfirm, Row, Space, Tag, Tooltip, Typography, Table, Input, notification, Tabs } from 'antd';
import React, { Suspense, useContext, useState } from 'react';
import ActionablePageHeader from '../../widgets/headers/ActionablePageHeader';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import { collection, deleteDoc, doc, getFirestore, orderBy, query, updateDoc, where } from 'firebase/firestore';
import TableTitle from '../../widgets/tables/TableTitle';
import { CopyToClipboard, generateCode, getItemsAmounts, paymentIntentsStatusMessage, returnColorByPaymentStatus, /*getItemsAmounts,*/ returnCurrencyValue, returnTextColorByPaymentStatus, SearchParamInURL, ShareViaWhatsapp } from '../functions/helpers';
import moment from 'moment';
import { BarcodeOutlined, BookOutlined, CheckOutlined, ClockCircleOutlined, CloudDownloadOutlined, DeleteOutlined, ExperimentOutlined, EyeInvisibleOutlined, InfoCircleOutlined, LinkOutlined, LoadingOutlined, MailOutlined, NotificationOutlined, StopOutlined, UserAddOutlined, WarningOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { SignedInternalAPIRequest } from '../functions/APIRequests';
import { useAuth, useFirestoreCollectionData } from 'reactfire';
import ReviewPaymentModal from '../../widgets/payments/ReviewPaymentModal';
import { useMembershipOnce } from '../../customHooks/useMembershipOnce';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import ComposeEmailModal from '../../widgets/email/ComposeEmailModal';
import NotifyMultiple from '../../widgets/payments/NotifyMultiple';
import TeamContext from '../../context/teamcontext/TeamContext';
// import CreateElementHolder from '../../widgets/drawers/CreateElementHolder';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import NewPaymentRequest from '../../widgets/payments/NewPaymentRequest';
import { useUserPermissions } from '../../customHooks/userPermissions';
import ClientsListModal from '../../widgets/clients/ClientsListModal';
import RelationsDrawer from '../../widgets/relations/RelationsDrawer';
import RegisterPaymentModal from '../../widgets/payments/RegisterPaymentModal';
import NewRecurringPayment from '../../widgets/payments/NewRecurringPayment';
import PaymentTotalForClient from '../../widgets/modals/PaymentModal';
import PaymentDueForClient from '../../widgets/modals/PaymentDueForCustomer';


//TODO: CHECK MODALS

const RenderToReview = ({ userDocument, setconfirmPayment, payments: data }) => {

    if (data.length <= 0) {
        return <></>
    }
    return <Row style={{ width: '100%' }}>

        <Col xs={24}>
            <Col xs={24} style={{ marginTop: '10px' }}>
                <ActionablePageHeader title="Revisa los pagos" actions={
                    <Typography.Text type="secondary">{data.length} pago{data.length > 1 ? 's' : ''} por revisar</Typography.Text>}
                />
            </Col>
            <div className="tableContainer d-flex flex-column">
                {data.map((rev_payment) => {
                    const amounts = getItemsAmounts(rev_payment.items, true)
                    return <Col key={rev_payment.id} xs={24} md={24} style={{ marginBottom: '10px' }}>
                        <div style={{ backgroundColor: '#F9F3DE', padding: '10px 15px', borderRadius: '10px' }}>
                            <Row justify="space-between" align="middle">
                                <Space>
                                    <div style={{ minHeight: '50px', height: '100%', backgroundColor: rev_payment?.voucherConceptExists ? '#22BD5A' : '#ffb194', width: '5px', marginRight: '10px', borderRadius: '5px' }}></div>
                                    {rev_payment?.voucherConceptExists ? <Tooltip title="Hemos encontrado el concepto en el voucher">
                                        <InfoCircleOutlined style={{ fontSize: '15px' }} />
                                    </Tooltip> : <Tooltip title="No hemos encontrado el concepto en el voucher">
                                        <WarningOutlined style={{ fontSize: '15px' }} />
                                    </Tooltip>}
                                    <div className="d-flex flex-column " style={{ marginLeft: '10px' }}>
                                        <Typography.Text style={{ fontSize: '13px' }} className="strong" >{rev_payment.client?.name ?? 'Sin Cliente'} / {returnCurrencyValue(amounts.total - amounts.feeAdded)} {rev_payment.currency}</Typography.Text>
                                        <Row>
                                            <Typography.Text className="secondary" style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeigth: 'w400' }}>{moment(rev_payment.review_created_at).format('DD-MM-YYYY HH:mm:ss')}</Typography.Text>
                                            <Typography.Text className='secondary' style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeigth: 'w400', marginLeft: '5px' }}>- {rev_payment.conceptReference ?? rev_payment.fid?.replace('payment_', '')}</Typography.Text>
                                        </Row>
                                    </div>
                                </Space>
                                <Row justify='start' style={{ width: '100%' }}>
                                    <Button style={{ marginTop: '10px' }} type="" size="middle" onClick={() => setconfirmPayment(rev_payment)}>Revisar pago</Button>
                                </Row>
                            </Row>
                        </div>
                    </Col>
                })}
            </div>
        </Col>
    </Row>

}

const CardTotal = ({ amount, description, extraElement, type = 'currency', Titulo, color = '#7E7E82' }) => {

    return (
        <div style={{ borderRadius: '10px', backgroundColor: 'white', padding: '20px 30px', height: '200px', margin: '10px 15px 20px 0px', width: '33%' }}>
            <div>
                <Typography.Title level={4} style={{ color }} type="secondary">
                    {Titulo}
                </Typography.Title>
                <Typography.Text type='secondary' style={{ padding: 0, fontSize: '15px' }}>{description}</Typography.Text>
                <Row justify='start' align='middle'>
                    <Typography.Title style={{ fontSize: '30px', fontWeight: '600', marginTop: '15px', color }}>
                        {returnCurrencyValue(amount)}
                    </Typography.Title>
                </Row>
            </div>
        </div>
    );
};


/**
 * It takes an id and an index, and then deletes the record with that id
 * @param id - The id of the document to be deleted
 * @param index - The index of the record in the array
 */
export const DeleteRecord = async (id, index) => {
    try {
        const fs = getFirestore();
        await deleteDoc(doc(fs, `payments/${id}`))
        message.success('Pago eliminado')

    } catch (error) {
        message.error('Error deleting record')
    }
}

function CollectionsPayments({ userDocument, subtab, changeRender }) {

    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const fs = getFirestore();
    const auth = useAuth()
    // const paymentsRef = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));
    const [actionOnPayment, setactionOnPayment] = useState(null)
    const [createRecurring, setcreateRecurring] = useState(false)
    const [editRecurring, seteditRecurring] = useState(null)
    const [pageActive, setpageActive] = useState(SearchParamInURL('pageActive') ?? 1)
    const [pageSize, setpageSize] = useState(10)
    const [filterText, setfilterText] = useState('')
    const [filterBy, setfilterBy] = useState('all')
    // const [showTestMode, setShowTestMode] = useState(false)
    // const [showHidden, setShowHidden] = useState(SearchParamInURL('showHidden') === "false" ? false : true);

    const [composeEmail, setComposeEmail] = useState(null)
    const [defaultClientSearch, setdefaultClientSearch] = useState([])
    const [confirmPayment, setconfirmPayment] = useState(null)
    const [notifyMultiple, setNotifyMultiple] = useState(false);
    const [selectClient, setSelectClient] = useState(null)
    const [showReceiptRelations, setShowReceiptRelations] = useState(false)
    const [registerPayment, setRegisterPayment] = useState(false)
    const pendingPaymentsRef = query(collection(fs, `payments`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), where('status', 'in', ['requires_payment_method', 'pending', 'review_requested', 'manual_confirmation', 'open']));

    const { credits, isPaidUser, payAsYouGo } = useMembershipOnce({ uid: userDocument?.uid, billingAccountId: billingAccount?.id })

    const { canCreatePayments } = useUserPermissions({ user: userDocument, team, billingAccount })

    const { status, data } = useFirestoreCollectionData(pendingPaymentsRef, {
        idField: 'fid'
    })

    /**
     * It takes a payment object as an argument, sets the actionOnPayment state to the payment's id, then
     * makes a request to the server to cancel the payment, and if the request is successful, it sets the
     * actionOnPayment state to null
     * @param payment - The payment object that you want to cancel.
     * @returns const resp = await SignedInternalAPIRequest({ type: 'payment_request_cancel', id:
     * payment.paymentIntent, paymentID: payment.id }, 'stripeActions', auth.currentUser)
     */
    const CancelPayment = async (payment) => {

        setactionOnPayment(payment.id)
        try {
            const resp = await SignedInternalAPIRequest({ type: 'payment_request_cancel', id: payment.paymentIntent, paymentID: payment.id, fid: payment.fid }, 'paymentsHandler', auth.currentUser)
            if (resp.error) {
                message.error('Error al cancelar pago')
                return setactionOnPayment(null)
            }
            notification.success({ placement: "topRight", description: 'El pago ha sido cancelado, ahora solo lo podrás visualizar en el apartado de pagos', duration: 10 })
            setactionOnPayment(null)
        } catch (error) {
            message.error('Error al cancelar pago')
            setactionOnPayment(null)
        }
    }


    const [createRequest, setcreateRequest] = useState(false)

    /**
     * It renders a table with the payments of the user
     */
    const RenderPayments = () => {

        // const [originalData, setOriginalData] = useState([]);
        const [filteredData, /*setFilteredData*/] = useState([]);


        // const handleInitialData = (data) => {
        //     setOriginalData(data);
        //     setFilteredData(data);
        // };

        // // Al realizar una búsqueda o filtro
        // const handleFilter = (filterText) => {
        //     const filtered = originalData.filter((p) => {


        //         if (filterBy === 'past') {
        //             var limitDate = p?.limitDaysToPay ? moment(p?.timestamp).add(p?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
        //             var isLate = limitDate?.valueOf() < moment().valueOf()
        //             return isLate
        //         }
        //         if (filterBy === 'due') {
        //             return p.limitDateToPay < moment().valueOf()
        //         }

        //         if (filterBy === 'review_requested') {
        //             return p.status === 'review_requested'
        //         }

        //         if (filterBy === 'all' && filterText === '') {
        //             return true
        //         }

        //         return p.client?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
        //             p.client?.email?.toLowerCase().includes(filterText?.toLowerCase()) ||
        //             p.fid?.toLowerCase().includes(filterText?.toLowerCase()) ||
        //             p.id?.toLowerCase().includes(filterText?.toLowerCase()) ||
        //             p.amount === parseInt(filterText) ||
        //             p.amount === parseFloat(filterText) ||
        //             p.amount === parseFloat(filterText?.replace(',', '.'))

        //     });

        //     setFilteredData(filtered);
        // };

        return <Row style={{ width: '100%' }}>
            <Col xs={24} >
                <ActionablePageHeader title="Pagos por cobrar" actions={
                    <Space align="center">
                        {team?.hilos?.completed && <Tooltip title="Enviar recordatorio a multiples.">
                            <NotificationOutlined onClick={() => {
                                setNotifyMultiple(true)
                            }} style={{ marginBottom: '5px', marginRight: '10px', color: 'black' }} className="clickable" />
                        </Tooltip>}
                        {(credits > 0 || payAsYouGo) && <Button onClick={() => setRegisterPayment(true)} type="ghost">Registrar pago</Button>}
                        {(credits <= 0 && !payAsYouGo) ? <Button onClick={() => {
                            changeRender('settings')
                            window.history.pushState({}, '', `/settings?subtab=membership`)

                        }}>{isPaidUser ? 'No tienes eventos' : 'Actualiza tu plan'}</Button> : canCreatePayments && <Button disabled={credits <= 0 && !payAsYouGo} onClick={() => {
                            setcreateRequest(true);
                        }} type='primary'>Nueva solicitud</Button>}

                    </Space>

                }

                />
            </Col>



            <Col xs={24} style={{ marginTop: '25px' }}>
                <Row justify="end" style={{ marginBottom: '15px', marginTop: '10px' }}>
                    <Col xl={12}>
                        <div className="d-flex flex-column">
                            <Input.Search defaultValue={filterText} onSearch={setfilterText} allowClear placeholder="Buscar cliente" />
                            <Space wrap size="small" style={{ marginTop: '10px' }}>
                                {team?.defaults?.allowPaymentsSetting && <Tag onClick={() => setfilterBy('past')} className={filterBy === 'past' ? 'activeTag clickable' : ' clickable'}>Atrasados</Tag>}
                                <Tag onClick={() => setfilterBy('all')} className={filterBy === 'all' ? 'activeTag clickable' : ' clickable'}>Todos</Tag>
                                <Tag onClick={() => setfilterBy('review_requested')} className={filterBy === 'review_requested' ? 'activeTag clickable' : ' clickable'}>Por revisar</Tag>
                                <Tag onClick={() => setfilterBy('due')} className={filterBy === 'due' ? 'activeTag clickable' : ' clickable'}>Vencidos</Tag>

                            </Space>
                        </div>
                    </Col>
                </Row>
                {showReceiptRelations && <Suspense fallback={<LoadingOutlined />}><RelationsDrawer open={showReceiptRelations} relation={showReceiptRelations} render='receipts' close={() => setShowReceiptRelations(false)} /></Suspense>}
                {<ErrorBoundary>
                    <Suspense fallback={<LoadingOutlined />}>
                        <Table
                            dataSource={data.filter(p => {

                                if (filterBy === 'past') {
                                    var limitDate = p?.limitDaysToPay ? moment(p?.timestamp).add(p?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
                                    var isLate = limitDate?.valueOf() < moment().valueOf()
                                    return isLate
                                }
                                if (filterBy === 'due') {
                                    return p.limitDateToPay < moment().valueOf()
                                }

                                if (filterBy === 'review_requested') {
                                    return p.status === 'review_requested'
                                }

                                if (filterBy === 'all' && filterText === '') {
                                    return true
                                }
                                if (filterBy === 'review_requested') {
                                    return p.status === 'review_requested'
                                }

                                return p.client?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.client?.email?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.fid?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.id?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.amount === parseInt(filterText) ||
                                    p.amount === parseFloat(filterText) ||
                                    p.amount === parseFloat(filterText?.replace(',', '.'))
                            })}
                            pageSize={pageSize}
                            loading={{
                                spinning: status === 'loading',
                                indicator: <LoadingOutlined style={{ color: '#8666FF' }} />,
                            }}
                            // pagination={{
                            //     pageSize: pageSize,
                            //     defaultCurrent: pageActive,
                            //     onChange: (p, ps) => {
                            //         setpageActive(p)
                            //         setpageSize(ps)
                            //     },
                            //     showSizeChanger: true,
                            //     pageSizeOptions: ['10', '20', '50', '100'],
                            //     showTotal: (total, range) => `${range[0]}-${range[1]} de ${data.length} pagos`,
                            //     position: ['bottomCenter'],
                            //     defaultPageSize: 10,
                            //     showQuickJumper: false,
                            //     responsive: true,
                            //     total: data.length ?? 1,
                            //     locale: { items_per_page: '/ página' }
                            // }}
                            pagination={{
                                pageSize: pageSize,
                                defaultCurrent: pageActive,
                                onChange: (p, ps) => {
                                    setpageActive(p);
                                    setpageSize(ps);
                                },
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} pagos`,

                                position: ['bottomCenter'],
                                defaultPageSize: 10,
                                showQuickJumper: false,
                                responsive: true,
                                total: filteredData.length,
                                locale: { items_per_page: '/ página' }
                            }}

                            onChange={(p, filters, sorter, extra) => {
                                if (extra.action === 'filter') {
                                    setdefaultClientSearch(extra.currentDataSource?.map((d) => d.client?.name ?? 'Sin cliente'))
                                }
                                setpageSize(p.pageSize)
                            }}
                            empty='No tienes solicitudes de pago por cobrar'
                            elementName="solicitud de pago"

                            emptyAction={() => {
                                setcreateRequest(true)
                            }}
                            columns={[
                                {
                                    title: <TableTitle title="Cliente" />,
                                    dataIndex: 'client',
                                    key: generateCode(5),
                                    filterMode: 'multiple',
                                    defaultFilteredValue: defaultClientSearch,
                                    onFilter: (value, record) => {
                                        return (record.client?.name ?? 'Sin cliente') === value
                                    },
                                    fixed: 'left',
                                    width: '15%',
                                    sorter: (a, b) => ((a?.name)?.localeCompare(b?.name)),
                                    render: (text, record) => {
                                        const clientId = record?.clientId ?? record?.clientID
                                        return <div className="d-flex flex-column">
                                            <Row align='middle'>
                                                {record.hidden && <Tooltip title="Oculto">
                                                    <EyeInvisibleOutlined onClick={() => {
                                                        updateDoc(doc(fs, 'payments', record.fid), { hidden: false })
                                                    }} style={{ marginRight: '5px' }} type="secondary" className="secondary" />
                                                </Tooltip>}
                                                <div className='d-flex flex-row'>
                                                    <Typography.Text onClick={() => {
                                                        changeRender('paymentDetails')
                                                        return window.history.replaceState(null, `tab`, `/paymentDetails?id=${record?.fid}&pageActive=${pageActive}`)
                                                    }} className="primary clickable" style={{ flex: 1 }} >{record.client?.name ?? 'Sin Cliente'}</Typography.Text>
                                                    {((!clientId || record?.clientEdited) && [...(record?.invoices ?? [])].length === 0) &&
                                                        <Tooltip title="Asignar cliente">
                                                            <UserAddOutlined onClick={() => setSelectClient(record)} style={{ color: '#8666FF', fontSize: '14px', margin: '0px 5px' }} />
                                                        </Tooltip>
                                                    }
                                                </div>
                                                {!record.livemode && <Tooltip title="Modo pruebas">
                                                    <ExperimentOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#cecece' }} />
                                                </Tooltip>}
                                            </Row>

                                            <Typography.Text copyable type="secondary" style={{ fontSize: '10px' }}>{record.fid}</Typography.Text>
                                        </div>
                                    }
                                },
                                {
                                    title: <TableTitle title="Estado" />,
                                    dataIndex: 'status',
                                    key: generateCode(5),
                                    width: '12%',
                                    ellipsis: true,
                                    sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
                                    render: (text, record) => {
                                        return <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                            <Tooltip title={paymentIntentsStatusMessage(record)}>
                                                <div> <Tag className="statusTag" color={returnColorByPaymentStatus(record)} style={{
                                                    color: returnTextColorByPaymentStatus(record), borderRadius: '10px', opacity: 0.9, padding: '0px 10px', fontWeight: 500
                                                }}>{paymentIntentsStatusMessage(record)}</Tag></div>
                                            </Tooltip>
                                        </Row>

                                    }
                                },
                                {
                                    title: <TableTitle title="Total" />,
                                    dataIndex: 'amount',
                                    align: 'right',
                                    width: '12%',
                                    key: generateCode(5),
                                    sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),

                                    render: (text, record) => {
                                        var showTotal = record.paidIn === 'bank' ? getItemsAmounts(record.items, (record.paidIn === 'bank')).total : getItemsAmounts(record.items, (record.paidIn === 'bank')).total
                                        return <Tooltip title={record.exchangeRate ? `${returnCurrencyValue((showTotal) * (record.exchangeRate ?? 1))} mxn` : null}>
                                            <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => {
                                            }} ellipsis>{returnCurrencyValue(showTotal)} {(record?.currency ?? '').toUpperCase()} </Typography.Text>
                                        </Tooltip>
                                    }
                                },
                                {
                                    title: <TableTitle title="Creado" />,
                                    dataIndex: 'timestamp',
                                    width: '12%',
                                    key: generateCode(5),
                                    sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),
                                    render: (text, record) => {

                                        return <div className="d-flex flex-column">
                                            <Typography.Text type="secondary" >{moment(record.timestamp).format('DD MMM YYYY HH:mm')}</Typography.Text>
                                        </div>
                                    }
                                },
                                // {
                                //     title: <TableTitle title="Automatización" />,
                                //     dataIndex: 'automations',
                                //     key: generateCode(5),
                                //     width: '10%',
                                //     align: 'center',
                                //     render: (text, record) => {
                                //         const hasAutomation = record.automations?.length > 0
                                //         const isSucceeded = record.status === 'succeeded'
                                //         const automationFailled = hasAutomation && isSucceeded && !record.invoices && !record.receipts && moment().diff(moment(record.timestamp), 'minutes') > 2
                                //         const automationMessage = automationFailled ? 'Automatización fallida' : !hasAutomation ? "Sin automatización" : (hasAutomation && !isSucceeded) ? 'Automatización para factura' : 'Automatización exitosa'
                                //         return <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                //             <div style={{ marginRight: '10px' }}>
                                //                 {hasAutomation && <Tooltip title={automationMessage}>
                                //                     {hasAutomation && !isSucceeded ? <RobotFilled style={{ color: '#6B7CF9', fontSize: 18 }} /> : null}
                                //                     {automationFailled ? <RobotOutlined style={{ color: '#f96f6a', fontSize: 18 }} /> : null}
                                //                     {(hasAutomation && isSucceeded && !automationFailled) ? <RobotOutlined style={{ color: '#b7b7b7', fontSize: 18 }} /> : null}
                                //                 </Tooltip>}
                                //             </div>
                                //             {(record.automationWarnings?.length > 0 && (record.invoices?.length ?? 0) <= 0) && <Tooltip title={<div className="d-flex flex-column">
                                //                 <Typography.Text className='strong' style={{ color: 'white' }}>Errores en automatización</Typography.Text>
                                //                 <Typography.Text style={{ fontSize: '10px', color: 'white' }}>{record.automationWarnings.map((a) => a + '' + a + '\n')}</Typography.Text>
                                //             </div>}>
                                //                 <WarningOutlined className="" style={{ color: '#b7b7b7' }} />
                                //             </Tooltip>}
                                //         </div>
                                //     }
                                // },
                                // {
                                //     title: <TableTitle title="Facturas" />,
                                //     dataIndex: 'invoices',
                                //     key: generateCode(5),
                                //     width: '12%',
                                //     render: (text, record) => {
                                //         const receipts = (record.receipts ?? []).length
                                //         return <div className="d-flex flex-column">
                                //             <InvoicesRelations custom={true} items={record?.items} collection="payments" elementType="payment" changeState={(i) => {
                                //             }} client={record?.client} item={{ ...record, invoices: record.invoices }} id={record?.fid} userDocument={userDocument} readable="pago" />

                                //             {receipts > 0 && <div style={{ marginLeft: '10px' }}>
                                //                 <span onClick={() => setShowReceiptRelations({
                                //                     title: `Recibos de venta relacionados`,
                                //                     type: 'invoices',
                                //                     db: 'payments',
                                //                     relatedWith: record,
                                //                     count: record?.receiptsCount ?? 1,
                                //                     elementID: record?.id,
                                //                     subdb: 'receipts',
                                //                     item: record
                                //                 })} style={{ color: '#757575', fontSize: '11px', cursor: 'pointer' }}>{record.receipts?.length} recibo{receipts > 1 ? 's' : ''} de venta</span>
                                //             </div>}
                                //         </div>
                                //     }
                                // },
                                {
                                    title: <TableTitle title="Vencimiento" />,
                                    dataIndex: 'lateStatus',
                                    key: generateCode(5),
                                    width: '12%',
                                    ellipsis: true,
                                    sorter: (a, b) => ((a?.status)?.localeCompare(b?.status)),
                                    render: (text, record) => {
                                        var limitDate = record?.limitDaysToPay ? moment(record?.timestamp).add(record?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
                                        var isLate = limitDate?.valueOf() < moment().valueOf()

                                        return <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                            {(limitDate && record.status !== 'succeeded') &&
                                                <div style={{ marginTop: '5px' }}>  <Tag color={isLate ? 'red' : '#E7F3FA'} style={{ color: isLate ? 'red' : '#0B9DF8' }} >{(limitDate?.valueOf() < moment().valueOf()) ? `Atrasado ${moment(record?.timestamp).add(record?.limitDaysToPay, 'days').hours(team?.defaults?.limitHourToPay ?? '17').fromNow()}` : `Vence ${limitDate.fromNow()}`}</Tag></div>
                                            }
                                        </Row>

                                    }
                                },
                                team?.defaults?.sendPaymentsReminder ? {
                                    title: <TableTitle title="Recordatorio de pago" />,
                                    dataIndex: 'reminderPayments',
                                    key: generateCode(5),
                                    width: '10%',
                                    align: 'center',
                                    render: (text, record) => {
                                        const sendReminderMessage = 'Recordatorio enviado'
                                        const wohManyDaysMessage = 'Fecha en la que se envia el recordatorio del pago por correo a tu cliente'
                                        const emailSend = record?.reminderEmailSended
                                        const limiteDateToPay = record?.limitDateToPay
                                        const defaultsDaysToSendEmail = team?.defaults?.paymentsReminderDays ?? 2
                                        const daysInReminderEmail = moment(limiteDateToPay).subtract(defaultsDaysToSendEmail, 'days').fromNow()
                                        return <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <div style={{ marginRight: '10px' }}>
                                                {emailSend ?
                                                    <Tooltip title={sendReminderMessage}>
                                                        <CheckOutlined style={{ color: '#898989' }} />
                                                    </Tooltip> : <Typography.Text style={{ color: '#898989' }}>{!limiteDateToPay || (record.status === 'succeeded') ? null : <Tooltip title={wohManyDaysMessage}>{daysInReminderEmail ? `${daysInReminderEmail}` : null}</Tooltip>}</Typography.Text>}
                                            </div>
                                        </div>

                                    }
                                } : <></>,
                                {
                                    title: <TableTitle title="" />,
                                    dataIndex: 'actions',
                                    key: generateCode(5),
                                    width: '13%',
                                    render: (text, record, i) => {

                                        const amounts = getItemsAmounts(record.items, record.paidIn === 'bank');

                                        return (actionOnPayment === record.id ? <LoadingOutlined /> :
                                            <Space align='middle'>
                                                {(record?.token && record?.status !== 'succeeded' && record?.status !== 'canceled') && (
                                                    <>
                                                        <Tooltip title="Copiar link de pago">
                                                            <LinkOutlined onClick={() => {
                                                                setactionOnPayment(record.id);
                                                                CopyToClipboard(record.shortURL ?? `https://beta.gigstack.pro/pay/${record.token}`);
                                                                setactionOnPayment(null);
                                                            }} />
                                                        </Tooltip>
                                                        <Tooltip title="Enviar link por correo">
                                                            <MailOutlined onClick={() => setComposeEmail(record)} />
                                                        </Tooltip>
                                                    </>
                                                )
                                                }

                                                {(record?.shortURL && record.status !== 'succeeded' && record.status !== 'canceled') && (
                                                    <Tooltip title="Enviar por Whatsapp">
                                                        <WhatsAppOutlined onClick={() => ShareViaWhatsapp(
                                                            `Puedes realizar el pago por ${amounts.totalString} ${record.currency} en el siguiente enlace! 😉 : ${record.shortURL}`
                                                        )} />
                                                    </Tooltip>
                                                )}

                                                {(record.status === 'canceled') && (
                                                    <Tooltip title="Eliminar pago">
                                                        <Popconfirm
                                                            showArrow={false}
                                                            title="¿Estás seguro de eliminar el pago?"
                                                            okText="Eliminar pago"
                                                            cancelText="No"
                                                            onConfirm={() => {
                                                                if (
                                                                    (record.status === 'canceled' || record.object === 'manual_payment') &&
                                                                    record.review_status !== 'succeeded'
                                                                ) {
                                                                    DeleteRecord(record.fid, i);
                                                                }
                                                            }}
                                                        >
                                                            <DeleteOutlined className="clickable itemHoverDanger" />
                                                        </Popconfirm>
                                                    </Tooltip>
                                                )}

                                                {((record.status === 'requires_payment_method' || record.status === 'requires_action') && (record.status !== 'canceled' && record.status !== 'succeeded')) && (
                                                    <Tooltip title="Cancelar pago">
                                                        <Popconfirm
                                                            title="¿Estás seguro de cancelar el pago?"
                                                            okText="Cancelar pago"
                                                            cancelText="No"
                                                            onConfirm={() => {
                                                                if (
                                                                    record.status === 'requires_payment_method' ||
                                                                    record.status === 'requires_confirmation' ||
                                                                    record.status === 'pending' ||
                                                                    record.status === 'requires_action'
                                                                ) {
                                                                    CancelPayment(record);
                                                                    // message.success('Pago cancelado correctamente')
                                                                }
                                                            }}
                                                        >
                                                            <StopOutlined className="itemHoverDanger clickable" />
                                                        </Popconfirm>
                                                    </Tooltip>
                                                )}

                                                {(record.charges?.data?.length > 0) && <Tooltip title="Ver recibo">
                                                    <BarcodeOutlined className="itemHoverPrimary" onClick={() => window.open(record.charges?.data[0]?.receipt_url, '_blank')} />
                                                </Tooltip>}
                                            </Space>
                                        );
                                    },
                                },
                            ]}
                            onPageChange={(p) => {
                                setpageActive(p)
                            }}
                            defaultPage={pageActive}
                        />
                    </Suspense>
                </ErrorBoundary>}
            </Col>
        </Row >
    }

    // const RenderForClient = () => {
    //     const [showModalTotal, setShowModalTotal] = useState(false)
    //     const [selectedPayment, setSelectedPayment] = useState(false);
    //     const [selectedPaymentDue, setSelectedPaymentDue] = useState(false);
    //     const [showModalTotalDue, setShowModalTotalDue] = useState(false)
    //     // const [originalData, setOriginalData] = useState([]);
    //     const [filteredData, /*setFilteredData*/] = useState([]);
    //     const [downloadFile, setDownloadFile] = useState(null)
    //     const DownloadXLSX = async ({ endpoint = ``, type = "pendigAllClients" }) => {
    //         try {
    //             setDownloadFile('pendigAllClients')
    //             await SignedInternalAPIRequest({
    //                 clientId: null,

    //             }, `exportFiles/v1/forClients`, auth.currentUser, { isFile: true, fileName: `clientes-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
    //             setDownloadFile(false)

    //         } catch (error) {
    //             message.error('Error al descargar el archivo')
    //             setDownloadFile(false)
    //         }
    //     }

    //     const groupPaymentsByClientId = (data) => {
    //         const groupedPayments = {};

    //         data.forEach(payment => {
    //             const clientId = payment?.clientId ?? payment?.clientID;
    //             if (clientId) {
    //                 if (!groupedPayments[clientId]) {
    //                     groupedPayments[clientId] = [];
    //                 }
    //                 groupedPayments[clientId].push(payment);
    //             }
    //         });

    //         return groupedPayments;
    //     }
    //     const groupedPayments = groupPaymentsByClientId(data);
    //     const dataSource = Object.keys(groupedPayments).map(clientId => ({
    //         key: clientId,
    //         clientId: clientId,
    //         payments: groupedPayments[clientId],
    //     }));
    //     const [sortedDataSource, setSortedDataSource] = useState(dataSource);
    //     const calculateTotalOverdueAmount = (payments) => {
    //         let total = 0;
    //         for (const payment of payments) {
    //             if (payment.limitDateToPay < moment().valueOf()) {
    //                 total += getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;
    //             }
    //         }
    //         return total;
    //     };

    //     useEffect(() => {
    //         const sortedDataSource = [...dataSource];
    //         sortedDataSource.sort((a, b) => {
    //             const totalOverdueAmountA = calculateTotalOverdueAmount(a.payments);
    //             const totalOverdueAmountB = calculateTotalOverdueAmount(b.payments);
    //             return totalOverdueAmountB - totalOverdueAmountA;
    //         });
    //         setSortedDataSource(sortedDataSource);
    //     }, [dataSource]);
    //     const openModal = (payment) => {
    //         setSelectedPayment(payment);
    //         setShowModalTotal(true);
    //     };
    //     const openModalDue = (payment) => {
    //         setSelectedPaymentDue(payment);
    //         setShowModalTotalDue(true);
    //     };

    //     return <Row style={{ width: '100%' }}>
    //         {selectedPayment && (
    //             <Suspense fallback={<>Loading...</>}>
    //                 <PaymentTotalForClient
    //                     payment={selectedPayment}
    //                     close={() => setShowModalTotal(false)}
    //                     open={showModalTotal}

    //                     relation={showModalTotal}
    //                     userDocument={userDocument}
    //                 />
    //             </Suspense>

    //         )}
    //         {selectedPaymentDue && (
    //             <Suspense fallback={<>Loading...</>}>
    //                 <PaymentDueForClient
    //                     payment={selectedPaymentDue}
    //                     // visible={showModalTotal}
    //                     // onClose={closeModal}
    //                     close={() => setShowModalTotalDue(false)}
    //                     open={showModalTotalDue}
    //                     relation={showModalTotalDue}
    //                     userDocument={userDocument}
    //                 />
    //             </Suspense>

    //         )}
    //         <Col xs={24}>
    //             <ActionablePageHeader title="Pagos por cobrar de cada cliente" actions={
    //                 <Space align="center">

    //                     <div style={{ textAlign: 'right', marginBottom: '10px' }}>

    //                         <Button type="secondary" onClick={DownloadXLSX}>{downloadFile === 'pendigAllClients' ? <LoadingOutlined /> : <CloudDownloadOutlined />}Pagos pendientes</Button>
    //                     </div>
    //                 </Space>
    //             }
    //             />
    //         </Col>

    //         <Col xs={24} style={{ marginTop: '25px' }}>

    //             <Row justify="end" style={{ marginBottom: '15px', marginTop: '10px' }}>

    //                 <Col xl={12}>
    //                     <div className="d-flex flex-column">
    //                         <Input.Search defaultValue={filterText} onSearch={setfilterText} allowClear placeholder="Buscar cliente" />
    //                         <Space wrap size="small" style={{ marginTop: '10px' }}>
    //                             {team?.defaults?.allowPaymentsSetting && <Tag onClick={() => setfilterBy('past')} className={filterBy === 'past' ? 'activeTag clickable' : ' clickable'}>Atrasados</Tag>}
    //                         </Space>
    //                     </div>
    //                 </Col>
    //             </Row>

    //             {<ErrorBoundary>
    //                 <Suspense fallback={<LoadingOutlined />}>
    //                     <Table
    //                         dataSource={sortedDataSource.filter(p => {
    //                             return p.payments[0].client?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
    //                                 p.payments[0].client?.email?.toLowerCase().includes(filterText?.toLowerCase()) ||
    //                                 p.payments[0].fid?.toLowerCase().includes(filterText?.toLowerCase()) ||
    //                                 p.payments[0].clientId?.toLowerCase().includes(filterText?.toLowerCase())
    //                         })}
    //                         pageSize={pageSize}
    //                         loading={{
    //                             spinning: status === 'loading',
    //                             indicator: <LoadingOutlined style={{ color: '#8666FF' }} />,
    //                         }}
    //                         // pagination={{
    //                         //     pageSize: pageSize,
    //                         //     defaultCurrent: pageActive,
    //                         //     onChange: (p, ps) => {
    //                         //         setpageActive(p)
    //                         //         setpageSize(ps)
    //                         //     },
    //                         //     showSizeChanger: true,
    //                         //     pageSizeOptions: ['10', '20', '50', '100'],
    //                         //     showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} clientes`,
    //                         //     position: ['bottomCenter'],
    //                         //     defaultPageSize: 10,
    //                         //     showQuickJumper: false,
    //                         //     responsive: true,
    //                         //     total: dataSource.length ?? 1,
    //                         //     locale: { items_per_page: '/ página' }
    //                         // }}
    //                         pagination={{
    //                             pageSize: pageSize,
    //                             defaultCurrent: pageActive,
    //                             onChange: (p, ps) => {
    //                                 setpageActive(p);
    //                                 setpageSize(ps);
    //                             },
    //                             showSizeChanger: true,
    //                             pageSizeOptions: ['10', '20', '50', '100'],
    //                             showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} pagos`,

    //                             position: ['bottomCenter'],
    //                             defaultPageSize: 10,
    //                             showQuickJumper: false,
    //                             responsive: true,
    //                             total: filteredData.length,
    //                             locale: { items_per_page: '/ página' }
    //                         }}
    //                         onChange={(p, filters, sorter, extra) => {
    //                             if (extra.action === 'filter') {
    //                                 setdefaultClientSearch(extra.currentDataSource?.map((d) => d.client?.name ?? 'Sin cliente'))
    //                             }
    //                             setpageSize(p.pageSize)
    //                         }}
    //                         empty='No tienes solicitudes de pago por cobrar'
    //                         elementName="solicitud de pago"

    //                         emptyAction={() => {
    //                             setcreateRequest(true)
    //                         }}
    //                         columns={[
    //                             {
    //                                 title: <TableTitle title="Cliente" />,
    //                                 dataIndex: 'client',
    //                                 key: generateCode(5),
    //                                 filterMode: 'multiple',
    //                                 defaultFilteredValue: defaultClientSearch,
    //                                 onFilter: (value, record) => {
    //                                     return (record.client?.name ?? 'Sin cliente') === value
    //                                 },
    //                                 fixed: 'left',
    //                                 width: '15%',
    //                                 render: (text, record) => {
    //                                     const clientId = record?.clientId ?? record?.clientID

    //                                     return (
    //                                         <>
    //                                             <div className="d-flex flex-column">
    //                                                 <Row align='middle'>
    //                                                     <Typography.Text>{record.payments[0].client.name}</Typography.Text>
    //                                                 </Row>
    //                                                 <Typography.Text copyable type="secondary" style={{ fontSize: '10px' }}>{clientId}</Typography.Text>
    //                                             </div>
    //                                         </>

    //                                     )
    //                                 }
    //                             },

    //                             {
    //                                 title: <TableTitle title="Pagos vencidos" />,
    //                                 dataIndex: 'timestamp',
    //                                 width: '12%',
    //                                 key: generateCode(5),

    //                                 render: (text, record) => {
    //                                     var total = 0
    //                                     for (const payment of record.payments) {
    //                                         if (payment.limitDateToPay < moment().valueOf()) {
    //                                             total++
    //                                         }
    //                                     }
    //                                     return (total > 0 ?
    //                                         <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
    //                                             <Button
    //                                                 style={{
    //                                                     backgroundColor: '#F9DADA',
    //                                                     color: '#7D2828',
    //                                                     borderColor: '#F9DADA',
    //                                                     fontWeight: 400,
    //                                                 }}
    //                                                 className=""
    //                                                 onClick={() => openModalDue(record)}
    //                                                 onMouseEnter={(e) => (e.target.style.color = '#000')}
    //                                                 onMouseLeave={(e) => (e.target.style.color = '#7D2828')}
    //                                             >
    //                                                 {total}
    //                                             </Button>


    //                                         </Row> : <></>
    //                                     )
    //                                 }
    //                             },

    //                             {
    //                                 title: <TableTitle title="Monto total de pagos vencidos" />,
    //                                 dataIndex: 'amount',
    //                                 align: 'right',
    //                                 width: '12%',
    //                                 key: generateCode(5),

    //                                 onFilter: (amount, record) => record.amount.indexOf(amount) === 0,
    //                                 render: (text, record) => {
    //                                     const paymentSums = {};
    //                                     for (const payment of record.payments.filter(p => p.limitDateToPay < moment().valueOf())) {

    //                                         const currency = payment.currency.toLowerCase()
    //                                         const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total
    //                                         // const amount = payment.amount // getitemsAmounts({items: payment.items ?' payment.internlaITems, useDiuscount: })
    //                                         if (!paymentSums[currency]) {
    //                                             paymentSums[currency] = amount;
    //                                         } else if (paymentSums[currency]) {
    //                                             paymentSums[currency] += amount;
    //                                         }
    //                                     }
    //                                     const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
    //                                         return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
    //                                     });
    //                                     return (
    //                                         <>
    //                                             <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => { }}>
    //                                                 {currencySumStrings.join(', ')}
    //                                             </Typography.Text>

    //                                         </>
    //                                     );

    //                                 }
    //                             },
    //                             {
    //                                 title: <TableTitle title="Cantidad de pagos en espera" />,
    //                                 dataIndex: 'status',
    //                                 key: generateCode(5),
    //                                 width: '12%',
    //                                 ellipsis: true,
    //                                 render: (text, record) => {
    //                                     // let expiredPaymentAmount = 0; // Inicializa el monto del pago vencido en cero
    //                                     let total = 0
    //                                     for (const payment of record.payments) {
    //                                         if (payment.limitDateToPay < moment().valueOf()) {
    //                                             // expiredPaymentAmount += 1
    //                                         } else {
    //                                             total += 1
    //                                         }
    //                                     }
    //                                     return <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
    //                                         {total > 0 && <Button style={{ fontWeight: 500 }}
    //                                             className=""
    //                                             onClick={() => openModal(record)}>
    //                                             {total}
    //                                         </Button>}

    //                                     </Row>
    //                                 }


    //                             },
    //                             {
    //                                 title: <TableTitle title="Total en espera" />,
    //                                 dataIndex: 'amount',
    //                                 align: 'right',
    //                                 width: '12%',
    //                                 key: generateCode(5),
    //                                 render: (text, record) => {
    //                                     const paymentSums = {};

    //                                     // let expiredPaymentAmount = 0; // Inicializa el monto del pago vencido en cero

    //                                     for (const payment of record.payments) {
    //                                         const currency = payment.currency.toLowerCase();
    //                                         const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

    //                                         if (payment.limitDateToPay < moment().valueOf()) {
    //                                             // expiredPaymentAmount += amount;
    //                                         } else {
    //                                             if (!paymentSums[currency]) {
    //                                                 paymentSums[currency] = amount;
    //                                             } else {
    //                                                 paymentSums[currency] += amount;
    //                                             }
    //                                         }
    //                                     }

    //                                     const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
    //                                         return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
    //                                     });

    //                                     return (
    //                                         <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => { }}>
    //                                             {currencySumStrings.join(', ')}
    //                                         </Typography.Text>
    //                                     );
    //                                 }

    //                             },
    //                         ]
    //                         }
    //                         onPageChange={(p) => {
    //                             setpageActive(p)
    //                         }}
    //                         defaultPage={pageActive}
    //                     />
    //                 </Suspense >
    //             </ErrorBoundary >}
    //         </Col >
    //     </Row >
    // }
    const RenderForClient = () => {
        const [showModalTotal, setShowModalTotal] = useState(false)
        const [selectedPayment, setSelectedPayment] = useState(false);
        const [selectedPaymentDue, setSelectedPaymentDue] = useState(false);
        const [showModalTotalDue, setShowModalTotalDue] = useState(false)
        // const [originalData, setOriginalData] = useState([]);
        const [filteredData, /*setFilteredData*/] = useState([]);
        const [downloadFile, setDownloadFile] = useState(null)
        const DownloadXLSX = async ({ endpoint = ``, type = "pendigAllClients" }) => {
            try {
                setDownloadFile('pendigAllClients')
                await SignedInternalAPIRequest({
                    clientId: null,

                }, `exportFiles/v1/forClients`, auth.currentUser, { isFile: true, fileName: `clientes-${moment().format('DD/MM/YYYY')}`, fileExtension: 'xlsx' }, 'POST')
                setDownloadFile(false)

            } catch (error) {
                message.error('Error al descargar el archivo')
                setDownloadFile(false)
            }
        }
        const groupPaymentsByClientId = (data) => {
            const groupedPayments = {};

            data.forEach(payment => {
                const clientId = payment?.clientId ?? payment?.clientID;
                if (clientId) {
                    if (!groupedPayments[clientId]) {
                        groupedPayments[clientId] = [];
                    }
                    groupedPayments[clientId].push(payment);
                }
            });

            return groupedPayments;
        }
        const groupedPayments = groupPaymentsByClientId(data);
        const dataSource = Object.keys(groupedPayments).map(clientId => ({
            key: clientId,
            clientId: clientId,
            payments: groupedPayments[clientId],
        }));
        const calculateTotalOverdueAmount = (payments) => {
            let total = 0;
            for (const payment of payments) {
                if (payment.limitDateToPay < moment().valueOf()) {
                    total += getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;
                }
            }
            return total;
        }


        //FUNCTIONS FOR SORTER
        function getMaxDiasVencidos(record) {
            const overduePayments = record.payments.filter(p => p.limitDateToPay < moment().valueOf());
            let maxDiasVencidos = 0;
            overduePayments.forEach(payment => {
                const today = moment();
                const diasVencidos = today.diff(payment.limitDateToPay, 'days');

                if (diasVencidos > maxDiasVencidos) {
                    maxDiasVencidos = diasVencidos;
                }
            });
            return maxDiasVencidos;
        }
        function getTotalPagosVencidos(record) {
            let total = 0;
            for (const payment of record.payments) {
                if (payment.limitDateToPay < moment().valueOf()) {
                    total++;
                }
            }
            return total;
        }


        function getTotalMontoPagosVencidos(record) {
            let totalMontoPagosVencidos = 0;
            const pagosVencidos = record.payments.filter(p => p.limitDateToPay < moment().valueOf());
            for (const payment of pagosVencidos) {
                const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

                totalMontoPagosVencidos += amount;
            }

            return totalMontoPagosVencidos;
        }

        function getTotalEnEspera(record) {
            const paymentSums = {};
            for (const payment of record.payments) {
                const currency = payment.currency.toLowerCase();
                const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

                if (payment.limitDateToPay >= moment().valueOf()) {
                    if (!paymentSums[currency]) {
                        paymentSums[currency] = amount;
                    } else {
                        paymentSums[currency] += amount;
                    }
                }
            }

            let totalEnEspera = 0;
            for (const currency in paymentSums) {
                totalEnEspera += paymentSums[currency];
            }

            return totalEnEspera;
        }

        function getCantidadPagosEnEspera(record) {
            let cantidadPagosEnEspera = 0;
            for (const payment of record.payments) {
                if (payment.limitDateToPay >= moment().valueOf()) {
                    cantidadPagosEnEspera += 1;
                }
            }
            return cantidadPagosEnEspera;
        }

        const sortedDataSource = (dataSource.sort((a, b) => {
            const totalOverdueAmountA = calculateTotalOverdueAmount(a.payments);
            const totalOverdueAmountB = calculateTotalOverdueAmount(b.payments);
            return totalOverdueAmountB - totalOverdueAmountA;
        }))






        // OPEN MODALS
        const openModal = (payment) => {
            setSelectedPayment(payment);
            setShowModalTotal(true);
        };
        const openModalDue = (payment) => {
            // console.log(payment)
            setSelectedPaymentDue(payment);
            setShowModalTotalDue(true);
        };

        return <Row style={{ width: '100%' }}>
            {selectedPayment && (
                <Suspense fallback={<>Loading...</>}>
                    <PaymentTotalForClient
                        payment={selectedPayment}
                        close={() => setShowModalTotal(false)}
                        open={showModalTotal}

                        relation={showModalTotal}
                        userDocument={userDocument}
                    />
                </Suspense>

            )}
            {selectedPaymentDue && (
                <Suspense fallback={<>Loading...</>}>
                    <PaymentDueForClient
                        payment={selectedPaymentDue}
                        // visible={showModalTotal}
                        // onClose={closeModal}
                        close={() => setShowModalTotalDue(false)}
                        open={showModalTotalDue}
                        relation={showModalTotalDue}
                        userDocument={userDocument}
                    />
                </Suspense>

            )}
            <Col xs={24}>
                <ActionablePageHeader title="Pagos por cobrar de cada cliente" actions={
                    <Space align="center">

                        <div style={{ textAlign: 'right', marginBottom: '10px' }}>

                            <Button type="secondary" onClick={DownloadXLSX}>{downloadFile === 'pendigAllClients' ? <LoadingOutlined /> : <CloudDownloadOutlined />}Descargar pagos pendientes</Button>
                        </div>
                    </Space>
                }
                />
            </Col>

            <Col xs={24} style={{ marginTop: '25px' }}>

                <Row justify="end" style={{ marginBottom: '15px', marginTop: '10px' }}>

                    <Col xl={12}>
                        <div className="d-flex flex-column">
                            <Input.Search defaultValue={filterText} onSearch={setfilterText} allowClear placeholder="Buscar cliente" />
                            <Space wrap size="small" style={{ marginTop: '10px' }}>
                                {team?.defaults?.allowPaymentsSetting && <Tag onClick={() => setfilterBy('past')} className={filterBy === 'past' ? 'activeTag clickable' : ' clickable'}>Atrasados</Tag>}
                            </Space>
                        </div>
                    </Col>
                </Row>

                {<ErrorBoundary>
                    <Suspense fallback={<LoadingOutlined />}>
                        <Table
                            //TODO: 
                            dataSource={sortedDataSource.filter(p => {
                                return p.payments[0].client?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.payments[0].client?.email?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.payments[0].fid?.toLowerCase().includes(filterText?.toLowerCase()) ||
                                    p.payments[0].clientId?.toLowerCase().includes(filterText?.toLowerCase())
                            })}
                            pageSize={pageSize}
                            loading={{
                                spinning: status === 'loading',
                                indicator: <LoadingOutlined style={{ color: '#8666FF' }} />,
                            }}
                            // pagination={{
                            //     pageSize: pageSize,
                            //     defaultCurrent: pageActive,
                            //     onChange: (p, ps) => {
                            //         setpageActive(p)
                            //         setpageSize(ps)
                            //     },
                            //     showSizeChanger: true,
                            //     pageSizeOptions: ['10', '20', '50', '100'],
                            //     showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} clientes`,
                            //     position: ['bottomCenter'],
                            //     defaultPageSize: 10,
                            //     showQuickJumper: false,
                            //     responsive: true,
                            //     total: dataSource.length ?? 1,
                            //     locale: { items_per_page: '/ página' }
                            // }}
                            pagination={{
                                pageSize: pageSize,
                                defaultCurrent: pageActive,
                                onChange: (p, ps) => {
                                    setpageActive(p);
                                    setpageSize(ps);
                                },
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} pagos`,

                                position: ['bottomCenter'],
                                defaultPageSize: 10,
                                showQuickJumper: false,
                                responsive: true,
                                total: filteredData.length,
                                locale: { items_per_page: '/ página' }
                            }}
                            onChange={(p, filters, sorter, extra) => {
                                if (extra.action === 'filter') {
                                    setdefaultClientSearch(extra.currentDataSource?.map((d) => d.client?.name ?? 'Sin cliente'))
                                }
                                setpageSize(p.pageSize)
                            }}
                            empty='No tienes solicitudes de pago por cobrar'
                            elementName="solicitud de pago"

                            emptyAction={() => {
                                setcreateRequest(true)
                            }}
                            columns={[
                                {
                                    title: <TableTitle title="Cliente" />,
                                    dataIndex: 'client',
                                    key: generateCode(5),
                                    filterMode: 'multiple',
                                    defaultFilteredValue: defaultClientSearch,
                                    onFilter: (value, record) => {
                                        return (record.client?.name ?? 'Sin cliente') === value
                                    },
                                    fixed: 'left',
                                    width: '15%',
                                    render: (text, record) => {
                                        const clientId = record?.clientId ?? record?.clientID

                                        return (
                                            <>
                                                <div className="d-flex flex-column">
                                                    <Row align='middle'>
                                                        <Typography.Text>{record.payments[0].client.name}</Typography.Text>
                                                    </Row>
                                                    <Typography.Text type="secondary" style={{ fontSize: '10px' }}>{record.payments[0].client.company}</Typography.Text>
                                                    <Typography.Text copyable type="secondary" style={{ fontSize: '10px' }}>{clientId}</Typography.Text>
                                                    {record.payments[0].client?.defaults?.limitDaysToPay ? <Typography.Text type="secondary" style={{ fontSize: '10px' }}>Días límites de crédito: {record.payments[0].client?.defaults?.limitDaysToPay}</Typography.Text> : <></>}</div>
                                            </>

                                        )
                                    }
                                },
                                {
                                    title: <TableTitle title="Vencido" />,
                                    dataIndex: 'client',
                                    key: generateCode(5),
                                    filterMode: 'multiple',
                                    defaultFilteredValue: defaultClientSearch,
                                    // onFilter: (value, record) => {
                                    //     return (record.client?.name ?? 'Sin cliente') === value
                                    // },
                                    fixed: 'left',
                                    width: '15%',
                                    sorter: (a, b) => {
                                        const maxDiasVencidosA = getMaxDiasVencidos(a);
                                        const maxDiasVencidosB = getMaxDiasVencidos(b);
                                        return maxDiasVencidosA - maxDiasVencidosB;
                                    },
                                    render: (text, record) => {
                                        const overduePayments = record.payments.filter(p => p.limitDateToPay < moment().valueOf());
                                        let maxDiasVencidos = 0;
                                        overduePayments.forEach(payment => {
                                            const today = moment();
                                            const diasVencidos = today.diff(payment.limitDateToPay, 'days');

                                            if (diasVencidos > maxDiasVencidos) {
                                                maxDiasVencidos = diasVencidos;
                                            }
                                        });

                                        return (
                                            <>
                                                <Typography.Text type='secondary' align='middle'>
                                                    {maxDiasVencidos} {maxDiasVencidos === 1 ? 'día' : 'días'}
                                                </Typography.Text>
                                            </>
                                        );
                                    }

                                    // render: (text, record) => {
                                    //     const paymentDue = record.payments.filter(p => p.limitDateToPay < moment().valueOf())
                                    //     console.log('diasVencidos', paymentDue[0].limitDateToPay)
                                    //     const today = moment();
                                    //     const diasVencidos = today.diff(paymentDue[0].limitDateToPay, 'days');
                                    //     console.log('ACA', diasVencidos)
                                    //     return (
                                    //         <>
                                    //             <Row align='middle'>
                                    //                 <Typography.Text type='secondary'>{diasVencidos}</Typography.Text>

                                    //                 {/* <Typography.Text>{record.payments[0].client.name}</Typography.Text> */}
                                    //             </Row>
                                    //         </>

                                    //     )
                                    // }
                                },
                                {
                                    title: <TableTitle title="Pagos vencidos" />,
                                    dataIndex: 'timestamp',
                                    width: '12%',
                                    key: generateCode(5),
                                    sorter: (a, b) => {
                                        const totalA = getTotalPagosVencidos(a);
                                        const totalB = getTotalPagosVencidos(b);
                                        return totalA - totalB;
                                    },
                                    render: (text, record) => {
                                        const total = getTotalPagosVencidos(record);
                                        return (
                                            total > 0 ?
                                                <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: '#F9DADA',
                                                            color: '#7D2828',
                                                            borderColor: '#F9DADA',
                                                            fontWeight: 400,
                                                        }}
                                                        className=""
                                                        onClick={() => openModalDue(record)}
                                                        onMouseEnter={(e) => (e.target.style.color = '#000')}
                                                        onMouseLeave={(e) => (e.target.style.color = '#7D2828')}
                                                    >
                                                        {total}
                                                    </Button>
                                                </Row> :
                                                <></>
                                        );
                                    }
                                },

                                // {
                                //     title: <TableTitle title="Pagos vencidos" />,
                                //     dataIndex: 'timestamp',
                                //     width: '12%',
                                //     key: generateCode(5),

                                //     render: (text, record) => {
                                //         var total = 0
                                //         for (const payment of record.payments) {
                                //             if (payment.limitDateToPay < moment().valueOf()) {
                                //                 total++
                                //             }
                                //         }
                                //         return (total > 0 ?
                                //             <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                //                 <Button
                                //                     style={{
                                //                         backgroundColor: '#F9DADA',
                                //                         color: '#7D2828',
                                //                         borderColor: '#F9DADA',
                                //                         fontWeight: 400,
                                //                     }}
                                //                     className=""
                                //                     onClick={() => openModalDue(record)}
                                //                     onMouseEnter={(e) => (e.target.style.color = '#000')}
                                //                     onMouseLeave={(e) => (e.target.style.color = '#7D2828')}
                                //                 >
                                //                     {total}
                                //                 </Button>


                                //             </Row> : <></>
                                //         )
                                //     }
                                // },

                                // {
                                //     title: <TableTitle title="Monto total de pagos vencidos" />,
                                //     dataIndex: 'amount',
                                //     align: 'right',
                                //     width: '12%',
                                //     key: generateCode(5),

                                //     onFilter: (amount, record) => record.amount.indexOf(amount) === 0,
                                //     render: (text, record) => {
                                //         const paymentSums = {};
                                //         for (const payment of record.payments.filter(p => p.limitDateToPay < moment().valueOf())) {

                                //             const currency = payment.currency.toLowerCase()
                                //             const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total
                                //             // const amount = payment.amount // getitemsAmounts({items: payment.items ?' payment.internlaITems, useDiuscount: })
                                //             if (!paymentSums[currency]) {
                                //                 paymentSums[currency] = amount;
                                //             } else if (paymentSums[currency]) {
                                //                 paymentSums[currency] += amount;
                                //             }
                                //         }
                                //         const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
                                //             return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
                                //         });
                                //         return (
                                //             <>
                                //                 <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => { }}>
                                //                     {currencySumStrings.join(', ')}
                                //                 </Typography.Text>

                                //             </>
                                //         );

                                //     }
                                //},
                                {
                                    title: <TableTitle title="Monto total de pagos vencidos" />,
                                    dataIndex: 'amount',
                                    align: 'right',
                                    width: '12%',
                                    key: generateCode(5),
                                    onFilter: (amount, record) => record.amount.indexOf(amount) === 0,
                                    sorter: (a, b) => {
                                        const totalA = getTotalMontoPagosVencidos(a);
                                        const totalB = getTotalMontoPagosVencidos(b);
                                        return totalA - totalB;
                                    },
                                    render: (text, record) => {
                                        const paymentSums = {};
                                        for (const payment of record.payments.filter(p => p.limitDateToPay < moment().valueOf())) {
                                            const currency = payment.currency.toLowerCase();
                                            const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

                                            if (!paymentSums[currency]) {
                                                paymentSums[currency] = amount;
                                            } else {
                                                paymentSums[currency] += amount;
                                            }
                                        }

                                        const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
                                            return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
                                        });

                                        return (
                                            <>
                                                <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => { }}>
                                                    {currencySumStrings.join(', ')}
                                                </Typography.Text>
                                            </>
                                        );
                                    }
                                },

                                // {
                                //     title: <TableTitle title="Cantidad de pagos en espera" />,
                                //     dataIndex: 'status',
                                //     key: generateCode(5),
                                //     width: '12%',
                                //     ellipsis: true,
                                //     render: (text, record) => {
                                //         // let expiredPaymentAmount = 0; // Inicializa el monto del pago vencido en cero
                                //         let total = 0
                                //         for (const payment of record.payments) {
                                //             if (payment.limitDateToPay < moment().valueOf()) {
                                //                 // expiredPaymentAmount += 1
                                //             } else {
                                //                 total += 1
                                //             }
                                //         }
                                //         return <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                //             {total > 0 && <Button style={{ fontWeight: 500 }}
                                //                 className=""
                                //                 onClick={() => openModal(record)}>
                                //                 {total}
                                //             </Button>}

                                //         </Row>
                                //     }


                                // },
                                {
                                    title: <TableTitle title="Cantidad de pagos en espera" />,
                                    dataIndex: 'status',
                                    key: generateCode(5),
                                    width: '12%',
                                    ellipsis: true,
                                    sorter: (a, b) => {
                                        const cantidadA = getCantidadPagosEnEspera(a);
                                        const cantidadB = getCantidadPagosEnEspera(b);
                                        return cantidadB - cantidadA; // Ordenar de mayor a menor cantidad
                                    },
                                    render: (text, record) => {
                                        let total = 0;
                                        for (const payment of record.payments) {
                                            if (payment.limitDateToPay < moment().valueOf()) {
                                                // expiredPaymentAmount += 1
                                            } else {
                                                total += 1;
                                            }
                                        }
                                        return (
                                            <Row wrap className="d-flex flex-column" style={{ width: 'auto' }}>
                                                {total > 0 && (
                                                    <Button
                                                        style={{ fontWeight: 500 }}
                                                        className=""
                                                        onClick={() => openModal(record)}
                                                    >
                                                        {total}
                                                    </Button>
                                                )}
                                            </Row>
                                        );
                                    }
                                },

                                // {
                                //     title: <TableTitle title="Total en espera" />,
                                //     dataIndex: 'amount',
                                //     align: 'right',
                                //     width: '12%',
                                //     key: generateCode(5),
                                //     render: (text, record) => {
                                //         const paymentSums = {};

                                //         // let expiredPaymentAmount = 0; // Inicializa el monto del pago vencido en cero

                                //         for (const payment of record.payments) {
                                //             const currency = payment.currency.toLowerCase();
                                //             const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

                                //             if (payment.limitDateToPay < moment().valueOf()) {
                                //                 // expiredPaymentAmount += amount;
                                //             } else {
                                //                 if (!paymentSums[currency]) {
                                //                     paymentSums[currency] = amount;
                                //                 } else {
                                //                     paymentSums[currency] += amount;
                                //                 }
                                //             }
                                //         }

                                //         const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
                                //             return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
                                //         });

                                //         return (
                                //             <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => { }}>
                                //                 {currencySumStrings.join(', ')}
                                //             </Typography.Text>
                                //         );
                                //     }

                                // },
                                {
                                    title: <TableTitle title="Total en espera" />,
                                    dataIndex: 'amount',
                                    align: 'right',
                                    width: '12%',
                                    key: generateCode(5),
                                    sorter: (a, b) => {
                                        const totalA = getTotalEnEspera(a);
                                        const totalB = getTotalEnEspera(b);
                                        return totalB - totalA; // Ordenar de mayor a menor total en espera
                                    },
                                    render: (text, record) => {
                                        const paymentSums = {};
                                        for (const payment of record.payments) {
                                            const currency = payment.currency.toLowerCase();
                                            const amount = getItemsAmounts(payment.items ?? payment.internalItems, payment.paidIn === 'bank').total;

                                            if (payment.limitDateToPay < moment().valueOf()) {
                                                // expiredPaymentAmount += amount;
                                            } else {
                                                if (!paymentSums[currency]) {
                                                    paymentSums[currency] = amount;
                                                } else {
                                                    paymentSums[currency] += amount;
                                                }
                                            }
                                        }

                                        const currencySumStrings = Object.entries(paymentSums).map(([currency, sum]) => {
                                            return `${returnCurrencyValue(sum)} ${currency.toUpperCase()}`;
                                        });

                                        return (
                                            <Typography.Text style={{ fontWeight: 500 }} className="" onClick={() => { }}>
                                                {currencySumStrings.join(', ')}
                                            </Typography.Text>
                                        );
                                    }
                                }

                            ]
                            }
                            onPageChange={(p) => {
                                setpageActive(p)
                            }}
                            defaultPage={pageActive}
                        />
                    </Suspense >
                </ErrorBoundary >}
            </Col >
        </Row >
    }
    return <Row justify='center' >
        {registerPayment && <Suspense>
            <RegisterPaymentModal open={registerPayment} close={() => setRegisterPayment(false)} />
        </Suspense>}
        {notifyMultiple && <Suspense>
            <NotifyMultiple open={notifyMultiple} close={() => setNotifyMultiple(false)} userDocument={userDocument} />
        </Suspense>}
        {selectClient && <Suspense>
            <ClientsListModal importFromStripe={false} open={selectClient} userDocument={userDocument} close={() => setSelectClient(false)} onSelect={async (c) => {
                try {
                    await updateDoc(doc(fs, 'payments', selectClient?.id), {
                        clientID: c.id,
                        clientId: c.id,
                        client: {
                            ...c
                        },
                        clientEdited: true
                    })
                    message.success('Cliente asignado al pago')
                    setSelectClient(null)
                } catch (error) {
                    message.error(error?.message ?? 'Error al asignar cliente')
                }
            }} />
        </Suspense>}
        {confirmPayment && <ReviewPaymentModal open={confirmPayment} close={() => setconfirmPayment(false)} payment={confirmPayment} />}
        {createRequest && <Suspense fallback={<>loading..</>}>
            <NewPaymentRequest
                open={createRequest}
                close={(v) => {
                    setcreateRequest(false)
                }}
                userDocument={userDocument}
                data={{}} />

        </Suspense>}
        {createRecurring && <Suspense fallback={<>loading..</>}>
            <NewRecurringPayment edit={editRecurring?.id ? editRecurring : null} open={createRecurring} close={(v) => {
                if (v) {

                }
                setcreateRecurring(false)
                seteditRecurring(null)
            }} data={{}} userDocument={userDocument} type="recurringPayment" />
        </Suspense>}
        {composeEmail && <ComposeEmailModal close={() => setComposeEmail(null)} actionText={
            null
        } idRef={'payments'} data={{ ...composeEmail, payment: composeEmail }} userDocument={userDocument} open={composeEmail} element={composeEmail} subject={`Solicitud de pago por ${getItemsAmounts(composeEmail.items, composeEmail.paidIn === 'bank').totalString}`} readableType={'pago'} client={composeEmail.client ?? { name: 'Sin Cliente', email: '', }} />}
        <Col xs={24}>
            <DefaultHelpPageHeader userDocument={userDocument} title="Cobranza" description={"Solicita pagos a tus clientes de manera fácil y rápida."} />
        </Col>
        {/* {canCreatePayments && <RenderToReview userDocument={userDocument} setconfirmPayment={(v) => setconfirmPayment(v)} />} */}

        <Col xs={24} style={{ marginTop: '25px' }}>

            <Row justify="space-between" align="top">
                <Col xs={24} md={data.filter(p => p.status === 'review_requested').length === 0 ? 24 : 18} xl={data.filter(p => p.status === 'review_requested').length === 0 ? 24 : 18}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CardTotal
                            Titulo='Vencido'
                            amount={data.filter(p => {
                                var limitDate = p?.limitDaysToPay ? moment(p?.timestamp).add(p?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
                                var isLate = limitDate?.valueOf() < moment().valueOf()
                                return isLate
                            }).map(p => getItemsAmounts(p.items, p.paidIn === 'bank').total).reduce((a, b) => a + b, 0)}
                            description="Monto de pagos vencidos"
                            color='#DF6E5D'
                        />
                        <CardTotal
                            Titulo='Por cobrar'
                            amount={data.filter(p => {
                                var limitDate = p?.limitDaysToPay ? moment(p?.timestamp).add(p?.limitDaysToPay ?? 0, 'days').hours(team?.defaults?.limitHourToPay ?? '17') : null
                                var isLate = limitDate?.valueOf() < moment().valueOf()
                                return !isLate
                            }).map(p => getItemsAmounts(p.items, false).total).reduce((a, b) => a + b, 0)}
                            description="Monto de pagos por cobrar"
                        />
                        <CardTotal
                            Titulo='Por revisar'
                            amount={data.filter(p => {
                                return p.status === 'review_requested'
                            }).map(p => getItemsAmounts(p.items, p.paidIn === 'bank').total).reduce((a, b) => a + b, 0)}
                            description="Monto de pagos cobrados"
                        />
                    </div>
                    <Tabs onChange={() => { }} type="card">
                        <Tabs.TabPane tab={<Row align="middle">
                            <ClockCircleOutlined /><Typography.Text>Cobranza de cada cliente</Typography.Text>
                        </Row>} key="collectionForClient">
                            <RenderForClient />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<Row align="middle">
                            <BookOutlined /><Typography.Text> Cobranza</Typography.Text>
                        </Row>} key="collection">
                            <RenderPayments />
                        </Tabs.TabPane>


                    </Tabs>
                    {/* <RenderPayments /> */}
                </Col>
                <Col xs={24} md={8} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div style={{ marginLeft: '25px' }}>
                        <RenderToReview userDocument={userDocument} setconfirmPayment={(v) => setconfirmPayment(v)} payments={data.filter(p => p.status === 'review_requested')} />
                    </div>
                </Col>
            </Row>
        </Col>



    </Row >

}

export default CollectionsPayments;