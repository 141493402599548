import { Drawer } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import React, { useContext } from 'react';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import AddNewClientForm from '../clients/AddNewClientForm';
import AddNewExpense from '../expenses/AddNewExpense';
import CreateReceipt from '../invoices/CreateReceipt';
import NewPaymentRequest from '../payments/NewPaymentRequest';
import NewRecurringPayment from '../payments/NewRecurringPayment';
import AddNewService from '../services/AddNewService';

/**
 * It returns a component based on the type of form that is passed to it
 * @returns A component that renders a form based on the type of form that is passed to it.
 */


function CreateElementHolder({ userDocument: incomingUserDoc, open, close, type, data = {}, edit, refreshUserDocument, refreshChild }) {
    // const [userDocument, setuserDocument] = useState(incomingUserDoc)
    const { user: userDocument } = useContext(UserContext)
    const { team, getCurrentTeam } = useContext(TeamContext)
    // const [loading, setloading] = useState(refreshUserDocument)
    // const GetLatestUserDocument = async () => {
    //     setloading(true)
    //     const userDoc = await getDoc(doc(getFirestore(), 'users', incomingUserDoc.uid))
    //     setuserDocument(userDoc.data());
    //     setloading(false)
    // }



    /**
     * It returns a component based on the type of form that is passed to it
     * @returns A function that returns a component based on the type of form that is being requested.
     */
    const FormToRender = () => {
        switch (type) {
            case 'clients':
                return <AddNewClientForm close={close} userDocument={userDocument} edit={edit} />
            case 'expense':
                return <AddNewExpense close={close} userDocument={userDocument} edit={edit} />
            case 'services':
                return <AddNewService refreshChild={refreshChild ? (v) => refreshChild(v) : () => { }} close={close} userDocument={userDocument} edit={edit} useProps={true} />
            case 'payments':
                return <NewPaymentRequest close={close} userDocument={userDocument} data={data} />
            case 'recurringPayment':
                return <NewRecurringPayment edit={edit} close={close} userDocument={userDocument} data={data} />
            case 'receipt':
                return <CreateReceipt edit={edit} close={close} userDocument={userDocument} data={data} />
            // case 'recurringInvoice':
            //     return <CreateInvoice edit={edit} close={close} userDocument={userDocument} data={data} recurring={createInvoicesRecurring} />
            default:
                return <></>
        }
    }





    /* A component that renders a form based on the type of form that is passed to it. */

    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
            close()
            getCurrentTeam(team?.id)
        }} header={null} footer={null}>
            <ErrorBoundary>
                <div style={{ maxHeight: '100%', width: '100%' }}>
                    <FormToRender />
                </div>
            </ErrorBoundary>

        </Drawer>
    );
}

export default CreateElementHolder;