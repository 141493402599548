import { useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import Select from 'react-select'
import { generalreactSelectStyles } from "../../app/functions/styles";
import { paymentForms } from '../../app/functions/helpers';
import DefaultTableItemsLocal from '../tables/DefaultTableItemsLocal';

export const ConfirmSignInvoiceModal = ({ open, close, add, data, loading }) => {


    const [state, setstate] = useState({
        items: data?.items ?? [],
        internalItems: data?.internalItems ?? []
    })


    return (
        <Modal wrapClassName="pro-modal" footer={null} onCancel={() => close()} style={{ top: 15 }} destroyOnClose={true} visible={open} >
            <div style={{ padding: '20px 20px', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                <Typography.Title level={4}>Timbrar Factura </Typography.Title>
                <Typography.Text type="secondary">Puedes modificar la serie y el número de folio antes de timbrar</Typography.Text>
                <Form style={{ marginTop: '15px' }} layout="vertical" onFinish={(v) => {
                    add({ ...data, series: v.series, folio_number: Number(v.folio_number), items: state.items, internalItems: state.internalItems, payment_form: v.payment_form.value, payment_method: v.payment_method.value })
                    // close()
                }} initialValues={{
                    ...data,
                    payment_method: data.payment_method === 'PUE' ? { label: 'Pago en una sola exhibición', value: 'PUE' } : { label: 'Pago en parcialidades', value: 'PPD' },
                    payment_form: paymentForms.find(p => p.value === data.payment_form)
                }}>
                    <Form.Item label="Serie" name="series" rules={[{ required: true, message: 'Por favor añade la serie' }]}>
                        <Input className="pro-input" placeholder="" />
                    </Form.Item>
                    <Form.Item label="Folio" name="folio_number" rules={[{ required: true, message: 'Por favor añade el folio' }]}>
                        <Input className="pro-input" placeholder="" type="number" step='0.00000001' />
                    </Form.Item>
                    <Row gutter={10}>
                        <Col xs={12}>
                            <Form.Item label="Método de pago" name="payment_method" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                                <Select
                                    styles={generalreactSelectStyles}
                                    className="pro-select-input"
                                    options={[{ label: 'Pago en una sola exhibición', value: 'PUE' }, { label: 'Pago en parcialidades', value: 'PPD' }]}
                                    isLoading={false}

                                    placeholder="Método de pago"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label="Forma de pago" name="payment_form" rules={[{ required: true, message: 'Por favor añade este campo' }]}>
                                <Select
                                    styles={generalreactSelectStyles}
                                    className="pro-select-input"
                                    options={paymentForms}
                                    isLoading={false}

                                    placeholder="Forma de pago"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Typography.Title level={5}>Servicios</Typography.Title>
                    </Row>

                    <Col xs={24} style={{ marginBottom: '15px', maxHeight: '150px', overflowY: 'scroll' }}>
                        <DefaultTableItemsLocal avoidContainer={true} empty="Registra un impuesto" elementName="impuesto" data={state.internalItems} columns={[{
                            title: 'Nombre del servicio',
                            dataIndex: 'name',
                            //SET WIDTH TO 50%
                            width: '50%',
                            render: (text, record, i) => {
                                return <Typography.Text
                                    editable={{
                                        onChange: (v) => {
                                            setstate({
                                                ...state, items: state?.items?.map((item, index) => {
                                                    if (index === i) {
                                                        return { ...item, product: { ...item.product, description: v } }
                                                    }
                                                    return item
                                                }), internalItems: state?.internalItems?.map((item, index) => {
                                                    if (index === i) {
                                                        return { ...item, name: v }
                                                    }
                                                    return item
                                                })
                                            })
                                        }
                                    }}
                                    className=""
                                    type="secondary">{text}</Typography.Text>
                            }
                        },
                        {
                            title: 'Cantidad',
                            width: '50%',
                            dataIndex: 'quantity',
                            render: (text, record, i) => {
                                return <Typography.Text >{text}</Typography.Text>
                            }
                        },

                        ]} />

                    </Col>

                    <Row justify="center" style={{ marginTop: '25px' }}>
                        <Button loading={loading} htmlType="submit" type="primary">Timbrar Factura</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

