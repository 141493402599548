import { CloseOutlined, FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { Modal, Row, Typography, Button, Rate, Input, Col } from 'antd';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { generateCode } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import buenaCalificacion from '../../assets/images/buenaCalificacion.png';
import gracias from '../../assets/images/gracias.png';
import moment from 'moment';
const { TextArea } = Input;

function ReviewModal({ open, close }) {
    const desc = ['Terrible', 'Malo', 'Normal', 'Bueno', 'Excelente'];
    const { team } = useContext(TeamContext);
    const { user } = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [smile, setSmile] = useState(0);
    const [exitGig, setExitGig] = useState(0);
    const [step, setStep] = useState(1);
    const [state, setState] = useState({
        automation: '',
    })
    const propBD = async () => {
        await updateDoc(doc(getFirestore(), 'users', user?.uid), {
            review: moment().valueOf(),
        });
    }
    const handleReview = async (values) => {
        try {
            await setDoc(doc(getFirestore(), 'reviews', generateCode(10, 'review')), {
                name: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
                team: team?.id ?? '',
                alias: team?.brand?.alias ?? '',
                user: user?.uid ?? '',
                recomendacion: value ?? 0,
                customeSupport: smile ?? 0,
                exitGig: exitGig ?? 0,
                text: state.automation ?? '',
                fecha: moment().valueOf() ?? 0,
            });
            await propBD()
            handleNext()
        } catch (error) {
            console.log(error);
        }
    };


    const handleNext = () => {
        setStep(step + 1);
    };

    const customIcons = {
        1: <FrownOutlined />,
        2: <FrownOutlined />,
        3: <MehOutlined />,
        4: <SmileOutlined />,
        5: <SmileOutlined />,
    };

    const steps = [
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Row justify="center" style={{ margin: '10px' }}>
                                <img src={buenaCalificacion} alt="buenaCalificacion" style={{ width: '100px', height: '100px' }} />
                            </Row>
                            <Typography.Title level={4}>
                                Cuéntanos tu experiencia en gigstack
                            </Typography.Title>
                            <Typography.Text>
                                Te tomará menos de 2 minutos
                            </Typography.Text>
                            <Button type="primary" style={{ marginTop: '30px' }} onClick={handleNext}>
                                Comenzar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                ¿Con qué probabilidad recomendarías nuestros <br />servicios a un colega?    </Typography.Title>
                            <Rate
                                required
                                tooltips={desc}
                                value={value}
                                onChange={(val) => setValue(val)}
                                character={({ index }) => customIcons[index + 1]}
                                style={{ color: '#8666fe', fontSize: '34px', marginBottom: '40px' }}
                            />
                            <Button type="primary" style={{ marginTop: '30px' }} disabled={value === 0 ? true : false} onClick={handleNext}>
                                Siguiente
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                ¿Cómo te has sentido con el servicio de nuestro <br />equipo de customer support?  </Typography.Title>
                            <Rate
                                tooltips={desc}
                                value={smile}
                                onChange={(val) => setSmile(val)}
                                character={({ index }) => customIcons[index + 1]}
                                style={{ color: '#8666fe', fontSize: '34px', marginBottom: '40px' }}
                            />
                            <Button type="primary" style={{ marginTop: '30px' }} disabled={smile === 0 ? true : false} onClick={handleNext}>
                                Siguiente
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                Si Gigstack desapareciera el día de hoy, ¿qué tan mal<br /> te sentirías? </Typography.Title>
                            <Rate
                                tooltips={desc}
                                value={exitGig}
                                onChange={(val) => setExitGig(val)}
                                character={({ index }) => customIcons[index + 1]}
                                style={{ color: '#8666fe', fontSize: '34px', marginBottom: '40px' }}
                            />
                            <Button type="primary" style={{ marginTop: '30px' }} disabled={exitGig === 0 ? true : false} onClick={handleNext}>
                                Siguiente
                            </Button>
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                            propBD()
                        }}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Typography.Title level={4} style={{ marginBottom: '40px' }}>
                                ¿Qué otro proceso administrativo te parece importante<br /> automatizar?
                            </Typography.Title>
                            {/* <Form form={form} onFinish={handleReview}>
                                <Form.Item name="text"> */}
                            <TextArea rows={4} placeholder="Escribe tu comentario aquí" value={state.automation} onChange={(v) => setState({ ...state, automation: v.target.value })} />
                            {/* </Form.Item> */}
                            <Button type="primary" htmlType="submit" onClick={handleReview} style={{ marginTop: '30px' }}>
                                Enviar
                            </Button>
                            {/* </Form> */}
                        </Row>
                    </Col>
                </Row>
            ),
        },
        {
            content: (
                <Row justify="center" align="middle">
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <CloseOutlined className="clickable" onClick={() => {
                            close();
                        }} />
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Row style={{ margin: '10px 0px' }} className="d-flex flex-column">
                            <Row justify="center" style={{ margin: '10px' }}>
                                <img src={gracias} alt="gracias" style={{ width: '100px', height: '100px' }} />
                            </Row>
                            <Typography.Title level={4}>
                                ¡Gracias por tu respuesta!
                            </Typography.Title>
                            <Typography.Text style={{ marginBottom: '20px' }}>
                                Todas tus respuestas nos ayudan<br /> mucho a seguir creciendo y <br />mejorando
                            </Typography.Text>

                        </Row>
                    </Col>
                </Row>)
        }
    ];

    return (
        <Modal
            visible={open}
            footer={null}
            onCancel={() => close()}
            width={600}
            maskClosable={false}
            destroyOnClose
            closable={false}
        >
            {steps[step - 1].content}
        </Modal>
    );
}

export default ReviewModal;


