
import React from 'react';
import { Routes, Route, } from "react-router-dom";
import OAuthLayout from '../../widgets/auth/oauth/OAuthLayout';
import RedirectToBeta from '../../widgets/payments/RedirectToBeta';
// import AuthLayout from '../layouts/AuthLayout';
import MembershipsPage from '../pages/MembershipsPage';
import LoginWidget from '../../widgets/auth/LoginWidget';
import RegisterWidget from '../../widgets/auth/RegisterWidget';

function NotSignedRoutes() {
  return (
    <Routes>
      <Route
        path="/pay/:code"
        element={<RedirectToBeta autoInvoice={false} />}
      />
      <Route
        path="/autofactura"
        element={<RedirectToBeta autoInvoice={true} />}
      />
      <Route
        path="/memberships"
        element={<MembershipsPage authData={null} />}
      />
      <Route path="/oauth/:tab" element={<OAuthLayout type="login" />} />
      <Route path="/oauth" element={<OAuthLayout type="register" />} />
      <Route path="/login" element={<LoginWidget type="register" />} />
      <Route path="/register" element={<RegisterWidget type="register" />} />
      <Route path="/signup" element={<RegisterWidget type="register" />} />
      <Route exact path="*" element={<LoginWidget type="register" />} />
    </Routes>
  );

}

export default NotSignedRoutes;