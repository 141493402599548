import { Form, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
// import Select from 'react-select'
// import { generalreactSelectStyles } from '../../app/functions/styles';
import TeamContext from '../../context/teamcontext/TeamContext';




function PaymentLimitComponent({ updateState, userDocument, forceDefault, client = null }) {
    const { team } = useContext(TeamContext)

    const [defaultOption, setDefaultOption] = useState(
        client?.defaults?.limitDaysToPay ? client.defaults?.limitDaysToPay : typeof team?.defaults?.limitDaysToPay === 'object' ? team?.defaults?.limitDaysToPay?.value : team?.defaults?.limitDaysToPay ?? null
    )
    useEffect(() => {
        if (client?.defaults?.limitDaysToPay) {
            // console.log("SETTING NEW VALUE")
            setDefaultOption(client?.defaults?.limitDaysToPay)
        }
        //eslint-disable-next-line
    }, [client])


    // console.log('cliente', client)
    // console.log(client?.defaults?.limitDaysToPay ? client?.defaults?.limitDaysToPay : team?.defaults?.limitDaysToPay)
    const options = Array.from(Array(90).keys()).map((v) => ({
        value: v + 1,
        label: `${v + 1} día${v > 0 ? 's' : ''}`,
    }));
    return (
        <div className="d-flex flex-column" style={{
            marginBottom: '15px',
            backgroundColor: '#F5F5F5', padding: '15px', borderRadius: '10px'
        }}>
            {/* <Typography.Text type="secondary" style={{ fontSize: '12px', }}>Fecha límite para el pago <Tooltip title="Establece un límite de pago para esta solicitud."><QuestionCircleOutlined /></Tooltip></Typography.Text> */}
            <Form.Item label="Días límite de pago" name={"limitDaysToPay"} help={
                <Typography.Text type="secondary" style={{ fontSize: '10px', }}>Después de estos días, verás un aviso "Pago atrasado" en la lista. </Typography.Text>

            }>
                {/* <Select
                    // defaultValue={defaultOption}
                    value={{
                        value: defaultOption,
                        label: `${defaultOption + 1} día${defaultOption > 0 ? 's' : ''}`
                    }}
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    isMulti={false}
                    onChange={(v) => {
                        if (v) {
                            setDefaultOption(v.value)
                            updateState({ limitDaysToPay: v.value })
                        } else {
                            setDefaultOption(null)
                            updateState({ limitDaysToPay: null })
                        }
                    }}
                    options={
                        Array.from(Array(90).keys()).map((v) => { v = v + 1; return { value: v, label: `${v} día${v > 1 ? 's' : ''}` } })
                    }
                    // defaultValue={forceDefault ? { value: forceDefault, label: `${forceDefault} día${forceDefault > 1 ? 's' : ''}` } : (defaults?.limitDaysToPay ? defaults?.limitDaysToPay : null)}
                    isLoading={false}
                    placeholder="Días para marcar como atrasado"
                /> */}
                <Select
                    defaultValue={defaultOption}

                    options={options}
                    onChange={(v) => {
                        if (v) {
                            setDefaultOption(v)
                            updateState({ limitDaysToPay: v })
                        } else {
                            setDefaultOption(null)
                            updateState({ limitDaysToPay: null })
                        }
                    }}
                />
            </Form.Item>
        </div>
    );
}

export default PaymentLimitComponent;