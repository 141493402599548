import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Switch, Typography, message } from "antd";
import { useContext, useState } from "react";
import TeamContext from "../../context/teamcontext/TeamContext";
import HelperCard from "../cards/HelperCard";
import whmcsLogo from '../../assets/images/WHMCS.png'
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const WhmcsModal = ({ open, close }) => {

    const { team, getCurrentTeam } = useContext(TeamContext)
    const [formRef] = Form.useForm();

    const [state, setstate] = useState({
        livemode: team?.whmcs?.livemode ?? false,
        completed: team?.whmcs?.completed ?? false,
        automaticInvoicing: team?.whmcs?.automaticInvoicing ?? false,
    })
    const [loading, setLoading] = useState(false)

    const HandleWHMCSConnection = async (v) => {
        try {
            setLoading(true)
            await updateDoc(doc(getFirestore(), `teams/${team.id}`), {
                whmcs: {
                    ...state,
                    completed: true
                }
            })
            message.success('Se ha guardado la configuración')
            getCurrentTeam(team.id)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            message.error(error.message)
        }
    }

    const RenderWHMCSConnection = () => {

        return (
            <Form form={formRef} initialValues={{}} layout="vertical" onFinish={HandleWHMCSConnection} style={{ marginTop: '20px' }}>
                <Form.Item label="Modo pruebas" name="livemode">
                    <Row align="middle">
                        <Col xl={2}>
                            <Switch onChange={(v) => setstate({ ...state, livemode: !v })} checked={!state.livemode} />
                        </Col>
                        <Col xl={22}>
                            <Typography.Text type="secondary"> Cuando actives el modo prueba, procesaremos todos los pagos que recibas en este modo, así como las facturas y recibos de venta que se generen de estos pagos </Typography.Text>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="Facturación automática" name="automaticInvoicing" style={{ marginTop: '25px' }}>
                    <Row align="middle">
                        <Col xl={2}>
                            <Switch onChange={(v) => setstate({ ...state, automaticInvoicing: v })} checked={state.automaticInvoicing} />
                        </Col>
                        <Col xl={22}>
                            <Typography.Text type="secondary"> Siempre que tengas activada la automatización, realizaremos una factura por cada pago que recibas. </Typography.Text>
                        </Col>
                    </Row>
                </Form.Item>

                <Row justify='center'>
                    <Button loading={loading} type="primary" htmlType="submit">Guardar</Button>
                </Row>
            </Form>
        )
    }

    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4}>Integración con WHMCS</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                </Row>
                <Typography.Text type="secondary">Conecta tu cuenta de Whmcs a gigstack para procesar los pagos de tus clientes</Typography.Text>
                {team?.whmcs?.completed ? <RenderWHMCSConnection /> : <div className="d-flex flex-column">
                    <HelperCard openIntercom title="¿Necesitas esta integración?, escríbenos" description="Escríbenos, estaremos encantados de ayudarte a configurar WHMCS en tu cuenta" supportImageUrl={whmcsLogo} />
                    <Row justify='center' style={{ marginTop: '20px' }}>
                        <Button onClick={() => close()} type="primary">Cerrar</Button>
                    </Row>
                </div>}
            </div>
        </Modal>
    );
}

export default WhmcsModal;
