import { CloseOutlined, LoadingOutlined, LogoutOutlined, SaveOutlined } from '@ant-design/icons';
import { Modal, Row, Form, Typography, Button, Input, message, Tag, Checkbox, Collapse, Divider, Table } from 'antd';
import { logEvent } from 'firebase/analytics';
import { collection, doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useAnalytics, useAuth, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { ExtractVariablesFromText, generateCode } from '../../app/functions/helpers';
import HelperCard from '../cards/HelperCard';
import Select from 'react-select';
import { generalreactSelectStyles } from '../../app/functions/styles';
import TableTitle from '../tables/TableTitle';
import TeamContext from '../../context/teamcontext/TeamContext';
import { useUserPermissions } from '../../customHooks/userPermissions';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';


function HilosModal({ open, close, userDocument }) {
    const auth = useAuth()
    const { team, getCurrentTeam } = useContext(TeamContext)
    const analytics = useAnalytics()
    const [formRef] = Form.useForm();
    const [loadingTemplates, setLoadingTemplates] = useState(false)
    const [testConnectionLoading, setTestConnectionLoading] = useState(false)
    const [testConnectionResult, setTestConnectionResult] = useState(userDocument?.hilos?.ok ?? null)
    const [hilosTemplates, setHilosTemplates] = useState([])
    const messagingRef = collection(getFirestore(), 'teams', team.id, 'messaging')
    const { data } = useFirestoreCollectionData(messagingRef, {
        idField: 'documentId'
    })

    const { billingAccount } = useContext(BillingAccountContext)
    const { status, data: userData } = useFirestoreDocData(doc(getFirestore(), 'users', userDocument.uid));


    const [configTemplatesCollpase, setConfigTemplatesCollpase] = useState([])
    const { integrationsCompleted } = useUserPermissions({ user: userData, team, billingAccount })


    /**
     * It gets the templates from the database and sets them in the state
     */
    const getHilos = async () => {
        setLoadingTemplates(true)
        try {
            const data = await SignedInternalAPIRequest({

            }, 'hilosApp/v1/templates', auth.currentUser, {

            }, 'GET')
            setLoadingTemplates(false)
            setHilosTemplates(data.results)
        } catch (error) {
            message.error('Ocurrió un error al traer las plantillas')
            setLoadingTemplates(false)
        }

    }

    useEffect(() => {
        if (team?.hilos?.token && userDocument?.hilos?.okTimestamp && hilosTemplates?.length === 0) {
            getHilos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    /**
     * It makes a request to the Hilos API to get the list of templates, and if it succeeds, it updates the
     * user's document in Firestore to indicate that the connection is working
     */
    const TestHilosConnection = async () => {
        try {
            setTestConnectionLoading(true)
            const data = await SignedInternalAPIRequest({
                token: formRef.getFieldValue('token')
            }, 'hilosApp/v1/connect', auth.currentUser, {
            })
            if (data?.count !== null) {
                message.success('Conexión exitosa')
                setTestConnectionResult(true)
                setHilosTemplates(data.results)
                logEvent(analytics, 'hilosConnected', {});
            }
            setTestConnectionLoading(false)
            getCurrentTeam(team?.id)
        } catch (error) {
            setTestConnectionLoading(false)
            message.error(error.message)
        }
    }
    /**
     * It updates the user's document in the database with the new hilos object
     */
    const DisconnectHilos = async () => {
        try {
            await updateDoc(doc(getFirestore(), 'teams', team?.id), {
                hilos: {
                    keys: {
                        token: null,
                    },
                    ok: false,
                    completed: false,
                    okTimestamp: null
                }
            })
            getCurrentTeam(team?.id)
            setTestConnectionResult(false)
            setHilosTemplates([])
        } catch (error) {
            message.error(error.message ?? 'Ocurrió un error desconectando tu cuenta de Hilos');
        }
    }


    /**
     * It returns a React component that renders a Row, a Typography.Text and a HelperCard
     * @returns A React component.
     */
    const Header = () => {
        return <>
            <Row justify="space-between">
                <Typography.Title level={4}>Agrega tu cuenta de Hilos</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close()} />
            </Row>
            <Typography.Text type="secondary" >Consigue tus credenciales en <a href="https://app.hilos.io/dev/api-keys" target="_blank" rel="noreferrer">https://app.hilos.io/dev/api-keys</a>.</Typography.Text>
            <HelperCard title={userData?.hilos?.okTimestamp ? '¿Necesitas ayuda?' : "¿Necesitas ayuda para conectar tu cuenta?"} description="El equipo de hilos.io estará encantado de ayudarte!." supportImageUrl={'https://app.hilos.io/static/media/hilos_imago_opt.9b6761a6b7329d693e6c.webp'} onClick={() => {
                window.open('https://api.whatsapp.com/send?phone=5215592257050&text=Hi%2C%20I%20need%20help%20with%20my%20Hilos%20Account.', '_blank')
            }} />
        </>
    }

    /**
     * It returns an empty tag if the user has a token, otherwise it returns a form item with an input
     * @returns A Form.Item component with a label of "Token" and a name of "token" with a style of
     * marginTop: '20px' and rules of required: true and message: 'Agrega el token de tu cuenta'.
     */
    const TokenInput = () => {
        if (integrationsCompleted?.hilos) return <></>
        return <Form.Item label="Token" name="token" style={{ marginTop: '20px' }} rules={[{ required: true, message: 'Agrega el token de tu cuenta' }]}>
            <Input className="pro-input" placeholder="token" />
        </Form.Item>
    }

    /**
     * It returns a div with a collapsable panel that contains a list of templates that the user can
     * configure
    
     */
    const CurrentSelectedTemplates = () => {
        if (!integrationsCompleted?.hilos) {
            return <></>
        }
        return <div className='d-flex flex-column'>
            <Divider ><Typography.Text style={{ fontSize: '11px' }}>Configurar</Typography.Text></Divider>
            <Collapse defaultActiveKey={configTemplatesCollpase} onChange={setConfigTemplatesCollpase} ghost style={{ padding: '0px', margin: '0px' }}>
                <Collapse.Panel header={<Typography.Text style={{ marginTop: '20px' }} className="cLabel" >Configurar Plantillas ({data?.length})</Typography.Text>} key="1">
                    {data.map((m, inx) => {
                        return <div key={m.id} className="d-flex flex-column">
                            <Row justify="space-between" align='middle' style={{ marginTop: '20px' }}>
                                <Typography.Text style={{}} className="cLabel" >{m.useIn === 'payment-request' ? 'Solicitud de pago' : m.useIn === 'payment-remember' ? 'Recordatorio de pago' : m.useIn === 'new-receipt' ? 'Nuevo recibo' :
                                    m.useIn === 'reminder-receipt' ? 'Recordatorio de recibo' :
                                        '-'}</Typography.Text>
                                <div style={{
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#8666FF'
                                }}><Typography.Text style={{ fontSize: '10px', color: 'white' }}>{inx + 1}</Typography.Text></div>
                            </Row>
                            <div style={{ width: '100%', marginTop: '15px', backgroundColor: 'rgba(247,247,247,1)', padding: '10px', borderRadius: '10px' }}>
                                {m.headerVariables && <div key={generateCode(5)} style={{ backgroundImage: m.headerVariables?.url ? `url(${m.headerVariables?.url})` : 'url(https://app.hilos.io/static/media/template_image_placeholder.598186e394884ed7706cb89f7a0e76bd.svg)', width: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', flexDirection: 'column', height: '120px', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }} >
                                    <Row align="middle">
                                        <Form.Item name={`headerUrl-${m.useIn}`} style={{ maxWidth: '100%', margin: '0', marginRight: '5px' }}>
                                            <Input defaultValue={m?.headerVariables?.url} />
                                        </Form.Item>
                                        <Button type="ghost" icon={<SaveOutlined />}
                                            onClick={() => {
                                                updateDoc(doc(getFirestore(), `teams/${team?.id}/messaging/${m.useIn}`), {
                                                    headerVariables: {
                                                        ...m.headerVariables,
                                                        url: formRef.getFieldValue(`headerUrl-${m.useIn}`)
                                                    }
                                                })
                                            }}></Button>
                                    </Row>
                                </div>}
                                <Typography.Text type="secondary" style={{ fontSize: '12px', flex: 1, padding: '8px 0px', whiteSpace: 'pre-wrap' }}>{m.body}</Typography.Text>
                                <div style={{ marginBottom: '15px' }}></div>
                                <Divider></Divider>
                                <Typography.Text type="secondary">Variables en el cuerpo</Typography.Text>
                                <div style={{ marginBottom: '10px' }}></div>
                                {m.bodyVariables?.map((v) => {
                                    return <div key={generateCode(5)} >
                                        <Form.Item label={`Variable ${v.name}`}>
                                            <Select
                                                defaultValue={v.raw ?? null}
                                                styles={generalreactSelectStyles}
                                                options={[
                                                    {
                                                        label: 'Nombre del cliente',
                                                        value: 'client'
                                                    },
                                                    {
                                                        label: 'Total',
                                                        value: 'total'
                                                    },
                                                    {
                                                        label: 'Fecha',
                                                        value: 'date'
                                                    },
                                                    {
                                                        label: 'Enlace',
                                                        value: 'shortUrl'
                                                    }
                                                ]}
                                                isLoading={false}
                                                placeholder={v.name}
                                                onChange={async (value) => {
                                                    var variables = m.bodyVariables
                                                    var index = variables.findIndex((variable) => variable.name === v.name)


                                                    variables[index] = {
                                                        ...v,
                                                        value: value.value,
                                                        raw: value
                                                    }
                                                    await updateDoc(doc(getFirestore(), `teams/${team.id}/messaging/${m.useIn}`), {
                                                        bodyVariables: variables
                                                    })
                                                }}
                                            />
                                        </Form.Item>
                                    </div>


                                })}
                                <Typography.Text type="secondary">Botones</Typography.Text>
                                <div style={{ marginBottom: '10px' }}></div>
                                {m?.buttonsVariables?.map((v, inx) => {
                                    return <div key={generateCode(5)} className='d-flex flex-column' style={{ marginRight: '5px' }}>
                                        <Button style={{ fontSize: '10px' }}>{v.url ?? v.name}</Button>
                                        <Row align="middle" style={{ marginTop: '10px' }}>
                                            <Form.Item name={`button-${v.type}-${m.useIn}`} style={{ maxWidth: '80%', margin: '0', marginRight: '5px' }}>
                                                <Input defaultValue={v?.value} />
                                            </Form.Item>
                                            <Button type="ghost" icon={<SaveOutlined />}

                                                onClick={() => {
                                                    var buttons = m.buttonsVariables
                                                    buttons[inx].value = `${formRef.getFieldValue(`button-${v.type}-${m.useIn}`)}`
                                                    updateDoc(doc(getFirestore(), `teams/${team?.id}/messaging/${m.useIn}`), {
                                                        buttonsVariables: buttons
                                                    })
                                                }}></Button>
                                        </Row>
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </Collapse.Panel>
            </Collapse>
        </div>


    }


    /**
     * `HilosTemplates` is a function that returns a `<div>` element with a `<Collapse>` element inside
     * @returns A component that is a collapsible panel that contains a list of templates.
     */
    const HilosTemplates = () => {
        if (!integrationsCompleted?.hilos) {
            return <></>
        }
        return <>
            <Divider ><Typography.Text style={{ fontSize: '11px' }}>Plantillas</Typography.Text></Divider>
            <Table pagination={{ pageSize: 5 }} loading={loadingTemplates} locale={{
                emptyText: <Row justify="center">
                    {hilosTemplates?.length === 0 && <Row justify="center" >
                        <Button className='' loading={loadingTemplates} onClick={getHilos} type="primary">Cargar plantillas</Button>
                    </Row>}
                </Row>
            }} dataSource={hilosTemplates} columns={[
                {
                    title: <TableTitle title="Plantilla" />,
                    dataIndex: 'name',

                    render: (text, template) => {
                        const body = template?.components?.find((c) => c.type === 'BODY').text
                        return <div className="d-flex flex-column">
                            <Row justify="space-between">
                                <Typography.Text style={{ fontSize: '12px' }} className="strong">{text}</Typography.Text>
                                <Row>
                                    <Tag style={{ fontSize: '10px' }}>{template.language}</Tag>
                                    <Tag style={{ fontSize: '10px' }}>{template.category}</Tag>
                                </Row>
                            </Row>
                            <Typography.Text style={{ fontSize: '11px', width: '100%', color: '', whiteSpace: "pre-line" }} >{body}</Typography.Text>
                        </div>
                    }
                },



                {
                    title: <TableTitle title="Utilizar en" />,
                    dataIndex: 'name',

                    render: (text, template) => {
                        const paymentRequestSelectedFromdata = data?.findIndex((d) => d.id === template.id && d.useIn === 'payment-request') > -1
                        const paymentRememberSelectedFromdata = data?.findIndex((d) => d.id === template.id && d.useIn === 'payment-remember') > -1
                        const newReceipt = data?.findIndex((d) => d.id === template.id && d.useIn === 'new-receipt') > -1
                        const reminderReceipt = data?.findIndex((d) => d.id === template.id && d.useIn === 'reminder-receipt') > -1
                        const body = template.components?.find((c) => c.type === 'BODY').text
                        const a = ExtractVariablesFromText(body)
                        var vars = []
                        template.components?.forEach((c) => {
                            if (c.type === 'BODY') {
                                vars['bodyVariables'] = a?.map((v) => {
                                    return {
                                        name: `{{${v}}}`,
                                        value: ''
                                    }
                                })
                            } else if (c.type === 'HEADER') {
                                vars['headerVariables'] = {
                                    name: 'header',
                                    value: '',
                                    url: ''
                                }
                            } else if (c.type === 'BUTTONS') {
                                vars["buttonsVariables"] = c.buttons?.map((b) => {
                                    return {
                                        name: b.text,
                                        url: b.url ?? null,
                                        type: b.type,
                                        value: ''
                                    }
                                })

                            }
                        })
                        return <div className="d-flex flex-column">
                            <Checkbox onChange={async (v) => {
                                await setDoc(doc(getFirestore(), `teams/${team?.id}/messaging`, 'payment-request'), {
                                    id: template.id,
                                    internalId: template.id,
                                    useIn: 'payment-request',
                                    body: body,
                                    ...vars

                                })
                            }} value="payment-request" checked={paymentRequestSelectedFromdata} defaultChecked={
                                paymentRequestSelectedFromdata
                            }><Typography.Text type='secondary'>Solicitud de pago</Typography.Text></Checkbox>
                            <Checkbox style={{ marginLeft: 0 }} onChange={async (v) => {
                                await setDoc(doc(getFirestore(), `teams/${team?.id}/messaging`, 'payment-remember'), {
                                    id: template.id,
                                    internalId: template.id,
                                    useIn: 'payment-remember',
                                    body: body,
                                    ...vars

                                })
                            }} value="payment-request" checked={paymentRememberSelectedFromdata} defaultChecked={
                                paymentRememberSelectedFromdata
                            }><Typography.Text type='secondary'>Recordatorio de pago</Typography.Text></Checkbox>
                            <Checkbox style={{ marginLeft: 0 }} onChange={async (v) => {
                                await setDoc(doc(getFirestore(), `teams/${team?.id}/messaging`, 'new-receipt'), {
                                    id: template.id,
                                    internalId: template.id,
                                    useIn: 'new-receipt',
                                    body: body,
                                    ...vars

                                })
                            }} value="payment-request" checked={newReceipt} defaultChecked={
                                newReceipt
                            }><Typography.Text type='secondary'>Nuevo recibo</Typography.Text></Checkbox>
                            <Checkbox style={{ marginLeft: 0 }} onChange={async (v) => {
                                await setDoc(doc(getFirestore(), `teams/${team?.id}/messaging`, 'reminder-receipt'), {
                                    id: template.id,
                                    internalId: template.id,
                                    useIn: 'reminder-receipt',
                                    body: body,
                                    ...vars

                                })
                            }} value="payment-request" checked={reminderReceipt} defaultChecked={
                                reminderReceipt
                            }><Typography.Text type='secondary'>Recordatorio de recibo</Typography.Text></Checkbox>

                        </div>
                    }
                },

            ]} />
        </>

    }


    /**
     * It renders a connection status with Hilos
     * @returns A div with a row and a column.
     */
    const ConnectionResult = () => {
        if (!integrationsCompleted?.hilos) return <div></div>

        return <div style={{ width: '100%', padding: '10px', backgroundColor: 'rgba(247,247,247,1)', borderRadius: '8px', marginTop: '10px' }} className="d-flex flex-column">
            <Row justify="space-between">
                <div style={{ flex: 1 }} className="d-flex flex-column">
                    <Typography.Text style={{ fontSize: '12px' }}>Conexión con Hilos</Typography.Text>
                    <Typography.Text style={{ fontSize: '10px' }} type="secondary">{integrationsCompleted?.hilos ? `Conectado desde ${moment(team?.hilos?.connectedAt).format('DD/MM/yyyy HH:mm')}` : 'Prueba tu conexión para continuar'}</Typography.Text>
                    <Row style={{ marginTop: '10px' }}>
                        {!integrationsCompleted?.hilos && <Button loading={testConnectionLoading} onClick={TestHilosConnection} type="primary" size="small">Probar conexión</Button>}
                        {(!integrationsCompleted?.hilos) && <Button onClick={DisconnectHilos} type="ghost" size="small" danger style={{ marginLeft: '10px' }}>Desconectar</Button>}
                    </Row>
                </div>
                <Row >
                    <div
                        style={{
                            height: '5px',
                            width: '5px',
                            marginTop: '5px',
                            borderRadius: '50%',
                            backgroundColor: integrationsCompleted?.hilos ? '#52c41a' : '#f5222d'
                        }}
                    ></div>
                    {integrationsCompleted?.hilos && <LogoutOutlined style={{ marginLeft: '5px', fontSize: '10px', marginTop: '2px', cursor: 'pointer' }} onClick={DisconnectHilos} />}
                </Row>
            </Row>
            {testConnectionResult && <Typography>{testConnectionResult}</Typography>}
        </div>
    }


    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">

                <Header />

                <Form form={formRef} initialValues={{ ...team?.hilos }} layout="vertical" onFinish={TestHilosConnection} >

                    <ErrorBoundary>
                        {status === 'loading' ? <LoadingOutlined /> : <>
                            {!integrationsCompleted?.hilos && <TokenInput />}
                            <ConnectionResult />
                            <CurrentSelectedTemplates />
                            <HilosTemplates />
                        </>}
                    </ErrorBoundary>




                    {(integrationsCompleted?.hilos) ? <></> : <Row justify='center' style={{ marginTop: '10px' }}>
                        <Button loading={testConnectionLoading} type="primary" htmlType="submit">Conectar</Button>
                    </Row>}
                </Form>
            </div >
        </Modal >
    );
}

export default HilosModal;