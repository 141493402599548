import { useState, useContext, Suspense, useEffect } from 'react';
import { Layout, Row, Col, Space, Typography, Progress, Card, Collapse, Button, message } from 'antd';
import { FaStripe } from 'react-icons/fa';
import { AuditOutlined, CheckOutlined, LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { BsStripe } from 'react-icons/bs';
import { BiCreditCard } from 'react-icons/bi';
import { VscSymbolColor } from 'react-icons/vsc';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FiSettings } from 'react-icons/fi';
import { AiOutlineRobot } from 'react-icons/ai';
import IntegrationsContext from '../../context/integrationscontext/IntegrationsContext';
import FacturapiModal from '../../widgets/integrations/FacturapiModal';
import PaymentsListModal from '../../widgets/payments/PaymentsListModal';
import ServiceListModal from '../../widgets/services/ServicesListModal';
import BankModal from '../../widgets/integrations/BankModal';
import DefaultsConfigDrawer from '../../widgets/drawers/DefaultsConfigDrawer';
import TestInvoiceModal from '../../widgets/invoices/TestInvoiceModal';
import TeamContext from '../../context/teamcontext/TeamContext';
import { useTeamData } from '../../customHooks/useTeamData';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import ConectModal from '../../widgets/modals/ConectModal';
import { green } from '@ant-design/colors';
import StripeModal from '../../widgets/integrations/StripeModal';


const Onboarding = ({ changeRender, userDocument }) => {
  const { team, getCurrentTeam } = useContext(TeamContext)

  const { team: teamRt } = useTeamData({ teamId: team?.id })
  // const teamRt = team
  const integrationsTaxo = [
    {
      id: 'invoices',
      loading: false,
      disabled: false,
      required: true,
      title: "Conéctate al SAT",
      category: 'invoices',
      description: "Completa la información necesaria para poder emitir facturas",
      icon: <AuditOutlined style={{ fontSize: '30px', color: '#8666FF' }} />,
      showCloseIcon: false,
    },
    {
      id: 'testInvoice',
      loading: false,
      disabled: false,
      required: false,
      title: "Emite una factura de prueba",
      category: 'invoices',
      description: "Emite factura de prueba para verificar tus datos",
      icon: <IoDocumentTextOutline style={{ fontSize: '30px', color: '#8666FF' }} />,
      customMessage: "Primero debes conectarte con el SAT",
      showCloseIcon: true,
    },
  ]

  const integrationsData = [
    {
      id: 'stripeSetup',
      loading: false,
      disabled: false,
      required: true,
      title: "Conecta Stripe",
      category: 'stripe',
      description: "Conecta tu cuenta de Stripe para consultar los pagos de tus clientes",
      showCloseIcon: false,
      icon: <FaStripe style={{ fontSize: '40px', color: '#8666FF' }} />,
    },
    {
      id: 'invoices',
      loading: false,
      disabled: false,
      required: true,
      title: "Conéctate al SAT",
      category: 'invoices',
      description: "Completa la información necesaria para poder emitir facturas",
      icon: <AuditOutlined style={{ fontSize: '30px', color: '#8666FF' }} />,
      showCloseIcon: false,
    },
    {
      id: 'importStripeProducts',
      loading: false,
      disabled: false,
      required: false,
      title: "Importa los productos de Stripe",
      category: 'stripe',
      description: "Importaremos los productos que tienes en Stripe",
      icon: <FaStripe style={{ fontSize: '40px', color: '#8666FF' }} />,
      customMessage: "Primero debes completar la integración de Stripe",
      showCloseIcon: true,
    }
    ,
    {
      id: 'importStripePayments',
      loading: false,
      disabled: false,
      required: false,
      title: "Importa pagos y clientes de Stripe",
      description: "Importaremos los pagos y clientes que tienes en Stripe",
      category: 'stripe',
      icon: <BsStripe style={{ fontSize: '30px', color: '#8666FF' }} />,
      customMessage: "Primero debes completar la integración de Stripe",
      showCloseIcon: true,
    }
    ,
    {
      id: 'bank',
      loading: false,
      disabled: false,
      required: false,
      title: "Acepta transferecias bancarias",
      description: "Recibe transferencias electrónicas de tus clientes",
      icon: <BiCreditCard style={{ fontSize: '35px', color: '#8666FF' }} />,
      showCloseIcon: true,
    }
    ,
    {
      id: 'setupBrand',
      loading: false,
      disabled: false,
      required: false,
      title: "Configura la marca de tu empresa",
      description: "Agrega los colores y logotipo de tu marca",
      icon: <VscSymbolColor style={{ fontSize: '35px', color: '#8666FF' }} />,
      showCloseIcon: false,
    },
    {
      id: 'testInvoice',
      loading: false,
      disabled: false,
      required: false,
      title: "Emite una factura de prueba",
      category: 'invoices',
      description: "Emite factura de prueba para verificar tus datos",
      icon: <IoDocumentTextOutline style={{ fontSize: '30px', color: '#8666FF' }} />,
      customMessage: "Primero debes conectarte con el SAT",
      showCloseIcon: true,
    },
    {
      id: 'setDefaults',
      loading: false,
      disabled: false,
      required: true,
      category: 'automaticInvoicing',
      title: "Agrega los valores predeterminados",
      description: "Usaremos esta información por defecto para las automatizaciones",
      icon: <FiSettings style={{ fontSize: '30px', color: '#8666FF' }} />,
      showCloseIcon: false,
    },
    {
      id: 'automaticInvoicing',
      loading: false,
      disabled: false,
      required: false,
      title: "Facturación automática",
      category: 'automaticInvoicing',
      description: "Emitiremos facturas automáticamente cuando recibas un pago",
      icon: <AiOutlineRobot style={{ fontSize: '30px', color: '#8666FF' }} />,
      customMessage: "Primero debes completar la integración de Stripe",
      showCloseIcon: false,
    },
  ]

  const categories = [
    {
      id: 'stripe',
      title: 'Conecta tu cuenta de stripe',
      description: 'Con stripe podrás realizar facturas automáticas de tus pagos',
      icon: <></>
    },
    {
      id: 'invoices',
      title: 'Conectáte al SAT',
      description: 'Conecta tus sellos digitales y datos fiscales para poder emitir facturas',
      icon: <></>
    },
    {
      id: 'automaticInvoicing',
      title: 'Activa la facturación automática',
      description: 'Emitiremos facturas automáticamente cuando recibas un pago',
      icon: <></>
    },
  ]
  const categoriesTaxo = [
    {
      id: 'invoices',
      title: 'Conectáte al SAT',
      description: 'Conecta tus sellos digitales y datos fiscales para poder emitir facturas',
      icon: <></>
    },

  ]

  // const importantIntegrations = ['stripeSetup', 'invoices', 'automaticInvoicing', 'setDefaults']
  // const [cat, setcat] = useState(categories)
  const estado = teamRt?.skipOnboarding ? integrationsTaxo : integrationsData
  const cat = teamRt?.skipOnboarding ? categoriesTaxo : categories
  const [integrations, setIntegrations] = useState(estado);


  // const hasIntegrationImportantCompleted = () => {
  //   let completed = false
  //   importantIntegrations.forEach((integrationId) => {
  //     const integration = teamRt?.integrationsSetup.find((integration) => integration.id === integrationId)
  //     if (!integration.completed) {
  //       completed = false
  //     }
  //   })
  //   return completed
  // }

  const handleIntegrationsToShow = () => {

    let updatedIntegrations = teamRt?.skipOnboarding ? integrationsTaxo : integrationsData
    updatedIntegrations = updatedIntegrations.map(int => {
      if (int.id === 'testInvoice') {
        if (!teamRt?.facturapi?.completed) {
          return {
            ...int,
            disabled: true
          }
        }
        return { ...int }
      }
      if (int.id === 'automaticInvoicing') {
        if (!teamRt?.facturapi?.completed && !teamRt?.stripe?.completed) {
          return {
            ...int,
            disabled: true
          }
        }
        return { ...int }
      }
      if (int.id === 'importStripePayments' || int.id === 'importStripeProducts') {
        if (!teamRt?.stripe?.completed) {
          return {
            ...int,
            disabled: true
          }
        }
        return { ...int }
      }
      return { ...int }
    })

    setIntegrations(updatedIntegrations)
  }

  const [integrationsValidate, setIntegrationsValidate] = useState(['stripeSetup', 'invoices', 'automaticInvoicing', 'setDefaults', 'importStripePayments', 'importStripeProducts', 'testInvoice'])
  const validateIntegrations = () => {
    const newIntegrationsValidate = integrationsValidate.filter((item) => {
      if (item === 'stripeSetup' && teamRt?.stripe?.completed) {
        return false;
      }
      if (item === 'invoices' && teamRt?.facturapi?.completed) {
        return false;
      }
      if (item === 'automaticInvoicing' && teamRt?.stripe?.automaticInvoicing) {
        return false;
      }
      if (item === 'setDefaults' && !teamRt?.integrationsSetup?.includes('setDefaults')) {
        return false;
      }
      if (item === 'importStripePayments' && !teamRt?.integrationsSetup?.includes('importStripePayments')) {
        return false;
      }
      if (item === 'importStripeProducts' && !teamRt?.integrationsSetup?.includes('importStripeProducts')) {
        return false;
      }
      if (item === 'testInvoice' && !teamRt?.integrationsSetup?.includes('testInvoice')) {
        return false;
      }
      return true;
    });

    setIntegrationsValidate(newIntegrationsValidate);
  };

  useEffect(() => {
    handleIntegrationsToShow()
    validateIntegrations()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamRt?.integrationsSetup])

  useEffect(() => {
    validateIntegrations()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamRt?.stripe?.completed, teamRt?.facturapi?.completed])



  const {
    handleOpenIntegrationModal,
    handleLoadingCards,
    stripeModalOpen,
    satModalOpen,
    importProductsModalOpen,
    importPaymentsModalOpen,
    bankAccountModalOpen,
    testInvoiceModalOpen,
    handleCloseIntegrationModal,
    defaultsModalOpen,
    automaticInvoicingModalOpen
  } = useContext(IntegrationsContext)


  const handleOpenIntegration = async (value) => {
    if (!integrationsValidate.includes(value.id)) return
    switch (value.id) {
      case 'bank':
        handleOpenIntegrationModal('bankAccount')
        break;
      case 'invoices':
        handleOpenIntegrationModal('sat')
        break;
      case 'stripeSetup':
        handleOpenIntegrationModal('stripe')

        // const link = await handleStripeLink({ userDocument, auth })
        // window.open(link.url, '_blank')
        // setTimeout(() => {
        //     handleLoadingCards('stripeSetup', false)
        // }, 3000);
        break;
      case 'importStripeCustomers':
        if (integrationsValidate.includes('stripeSetup')) return message.warning('Primero debes completar la integración de Stripe')
        handleOpenIntegrationModal('importPayments')
        break;
      case 'importStripeProducts':
        if (integrationsValidate.includes('stripeSetup')) return message.warning('Primero debes completar la integración de Stripe')
        handleOpenIntegrationModal('importProducts')
        break;
      case 'setupBrand':
        changeRender('settings')
        window.history.replaceState(null, `tab`, `/settings?subtab=branding`);
        setTimeout(() => {
          handleLoadingCards('setupBrand', false)
        }, 3000);
        break;
      case 'importStripePayments':
        if (integrationsValidate.includes('stripeSetup')) return message.warning('Primero debes completar la integración de Stripe')
        handleOpenIntegrationModal('importPayments')
        break;
      case 'testInvoice':
        if (integrationsValidate.includes('invoices')) return message.warning('Primero debes completar la integración con el SAT ')
        handleOpenIntegrationModal('testInvoice')
        break;
      case 'setDefaults':
        handleOpenIntegrationModal('defaults')
        break;
      case 'automaticInvoicing':
        if (integrationsValidate.includes('stripeSetup') || integrationsValidate.includes('invoices')) return message.warning('Primero debes completar la integración Con el SAT y Stripe')
        handleOpenIntegrationModal('automaticInvoicing')
        break;
      default:
        break;
    }
  }


  const calculateProgress = (cat) => {
    const currentIntegrations = teamRt?.skipOnboarding ? integrationsTaxo : integrationsData.filter(i => i.category === cat.id)
    const completedIntegrations = currentIntegrations.filter(integration =>
      !integrationsValidate.includes(integration.id)
    );

    return ((completedIntegrations.length / currentIntegrations.length) * 100);
  };

  const updateSetupComplete = async () => {
    try {
      await updateDoc(doc(getFirestore(), 'teams', team?.id), {
        setupComplete: true
      })
      getCurrentTeam(team?.id)
      message.success('¡Tu configuración se ha completado!')
    } catch (err) {
      message.error(err.message ?? 'Ocurrió un error al completar la configuración')
    }
  }
  const [/*modalOpen*/, setModalOpen] = useState(false);

  // const closeModal = () => {
  //   setModalOpen(false);
  // };
  useEffect(() => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const lastModalDate = localStorage.getItem('lastModalDate');

    if (lastModalDate === currentDate) {
      setModalOpen(false);
    } else {
      setModalOpen(true);
      localStorage.setItem('lastModalDate', currentDate);
    }
  }, []);

  return (
    <Layout>
      {testInvoiceModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <TestInvoiceModal open={testInvoiceModalOpen} close={() => {
          handleCloseIntegrationModal('testInvoice')
          handleLoadingCards('testInvoice', false)
        }} />
      </Suspense>}
      {automaticInvoicingModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <StripeModal open={automaticInvoicingModalOpen} close={({ haveChange }) => {
          handleCloseIntegrationModal('automaticInvoicing')
          handleLoadingCards('automaticInvoicing', false)
          if (haveChange) getCurrentTeam(team?.id)
        }} />
      </Suspense>}
      {importPaymentsModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <PaymentsListModal open={importPaymentsModalOpen} close={() => {
          handleCloseIntegrationModal('importPayments')
          handleLoadingCards('importPayments', false)
        }} userDocument={userDocument} importFromStripe={true} />
      </Suspense>}
      {importProductsModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <ServiceListModal open={importProductsModalOpen} close={() => {
          handleCloseIntegrationModal('importProducts')
          handleLoadingCards('importStripeProducts', false)
        }} userDocument={userDocument} importFromStripe={true} />
      </Suspense>}
      {satModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <FacturapiModal edit={false} open={satModalOpen} close={() => {
          handleCloseIntegrationModal('sat')
          handleLoadingCards('invoices', false)
        }} userDocument={userDocument} />
      </Suspense>}
      {stripeModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <ConectModal edit={false} open={stripeModalOpen} close={() => {
          handleCloseIntegrationModal('stripe')
          handleLoadingCards('stripeSetup', false)
        }} userDocument={userDocument} />
      </Suspense>}
      {bankAccountModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <BankModal open={bankAccountModalOpen} close={() => {
          handleCloseIntegrationModal('bankAccount')
          handleLoadingCards('bank')
        }} userDocument={userDocument} />
      </Suspense>}
      {defaultsModalOpen && <Suspense fallback={<LoadingOutlined />}>
        <DefaultsConfigDrawer open={defaultsModalOpen} close={() => {
          handleCloseIntegrationModal('defaults')
          handleLoadingCards('setDefaults')
        }} userDocument={userDocument} />
      </Suspense>}
      {/* <OnboardingModal open={modalOpen} close={closeModal} userDocument={userDocument} /> */}

      <Col xs={24} style={{ marginBottom: '20px' }}>
        <Row justify="space-between" align="middle">
          <div style={{ borderBottom: '1px solid #fafbfd' }} className="d-flex flex-column">
            <Row justify="start">
              <Typography.Title style={{ margin: 0, padding: 0 }} level={3}>Hola, {userDocument?.firstName ?? ''}! </Typography.Title>
            </Row>
            <Row justify="start">
              <Typography.Text type="secondary">Terminemos la configuración de tu cuenta</Typography.Text>
            </Row>
          </div>
        </Row>
      </Col>
      <Row style={{ marginTop: '20px', marginBottom: '20px', width: '100%' }}>
        <Typography.Paragraph>
          Completa la configuración de tu cuenta para desbloquear tu Dashboard
        </Typography.Paragraph>

      </Row>
      <Row>
        <Collapse
          className='d-flex flex-column'
          defaultActiveKey={[cat[0].id]}
          style={{ marginBottom: '16px', width: '100%', borderRadius: '10px' }}
        >
          {cat.map((cat) => {
            return (
              <Collapse.Panel
                key={cat.id}
                header={
                  <div>
                    <Row justify="space-between" align="middle">
                      <Col>
                        <strong>{cat.title}</strong>
                      </Col>
                      <Col span={24}>
                        <Progress
                          format={(percent) => percent + '%'}
                          percent={Math.round(calculateProgress(cat))}
                          strokeColor={green[6]}
                          showInfo={true}
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '10%',
                            height: '20px',
                            marginRight: '10px',
                          }}
                        />
                      </Col>
                    </Row>

                    <p style={{ color: '#757575' }}>{cat.description}</p>
                  </div>
                }

              >
                <div>
                  {integrations
                    .filter((integration) => integration.category === cat.id)
                    .map((integration, index) => (
                      <div key={index} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Card.Grid
                          onClick={() => {
                            handleOpenIntegration(integration);
                          }}
                          key={integration.id}
                          style={{
                            width: '100%',
                            marginBottom: '16px',
                            borderRadius: '8px',
                            overflow: 'hidden', // Para ocultar cualquier contenido que se salga del borde redondeado
                          }}
                        >
                          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <div>
                                <div
                                  style={{
                                    marginRight: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '10px',
                                    borderRadius: '50%',
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: !integrationsValidate.includes(integration.id) ? '#8666FF' : '#DDDDDD',
                                  }}
                                >
                                  {integration.disabled ? <LockOutlined style={{ color: '#818181' }} /> : ''}
                                  {!integrationsValidate.includes(integration.id) ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#FFF',
                                        width: '100%',
                                        height: '100%',
                                      }}
                                    >

                                      <CheckOutlined style={{ margin: 'auto' }} />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-end' }} >
                                <Typography.Text strong style={{ fontSize: '16px' }}>
                                  {integration.title} {integration.required && <span style={{ fontSize: '12px', color: '#757575' }}>(requerido)</span>}
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: '12px', color: '#666' }}>
                                  {integration.description}
                                </Typography.Text>
                              </div>
                            </div>
                            <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
                              {integrationsValidate.includes(integration.id) ? (
                                <Typography.Text style={{ fontSize: '12px', color: '#757575' }}>Sin completar</Typography.Text>
                              ) : null}
                            </div>
                          </Space>
                        </Card.Grid>
                      </div>

                    ))}
                </div>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Row>


      <Space direction='vertical' justify="center" align="middle" style={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center', alignItems: 'center' }}>
        <Typography.Text type="secondary">Para Continuar al dashboard configura al menos los requeridos.  </Typography.Text>
        <Button type='primary' disabled={teamRt?.skipOnboarding ? !teamRt?.facturapi?.completed ? true : false : !teamRt?.facturapi?.completed && !teamRt?.stripe?.completed ? true : false} onClick={updateSetupComplete}>
          Continuar al Dashboard
        </Button>

      </Space>

    </Layout>
  );
};

export default Onboarding;
