
import { DeleteOutlined, ExperimentOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined, LoadingOutlined, } from '@ant-design/icons';
import { /*Button*/ Button, Col, message, Popconfirm, Row, Space, Tooltip, Typography } from 'antd';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import moment from 'moment';
import React, { useState } from 'react'
import { DownloadFile, DownloadInvoice, /*DownloadInvoice*/ returnCurrencyValue } from '../../app/functions/helpers';
import HelperCard from '../cards/HelperCard';
import infoGrad from '../../assets/images/information-grad.png';
import { useAuth } from 'reactfire';
// import { useAuth } from 'reactfire';


function ReceiptsRelatedTo({
    relatedTo,
    receipts,
    showHeader = true
}) {
    const auth = useAuth();

    const [downloading, setdownloading] = useState(null);
    // const auth = useAuth()

    const deleteReceipt = async (record) => {
        try {
            setdownloading(record.id);
            try {
                const d = doc(getFirestore(), "receipts", record.id);
                await deleteDoc(d);

                message.success("Recibo eliminado");
                setdownloading(null);
            } catch (error) {
                setdownloading(null);
                message.error(error.message);
            }
        } catch (error) { }
    };

    return (
        <>

            <Col justify="space-between" xs={24}>
                {showHeader && <HelperCard supportImageUrl={infoGrad} squareImg={true} title='¿Qué son los recibos de venta?' description='Los recibos de venta son documentos que se envían al cliente después de que ha realizado un pago. El propósito de estos recibos es permitir al cliente generar su propia autofactura a partir de la información proporcionada en el recibo.' onClick={() => window.open('https://helpcenter.gigstack.pro/es/articles/7546346-hablemos-del-recibo-de-venta', '_blank')} />}
                <Row style={{ marginTop: '15px' }}>
                    {receipts.map((p) => {

                        var readableStatus = (p.status === 'completed' || p.invoices) ? 'Completo' : "En espera";
                        var statusColor = readableStatus === 'Completo' ? '#CCF6DB' : '#8666FF';
                        return <Col xs={24} key={p.id} style={{
                            marginTop: '15px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                            borderRadius: '10px',
                            padding: '10px 10px',
                            backgroundColor: 'white'
                        }} className="hoverBorderPrimary" >
                            <div className="relatedPaymentCard" style={{ position: 'relative' }}>
                                <Row justify="space-between" align="middle" style={{ padding: '10px 15px' }}>
                                    <div className="d-flex flex-column">
                                        <span style={{ fontSize: '11px', marginLeft: '0px', textDecoration: 'underline', textDecorationColor: statusColor, color: 'rgba(0, 0, 0, 0.45)' }}>{readableStatus}</span>
                                        <div className='d-flex flex-row'>
                                            <Typography.Text className='strong'>{p.client?.name ?? p.client?.legal_name ?? 'Sin cliente'} </Typography.Text>
                                            {!p.livemode && <ExperimentOutlined
                                                style={{
                                                    marginTop: 'auto',
                                                    marginBottom: 'auto',
                                                    marginLeft: "6px",
                                                    fontSize: "10px",
                                                    color: "#cecece",
                                                }}
                                            />}
                                        </div>
                                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{moment(p.timestamp).format('DD/MM/YYYY HH:mm')} - <Typography.Text type='secondary' copyable={true}>{p.id}</Typography.Text></Typography.Text>
                                    </div>
                                    <div className="d-flex flex-column" >

                                        <Space style={{ marginBottom: '15px' }}>

                                            {p.pdf &&
                                                (downloading === p.pdf ? (
                                                    <LoadingOutlined />
                                                ) :
                                                    p.status === 'pending' && <FilePdfOutlined
                                                        className="secondary clickable itemHoverPrimary"
                                                        onClick={() => {
                                                            DownloadFile({
                                                                type: "pdf",
                                                                url: p.pdf,
                                                                name: "Recibo-de-venta",
                                                                setdownloading: (r) => setdownloading(r),
                                                            });
                                                        }}
                                                    />
                                                )}
                                            {p.invoice && p.status === 'completed' && !p.isGlobal && (
                                                <Button type='primary'
                                                    onClick={() => {
                                                        DownloadInvoice({
                                                            type: "pdf",
                                                            invoice: {
                                                                ...p,
                                                                id: p.invoice,
                                                                uuid: p.invoice,
                                                            },
                                                            authUser: auth.currentUser,
                                                            setdownloading: (v) => setdownloading(v),

                                                        });

                                                    }}
                                                    loading={downloading === p.invoice}
                                                >Descargar factura</Button>
                                            )}
                                            {p.status === "pending" && (
                                                <Tooltip title="Copiar URL de auto-factura">
                                                    <Typography.Text
                                                        copyable={{
                                                            icon: <LinkOutlined />,
                                                            text: p.url,
                                                            tooltips: false,
                                                        }}
                                                    ></Typography.Text>
                                                </Tooltip>
                                            )}
                                            <EyeOutlined onClick={() => window.open(`/receipts`, '_blank')} style={{ color: '#b7b7b7' }} className="clickable" />
                                            {p.status === "pending" ? (
                                                downloading === p.id ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <Popconfirm onConfirm={async () => deleteReceipt(p)} title="¿Eliminar el recibo?" okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar" cancelButtonProps={{ type: 'ghost' }}>
                                                        <DeleteOutlined
                                                            className="secondary itemHoverDanger clickable"
                                                        />
                                                    </Popconfirm>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </Space>
                                        <div className="pill">
                                            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{returnCurrencyValue(p.total)} {p.currency.toUpperCase()}</Typography.Text>
                                        </div>
                                    </div>
                                </Row>

                            </div>
                        </Col>
                    })
                    }
                </Row>
            </Col>
        </>
    );
}

export default ReceiptsRelatedTo;