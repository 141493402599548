import React, { useEffect, useContext, useState, Suspense } from "react";
import { Button, Col, Divider, Row, Typography, Breadcrumb, Form, Input, message, Tooltip, Tag, Popconfirm } from 'antd';
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext";
import Select from 'react-select';
import TeamContext from "../../context/teamcontext/TeamContext";
import UserContext from "../../context/usercontext/UserContext";
import { generalreactSelectStyles } from "../../app/functions/styles";
import { countryLabeledList, countryLabeledListWithCodeAlpha3 } from "../../app/functions/datasets";
import { collection, doc, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { generateCode, RemoveUndefined, returnColorByUserRole, returnTextColorByUserRole, userRoleMessage } from "../../app/functions/helpers";
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import DefaultSimpleTable from "../tables/DefaultTableClients";
import TableTitle from "../tables/TableTitle";
import { useAuth } from "reactfire";
import { useUserPermissions } from "../../customHooks/userPermissions";

function BillingAccount() {

    const { billingAccount } = useContext(BillingAccountContext);
    const { team } = useContext(TeamContext);
    const { user } = useContext(UserContext)


    // const [addMember, setAddMember] = useState(false);
    const [render, setRender] = useState(0) // 0 for info and 1 for admins table

    const { isBillingAccountOwner } = useUserPermissions({ user, team, billingAccount })



    const verifyIsBillingAccountAdmin = () => {
        if (billingAccount?.admins?.includes(user.uid)) {
            // setAddMember(true)
        } else {
            // setAddMember(false)
        }
    }

    useEffect(() => {
        verifyIsBillingAccountAdmin()

        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingAccount]);

    const BillingAdminsTable = () => {

        const auth = useAuth()
        const fs = getFirestore();
        const [admins, setAdmins] = useState([])
        const [loadingData, setloadingData] = useState(false);

        const membersRef = query(collection(fs, `teams`), where('id', '==', team?.id))

        const getAdminsData = async () => {
            try {
                setloadingData(true)
                const adminsData = await SignedInternalAPIRequest({
                    billingId: billingAccount.id,
                }, 'getBillingAccountAdmins', auth.currentUser)
                setAdmins(adminsData.users)
                setloadingData(false)
            } catch (error) {
                message.error(error.message)
                setloadingData(false)
            }
        }

        useEffect(() => {

            getAdminsData()

            return () => { }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [billingAccount])

        const handleDeleteAdmin = async (admin) => {
            try {
                await updateDoc(doc(fs, `billingAccounts`, billingAccount?.id), {
                    admins: billingAccount?.admins.filter(a => a !== admin.uid)
                })
                message.success('Administrador eliminado')
            } catch (error) {
                message.error(error.message)
            }
        }

        return <Suspense fallback={<LoadingOutlined />}>
            <DefaultSimpleTable
                lastRowMaxHeigth={true}
                query={membersRef}
                data={admins}
                loading={loadingData}
                columns={[
                    {
                        title: <TableTitle title="Email" />,
                        dataIndex: 'email',
                        key: generateCode(5),
                        fixed: 'left',
                        width: '25%',
                        sorter: (a, b) => ((a?.email).localeCompare(b?.email)),
                        render: (text, record) => {
                            return <Typography.Text className="primary" >{text}</Typography.Text>
                        }
                    },
                    {
                        title: <TableTitle title="Nombre" />,
                        dataIndex: 'name',
                        key: generateCode(5),
                        fixed: 'left',
                        width: '25%',
                        sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
                        render: (text, record) => {
                            return <Typography.Text className="primary" >{text}</Typography.Text>
                        }
                    },
                    {
                        title: <TableTitle title="Rol" />,
                        dataIndex: 'rol',
                        key: generateCode(5),
                        width: '15%',
                        sorter: (a, b) => ((a?.rol).localeCompare(b?.rol)),
                        render: (text, record) => {
                            return <Tooltip title={userRoleMessage(text)}>
                                <div> <Tag className="statusTag" color={returnColorByUserRole(text)} style={{ color: returnTextColorByUserRole(text), borderRadius: '10px', opacity: 0.7 }}>{userRoleMessage(text)}</Tag></div>
                            </Tooltip>
                        }
                    },
                    {
                        title: <TableTitle title="" />,
                        dataIndex: 'actions',
                        key: generateCode(5),
                        width: '5%',
                        render: (text, record) => {
                            if (!isBillingAccountOwner) return <></>
                            if (record.uid === user.uid) return <></>
                            return <Popconfirm
                                title="¿Estás seguro de eliminar este miembro?"
                                onConfirm={() => handleDeleteAdmin(record)}
                                okText="Sí"
                                cancelText="No"
                            >
                                <Button type="link" style={{ color: '#ff4d4f' }} icon={<DeleteOutlined />} />
                            </Popconfirm>
                        }
                    }
                ]}
                empty='No hay miembros'
                filterSearch={''}
                filterSearchKey="name"
            />
        </Suspense>

    }

    const BillingInfo = () => {
        const initialFields = [
            {
                name: ['name'],
                value: billingAccount?.name ?? null,
            },
            {
                name: ['legalName'],
                value: billingAccount?.legal_name ?? billingAccount?.legalName ?? null,
            },
            {
                name: ['rfc'],
                value: billingAccount?.rfc ?? null,
            },
            {
                name: ['supportPhone'],
                value: billingAccount?.supportPhone ?? null,
            },
            {
                name: ['country'],
                value: billingAccount?.address?.country ? { value: countryLabeledListWithCodeAlpha3.find((a) => a.code === billingAccount?.address?.country).code, label: countryLabeledListWithCodeAlpha3.find((a) => a.code === billingAccount?.address?.country).name } : null,
            },
            {
                name: ['street'],
                value: billingAccount?.address?.street ?? null,
            },
            {
                name: ['exterior'],
                value: billingAccount?.address?.exterior ?? null,
            },
            {
                name: ['neighborhood'],
                value: billingAccount?.address?.neighborhood ?? null,
            },
            {
                name: ['city'],
                value: billingAccount?.address?.city ?? null,
            },
            {
                name: ['state'],
                value: billingAccount?.address?.state ?? null,
            },
            {
                name: ['zip'],
                value: billingAccount?.address?.zip ?? null,
            },
            {
                name: ['currency'],
                value: billingAccount?.currency ?? null,
            },
        ]

        const [fields, setFields] = useState(initialFields)
        const [loading, setloading] = useState(false);


        useEffect(() => {
            setFields(initialFields)
            return () => { }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [billingAccount])

        const handleFormFinish = async (v) => {
            try {
                setloading(true);
                await updateDoc(doc(getFirestore(), `billingAccounts/${billingAccount.id}`), {
                    ...RemoveUndefined(v),
                    legal_name: v.legalName,
                    country: v.country?.label ?? null,
                })
                message.success('Cuenta actualizada correctamente.')
                setloading(false);
            } catch (error) {
                message.error(error.message)
                setloading(false);
            }
        }


        return <Form layout='vertical' style={{ width: '100%', padding: '0px 60px' }} fields={fields} onFinish={(v) => handleFormFinish(v)}>
            <Row style={{ width: '100%', marginTop: '25px' }} gutter={{ xs: 12, md: 12 }}>



                <Col xs={24} >
                    <Form.Item label="¿Nombre legal de la empresa?" name="legalName" rules={[{ required: true, message: 'Por favor añade el nombre legal' }]}>
                        <Input placeholder='Empresa SA DE CV' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="¿RFC de la empresa?" name="rfc" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='Empresa SA DE CV' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="¿Teléfono de la empresa?" name="supportPhone" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12} >
                    <Form.Item label="País de incorporación" name="country" rules={[{ required: true, message: 'Por favor añade tu país.' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={countryLabeledList}
                            isLoading={false}
                            placeholder="País de la empresa.."

                        />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Form.Item label="Calle" name="street" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Form.Item label="Número exterior" name="exterior" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Form.Item label="Colonia" name="neighborhood" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Form.Item label="Ciudad" name="city" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Form.Item label="Código postal" name="zip" rules={[{ required: true, message: 'Por favor añade tu código postal' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <Form.Item label="Estado" name="state" rules={[{ required: false, message: '' }]}>
                        <Input placeholder='' />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ width: '100%' }} justify="center">
                <Button loading={loading} htmlType="submit">Actualizar información</Button>
            </Row>

            <Row justify="center" >
                <Typography.Text style={{ marginTop: '10px', fontSize: '10px' }} type="secondary"><span className="">ID:</span> {billingAccount?.id} - {billingAccount?.customerID ?? billingAccount?.customerId ?? '-'}</Typography.Text>
            </Row>
        </Form>
    }

    const ToRender = () => {
        switch (render) {
            case 0:
                return <BillingInfo />
            case 1:
                return <BillingAdminsTable />
            default:
                return <BillingInfo />
        }
    }

    return (
        <Col sx={24}>
            <div className="settingsContainer">
                <Row sx={24} justify="space-between" style={{ width: '100%' }}>
                    <Col>
                        <Typography.Title style={{ marginBottom: '2px' }} level={5}>Cuenta de facturación</Typography.Title>
                        {/* <Typography.Text type="secondary">Información detallada sobre tu cuenta de facturación</Typography.Text> */}
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => setRender(0)}>
                                {render === 0 ? "Información de la cuenta" : <span style={{ color: "#8666FF" }}>Información de la cuenta</span>}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => setRender(1)}>
                                {render === 1 ? "Administradores" : <span style={{ color: "#8666FF" }}>Administradores</span>}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    {/* <Col>
                        {addMember && <Button style={{ marginTop: 2 }} type="primary" onClick={() => handleOpenModal()}> Agrega un administrador </Button>}
                    </Col> */}
                </Row>
                <Divider />
                <ToRender />
            </div>
        </Col>
    )
}

export default BillingAccount;