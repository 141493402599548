import {
    ApiOutlined, CloseOutlined, LoadingOutlined,
    // MailOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Form, message, Row, Switch, Tabs, Typography } from 'antd';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { useFirestoreDoc } from 'reactfire';
import { TranslatePeriodicityToObject } from '../../app/functions/helpers';
import SearchSATProductkey from '../services/SearchSATProductkey';
import TeamContext from '../../context/teamcontext/TeamContext';
import moment from 'moment';

function StripeAutomationDrawer({ open, close, userDocument }) {

    const { team } = useContext(TeamContext)
    const ref = doc(getFirestore(), "teams", team?.id)
    const { status, data } = useFirestoreDoc(ref);
    const { defaults } = data.data()
    const [tab, settab] = useState('fiscal')
    const [searchPK, setsearchPK] = useState(false)
    const [periodicity, setPeriodicity] = useState(moment())
    const [fiscalForm] = Form.useForm();
    const [automaticInvoicing, setAutomaticInvoicing] = useState(data.data()?.stripe?.automaticInvoicing ?? false)
    const [avoidReceipts, setAvoidReceipts] = useState(defaults?.avoidReceipts ?? false)

    useEffect(() => {

        setPeriodicity(defaults?.periodicity ?? '')

        //eslint-disable-next-line
    }, [])





    /**
     * It renders a form with some inputs and a button
     * @returns A function that returns a form.
     */

    const FiscalDefaults = () => {
        return (
            <>
                <Form layout='vertical' form={fiscalForm} initialValues={{ ...defaults, periodicity: TranslatePeriodicityToObject(defaults?.periodicity ?? '') }} onFinish={async (v) => {

                    await updateDoc(ref, {
                        defaults: {
                            ...defaults,
                            ...v,
                            avoidReceipts,
                            periodicity: periodicity,

                        }
                    })
                    message.success('Datos guardados')
                    close()
                }}>
                    <Row align="middle" style={{ marginTop: '15px', marginBottom: '35px' }}>
                        {status === 'loading' ? <LoadingOutlined /> : <Switch defaultChecked={!avoidReceipts} checked={!avoidReceipts} onChange={(v) => {
                            try {
                                updateDoc(ref, {
                                    defaults: {
                                        ...defaults,
                                        avoidReceipts: !v
                                    }
                                })
                                message.success('Datos guardados')
                                setAvoidReceipts(!v)
                            } catch (error) {
                                message.error(error?.message ?? 'Ocurrio un error al guardar los datos')
                            }
                        }} />}
                        <Typography.Text type="secondary" style={{ fontSize: '12px', flex: 1, marginLeft: '15px' }}>¿Crear recibos de venta cuando no se pueda emitir la factura de forma automática?. {data.data().avoidReceipts ?? ''}</Typography.Text>

                    </Row>

                    <div style={{ paddingTop: '15px' }}></div>
                    <Row justify="center">
                        <Button htmlType="submit" type="primary" >Guardar</Button>
                    </Row>
                </Form>

            </>
        )
    }

    return (
        <Drawer visible={open} onClose={() => close()} title={null} closable={false} width={window.innerWidth < 780 ? '90%' : '40%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} >
            {searchPK && <Suspense fallback={<>loading</>}>
                <SearchSATProductkey open={searchPK} close={() => setsearchPK(false)} onSelected={(k) => {
                    fiscalForm.setFieldsValue({ product_key: k })
                    setsearchPK(false)
                }} />
            </Suspense>}
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4} style={{ flex: 1 }}>Configuración de la automatización con Stripe</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                </Row>
                <Row align="middle" style={{ marginTop: '15px', marginBottom: '35px' }}>
                    {status === 'loading' ? <LoadingOutlined /> : <Switch defaultChecked={automaticInvoicing} checked={automaticInvoicing} onChange={(v) => {
                        updateDoc(ref, {
                            stripe: {
                                ...data.data()?.stripe,
                                automaticInvoicing: v
                            }
                        })
                        message.success('Datos guardados')
                        setAutomaticInvoicing(v)
                    }} />}
                    <Typography.Text type="secondary" style={{ fontSize: '12px', flex: 1, marginLeft: '15px' }}>Siempre que tengas activada la automatización, realizaremos una factura por cada pago que recibas en la cuenta conectada de stripe.</Typography.Text>

                </Row>
                {automaticInvoicing ? <Tabs onChange={(k) => { settab(k) }} defaultActiveKey={tab} type="card">
                    <Tabs.TabPane tab={<Row align="middle">
                        <ApiOutlined /><Typography.Text> Información fiscal</Typography.Text>
                    </Row>} key="fiscal">
                        <FiscalDefaults />
                    </Tabs.TabPane>
                </Tabs> : <></>}

            </div>
        </Drawer>
    );
}

export default StripeAutomationDrawer;