import { LoadingOutlined, SubnodeOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm, Row, Tooltip, Typography, message } from 'antd';
import { arrayUnion, setDoc, doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { Suspense, useContext, useState } from 'react';
import moment from 'moment';
import { getItemsAmounts, generateCode, roundAmount } from '../../app/functions/helpers';
import { logEvent } from 'firebase/analytics';
import { useAnalytics } from 'reactfire';
import RelatePayments from '../payments/RelatePayments';
import RelationsDrawer from './RelationsDrawer';
import NewPaymentRequest from '../payments/NewPaymentRequest';
import { useUserPermissions } from '../../customHooks/userPermissions';
import UserContext from '../../context/usercontext/UserContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';



function PaymentRelations({ item, id, /*client, changeState,*/ collection: incomingCollection, items, elementType, hideTitle = false, allowSmall = false, custom = false, payments = [], userDocument, readable, customElement }) {

    const analytics = useAnalytics()
    const { user } = useContext(UserContext)
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const [addPayment, setaddPayment] = useState(false);
    const [associatePayment, setAssociatePayment] = useState(null);
    const [viewRelations, setviewRelations] = useState(false);
    const [loading, setloading] = useState(false)
    const [openRegisterPayment, setOpenRegisterPayment] = useState(false)

    const { canCreatePayments } = useUserPermissions({ user, team, billingAccount })


    //REMOVE DUPLICATES 
    const correctPayments = []
    /* Removing duplicates from the array. */
    item.payments?.forEach((p) => {
        const indx = correctPayments.findIndex((i) => i === p)
        if (indx === -1) {
            correctPayments.push(p)
        }

    })


    const width = window.innerWidth;

    /**
     * It close the popconfirm register payment
     */
    const handleCancel = () => {
        setloading(false)
        setOpenRegisterPayment(false)
    }

    /**
     * It creates a payment object from invoice and write data to database
     * @returns response
     */
    const RegisterPayment = async () => {
        const { total, currency, internalClient, id, internalItems, livemode, client } = item


        const amounts = getItemsAmounts(internalItems, false)
        setloading(true)
        const code = generateCode(15, 'payment')
        const paymentObj = {
            livemode,
            sms: false,
            charges: null,
            review: null,
            automations: null,
            amount_capturable: 0,
            customer: internalClient ?? null,
            created: moment().valueOf(),
            timestamp: moment().valueOf(),
            shipping: null,
            amount_received: roundAmount(total * 100),
            capture_method: null,
            transfer_group: null,
            amount: roundAmount(total * 100),
            discount: amounts.masterDiscount,
            status: "succeeded",
            next_action: null,
            viewed: 0,
            receipt_email: "",
            last_payment_error: null,
            confirmation_method: "",
            statement_descriptor: "",
            owner: userDocument.uid,
            payment_method: null,
            application: null,
            automatic_payment_methods: null,
            description: '',
            lastViewed: null,
            on_behalf_of: null,
            currency: currency,
            items: internalItems,
            clientID: internalClient?.id ?? null,
            clientId: internalClient?.id ?? null,
            metadata: { items: 1, owner: userDocument.uid, internalID: code },
            internalStatus: "succeeded",
            object: "payment",
            cancellation_reason: null,
            source: null,
            token: null,
            id: code,
            payment_method_types: [],
            custom_method_types: [],
            client: internalClient ?? client,
            application_fee_amount: null,
            canceled_at: null,
            fid: code,
            isManual: true,
            processor: '',
            proposals: null,
            invoices: [id],
            v: 2,
            payment_intent: null,
            paidIn: 'bank',
            team: team.id,
        }

        try {
            await setDoc(doc(getFirestore(), 'payments', code), paymentObj)
            await updateDoc(doc(getFirestore(), 'invoices', id),
                {
                    payments: arrayUnion(code)
                })
            setloading(false)
            logEvent(analytics, 'paymentCreated', {});
            message.success('Pago registrado correctamente')
        } catch (error) {
            setloading(false)
            message.error(error.message)
        }
    }

    /**
     * It returns a menu with three items, each of which has a key, a label, and an onClick function
     */
    const menu = () => (
        <Menu items={[

            {
                key: 'addPayment',
                label: <Typography.Text className="itemHoverPrimary">Solicitar pago</Typography.Text>,
                onClick: () => {
                    if (!canCreatePayments) return message.warning('No tienes permisos para realizar esta acción')
                    setaddPayment(true)
                }
            },
            {
                key: 'associatePayment',
                label: <Typography.Text className="itemHoverPrimary">Asociar pago</Typography.Text>,
                onClick: () => {
                    if (!canCreatePayments) return message.warning('No tienes permisos para realizar esta acción')
                    setAssociatePayment(true)
                }
            },
            {
                key: 'registerPayment',
                label: (
                    canCreatePayments &&
                    <Popconfirm
                        title='¿Deseas registrar el pago de esta factura?'
                        description='Se registrará un pago por el monto total de esta factura'
                        open={openRegisterPayment}
                        onConfirm={RegisterPayment}
                        okButtonProps={{
                            loading: loading,
                        }}
                        onCancel={handleCancel}
                        okText='Registrar'
                        cancelText='Cancelar'
                    >
                        <Typography.Text className="itemHoverPrimary">Registrar pago</Typography.Text>
                    </Popconfirm>
                ),

            },
        ]} />
    );

    return (
        <>
            {viewRelations && <Suspense fallback={<LoadingOutlined />}>
                <RelationsDrawer userDocument={userDocument} open={viewRelations} render="payments" relation={viewRelations} close={() => setviewRelations(null)} />
            </Suspense>}
            {associatePayment && <RelatePayments addOn={incomingCollection} userDocument={userDocument} elementType={elementType} open={associatePayment} close={() => setAssociatePayment(false)} element={item} />}
            {addPayment && <Suspense fallback={<LoadingOutlined />}>
                <NewPaymentRequest open={addPayment} close={(v) => {
                    setaddPayment(false)
                }} data={{
                    readableType: "Factura",
                    amount: getItemsAmounts(items).total * 100,
                    pathToClient: 'internalClient',
                    ...item,
                    items: item.internalItems,
                    item: item,
                    addOn: "invoices",
                    elementID: item.id,
                    [elementType]: item
                }} userDocument={userDocument} type="payments" />
            </Suspense>}
            {customElement ? <div style={{ width: '100%' }} onClick={() => {
                setaddPayment(true)
            }}>{customElement}</div> : custom ? <Row style={{ width: '100%' }} align="middle">
                <div style={{ flex: 1 }}>
                    <Dropdown overlay={() => menu()} placement="bottom">
                        <Button size="small" style={{ flex: 1 }} type={payments?.length > 0 ? 'ghost' : 'primary'}>{payments?.length > 0 ? `${payments?.length
                            } pago${payments?.length > 1 ? 's' : ''} ` : 'Añadir pago'}</Button>
                    </Dropdown>
                </div>
                {(item.paymentsCount > 0 || item.payments?.length > 0) && <SubnodeOutlined onClick={() => setviewRelations({ title: `Pagos relacionados al ${readable ?? 'elemento'}`, type: 'payments', db: incomingCollection, relatedWith: item, count: item.paymentsCount, elementID: id, subdb: 'payments', item })} style={{ fontSize: '20px', color: '#b7b7b7', }} className="clickable itemHoverPrimary" />}
            </Row>
                //END CUSTOM
                :
                //START FULL
                <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', }}>
                        {hideTitle ? <></> : <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>{(correctPayments?.length ?? 0)} Pagos asociados</Typography.Text>}
                        <div>
                            {(width < 1250 && allowSmall) ? <Tooltip title="Solicitar pago">
                                <Button style={{ marginRight: '5px', marginBottom: '5px' }} shape="circle" size="small" type="primary" disabled={true} onClick={() => setaddPayment(true)}>S</Button>
                            </Tooltip> : <Button disabled={true} onClick={() => setaddPayment(true)} type="primary" size="small" style={{ fontSize: '11px', marginTop: '5px', marginRight: '10px' }}>Solicitar pago</Button>}
                            {(width < 1250 && allowSmall) ? <Tooltip title="Asociar pago">
                                <Button style={{ marginRight: '5px', marginBottom: '5px' }} disabled={true} type="ghost" shape="circle" size="small" onClick={() => { }}>A</Button>
                            </Tooltip> : <Button disabled={true} style={{ fontSize: '12px', marginTop: '5px' }} size="small" type="ghost" onClick={() => setAssociatePayment(true)}>Asociar pago</Button>}
                            <Popconfirm placement="top" title="¿Deseas registrar el pago de esta factura?" description="Se registrará el pago por el monto de esta factura" onConfirm={RegisterPayment} okText="Registrar" cancelText="Cancelar">
                                {(width < 1250 && allowSmall) ? <Tooltip title="Registrar pago">
                                    <Button style={{ marginRight: '5px', marginBottom: '5px' }} disabled={true} type="ghost" shape="circle" size="small" onClick={() => { }}>R</Button>
                                </Tooltip> : <Button disabled={true} style={{ fontSize: '12px', marginTop: '5px', marginRight: '10px' }} size="small" type="ghost" onClick={() => { }}>Registrar pago</Button>}
                            </Popconfirm>
                        </div>
                    </div>
                    {(item.paymentsCount > 0 || item.payments?.length > 0) && <SubnodeOutlined onClick={() => setviewRelations({ title: 'Pagos relacionados', type: 'payments', db: incomingCollection, relatedWith: item, count: item.paymentsCount, elementID: id, subdb: 'payments', item })} style={{ fontSize: '20px', color: '#b7b7b7', marginLeft: '20px' }} className="clickable itemHoverPrimary" />}
                </Row>}
        </>
    );
}

export default PaymentRelations;