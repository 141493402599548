import { CheckCircleOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Row, Tag, Typography } from "antd";
import { InvoiceStatusReadable } from "../../app/functions/helpers";

export const InvoiceStatusTag = ({ invoice, useSecondStatus }) => {
    const cancelling = invoice.cancellation_status !== 'none' && invoice.cancellation_status !== 'canceled' && invoice.status !== 'canceled';
    const status = useSecondStatus ? invoice?.cancellationStatus : invoice.status;
    var readableStatus = InvoiceStatusReadable(status);
    var statusColor = !invoice.livemode ? '#E6BEFF' :
        readableStatus === 'Válida' ? '#CCF6DB' :
            (readableStatus === 'Inválida') ? '#E6BEFF' :
                (readableStatus === 'Cancelada' || cancelling) ? '#FEE9E9' :
                    (readableStatus === 'Cancelación manual requerida') ? '#ffefd6' : '#CCF6DB';
    var textColor = !invoice.livemode ? '#876ee4' :
        readableStatus === 'Válida' ? '#019049' :
            (readableStatus === 'Inválida') ? '#F15B5B' :
                (readableStatus === 'Cancelada' || cancelling) ? '#F15B5B' :
                    (readableStatus === 'Cancelación manual requerida') ? '#FCB138' : '#000000';
    var icon = !invoice.livemode ? <InfoCircleOutlined style={{ color: textColor }} /> :
        (readableStatus === 'Válida' && !cancelling) ? <CheckCircleOutlined style={{ color: textColor }} /> :
            (readableStatus === 'Inválida' || cancelling) ? <WarningOutlined style={{ color: cancelling ? '#F15B5B' : textColor }} /> : <WarningOutlined style={{ color: cancelling ? '#F15B5B' : textColor }} />;
    return <Tag style={{ backgroundColor: cancelling ? '#FEE9E9' : statusColor, border: 'none' }}>
        <Row align="middle">
            {icon}
            <Typography.Text style={{ marginLeft: '5px', color: cancelling ? '#F15B5B' : textColor }}>{invoice.livemode ? cancelling ? 'En proceso de cancelación' : readableStatus : 'Sin timbrar'}</Typography.Text>
        </Row>
    </Tag>
}