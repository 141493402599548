/* A function that returns an object. */
export const reactSelectStyles = {
    control: (base, state) => ({
        ...base,
        '&:hover': { borderColor: 'rgba(134, 102, 255,0.5)' },

        boxShadow: "none",
        borderColor: 'rgba(134, 102, 255,0.2)',
        backgroundColor: '#fafbfd',
        borderRadius: '10px',
        height: '40px',

        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (base, state) => ({
        ...base,
        // backgroundColor: state.isFocused ? 'rgba(134, 102, 255,1)' : 'white',
        backgroundColor: "#FF6600",
        color: state.isFocused ? 'white' : '#333',
        '&:active': { backgroundColor: 'rgba(134, 102, 255,0.5)' },
        zIndex: 9999,

        // You can also use state.isFocused to conditionally style based on the focus state
    })
};
/* A function that returns an object. */
export const generalreactSelectStyles = {
    control: (base, state) => ({
        ...base,
        '&:hover': { border: '0.5px solid #E7E7E7' },

        boxShadow: "none",
        border: '0.5px solid #E7E7E7',

        backgroundColor: 'white',
        borderRadius: '10px',
        height: '38px',
        // zIndex: 99999999
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? '#fafbfd' : 'white',
        color: state.isFocused ? '#333' : '#333',
        '&:active': { backgroundColor: '#DDDDDD' },

        // You can also use state.isFocused to conditionally style based on the focus state
    })
};
/* A function that returns an object. */
export const reactSelectMultiStyles = {
    control: (base, state) => ({
        ...base,
        '&:hover': { borderColor: '#E7E7E7' },

        boxShadow: "none",
        borderColor: '#E7E7E7',
        backgroundColor: 'white',
        borderRadius: '10px',
        height: 'auto'
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? 'rgba(134, 102, 255,1)' : 'white',
        color: state.isFocused ? 'white' : '#333',
        '&:active': { backgroundColor: 'rgba(134, 102, 255,0.5)' },

        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: '#F2F0FF',
            borderRadius: '5px',
            color: data.__isNew__ ? '#ffa5a5' : '#333'
        };
    },
    multiValueRemove: (styles, { data }) => data.isFixed ? { ...styles, display: 'none' } : ({
        ...styles,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
    }),

};
