import { LoadingOutlined } from "@ant-design/icons";
import { Form, Row, Select, Switch, Typography, message } from "antd";
import { useContext, useEffect, useState } from "react";
import TeamContext from "../../context/teamcontext/TeamContext";
import { useFirestoreDoc } from "reactfire";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { UseUserDefaults } from "../../customHooks/useUserDefaults";
import { generalreactSelectStyles } from '../../app/functions/styles';
import moment from "moment";

const ReceiptsDefaults = ({ open, close, edit, userDocument }) => {
    const { team } = useContext(TeamContext);
    const ref = doc(getFirestore(), "teams", team?.id)
    const { status, data } = useFirestoreDoc(ref);
    const [updating, setUpdating] = useState(false);
    const [avoidReceipts, setAvoidReceipts] = useState(data.data()?.defaults?.avoidReceipts ?? false)
    const { defaults } = UseUserDefaults({ userDocument: data.data(), team: data.data() })
    const [periodicity, setPeriodicity] = useState(moment())


    useEffect(() => {
        setPeriodicity(defaults?.periodicity ?? '')

        //eslint-disable-next-line
    }, [])

    const validTime = () => {
        var validDays = moment().endOf('day').valueOf();
        switch (periodicity) {
            case 'day':
                validDays = moment().endOf('day').valueOf()
                break;
            case 'week':
                validDays = moment().day(7).endOf('day').valueOf()
                break;
            case 'fortnight':
                //VALID DAYS IS EQUAL TO MIDDLE OR END OF THE CURRENT MONTH
                validDays = moment().day(15).endOf('day').valueOf()

                break;
            case 'month':
                validDays = moment().endOf('month').valueOf()

                break;
            case 'two_months':
                validDays = moment().endOf('month').add(1, 'month').endOf('month').valueOf()

                break;
            default:
                validDays = moment().endOf('day').valueOf()
                break;
        }

        return validDays
    }

    const SectionDivider = ({ title }) => {
        return <div className='d-flex flex-row' style={{ backgroundColor: '#fafbfd', padding: '10px 0px', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', width: '100%', alignContent: 'center', borderRadius: '10px' }}>
            <Typography.Text style={{ alignSelf: 'center' }} className="strong">{title}</Typography.Text>
        </div>
    }


    return <>
        <SectionDivider title="Recibos de venta" />
        <div style={{ marginTop: '10px' }}></div>
        <Typography.Text className="cLabel">Crear recibos de venta</Typography.Text>
        <Row align="middle" style={{ marginTop: '15px', marginBottom: '35px' }}>
            {(status === 'loading' || updating) ? <LoadingOutlined /> : <Switch defaultChecked={!avoidReceipts} onChange={async (v) => {
                setUpdating(true)
                try {

                    await updateDoc(ref, {
                        defaults: {
                            ...defaults,
                            avoidReceipts: !v
                        }

                    })
                    setUpdating(false)
                    setAvoidReceipts(!v)
                    message.success('Datos guardados')
                } catch (error) {
                    message.error(error?.message ?? 'Ocurrió un error al guardar los datos')
                    setUpdating(false)
                }

            }} />}
            <Typography.Text type="secondary" style={{ fontSize: '12px', flex: 1, marginLeft: '15px' }}>¿Crear recibos de venta cuando no se pueda emitir la factura de forma automática?. {data.data().avoidReceipts ?? ''}</Typography.Text>

        </Row>
        <div style={{ paddingTop: '15px' }}></div>
        <Form.Item name="periodicity" label="Periodicidad de los recibos" rules={[{
            required: true,
            message: 'Selecciona la periodicidad de los recibos'
        }]} help={<Typography.Text type="secondary" style={{ fontSize: '11px' }}>{`Periodo en el que emitiremos una factura a público en general por todos los recibos abiertos, por ejemplo el siguiente sería (${moment(validTime()).format('ddd DD MMMM YYYY HH:mm')}).`}</Typography.Text>}>
            <Select
                styles={generalreactSelectStyles}
                className="pro-select-input"
                options={[{
                    value: 'day',
                    label: 'Día',
                },
                {
                    value: 'week',
                    label: 'Semana',
                },
                // {
                //     value: 'fortnight',
                //     label: 'Quincenal',
                // },
                {
                    value: 'month',
                    label: 'Mes',
                },
                {
                    value: 'two_months',
                    label: '2 meses',
                }
                ]}
                isLoading={false}
                onChange={(v) => {
                    if (!v) {
                        return;
                    }
                    setPeriodicity(v.value)
                }}
                placeholder="Periodicidad"
            />
        </Form.Item>

    </>
}



export default ReceiptsDefaults;