import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { message, Row, Typography, Button, Layout, Form, Col, Input } from "antd";
import { useEffect, useState } from "react";
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests";
import { SearchParamInURL } from "../../app/functions/helpers";
import { useAuth, useFirestore } from "reactfire";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import jwtDecode from 'jwt-decode'
import moment from "moment";

function AcceptInvite({ auth: authData }) {

    const [form] = Form.useForm();

    const auth = useAuth();
    const fs = useFirestore()

    const [step, setStep] = useState(0);
    const [loadingAccepted, setLoadingAccepted] = useState(false);
    const [loadingRejected, setLoadingRejected] = useState(false);
    const [validating, setValidating] = useState(true);
    const [invite, setInvite] = useState(null);
    const [teamData, setTeamData] = useState(null);
    const [billingData, setBillingData] = useState(null);
    const [fields, setFields] = useState([]);

    const initialValues = [
        {
            name: ["firstName"],
            value: ""
        },
        {
            name: ["lastName"],
            value: ""
        },
        {
            name: ["email"],
            value: auth?.currentUser?.email ?? invite?.email ?? ""
        },
        {
            name: ["phone"],
            value: ""
        }
    ]

    const verifyInvite = async () => {
        try {
            await setTimeout(() => { }, 2000);
            const token = SearchParamInURL("token")
            let decoded
            try {
                decoded = jwtDecode(token)
            } catch (error) {
            }
            const response = await SignedInternalAPIRequest({ token, billingAccountId: decoded?.billingId, teamId: decoded?.teamId }, "handleSeats/v1/verify", auth.currentUser)
            setValidating(false)
            setInvite(response.invite)
            setTeamData(response.team)
            setBillingData(response.billing)
        } catch (error) {
            if (error?.error?.name === "TokenExpiredError") {
                message.error("El token ha expirado")
            } else {
                message.error(error.message)
            }
            setTimeout(() => {
                window.location.href = "/"
            }, 1000);
        }
    }

    const handleAcceptInvite = async () => {
        try {
            setLoadingAccepted(true)
            const body = {
                token: SearchParamInURL("token"),
                action: "accept",
                currentUser: auth.currentUser,
                inviteId: invite.id,
                teamId: teamData.id,
            }
            await SignedInternalAPIRequest({ ...body }, "handleSeats/v1/action", auth.currentUser)
            message.success("Invitación aceptada")
            setLoadingAccepted(false)
            const user = await getDoc(doc(fs, "users", auth.currentUser.uid))
            if (!user.data()?.firstName || !user.data().lastName || !user.data().phone || !user.data().email) {
                setFields(initialValues)
                setStep(1)
            } else {
                window.location.href = "/"
            }
        } catch (error) {
            message.error(error.message)
            setLoadingAccepted(false)
        }
    }

    const handleRejectInvite = async () => {
        try {
            setLoadingRejected(true)
            const body = {
                token: SearchParamInURL("token"),
                action: "decline",
                currentUser: auth.currentUser,
                inviteId: invite.id,
                teamId: teamData.id,
            }
            await SignedInternalAPIRequest({ ...body }, "handleSeats/v1/action", auth.currentUser)
            message.success("Invitación rechazada")
            setLoadingRejected(false)
            setTimeout(() => {
                window.location.href = "/"
            }
                , 1000);
        } catch (error) {
            message.error(error.message)
            setLoadingRejected(false)
        }
    }

    useEffect(() => {
        if (auth?.currentUser?.uid) {
            verifyInvite();
        }
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.currentUser?.uid]);


    /**
     * It returns the first step of the accept invite process
     * @returns {JSX.Element}
     */
    const AcceptInvite = () => {

        if (validating) return (
            <Typography.Title level={4} style={{ padding: 0, margin: 0, textAlign: 'center' }}>Validando invitación...</Typography.Title>
        )
        return (<Row justify="center" align="middle">
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <p className='russo primary'>gigstack pro</p>
                    <Typography.Text type="secondary" style={{ fontSize: '10px' }} className="secondary">1/2</Typography.Text>
                </Row>
                <TeamOutlined style={{ marginRight: '10px', fontSize: '25px' }} />
                <div className="d-flex flex-column" style={{ margin: '0px 5%' }}>
                    <Typography.Title level={4} style={{ padding: 0, margin: 0, textAlign: 'center' }}>¡Únete a {invite?.type === 'team' ? teamData?.alias ?? "un equipo" : billingData?.legalName ?? "una cuenta"} en Gigstack!</Typography.Title>
                    <Typography.Text level={4} style={{ textAlign: 'center', marginTop: '8px' }} type="secondary">Has sido invitado por {invite?.emailOwner ?? "un admistrador"} {invite?.type === 'team' ? "para unirte a" : "para ser administrador de"} {invite?.type === 'team' ? teamData?.name ?? 'su equipo' : billingData?.legalName ?? "su cuenta"}.</Typography.Text>
                </div>

                <Row justify="center" style={{ marginTop: '35px' }} gutter={2} >
                    <Button type="secondary" loading={loadingRejected} onClick={() => handleRejectInvite()} style={{ marginRight: 5 }}>Rechazar</Button>
                    <Button type="primary" loading={loadingAccepted} onClick={() => handleAcceptInvite()}>Aceptar Invitación</Button>
                </Row>
            </div>
        </Row>)
    }

    const PersonalInformation = () => {
        return <>
            <Row justify="space-between">
                <p className='russo primary'>gigstack pro</p>
                <Typography.Text type="secondary" style={{ fontSize: '10px' }} className="secondary">2/2</Typography.Text>
            </Row>

            <Row align="middle">
                <UserOutlined style={{ marginRight: '20px', fontSize: '25px' }} />
                <div className="d-flex flex-column">
                    <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>¡Información Personal!</Typography.Title>
                    <Typography.Text level={4} type="secondary">Por favor añade tu información personal</Typography.Text>
                </div>

            </Row>

            <Row style={{ width: '100%', marginTop: '25px' }} gutter={{ xs: 12, md: 12 }}>

                <Col xs={24} md={12}>
                    <Form.Item name="firstName" label="¿Cuál es tu nombre?" rules={[{ required: true, message: 'Por favor añade tu nombre.' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="¿Cuáles son tus apellidos?" name="lastName" rules={[{ required: true, message: 'Por favor añade tu apellido.' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="¿Cuál es es tu correo?" name="email" rules={[{ required: true, message: 'Por favor añade tu correo.' }]}>
                        <Input disabled />
                    </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                    <Form.Item label="¿Cuál es tu número de teléfono?" name="phone" rules={[{ required: true, message: 'Por favor añade tu teléfono.' }]}>
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Row justify="end">
                        <Button loading={loadingAccepted} type="primary" htmlType="submit">Continuar</Button>
                    </Row>
                </Col>
            </Row>
        </>
    }

    const ToRenderByStep = () => {
        switch (step) {
            case 0:
                return <AcceptInvite />
            case 1:
                return <PersonalInformation />
            default:
                return <AcceptInvite />
        }
    }

    const registerUserToTeam = async (v) => {
        try {
            setLoadingAccepted(true);
            await updateDoc(doc(fs, 'users', auth.currentUser.uid), {
                firstName: v.firstName,
                lastName: v.lastName,
                phone: v.phone,
                name: `${v.firstName} ${v.lastName}`,
                needCompleteProfile: false,
                setupAt: moment().valueOf(),
                email: v.email
            })
            setLoadingAccepted(false);
            message.success('¡Bienvenido a Gigstack!')
            setTimeout(() => {
                window.location.href = '/'
            }, 1000);
        } catch (error) {
            setLoadingAccepted(false);
            message.error(error.message)
        }
    }

    return (
        <Layout>
            <Layout.Content>
                <Row>
                    <Col xs={24} style={{ minHeight: '100vh', height: 'auto', maxHeight: 'auto', padding: '30px 0px' }}>
                        <Row justify="center" align="middle" style={{ height: '100%' }}>
                            <div style={{ backgroundColor: 'white', padding: '40px', width: '80%', borderRadius: '20px', marginTop: '0px', marginBottom: '5px', height: 'auto', }} className="d-flex flex-column">
                                <Form onFinish={registerUserToTeam} form={form} layout='vertical' style={{ width: '100%' }} fields={fields} >
                                    <ToRenderByStep />
                                </Form>
                            </div>
                        </Row>

                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default AcceptInvite;