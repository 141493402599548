import React, { useEffect } from 'react';
import { Routes, Route, } from "react-router-dom";
import { SuspenseWithPerf, useSigninCheck, useUser } from 'reactfire';
import BillingAccountState from '../../context/billingaccountcontext/BillingAccountState';
import ModalState from '../../context/modalcontext/ModalState';
import TeamState from '../../context/teamcontext/TeamState';
import UserState from '../../context/usercontext/UserState';
import IntegrationsState from '../../context/integrationscontext/IntegrationsState';
import RedirectToBeta from '../../widgets/payments/RedirectToBeta';
import AcceptInvite from '../../widgets/teams/AcceptInvite';
import LoadingFullScreen from '../appStates/LoadingFullScreen';
import LoggedInLayout from '../layouts/LoggedInLayout';
import MembershipsPage from '../pages/MembershipsPage';
import NotSignedRoutes from './NotSignedRoutes';
import SubstituteInvoicePage from '../pages/SubstituteInvoicePage';
// import CreateReceipt from '../pages/CreateReceiptPage';
import CreateReceiptPage from '../pages/CreateReceiptPage';


/**
 * If the user is signed in, render the children, otherwise render the fallback
 * @returns The children or the fallback.
 */
export const AuthWrapper = ({ children, fallback }) => {
    const { data: signInCheckResult } = useSigninCheck();

    if (!children) {
        throw new Error('Children must be provided');
    }

    if (signInCheckResult.signedIn === true) {
        return children
    } else {
        return fallback;
    }
};




function MainRoutes() {
    const { data: authData } = useUser();

    useEffect(() => {
        // const handleBeforeUnload = (event) => {
        //     event.preventDefault();
        //     event.returnValue = 'show warning';
        // };

        // window.addEventListener('beforeunload', handleBeforeUnload);

        // return () => {
        //     window.removeEventListener('beforeunload', handleBeforeUnload);
        // };
    }, []);

    return (

        // <Routes>
        //     <Route path="/:tab" element={<LoggedInLayout auth={authData} />} />
        //     <Route exact path="/" element={<LoggedInLayout auth={authData} />} />
        // </Routes>


        <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={<LoadingFullScreen />}>
            <AuthWrapper fallback={<NotSignedRoutes />}>
                <IntegrationsState >
                    <ModalState >
                        <UserState>
                            <BillingAccountState>
                                <TeamState>
                                    <Routes>
                                        <Route path="/pay/:code" element={<RedirectToBeta autoInvoice={false} />} />
                                        <Route path="/autofactura" element={<RedirectToBeta autoInvoice={true} />} />
                                        <Route path="/memberships" element={<MembershipsPage authData={authData} />} />
                                        <Route path="/invites" element={<AcceptInvite auth={authData} />} />
                                        <Route path="/substituteInvoice/:id" element={<SubstituteInvoicePage auth={authData} />} />
                                        <Route path="/createReceipt" element={<CreateReceiptPage auth={authData} />} />
                                        <Route path="/:tab" element={<LoggedInLayout auth={authData} />} />
                                        <Route path="/" element={<LoggedInLayout auth={authData} />} />

                                        {/* //SET A DEFAULT PAGE */}
                                        <Route path="*" element={<LoggedInLayout auth={authData} />} />
                                    </Routes>
                                </TeamState>
                            </BillingAccountState>
                        </UserState>
                    </ModalState>
                </IntegrationsState>
            </AuthWrapper>
        </SuspenseWithPerf>

    );
}

export default MainRoutes;