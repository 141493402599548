import { LoadingOutlined } from '@ant-design/icons';
import { Button, message, Modal, Row, Tag, Tooltip, Typography } from 'antd';
import { logEvent } from 'firebase/analytics';
import { collection, doc, getDoc, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useAnalytics, useAuth, useFirestore } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { generateCode, paymentIntentsStatusMessage, returnColorByPaymentStatus, returnCurrencyValue, returnTextColorByPaymentStatus } from '../../app/functions/helpers';
import TeamContext from '../../context/teamcontext/TeamContext';
import DefaultSimpleTable from '../tables/DefaultTableClients';
import TableTitle from '../tables/TableTitle';


function PaymentsListModal({ open, close, onSelect, userDocument, importFromStripe = false }) {
    const { team } = useContext(TeamContext)
    const auth = useAuth()
    const analytics = useAnalytics()
    const ref = doc(useFirestore(), 'teams', team?.id)

    const [data, setdata] = useState([])
    const [loading, setloading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)


    const fs = getFirestore();
    const servicesRef = query(collection(fs, `services`), where('team', '==', team?.id ?? ''));

    const getServicesFromStripe = async () => {
        const payments = await SignedInternalAPIRequest({
            type: 'import_data',
            typeOfData: 'paymentsIntents'
        }, 'stripeActions', auth.currentUser)
        setdata(payments.paymentsIntents ?? [])

        setloading(false)
    }


    useEffect(() => {

        if (importFromStripe) {
            getServicesFromStripe()
        } else {
            setloading(false)
        }

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSavePayments = async () => {
        setLoadingButton(true)
        try {
            const res = await SignedInternalAPIRequest({
                payments: data,
                type: "save_data"
            }, 'stripeActions', auth.currentUser)
            try {
                const integrationsToSetup = (await getDoc(ref))?.data()?.integrationsSetup?.filter(int => int !== "importStripePayments") ?? []
                await updateDoc(ref, { integrationsSetup: integrationsToSetup })
            } catch (error) {
            }
            logEvent(analytics, 'importStripePayments')
            message.success(res.message)
            setLoadingButton(false)
            close()
        } catch (error) {
            message.error('Ocurrió un error al guardar los pagos')
            setLoadingButton(false)
        }
    }

    return (
        <Modal style={{ top: 10 }} width={window.innerWidth < 780 ? '100%' : importFromStripe ? "60%" : "50%"} visible={open} title={null} footer={null} onCancel={() => close()}>
            <Suspense fallback={<LoadingOutlined />}>
                <DefaultSimpleTable onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            onSelect(record)
                        },
                    };
                }}
                    query={servicesRef}
                    empty="No hay pagos"
                    importFromStripe={importFromStripe}
                    elementName="servicio"
                    data={data ?? null}
                    loading={loading}
                    filterSearchKey="name"
                    columns={[
                        {
                            title: <TableTitle title="Id" />,
                            dataIndex: 'id',
                            key: generateCode(5),
                            fixed: 'left',
                            width: importFromStripe ? '35%' : '50%',
                            sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
                            render: (text, record) => {
                                return <Typography.Text className="primary">{text}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Total" />,
                            dataIndex: 'amount',
                            width: '15%',
                            key: generateCode(5),
                            render: (text, record) => {
                                return <Typography.Text className="" type="secondary" ellipsis>{returnCurrencyValue(text / 100)}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Moneda" />,
                            dataIndex: 'currency',
                            width: '15%',
                            key: generateCode(5),
                            render: (text, record) => {
                                return <Typography.Text>{text}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Estado" />,
                            dataIndex: 'status',
                            key: generateCode(5),
                            render: (text, record) => {
                                return <Tooltip title={paymentIntentsStatusMessage(record)}>
                                    <Tag className="statusTag" color={returnColorByPaymentStatus(record)} style={{ color: returnTextColorByPaymentStatus(record), borderRadius: '10px', opacity: 0.7 }}>{paymentIntentsStatusMessage(record)}</Tag>
                                </Tooltip>
                            }
                        },
                    ]} />
            </Suspense>
            {importFromStripe && data.length > 0 ? <Row justify="center" style={{ marginTop: '25px' }}>
                <Button loading={loadingButton} onClick={() => {
                    handleSavePayments()
                }} type="primary" style={{ alignSelf: 'center' }}>Importar pagos y clientes</Button>
            </Row> : null}
        </Modal>
    );
}

export default PaymentsListModal;