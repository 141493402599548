import { BarcodeOutlined, CloseOutlined, DeleteOutlined, EyeOutlined, LinkOutlined, MailOutlined, RobotOutlined, StopOutlined, WarningOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Card, Col, Descriptions, Divider, message, Popconfirm, Row, Space, Statistic, Tooltip, Typography } from 'antd';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import moment from 'moment';
import React, { useState } from 'react'
import { useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { CopyToClipboard, generateCode, getItemsAmounts, InvoiceStatusReadable, paymentIntentsStatusMessage, returnCurrencyValue, returnTextColorByPaymentStatus, ShareViaWhatsapp } from '../../app/functions/helpers';
import ComposeEmailModal from '../email/ComposeEmailModal';

function PaymentsRelatedTo({ relatedTo = {}, payments = [], showCards = true, userDocument = {} }) {

    const [showDetails, setShowDetails] = useState(false);
    const [composeEmail, setComposeEmail] = useState(null)

    const auth = useAuth();
    const fs = getFirestore();

    /* Calculating the total amount of all the payments. */
    //TODO: lo comentado es como estaba antes
    // const array = payments?.length <= 0 ? 0 : (payments?.filter((a) => a.status === 'succeeded' || a.status === 'paid')?.map((p) => {
    //     if (p.currency?.toLowerCase() === 'mxn') {
    //         return (p.amount / 100)
    //     } else {
    //         const amount = p.amount / 100
    //         const rate = p.exchangeRate < 1 ? p.exchangeRate : (1 / p.exchangeRate)
    //         return amount
    //     }

    // }) ?? [])

    // const allTotals = array.length > 0 ? array?.reduce((prev, next) => prev + next, 0) : 0
    // const totalInMXN = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

    const array = payments?.length <= 0 ? [] : payments?.filter((a) => a.status === 'succeeded' || a.status === 'paid')?.map((p) => {
        if (p.currency?.toLowerCase() === 'mxn') {
            return p.amount / 100;
        } else {
            const amount = p.amount / 100;
            const rate = p.exchangeRate < 1 ? p.exchangeRate : 1 / p.exchangeRate;
            const currenciesFromInvoice = payments.map((p) => {
                return p.currency
            })
            const currenciesSet = new Set(currenciesFromInvoice)
            const currenciesArrayToCompare = [...currenciesSet]
            if (currenciesArrayToCompare.length > 1) {
                return amount / rate;
            } else {
                return amount;
            }
        }
    }) ?? [];

    const allTotals = array.length > 0 ? array.reduce((prev, next) => prev + next, 0) : 0;
    const totalInMXN = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const currenciesFromPayment = payments.map((p) => {
        return p.currency
    })
    const currenciesSet = new Set(currenciesFromPayment)
    const currenciesArrayToCompare = [...currenciesSet]
    const currency = currenciesArrayToCompare.length > 1 ? '' : currenciesArrayToCompare[0]

    const CancelPayment = async (payment) => {

        // setactionOnPayment(payment.id)
        try {
            const resp = await SignedInternalAPIRequest({ type: 'payment_request_cancel', id: payment.paymentIntent, paymentID: payment.id }, 'stripeActions', auth.currentUser)
            if (resp.error) {
                message.error(resp.error ?? 'Error al cancelar el pago.')
                // return setactionOnPayment(null)
            }

            // setactionOnPayment(null)
        } catch (error) {
            message.error(error.error ?? 'Error al cancelar el pago.')
            // setactionOnPayment(null)
        }

    }

    const DeletePayment = async (id, index) => {
        try {
            await deleteDoc(doc(fs, `payments / ${id}`))
            message.success('Pago eliminado')
        } catch (error) {
            message.error('Error deleting record')

        }
    }

    const amounts = getItemsAmounts(relatedTo?.internalItems)
    const total = getItemsAmounts(relatedTo?.internalItems).total
    return (
        <Col xs={24}>
            {showCards && <Typography.Title level={5}>Relaciones</Typography.Title>}

            <Row>
                {showCards && <Col xs={24}>
                    <Row justify="end">
                        <Card bordered={false} size="small">
                            <Statistic
                                title="Total de la factura"
                                value={total + ' ' + relatedTo?.currency}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                prefix="$"
                                suffix=""
                            />
                        </Card>
                        <Divider type="vertical" />
                        <Card bordered={false} size="small">
                            <Statistic
                                title="Total pagado"
                                value={currenciesArrayToCompare.length > 2 ? totalInMXN.toFixed(2) + 'MXN' : allTotals.toFixed(2) + ' ' + currency?.toUpperCase()}
                                precision={2}
                                valueStyle={{ color: '#cecece' }}
                                prefix="$"
                                suffix=""
                            />
                        </Card>
                        {currenciesArrayToCompare.length > 1 ? <></> : <> {(allTotals - total !== 0) && <Divider type="vertical" />}
                            {(allTotals - total !== 0) && <Card bordered={false} size="small">
                                <Statistic
                                    title="Diferencia"
                                    value={Math.abs(allTotals - total).toFixed(2) + ' ' + currency}
                                    precision={2}
                                    valueStyle={{ color: 'red' }}
                                    prefix="$"
                                    suffimoex=""
                                />
                            </Card>}</>}


                    </Row>
                </Col>}
                {payments.map((p, i) => {
                    const amounts = getItemsAmounts(p?.internalItems ?? p?.items)
                    return <Col xs={24} key={p.id} style={{
                        marginTop: '15px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px',
                        padding: '10px 10px',
                        backgroundColor: 'white'
                    }} className="hoverBorderPrimary " onClick={() => {
                        //  window.open(`/ paymentDetails ? id = ${ p.fid }`, '_blank')
                    }}>
                        <div className="relatedPaymentCard">
                            <Row justify="space-between" align="middle" style={{ padding: '10px 15px' }}>
                                <div className="d-flex flex-column">
                                    <span style={{ fontSize: '11px', marginLeft: '0px', textDecoration: 'underline', textDecorationColor: returnTextColorByPaymentStatus(p), color: 'rgba(0, 0, 0, 0.45)' }}>{paymentIntentsStatusMessage(p)}</span>
                                    <Typography.Text className='strong'>{p.client?.name ?? p.client?.legal_name ?? 'Sin cliente'} </Typography.Text>
                                    <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{moment(p.timestamp).format('DD/MM/YYYY HH:mm')} - {p.id}</Typography.Text>
                                </div>
                                <div className="d-flex flex-column">
                                    <Space align="middle" style={{ marginBottom: '10px' }}>
                                        {p.automations?.length > 0 && p.status !== 'succeeded' ? <Tooltip title="Automatización activa para factura">
                                            <RobotOutlined style={{ color: '#b7b7b7' }} />
                                        </Tooltip> : <></>}
                                        {(p.automationWarnings?.length > 0 && (p.invoices?.length ?? 0) <= 0) && <Tooltip title={<div className="d-flex flex-column">
                                            <Typography.Text className='strong' style={{ color: 'white' }}>Errores en automatización</Typography.Text>
                                            <Typography.Text style={{ fontSize: '10px', color: 'white' }}>{p.automationWarnings.map((a) => a + '' + a + '\n')}</Typography.Text>
                                        </div>}>
                                            <WarningOutlined className="" style={{ color: '#b7b7b7' }} />
                                        </Tooltip>}
                                        {(p.status !== 'succeeded' && p.status !== 'canceled') && <Tooltip title="Copiar link de pago">
                                            <LinkOutlined style={{ color: '#b7b7b7' }} className="itemHoverPrimary" onClick={() => {
                                                // setactionOnPayment(p.id)
                                                CopyToClipboard(p.shortURL ?? `https://beta.gigstack.pro/pay/${p.token}`)

                                                // setactionOnPayment(null)
                                            }} />
                                        </Tooltip >}
                                        {
                                            (p.status !== 'succeeded' && p.status !== 'canceled') && <Tooltip title="Enviar link por correo">
                                                <MailOutlined style={{ color: '#b7b7b7' }} className="itemHoverPrimary" onClick={() => {
                                                    setComposeEmail(p)
                                                }} />
                                            </Tooltip>
                                        }
                                        {
                                            (p.status !== 'succeeded' && p.status !== 'canceled' && p.shortURL) && <div onClick={() => {
                                                ShareViaWhatsapp(`Puedes realizar el pago por ${amounts.totalString} ${p.currency} en el siguiente enlace! 😉 : ${p.shortURL}`)
                                            }} >
                                                <WhatsAppOutlined style={{ color: '#b7b7b7' }} className="itemHoverPrimary clickable" />
                                            </div>
                                        }
                                        {
                                            p?.charges?.data?.map((c, inx) => {
                                                return c.receipt_url ? <BarcodeOutlined style={{ color: '#b7b7b7' }} key={inx} className="itemHoverPrimary" onClick={() => {
                                                    window.open(c.receipt_url, '_blank')
                                                }} /> : <div key={generateCode(5)}></div>

                                            })
                                        }
                                        {
                                            ((p.status === 'requires_payment_method' || p.status === 'requires_confirmation' || p.status === 'pending') && p.next_action?.type !== 'oxxo_display_details') && <Tooltip title="¿Cancelar pago?">
                                                <Popconfirm title="Tu cliente ya no podrá realizar el pago." okButtonProps={{ danger: true }} okText="Cancelar pago" cancelText="Regresar" onConfirm={() => CancelPayment(p)}>
                                                    <StopOutlined className="itemHoverDanger clickable" style={{ color: '#b7b7b7' }} />
                                                </Popconfirm>
                                            </Tooltip>
                                        }

                                        {
                                            ((p.status === 'canceled' || p.object === 'manual_payment') && p.review_status !== 'succeeded') && <Popconfirm onConfirm={() => DeletePayment(p.id, i)} icon={<CloseOutlined style={{ color: 'red' }} />} title="Eliminar el registro del pago" okButtonProps={{ danger: true }} okText="Eliminar Pago">
                                                <DeleteOutlined style={{ color: '#b7b7b7' }} className="clickable itemHoverDanger" />
                                            </Popconfirm>
                                        }
                                        <EyeOutlined className="clickable hoverPrimary" style={{ color: '#b7b7b7' }} onClick={() => {
                                            window.open(`/paymentDetails?id=${p.fid}`, '_blank')
                                        }} />
                                    </Space >
                                    <div className="pill">
                                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{returnCurrencyValue(p.amount / 100)} {p.currency?.toUpperCase()}</Typography.Text>
                                    </div>
                                </div >
                            </Row >
                        </div >
                    </Col >
                })
                }
            </Row >
            <Row hidden justify="space-between" align="middle" onClick={() => setShowDetails(!showDetails)} className="clickable" style={{
                marginTop
                    : '25px'
            }}>
                <Typography.Title level={5} style={{ margin: 0 }}>{!showDetails ? 'Mostrar' : 'Ocultar'} detalles de la factura</Typography.Title>
                <EyeOutlined />
            </Row>
            {
                showDetails && <Descriptions bordered style={{ marginTop: '15px' }}>
                    <Descriptions.Item label="Tipo de CDFI">{relatedTo.type === 'I' ? 'Ingreso' : relatedTo.type === 'P' ? 'Pago' : relatedTo.type === 'E' ? 'Egreso / Nota de crédito' : ''}</Descriptions.Item>
                    <Descriptions.Item label="Total">{amounts.totalString}</Descriptions.Item>
                    <Descriptions.Item label="Fecha de emisión">{moment(relatedTo.timestamp).format('DD/MM/YYYY HH:mm')}</Descriptions.Item>
                    <Descriptions.Item label="Estado">{InvoiceStatusReadable(relatedTo.status)}</Descriptions.Item>
                    <Descriptions.Item label="UUID">{relatedTo.uuid}</Descriptions.Item>
                    {!relatedTo.livemode && <Descriptions.Item label="Modo">Pruebas</Descriptions.Item>}

                </Descriptions>
            }
            {
                composeEmail && <ComposeEmailModal close={() => setComposeEmail(null)} actionText={
                    null
                } idRef={'payments'} data={{ ...composeEmail, payment: composeEmail }} userDocument={userDocument} open={composeEmail} element={composeEmail} subject={`Solicitud de pago por ${getItemsAmounts(composeEmail.items, false).totalString}`} readableType={'pago'} client={composeEmail.client ?? { name: 'Sin Cliente', email: '', }} />
            }
        </Col >
    );
}

export default PaymentsRelatedTo;