import { FlagOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import React from 'react';


function NoticeToStart({ setStep }) {



    return <>
        <Row justify="center" align="middle">
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <p className='russo primary'>gigstack pro</p>
                    <Typography.Text type="secondary" style={{ fontSize: '10px' }} className="secondary">1/4</Typography.Text>
                </Row>
                <FlagOutlined style={{ marginRight: '10px', fontSize: '25px', marginBottom: '15px' }} />
                <div className="d-flex flex-column" style={{ margin: '0px 5%' }}>
                    <Typography.Title level={3} style={{ padding: 0, margin: 0, textAlign: 'center', marginBottom: '15px' }}>¡Vamos a crear tu cuenta!</Typography.Title>
                    <Typography.Text level={4} style={{ textAlign: 'center', marginTop: '8px' }} type="secondary">Estás a 4 pasos de que tu empresa ahorre hasta 90% de tiempo en tareas administrativas del día a día. ¡Vamos!</Typography.Text>
                </div>

                <Row justify="center" style={{ marginTop: '35px' }}>
                    <Button type="primary" onClick={() => setStep(1)}>Comenzar</Button>
                </Row>
            </div>
        </Row >
    </>
}

export default NoticeToStart;