

import { Button, message, Modal, Row, Typography } from 'antd';
import { logEvent } from 'firebase/analytics';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useState } from 'react';
import { useContext } from 'react';
import { useAnalytics, useAuth, useFirestore } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { DownloadInvoice, generateCode } from '../../app/functions/helpers';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import ElementCreated from '../states/ElementCreated';
import LoadingAndResponse from '../states/LoadingAndResponse';

function TestInvoiceModal({ open, close, invoice }) {

  const auth = useAuth();
  const { team, getCurrentTeam } = useContext(TeamContext)
  const { billingAccount } = useContext(BillingAccountContext)
  const analytics = useAnalytics()

  const ref = doc(useFirestore(), 'teams', team.id)

  const [loading, setloading] = useState(false)
  const [downloading, setdownloading] = useState(false)
  const [step, setstep] = useState(1)
  const [invoiceOk, setinvoiceOk] = useState(false)
  const [invoiceError, setinvoiceError] = useState('')
  const [inv, setInv] = useState({})

  const CreateTestInvoice = async () => {
    const code = generateCode(10, 'inv')
    const invoice = {
      team: team.id,
      billingAccount: billingAccount.id,
      type: "create_invoice",
      invoiceType: "I",
      payment_method: "PUE",
      payment_form: "01",
      use: "S01",
      series: "TEST",
      folio_number: 1,
      currency: "mxn",
      exchange: 1,
      related: [],
      emails: null,
      test: true,
      automations: null,
      internalClient: {
        testInvoicesCreated: 0,
        tax_system: {
          value: "616",
          label: "Sin obligaciones fiscales"
        },
        name: "PUBLICO EN GENERAL",
        legal_name: "PUBLICO EN GENERAL",
        legalName: "PUBLICO EN GENERAL",
        email: null,
        id: code,
        phone: "",
        address: {
          zip: "85900"
        },
        RFCvalidated: true,
        efos: {
          is_valid: true,
          data: {
            detalles: [],
            mensaje: "La consulta para el rfc especificado no devolvió resultados"
          }
        },
        owner: auth.currentUser.uid,

        zip: "85900",
        rfc: "XAXX010101000",
        invoicesCreated: 0,
        timestamp: moment().valueOf(),
      },
      proposal: null,
      payment: null,
      invoice: null,
      date: moment().format("YYYY-MM-DD"),
      owner: auth.currentUser.uid,
      v4: true,
      global: {
        periodicity: 'day',
        months: (moment().month() + 1) < 10 ? '0' + (moment().month() + 1) : (moment().month() + 1).toString(),
        year: Number(moment().year()),
      },
      client: {
        testInvoicesCreated: 0,
        tax_system: "616",
        name: "PUBLICO EN GENERAL",
        legal_name: "PUBLICO EN GENERAL",
        legalName: "PUBLICO EN GENERAL",
        email: "",
        id: generateCode(10, 'client'),
        phone: "",
        address: {
          zip: "85900"
        },
        RFCvalidated: true,
        efos: {
          is_valid: true,
          data: {
            detalles: [],
            mensaje: "La consulta para el rfc especificado no devolvió resultados"
          }
        },
        owner: auth.currentUser.uid,
        zip: "85900",
        rfc: "XAXX010101000",
        invoicesCreated: 0,
        timestamp: moment().valueOf(),
        tax_id: "XAXX010101000"
      },
      clientID: code,
      items: [
        {
          quantity: 1,
          product: {
            description: "Producto de prueba",
            product_key: "01010101",
            price: 1,
            tax_included: false,
            taxes: [
              {
                type: "IVA",
                factor: "Tasa",
                rate: 0.16,
                withholding: false
              }
            ],
            unit_key: "E48"
          }
        }
      ],
      internalItems: [
        {
          from: "stripe",
          timestamp: 1675206442916,
          owner: auth.currentUser.uid,
          hours: null,
          name: "Producto de prueba",
          quantity: 1,
          taxes: [
            {
              "rate": 0.16,
              "type": "IVA",
              "factor": "Tasa",
              "withholding": false,
              "inclusive": false
            }
          ],
          total: 1,
          product_key: "01010101",
          paymentType: {
            value: "fixed",
            label: "Tarifa fija"
          },
          id: generateCode(10, 'client')
        }
      ]
    }

    try {
      setloading(true)
      const req = { invoicingIntegration: 'facturapi', type: 'create_invoice', test: true, ...invoice }

      const resp = await SignedInternalAPIRequest(req, 'invoicing', auth.currentUser)
      setInv(resp)

      if (resp.error) {
        setstep(step + 1)
        setinvoiceError(resp.error?.message)
        setloading(false)
        setinvoiceOk(false)
        return
      }

      try {
        const integrationsToSetup = (await getDoc(ref))?.data()?.integrationsSetup?.filter(int => int !== "testInvoice") ?? []
        await updateDoc(ref, { integrationsSetup: integrationsToSetup })
        getCurrentTeam(team?.id)
      } catch (error) {

      }

      setloading(false)
      message.success('Factura creada correctamente')
      logEvent(analytics, 'testInvoiceCreated', {});
      setstep(step + 2)
      setinvoiceOk(true)
      setloading(false)
      return
    } catch (error) {
      message.error(error.message)
      setinvoiceError(error.error)
      setinvoiceOk(false)
      setloading(false)
      setstep(step + 1)
    }
  }

  const ToRender = () => {
    switch (step) {
      case 1:
        return <Typography.Text type="secondary">Para probar que todos tus datos son correctos emitiremos una factura de prueba, ¡no te preocupes!, esta factura no tendrá validez fiscal</Typography.Text>

      case 2:
        return <Suspense fallback={<></>}>
          <LoadingAndResponse loading={false} isError={!invoiceOk} errorMessage={invoiceError} backAction={() => setstep(step - 1)} />
        </Suspense>
      case 3:
        return <ElementCreated />
      default:
        break;
    }
  }

  return (<Modal visible={open} onCancel={() => close()} footer={null} className={'pro-modal pro-modal-transp'}>
    <div className={'pro-modal-header'}>
      <Typography.Title level={4} style={{ padding: 0, margin: 0, }}>Crear factura de prueba <span style={{ color: '#b7b7b7', fontSize: '16px', margin: 0, padding: 0 }}></span></Typography.Title>
      <Typography.Text style={{ padding: 0, margin: 0, marginBottom: '15px' }}></Typography.Text>
      <Row style={{ marginTop: '20px' }} justify='center'>
        <ToRender />
      </Row>
      <Row justify='center' style={{ marginTop: '55px' }}>
        {step !== 2 && <Button loading={loading} type='primary' onClick={() => {
          if (step === 1) CreateTestInvoice()
          if (step === 3) close()
        }} >{step === 1 ? "Emitir factura" : "Cerrar"}</Button>}
        {step === 3 && <Button loading={downloading} style={{ marginLeft: 4 }} type='secondary' onClick={() => {
          DownloadInvoice({ invoice: inv, type: 'pdf', setdownloading: (v) => setdownloading(v), authUser: auth.currentUser })
          setTimeout(() => {
            close()
          }, 5000);
        }}>Descargar PDF</Button>}
      </Row>
    </div>
  </Modal>);
}

export default TestInvoiceModal;