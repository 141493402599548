import { Button, Checkbox, Col, Divider, Form, Input, Layout, message, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
// import twl from "../../assets/images/twl.svg";
import googl from "../../assets/images/googl.svg";
// import fbl from "../../assets/images/fbl.svg";
import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAnalytics, useAuth } from "reactfire";
import { ReadableFirebaseErrorMessage, SearchParamInURL } from "../../app/functions/helpers";
import { logEvent } from "firebase/analytics";
// import logo from "../../assets/images/logogigstack.png";
import bluredImage from "../../assets/images/BluredBackground.png";

function RegisterWidget({ changeRender }) {
    const [loading, setLoading] = useState(null)
    //TODO cambie const por let 
    let redirect = SearchParamInURL("redirect")
    const auth = useAuth();
    const analytics = useAnalytics();

    const [form] = Form.useForm();



    const MakeGoogleSignIn = async () => {
        setLoading("google")

        setTimeout(() => {
            const provider = new GoogleAuthProvider();

            provider.addScope("profile");
            provider.addScope("email");
            // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
            logEvent(analytics, "auth", { type: "google" });
            signInWithPopup(auth, provider)
                .then(async (result) => {

                    const user = result.user;

                    if (redirect) {
                        window.location.replace(redirect)
                    }
                    return user
                }).catch((error) => {
                    setLoading(false)
                    return message.error(ReadableFirebaseErrorMessage(error))

                });
        }, 300)
    }

    const searchRedirect = () => {
        const token = SearchParamInURL("token")
        if (token) {
            redirect = `${window.location.origin}/invites?token=${token}`
        }
    }

    useEffect(() => {
        searchRedirect()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])


    // const MaketFBSignIn = async () => {
    //     setLoading("Facebook")
    //     const provider = new FacebookAuthProvider();

    //     provider.setCustomParameters({
    //         "allow_signup": "false"
    //     });
    //     setTimeout(() => {
    //         logEvent(analytics, "auth", { type: "Facebook" });
    //         signInWithPopup(auth, provider)
    //             .then(async (result) => {
    //                 const user = result.user;


    //                 if (redirect) {
    //                     window.location.replace(redirect)
    //                 }
    //                 return user
    //             }).catch((error) => {
    //                 setLoading(false)
    //                 message.error(ReadableFirebaseErrorMessage(error))
    //                 return;
    //             });
    //     }, 300)

    // }
    // const MaketTWSignIn = async () => {
    //     setLoading("twitter")
    //     const provider = new TwitterAuthProvider();

    //     provider.setCustomParameters({
    //         "allow_signup": "false"
    //     });
    //     logEvent(analytics, "auth", { type: "twitter" });
    //     signInWithPopup(auth, provider)
    //         .then(async (result) => {
    //             const user = result.user;


    //             if (redirect) {
    //                 window.location.replace(redirect)
    //             }
    //             return user
    //         }).catch((error) => {
    //             setLoading(false)
    //             message.error(ReadableFirebaseErrorMessage(error))
    //             return

    //         });


    // }




    // const SocialLoginIcon2 = ({ img, action = () => { }, provider = "", title, top = "25px" }) => {
    //     return <Row onClick={action} justify=" center" style={{ width: "100%", marginTop: top }}>
    //         <div className="d-flex flex-row clickable hoverPrimary" style={{
    //             border: "1px solid #F6F7FD", padding: "7px 5px", borderRadius: "10px", width: window.innerWidth < 801 ? "90%" : "80%", alignItems: "center", justifyContent: "start", paddingLeft: "27%",

    //         }} >
    //             {(loading === provider && loading) ? <LoadingOutlined style={{ fontSize: "30px" }} /> : <img alt={provider} src={img} style={{ width: "25px", height: "25px", marginRight: "10px" }} />}
    //             <Typography.Text type="secondary" >{title}</Typography.Text>
    //         </div >
    //     </Row >
    // }
    const SocialLoginIcon = ({ img, action = () => { }, provider = "", title, top = "25px" }) => {
        return <div style={{
            justifyItems: 'center',
            alignContent: 'center',
            marginTop: '20px'
        }} className="d-flex flex-row">
            {(loading === provider && loading) ? <LoadingOutlined style={{ fontSize: "20px" }} /> : <img onClick={action} className="clickable" alt={provider} src={img} style={{ width: "35px", height: "35px", marginRight: "10px" }} />}
        </div>

        // <Row onClick={action} justify=" center" style={{ width: "100%", marginTop: top }}>
        //     <div className="d-flex flex-row clickable hoverPrimary" style={{ border: "1px solid #F6F7FD", padding: "7px 5px", borderRadius: "10px", width: window.innerWidth < 801 ? "90%" : "80%", alignItems: "center", justifyContent: "start", paddingLeft: "23%" }} >
        //         {(loading === provider && loading) ? <LoadingOutlined style={{ fontSize: "20px" }} /> : <img alt={provider} src={img} style={{ width: "25px", height: "25px", marginRight: "10px" }} />}
        //         <Typography.Text type="secondary" >{title}</Typography.Text>
        //     </div>
        // </Row >
    }

    //TODO: CHECK SIZE WHEN SMALL SCREENS
    return (
        <Layout>
            <Layout.Content style={{ width: "100hw", height: "100vh", maxHeight: "100vh" }}>
                <Row style={{ height: "100%" }}>
                    <Col XS={0} lg={10} style={{ height: "100%" }}>
                        <div style={{ height: "100%", backgroundImage: `url(${bluredImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", alignItems: "start", justifyContent: "center", padding: '0px 24px' }} className="d-flex flex-column" >
                            {/* <img src={logo} height="50px" alt="gigstack" style={{ alignSelf: "start" }} /> */}
                            <Typography.Title level={2}>Automatiza la administración de tu negocio</Typography.Title>
                            <Typography.Text style={{ fontSize: '17px' }}>Ahorra hasta 90% de tiempo en tareas repetitivas</Typography.Text>
                        </div>
                    </Col >
                    <Col xs={24} lg={14} className="d-flex flex-column" style={{ alignItems: "center", justifyContent: "center", height: "100%", maxHeight: "100%", overflow: 'auto' }}>
                        <div id="registerForm"
                            style={{
                                width: '100%',

                                height: '100%',
                                maxHeight: '100%',
                                padding: '20px 10%'
                            }}
                        >
                            <div className="d-flex flex-column" style={{
                                width: '100%', height: 'auto', overflow: 'auto', backgroundColor: 'white', padding: '50px', borderRadius: '10px', boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.05)",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >
                                {/* <img src={logo} height="30px" alt="gigstack" style={{ alignSelf: "start" }} /> */}
                                <Typography.Text className="russo primary" style={{ fontSize: '15px' }}>gigstack pro</Typography.Text>
                                <Typography.Title level={4} style={{ margin: 0, paddingTop: "15px", }}>Crea una cuenta</Typography.Title>
                                <Typography.Text type="secondary">Automatiza tu negocio en minutos, ¡creemos una cuenta!</Typography.Text>
                                {/*TODO: CENTER ELEMENT */}
                                <div className="d-flex flex-column" style={{ width: '80%', justifyContent: 'center', alignItems: 'center', backgroundColor: '' }}>
                                    <Space>
                                        <SocialLoginIcon title="Continuar con Google" img={googl} action={MakeGoogleSignIn} provider="google" />
                                        {/* <SocialLoginIcon title="Continuar con Twitter" img={twl} action={MaketTWSignIn} provider="twitter" top="10px" /> */}
                                        {/* <SocialLoginIcon title="Continuar con Facebook" img={fbl} action={MaketFBSignIn} provider="Facebook" top="10px" /> */}
                                    </Space>
                                </div>
                                < Divider />
                                <Form layout="vertical" form={form}
                                    onFinish={async (v) => {
                                        try {
                                            setLoading("email")
                                            await createUserWithEmailAndPassword(auth, v.email, v.password)

                                            if (redirect) {
                                                return window.location.replace(redirect)
                                            } else {
                                                // return window.location.replace("/")
                                            }

                                        } catch (error) {
                                            setLoading(null)
                                            message.error(ReadableFirebaseErrorMessage(error))
                                        }
                                    }}
                                    style={{
                                        width: window.innerWidth < 801 ? "90%" : "80%"
                                    }}>
                                    <Form.Item name="email" label="Correo electrónico" rules={[{
                                        required: true,
                                        type: "email",
                                        message: "Ingresa con un correo válido",

                                    }]}>
                                        <Input className="showInputBorder" type="email" prefix={
                                            <UserOutlined style={{ color: "#E7E7E7" }} />
                                        } />
                                    </Form.Item>
                                    <Form.Item name="password" label="Contraseña" rules={[{
                                        required: true,
                                        message: "Ingresa tu contraseña",

                                    }]} >
                                        <Input.Password onChange={() => form.validateFields()} className="showInputBorder" type="email" prefix={
                                            <LockOutlined style={{ color: "#E7E7E7" }} />
                                        } style={{ padding: '4px 10px' }} />
                                    </Form.Item>
                                    <Form.Item style={{ margin: 0 }} name="confirmPassword" label="Confirma tu contraseña" rules={[{
                                        required: true,
                                        message: "Añade una contraseña"


                                    }, {
                                        validator: (_, value) =>
                                            value === form.getFieldValue("password") ? Promise.resolve() : Promise.reject(new Error("Las contraseñas no coinciden")),
                                    }]}>
                                        <Input.Password className="showInputBorder" type="email" prefix={
                                            <LockOutlined style={{ color: "#E7E7E7" }} />
                                        } style={{ padding: '4px 10px' }} />
                                    </Form.Item>


                                    <Form.Item name="termsAccepted" valuePropName="checked"
                                        style={{ marginTop: '15px' }}
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error("Por favor acepta los términos y condiciones.")),
                                            },
                                        ]}>

                                        <Checkbox>Acepto que leí y estoy de acuerdo con los <a target="_blank" rel="noreferrer" href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf">términos y condiciones</a>  </Checkbox>
                                    </Form.Item >


                                    <Row justify=" center">
                                        <Button type="primary" style={{
                                            marginTop: "15px",
                                            width: "100%"
                                        }}
                                            htmlType="submit"
                                            loading={loading === "email"}
                                            disabled={loading && loading !== "email"}
                                        >Crear cuenta</Button>
                                    </Row >
                                </Form >


                                <Row justify=" center" style={{ width: "100%", marginTop: "20px" }}>
                                    <Typography.Text type="secondary" style={{ flex: 1, textAlign: 'center' }}>¿Ya tienes cuenta? <span className="primary strong clickable"
                                        onClick={() => {
                                            window.location.href = `/login${redirect ? `?redirect=${window.location.href.split('redirect=')[1]}` : ""}`
                                        }}
                                    >Iniciar sesión</span></Typography.Text >
                                </Row >
                            </div>
                        </div>


                    </Col >
                </Row >
            </Layout.Content >
        </Layout >
    )
}

/*
 <div id="registerForm" style={{
                            width: window.innerWidth < 800 ? "90%" : "90%", backgroundColor: "white", padding: "35px", borderRadius: "10px",
                            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.05)",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "auto",
                            height: "auto"


                        }} className="d-flex flex-column">
                            <img src={logo} height="30px" alt="gigstack" style={{ alignSelf: "start" }} />
                            <Typography.Title level={4} style={{ margin: 0, paddingTop: "15px", }}>Crea una cuenta</Typography.Title>
                            <Typography.Text type="secondary">Automatiza tu negocio en minutos, ¡creemos una cuenta!</Typography.Text>
                            <SocialLoginIcon title="Continuar con Google" img={googl} action={() => { }} provider="google" />
                            <SocialLoginIcon title="Continuar con Twitter" img={twl} action={() => { }} provider="twitter" top="10px" />
                            < SocialLoginIcon title="Continuar con Facebook" img={fbl} action={() => { }} provider="Facebook" top="10px" />
                            < Divider />

                            <Form layout="vertical" form={form} style={{
                                width: window.innerWidth < 801 ? "90%" : "80%"
                            }}>
                                <Form.Item name="email" label="Correo electrónico" rules={[{
                                    required: true,
                                    type: "email",
                                    message: "Ingresa con un correo válido",

                                }]}>
                                    <Input className="showInputBorder" type="email" prefix={
                                        <UserOutlined style={{ color: "#E7E7E7" }} />
                                    } />
                                </Form.Item>
                                <Form.Item name="password" label="Contraseña" rules={[{
                                    required: true,
                                    message: "Ingresa tu contraseña",

                                }]} >
                                    <Input.Password onChange={() => form.validateFields()} className="showInputBorder" type="email" prefix={
                                        <LockOutlined style={{ color: "#E7E7E7" }} />
                                    } />
                                </Form.Item>
                                <Form.Item style={{ margin: 0 }} name="confirmPassword" label="Confirma tu contraseña" rules={[{
                                    required: true,
                                    message: "Añade una contraseña"


                                }, {
                                    validator: (_, value) =>
                                        value === form.getFieldValue("password") ? Promise.resolve() : Promise.reject(new Error("Las contraseñas no coinciden")),
                                }]}>
                                    <Input.Password className="showInputBorder" type="email" prefix={
                                        <LockOutlined style={{ color: "#E7E7E7" }} />
                                    } />
                                </Form.Item>


                                <Form.Item name="termsAccepted" valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error("Por favor acepta los términos y condiciones.")),
                                        },
                                    ]}>
                      
<Checkbox>Acepto que leí y estoy de acuerdo con los <a target="_blank" rel="nonreferrer" href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf">términos y condiciones</a>  </Checkbox>
                                </Form.Item >


    <Row justify=" center">
        <Button type="primary" style={{
            marginTop: "25px",
            width: "100%"
        }}
            loading={loading === "email"}
            disabled={loading && loading !== "email"}
        >Iniciar sesión</Button>
    </Row >
                            </Form >


    <Row justify=" center" style={{ width: "100%", marginTop: "100px" }}>
        <Typography.Text type="secondary">¿Ya tienes cuenta? <span className="primary strong clickable"
            onClick={() => {
                window.location.href = `/login${redirect ? `?redirect=${redirect}` : ""}`
            }}
        >Iniciar sesión</span></Typography.Text >
    </Row >
                        </div >


*/

// function RegisterWidget({ changeRender }) {
//     const analytics = useAnalytics();

//     const [loading, setloading] = useState(null)

//     const auth = useAuth()



//     let redirect = SearchParamInURL("redirect")

//     useEffect(() => {

//         const token = SearchParamInURL("token")

//         if (token) {
//             // eslint-disable-next-line react-hooks/exhaustive-deps
//             redirect = `http://localhost:3000/invites?token=${token}`
//         }
//     }, [])


//     //SOCIAL LOGINS

//     /**
//      * A function that is used to sign in with the different social media platforms.
//      */
//     const MakeGoogleSignIn = async () => {
//         setloading("google")

//         setTimeout(() => {
//             const provider = new GoogleAuthProvider();

//             provider.addScope("profile");
//             provider.addScope("email");
//             // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
//             logEvent(analytics, "auth", { type: "google" });
//             signInWithPopup(auth, provider)
//                 .then(async (result) => {

//                     const user = result.user;

//                     if (redirect) {
//                         window.location.replace(redirect)
//                     }
//                     return user
//                 }).catch((error) => {
//                     return message.error(ReadableFirebaseErrorMessage(error))

//                 });
//         }, 300)
//     }
//     const MaketGitSignIn = async () => {
//         setloading("github")
//         const provider = new GithubAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         setTimeout(() => {
//             logEvent(analytics, "auth", { type: "github" });
//             signInWithPopup(auth, provider)
//                 .then(async (result) => {

//                     const user = result.user;

//                     if (redirect) {
//                         window.location.replace(redirect)
//                     }
//                     return user
//                 }).catch((error) => {
//                     return message.error(ReadableFirebaseErrorMessage(error))

//                 });
//         }, 300)

//     }
//     const MaketFBSignIn = async () => {
//         setloading("Facebook")
//         const provider = new FacebookAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         setTimeout(() => {
//             logEvent(analytics, "auth", { type: "Facebook" });
//             signInWithPopup(auth, provider)
//                 .then(async (result) => {
//                     const user = result.user;


//                     if (redirect) {
//                         window.location.replace(redirect)
//                     }
//                     return user
//                 }).catch((error) => {
//                     message.error(ReadableFirebaseErrorMessage(error))
//                     return;
//                 });
//         }, 300)

//     }
//     const MaketTWSignIn = async () => {
//         setloading("twitter")
//         const provider = new TwitterAuthProvider();

//         provider.setCustomParameters({
//             "allow_signup": "false"
//         });
//         logEvent(analytics, "auth", { type: "twitter" });
//         signInWithPopup(auth, provider)
//             .then(async (result) => {
//                 const user = result.user;


//                 if (redirect) {
//                     window.location.replace(redirect)
//                 }
//                 return user
//             }).catch((error) => {
//                 message.error(ReadableFirebaseErrorMessage(error))
//                 return

//             });


//     }







//     const SocialLoginIcon = ({ img, action = () => { }, provider = "", title }) => {
//         return <Row onClick={action} justify="center">
//             <div className="socialLoginButton">
//                 {(loading === provider && loading) ? <LoadingOutlined style={{ fontSize: "30px" }} /> : <img alt={provider} src={img} style={{ width: "28px", height: "28px", marginRight: "10px" }} />}
//                 <Typography.Text type="secondary" >{title}</Typography.Text>
//             </div>
//         </Row>
//     }
//     return (
//         <Row>
//             <Col XS={24}>
//                 <div className="d-flex flex-column">
//                     <Typography.Title level={4} style={{ margin: 0, padding: 0, marginBottom: "5px" }}>Crea una nueva cuenta</Typography.Title>
//                     <Typography.Text type="secondary">Crear una cuenta en gigstack no requiere de tarjeta de crédito o débito.</Typography.Text>
//                 </div>
//             </Col>
//             <Col XS={24} style={{ marginTop: "15px" }}>
//                 <div className="d-flex flex-column">
//                     <SocialLoginIcon title="Inicia con Google" img={googl} action={() => MakeGoogleSignIn()} provider="google" />
//                     <SocialLoginIcon title="Inicia con Twitter" img={twl} provider="twitter" action={() => MaketTWSignIn()} />
//                     <SocialLoginIcon title="Inicia con Github" img={gitl} provider="github" action={() => MaketGitSignIn()} />
//                     <SocialLoginIcon title="Inicia con Facebook" img={fbl} provider="Facebook" action={() => MaketFBSignIn()} />
//                 </div>

//             </Col>
//             <Col XS={24}>
//                 <Divider />
//             </Col>

//             <Col XS={24}>
//                 <Form layout="vertical" onFinish={async (v) => {
//                     if (!v.email || !v.password) {
//                         return;
//                     }
//                     try {
//                         setloading("email")
//                         await createUserWithEmailAndPassword(auth, v.email, v.password)

//                         if (redirect) {
//                             return window.location.replace(redirect)
//                         } else {
//                             return window.location.replace("/")
//                         }

//                     } catch (error) {
//                         setloading(null)
//                         message.error(ReadableFirebaseErrorMessage(error))
//                     }
//                 }}>
//                     <Form.Item name="email" label="Correo Electrónico" style={{ marginBottom: "10px", paddingBottom: "0" }}>
//                         <Input placeholder="Ingresa tu correo electrónico" />
//                     </Form.Item>
//                     <Form.Item name="password" label="Contraseña" style={{ marginBottom: "10px", paddingBottom: "0" }}>
//                         <Input.Password placeholder="Contraseña de tu cuenta" />
//                     </Form.Item>
//                     <Form.Item
//                         name="agreement"
//                         valuePropName="checked"
//                         rules={[
//                             {
//                                 validator: (_, value) =>
//                                     value ? Promise.resolve() : Promise.reject(new Error("Por favor acepta los términos y condiciones")),
//                             },
//                         ]}

//                     >
//                         <Checkbox>
//                             He leído y acepto los  <a href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/terms.pdf" target="_blank" rel="noreferrer">términos y condiciones</a>
//                         </Checkbox>
//                     </Form.Item>
//                     <Row style={{ marginTop: "15px" }}>
//                         <Button htmlType="submit" loading={loading === "email"} type="primary" style={{ width: "100%" }} >Crear mi cuenta</Button>
//                     </Row>
//                     <Row justify="start" style={{ marginTop: "15px" }}>
//                         <Typography.Text className="" style={{ fontSize: "13px" }}>¿Ya tienes cuenta? <span onClick={() => {
//                             window.history.replaceState(null, ``, `/login${redirect ? `?redirect=${redirect}` : ""}`)

//                             changeRender("login");
//                         }} className="strong primary clickable">Inicia sesión</span></Typography.Text>
//                     </Row>
//                 </Form>
//             </Col>


//         </Row>
//     );
// }

export default RegisterWidget;