import { Col, Input, Row, Space, Typography } from "antd"
import { Suspense, useContext, useState } from "react"
import TeamContext from "../../context/teamcontext/TeamContext"
import EmptyTable from "../states/EmptyTable"
import ViewTemplates from "../templates/ViewTemplates"
import { LoadingOutlined } from "@ant-design/icons"

const EmailsTemplatesDefaults = ({ onSelectedTemplates }) => {

    const { team } = useContext(TeamContext)
    const [addTemplate, setAddTemplate] = useState(null)
    const [selectedTemplates, setSelectedTemplates] = useState({
        invoicesEmailsTemplate: team?.defaults?.invoicesEmailsTemplate ?? null,
        paymentsEmailsTemplate: team?.defaults?.paymentsEmailsTemplate ?? null,
        receiptsEmailsTemplate: team?.defaults?.receiptsEmailsTemplate ?? null,
    })

    return <Col xs={24}>
        {addTemplate && <Suspense fallback={<LoadingOutlined />}>
            <ViewTemplates selected={(t) => {
                setSelectedTemplates({ ...selectedTemplates, [addTemplate]: t.content })
                onSelectedTemplates({ ...selectedTemplates, [addTemplate]: t.content })
                setAddTemplate(null)
            }} open={addTemplate} close={() => setAddTemplate(null)} />
        </Suspense>}
        <Space direction="vertical" style={{ width: '100%' }} size="middle" >
            <div style={{ padding: '15px', backgroundColor: '#FAFBFD', width: '100%', borderRadius: '10px' }}>
                <Row justify="space-between">
                    <Typography.Text style={{ fontWeight: 600 }}>Plantilla para facturas</Typography.Text>
                    {selectedTemplates.invoicesEmailsTemplate && <span className="clickable primary" onClick={() => setAddTemplate('invoicesEmailsTemplate')}>
                        Cambiar plantilla
                    </span>}
                </Row>
                {(selectedTemplates?.invoicesEmailsTemplate) ? <Row style={{ marginTop: '10px' }}>
                    <Input.TextArea rows={5} value={selectedTemplates?.invoicesEmailsTemplate} />
                </Row> : <EmptyTable customMessage="No tienes niguna plantilla configurada" elementName="plantilla de facturas" parentAction={() => setAddTemplate('invoicesEmailsTemplate')} />}
            </div>
            <div style={{ padding: '15px', backgroundColor: '#FAFBFD', width: '100%', borderRadius: '10px' }}>
                <Row justify="space-between">
                    <Typography.Text style={{ fontWeight: 600 }}>Plantilla para solicitudes de pago</Typography.Text>
                    {selectedTemplates.paymentsEmailsTemplate && <span className="clickable primary" onClick={() => setAddTemplate('paymentsEmailsTemplate')}>
                        Cambiar plantilla
                    </span>}
                </Row>
                {(selectedTemplates?.paymentsEmailsTemplate) ? <Row style={{ marginTop: '10px' }}>
                    <Input.TextArea rows={5} value={selectedTemplates?.paymentsEmailsTemplate} />
                </Row> : <EmptyTable customMessage="No tienes niguna plantilla configurada" elementName="plantilla de pagos" parentAction={() => setAddTemplate('paymentsEmailsTemplate')} />}
            </div>
            <div style={{ padding: '15px', backgroundColor: '#FAFBFD', width: '100%', borderRadius: '10px', marginBottom: '15px' }}>
                <Row justify="space-between">
                    <Typography.Text style={{ fontWeight: 600 }}>Plantilla para recibos de venta</Typography.Text>
                    {selectedTemplates.receiptsEmailsTemplate && <span className="clickable primary" onClick={() => setAddTemplate('receiptsEmailsTemplate')}>
                        Cambiar plantilla
                    </span>}
                </Row>
                {(selectedTemplates?.receiptsEmailsTemplate) ? <Row style={{ marginTop: '10px' }}>
                    <Input.TextArea rows={5} value={selectedTemplates?.receiptsEmailsTemplate} />
                </Row> : <EmptyTable customMessage="No tienes niguna plantilla configurada" elementName="plantilla de recibos" parentAction={() => setAddTemplate('receiptsEmailsTemplate')} />}
            </div>
        </Space>
    </Col>
}

export default EmailsTemplatesDefaults