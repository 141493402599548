import { Col, Row, Tabs, Typography } from 'antd';
import React, { useContext } from 'react'
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import UserContext from '../../context/usercontext/UserContext';
// import { ClockCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { TbFileInvoice } from 'react-icons/tb';
import { CiMoneyBill } from 'react-icons/ci';
import RecurrentPayments from '../../widgets/payments/RecurrentPayments';

function Recurrences() {
    const { user: userDocument } = useContext(UserContext);
    return (
        <Row justify="center">



            <Col xs={24}>
                <DefaultHelpPageHeader
                    userDocument={userDocument}
                    title="Recurrencias"
                    description={"Crea elementos de forma recurrente."}
                    extraInSub={null
                        // <Row>
                        //   <div style={{ height: '5px', width: '5px', backgroundColor: 'green', borderRadius: '50%' }}></div>
                        //   <Typography.Text style={{ marginLeft: '5px' }}>Recibiendo eventos de Stripe</Typography.Text>
                        // </Row>
                    }
                />
            </Col>
            <Col xs={24} style={{ marginTop: "25px" }}>
                <Tabs onChange={() => { }} type="card">

                    <Tabs.TabPane tab={<Row align="middle">
                        <CiMoneyBill style={{ marginRight: '5px' }} /><Typography.Text> Pagos recurrentes</Typography.Text>
                    </Row>} key="payments">
                        <RecurrentPayments />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<Row align="middle">
                        <TbFileInvoice style={{ marginRight: '5px' }} /><Typography.Text> Facturas recurrentes</Typography.Text>
                    </Row>} key="invoices">
                        <>facturas</>
                    </Tabs.TabPane>

                </Tabs>
            </Col>

        </Row>
    );
}

export default Recurrences;