import { BuildOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { countryLabeledListWithCodeAlpha3 } from "../../../app/functions/datasets";
import { generalreactSelectStyles } from "../../../app/functions/styles";
import Geocode from "react-geocode";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import EmptyTable from "../../states/EmptyTable";
import UserContext from "../../../context/usercontext/UserContext";
import { generateCode, taxRegimes } from "../../../app/functions/helpers";
import moment from "moment";
import {
  arrayUnion,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useAnalytics, useAuth } from "reactfire";
import BillingAccountContext from "../../../context/billingaccountcontext/BillingAccountContext";
import { logEvent } from "firebase/analytics";
import TeamContext from "../../../context/teamcontext/TeamContext";
const APIK = "AIzaSyA9JDop22AY_lamrn2dKtwhC31LfzWHE5I";

function BillingAccountOnboarding({ step, setStep }) {
  const [loading, setLoading] = useState(false);
  // const [loadingSearch, setLoadingSearch] = useState(false);
  const [searched, setSearched] = useState(false);

  const { billingAccount, getBillingAccount } = useContext(
    BillingAccountContext
  );
  const { getCurrentTeam } = useContext(TeamContext);

  const auth = useAuth();
  const { user, getUserData } = useContext(UserContext);

  // const { user } = useContext(UserContext)
  // const { getBillingAccount, billingAccount } = useContext(BillingAccountContext)

  const fs = getFirestore();

  const analytics = useAnalytics();

  Geocode.setApiKey(APIK);

  const [form] = Form.useForm();

  const Geo = async ({ address }) => {
    try {
      // setLoadingSearch(true)
      const response = await Geocode.fromAddress(address);
      let city,
        state,
        country,
        zip,
        exterior,
        street,
        municipality,
        neighborhood;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "sublocality_level_1":
              municipality =
                response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            case "country":
              country = response.results[0].address_components[i].long_name;
              break;
            case "postal_code":
              zip = response.results[0].address_components[i].long_name;
              break;
            case "street_number":
              exterior = response.results[0].address_components[i].long_name;
              break;
            case "route":
              street = response.results[0].address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      country = country
        .replace("á", "a")
        .replace("é", "e")
        .replace("í", "i")
        .replace("ó", "o")
        .replace("ú", "u")
        .replace("Á", "A")
        .replace("É", "E")
        .replace("Í", "I")
        .replace("Ó", "O")
        .replace("Ú", "U");

      form.setFieldsValue({
        city,
        state,
        country:
          {
            label: countryLabeledListWithCodeAlpha3.find(
              (c) => c.name === country
            ).name,
            value: countryLabeledListWithCodeAlpha3.find(
              (c) => c.name === country
            ).code,
          } ?? null,
        zip,
        exterior,
        street,
        municipality,
        neighborhood: neighborhood ?? "",
      });
      // setLoadingSearch(false)
      setSearched(true);

      return {
        city,
        state,
        country,
        zip,
        exterior,
        street,
      };
    } catch (error) {
      // setLoadingSearch(false)
      setSearched(true);
    }
  };

  const SaveBillingAccount = async (v) => {
    try {

      const code = user?.lastBillingAccountViewed
        ? user?.lastBillingAccountViewed
        : generateCode(15, "ba");

      const billingAccountObj = {
        legalName: v.legalName.toUpperCase() ?? null,
        id: code,
        logo: null,
        teamMembers: 1,
        tax_system_obj: v?.tax_system_obj ?? {
          label: 'Ley general de persona moral',
          value: '601'
        },
        tax_system: v?.tax_system_obj?.value ?? '601',
        timestamp: moment().valueOf(),
        admins: arrayUnion(auth.currentUser.uid),
        currency: v.currency?.value ?? null,
        owner: auth.currentUser.uid,
        masterAdmin: auth.currentUser.uid,
        blocked: false,
        address: {
          city: v.city ?? null,
          street: v.street ?? null,
          state: v.state ?? null,
          country: v.country?.value ?? null,
          countryObj: v.country ?? null,
          exterior: v.exterior ?? null,
          neighborhood: v.neighborhood ?? null,
          zip: v.zip ?? null,
        },
        supportPhone: v.supportPhone ?? null,
        rfc: v.rfc?.toUpperCase() ?? null,
        v: "1",
      };
      var brandCode = user?.lastTeamViewed
        ? user?.lastTeamViewed
        : generateCode(15, "team");

      var userUpdate = {
        onboarded: arrayUnion("billingAccount"),
        billingAccounts: arrayUnion(billingAccountObj.id),
        lastBillingAccountViewed: billingAccountObj.id,
        lastTeamViewed: brandCode,
        teams: arrayUnion(brandCode),
      };
      setLoading(true);
      const promises = [];

      await setDoc(
        doc(fs, `billingAccounts/${code}`),
        { ...billingAccountObj },
        { merge: true }
      );

      await updateDoc(doc(fs, `users/${auth.currentUser.uid}`), userUpdate, {
        merge: true,
      });

      await setDoc(
        doc(fs, `teams/${brandCode}`),
        {
          billingAccount: billingAccountObj.id,
          id: brandCode,
          membersIds: arrayUnion(auth.currentUser.uid),
          rfc: billingAccountObj.rfc,
          legalName: billingAccountObj.legalName,
          timestamp: moment().valueOf(),
          address: billingAccountObj.address,
          owner: user.uid,
          setupComplete: false,
          integrationsSetup: [
            "stripeSetup",
            "invoices",
            "importStripeProducts",
            "importStripePayments",
            "bank",
            "setupBrand",
            "testInvoice",
            "setDefaults",
            "automaticInvoicing"
          ],
          members: arrayUnion({
            id: auth.currentUser.uid,
            isAdmin: true,
            rol: "admin",
            email: auth.currentUser.email,
            payments: "editor",
            invoices: "editor",
            services: "editor",
            expenses: "editor",
          }),
        },
        { merge: true }
      );

      await Promise.all(promises).finally(() => {
        // if (!billingAccount) {
        //
        // }
        getBillingAccount(billingAccountObj.id);
        getCurrentTeam(brandCode);
        getUserData(auth.currentUser.uid);
        logEvent(analytics, "companyInformationSetUp", {});
        setStep(step + 1);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      message.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (billingAccount) {
      setSearched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      form={form}
      initialValues={{
        tax_system_obj: {
          label: 'Ley general de personas morales',
          value: '601'
        },
        ...(billingAccount ?? {}),
        ...(billingAccount?.address ?? {}),
        country:
          billingAccount?.address?.country ?
            {
              label: countryLabeledListWithCodeAlpha3.find(
                (c) => c.code === billingAccount?.address?.country
              )?.name,
              value: billingAccount?.address?.country,
            } : null,
        currency:
          billingAccount?.currency ?
            {
              label: billingAccount?.currency?.toUpperCase(),
              value: billingAccount?.currency?.toUpperCase(),
            } : null,
      }}
      onFinish={SaveBillingAccount}
      layout="vertical"
    >
      <Row justify="space-between">
        <p className="russo primary">gigstack pro</p>
        <Typography.Text
          type="secondary"
          style={{ fontSize: "10px" }}
          className="secondary"
        >
          3/4
        </Typography.Text>
      </Row>

      <Row align="middle">
        <BuildOutlined
          className="primary"
          style={{ marginRight: "20px", fontSize: "30px" }}
        />
        <div className="d-flex flex-column">
          <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>
            ¡Cuenta de facturación!
          </Typography.Title>
          <Typography.Text level={4} type="secondary">
            Usaremos esta información para procesar los pagos, facturas y
            consolidar el uso de las marcas que crees.
          </Typography.Text>
        </div>
      </Row>
      <Divider />
      <Row
        style={{ width: "100%", marginTop: "25px" }}
        gutter={{ xs: 12, md: 12 }}
      >
        <Col xs={24}>
          <Form.Item
            label="Razón social"
            name="legalName"
            rules={[
              { required: true, message: "Por favor añade el nombre legal" },
            ]}
          >
            <Input placeholder="NOMBRE LEGAL DE LA EMPRESA" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="¿RFC ó identificador tributario de la empresa?"
            name="rfc"
            rules={[
              { required: true, message: "Añade el RFC para recibir facturas" },
            ]}
          >
            <Input placeholder="DLE180323RU9" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label="Régimen Fiscal"
            name="tax_system_obj"
            rules={[
              { required: true, message: "Por favor añade el nombre legal" },
            ]}
          >
            <Select
              styles={generalreactSelectStyles}
              className="pro-select-input"
              options={taxRegimes}
              isLoading={false}
              placeholder="Régimen de incorporación.."
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label="¿Teléfono de la empresa?"
            name="supportPhone"
            rules={[{ required: false, message: "" }]}
          >
            <Input placeholder="01 800" />
          </Form.Item>
        </Col>
        <Col xs={24} style={{ marginBottom: "25px" }}>
          <Typography.Text className="cLabel labelRequired">
            Buscar dirección
          </Typography.Text>
          <GooglePlacesAutocomplete
            apiKey={APIK}
            onLoadFailed={(error) => {
            }}
            apiOptions={{ language: "es" }}
            minLengthAutocomplete={10}
            selectProps={{
              styles: generalreactSelectStyles,
              placeholder: "Buscar dirección",
              onChange: (e) => {
                Geo({ address: e.label });
              },
              noOptionsMessage: () => {
                return (
                  <EmptyTable
                    customMessage="No hemos encontrado resultados"
                    parentAction={null}
                    elementType="dirección"
                  />
                );
              },
              loadingMessage: () => {
                return "Buscando...";
              },
            }}
            autocompletionRequest={{
              componentRestrictions: {},
            }}
          />
        </Col>
        {searched && (
          <Col xs={24}>
            <Row gutter={{ xs: 12, md: 15, lg: 20 }}>
              <Col xs={24} md={12} lg={12}>
                <Form.Item
                  label="País de incorporación"
                  name="country"
                  rules={[
                    { required: true, message: "Por favor añade tu país." },
                  ]}
                >
                  <Select
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    options={countryLabeledListWithCodeAlpha3.map((country) => {
                      return {
                        label: country.name,
                        value: country.code,
                      };
                    })}
                    isLoading={false}
                    placeholder="País de la empresa.."
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Item
                  label="Calle"
                  name="street"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input placeholder="De las sirenas" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Item
                  label="Número exterior"
                  name="exterior"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input placeholder="10" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Item
                  label="Colonia"
                  name="neighborhood"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input placeholder="Colonia" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Item
                  label="Ciudad"
                  name="city"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input placeholder="Ciudad de México" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Item
                  label="Código postal"
                  name="zip"
                  rules={[
                    {
                      required: true,
                      message: "Por favor añade tu código postal",
                    },
                  ]}
                >
                  <Input placeholder="10200" />
                </Form.Item>
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Form.Item
                  label="Estado"
                  name="state"
                  rules={[{ required: false, message: "" }]}
                >
                  <Input placeholder="Ciudad de México" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}

        <Col xs={24} lg={12}>
          <Form.Item
            label="Moneda predeterminada"
            name="currency"
            rules={[
              {
                required: true,
                message: "Por favor añade tu moneda de preferencia.",
              },
            ]}
          >
            <Select
              styles={generalreactSelectStyles}
              className="pro-select-input"
              options={[
                { label: "USD", value: "usd" },
                { label: "EUR", value: "eur" },
                { label: "MXN", value: "mxn" },
                { label: "BRL", value: "brl" },
                { label: "CAD", value: "cad" },
                { label: "COP", value: "cop" },
                { label: "CLP", value: "clp" },
                { label: "CZK", value: "czk" },
                { label: "DKK", value: "dkk" },
                { label: "HKD", value: "hkd" },
              ]}
              isLoading={false}
              placeholder="Moneda principal"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Row justify="end">
            <Button
              style={{ marginRight: "15px" }}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Información personal
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              htmlType="submit"
            >
              Continuar
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default BillingAccountOnboarding;
