import { LoadingOutlined, PlusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Popconfirm, Row, Typography, message } from "antd";
import React, { Suspense, useContext, useState } from "react";
import moment from "moment";
import CreateInvoice from "../invoices/CreateInvoice";
import RelateInvoices from "../invoices/RelateInvoices";
import RelationsDrawer from "./RelationsDrawer";
import { useUserPermissions } from "../../customHooks/userPermissions";
import UserContext from "../../context/usercontext/UserContext";
import TeamContext from "../../context/teamcontext/TeamContext";
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext";
import CreateReceiptProcess from "../modals/AutomatiosCreateReceiptModal";
import { paymentForms } from "../../app/functions/helpers";
import { TbCirclesRelation } from 'react-icons/tb'




function InvoicesRelations({ customElement, item, id, client, changeState, collection: incomingCollection, items, elementType, custom, userDocument, readable }) {


    const { user } = useContext(UserContext)
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const [viewRelations, setviewRelations] = useState(false);
    const [associateInvoice, setAssociateInvoice] = useState(false);
    const [addInvoice, setaddInvoice] = useState(false);
    const [showCreateReceipt, setShowCreateReceipt] = useState(false);
    const { canCreateInvoices } = useUserPermissions({ user, team, billingAccount })
    const invoiceItems = [
        {
            key: 'createInvoice',
            label: (
                <Typography.Text className="itemHoverPrimary">Emitir factura</Typography.Text>
            ),
            onClick: () => {

                if (canCreateInvoices) {
                    setaddInvoice(true)
                } else {
                    message.error('No tiene permisos para crear facturas')
                }
            }
        },
        {
            key: 'associateInvoice',
            label: (
                <Typography.Text className="itemHoverPrimary">Asociar factura</Typography.Text>
            ),
            onClick: () => {
                if (canCreateInvoices) {
                    setAssociateInvoice(item)
                } else {
                    message.error('No tiene permisos para crear facturas')
                }
            }
        },
    ]

    const invoices = []
    /* Removing duplicates from the array. */
    const itemInvs = typeof item?.invoices === 'number' ? [] : (item?.invoices ?? [])
    itemInvs?.forEach((inv) => {
        const indx = invoices.findIndex((i) => i === inv)
        if (indx === -1) {
            invoices.push(inv)
        }
    })


    const data = {
        client: item.client,
        items: item?.items ?? item?.internalItems,
        currency: item?.currency,
        livemode: item.livemode,
        periodicity: typeof team?.defaults?.periodicity === 'object' ? team?.defaults?.periodicity.value : team?.defaults?.periodicity ?? 'month',
        payment_form: item.payment_form ? paymentForms.find(p => p.value === item?.payment_form) : null,
        exchange: item?.exchange,
        test: !item.livemode,
        validDays: moment().endOf(team?.defaults?.periodicity ?? 'month').valueOf(),
        validUntil: moment().endOf(team?.defaults?.periodicity ?? 'month').valueOf(),
        type: "create_receipt",
        team: item?.team,
        billingAccount: item?.billingAccount,
        payments: [item?.id],
        paymentId: item?.id,
    }

    if (item?.status === 'succeeded' && [...(item?.receipts ?? [])].length === 0 && invoices?.length === 0 && moment().diff(moment(item?.succeededTimestamp ?? item?.timestamp), 'minutes') > 2) {
        // invoiceItems.push({
        //     key: 'createReceipt',

        // })
        invoiceItems.push({
            key: 'createReceipt',
            label: (
                <Popconfirm
                    title="¿Desea iniciar el proceso de autofactura?"
                    description="Se creará una factura con los datos de la empresa y se asociará a este elemento."
                    onConfirm={() => setShowCreateReceipt(true)}
                    okText="Iniciar"
                    cancelText="Cancelar"
                >
                    <Typography.Text className="itemHoverPrimary">Iniciar auto-factura</Typography.Text>
                </Popconfirm>
            ),
            onClick: () => {
                // setShowCreateReceipt(true)
            }
        })
    }
    // if (
    //     item?.status === 'succeeded' &&
    //     [...(item?.receipts ?? [])].length === 0 &&
    //     invoices?.length === 0 &&
    //     moment().diff(moment(item?.succeededTimestamp ?? item?.timestamp), 'minutes') > 2
    // ) {
    //     invoiceItems.push({
    //         key: 'createReceipt',
    //         label: (
    //             <Popconfirm
    //                 title="¿Desea iniciar el proceso de autofactura?"
    //                 description="Se creará una factura con los datos de la empresa y se asociará a este elemento."
    //                 onConfirm={() => {
    //                     setShowCreateReceipt(true);
    //                 }}
    //                 okText="Iniciar"
    //                 cancelText="Cancelar"
    //             >
    //                 <Typography.Text className="itemHoverPrimary">Iniciar auto-factura</Typography.Text>
    //             </Popconfirm>
    //         ),
    //         onClick: () => {
    //             // No se ejecutará inmediatamente, solo se agregará a una cola de tareas
    //             // y se ejecutará después de que el usuario confirme el Popconfirm.
    //         },
    //     });
    // }

    if (!item) {
        return <></>
    }

    return (
        <>
            {addInvoice && <Suspense fallback={<LoadingOutlined />}>
                <CreateInvoice refreshUserDocument={true} open={addInvoice} close={(v) => {
                    setaddInvoice(false)
                    // console.log('Closing modal')
                }} data={{
                    client: item?.internalClient,
                    items: item?.items,
                    ...item,
                    [elementType]: item
                }} userDocument={userDocument} type="invoice" />
            </Suspense>}
            {viewRelations && <Suspense fallback={<LoadingOutlined />}>
                <RelationsDrawer userDocument={userDocument} open={viewRelations} render="invoices" relation={viewRelations} close={() => setviewRelations(null)} />
            </Suspense>}
            {associateInvoice && <Suspense fallback={<LoadingOutlined />}>
                <RelateInvoices addOn={incomingCollection} userDocument={userDocument} elementType={elementType} open={associateInvoice} close={() => setAssociateInvoice(false)} element={item} />
            </Suspense>}
            {showCreateReceipt && <Suspense fallback={<LoadingOutlined />}>
                <CreateReceiptProcess open={true} close={() => setShowCreateReceipt(false)} data={data} endpoint='receiptsApp/v1/create' />
            </Suspense>
            }
            {customElement ?
                <div style={{ width: '100%' }} onClick={() => {
                    setaddInvoice(true)
                }}>{customElement}</div> : custom ? <Row style={{ width: '100%' }} align="middle">
                    <div style={{ flex: 1 }}>
                        <Dropdown overlay={<Menu items={invoiceItems} />} placement="bottom">
                            <Button size="small" type="link" style={{ border: 'none' }}>
                                <Row justify="center" align="middle">
                                    {invoices?.length > 0 ? <PlusCircleOutlined style={{ fontSize: '12px', color: "#757575" }} /> : <PlusCircleFilled style={{ fontSize: '12px' }} />}
                                    <span style={{ marginTop: 'auto', fontSize: '14px', marginLeft: 5, color: "#757575" }}>{invoices?.filter(i => i !== null)?.length > 0 ? `${invoices?.filter(i => i !== null)?.length} factura${invoices?.length > 1 ? 's' : ''} ` : <span className="primary">Emitir</span>}</span>
                                </Row>
                            </Button>

                        </Dropdown>
                        {/* {(item?.status === 'succeeded' && [...(item?.receipts ?? [])].length === 0 && invoices?.length === 0 && moment().diff(moment(item?.succeededTimestamp ?? item?.timestamp), 'minutes') > 2) && <Tooltip title='Iniciar proceso de autofactura' placement="right">
                            <Popconfirm
                                title="¿Desea iniciar el proceso de autofactura?"
                                description="Se creará una factura con los datos de la empresa y se asociará a este elemento."
                                onConfirm={() => setShowCreateReceipt(true)}
                                okText="Iniciar"
                                cancelText="Cancelar"
                            >
                                <Button
                                    size="small"
                                    style={{ flex: 1, marginLeft: '5px' }}
                                    type={'ghost'}
                                >
                                    <FileDoneOutlined />
                                </Button>
                            </Popconfirm>
                        </Tooltip>} */}
                    </div>
                    {(item.invoicesCount > 0 || item.invoices?.length > 0) &&
                        <TbCirclesRelation
                            onClick={() => setviewRelations({ title: `Facturas relacionadas al ${readable ?? 'elemento'}`, type: 'payments', db: incomingCollection, relatedWith: item, count: item.invoicesCount, elementID: id, subdb: 'invoices', item })}
                            style={{ fontSize: '20px', color: '#b7b7b7', }}
                            className="clickable itemHoverPrimary" />}
                </Row>

                    //END CUSTOM
                    :
                    //START FULL

                    <Row justify="space-between">
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>{(invoices?.length ?? 0)}  Facturas asociadas</Typography.Text>
                            <div>
                                <Button
                                    disabled={false}
                                    onClick={() => setaddInvoice(true)}
                                    type="primary"
                                    size="small"
                                    style={{ fontSize: '11px', marginTop: '5px', marginRight: '10px' }}>
                                    Crear factura

                                </Button>
                                <Button
                                    onClick={() => setAssociateInvoice(item)}
                                    type="ghost"
                                    size="small"
                                    style={{ fontSize: '11px', marginTop: '5px', marginLeft: '5px' }} >
                                    Asociar factura
                                </Button>
                            </div>
                        </div>
                        {(item.invoicesCount > 0 || item.invoices?.length > 0) &&
                            <TbCirclesRelation
                                onClick={() => {
                                    setviewRelations({
                                        title: `Facturas relacionadas al pago`,
                                        type: 'invoices',
                                        db: incomingCollection,
                                        relatedWith: item,
                                        count: item?.invoicesCount,
                                        elementID: item?.id,
                                        subdb: 'invoices',
                                        item: item
                                    })
                                }}
                                style={{ fontSize: '20px', color: '#b7b7b7' }}
                                className="clickable itemHoverPrimary" />}
                    </Row>}

        </>
    );
}

export default InvoicesRelations;
