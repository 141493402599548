import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Row, Typography } from 'antd';
import React, { useState } from 'react'
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import { useAnalytics, useAuth } from 'reactfire';
import HelperCard from '../cards/HelperCard';
import marianahb from '../../assets/images/marianahb.png'
import { logEvent } from 'firebase/analytics';


function ConektaModal({ open, close, userDocument }) {

    const auth = useAuth()
    const analytics = useAnalytics()

    const [formRef] = Form.useForm();
    const [loading, setLoading] = useState(false)


    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Row justify="space-between">
                    <Typography.Title level={4}>Agrega tu cuenta de Conekta</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => close()} />
                </Row>
                <Typography.Text type="secondary" >Consigue tus credenciales en <a href="https://panel-beta.conekta.com/developers" target="_blank" rel="noreferrer">https://panel-beta.conekta.com/developers</a>.</Typography.Text>
                <a href="https://gigstack.notion.site/Conecta-tu-cuenta-de-Conekta-76c815a2d6404aa585bce09dc07b5308" target="_blank" rel="noreferrer" style={{ fontSize: '13px', marginTop: '15px' }} className="strong">Aprende a configurar Conekta haciendo click aquí</a>
                <HelperCard title="¿Necesitas ayuda para conectar tu cuenta?" description="Agenda una llamada conmigo y puedo ayudarte, prometo que no tardaremos ni 15 min." supportImageUrl={marianahb} onClick={() => {
                    window.open('https://meetings.hubspot.com/mariana-barreto1', '_blank')
                }} />
                <Form form={formRef} initialValues={{ ...userDocument?.conekta }} layout="vertical" onFinish={async (v) => {
                    try {
                        setLoading(true)
                        await SignedInternalAPIRequest({
                            ...v
                        }, 'connectConekta', auth.currentUser, {

                        })
                        setLoading(false)
                        logEvent(analytics, 'conektaConfigured', {});
                        message.success('Cuenta de Conekta conectada correctamente')
                        close()
                    } catch (error) {
                        setLoading(false)
                        message.error(error.message)
                    }
                }} >

                    <Form.Item label="API Key privada" name="private_key" style={{ marginTop: '20px' }} rules={[{ required: true, message: 'Clave secreta' }]}>
                        <Input className="pro-input" placeholder="key___" />
                    </Form.Item>
                    <Form.Item label="API Key pública" name="public_key" rules={[{ required: true, message: 'Añade tu API Key pública' }]}>
                        <Input className="pro-input" placeholder="key__" />
                    </Form.Item>




                    <Row justify='center'>

                        <Button loading={loading} type="primary" htmlType="submit">Guardar</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default ConektaModal;