import { Modal, Form, Input, Checkbox, Row, Button, Typography } from 'antd';
import React, { useState } from 'react';
import Select from 'react-select';
import { generalreactSelectStyles } from '../../app/functions/styles';



function ServiceTaxModal(props) {

    const [withholding, setwithholding] = useState(false)


    const localTax = props?.localTax ?? false


    return (
        <Modal wrapClassName="pro-modal" footer={null} onCancel={() => props.close()} style={{ top: 15 }} destroyOnClose={true} visible={props.open} >
            <div style={{ padding: '35px 20px', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                <Typography.Title level={4}>Añadir impuesto {localTax ? "local" : ""}</Typography.Title>
                <Form layout="vertical" onFinish={(v) => {
                    const taxToAdd = localTax ? { ...v, inclusive: true } : { ...v, type: v.type.value, factor: v.factor.value, inclusive: v.inclusive.value, withholding: v.withholding ?? false }
                    if (taxToAdd.type === 'IEPS') {
                        taxToAdd.ieps_mode = 'break_down'
                    }
                    props.add({ ...taxToAdd })
                }}>
                    <Form.Item label={localTax ? "Nombre del impuesto" : "Tipo de impuesto"} name="type" rules={[{ required: true, message: 'Por favor añade el tipo de impuesto' }]}>
                        {!localTax ? <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            //onChange={setSelectedOption}
                            options={[{ label: 'IVA', value: 'IVA' }, { label: 'ISR', value: 'ISR' }, { label: 'IEPS', value: 'IEPS' }]}
                            isLoading={false}
                            placeholder="Tipo de impuesto"
                        /> : <Input />}
                    </Form.Item>
                    {!localTax && <Form.Item label="Factor del impuesto" name="factor" rules={[{ required: true, message: 'Por favor añade el factor' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            //onChange={setSelectedOption}
                            options={[{ label: 'Tasa', value: 'Tasa' }, { label: 'Cuota', value: 'Cuota' }, { label: 'Exento', value: 'Exento' }]}
                            isLoading={false}
                            placeholder="Factor del impuesto"
                        />
                    </Form.Item>}
                    <Form.Item label="Tasa del impuesto" name="rate" rules={[{
                        required: true, message: 'Por favor añade la tasa del impuesto'
                    }, {
                        validator: (_, value) => {
                            if (Number(value) > 1 || Number(value) < 0) {
                                return Promise.reject("La tasa debe ser un número entre 0 y 1")
                            } else {
                                return Promise.resolve()
                            }
                        }
                    }]}>
                        <Input className="pro-input" placeholder="0.16" type="number" step="0.000001" max="1" />
                    </Form.Item>
                    {!localTax && <Form.Item label="Impuesto incluido" name="inclusive" rules={[{ required: true, message: 'Por favor añade si el impuesto está incluido' }]}>
                        <Select
                            styles={generalreactSelectStyles}
                            className="pro-select-input"
                            options={[{ label: 'Incuido', value: true }, { label: 'No Incluido', value: false }]}
                            isLoading={false}
                            placeholder=""
                        />
                    </Form.Item>}
                    {props?.withBase && <Form.Item label="Base del impuesto" name="base" rules={[{ required: true, message: 'Por favor añade la tasa del impuesto' }]}>
                        <Input className="pro-input" placeholder="100" type="number" />
                    </Form.Item>}
                    <Form.Item name="withholding" valuePropName="checked" style={{ marginBottom: '0px' }}>
                        <Checkbox onChange={(v) => setwithholding(v.target.checked)}>¿Impuesto retenido por tu cliente?</Checkbox>
                    </Form.Item>


                    <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{withholding ? 'El impuesto es retenido por tu cliente' : 'Tendrás que trasladar el impuesto al SAT'}</Typography.Text>

                    <Row justify="center" style={{ marginTop: '25px' }}>
                        <Button htmlType="submit" type="primary">Añadir impuesto</Button>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}

export default ServiceTaxModal;