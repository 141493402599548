import { doc, getFirestore } from 'firebase/firestore';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFirestoreDocDataOnce } from 'reactfire';
import SubstituteInvoice from '../../widgets/invoices/SubstituteInvoice';
import { Col, Row } from 'antd';

function SubstituteInvoicePage() {
    const { id } = useParams();
    // console.log(id);
    const { status, data } = useFirestoreDocDataOnce(doc(getFirestore(), 'invoices', id))


    if (status === 'loading') {
        return <></>
    }
    // return <></>
    return (
        <Row justify="center" style={{ padding: '20px' }}>
            <Col xs={23} lg={18} xl={15} style={{
                backgroundColor: 'white', padding: '20px', borderRadius: '10px',
                boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.15)'
            }}>
                <SubstituteInvoice pastInvoiceDocument={data} fullPage={true} />
            </Col>
        </Row>
    );
}

export default SubstituteInvoicePage;