import { BookOutlined, ClockCircleOutlined, CloudDownloadOutlined, ExperimentOutlined, LoadingOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { Alert, Button, Col, message, Row, Space, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import { collection, doc, getCountFromServer, getDocs, getFirestore, limit, orderBy, query, startAfter, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { /*useAuth*/ useFirestoreCollectionData } from "reactfire";
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext";
import TeamContext from "../../context/teamcontext/TeamContext";
import UserContext from "../../context/usercontext/UserContext";
import { useUserPermissions } from "../../customHooks/userPermissions";
import ComposeEmailModal from "../../widgets/email/ComposeEmailModal";
import ActionablePageHeader from "../../widgets/headers/ActionablePageHeader";
import DefaultHelpPageHeader from "../../widgets/headers/DefaultHelpPageHeader";
import CancelInvoiceModal from "../../widgets/invoices/CancelInvoiceModal";
import CreateInvoice from "../../widgets/invoices/CreateInvoice";
// import InvoicesRelations from "../../widgets/relations/InvoicesRelations";
// import { SignedInternalAPIRequest } from "../functions/APIRequests";
import { SearchParamInURL, returnCurrencyValue } from "../functions/helpers";
import InvoiceSettings from "../../widgets/modals/InvoiceSettings";
import TableInvoicesRecurring from "../../widgets/invoices/TableInvoicesRecurringEvents";
import TableTitle from "../../widgets/tables/TableTitle";
import SearchModal from '../../widgets/search/SearchModal'
import DeleteInvoiceGlobal from "../../widgets/modals/DeleteInvoiceGlobalModal";
import { ErrorBoundary } from "@sentry/react";
import { InvoiceStatusTag } from "../../widgets/invoices/InvoicesStatus";
import PaymentRelations from "../../widgets/relations/PaymentsRelations";
import { AlgoliaSearchComponent } from "../../widgets/search/AlgoliaSearchComponents";
import { InvoiceActions } from "../../widgets/actions/InvoiceActions";
import { DownloadFilesModal } from "../../widgets/modals/DownloadFiles";
import InvoicesRelations from "../../widgets/relations/InvoicesRelations";
// import { Navigate } from "react-router-dom";


function InvoicesPage({ userDocument, changeRender, subtab }) {
  const { user } = useContext(UserContext);

  const { team, getCurrentTeam } = useContext(TeamContext);
  const { billingAccount } = useContext(BillingAccountContext);

  const fs = getFirestore();
  const invoicesRef = query(
    collection(fs, `invoices`),
    where("team", "==", team?.id ?? ""),
    orderBy("timestamp", "desc")
  );

  const [invoices, setinvoices] = useState([]);
  const [createInvoice, setcreateInvoice] = useState(false);
  const [createInvoicesRecurring, setcreateInvoicesRecurring] = useState(false);
  const [downloading, setdownloading] = useState(false);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const [deleteInvoiceGlobal, setDeleteInvoiceGlobal] = useState(false);

  const [composeEmail, setcomposeEmail] = useState(null);
  const [cancelInvoice, setcancelInvoice] = useState(null);
  const [signInvoiceModal, setSignIvoiceModal] = useState(false);
  const [substituteInvoice, setSubstituteInvoice] = useState(false);


  const [pageSize, setPageSize] = useState(10);
  const [pageActive, setpageActive] = useState(SearchParamInURL('pageActive') ?? 1);
  const [defaultClientSearch, setdefaultClientSearch] = useState([])


  const [/*invoiceToSign*/, setInvoiceToSign] = useState([]);
  // const auth = useAuth();
  const [/*loadingSignInvoice*/, /*setLoadingSignInvoice*/] = useState(false);
  const [generalSettingsModal, setGeneralSettingsModal] = useState(false);
  const { canCreateInvoices, canCreateInvoicesRecurring } = useUserPermissions({
    user,
    team,
    billingAccount,
  });

  const invoicesRefPagination = query(collection(fs, `invoices`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), limit(1000));
  const { status, data: firstInvoicesData } = useFirestoreCollectionData(invoicesRefPagination, {
    idField: 'id'
  })
  const [invoicesData, setInvoicesData] = useState([])
  // const [lastInvoiceDoc, setLastInvoiceDoc] = useState(null)
  const [totalInvoices, setTotalInvoices] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)
  const [search, /*setSearch*/] = useState('')
  const [filterBy, setFilterBy] = useState('')
  const [createPaymentComplement, setCreatePaymentComplement] = useState(null)




  useEffect(() => {
    setInvoicesData([])
    // setLastInvoiceDoc(null)
    setTableLoading(true)

    const getInitial = async () => {
      setTableLoading(true)
      const coll = query(collection(fs, `invoices`), where('team', '==', team?.id ?? ''));
      const snapshot = await getCountFromServer(coll);
      // console.log("Getting New Data initial")
      setTotalInvoices(snapshot.data().count)
      // onSnapshot(invoicesRefPagination, (data) => {
      //   let newDocs = data.docs.map((doc) => {
      //     return doc.data()
      //   })
      //   setInvoicesData(newDocs)
      //   setLastInvoiceDoc(data.docs[newDocs.length - 1])
      //   setTableLoading(false)

      // });
      setTableLoading(false)
    }
    getInitial();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team?.id])

  const getNextPage = async ({ pa, ps, next }) => {
    try {
      setTableLoading(true)
      if ((pa * ps - [...firstInvoicesData, ...invoicesData].length) <= 0) {
        // console.log('NOT LOADING MORE')
        return setTableLoading(false)
      }
      // console.log("Getting new data on next page")
      const allItems = [...(firstInvoicesData ?? []), ...(invoicesData ?? [])]
      const invoicesRefPagination = query(collection(fs, `invoices`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'), startAfter(next), limit((ps * pa) - allItems.length + 1));
      // console.log(pa * ps - invoicesData.length)
      const newDocs = await getDocs(invoicesRefPagination)
      let newDocsData = newDocs.docs.map((d) => d.data())
      setInvoicesData([...invoicesData, ...newDocsData])
      // setLastInvoiceDoc(newDocs.docs[newDocsData.length - 1])
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
    }
  }


  // /**
  //  * It downloads an excel file from a signed API request
  //  */

  // const DownloadXLSX = async () => {
  //   try {
  //     setdownloading(true);
  //     await SignedInternalAPIRequest(
  //       {
  //         clientId: null,
  //       },
  //       `exportFiles/v1/invoices`,
  //       auth.currentUser,
  //       {
  //         isFile: true,
  //         fileName: `facturas-${moment().format("DD/MM/YYYY")}`,
  //         fileExtension: "xlsx",
  //       },
  //       "POST"
  //     );
  //     //DOWNLOAD FILE FROM readableStream
  //     setdownloading(false);

  //   } catch (error) {
  //     message.error("Error al descargar el archivo");
  //     setdownloading(false);
  //   }
  // };

  /**
   * It takes an invoice object, and sends it to the server to be signed and timbrada
   * @param invoice - The invoice object.
   */

  // const DuplicateInvoice = async (invoice) => {
  //   setLoadingSignInvoice(true);
  //   try {
  //     const ninv = {
  //       ...invoice,
  //       type: "create_invoice",
  //       signPastInovice: true,
  //       signPastInvoice: true,
  //       items: invoice.items,
  //       test: false,
  //       payment_form: invoice.payment_form,
  //       series: invoice.series || "gigstack",
  //       use: invoice.use,
  //       invoicingIntegration: "facturapi",
  //       client: invoice.client,
  //       customer: invoice.client,
  //       relation: invoice.relation ?? null,
  //     };

  //     if (invoice.relation?.length <= 0 || invoice.relation == null) {
  //       delete ninv["relation"];
  //     }

  //     await SignedInternalAPIRequest(ninv, "invoicing", auth.currentUser);

  //     notification.success({
  //       placement: "bottomRight",
  //       description: "Hemos timbrado la factura",
  //     });
  //     setLoadingSignInvoice(false);
  //     setSignIvoiceModal(false);
  //   } catch (error) {
  //     message.error(error.message);
  //     setLoadingSignInvoice(false);
  //   }
  // };

  /* Creating a new array of objects with the following structure:
    {
        text: 'Client name',
        value: 'Client name',
        key: 'Client name'
    } */
  // const filterableClients = RemoveDuplicatesFromObjectArray(
  //   (clients ?? [])?.map((p) => ({
  //     text: p?.client?.name ?? "Sin cliente",
  //     value: p?.client?.name ?? "Sin cliente",
  //     key: p?.client?.name ?? "Sin cliente",
  //   })),
  //   "key"
  // );

  var filteredInvoices = [...(firstInvoicesData ?? []), ...(invoicesData ?? [])]?.filter((invoice) => {
    if (filterBy === "emailErrors") {
      return ['bounce', 'deferred', 'blocked'].includes(invoice?.emails?.lastEvent)
    }
    if (search === '') return true
    return (invoice?.client?.name?.toLowerCase().includes(search?.toLowerCase()) || String(invoice?.whmcsInvoiceId ?? '').toLocaleLowerCase().includes(search.toLocaleLowerCase()) || invoice?.client?.legal_name?.toLowerCase().includes(search?.toLowerCase()) || invoice?.client?.legalName?.toLowerCase().includes(search?.toLowerCase()) || invoice?.client?.email?.toLowerCase().includes(search?.toLowerCase()) || invoice?.id?.toLowerCase().includes(search?.toLowerCase()) || invoice?.folio_number?.toString().toLowerCase().includes(search?.toLowerCase()) || invoice?.total?.toString()?.toLowerCase().includes(search?.toLowerCase()) || invoice?.currency?.toLowerCase().includes(search?.toLowerCase()) || invoice?.uuid?.toLowerCase().includes(search?.toLowerCase()))
  })

  /**
   * It returns a row with a column that contains a page header with a title and two buttons, and a
   * column that contains a table with a list of invoices
   */
  const Invoices = () => {
    return (
      <Row style={{ width: "100%" }}>

        <DownloadFilesModal open={downloading} close={() => setdownloading(false)} data={{ client: null, readableType: 'facturas' }} />
        <Col xs={24} style={{ marginTop: "25px" }}>
          <ActionablePageHeader
            title="Facturas emitidas"
            actions={
              <Space>
                <Button icon={<SettingOutlined />} onClick={() => { setGeneralSettingsModal(true) }} />


                <Button loading={downloading} icon={<CloudDownloadOutlined />} onClick={() => {
                  setdownloading(true);
                }} />

                {canCreateInvoices && (
                  <Button
                    onClick={() => {
                      if (!team.facturapi?.completed) {
                        return message.warning('Antes de crear una factura debes conectarte al SAT')
                      }
                      setcreateInvoice(true);
                      getCurrentTeam(team.id);
                    }}
                    type="primary"
                  >
                    Nueva factura
                  </Button>
                )}
              </Space>
            }
          />

        </Col>

        <Col xs={24} style={{ marginTop: "25px" }}>
          <Row style={{ padding: 0, margin: 0, width: '100%' }}>

            <Col xs={24}>
              {showSearchModal && <SearchModal open={showSearchModal} close={() => setShowSearchModal(false)} localTypeData="invoices" localData={invoicesData.map(i => {
                return {
                  ...i,
                  clientName: i.customer?.legal_name,
                  clientCountry: i.customer?.email,
                  rawData: JSON.stringify(i)
                }
              })} changeRender={(v) => changeRender(v)} />}
              {deleteInvoiceGlobal && (<DeleteInvoiceGlobal open={deleteInvoiceGlobal} close={() => setDeleteInvoiceGlobal(null)} onAccept={() => setcancelInvoice(deleteInvoiceGlobal)} />)}
              {/* {generalSettingsModal && (<ConectModal open={generalSettingsModal} close={(v) => {
                setGeneralSettingsModal(false)
              }}

              />
              )} */}
              {createPaymentComplement && <CreateInvoice data={{ ...createPaymentComplement, type: 'P', step: 2, customTitle: 'Crear complemento de pago', items: createPaymentComplement?.internalItems, client: { ...createPaymentComplement?.internalClient, tax_system: createPaymentComplement.client?.tax_system }, toRelate: createPaymentComplement.uuid, payments: [], emails: [] }} open={createPaymentComplement} close={() => {
                setPageSize(pageSize)
                setpageActive(pageActive)
                setCreatePaymentComplement(null)
              }
              } />}
              <div className={'tableContainer'}>
                <Row align='middle' style={{ width: '100%', marginBottom: '10px' }} justify="end">
                  <Alert
                    onClick={() => setGeneralSettingsModal(true)}
                    type={team?.stripe?.automaticInvoicing || team?.openpay?.automaticInvoicing || team?.whmcs?.automaticInvoicing ? 'success' : 'error'}
                    style={{ marginRight: 'auto', textAlign: 'center', borderRadius: '25px', cursor: 'pointer', height: '35px' }}
                    message={
                      team?.stripe?.automaticInvoicing || team?.openpay?.automaticInvoicing || team?.whmcs?.automaticInvoicing
                        ? 'Facturación automática activada.'
                        : 'Facturación automática desactivada.'
                    }
                  />
                  <Space direction="vertical" style={{ width: window.innerWidth < 900 ? '100%' : '50%' }}>
                    {/* <Input.Search style={{ width: '100%' }} placeholder={`Buscar en ${[...(firstInvoicesData ?? []), ...(invoicesData ?? [])]?.length} de ${totalInvoices} facturas`} defaultValue={search} onSearch={(value) => {
                      setpageActive(1)
                      setSearch(value)
                    }} /> */}
                    <AlgoliaSearchComponent indexName='invoices' teamId={team.id} type='invoices' />
                    <Row justify="end" style={{ marginTop: '5px' }}>
                      <Space>
                        <Tag onClick={() => setFilterBy(filterBy !== '' ? '' : 'emailErrors')} className={filterBy ? 'activeTag clickable' : ' clickable'}>Correo con errores</Tag>
                      </Space>
                    </Row>
                    {/* <Typography.Text type='secondary'>
                      Para ver el historial completo de tu cuenta haz CTRL + F
                    </Typography.Text> */}
                  </Space>
                </Row>
                <ErrorBoundary>
                  <Table
                    scroll={{ x: 500 }}
                    locale={
                      {
                        emptyText: (
                          <div style={{ textAlign: 'center' }}>
                            <p>No se encuentra registro en los últimos 35 dias, busca en todo tu historial</p>
                            <Button shape="circle" icon={<SearchOutlined />} onClick={() => setShowSearchModal(true)} />
                          </div>
                        ),
                      }
                    }
                    loading={status === 'loading' || tableLoading}
                    pagination={{
                      pageSize: pageSize,
                      defaultCurrent: pageActive,
                      onChange: (p, ps) => {
                        setpageActive(p)
                        setPageSize(ps)
                        if (invoicesData.length <= p * ps) {
                          const allItems = [...(firstInvoicesData ?? []), ...(invoicesData ?? [])]
                          getNextPage({ pa: p, ps: ps, next: allItems[allItems.length - 1]?.timestamp })
                        }
                      },
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '50', '100'],
                      showTotal: (total, range) => {
                        if (search) {
                          return `${range[0]}-${range[1]} de ${filteredInvoices.length}`;
                        } else {
                          return `${range[0]}-${range[1]} de ${total} facturas`;
                        }
                      },
                      // showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} facturas`,
                      position: ['bottomCenter'],
                      defaultPageSize: 10,
                      showQuickJumper: false,
                      responsive: true,
                      total: (search || filterBy) ? filteredInvoices : totalInvoices ?? 1,
                      locale: { items_per_page: '/ página' }
                    }}
                    dataSource={filteredInvoices}
                    columns={[
                      {
                        title: <TableTitle title="Emitido para" />,
                        dataIndex: 'clientID',
                        key: 'clientID',
                        // filters: filterableClients,
                        filterMode: 'multiple',

                        defaultFilteredValue: defaultClientSearch,
                        onFilter: (value, record) => {
                          setdefaultClientSearch(value)
                          return (record.client?.name ?? 'Sin cliente') === value
                        },

                        width: '20%',
                        render: (text, record) => {
                          return <div className="d-flex flex-column">
                            <Row onClick={() => {
                              changeRender('invoiceDetails')
                              return window.history.replaceState(null, `tab`, `/invoiceDetails?id=${record?.id}&pageActive=${pageActive}`)
                            }} className="clickable itemHoverPrimary" align="middle">

                              <Typography.Text style={{ margin: 0, paddingLeft: '0px' }} className="primary">{record.customer?.legal_name}</Typography.Text>
                              {!record.livemode && <Tooltip title="Modo pruebas">
                                <ExperimentOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#cecece' }} />
                              </Tooltip>}
                            </Row>
                            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>{record.client?.company ?? ''}</Typography.Text>
                            <Typography.Text type="secondary" style={{ fontSize: '11px' }} copyable>{record.uuid ?? ''}</Typography.Text>
                            <Typography.Text type="secondary" style={{ fontSize: '11px' }}

                            >Order ID: <Typography.Text
                              type="secondary" style={{ fontSize: '11px' }}
                              editable={{
                                onChange: async (v) => {
                                  if (v !== record.orderId) {
                                    if (!v) return
                                    if (v === (record?.orderId ?? record?.metadata?.orderId)) return
                                    await updateDoc(doc(getFirestore(), 'invoices', record.id), {
                                      metadata: {
                                        ...record.metadata,
                                        orderId: v
                                      }
                                    })
                                    message.success('Order ID actualizado')
                                  }
                                }
                              }}
                            > {record.orderId ?? record?.metadata?.orderId ?? ''}</Typography.Text>  </Typography.Text>
                            {record?.whmcsInvoiceId && <Typography.Text type="secondary" style={{ fontSize: '11px' }}>WHMCS: {record?.whmcsInvoiceId ?? ''}</Typography.Text>}
                          </div>

                        },
                      },
                      {
                        title: <TableTitle title="Estado" />,
                        dataIndex: 'status',
                        key: 'status',
                        render: (text, record) => {
                          return (
                            <>
                              <InvoiceStatusTag invoice={record} />
                              {record?.cancellationStatus && record.status !== 'canceled' ? <InvoiceStatusTag invoice={record} useSecondStatus={true} /> : null}
                            </>
                          )
                        },
                      },
                      {
                        title: <TableTitle title="Folio y Serie" />,
                        dataIndex: 'folio',
                        key: 'folio',
                        render: (text, record) => {
                          return <Typography.Text type="secondary">{record.folio_number} {record.series}</Typography.Text>
                        },
                      },
                      {
                        title: <TableTitle title="Total" />,
                        dataIndex: 'total',
                        key: 'total',
                        render: (text, record) => {
                          return <Typography.Text type="secondary">{returnCurrencyValue(record.total)} <span style={{ fontSize: '10px' }}>{record.currency}</span></Typography.Text>
                        },
                      },
                      {
                        title: <TableTitle title="Tipo" />,
                        dataIndex: 'type',
                        key: 'type',
                        render: (text, record) => {
                          return <Tooltip title={record.type === 'E' ? 'Egreso' : 'Ingreso'}>
                            <Typography.Text type="secondary">{record.type}</Typography.Text>
                          </Tooltip>
                        },
                      },
                      // {
                      //   title: <TableTitle title="Forma de pago" />,
                      //   dataIndex: 'payment_form',
                      //   key: 'payment_form',
                      //   render: (text, record) => {
                      //     return <Typography.Text type="secondary">{paymentForms.find(pf => pf.value === record.payment_form).label}</Typography.Text>
                      //   },
                      // },
                      {
                        title: <TableTitle title="Fecha" />,
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        render: (text, record) => {
                          return <Typography.Text type="secondary">{moment(text).format('DD MMM YYYY H:mm')}</Typography.Text>
                        },
                      },
                      {
                        title: <TableTitle title="Creada desde" />,
                        dataIndex: 'from',
                        key: 'from',
                        render: (text, record) => {
                          return <Tag style={{ backgroundColor: "#dee7ff", color: "#245ef0", border: 'none' }}>
                            {record?.from ?? 'manual'}
                          </Tag>
                        },
                      },
                      //TODO: SHOW RELATIONS TO INVOICES AND PAYMENTS
                      {
                        title: <TableTitle title="Relaciones" />,
                        dataIndex: 'timestamp',
                        key: 'timestamp',
                        render: (text, record) => {

                          return <div className='d-flex flex-column'>
                            {(record.payment_form === '99' && !record.global && record.invoices?.length <= 0) && <div style={{ marginBottom: '4px' }}>
                              <Button size='small' onClick={() => {
                                //UPDTE URL WITH PAGE ACTIVE

                                setCreatePaymentComplement({
                                  client: record?.internalClient,
                                  items: record?.items,
                                  ...record,
                                  'invoice': record
                                })
                                return window.history.replaceState(null, `tab`, `?pageActive=${pageActive ?? 1}`)
                              }}>
                                Complemento de pago
                              </Button>
                            </div>}
                            <PaymentRelations
                              items={record?.internalItems}
                              hideTitle={true}
                              collection="invoices"
                              elementType="invoice"
                              allowSmall={true}
                              custom={true}
                              payments={record?.payments}
                              changeState={(i) => {
                                //setState({ invoice: { ...record, ...i } })
                              }}
                              client={record?.internalClient ?? record.customer}
                              item={{ ...record }}
                              userDocument={user}
                              refresh={(V) => {
                                if (V) {
                                  window.location.reload()
                                }
                              }}
                              id={record?.id}
                              readable="cfdi"
                            />
                            <InvoicesRelations items={record?.internalItems} hideTitle={true} collection="invoices" elementType="invoice" allowSmall={true} custom={true} changeState={(i) => {
                              //setState({ invoice: { ...record, ...i } })
                            }} client={record?.internalClient ?? record.customer} item={{ ...record }} userDocument={user} refresh={(V) => {
                              if (V) {
                                window.location.reload()
                              }
                            }} id={record?.id} readable="cfdi" />


                          </div>
                        },
                      },
                      {
                        title: <TableTitle title="" />,
                        dataIndex: 'actions',
                        key: 'actions',
                        render: (text, record) => {
                          return <InvoiceActions record={record} />
                        },
                      },
                    ]}
                  />
                </ErrorBoundary>

              </div>
            </Col>
          </Row>
        </Col >
      </Row >
    );
  };
  const RecurringEventsInvoices = () => {
    return (
      <Row style={{ width: "100%" }}>
        {substituteInvoice && (
          <Suspense fallback={<LoadingOutlined />}>

          </Suspense>
        )}
        {signInvoiceModal && (
          <Suspense fallback={<LoadingOutlined />}>


          </Suspense>
        )}

        <Col xs={24} style={{ marginTop: "25px" }}>
          <ActionablePageHeader
            title="Facturas Recurrentes"
            actions={
              <Space>
                {/* <Button icon={<SettingOutlined />} onClick={() => { setGeneralSettingsModal(true) }} /> */}


                {/* <Button loading={downloadingXLSX} icon={<CloudDownloadOutlined />} onClick={() => DownloadXLSX({ objectUrl: '`gigstackpro.pro.cleaned_recurring_events_view`', fname: "recurrentes" })} /> */}

                {canCreateInvoicesRecurring && (
                  <Button
                    onClick={() => {

                      setcreateInvoicesRecurring(true);
                      getCurrentTeam(team.id);
                    }}
                    type="primary"
                  >
                    Nueva factura recurrente
                  </Button>
                )}
              </Space>
            }
          />

        </Col>


        <Col xs={24} style={{ marginTop: "25px" }}>
          <TableInvoicesRecurring
            query={invoicesRef}
            setcomposeEmail={(v) => setcomposeEmail(v)}
            changeRender={(v) => changeRender(v)}
            // filterableClients={filterableClients}
            setSignIvoiceModal={setSignIvoiceModal}
            setInvoiceToSign={setInvoiceToSign}
            setcancelInvoice={setcancelInvoice}
            setSubstituteInvoice={setSubstituteInvoice}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Row justify="center">

      {generalSettingsModal && (<InvoiceSettings open={generalSettingsModal} close={(v) => {
        setGeneralSettingsModal(false)
      }}
      />
      )}
      {createInvoice && (
        <Suspense fallback={<>loading..</>}>
          <CreateInvoice open={createInvoice} close={(v) => {
            setcreateInvoice(false);
          }}
            userDocument={userDocument}
            data={{}}
          />
        </Suspense>
      )}
      {createInvoicesRecurring && (
        <Suspense fallback={<>loading..</>}>
          <CreateInvoice recurring={createInvoicesRecurring} open={createInvoicesRecurring} close={(v) => {
            setcreateInvoicesRecurring(false);
          }}
            userDocument={userDocument}
            data={{}}
          />

        </Suspense>
      )}


      {composeEmail && (
        <ComposeEmailModal
          close={() => setcomposeEmail(null)}
          actionText={null}
          idRef={"invoices"}
          data={{ ...composeEmail, invoice: composeEmail }}
          userDocument={userDocument}
          open={composeEmail}
          element={composeEmail}
          subject={`Factura ${composeEmail?.series} ${composeEmail?.folio_number}`}
          readableType={"factura"}
          client={
            composeEmail.internalClient ?? { name: "Sin Cliente", email: "" }
          }
        />
      )}
      {cancelInvoice && (
        <CancelInvoiceModal
          open={cancelInvoice}
          close={() => setcancelInvoice(null)}
          invoice={cancelInvoice}
          cancelInvoice={(inv) => {
            const nv = [];
            invoices?.forEach((i) => {
              if (i.id !== inv.id) {
                nv.push(i);
              }
            });
            setTimeout(() => {
              setinvoices(nv);
            });
          }}
        />
      )}

      <Col xs={24}>
        <DefaultHelpPageHeader
          userDocument={userDocument}
          title="Facturas"
          description={"Emite facturas fácil, rápido y seguro."}
          extraInSub={null}
        />
      </Col>
      <Col xs={24} style={{ marginTop: '25px' }}>
        <Tabs onChange={() => { }} type="card">

          <Tabs.TabPane tab={<Row align="middle">
            <BookOutlined /><Typography.Text> Facturas</Typography.Text>
          </Row>} key="invoices">
            <Invoices />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<Row align="middle">
            <ClockCircleOutlined /><Typography.Text>Facturas Recurrentes</Typography.Text>
          </Row>} key="recurringEventsInvoices">
            <RecurringEventsInvoices />
          </Tabs.TabPane>

        </Tabs>
      </Col>

    </Row>
  );
}

export default InvoicesPage;
