export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_BILLINGACCOUNT_DATA = 'GET_BILLINGACCOUNT_DATA';
export const GET_TEAM_DATA = 'GET_TEAM_DATA';
export const GET_TEAMLIST_DATA = 'GET_TEAMLIST_DATA';

// Types for Modals
export const OPEN_INVITE_MODAL = 'OPEN_INVITE_MODAL';
export const CLOSE_INVITE_MODAL = 'CLOSE_INVITE_MODAL';
export const OPEN_CREATE_TEAM_MODAL = 'OPEN_CREATE_TEAM_MODAL';
export const CLOSE_CREATE_TEAM_MODAL = 'CLOSE_CREATE_TEAM_MODAL';
export const SET_MODAL_TEAM = 'SET_MODAL_TEAM';
export const SET_MODAL_BILLINGACCOUNT = 'SET_MODAL_BILLINGACCOUNT';
export const SET_MODAL_USER = 'SET_MODAL_USER';
export const INVITE_TYPE = 'INVITE_TYPE';

export const SET_MODAL_PROPS_DATA = 'SET_MODAL_PROPS_DATA';

export const SET_MODAL_CLIENT_PROPS = 'SET_MODAL_CLIENT_PROPS';
export const OPEN_NEW_CLIENT_MODAL = 'OPEN_NEW_CLIENT_MODAL';
export const CLOSE_NEW_CLIENT_MODAL = 'CLOSE_NEW_CLIENT_MODAL';

export const SET_MODAL_EXPENSE_PROPS = 'SET_MODAL_EXPENSE_PROPS';
export const OPEN_NEW_EXPENSE_MODAL = 'OPEN_NEW_EXPENSE_MODAL';
export const CLOSE_NEW_EXPENSE_MODAL = 'CLOSE_NEW_EXPENSE_MODAL';

export const SET_MODAL_SERVICE_PROPS = 'SET_MODAL_SERVICE_PROPS';
export const OPEN_NEW_SERVICE_MODAL = 'OPEN_NEW_SERVICE_MODAL';
export const CLOSE_NEW_SERVICE_MODAL = 'CLOSE_NEW_SERVICE_MODAL';

export const SET_MODAL_PAYMENTREQUEST_PROPS = 'SET_MODAL_PAYMENTREQUEST_PROPS';
export const OPEN_NEW_PAYMENTREQUEST_MODAL = 'OPEN_NEW_PAYMENTREQUEST_MODAL';
export const CLOSE_NEW_PAYMENTREQUEST_MODAL = 'CLOSE_NEW_PAYMENTREQUEST_MODAL';

export const SET_MODAL_RECURRINGPAYMENT_PROPS = 'SET_MODAL_RECURRINGPAYMENT_PROPS';
export const OPEN_NEW_RECURRINGPAYMENT_MODAL = 'OPEN_NEW_RECURRINGPAYMENT_MODAL';
export const CLOSE_NEW_RECURRINGPAYMENT_MODAL = 'CLOSE_NEW_RECURRINGPAYMENT_MODAL';

export const SET_MODAL_INVOICE_PROPS = 'SET_MODAL_INVOICE_PROPS';
export const OPEN_NEW_INVOICE_MODAL = 'OPEN_NEW_INVOICE_MODAL';
export const CLOSE_NEW_INVOICE_MODAL = 'CLOSE_NEW_INVOICE_MODAL';

export const SET_MODAL_RECEIPT_PROPS = 'SET_MODAL_RECEIPT_PROPS';
export const OPEN_NEW_RECEIPT_MODAL = 'OPEN_NEW_RECEIPT_MODAL';
export const CLOSE_NEW_RECEIPT_MODAL = 'CLOSE_NEW_RECEIPT_MODAL';
export const LOADING_INTEGRATION_CARD = 'LOADING_INTEGRATION_CARD'
export const NOT_LOADING_INTEGRATION_CARD = 'NOT_LOADING_INTEGRATION_CARD'
export const HANDLE_OPEN_INTEGRATION_MODAL = 'HANDLE_OPEN_INTEGRATION_MODAL'
export const HANDLE_CLOSE_INTEGRATION_MODAL = 'HANDLE_CLOSE_INTEGRATION_MODAL'

export const OPEN_STRIPE_AUTOMATIONS_MODAL = 'OPEN_STRIPE_AUTOMATIONS_MODAL'
export const CLOSE_STRIPE_AUTOMATIONS_MODAL = 'CLOSE_STRIPE_AUTOMATIONS_MODAL'

export const DATA_TO_UPDATE = 'DATA_TO_UPDATE';
export const ON_ITEMS_CHANGE = 'ON_ITEMS_CHANGE'
export const SET_USER_DATA = 'SET_USER_DATA'