import {
    LoadingOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Form, Row, Typography } from 'antd';
// import moment from 'moment';
import React, { Suspense, useContext, useState } from 'react';
// import { useAuth } from 'reactfire';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import DefaultsConfigDrawer from '../drawers/DefaultsConfigDrawer';
import stripeLogo from '../../assets/images/stripeSquare.png';
import satLogo from '../../assets/images/logoSatSquare.png';
import speiLogo from '../../assets/images/speiSquare.png';
import hilosLogo from '../../assets/images/hilosSquare.png';
import openpayLogo from '../../assets/images/logoopenpaySquare.jpg';


import BankModal from '../integrations/BankModal';
import FacturapiModal from '../integrations/FacturapiModal';
import HilosModal from '../integrations/HilosModal';
import OpenBankingModal from '../integrations/OpenBankingModal';
import PayPalModal from '../integrations/PayPalModal';
// import { handleStripeLink } from './PaymentOptions';
import { useUserPermissions } from '../../customHooks/userPermissions';
import ConektaModal from '../integrations/ConektaModal';
import ConectModal from '../modals/ConectModal';
import AppCard from '../cards/AppCard';
import OpenpayConnectModal from '../modals/OpenpayConnectModal';
import zapierLogo from '../../assets/images/zapier.png';
import ModalZapier from '../modals/ModalZapier';
import WHMCS from '../../assets/images/WHMCS.png';
import WhmcsModal from '../modals/WhmcsModal';
import airtableLogo from '../../assets/images/airtableSquare.png';
import googleSheetsLogo from '../../assets/images/googleSheetsSquare.png'
import AirtableModal from '../modals/AirtableModaj';
import StripeModal from '../integrations/StripeModal';
function IntegrationsSettings({ userDocument }) {

    // const auth = useAuth()
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const { user } = useContext(UserContext)

    // const accountsRef = query(collection(getFirestore(), `OBAccounts`), where('team', '==', team?.id));
    // const { status: accountsDBStatus, data: accountsData } = useFirestoreCollectionData(accountsRef, {})


    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false)
    const [addConekta, setAddConekta] = useState(false)
    const [addBank, setaddBank] = useState(false)
    const [addZapier, setaddZapier] = useState(false)
    const [addAirtable, setAddAirtable] = useState(false)
    const [addOpenpay, setaddOpenpay] = useState(false)
    const [addPayPal, setaddPayPal] = useState(false)
    const [addFacturapi, setaddFacturapi] = useState(false)
    const [addOpenBankingAccount, setaddOpenBankingAccount] = useState(false)
    const [addHilos, setAddHilos] = useState(false)
    const [addwhmcs, setAddwhmcs] = useState(false)

    const [configDefaults, setConfigDefaults] = useState(false)
    const { integrationsCompleted } = useUserPermissions({ user, team, billingAccount })
    const { getCurrentTeam } = useContext(TeamContext)
    // const connectStripe = async () => {
    //     try {
    //         const link = await handleStripeLink({ userDocument, auth, teamId: team.id, billingAccountId: billingAccount.id, team })

    //         return window.open(link.url, '_blank')
    //     } catch (error) {
    //         message.error(error?.message ?? 'Ocurrio un error al contectar la cuenta.')

    //     }

    // }




    /**
     * > If the user has a valid certificate, and has a live FTPI key, then return true
     * @returns An object with two properties: hasFtpi and valid.
     */
    // const HasFtpi = () => {
    //     const validCerts = team?.facturapi?.completed ? moment(team?.facturapi?.certificate?.expires_at).isAfter(moment()) : false
    //     var ftpi = (team?.facturapi?.keys ?? {})
    //     return { hasFtpi: (ftpi?.live_key), valid: validCerts }
    // }

    /**
     * It checks if the user has a token.
     * @returns { hasHilos: token != null, valid: token != null }
     */
    // const HasHilos = () => {
    //     const { token } = userDocument?.hilos ?? {}
    //     return { hasHilos: token != null, valid: token != null }

    // }

    const AppCategoryHeader = ({ title }) => {
        return <div style={{ backgroundColor: '#f5f5f5', width: '100%', height: '40px', alignItems: 'center', justifyContent: 'center', borderRadius: '10px', margin: '10px 0px' }} className="d-flex">
            <Typography.Title level={5} style={{ margin: 0, color: '#525252' }}>{title}</Typography.Title>
        </div>
    }

    // const AppCard = ({ title, description, onClick, logo, isCompleted, height }) => {
    //     return <Col xs={24} md={12} lg={6}>
    //         <div className={`d-flex flex-column ${isCompleted ? "backgroundGrayHoverSettings" : ''}`} style={{
    //             alignItems: 'center', justifyContent: 'center', margin: '20px 10px', padding: '15px', position: 'relative'
    //         }} onClick={() => {
    //             if (!isCompleted) return;
    //             onClick();
    //         }} >
    //             <div style={{ borderRadius: '10px', backgroundColor: '#f7f7f7', height: '70px', width: '80px', alignItems: 'center', justifyItems: 'center', justifyContent: 'center', marginBottom: '10px' }} className="d-flex flex-column">
    //                 {/* <CheckCircleOutlined style={{ fontSize: '25px', color: "#0b9c10", marginTop: '10px' }} /> */}
    //                 <img src={logo} height={height} style={{}} alt={title} />
    //             </div>
    //             {isCompleted && <SettingOutlined onClick={() => onClick()} className='centerIconSettings' style={!isCompleted ? { display: 'none', } : { position: 'absolute' }} />}
    //             <Typography.Text type="secondary" style={{ textAlign: 'center' }}>{description}</Typography.Text>
    //             {isCompleted ? <Tooltip title='Instalada correctamente' ><CheckCircleOutlined style={{ fontSize: '25px', color: "#8666FF", marginTop: '10px' }} /></Tooltip> : <Button onClick={() => onClick()} style={{ marginTop: '20px', alignSelf: 'center' }} type="dashed">Instalar</Button>}
    //         </div>
    //     </Col>
    // }


    const AppsInfo = () => {

        const integrations = [
            {
                name: 'Stripe',
                logo: stripeLogo,
                completed: team?.stripe?.completed,
                description: 'Cobra a tus clientes con Stripe',
                id: 'stripe'
            },
            {
                name: 'Openpay',
                logo: openpayLogo,
                completed: team?.openpay?.completed,
                description: 'Cobra a tus clientes con Openpay',
                id: 'openpay'
            },
            {
                name: 'SPEI',
                logo: speiLogo,
                completed: team?.bank?.completed,
                description: 'Recibe transferencia vía SPEI',
                id: 'spei'
            },
            {
                name: 'Zapier',
                logo: zapierLogo,
                completed: team?.zapier?.completed,
                description: 'Procesa pagos desde Zapier',
                id: 'zapier'
            },
            {
                name: 'Airtable',
                logo: airtableLogo,
                completed: team?.airtable?.completed,
                description: 'Crea solicitudes de pago desde Airtable',
                id: 'airtable'
            },
            {
                name: 'Google Sheets',
                logo: googleSheetsLogo,
                completed: team?.googlesheets?.completed,
                description: 'Registra pagos desde Google Sheets',
                disabled: true,
                id: 'googlesheets'
            },
            {
                name: 'WHMCS',
                logo: WHMCS,
                completed: team?.whmcs?.completed,
                description: 'Conectate a WHMCS',
                id: 'whmcs'
            },
            {
                name: 'SAT',
                logo: satLogo,
                completed: team?.facturapi?.completed,
                description: 'Emite facturas desde Gigstack',
                id: 'facturapi'
            },
            {
                name: 'Hilos',
                logo: hilosLogo,
                completed: team?.hilos?.completed,
                description: 'Envía mensajes de whatsapp a tus clientes',
                id: 'hilos'
            },
        ]

        return <Row style={{ width: '100%' }}>
            <AppCategoryHeader title='Pagos' />
            {/* <AppCard title='Stripe' description='Cobra a tus clientes con Stripe' onClick={() => setOpenModal(true)} logo={stripeLogo} isCompleted={integrationsCompleted?.stripe ?? false} height='20px' />
            <AppCard title='Spei' description='Recibe transferencia vía SPEI' onClick={() => setaddBank(true)} logo={speiLogo} isCompleted={integrationsCompleted?.bank ?? false} height='15px' /> */}
            <Row gutter={15} style={{ width: '100%' }}>
                {!(team?.integrationsBlocked ?? []).includes('stripe') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'stripe')} setupIntegration={() => setOpenModal(true)} />
                </Col>}
                {!(team?.integrationsBlocked ?? []).includes('openpay') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'openpay')} setupIntegration={() => setaddOpenpay(true)} />
                </Col>}
                {!(team?.integrationsBlocked ?? []).includes('bank') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'spei')} setupIntegration={() => setaddBank(true)} />
                </Col>}
                {!(team?.integrationsBlocked ?? []).includes('zapier') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'zapier')} setupIntegration={() => setaddZapier(true)} />
                </Col>}
                {!(team?.integrationsBlocked ?? []).includes('airtable') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'airtable')} setupIntegration={() => setAddAirtable(true)} />
                </Col>}
                {!(team?.integrationsBlocked ?? []).includes('googlesheets') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'googlesheets')} setupIntegration={() => console.log('googlesheets')} />
                </Col>}
                {!(team?.integrationsBlocked ?? []).includes('whmcs') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'whmcs')} setupIntegration={() => setAddwhmcs(true)} />
                </Col>}
            </Row>
            {/* <AppCard title='Paypal' description='Cobra a tus clientes con Paypal' onClick={() => setaddPayPal(true)} logo={paypalLogo} isCompleted={integrationsCompleted?.paypal ?? false} height='25px' />
            <AppCard title='Conekta' description='Cobra a tus clientes con Conekta' onClick={() => setAddConekta(true)} logo={conektaLogo} isCompleted={integrationsCompleted?.conekta ?? false} height='75px' /> */}
            <AppCategoryHeader title='Facturación' />
            {/* <AppCard title='Facturapi' description='Emite facturas desde gigstack' onClick={() => setaddFacturapi(true)} logo={facturapiLogo} isCompleted={integrationsCompleted?.facturapi ?? false} height='15px' /> */}
            <Row gutter={15} style={{ width: '100%' }}>
                <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'facturapi')} setupIntegration={() => setaddFacturapi(true)} />
                </Col>
            </Row>
            <AppCategoryHeader title='Mensajería' />
            {/* <AppCard title='Hilos' description='Envía mensajes de WhatsApp a tus clientes' onClick={() => setAddHilos(true)} logo={hilosLogo} isCompleted={integrationsCompleted?.hilos ?? false} height='20px' /> */}
            <Row gutter={15} style={{ width: '100%' }}>
                {!(team?.integrationsBlocked ?? []).includes('hilos') && <Col xl={6}>
                    <AppCard integration={integrations.find(i => i.id === 'hilos')} setupIntegration={() => setAddHilos(true)} />
                </Col>}
            </Row>
        </Row>
    }


    // const IntegrationsInfo = () => {
    //     return <Row style={{ width: '100%' }}>
    //         <Col xs={24} md={12} lg={6}>
    //             <Typography.Text className='cLabel'>Pagos</Typography.Text>
    //         </Col>
    //         <Col xs={24} md={12} lg={18}>
    //             <PaymentOptions userDocument={userDocument} />
    //         </Col>
    //         <Col xs={24} style={{ marginTop: '25px' }}></Col>
    //         <Col xs={24} md={12} lg={6}>
    //             <Typography.Text className='cLabel'>Contabilidad</Typography.Text>
    //         </Col>
    //         <Col xs={24} md={12} lg={18}>
    //             <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
    //                 <Col xs={24} style={{ marginTop: '20px', backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
    //                     <Row align="middle">
    //                         <div onClick={() => setaddFacturapi(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
    //                             {HasFtpi().hasFtpi ? <EditOutlined /> : <SettingOutlined />}
    //                         </div>
    //                         <div className='d-flex flex-column'>
    //                             <Typography.Text style={{ fontSize: '14px' }}>Emite facturas <span style={{ fontSize: '11px' }}>{userDocument?.invoiceProfile ? `- certificados válidos hasta el ${moment(userDocument?.invoiceProfile?.certificate?.expires_at).format('dddd D MMMM YYYY') ?? ''}` : ''}</span></Typography.Text>
    //                             <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Emite y timbra facturas válidas ante el SAT</Typography.Text>
    //                         </div>
    //                     </Row>
    //                 </Col>
    //                 <Col xs={24} style={{ marginTop: '20px', backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
    //                     <Row align="middle">
    //                         <div onClick={() => setaddOpenBankingAccount(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
    //                             {accountsDBStatus === 'loading' ? <LoadingOutlined /> : <Suspense fallback={<LoadingOutlined />}>
    //                                 {accountsData.length >= 0 ? <Typography.Text type="secondary">{accountsData.length}</Typography.Text> : <PlusOutlined />}
    //                             </Suspense>}
    //                         </div>
    //                         <div className='d-flex flex-column'>
    //                             <Typography.Text style={{ fontSize: '14px' }}>Cuentas bancarias</Typography.Text>
    //                             <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Concilia tus transacciones bancarias con tus pagos y facturas </Typography.Text>
    //                         </div>
    //                     </Row>
    //                 </Col>
    //             </Row>
    //         </Col>
    //         <Col xs={24} md={12} lg={6}>
    //             <Typography.Text className='cLabel'>Mensajería</Typography.Text>
    //         </Col>
    //         <Col xs={24} md={12} lg={18}>
    //             <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
    //                 <Col xs={24} style={{ marginTop: '20px', backgroundColor: '#f8f8f8', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px' }}>
    //                     <Row align="middle">
    //                         <div onClick={() => setAddHilos(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
    //                             {HasHilos().hasHilos ? <EditOutlined /> : <SettingOutlined />}
    //                         </div>
    //                         <div className='d-flex flex-column'>
    //                             <Typography.Text style={{ fontSize: '14px' }}>Notifica a tus clientes</Typography.Text>
    //                             <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Envía notificaciones a tus clientes vía WhatsApp con la integración de hilos.io</Typography.Text>
    //                         </div>
    //                     </Row>
    //                 </Col>

    //             </Row>
    //         </Col>

    //     </Row>
    // }

    return (
        <Col xs={24}>
            {configDefaults && <Suspense>
                <DefaultsConfigDrawer open={configDefaults} close={() => setConfigDefaults(false)} userDocument={userDocument} />
            </Suspense>}
            {addBank && <Suspense fallback={<LoadingOutlined />}>
                <BankModal open={addBank} close={() => setaddBank(false)} userDocument={userDocument} />
            </Suspense>}
            {addwhmcs && <Suspense fallback={<LoadingOutlined />}>
                <WhmcsModal open={addwhmcs} close={() => setAddwhmcs(false)} userDocument={userDocument} />
            </Suspense>}
            {addZapier && <Suspense fallback={<LoadingOutlined />}>
                <ModalZapier open={addZapier} close={() => setaddZapier(false)} userDocument={userDocument} />
            </Suspense>}
            {addAirtable && <Suspense fallback={<LoadingOutlined />}>
                <AirtableModal open={addAirtable} close={() => setAddAirtable(false)} />
            </Suspense>}
            {addPayPal && <Suspense fallback={<LoadingOutlined />}>
                <PayPalModal open={addPayPal} close={() => setaddPayPal(false)} userDocument={userDocument} />
            </Suspense>}
            {addOpenpay && <Suspense fallback={<LoadingOutlined />}>
                <OpenpayConnectModal open={addOpenpay} close={() => setaddOpenpay(false)} />
            </Suspense>}
            {addFacturapi && <Suspense fallback={<LoadingOutlined />}>
                <FacturapiModal edit={integrationsCompleted?.facturapi ?? false} open={addFacturapi} close={() => setaddFacturapi(false)} userDocument={userDocument} />
            </Suspense>}
            {addOpenBankingAccount && <Suspense fallback={<LoadingOutlined />}>
                <OpenBankingModal userDocument={userDocument} open={addOpenBankingAccount} close={() => setaddOpenBankingAccount(false)} />
            </Suspense>}
            {addConekta && <Suspense fallback={<LoadingOutlined />}>
                <ConektaModal close={() => setAddConekta(false)} userDocument={userDocument} open={addConekta} />
            </Suspense>}

            {addHilos && <Suspense fallback={<LoadingOutlined />}>
                <HilosModal open={addHilos} close={() => setAddHilos(false)} userDocument={userDocument} />
            </Suspense>}

            {openModal && <Suspense fallback={<LoadingOutlined />}>
                {team?.stripe?.completed ? <StripeModal open={openModal} close={({ haveChange }) => {
                    setOpenModal(false)
                    if (haveChange) getCurrentTeam(team?.id)
                }} userDocument={userDocument} /> :
                    <ConectModal open={openModal} close={() => setOpenModal(false)} userDocument={userDocument} />}

            </Suspense>}

            <Form form={form} initialValues={userDocument} onFinish={async (v) => {

            }} layout='vertical' style={{ width: '100%' }}>
                <div className="settingsContainer">
                    <Row justify="space-between" style={{ width: '100%' }} align="middle">
                        <div className="d-flex flex-column">
                            <Typography.Title style={{ marginBottom: '2px' }} level={5}>Aplicaciones </Typography.Title>
                            <Typography.Text type="secondary">Conecta las aplicaciones que utilizas día con día y automatiza las acciones</Typography.Text>
                        </div>
                        <Button size="medium" type='primary' onClick={() => setConfigDefaults(true)}>Definir información predeterminada</Button>
                    </Row>

                    <Divider />
                    <AppsInfo />
                    {/* <IntegrationsInfo /> */}


                </div>
            </Form>


        </Col>
    );
}

export default IntegrationsSettings;