import { ClockCircleOutlined, CloseOutlined, EditOutlined, LoadingOutlined, PlusOutlined, RightOutlined, StarOutlined } from '@ant-design/icons';
import { Col, Divider, Form, message, Row, Typography } from 'antd';
import moment from 'moment';


import React, { useContext, useState } from 'react'
import { SuspenseWithPerf, useAuth } from 'reactfire';
import { SignedInternalAPIRequest } from '../../app/functions/APIRequests';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { useMembership } from '../../customHooks/useMembership';


function MembershipSettings({ userDocument }) {
    const { billingAccount } = useContext(BillingAccountContext)
    const auth = useAuth()
    const { isPaidUser, membership: md, payAsYouGo, credits } = useMembership({ uid: auth.currentUser.uid, billingAccountId: billingAccount?.id });

    const [form] = Form.useForm();
    const [loading, setloading] = useState(false)




    const ManageStripePortal = async (manage) => {
        if (loading) { return }
        setloading(true)
        const session = await SignedInternalAPIRequest({
            type: manage ? 'manage' : 'subscribe', price: '', redirect: 'https://app.gigstack.pro/settings?subtab=membership'
        }, 'manageSubscriptionWithCustomerPortal', auth.currentUser)
        setloading(false)
        if (session.error) {
            message.error(session.error)
            return;
        }
        return window.open(session.session, '_self')
    }



    const HasSubscription = () => {
        const { price_id } = md
        return { hasSubscription: (price_id != null && price_id !== 'free' && price_id !== 'gratis') }

    }



    const { cancel_at, current_period_end } = (md.subscription ?? {})

    const MembershipData = () => {
        return <Row style={{ width: '100%' }}>
            <Col xs={24} md={12} lg={6}>
                <Typography.Text className='cLabel'>Suscripción</Typography.Text>
            </Col>
            <Col xs={24} md={12} lg={18}>
                <Row gutter={{ xs: 10, md: 12, lg: 17 }}>
                    <Col xs={24} style={{ marginTop: '20px' }}>
                        <Row align="middle">
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <Typography.Text type='secondary' style={{ fontSize: '10px' }}> {credits <= 0 ? '0' : md?.credits ?? "3"}</Typography.Text>
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>Eventos disponibles</Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Los eventos te permiten crear automatizaciones.</Typography.Text>
                                {!isPaidUser && <Typography.Text style={{ fontSize: '12px' }} type='secondary'>Siguiente renovación {moment(md?.nextAttach).format('DD/MM/YY HH:mm')}</Typography.Text>}
                            </div>
                        </Row>
                    </Col>
                    {payAsYouGo &&
                        <Col xs={24} style={{ marginTop: '20px' }}>
                            <Row align="middle">
                                <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px', backgroundColor: '#8666FF' }}>
                                    <Typography.Text type='secondary' style={{ fontSize: '10px' }}><StarOutlined style={{ color: 'white', marginBottom: '2px', fontSize: '14px' }} /></Typography.Text>
                                </div>
                                <div className='d-flex flex-column' style={{ flex: 1 }}>
                                    <Typography.Text style={{ fontSize: '14px' }}>Pay as you go!</Typography.Text>
                                    <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Tu empresa nunca para, una vez que termines los eventos de tu cuenta se facturará cada evento extra utilizado. De esta forma nunca tendrás interrupción.</Typography.Text>
                                    {credits <= 0 && <Typography.Text style={{ fontSize: '12px' }} type='secondary'>{credits * -1} evento{credits * -1 > 1 ? 's' : ''} extra utilizados</Typography.Text>}
                                </div>
                            </Row>
                        </Col>}
                    <Col xs={24} style={{ marginTop: '20px' }}>
                        <Row align="middle">
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <ClockCircleOutlined />
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>Último evento utilizado</Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>{moment(md?.lastUsed?.seconds * 1000).fromNow()}</Typography.Text>
                            </div>
                        </Row>
                    </Col>
                    {cancel_at && <Col xs={24} style={{ marginTop: '20px' }}>
                        <Row align="middle">
                            <div onClick={() => ManageStripePortal(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px', backgroundColor: '#eda3a3' }}>
                                {loading ? <LoadingOutlined /> : <CloseOutlined style={{ color: 'white' }} />}
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>Cancelaste tu suscripción</Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>{moment(cancel_at * 1000).format('dddd DD MMMM YYYY HH:mm')} - Recupera tu cuenta. {moment().valueOf() < (cancel_at * 1000) && `Podrás utilizar tus eventos hasta el día ${moment(current_period_end * 1000).format('DD/MM/YYYY')}`}</Typography.Text>
                            </div>
                        </Row>
                    </Col>}
                    {(current_period_end && !cancel_at) && <Col xs={24} style={{ marginTop: '20px' }}>
                        <Row align="middle">
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <RightOutlined />
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>Siguiente abono de eventos</Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>{moment(md.nextAttach).format('dddd D MMMM YYYY HH:mm')}</Typography.Text>
                            </div>
                        </Row>
                    </Col>}
                    {HasSubscription().hasSubscription ? <Col xs={24} style={{ marginTop: '20px' }}>
                        <Row align="middle">
                            <div onClick={() => ManageStripePortal(true)} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                {loading ? <LoadingOutlined /> : <EditOutlined />}
                            </div>
                            <div className='d-flex flex-column'>
                                <Typography.Text style={{ fontSize: '14px' }}>Actualiza tu suscripción</Typography.Text>
                                <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Actualiza tu tarjeta, cambia de plan, revisa tus recibos, etc.</Typography.Text>
                            </div>
                        </Row>
                    </Col> :

                        <Col xs={24} style={{ marginTop: '20px' }}>
                            <Row align="middle">
                                <div onClick={() => {
                                    return window.open(`/memberships`, '_self');
                                }} className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                </div>
                                <div className='d-flex flex-column'>
                                    <Typography.Text style={{ fontSize: '14px' }}>Mejora tu plan</Typography.Text>
                                    <Typography.Text className='' style={{ fontSize: '12px' }} type='secondary'>Accede a más recursos, para administrar tu negocio.</Typography.Text>
                                </div>
                            </Row>
                        </Col>
                    }

                </Row>
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}></Col>


        </Row>
    }

    return (
        <Col xs={24}>
            <Form form={form} initialValues={userDocument} onFinish={async (v) => {

            }} layout='vertical' style={{ width: '100%' }}>
                <div className="settingsContainer">
                    <Typography.Title style={{ marginBottom: '2px' }} level={5}>Suscripción en gigstack pro </Typography.Title>
                    <Typography.Text type="secondary">Recursos disponibles para ti con la suscripción que tienes actualmente</Typography.Text>
                    <Divider />
                    <SuspenseWithPerf traceId='membership-data' fallback={<LoadingOutlined />}>
                        <MembershipData />
                    </SuspenseWithPerf>



                </div>
            </Form>


        </Col>
    );
}

export default MembershipSettings;