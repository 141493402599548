import { Checkbox, Modal, Switch, Typography } from 'antd';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'


function ClientDefaultsModal({ open, close, client }) {

    const [defaults, setDefaults] = useState(client.defaults ?? {
        avoidNextInvoices: false
    })
    return (
        <Modal visible={open} onCancel={async () => {
            close()
            await updateDoc(doc(getFirestore(), 'clients', client.id), { defaults: defaults })
        }} footer={null} title={null}>
            <Typography.Title level={5}>Valores predeterminados del cliente</Typography.Title>
            <div className="d-flex flex-column" style={{ marginTop: '10px' }}>
                <Typography.Text className='cLabel'>Emitir facturas automáticas para este cliente</Typography.Text>
                <div style={{ marginBottom: '5px', marginTop: '5px' }}>
                    <Switch onChange={async (v) => {
                        setDefaults({ ...defaults, avoidNextInvoices: !v })
                    }} defaultChecked={!defaults?.avoidNextInvoices} />
                </div>
                <div className='d-flex flex-row' style={{ marginTop: '15px' }}>
                    <Checkbox disabled={!defaults?.avoidNextInvoices} checked={defaults?.invoiceableReceipts} onChange={(v) => {
                        setDefaults({ ...defaults, invoiceableReceipts: v.target.checked })
                    }} >
                        Crear recibos de venta facturables
                    </Checkbox>
                </div>
                <Typography.Text type='secondary' style={{ fontSize: '10px' }}>{defaults?.avoidNextInvoices ? `*Emitiremos recibos de venta que ${defaults?.invoiceableReceipts ? '' : 'NO'} podrán ser facturados` : '*Emitiremos facturas o recibos de venta facturables para este cliente.'}</Typography.Text>
            </div>

        </Modal>
    );
}

export default ClientDefaultsModal;