import { Col, Form, Row, TimePicker } from 'antd';
import React from 'react';


/**
 * It's a React component that renders a time picker and updates the state of the parent component when
 * the time is changed
 * @returns A React component
 */
function DailyRecurring({ updateState = () => { } }) {
    return (

        <Col xs={24} md={12}>
            <Row gutter={{ xs: 1, md: 5 }}>
                <Col xs={24} >
                    <Form.Item name="onTime" label="Hora" style={{ width: '100%' }}>
                        <TimePicker className="pro-input" format={'HH:mm'} placement="bottomLeft" onChange={(v) => {
                            if (v) {
                                updateState({ onTime: v.format('HH:mm') })
                            } else {
                                updateState({ onTime: null })
                            }
                        }} />
                    </Form.Item>
                </Col>
            </Row>
        </Col>
    );
}

export default DailyRecurring;