import { BookOutlined, CheckCircleOutlined, CheckOutlined, ClockCircleOutlined, CopyOutlined, DeleteOutlined, DollarOutlined, EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined, LeftOutlined, LoadingOutlined, MailOutlined, NodeIndexOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, message, Popconfirm, Row, Space, Tag, Typography } from 'antd';
import { collection, deleteDoc, doc, getFirestore, increment, query, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useContext, useState } from 'react'
import { useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import TeamContext from '../../context/teamcontext/TeamContext';
import { UseUserDefaults } from '../../customHooks/useUserDefaults';
import ComposeEmailModal from '../../widgets/email/ComposeEmailModal';
import InvoicesRelatedTo from '../../widgets/invoices/InvoicesRelatedTo';
import ReviewPaymentModal from '../../widgets/payments/ReviewPaymentModal';
import InvoicesRelations from '../../widgets/relations/InvoicesRelations';
import EmptyTable from '../../widgets/states/EmptyTable';
import { CopyToClipboard, getItemsAmounts, paymentIntentsStatusMessage, returnColorByPaymentStatus, returnCurrencyValue, returnTextColorByPaymentStatus, SearchParamInURL } from '../functions/helpers';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import UserContext from '../../context/usercontext/UserContext';
import { useUserPermissions } from '../../customHooks/userPermissions';
import ReceiptsRelatedTo from '../../widgets/receipts/ReceiptsRelatedTo';


function PaymentDetails({ userDocument, changeRender }) {
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)
    const { user } = useContext(UserContext)
    const { canCreatePayments, canViewPayments } = useUserPermissions({ user, team, billingAccount })

    const fs = getFirestore()
    const ref = doc(fs, 'payments', SearchParamInURL('id'))
    const { defaults } = UseUserDefaults({ userDocument, team })
    const { status, data: paymentData } = useFirestoreDocData(ref)
    const { status: relStatus, data: relationData } = useFirestoreCollectionData(query(collection(fs, 'invoices'), where('team', '==', team?.id ?? ''), where('payments', 'array-contains-any', [SearchParamInURL('id')])), {
        idField: 'id',
    })

    const { status: receiptsStatus, data: receiptsRelations } = useFirestoreCollectionData(query(collection(fs, 'receipts'), where('team', '==', team?.id ?? ''), where('payments', 'array-contains-any', [SearchParamInURL('id')])), {
        idField: 'id',
    })

    const { status: emailStatus, data: emailsData } = useFirestoreCollectionData(query(collection(fs, 'emails'), where('related', '==', `payments/${SearchParamInURL('id')}`)))
    const [loading, setloading] = useState(false)
    const [confirmPayment, setconfirmPayment] = useState(null)
    const [composeEmail, setcomposeEmail] = useState(null)

    if (status === 'loading') {
        return <div>Cargando...</div>
    }

    /**
     * It closes the modal and returns the user to the previous page
     * @returns the window.history.replaceState() function.
     */
    const Close = () => {
        changeRender('payments')
        return window.history.replaceState(null, `tab`, `/payments?pageActive=${SearchParamInURL('pageActive') ?? '1'}`)
    }



    /**
     * It deletes the document from the database
     */
    const DeleteCharge = async () => {
        setloading('delete')
        try {
            await deleteDoc(doc(fs, 'payments', paymentData.fid || paymentData.id))
            Close()
            setloading(null)
        } catch (error) {
            message.error(error.message)
            setloading(null)
        }
    }



    /**
     * It returns a Col component with a div inside, which contains two Row components, one with a
     * LeftOutlined icon and a Title component, and the other with a div with a pill class and a Text
     * component
     * @returns A React component
     */
    const Header = () => {
        return <Col xs={24}>
            <div style={{}} className="d-flex flex-column">
                <Row justify="space-between" align="middle">
                    <Row align="middle">
                        <LeftOutlined onClick={Close} />
                        <Typography.Title style={{ margin: 0, padding: 0, marginLeft: '10px' }} level={3}>Detalles del pago por {returnCurrencyValue(paymentData?.amount / 100 ?? paymentData?.total / 100)} {paymentData?.currency?.toUpperCase()}</Typography.Title>
                    </Row>
                    <div className="pill" style={{ marginLeft: '10px', backgroundColor: returnColorByPaymentStatus(paymentData) }}>
                        <Typography.Text style={{ color: returnTextColorByPaymentStatus(paymentData) }}>{paymentIntentsStatusMessage(paymentData)}</Typography.Text>
                    </div>
                </Row>
                {paymentData.hidden && <Row justify="start" style={{ marginLeft: '25px' }} align="middle">
                    <WarningOutlined style={{ color: '#fadb14', marginRight: '5px' }} />
                    <Typography.Text type="secondary">Este pago se encuentra oculto en las listas <span className="clickable primary" onClick={() => {
                        updateDoc(doc(getFirestore(), 'payments', paymentData?.fid), {
                            hidden: !paymentData.hidden
                        })
                    }}>mostrar</span></Typography.Text>
                </Row>}
                <Row justify="start" style={{ marginLeft: '25px' }}>
                    <Typography.Text type="secondary">Solicitado  el {moment(paymentData?.timestamp).format('DD/MM/YYYY HH:mm')} ({moment(paymentData?.timestamp).fromNow()})</Typography.Text>
                </Row>
            </div>
        </Col>
    }

    /**
     * It returns a column with a title, a div with a row with a div with a text inside, and another div
     * with a text and another text inside
     * @returns A Col component with a div inside it.
     */
    const Client = () => {
        return <Col xs={24} md={12} lg={8}>
            <div className="d-flex flex-column">
                <Typography.Title level={5}>Cliente</Typography.Title>
                <div className='tableContainer d-flex flex-column'>

                    <Row align="middle">
                        <div className="customCircledTag" style={{ height: '30px', width: '30px' }}>
                            <Typography.Text>{paymentData?.client?.name?.substring(0, 1) ?? "?"}</Typography.Text>
                        </div>
                        <div className="d-flex flex-column" style={{ marginLeft: '10px' }}>
                            <Typography.Text style={{ margin: 0, padding: 0 }}>{paymentData?.client?.name ?? "?"}</Typography.Text>
                            <Typography.Text style={{ margin: 0, padding: 0, fontSize: '11px' }} type="secondary">{paymentData?.client?.email ?? "?"}</Typography.Text>
                        </div>
                    </Row>
                </div>
            </div>

        </Col>
    }

    /**
     * It returns a column with a title, a table, and a loading icon
     * @returns A Col component with a div inside.
     */
    const Invoices = () => {
        return <Col xs={24} md={12} lg={10}>
            <div className="d-flex flex-column">
                <Typography.Title level={5}>Facturación</Typography.Title>
                <div className='tableContainer d-flex flex-column'>

                    {relStatus === 'loading' ? <LoadingOutlined /> : <Suspense fallback={<LoadingOutlined />}>
                        {relationData.length > 0 ? <InvoicesRelatedTo showCards={false} relatedTo={paymentData} invoices={relationData} userDocument={userDocument} /> : <EmptyTable customMessage={'No hay facturas relacionadas'} parentAction={null} elementName={'pagos'} />}
                    </Suspense>}
                </div>
            </div>

        </Col>;
    }


    /**
   * It returns a column with a title, a table, and a loading icon
   * @returns A Col component with a div inside.
   */
    const Receipts = () => {
        return <Col xs={24} md={12} lg={10}>
            <div className="d-flex flex-column">
                <Typography.Title level={5}>Recibos de autofactura</Typography.Title>
                <div className='tableContainer d-flex flex-column'>

                    {receiptsStatus === 'loading' ? <LoadingOutlined /> : <Suspense fallback={<LoadingOutlined />}>
                        {(receiptsRelations ?? [])?.length > 0 ? <ReceiptsRelatedTo showHeader={false} showCards={false} relatedTo={paymentData} receipts={receiptsRelations} userDocument={userDocument} /> : <EmptyTable customMessage={'No hay facturas relacionadas'} parentAction={null} elementName={'pagos'} />}
                    </Suspense>}
                </div>
            </div>

        </Col>;
    }


    /**
     * It returns a column with a title and a table with two rows
     * @returns A Col component with a div inside.
     */
    const FullDetails = () => {
        var limitDate = paymentData?.limitDaysToPay ? moment(paymentData?.timestamp).add(paymentData?.limitDaysToPay ?? 0, 'days').hours(defaults?.limitHourToPay ?? '17') : null


        return <Col xs={24} lg={10}>
            <div className="d-flex flex-column">
                <Typography.Title level={5}>Detalles del pago</Typography.Title>
                <div className='tableContainer d-flex flex-column'>
                    {paymentData.hilosResponse && <Row align="middle" style={{ marginBottom: '10px' }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <WarningOutlined type="secondary" />
                        </div>
                        <div className="d-flex flex-column">
                            <Typography.Text >Hilos</Typography.Text>
                            {Object.keys(paymentData.hilosResponse).map((key, index) => {
                                return <Typography.Text key={index} style={{ fontSize: '11px' }}>
                                    <span className="strong">{key}</span>: {paymentData.hilosResponse[key]}
                                </Typography.Text>

                            })}
                        </div>
                    </Row>}
                    <Row align="middle">
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <DollarOutlined type="secondary" />
                        </div>
                        <div className="d-flex flex-column">
                            <Typography.Text >Monto</Typography.Text>
                            <Typography.Text style={{ fontSize: '11px' }}>
                                {getItemsAmounts(paymentData.items, (paymentData.discount > 0 && paymentData.paidIn === 'bank')).totalString}
                            </Typography.Text>
                        </div>
                    </Row>
                    <Row align="middle" style={{ marginTop: '10px' }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <DollarOutlined type="secondary" />
                        </div>
                        <div className="d-flex flex-column">
                            <Typography.Text >Monto Pagado</Typography.Text>
                            <Typography.Text style={{ fontSize: '11px' }}>{returnCurrencyValue(paymentData?.amount / 100 ?? 0)}</Typography.Text>
                        </div>
                    </Row>
                    <Row align="middle" style={{ marginTop: '10px' }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <NodeIndexOutlined type="secondary" />
                        </div>
                        <div className="d-flex flex-column">
                            <Typography.Text >ID del pago</Typography.Text>
                            <Typography.Text style={{ fontSize: '11px' }} copyable>{paymentData?.fid}</Typography.Text>
                        </div>
                    </Row>
                    {(defaults?.allowPaymentsSetting && paymentData?.limitDaysToPay) && <Row align="middle" style={{ marginTop: '10px' }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <ClockCircleOutlined type="secondary" />
                        </div>
                        <div className="d-flex flex-column">
                            <Row>
                                <Typography.Text style={{ marginRight: '15px' }}>Límite de pago en</Typography.Text>
                                <Typography.Text style={{ marginRight: '5px' }} editable={{
                                    onChange: (v) => {
                                        //CHECK IF V IS A NUMBER

                                        if (v && typeof Number(v) === "number") {
                                            updateDoc(doc(fs, 'payments', paymentData?.fid), {
                                                limitDaysToPay: Number(v)
                                            })
                                        }
                                    }
                                }}>{paymentData?.limitDaysToPay}</Typography.Text>
                                <Typography.Text>días</Typography.Text>
                            </Row>
                            <Row align="middle">
                                <Typography.Text style={{ fontSize: '11px', color: limitDate?.valueOf() < moment().valueOf() ? 'red' : '' }} >{limitDate?.format('D MMMM YYYY ha')}</Typography.Text>
                                {limitDate?.valueOf() < moment().valueOf() && <Tag className="clickable"
                                    onClick={() => {
                                        updateDoc(doc(fs, 'payments', paymentData?.fid), {
                                            limitDaysToPay: increment(1)
                                        })
                                    }}
                                    style={{ marginLeft: '10px' }}>Añadir 1 día</Tag>}
                            </Row>
                        </div>
                    </Row>}
                </div>
            </div>
        </Col>
    }

    /**
     * It returns a column with a title, a loading icon, an empty table component, or a list of emails
     * @returns A Col component with a div inside.
     */
    const SentEmails = () => {
        return <Col xs={24} lg={12} >
            <div className="d-flex flex-column">
                <Typography.Title level={5}>Correos enviados</Typography.Title>
                <div className='tableContainer d-flex flex-column'>
                    {emailStatus === 'loading' ? <LoadingOutlined /> : emailsData?.length <= 0 ? <EmptyTable customMessage={'No hay correos enviados'} parentAction={null} elementName={'emails'} /> : emailsData.map((email, inx) => {
                        return <Row key={email?.id} style={{ marginTop: inx === 0 ? '0px' : '10px' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                <MailOutlined type="secondary" />
                            </div>
                            <div className="d-flex flex-column">
                                <Typography.Text >"{email?.subject}"</Typography.Text>
                                <Row wrap={true}>
                                    {email?.to?.map((to) => {
                                        return <Tag key={to}>{to}</Tag>
                                    })}
                                </Row>
                                <Typography.Text type="secondary" style={{ fontSize: '11px', marginTop: '5px' }}>Enviado {moment(email.timestamp).fromNow()}, último evento recibido: {email.lastEvent ? `${email?.lastEvent === 'open' ? '"abierto"' : email?.lastEvent} ${moment(email?.lastEventAt * 1000).fromNow()}` : 'sin evento.'}</Typography.Text>
                            </div>
                        </Row>
                    })}
                </div>
            </div>
        </Col>
    }

    /**
     * It returns a column with a title, and a list of actions that can be performed on the payment
     * @returns A column with a title and a div with a class of tableContainer.
     */

    const Actions = () => {
        return <Col xs={24} md={12} lg={6}>
            <div className="d-flex flex-column">
                <Typography.Title level={5}>Acciones</Typography.Title>
                <div className='tableContainer d-flex flex-column'>

                    <InvoicesRelations customElement={<Row align="middle" className="clickable" style={{ marginTop: '0px' }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <BookOutlined type="secondary" />
                        </div>

                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Crear factura</Typography.Text>
                    </Row>} custom={true} items={paymentData?.items} collection="payments" elementType="payment" changeState={(i) => {

                    }} client={paymentData?.client} item={{ ...paymentData, invoices: paymentData?.invoices }} id={paymentData?.fid} userDocument={userDocument} readable="pago" />


                    {paymentData?.shortURL && <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => CopyToClipboard(paymentData?.shortURL)}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <CopyOutlined type="secondary" />
                        </div>

                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Copiar enlace</Typography.Text>
                    </Row>}
                    {paymentData?.status !== 'succeeded' && <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => {

                        setcomposeEmail(true)
                    }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            <MailOutlined type="secondary" />
                        </div>
                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Enviar recordatorio</Typography.Text>
                    </Row>}
                    {paymentData?.status !== 'succeeded' && <Row align="middle" className="clickable" style={{ marginTop: '10px' }} onClick={() => {
                        updateDoc(doc(getFirestore(), 'payments', paymentData?.fid), {
                            hidden: !paymentData.hidden
                        })

                    }}>
                        <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                            {paymentData?.hidden ? <EyeInvisibleOutlined type="secondary" /> : <EyeOutlined type="secondary" />}
                        </div>

                        <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">{paymentData.hidden ? 'Mostrar en' : 'Ocultar de'} las listas</Typography.Text>
                    </Row>}

                    {(paymentData?.status !== 'succeeded' && paymentData?.status !== 'paid') && <Popconfirm okText="Pagado" cancelText="Cancelar" icon={<CheckCircleOutlined />} title="Marcar esta transacción como pagada con efectivo?" onConfirm={() => {
                        updateDoc(doc(getFirestore(), 'payments', paymentData?.fid), {
                            status: 'succeeded',
                            internalStatus: 'succeeded',
                            paidIn: 'bank',
                            binnacle: (paymentData.binnacle ?? '') + `\nPayment marked as paid with cash but use bank to add discount`,
                            payment_form: '01'
                        })
                    }}>

                        <Row align="middle" className="clickable" style={{ marginTop: '10px' }} >
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                {<CheckOutlined />}
                            </div>

                            <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px', flex: 1 }} type="secondary">Marcar como pagado con efectivo</Typography.Text>
                        </Row>
                    </Popconfirm>}

                    {canCreatePayments && (paymentData?.paidIn || paymentData?.status === 'pending' || paymentData?.status === 'requires_payment_method') && <Popconfirm title="¿Eliminar el cargo?" onConfirm={DeleteCharge} okButtonProps={{ danger: true }} okText="Eliminar" cancelText="Cancelar">
                        <Row align="middle" className="clickable" style={{ marginTop: '10px' }}>
                            <div className="customCircledTag" style={{ width: '30px', height: '30px', marginRight: '15px' }}>
                                {loading === 'delete' ? <LoadingOutlined /> : <DeleteOutlined type="secondary" />}
                            </div>

                            <Typography.Text style={{ margin: 0, padding: 0, marginLeft: '0px' }} type="secondary">Eliminar el cargo</Typography.Text>
                        </Row>
                    </Popconfirm>}

                </div>
            </div>

        </Col>
    }


    if (!paymentData) {
        return <div></div>
    }

    if (!canViewPayments) return <div></div>

    return (
        <Row justify='center'>
            {confirmPayment && <ReviewPaymentModal open={confirmPayment} close={() => setconfirmPayment(false)} payment={paymentData} />}
            {composeEmail && <ComposeEmailModal
                body={`Hola ${(paymentData?.client ?? paymentData?.internalClient)?.name ?? ''}, este es un recordatorio del pago pendiente solicitado ${moment(paymentData?.timestamp).fromNow()} por un total de ${returnCurrencyValue(paymentData?.amount / 100)} ${paymentData?.currency}.\n\nCualquier duda por favor responde a este correo.\n\n\n-${userDocument?.brand?.alias ?? userDocument?.firstName}`}
                close={() => setcomposeEmail(false)}
                actionText={'Realizar pago'}
                idRef={'payments'}

                data={paymentData}
                userDocument={userDocument}
                open={composeEmail}
                element={paymentData}
                subject={`Recordatorio de pago pendiente`}
                readableType={'pagos'}
                client={(paymentData?.client || paymentData?.internalClient) ?? { name: 'Sin Cliente', email: '' }} />}
            <Header />
            {(paymentData?.review_status === 'pending' && paymentData) && <Col xs={24} style={{ marginTop: '25px' }}>
                <Row>
                    <Col key={paymentData?.id} xs={24}>
                        <div style={{ backgroundColor: '#F9F3DE', padding: '10px 20px', borderRadius: '10px' }}>
                            <Row justify="space-between" align="middle">
                                <Space>
                                    <div style={{ minHeight: '50px', height: '100%', backgroundColor: '#FFE594', width: '5px', marginRight: '10px', borderRadius: '5px' }}></div>
                                    <InfoCircleOutlined style={{ fontSize: '20px' }} />
                                    <div className="d-flex flex-column " style={{ marginLeft: '10px' }}>
                                        <Typography.Text style={{ fontSize: '13px' }} className="strong" >{paymentData?.client?.name ?? 'Sin Cliente'} / {returnCurrencyValue(paymentData?.amount / 100)} {paymentData?.currency}</Typography.Text>
                                        <Typography.Text className="secondary" style={{ fontSize: '12px', fontFamily: 'DM Sans', fontWeigth: 'w400' }}>{moment(paymentData?.review_created_at).fromNow()}</Typography.Text>
                                    </div>
                                </Space>
                                <Button type="" size="middle" onClick={() => setconfirmPayment(paymentData)}>Revisar pago</Button>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>}
            <Col xs={24} style={{ marginTop: '50px' }}>
                <Row gutter={{ xs: 12, md: 15, lg: 20 }}>
                    <Client />
                    <FullDetails />
                    <Actions />
                    <Col xs={24} style={{ marginBottom: '25px' }}></Col>
                    <Invoices />
                    <Receipts />
                    <SentEmails />
                    <Col xs={24} style={{ marginBottom: '25px' }}></Col>
                </Row>
            </Col>

        </Row>
    );
}

export default PaymentDetails;