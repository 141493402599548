import { Col, Input, Popconfirm, Row, Space, Table, Tag, Tooltip, Typography } from "antd"
import TableTitle from "./TableTitle"
import { SearchParamInURL, generateCode } from "../../app/functions/helpers"
import { CopyOutlined, DeleteOutlined, EditOutlined, ExperimentOutlined, EyeOutlined } from "@ant-design/icons"
import { useContext, useState } from "react"
import TeamContext from "../../context/teamcontext/TeamContext"
import { collection, deleteDoc, doc, getFirestore, orderBy, query, where } from "firebase/firestore"
import { useFirestoreCollectionData } from "reactfire"
import moment from "moment"

const ClientsTable = ({ setEditClient, changeRender, setaddClient, setPreview }) => {

    const { team } = useContext(TeamContext)


    const fs = getFirestore();
    const clientsRef = query(collection(fs, `clients`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));

    // subscribe to a document for realtime updates. just one line!
    const { status: clientsDBStatus, data: clientsData } = useFirestoreCollectionData(clientsRef, {
        id: 'id', // this field will be added to the object created from each document
    });


    const [pageActive, setPageActive] = useState(SearchParamInURL('pageActive') ?? 1)
    const [pageSize, setPageSize] = useState(10)
    const [search, setSearch] = useState('')
    const [showTestMode, setShowTestMode] = useState(true)


    return (<>
        <Col xs={24} style={{ marginTop: '25px' }}>
            <Row justify="end">
                <Input.Search defaultValue={search} placeholder="Buscar cliente" onSearch={(v) => {
                    setSearch(v ?? '')
                }} style={{ width: window.innerWidth < 800 ? '100%' : '30%' }} />
            </Row>
            <Row justify="end" style={{ marginTop: '5px' }}>
                <Space wrap size="small" style={{ marginTop: '10px' }}>
                    <Tag onClick={() => setShowTestMode(!showTestMode)} className={showTestMode ? 'activeTag clickable' : ' clickable'}>Ver clientes de prueba</Tag>
                </Space>
            </Row>
        </Col>

        <Col xs={24} style={{ marginTop: '25px' }}>
            <Table
                loading={clientsDBStatus === 'loading'}
                dataSource={clientsData.filter((client) => {
                    return client?.name?.toLowerCase()?.includes(search?.toLowerCase()) || client?.email?.toLowerCase()?.includes(search?.toLowerCase()) || client?.rfc?.toLowerCase()?.includes(search?.toLowerCase()) || client?.company?.toLowerCase()?.includes(search?.toLowerCase()) || (client?.phone !== '' && client?.phone?.toLowerCase()?.includes(search?.toLowerCase())) || client?.id?.toLowerCase()?.includes(search?.toLowerCase())
                })}
                pagination={{
                    pageSize: pageSize,
                    current: pageActive,
                    onChange: (page, size) => {
                        setPageActive(page)
                        setPageSize(size)
                        return window.history.replaceState(null, `tab`, `/clients?pageActive=${page}`)
                    }
                }}
                columns={
                    [
                        {
                            title: <TableTitle title="Nombre" />,
                            dataIndex: 'name',
                            key: generateCode(5),
                            fixed: 'left',
                            width: '20%',
                            sorter: (a, b) => ((a?.name)?.localeCompare(b?.name)),
                            render: (text, record) => {
                                return <div className="d-flex flex-column">
                                    <Row align='middle'>
                                        <Typography.Text onClick={() => {
                                            changeRender('clientDetails')
                                            return window.history.replaceState(null, `tab`, `/clientDetails?id=${record?.id}&pageActive=${pageActive}`)
                                        }} className="primary clickable" >{text}</Typography.Text>
                                        {!record.livemode && record.livemode !== undefined && <Tooltip title="Modo pruebas">
                                            <ExperimentOutlined style={{ marginLeft: '5px', fontSize: '10px', color: '#cecece' }} />
                                        </Tooltip>}
                                    </Row>

                                    <Typography.Text copyable type="secondary" style={{ fontSize: '10px' }}>{record.id}</Typography.Text>
                                    <Typography.Text style={{ fontSize: '10px' }}>{record?.metadata?.stripeId}</Typography.Text>
                                    <Typography.Text style={{ fontSize: '10px' }}>{moment(record.timestamp).format('DD/MM/YYYY HH:mm:ss')}</Typography.Text>
                                </div>
                            }
                        },
                        {
                            title: <TableTitle title="Email" />,
                            dataIndex: 'email',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.email)?.localeCompare(b?.email)),
                            render: (text, record) => {

                                return <Typography.Text className="" type="secondary" copyable={{ text: text, icon: <CopyOutlined className="primary" /> }} ellipsis>{text}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="RFC" />,
                            dataIndex: 'rfc',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),
                            render: (text, record) => {

                                return <Typography.Text className="" type="secondary" copyable={text ? { text: text, icon: <CopyOutlined className="primary" /> } : null} ellipsis>{text ?? '-'}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Empresa" />,
                            dataIndex: 'company',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),
                            render: (text, record) => {

                                return record.website ? <a href={record.website} target="_blank" rel="noreferrer"><Typography.Text className="" type="secondary" copyable={null} ellipsis>{text ?? '-'}</Typography.Text></a> : <Typography.Text className="" type="secondary" copyable={null} ellipsis>{text ?? '-'}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Teléfono" />,
                            dataIndex: 'phone',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.rfc)?.localeCompare(b?.rfc)),
                            render: (text, record) => {

                                return <Typography.Text className="" type="secondary" copyable={null} ellipsis>{text ?? '-'}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="" />,
                            dataIndex: '',
                            key: generateCode(5),
                            render: (text, record) => {
                                return <Space>
                                    <EditOutlined style={{ color: '#cecece' }} className="hoverPrimary" onClick={() => {
                                        setEditClient(record)
                                        setaddClient(true)
                                    }} />
                                    <Popconfirm cancelText="Cancelar" okButtonProps={{ danger: true }} title="¿Eliminar cliente?" okText="Eliminar" onConfirm={async () => {
                                        await deleteDoc(doc(fs, `clients/${record.id}`))
                                    }}>
                                        <DeleteOutlined style={{ color: '#cecece' }} className="hoverDanger" />
                                    </Popconfirm>
                                    <EyeOutlined onClick={() => setPreview(record)} />
                                </Space>
                            }
                        }
                    ]}
            />
        </Col>
    </>)
}

export default ClientsTable