
import React, { useContext, useState } from 'react';
import { Button, Col, message, Popconfirm, Row, Space, Typography } from 'antd';
import ActionablePageHeader from '../../widgets/headers/ActionablePageHeader';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import DefaultTableItems from '../../widgets/tables/DefaultTableItems';
import { collection, deleteDoc, doc, getFirestore, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';

import TableTitle from '../../widgets/tables/TableTitle';
import { ForceDecimalNumber, generateCode, returnCurrencyValue } from '../functions/helpers';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import UserContext from '../../context/usercontext/UserContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import { useUserPermissions } from '../../customHooks/userPermissions';
// import CreateElementHolder from '../../widgets/drawers/CreateElementHolder';
import AddNewService from '../../widgets/services/AddNewService';

function ServicesPage({ userDocument, subtab }) {
    const [addService, setaddService] = useState(false)
    const [editService, seteditService] = useState(null)


    const { user } = useContext(UserContext)
    const { team } = useContext(TeamContext)
    const { billingAccount } = useContext(BillingAccountContext)

    const fs = getFirestore();
    const servicesRef = query(collection(fs, `services`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));

    // subscribe to a document for realtime updates. just one line!
    // const { status: servicesDBStatus, data: servicesData } = useFirestoreCollectionData(servicesRef, {
    //     idOnDB: 'id', // this field will be added to the object created from each document
    // });


    //TODO: CHECK MODAL



    const { canCreateServices } = useUserPermissions({ user, team, billingAccount })

    const DuplicateService = async (service) => {
        const code = generateCode(10, 'service');
        const newService = {
            id: code,
            ...service,
            name: `${service.name} (copia)`,
            timestamp: moment().valueOf()
        }

        try {
            await setDoc(doc(fs, `services/${code}`), newService)
            message.success('Servicio duplicado');
        } catch (error) {
            message.error(error.message);
        }


    }





    return (
        <Row justify='center' >
            {addService && <AddNewService open={addService} edit={editService ?? null} close={() => {
                setaddService(false)
                seteditService(null)
            }} userDocument={userDocument} type="services" />}
            <Col xs={24}>
                <DefaultHelpPageHeader userDocument={userDocument} title="Servicios" description={"Guarda tus servicios para que puedas solicitar pagos, enviar facturas y más de manera fácil "} />
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <ActionablePageHeader title="Mis servicios" actions={
                    <Space>
                        {canCreateServices && <Button type='primary' onClick={() => {
                            setaddService(true)
                        }}>Nuevo servicio</Button>}
                    </Space>}
                />
            </Col>

            <Col xs={24} style={{ marginTop: '25px' }}>
                <DefaultTableItems
                    elementName="servicio / producto"
                    empty='Aún no creas un servicio'
                    emptyAction={() => {
                        setaddService(true)
                        seteditService(null)

                    }}
                    columns={[
                        {
                            title: <TableTitle title="Nombre" />,
                            dataIndex: 'name',
                            key: generateCode(5),
                            fixed: 'left',
                            width: '20%',
                            sorter: (a, b) => ((a?.name).localeCompare(b?.name)),
                            render: (text, record) => {

                                return <div className='d-flex flex-column'>
                                    <Typography.Text className="primary" editable={canCreateServices ? {

                                        onChange: (e) => {
                                            if (!e) return;
                                            updateDoc(doc(fs, `services/${record.id}`), {
                                                name: e
                                            })
                                        },
                                    } : false} >{text}</Typography.Text>
                                    <Typography.Text className="secondary" style={{ fontSize: '10px' }}>{record.id}</Typography.Text>
                                </div>
                            }
                        },
                        {
                            title: <TableTitle title="Tipo de cobro" />,
                            dataIndex: 'paymentType',
                            key: generateCode(5),

                            render: (text, record) => {
                                const isHours = record?.paymentType?.value === 'hour';
                                return <Typography.Text className="" type="secondary" >{isHours ? 'Hora' : 'Tarifa fija'}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Cantidad" />,
                            dataIndex: 'quantity',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.rfc).localeCompare(b?.rfc)),
                            render: (text, record) => {
                                const isHours = record?.paymentType?.value === 'hour';
                                return <Typography.Text className="" type="secondary" ellipsis editable={canCreateServices ? {
                                    onChange: (e) => {
                                        const ns = ForceDecimalNumber(e)
                                        //check if ns is number
                                        if (!isNaN(ns)) {
                                            updateDoc(doc(fs, `services/${record.id}`), isHours ? { hours: ns } : { quantity: ns })

                                        }
                                    }
                                } : false}>{isHours ? '' : 'x'}{isHours ? record.hours : record.quantity}{isHours ? ' hrs' : ' '}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Precio unitario" />,
                            dataIndex: 'total',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.rfc).localeCompare(b?.rfc)),
                            render: (text, record) => {
                                return <Typography.Text className="" type="secondary" editable={canCreateServices ? {
                                    onChange: (e) => {
                                        /* A function that takes a string and returns a number. */
                                        const ns = ForceDecimalNumber(e)

                                        /* Checking if the value is a number, if it is, it updates the
                                        document with the new value. */
                                        if (!isNaN(ns)) {
                                            updateDoc(doc(fs, `services/${record.id}`), {
                                                total: ns
                                            })
                                        }
                                    }
                                } : false} copyable={null} ellipsis>{returnCurrencyValue(record.total)}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="Total" />,
                            dataIndex: 'amount',
                            key: generateCode(5),
                            sorter: (a, b) => ((a?.rfc).localeCompare(b?.rfc)),
                            render: (text, record) => {
                                const isHours = record?.paymentType?.value === 'hour';
                                const amount = isHours ? record.hours * record.total : record.quantity * record.total;
                                return <Typography.Text className="" type="secondary" copyable={null} ellipsis>{returnCurrencyValue(amount)}</Typography.Text>
                            }
                        },
                        {
                            title: <TableTitle title="" />,
                            dataIndex: 'actions',

                            render: (text, record) => {
                                return <Space>
                                    {canCreateServices && <EditOutlined onClick={() => {
                                        seteditService(record)
                                        setaddService(true)

                                    }} />}
                                    {canCreateServices && <CopyOutlined className="clickable" onClick={() => DuplicateService(record)} />}
                                    {canCreateServices && <Popconfirm onConfirm={() => {
                                        deleteDoc(doc(fs, `services/${record.id}`))
                                    }} title="Eliminar servicio?" okText="Eliminar" okButtonProps={{ danger: true }} cancelText="Cancelar">
                                        <DeleteOutlined className="clickable" />
                                    </Popconfirm>}

                                </Space>

                            }
                        }


                    ]}
                    // data={servicesData ?? []} 
                    // loading={servicesDBStatus === 'loading'} 
                    query={servicesRef}
                    userDocument={userDocument} />
            </Col>

        </Row >
    );




}

export default ServicesPage;
