
import { CloseOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Typography } from 'antd';
// import { FaWhatsapp, FaLinkedin, FaTwitter, FaFacebookSquare } from "react-icons/fa";

import React from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    LinkedinIcon,
    TwitterIcon,
} from 'react-share';
// import { FaWhatsapp, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";

function SavingsGraphModal({ open, close, userDocument, imageUrl }) {

    return (
        <Modal width={window.innerWidth <= 800 ? '100%' : "40%"} style={{ top: 20 }} title={null} footer={null} visible={open} onCancel={() => close()} closable={false}>
            <Row justify="space-between">
                <Typography.Title level={4}>Conoce todo lo que has ahorrado en <span className="russo primary" style={{ fontWeight: 100 }}>gigstack pro</span></Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close()} />
            </Row>
            <Typography.Text type='secondary'>¡Gracias por usar gigstack pro! A continuación encontrarás un gráfico que muestra todo lo que has ahorrado en gigstack pro.</Typography.Text>
            <div style={{ height: '100%', width: '100%', backgroundColor: 'white', padding: '20px 0px' }}>
                <Row justify="center" style={{ height: '100%', backgroundColor: '' }}>

                    <Col xs={24} style={{ height: '100%', backgroundColor: '' }}>
                        <div className="d-flex flex-column" style={{ display: "flex", height: '100%', width: '100%', alignItems: 'center', justifyContent: "start", backgroundColor: '', padding: '0px' }}>
                            <img width='90%' src={imageUrl} alt='' />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className='d-flex flex-column' style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Typography.Text>¡Compártelo en tus redes sociales!</Typography.Text>
                <div className='d-flex flex-row'>
                    <div style={{ margin: '5px' }}>
                        <LinkedinShareButton url={imageUrl}>
                            <LinkedinIcon size={24} style={{ borderRadius: '50%' }} />
                            {/* <FaLinkedin className='primary' /> */}
                        </LinkedinShareButton>
                    </div>
                    <div style={{ margin: '5px' }}>
                        <WhatsappShareButton url={imageUrl}>
                            <WhatsappIcon size={24} style={{ borderRadius: '50%' }} />
                            {/* <FaWhatsapp className='primary' /> */}
                        </WhatsappShareButton>
                    </div>
                    <div style={{ margin: '5px' }}>
                        <TwitterShareButton url={imageUrl}>
                            <TwitterIcon size={24} style={{ borderRadius: '50%' }} />
                            {/* <FaTwitter className='primary' /> */}
                        </TwitterShareButton>
                    </div>
                    <div style={{ margin: '5px' }}>
                        <FacebookShareButton url={imageUrl}>
                            <FacebookIcon size={24} style={{ borderRadius: '50%' }} />
                            {/* <FaFacebook className='primary' /> */}
                        </FacebookShareButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default SavingsGraphModal;