import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Typography } from 'antd';
import { collection, getFirestore, query, where } from 'firebase/firestore';
import React, { useContext } from 'react'
import { useFirestoreCollectionData } from 'reactfire';
import TeamContext from '../../context/teamcontext/TeamContext';
import InvoicesRelatedTo from '../invoices/InvoicesRelatedTo';
import PaymentsRelatedTo from '../payments/PaymentsRelatedTo';
import ReceiptsRelatedTo from '../receipts/ReceiptsRelatedTo';
import InvoicesGlobalRelatedTo from '../invoices/InvoicesGlobalRelatedTo';


function RelationsDrawer({ render, relation, open, close, userDocument }) {
    const { team } = useContext(TeamContext)
    const { title, db, subdb, elementID, relatedWith } = relation
    const q = query(collection(getFirestore(), subdb), where('team', '==', team?.id ?? ''), where(db, 'array-contains-any', [elementID]))
    const { status, data: relationData } = useFirestoreCollectionData(q, {
        idField: 'id',
    })






    /**
     * It returns a component based on the value of the `render` variable
     * @returns const ElementToRender = () => {
     *         switch (render)
     */
    const ElementToRender = () => {
        switch (render) {
            case 'payments':
                return <PaymentsRelatedTo relatedTo={relatedWith} payments={relationData} userDocument={userDocument} />
            case 'invoices':
                return <InvoicesRelatedTo relatedTo={relatedWith} invoices={relationData} userDocument={userDocument} />
            case 'receipts':
                return <ReceiptsRelatedTo relatedTo={relatedWith} receipts={relationData} />
            case 'invoicesGlobal':
                return <InvoicesGlobalRelatedTo relatedTo={relatedWith} item={relation?.item} elementsRelated={relation?.related ?? []} invoicesGlobal={relationData} userDocument={userDocument} />

            default:
                return <></>
        }
    }
    return (
        <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => close()} header={null} footer={null} >
            <div style={{ maxHeight: '100%', width: '100%' }} className="d-flex flex-column">
                <Row>
                    <Col xs={24}>
                        <Row justify="space-between" >
                            <Typography.Title level={4}>{title}</Typography.Title>
                            <CloseOutlined className="clickable" onClick={() => close()} />
                        </Row>
                    </Col>
                    {(status === 'loading') ? <LoadingOutlined /> : <ElementToRender />}
                </Row>
            </div>
        </Drawer>
    );
}

export default RelationsDrawer;