import {
  CloseOutlined,
  // InfoCircleOutlined,
  // InfoCircleOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  message,
  Row,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useAnalytics } from "reactfire";
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests";
import {
  FeesAmounts,
  generateCode,
  getItemsAmounts,
} from "../../app/functions/helpers";
import BillingAccountContext from "../../context/billingaccountcontext/BillingAccountContext";
import TeamContext from "../../context/teamcontext/TeamContext";
import CurrencyChip from "../cards/CurrencyChip";
import SelectClientRowCard from "../clients/SelectClientRowCard";
import ServicesViewTable from "../services/ServicesViewTable";
import ElementCreated from "../states/ElementCreated";
import LoadingAndResponse from "../states/LoadingAndResponse";
import AvailPaymentOptions from "./AvailPaymentOptions";
import PaymentLimitComponent from "./PaymentLimitComponent";

function NewPaymentRequest({ close, userDocument, data = {}, open }) {
  const { team, getCurrentTeam } = useContext(TeamContext);
  const { billingAccount } = useContext(BillingAccountContext);

  const auth = getAuth();

  const [loading, setloading] = useState(false);
  const [step, setstep] = useState(0);
  const [state, setstate] = useState({
    currency: team?.currency ?? "mxn",
    custom_method_types: [],
    ...data,
    automations: null,
    clabe: null,
  });

  const [paymentOk, setpaymentOk] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");

  const [form] = Form.useForm();

  const { items, currency } = state;

  const analytics = useAnalytics();

  //FUNCTIONS
  /**
   * It returns an object with two properties: `disabled` and `message`
   * @returns {
   *         disabled: false,
   *         message: 'Crear link de pago'
   *     }
   */
  const HandleButtonState = () => {
    if (step === 0) {
      if ((items ?? []).length <= 0) {
        return { disabled: true, message: "Añade al menos un servicio" };
      } else return { disabled: false, message: "Siguiente" };
    }
    if (step === 1) {
      if (state.custom_method_types?.length <= 0) {
        return { disabled: true, message: "Selecciona al menos un método" };
      } else return { disabled: false, message: "Crear link de pago" };
    }

    return { disabled: false, message: "Crear link de pago" };
  };
  /**
   * It creates a payment object, sends it to the server, and then returns the response
   * @returns
   */
  const RequestPayment = async () => {

    const code = generateCode(15, "payment");
    const payment = {
      team: team?.id,
      billingAccount: billingAccount?.id,
      livemode: true,
      sms: state.sms || false,
      charges: null,
      review: null,
      automations: state.automations,
      amount_capturable: 0,
      customer: state.client ?? null,
      created: moment().valueOf(),
      timestamp: moment().valueOf(),
      shipping: null,
      amount_received: 0,
      capture_method: "automatic",
      transfer_group: null,
      amount: getItemsAmounts(state.items, false).total * 100,
      discount: Number(state.feeAdded ?? 0),
      status: "pending",
      next_action: null,
      viewed: 0,
      receipt_email: state.client?.email ?? null,
      last_payment_error: null,
      confirmation_method: "",
      statement_descriptor: "",
      owner: userDocument.uid,
      payment_method: null,
      application: null,
      automatic_payment_methods: null,
      description: "",
      lastViewed: null,
      on_behalf_of: null,
      currency: state?.currency ?? "mxn",
      items: state.items,
      internalItems: state.items,
      clientID: state.client?.id ?? null,
      clientId: state.client?.id ?? null,
      metadata: { items: 1, owner: userDocument.uid, internalID: code },
      internalStatus: "pending",
      object: "payment",
      cancellation_reason: null,
      source: null,
      token: null,
      id: code,
      payment_method_types: [],
      clabe: state.clabe ? state.client.metadata.clabe : null,
      //TODO: ELIMINAR CUSTOMER_BALANCE
      custom_method_types: state.custom_method_types,
      // [...state.custom_method_types, {
      //   id: 'customer_balance',
      //   name: 'SPEI'
      // }],
      client: state.client ?? null,
      application_fee_amount: null,
      canceled_at: null,
      fid: code,
      isManual: false,
      processor: "",
      proposals: data?.proposal ? [data.proposal?.id] : null,
      invoices: data?.invoice ? [data?.invoice?.id] : null,
      v: 2,
      paymentLimit: state.paymentLimit ?? null,
      payment_intent: null,
      limitDaysToPay: state?.limitDaysToPay ?? null,
    };
    if (state.addTransactionFee) {
      payment.addTransactionFee = true
      payment.transactionFeeInfo = FeesAmounts(userDocument, team);
    }
    // if (state.addTransactionFee) {
    //   payment.addTransactionFee = true
    //   var feeAdded = 0;
    //   var it = payment.items
    //   items?.forEach((item, index) => {
    //     const sub = parseFloat(item.total); //86.21
    //     const { fee, feeA } = FeesAmounts(userDocument, team);
    //     const feeTotal = ((sub + feeA) / (1 - (fee / 100)) - sub) * 1.16;  //7.34
    //     const total = Number(parseFloat(sub + feeTotal).toFixed(2));
    //     const remaining = total - sub;
    //     feeAdded += remaining;

    //     it[index].total = total;
    //     it[index].feeAdded = remaining;
    //     it[index].feeInfo = FeesAmounts(userDocument, team);
    //     it[index].master = sub;
    //   });
    //   payment.transactionFeeInfo = FeesAmounts(userDocument, team);
    //   payment.feeAdded = feeAdded
    //   payment.amount = getItemsAmounts(state.items, false).total * 100
    //   payment.discount = Number(feeAdded ?? 0)
    // }

    // return console.log(payment)

    setloading(true);
    if (state.custom_method_types.findIndex((v) => v.id === "bank") > -1) {
      payment.bank = {
        ...userDocument.bank,
      };
    }

    setstate({ ...state, step: state.step + 1 });
    setstep(step + 1);

    try {
      const paymentResponse = await SignedInternalAPIRequest(
        {
          payment,
          billingAccount: billingAccount.id,
          team: team.id,
          type: "payment_request_2",
        },
        "paymentsHandler",
        auth.currentUser
      );
      if (paymentResponse.error) {
        setpaymentOk(false);
        setloading(false);
        message.error(
          paymentResponse?.message ?? "Ocurrio un error en el servidor"
        );
        return seterrorMessage(
          paymentResponse?.message ?? "Ocurrio un error en el servidor"
        );
      }
      // RemoveFeesFromTotal();
      setloading(false);
      setpaymentOk(paymentResponse);
      setstep(step + 2);
      logEvent(analytics, "paymentCreated", {});
    } catch (error) {
      setpaymentOk(false);
      setloading(false);
      message.error(error?.message ?? "Ocurrio un error en el servidor");
      seterrorMessage(error?.message ?? "Ocurrio un error en el servidor");
    }
  };


  useEffect(() => {
    if (state?.client?.defaults?.limitDaysToPay) {
      setstate({ ...state, limitDaysToPay: state?.client?.defaults?.limitDaysToPay })
    }

    if (!state?.client?.defaults?.limitDaysToPay && team?.defaults?.limitDaysToPay) {
      setstate({ ...state, limitDaysToPay: typeof team?.defaults?.limitDaysToPay === 'object' ? team?.defaults?.limitDaysToPay?.value : team?.defaults?.limitDaysToPay })
    }
    // eslint-disable-next-line
  }, [state.client])

  /**
   * It takes the total of each item, adds the fee to it, and then adds the fee to the total of the item
   * @returns the total amount of the fee added to the items.
   */

  // const AddFeesToTotal = () => {
  //   if (!items) {
  //     return;
  //   }
  //   var feeAdded = 0;

  //   items?.forEach((item, index) => {
  //     const sub = parseFloat(item.total); //86.21
  //     const { fee, feeA } = FeesAmounts(userDocument, team);
  //     const feeTotal = ((sub + feeA) / (1 - (fee / 100)) - sub) * 1.16;  //7.34
  //     const total = Number(parseFloat(sub + feeTotal).toFixed(2));
  //     const remaining = total - sub;
  //     feeAdded += remaining;

  //     items[index].total = total;
  //     items[index].feeAdded = remaining;
  //     items[index].feeInfo = FeesAmounts(userDocument, team);
  //     items[index].master = sub;
  //   });

  //   setstate({ ...state, feeAdded, items: [...items] });
  // };
  // /**
  //  * It removes the fee from the total of each item in the state
  //  */
  // const RemoveFeesFromTotal = () => {
  //   items?.forEach((item, index) => {
  //     items[index].total = item.total - (item.feeAdded ?? 0);
  //     items[index].feeAdded = null;
  //     items[index].feeInfo = null;
  //   });

  //   setstate({ ...state, feeAdded: null, items: [...items] });
  //   // message.success("Hemos actualizado los servicios");
  // };

  //CLIENT AND ITEMS
  /**
   * It takes in an object with a type, action, and ref, and then either adds or removes the object from
   * the automations array in state
   */
  const manageAutomation = async ({ type, action, ref }) => {
    const index = (state.automations || []).findIndex((e) => e.type === type);

    var tempAutom = state.automations || [];
    if (index > -1) {
      tempAutom.splice(index, 1);
    } else {
      tempAutom.push({ type, action, ref, from: "payments" });
    }

    setstate({ ...state, automations: tempAutom });
  };
  /**
   * It's a function that returns a div with a text and a switch. The text is a question that asks the
   * user if he wants to add the fees to the total amount of the order. The switch is a toggle that adds
   * or removes the fees from the total amount of the order
   * @returns A div with a row and a switch.
   */
  const AddFees = () => {
    return (
      <div
        className="d-flex flex-column"
        style={{
          backgroundColor: "#F5F5F5",
          padding: "15px",
          borderRadius: "10px",
        }}
      >
        {/* {
          FeesAmounts(userDocument, team).type && (
            <Typography.Text className="cLabel">
              ¿Añadir la comisión de transacciones de tu orden?{" "}
              <Tooltip title="Algunas pasarelas de pago tienen fee's por transacción, esto es externo a gigstack.pro">
                <QuestionCircleOutlined />
              </Tooltip>
            </Typography.Text>
          )}
        {
          FeesAmounts(userDocument, team).type && (
            <Row
              style={{ marginTop: "10px", marginBottom: "15px" }}
              align="middle"
            >
              <Switch
                checked={state.addTransactionFee}
                onChange={(v) => {
                  setstate({ ...state, addTransactionFee: v });
                  // if (state.feeAdded) {
                  //   RemoveFeesFromTotal();
                  // } else {
                  //   AddFeesToTotal();
                  // }
                }}
              />
              <Row
                style={{
                  flex: 1,
                  borderRadius: "10px",
                  border: "0.5px solid #DDDDDD",
                  padding: "5px 10px",
                  marginLeft: "10px",
                }}
                className=""
                align="middle"
              >
                <InfoCircleOutlined style={{ color: "#757575" }} />
                <Typography.Text
                  style={{ marginLeft: "5px", color: "#757575", flex: 1 }}
                >
                  {state.feeAdded
                    ? `Hemos añadido un total de  ${getItemsAmounts(items).feeAddedString
                    } a tu solicitud de pago.`
                    : `Añade el costo de la pasarela de mayor costo (${FeesAmounts(userDocument, team).type
                    }) a tus servicios `}
                </Typography.Text>
              </Row>
            </Row>
          )} */}
        {state?.client?.metadata?.clabe && (<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography.Text className="cLabel" style={{ marginBottom: '10px' }}>
            Deseas añadir la Clabe interbancaria numero {state?.client?.metadata?.clabe} para que el pago se reciba en esa cuenta?{" "}
            <Tooltip title=""></Tooltip>
          </Typography.Text>
          <Switch
            style={{ marginBottom: '10px' }}
            checked={state.clabe}
            onChange={(v) => {
              setstate({ ...state, clabe: v })
            }}
          />
          {state.clabe && (
            <Typography.Text
              type="secondary"
              className="clickable itemHoverPrimary"
              style={{ fontSize: "12px" }}
            >
              *El pago se recibirá en la cuenta {state?.client?.metadata?.clabe}
            </Typography.Text>
          )}
        </div>)}


      </div>
    );
  };
  /**
   * It creates a checkbox that allows the user to create an invoice when the payment is complete.
   * @returns A div with a Typography.Text and a Checkbox.
   */
  const AutomationAdd = () => {
    return (
      <div
        className="d-flex flex-column"
        style={{
          backgroundColor: "#F5F5F5",
          padding: "15px",
          borderRadius: "10px",
          marginTop: "15px",
        }}
      >
        <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
          Automatizaciones disponibles{" "}
          <Tooltip title="Nuestra meta es que tu te enfoques en crecer mientras gigstack.pro se enfoca en todo lo demás.">
            <QuestionCircleOutlined />
          </Tooltip>
        </Typography.Text>
        <Checkbox
          checked={
            (state.automations || []).findIndex((e) => e.type === "invoice") >
            -1
          }
          disabled={!getItemsAmounts(state.items, true).canSend}
          style={{ marginTop: "10px" }}
          onChange={(v) =>
            manageAutomation({
              type: "invoice",
              action: "create",
              ref: "payments",
            })
          }
        >
          <div className="d-flex flex-column">
            <Typography.Text
              type="secondary"
              className="strong"
              style={{ fontSize: "13px" }}
            >
              Crear una factura cuando el pago esté completo
            </Typography.Text>
            {!getItemsAmounts(state.items, true).canSend && (
              <Typography.Text
                onClick={() => setstep(1)}
                type="secondary "
                className="clickable itemHoverPrimary"
                style={{ fontSize: "12px" }}
              >
                *Revisa que los servicios/productos tengan los elementos para
                emitir una factura (clave de producto e impuestos).{" "}
              </Typography.Text>
            )}
            {!state.client && (
              <Typography.Text
                type="secondary"
                className="clickable itemHoverPrimary"
                style={{ fontSize: "12px" }}
              >
                Tu solicitud no tiene un cliente, generaremos un recibo de venta
                que se convertirá en una factura al público en general el día
                que se complete a las 11:59 pm.
              </Typography.Text>
            )}
          </div>
        </Checkbox>
      </div>
    );
  };

  /**
   * It renders a form to create a new invoice
   * @returns A function that returns a div with a title, a select client row card, a checkbox, a
   * services view table, an add fees, an automation add, and a row with a list of items.
   */
  const ClientItems = () => {
    return (
      <div style={{ padding: "10px 0px" }}>
        <Typography.Title level={5} style={{ marginTop: "0px" }}>
          1. Cliente
        </Typography.Title>
        <SelectClientRowCard
          onSelect={(c) => {
            setstate({ ...state, client: c });

          }}
          userDocument={userDocument}
          incomingClient={state.client}
        />
        {state.client?.phone && userDocument.twilio && (
          <div style={{ marginTop: "10px", marginLeft: "5px" }}>
            <Checkbox
              defaultChecked={state.sms}
              onChange={(v) => setstate({ ...state, sms: v.target.checked })}
            >
              <Typography.Text type="secondary">Enviar por SMS</Typography.Text>
            </Checkbox>
          </div>
        )}
        <Typography.Title level={5} style={{ marginTop: "30px" }}>
          2. Productos y servicios
        </Typography.Title>
        <Suspense fallback={<></>}>
          <ServicesViewTable
            onSelect={(v) => { }}
            onItemsChange={(it) => {
              setstate({ ...state, items: it });
            }}
            selectedItems={items}
            userDocument={userDocument}
            currency={currency}
          />
        </Suspense>
        <Row justify="end">
          <ul style={{ listStyle: "none" }}>
            <li>
              <Typography.Text type="secondary">
                Subtotal: {getItemsAmounts(items).subtotalString}
              </Typography.Text>
            </li>
            <li>
              <Typography.Text type="secondary">
                Retenciones: {getItemsAmounts(items).retentionsString}
              </Typography.Text>
            </li>
            <li>
              <Typography.Text type="secondary">
                Traslados: {getItemsAmounts(items).taxesString}
              </Typography.Text>
            </li>
            <li>
              <Typography.Text className="strong primary">
                Total: {getItemsAmounts(items, false).totalString}{" "}
                {state.currency}
              </Typography.Text>
            </li>
          </ul>
        </Row>
        <Typography.Title level={5} style={{ marginTop: "30px" }} >
          3. Configura
        </Typography.Title>
        {/* //TODO: aca quite el allowPayments */}

        <PaymentLimitComponent
          updateState={(v) => setstate({ ...state, ...v })}
          userDocument={userDocument}
          client={state?.client}
        />

        <AddFees />
        <AutomationAdd />
      </div>
    );
  };

  /**
   * It renders a component called AvailPaymentOptions, which is a component that renders a list of
   * payment methods
   * @returns A div with a title and a component called AvailPaymentOptions.
   */
  const PaymentMethods = () => {
    return (
      <div style={{ width: "97%", padding: "10px 0px" }}>
        <Typography.Title level={5} style={{ marginTop: "0px" }}>
          4. Métodos disponibles
        </Typography.Title>
        <AvailPaymentOptions
          state={state}
          setstate={(v) => setstate(v)}
          userDocument={userDocument}
        />
        <div style={{ marginTop: "20px" }}></div>
      </div>
    );
  };


  const ToRender = () => {
    switch (step) {
      case 0:
        return <ClientItems />;
      case 1:
        return (
          <Suspense fallback={<></>}>
            <PaymentMethods />
          </Suspense>
        );
      case 2:
        return (
          <Suspense fallback={<></>}>
            <LoadingAndResponse
              loading={loading}
              isError={!paymentOk && errorMessage}
              errorMessage={errorMessage}
              backAction={() => setstep(step - 1)}
            />
          </Suspense>
        );

      case 3:
        return (
          <ElementCreated
            idRef="payments"
            actionText="Realizar pago"
            userDocument={userDocument}
            message={"La solicitud del pago se creo con éxito"}
            actions={{
              email: "shortURL",
              copy: "shortURL",
              whatsapp: "shortURL",
            }}
            close={() => close(true)}
            element={paymentOk}
            shareMessage={`Ya puedes realizar el pago por ${getItemsAmounts(items).totalString
              } ${state.currency} desde el siguiente enlace:`}
            data={state}
            subject="Realiza el pago correspondiente."
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <Drawer visible={open} closable={false} width={window.innerWidth < 780 ? '100%' : '55%'} drawerStyle={{ borderRadius: '20px' }} contentWrapperStyle={{ padding: '10px', backgroundColor: 'transparent', boxShadow: 'none' }} onClose={() => {
      // RemoveFeesFromTotal();
      close()
      //RESET ITEMS REMOVING FEE ADDED

      getCurrentTeam(team?.id)
    }} header={null} footer={null}>

      <Row style={{ width: "100%" }}>
        <Col xs={24}>
          <Form
            form={form}
            initialValues={{}}
            layout="vertical"
            onFinish={async () => {
              if (step === 0) {
                setstep(1);
              } else if (step === 1) {
                RequestPayment();
              }
            }}
          >
            <div className="d-flex flex-column" style={{ padding: "5px" }}>
              <div className="d-flex flex-column">
                <Row justify="space-between">
                  <Typography.Title level={4}>
                    {"Nueva solicitud de pago"}
                  </Typography.Title>
                  <CloseOutlined onClick={() => {
                    close()
                    // RemoveFeesFromTotal();
                  }} />
                </Row>
              </div>
              {step < 2 && (
                <Row style={{ marginTop: "10px" }} justify="end">
                  <CurrencyChip
                    onUpdate={(v) => {
                      setstate({ ...state, currency: v.currency });
                    }}
                    state={state}
                  />
                </Row>
              )}
              <div
                className="d-flex flex-column"
                style={{
                  flex: 1,
                  maxHeight: "78vh",
                  overflow: "auto",
                  padding: "5px",
                }}
              >
                <Suspense fallback={<LoadingOutlined />}>
                  <ToRender />
                </Suspense>
              </div>
              {step < 2 && (
                <Row style={{ marginBottom: "10px" }} gutter={{ xs: 1 }}>
                  {step > 0 && (
                    <Button
                      style={{ flex: 1, marginRight: "5px" }}
                      onClick={() => setstep(step - 1)}
                    >
                      Atrás
                    </Button>
                  )}
                  <Button
                    htmlType="submit"
                    disabled={HandleButtonState().disabled}
                    loading={loading}
                    type="primary"
                    style={{ flex: 1 }}
                  >
                    {HandleButtonState().message}
                  </Button>
                </Row>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Drawer>

  );
}

export default NewPaymentRequest;
