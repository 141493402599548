import React, { useReducer } from 'react';
import { getDoc, getFirestore, doc } from 'firebase/firestore';
import {
    GET_USER_DATA
} from '../../types';

import UserContext from './UserContext';
import UserReducer from './UserReducer';

const UserState = props => {

    const fs = getFirestore()

    const initialState = {
        user: null
    }

    const [state, dispatch] = useReducer(UserReducer, initialState);

    /**
     * It gets the user data from the database and dispatches it to the reducer
     * @param id 
     */
    const getUserData = async (id) => {
        if (!id) return
        try {
            await getDoc(doc(fs, "users", id)).then((doc) => {
                dispatch({
                    type: GET_USER_DATA,
                    payload: doc.data()
                })
            });
        } catch (error) {
        }
    }

    const setUserData = (user) => {
        try {
            dispatch({
                type: GET_USER_DATA,
                payload: user
            })
        } catch (error) {
        }
    }

    return (
        <UserContext.Provider
            value={{
                user: state.user,
                getUserData,
                setUserData
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState;