import React, { useState, useContext, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Modal, Row, Typography, Col, Form, Button, message, Input, Switch, notification } from "antd";
import Select from 'react-select';
import moment from 'moment';
import { generalreactSelectStyles } from '../../app/functions/styles';
import ModalContext from "../../context/modalcontext/ModalContext";
import { generateCode } from "../../app/functions/helpers";
import SingleImageUpload from "../uploads/SingleImageUpload";
import { arrayUnion, doc, getFirestore, increment, setDoc, updateDoc } from "firebase/firestore";
import { SignedInternalAPIRequest } from "../../app/functions/APIRequests";
import { useAuth } from "reactfire";
import Lottie from "lottie-react";
import teamBAnimation from '../../assets/lotties/teamBuild.json';
// import UserContext from "../../context/usercontext/UserContext";
function CreateTeam() {

    const auth = useAuth();
    const fs = getFirestore();

    const [formRef] = Form.useForm();

    // TODO Review Element Hola, Cómo estás ?
    const { createTeamModalOpen, handleCloseCreateTeamModal } = useContext(ModalContext);
    const { modalUser: user, modalBillingAccount: billingAccount } = useContext(ModalContext)


    const initialValues = {
        alias: "",
        legalName: billingAccount?.legal_name ?? billingAccount?.legalName ?? "",
        rfc: billingAccount?.rfc ?? "",
        supportPhone: billingAccount?.supportPhone ?? "",
        zip: billingAccount?.address?.zip ?? "",
        currency: billingAccount?.currency?.value ?? { label: "MXN", value: "MXN" },
        primaryColor: "#8666FF",
        secondaryColor: "#9AEDB6",
    }

    const [loading, setLoading] = useState(false);
    const [brandLogo, setBrandLogo] = useState(null);
    const [useBillingAccountInfo, setUseBillingAccountInfo] = useState(true);
    const [step, setStep] = useState(1);

    const brandCode = generateCode(15, 'team')

    const handleCreateBrand = async (v) => {
        try {
            setLoading(true);
            setBrandLogo(null)
            const charge = await SignedInternalAPIRequest({ billingAccount: billingAccount?.id }, 'createNewTeamRequest', auth.currentUser, {}, 'POST')


            if (!charge?.success) {

                notification.error({
                    message: 'Error',
                    description: charge?.message ?? 'No se pudo crear la marca, intente más tarde'
                })
                setLoading(false);
                return;
            }

            const brandObj = {
                supportPhone: v.supportPhone ?? null,
                timestamp: moment().valueOf(),
                alias: v.alias ?? null,
                contactEmails: arrayUnion(user?.email),
                supportEmail: user?.email,
                logo: brandLogo,
                id: brandCode,
                address: billingAccount?.address ?? null,
                currency: typeof v.currency === 'string' ? v.currency : v?.currency?.value ?? 'MXN',
                legalName: v.legalName ?? null,
                owner: user?.uid,
                billingAccount: billingAccount?.id,
                setupComplete: false,
                integrationsSetup: ['stripeSetup', 'invoices', 'importStripeProducts', 'importStripePayments', 'bank', 'setupBrand', 'testInvoice', 'setDefaults', 'automaticInvoicing'],
                members: arrayUnion({
                    id: user?.uid,
                    isAdmin: true,
                    payments: "editor",
                    invoices: "editor",
                    services: "editor",
                    expenses: "editor",
                    email: user?.email,
                    rol: 'admin',
                }),
                social: {
                    website: null,
                    instagram: null,
                    linkedin: null,
                },
                membersIds: arrayUnion(user?.uid),
                brand: {
                    logo: brandLogo,
                    alias: v.alias,
                    primaryColor: v?.primaryColor ?? '#8666FF',
                    secondaryColor: v?.secondaryColor ?? '#9AEDB6',
                }
            }

            await setDoc(doc(fs, `teams/${brandCode}`), { ...brandObj });
            await updateDoc(doc(fs, `users/${user?.uid}`), { teams: arrayUnion(brandCode), lastTeamViewed: brandCode }, { merge: true });
            await updateDoc(doc(fs, `billingAccounts/${billingAccount?.id}`), { teams: arrayUnion(brandCode), pendingTeamConfigurations: increment(-1) }, { merge: true });
            message.success("Marca creada con éxito");


            handleCloseCreateTeamModal();
            setLoading(false);
        } catch (error) {
            message.error(error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createTeamModalOpen])



    const FormToCreate = () => {
        return <>
            <Row justify="space-between">
                <Typography.Title level={4}>Crea una nueva marca</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => handleCloseCreateTeamModal()} />
            </Row>
            <Form initialValues={initialValues} form={formRef} layout="vertical" onFinish={handleCreateBrand} >
                <Row style={{ width: '100%', marginTop: '0px' }} gutter={{ xs: 12, md: 12 }}>
                    <Col xs={24} style={{ marginBottom: '10px', marginTop: '10px' }}>
                        <Typography.Text className='cLabel' >Logo</Typography.Text>
                        <div style={{ marginTop: '5px' }}></div>
                        <SingleImageUpload imageUrl={brandLogo} withFileName={false} uploadTo={`/teams/${brandCode}/files/logo`} dbRef={`/teams/${brandCode}`} disableCode={true} saveOnDB={true} defaultOnDb={{ id: brandCode }} onSuccess={async (file) => {
                            try {
                                setBrandLogo(file.url)
                            } catch (error) {
                                message.error(error.message)
                            }
                        }} />
                        <Typography.Text type="secondary" style={{ marginTop: '5px', fontSize: '10px' }}>Imagen en jpg, jpeg o png de máximo 2MB</Typography.Text>
                    </Col>

                    <Col xs={24} >
                        <Form.Item label="¿Nombre de la marca?" name="alias" rules={[{ required: true, message: 'Ingresa el nombre de la marca' }]}>
                            <Input placeholder='Mi marca' />
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={12} >
                        <Form.Item label="Color primario" name="primaryColor" rules={[{ required: true, message: 'Ingresa el color principal' }]}>
                            <Input type="color" placeholder='' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}  >
                        <Form.Item label="Color secundario" name="secondaryColor" rules={[{ required: false, message: '' }]}>
                            <Input type="color" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24} lg={24} >
                        <Switch checked={useBillingAccountInfo} size="small" onChange={() => setUseBillingAccountInfo(!useBillingAccountInfo)} />
                        <Typography.Text type="primary" style={{ marginLeft: '10px', marginTop: '0px' }}>¿Usar la misma información legal de la cuenta de facturación?</Typography.Text>
                    </Col>

                    <Col xs={24} >
                        <Form.Item label="¿Nombre legal de la marca?" name="legalName" rules={[{ required: false, message: '' }]} style={{ marginTop: '12px' }}>
                            <Input placeholder='Empresa SA DE CV' disabled={useBillingAccountInfo} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="¿RFC de la marca?" name="rfc" rules={[{ required: false, message: '' }]}>
                            <Input placeholder='Empresa SA DE CV' disabled={useBillingAccountInfo} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="¿Teléfono de la marca?" name="supportPhone" rules={[{ required: false, message: '' }]}>
                            <Input placeholder='' disabled={useBillingAccountInfo} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                        <Form.Item label="Código postal" name="zip" rules={[{ required: true, message: 'Por favor añade tu código postal' }]}>
                            <Input placeholder='' disabled={useBillingAccountInfo} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label="Moneda predeterminada" name="currency" rules={[{ required: true, message: 'Por favor añade tu moneda de preferencia.' }]}>
                            <Select
                                styles={generalreactSelectStyles}
                                isDisabled={useBillingAccountInfo}
                                className="pro-select-input"
                                options={[
                                    { label: 'USD', value: 'usd' },
                                    { label: 'EUR', value: 'eur' },
                                    { label: 'MXN', value: 'mxn' },
                                    { label: 'BRL', value: 'brl' },
                                ]}
                                isLoading={false}
                                placeholder="Moneda de preferencia"

                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Button loading={loading} type="primary" htmlType="submit">Crear marca</Button>
                </Row>
            </Form>
        </>


    }

    const ChargeNotice = () => {
        return <Row>
            <Col xs={24}>
                <Row align="top" justify="space-between">
                    <Typography.Title style={{ marginBottom: '0', flex: 1 }} level={4} type="">Simplifica tu gestión creando varias marcas / equipos en una misma cuenta de facturación.</Typography.Title>
                    <CloseOutlined className="clickable" onClick={() => handleCloseCreateTeamModal()} />
                </Row>
            </Col>

            <Col xs={24} style={{ marginTop: '10px' }}>
                <Row>
                    <Typography.Text style={{ marginTop: '' }} type="secondary">Crear un nuevo equipo te permitirá mantener ordenadas las finanzas en un solo lugar, la creación del nuevo equipo tiene un costo único de configuración de US$5 <span style={{ fontSize: '10px' }}>+IVA</span>.</Typography.Text>
                </Row>
            </Col>

            <Col xs={24} style={{ margin: '80px 0px' }}>
                <Lottie animationData={teamBAnimation} style={{ height: '150px', position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)' }} loop={false} />
            </Col>

            <Col xs={24} style={{ marginTop: '15px' }}>
                <Row justify="center" >
                    <Button type="primary" onClick={() => setStep(2)}>Siguiente</Button>
                </Row>
            </Col>
        </Row>
    }

    const ToRender = () => {
        switch (step) {
            case 1:

                return <ChargeNotice />

            case 2:
                return <FormToCreate />

            default:
                break;
        }
    }

    return (
        <Modal visible={createTeamModalOpen} onCancel={() => handleCloseCreateTeamModal()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "50%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <ToRender />
            </div>
        </Modal>
    )
}

export default CreateTeam;