import { BuildOutlined, ClusterOutlined, CreditCardOutlined, ForkOutlined, HeartOutlined, LockOutlined, StarOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row, Tabs, Typography } from 'antd';
// import { getFirestore } from 'firebase/firestore';
import React, { useContext, useState } from 'react'
import BillingAccountContext from '../../context/billingaccountcontext/BillingAccountContext';
import TeamContext from '../../context/teamcontext/TeamContext';
import UserContext from '../../context/usercontext/UserContext';
import { useUserPermissions } from '../../customHooks/userPermissions';
import { webAppVersion } from '../../VersionVariables';
//import { useFirestoreDocData } from 'reactfire';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';
import APISettings from '../../widgets/settings/APISettings';
import BillingAccount from '../../widgets/settings/BillingAccount';
import BrandingSettings from '../../widgets/settings/BrandingSettings';
import IntegrationsSettings from '../../widgets/settings/IntegrationsSettings';
import MembershipSettings from '../../widgets/settings/MembershipSettings';
import PersonalSettings from '../../widgets/settings/PersonalSettings';
import SecuritySettings from '../../widgets/settings/SecuritySettings';
import TeamsSettings from '../../widgets/settings/TeamsSettings';
import CustomerPortal from '../../widgets/settings/CustomerPortal';


function SettingsPage({ userDocument, subtab }) {
    // const fs = getFirestore();
    //const userRef = doc(fs, 'users', incomingUserDocument.uid);
    // const { status: userDBSt, data: userDocument } = useFirestoreDocData(userRef, {
    //     id: 'id', // this field will be added to the object created from each document
    // });

    const [tab, settab] = useState(subtab ?? 'personal')

    const { team } = useContext(TeamContext);
    const { billingAccount } = useContext(BillingAccountContext);
    const { user } = useContext(UserContext)

    // if (userDBSt === 'loading') {
    //     return <LoadingOutlined />
    // }


    const { isTeamAdmin, isBillingAccountAdmin } = useUserPermissions({ user, team, billingAccount })



    return (
        <Row justify='center'>
            <Col xs={24}>
                <DefaultHelpPageHeader userDocument={userDocument} title="Ajustes" description={"Configura y prepara todos los detalles de tu cuenta."} />
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <Tabs onChange={(k) => { settab(k) }} defaultActiveKey={tab} type="card">
                    <Tabs.TabPane tab={<Row align="middle">
                        <UserOutlined /><Typography.Text> Personal</Typography.Text>
                    </Row>} key="personal">
                        <PersonalSettings userDocument={userDocument} />
                    </Tabs.TabPane>
                    {isTeamAdmin && <Tabs.TabPane tab={<Row align="middle">
                        <HeartOutlined /><Typography.Text>Marca</Typography.Text>
                    </Row>} key="branding">
                        <BrandingSettings userDocument={userDocument} />
                    </Tabs.TabPane>}
                    {isTeamAdmin && <Tabs.TabPane tab={<Row align="middle">
                        <BuildOutlined /><Typography.Text>Aplicaciones</Typography.Text>
                    </Row>} key="integrations">
                        <IntegrationsSettings userDocument={userDocument} />
                    </Tabs.TabPane>}
                    {isTeamAdmin && <Tabs.TabPane tab={<Row align="middle">
                        <ForkOutlined /><Typography.Text>API</Typography.Text>
                    </Row>} key="api">
                        <APISettings userDocument={userDocument} />
                    </Tabs.TabPane>}
                    {isBillingAccountAdmin && <Tabs.TabPane tab={<Row align="middle">
                        <StarOutlined /><Typography.Text>Suscripción</Typography.Text>
                    </Row>} key="membership">
                        <MembershipSettings userDocument={userDocument} />
                    </Tabs.TabPane>}
                    <Tabs.TabPane tab={<Row align="middle">
                        <LockOutlined /><Typography.Text>Seguridad</Typography.Text>
                    </Row>} key="security">
                        <SecuritySettings userDocument={userDocument} />
                    </Tabs.TabPane>
                    {isBillingAccountAdmin && <Tabs.TabPane tab={<Row align="middle">
                        <CreditCardOutlined /><Typography.Text>Cuenta de facturación</Typography.Text>
                    </Row>} key="billingAccount">
                        <BillingAccount />
                    </Tabs.TabPane>}
                    {isTeamAdmin && <Tabs.TabPane tab={<Row align="middle">
                        <TeamOutlined /><Typography.Text>Asientos</Typography.Text>
                    </Row>} key="teams">
                        <TeamsSettings />
                    </Tabs.TabPane>}
                    <Tabs.TabPane tab={<Row align="middle">
                        <ClusterOutlined /><Typography.Text> Portal del cliente</Typography.Text>
                    </Row>} key="customerPortal">
                        <CustomerPortal userDocument={userDocument} />
                    </Tabs.TabPane>
                </Tabs>
                <Row justify="center" style={{ marginTop: '10px' }}>
                    <div className='d-flex flex-column'>
                        <Typography.Text hidden type="secondary" style={{ fontSize: '11px' }}>Desarrollado con 💜 desde el 🌍</Typography.Text>
                        <Typography.Text type="secondary" style={{ fontSize: '11px' }}>v{webAppVersion}</Typography.Text>
                    </div>
                </Row>
            </Col>
        </Row>
    );
}

export default SettingsPage;