import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import Lottie from "lottie-react";
import loading from '../../assets/lotties/loading.json';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import UserContext from '../../context/usercontext/UserContext';



/**
 * It returns a full screen layout with a loading animation in the middle
 * @returns A loading animation
 */
function LoadingFullScreen() {
    return (
        <Layout>
            <Layout.Content className='layoutContent' style={{ height: '100vh', width: '100vw', position: 'relative' }}>
                <Lottie animationData={loading} style={{ height: '150px', position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)' }} loop={true} />
            </Layout.Content>
        </Layout>
    );
}

export const LoadingFullScreenFirstTime = ({ uid }) => {

    const { setUserData } = useContext(UserContext)

    useEffect(() => {
        const getUserRtData = () => {
            onSnapshot(doc(getFirestore(), 'users', uid), (snap) => {
                setUserData(snap.data())
            })
        }
        getUserRtData()

        // eslint-disable-next-line
    }, [])

    return (
        <Layout>
            <Layout.Content className='layoutContent' style={{ height: '100vh', width: '100vw', position: 'relative' }}>
                <Lottie animationData={loading} style={{ height: '150px', position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)' }} loop={true} />
            </Layout.Content>
        </Layout>
    );
}

export default LoadingFullScreen
