import { DollarOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Row, Tag, Typography } from 'antd';
import React from 'react'
import { Currencies } from '../../app/functions/datasets';

/**
 * It's a dropdown menu that allows the user to select a currency
 * @returns A dropdown menu with a list of currencies.
 */
function CurrencyChip({ onUpdate, state }) {
    const CurrencyChip = () => {
        const currencyMenu = (
            <Menu onClick={(v) => onUpdate({ ...state, currency: v.key })} items={
                Currencies.map((c) => {
                    return { key: c.value, label: c.label, icon: <DollarOutlined /> }
                })
            } />
        );
        return <Dropdown arrow={{ pointAtCenter: true }} overlay={currencyMenu
        }>
            <Tag className="clickable" color="#EFECFE">
                <Row align="middle" style={{ padding: '5px 5px' }}>
                    <DollarOutlined style={{ color: '#8666FF', marginBottom: '0px', marginRight: '5px' }} />
                    <Typography.Text style={{ margin: 0, padding: 0, color: '#8666FF', fontSize: '12px' }} className="strong">{state?.currency?.toUpperCase()}</Typography.Text>
                </Row>

            </Tag>
        </Dropdown>

    }
    return <CurrencyChip />
}

export default CurrencyChip;