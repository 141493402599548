import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row, Typography, Button } from 'antd';


function DeleteInvoiceGlobal({ open, close, onAccept }) {


    const Header = () => {
        return <>
            <Row justify="space-between">
                <Typography.Title level={4} style={{ flex: 1 }}>¿Estás seguro que deseas cancelar esta factura global?</Typography.Title>
                <CloseOutlined className="clickable" onClick={() => close()} />
            </Row>
        </>
    }

    const Body = () => {
        return (
            <Row style={{ margin: '10px 0px 0px 10px' }} className="d-flex flex-column">
                <Row style={{ marginBottom: '10px' }} >
                    <Typography.Text level={2} style={{ margin: '0px 0px 0px 10px' }}>
                        Algunas cuestiones que podría ocasionar cancelarla:
                    </Typography.Text>
                </Row>
                <Row style={{ margin: '10px 10px 10px 10px' }}>
                    <Typography.Text>
                        <ul style={{ color: 'black' }}>
                            <li><Typography.Text> Falta de cumplimiento fiscal: La cancelación puede ocasionar problemas de cumplimiento fiscal para la empresa, como la imposición de multas y sanciones.</Typography.Text></li>
                            <li><Typography.Text>Pérdida de crédito fiscal: La cancelación de una factura global puede afectar el crédito fiscal de la empresa, lo que puede impactar su capacidad para deducir impuestos y recibir devoluciones de impuestos.</Typography.Text></li>
                        </ul>
                        Por lo tanto, antes de cancelar una factura global, es importante considerar cuidadosamente las implicaciones fiscales y contables de la cancelación.
                    </Typography.Text>
                </Row>
                <Row justify='center' style={{ marginTop: '10px' }} >
                    <Button onClick={() => onAccept(open)} type="primary">Aceptar cancelación</Button>
                </Row>
            </Row>
        )

    }


    return (
        <Modal visible={open} onCancel={() => close()} closable={false} footer={null} width={window.innerWidth < 780 ? "90%" : "40%"} style={{ top: 20 }} >
            <div className="d-flex flex-column">
                <Header />
                <Body />
            </div >

        </Modal >
    );
}

export default DeleteInvoiceGlobal;