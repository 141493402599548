import {
    GET_USER_DATA
} from '../../types';

function UserReducer(state, action) {
    switch (action.type) {
        case GET_USER_DATA:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}

export default UserReducer;
