import { LockOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import { logEvent } from 'firebase/analytics';
import React from 'react'
import { useAnalytics } from 'reactfire';

/**
 * It's a modal that shows up when a user tries to access a feature that is not available in their
 * current plan
 * @returns A React component
 */
function BlockedModal({ title = "Mejora tu salud financiera haciendo upgrade", description = "Obtén todos los beneficios exclusivos de gigstack.pro mejorando tu plan" }) {
    const analytics = useAnalytics();
    return (
        <div style={{ backgroundColor: 'white', padding: '20px 40px', borderRadius: '20px', alignItems: 'center' }} className="d-flex flex-column">
            <LockOutlined className="primary" style={{ fontSize: '30px' }} />
            <Typography.Title style={{ padding: 0, margin: 0, marginTop: '25px' }} level={5}>{title}</Typography.Title>
            <Typography.Text style={{ marginTop: '5px' }}>{description}</Typography.Text>
            <Row justify="center" style={{ marginTop: '25px' }}>
                <Button onClick={() => {
                    try {
                        logEvent(analytics, 'memberships', {});
                    } catch (error) {

                    }
                    window.open('https://app.gigstack.pro/memberships')
                }} type="primary" style={{ alignSelf: 'center' }}>Mejorar mi plan</Button>
            </Row>
        </div>
    );
}

export default BlockedModal;