import {
    OPEN_INVITE_MODAL,
    CLOSE_INVITE_MODAL,
    OPEN_CREATE_TEAM_MODAL,
    CLOSE_CREATE_TEAM_MODAL,
    SET_MODAL_TEAM,
    SET_MODAL_BILLINGACCOUNT,
    SET_MODAL_USER,
    INVITE_TYPE,
    SET_MODAL_PROPS_DATA,
    OPEN_NEW_CLIENT_MODAL,
    CLOSE_NEW_CLIENT_MODAL,
    SET_MODAL_CLIENT_PROPS,
    OPEN_NEW_EXPENSE_MODAL,
    CLOSE_NEW_EXPENSE_MODAL,
    SET_MODAL_EXPENSE_PROPS,
    OPEN_NEW_SERVICE_MODAL,
    CLOSE_NEW_SERVICE_MODAL,
    SET_MODAL_SERVICE_PROPS,
    OPEN_NEW_PAYMENTREQUEST_MODAL,
    CLOSE_NEW_PAYMENTREQUEST_MODAL,
    SET_MODAL_PAYMENTREQUEST_PROPS,
    OPEN_NEW_INVOICE_MODAL,
    CLOSE_NEW_INVOICE_MODAL,
    SET_MODAL_INVOICE_PROPS,
    OPEN_NEW_RECEIPT_MODAL,
    CLOSE_NEW_RECEIPT_MODAL,
    SET_MODAL_RECEIPT_PROPS,
    SET_MODAL_RECURRINGPAYMENT_PROPS,
    OPEN_NEW_RECURRINGPAYMENT_MODAL,
    CLOSE_NEW_RECURRINGPAYMENT_MODAL,
    OPEN_STRIPE_AUTOMATIONS_MODAL,
    CLOSE_STRIPE_AUTOMATIONS_MODAL,
    DATA_TO_UPDATE,
    ON_ITEMS_CHANGE
} from '../../types';

function ModalReducer(state, action) {
    switch (action.type) {
        case OPEN_INVITE_MODAL:
            return {
                ...state,
                inviteModalOpen: true
            };
        case CLOSE_INVITE_MODAL:
            return {
                ...state,
                inviteModalOpen: false
            };
        case OPEN_CREATE_TEAM_MODAL:
            return {
                ...state,
                createTeamModalOpen: true
            };
        case CLOSE_CREATE_TEAM_MODAL:
            return {
                ...state,
                createTeamModalOpen: false
            };
        case SET_MODAL_TEAM:
            return {
                ...state,
                modalTeam: action.payload
            }
        case SET_MODAL_BILLINGACCOUNT:
            return {
                ...state,
                modalBillingAccount: action.payload
            }
        case SET_MODAL_USER:
            return {
                ...state,
                modalUser: action.payload
            }
        case INVITE_TYPE:
            return {
                ...state,
                inviteType: action.payload
            }
        case SET_MODAL_PROPS_DATA:
            return {
                ...state,
                modalPropsData: action.payload
            }
        case OPEN_NEW_CLIENT_MODAL:
            return {
                ...state,
                newClientModalOpen: true
            }
        case CLOSE_NEW_CLIENT_MODAL:
            return {
                ...state,
                newClientModalOpen: false
            }
        case SET_MODAL_CLIENT_PROPS:
            return {
                ...state,
                modalClientProps: action.payload
            }
        case OPEN_NEW_EXPENSE_MODAL:
            return {
                ...state,
                newExpenseModalOpen: true
            }
        case CLOSE_NEW_EXPENSE_MODAL:
            return {
                ...state,
                newExpenseModalOpen: false
            }
        case SET_MODAL_EXPENSE_PROPS:
            return {
                ...state,
                modalExpenseProps: action.payload
            }
        case OPEN_NEW_SERVICE_MODAL:
            return {
                ...state,
                newServiceModalOpen: true
            }
        case CLOSE_NEW_SERVICE_MODAL:
            return {
                ...state,
                newServiceModalOpen: false
            }
        case SET_MODAL_SERVICE_PROPS:
            return {
                ...state,
                modalServiceProps: { ...action.payload }
            }
        case OPEN_NEW_PAYMENTREQUEST_MODAL:
            return {
                ...state,
                newPaymentRequestModalOpen: true
            }
        case CLOSE_NEW_PAYMENTREQUEST_MODAL:
            return {
                ...state,
                newPaymentRequestModalOpen: false
            }
        case SET_MODAL_PAYMENTREQUEST_PROPS:
            return {
                ...state,
                modalPaymentRequestProps: action.payload
            }
        case OPEN_NEW_RECURRINGPAYMENT_MODAL:
            return {
                ...state,
                newRecurringPaymentModalOpen: true
            }
        case CLOSE_NEW_RECURRINGPAYMENT_MODAL:
            return {
                ...state,
                newRecurringPaymentModalOpen: false
            }
        case SET_MODAL_RECURRINGPAYMENT_PROPS:
            return {
                ...state,
                modalRecurringPaymentProps: action.payload
            }
        case OPEN_NEW_INVOICE_MODAL:
            return {
                ...state,
                newInvoiceModalOpen: true
            }
        case CLOSE_NEW_INVOICE_MODAL:
            return {
                ...state,
                newInvoiceModalOpen: false
            }
        case SET_MODAL_INVOICE_PROPS:
            return {
                ...state,
                modalInvoiceProps: action.payload
            }
        case OPEN_NEW_RECEIPT_MODAL:
            return {
                ...state,
                newReceiptModalOpen: true
            }
        case CLOSE_NEW_RECEIPT_MODAL:
            return {
                ...state,
                newReceiptModalOpen: false
            }
        case SET_MODAL_RECEIPT_PROPS:
            return {
                ...state,
                modalReceiptProps: action.payload
            }
        case OPEN_STRIPE_AUTOMATIONS_MODAL:
            return {
                ...state,
                stripeAutomationsOpen: true
            }
        case CLOSE_STRIPE_AUTOMATIONS_MODAL:
            return {
                ...state,
                stripeAutomationsOpen: false
            }
        case DATA_TO_UPDATE:
            return {
                ...state,
                dataToUpdate: action.payload
            }

        case ON_ITEMS_CHANGE:
            return {
                ...state,
                itemsToChange: action.payload
            }

        default:
            return state;
    }
}

export default ModalReducer;