import { useReducer } from 'react';

import {
    OPEN_INVITE_MODAL,
    CLOSE_INVITE_MODAL,
    OPEN_CREATE_TEAM_MODAL,
    CLOSE_CREATE_TEAM_MODAL,
    SET_MODAL_TEAM,
    SET_MODAL_BILLINGACCOUNT,
    SET_MODAL_USER,
    INVITE_TYPE,
    OPEN_NEW_CLIENT_MODAL,
    CLOSE_NEW_CLIENT_MODAL,
    SET_MODAL_CLIENT_PROPS,
    OPEN_NEW_EXPENSE_MODAL,
    CLOSE_NEW_EXPENSE_MODAL,
    SET_MODAL_EXPENSE_PROPS,
    OPEN_NEW_SERVICE_MODAL,
    CLOSE_NEW_SERVICE_MODAL,
    SET_MODAL_SERVICE_PROPS,
    OPEN_NEW_PAYMENTREQUEST_MODAL,
    CLOSE_NEW_PAYMENTREQUEST_MODAL,
    SET_MODAL_PAYMENTREQUEST_PROPS,
    OPEN_NEW_INVOICE_MODAL,
    CLOSE_NEW_INVOICE_MODAL,
    SET_MODAL_INVOICE_PROPS,
    OPEN_NEW_RECEIPT_MODAL,
    CLOSE_NEW_RECEIPT_MODAL,
    SET_MODAL_RECEIPT_PROPS,
    SET_MODAL_RECURRINGPAYMENT_PROPS,
    OPEN_NEW_RECURRINGPAYMENT_MODAL,
    CLOSE_NEW_RECURRINGPAYMENT_MODAL,
    OPEN_STRIPE_AUTOMATIONS_MODAL,
    CLOSE_STRIPE_AUTOMATIONS_MODAL,
    DATA_TO_UPDATE,
    ON_ITEMS_CHANGE
} from '../../types';

import CreateTeam from '../../widgets/teams/CreateTeamModal';
import InviteMember from '../../widgets/teams/InviteMember';

import ModalReducer from './ModalReducer';
import ModalContext from './ModalContext';
import { ClientsModalContext, ExpenseModalContext, InvoiceModalContext, PaymentRequestModalContext, ReceiptModalContext, RecurringPaymentModalContext, ServiceModalContext, StripeAutomationModalContext } from '../../widgets/drawers/CreateClientDrawer';

const ModalState = props => {
    const initialState = {
        inviteModalOpen: false,
        createTeamModalOpen: false,
        newClientModalOpen: false,
        newExpenseModalOpen: false,
        newServiceModalOpen: false,
        newPaymentRequestModalOpen: false,
        newRecurringPaymentModalOpen: false,
        newInvoiceModalOpen: false,
        newReceiptModalOpen: false,
        inviteType: null,
        modalTeam: null,
        modalBillingAccount: null,
        modalUser: null,
        modalClientProps: null,
        modalExpenseProps: null,
        modalServiceProps: null,
        modalPaymentRequestProps: null,
        modalRecurringPaymentProps: null,
        modalInvoiceProps: null,
        modalReceiptProps: null,
        modalPropsData: null,
        stripeAutomationsOpen: false,
        dataToUpdate: null,
        itemsToChange: null,
        modalData: null
    }

    const [state, dispatch] = useReducer(ModalReducer, initialState);

    const handleOpenInviteModal = () => {
        dispatch({
            type: OPEN_INVITE_MODAL,
        });
    }

    const handleCloseInviteModal = () => {
        dispatch({
            type: CLOSE_INVITE_MODAL
        });
    }

    const handleOpenCreateTeamModal = () => {
        dispatch({
            type: OPEN_CREATE_TEAM_MODAL
        });
    }

    const handleCloseCreateTeamModal = () => {
        dispatch({
            type: CLOSE_CREATE_TEAM_MODAL
        });
    }

    const handleSetModalTeam = (team) => {
        dispatch({
            type: SET_MODAL_TEAM,
            payload: team
        });
    }

    const handleSetModalBillingAccount = (billingAccount) => {
        dispatch({
            type: SET_MODAL_BILLINGACCOUNT,
            payload: billingAccount
        });
    }

    const handleSetModalUser = (user) => {
        dispatch({
            type: SET_MODAL_USER,
            payload: user
        });
    }

    const handleSetInviteType = (inviteType) => {
        dispatch({
            type: INVITE_TYPE,
            payload: inviteType
        });
    }

    const handleOpenNewClientModal = () => {
        dispatch({
            type: OPEN_NEW_CLIENT_MODAL
        });
    }

    const handleCloseNewClientModal = () => {
        dispatch({
            type: CLOSE_NEW_CLIENT_MODAL
        });
    }

    const setDataToUpdate = (data) => {
        dispatch({
            type: DATA_TO_UPDATE,
            payload: data
        });
    }

    const setOnItemsChange = (data) => {
        dispatch({
            type: ON_ITEMS_CHANGE,
            payload: data
        });
    }

    const handleOpenDrawerModal = (type) => {

        switch (type) {
            case 'client':
                dispatch({
                    type: OPEN_NEW_CLIENT_MODAL
                });
                break;
            case 'expense':
                dispatch({
                    type: OPEN_NEW_EXPENSE_MODAL
                });
                break;
            case 'service':
                dispatch({
                    type: OPEN_NEW_SERVICE_MODAL
                });
                break;
            case 'paymentrequest':
                dispatch({
                    type: OPEN_NEW_PAYMENTREQUEST_MODAL
                });
                break;
            case 'recurringpayment':
                dispatch({
                    type: OPEN_NEW_RECURRINGPAYMENT_MODAL
                });
                break;
            case 'invoice':
                dispatch({
                    type: OPEN_NEW_INVOICE_MODAL
                });
                break;
            case 'receipt':
                dispatch({
                    type: OPEN_NEW_RECEIPT_MODAL
                });
                break;

            case 'stripeAutomations':
                dispatch({
                    type: OPEN_STRIPE_AUTOMATIONS_MODAL
                })
                break;
            default:
                break;
        }
    }

    const handleCloseDrawerModal = (type) => {
        switch (type) {
            case 'client':
                dispatch({
                    type: CLOSE_NEW_CLIENT_MODAL
                });
                break;
            case 'expense':
                dispatch({
                    type: CLOSE_NEW_EXPENSE_MODAL
                });
                break;
            case 'service':
                dispatch({
                    type: CLOSE_NEW_SERVICE_MODAL
                });
                break;
            case 'paymentrequest':
                dispatch({
                    type: CLOSE_NEW_PAYMENTREQUEST_MODAL
                });
                break;
            case 'recurringpayment':
                dispatch({
                    type: CLOSE_NEW_RECURRINGPAYMENT_MODAL
                });
                break;
            case 'invoice':
                dispatch({
                    type: CLOSE_NEW_INVOICE_MODAL
                });
                break;
            case 'receipt':
                dispatch({
                    type: CLOSE_NEW_RECEIPT_MODAL
                });
                break;
            case 'stripeAutomations':
                dispatch({
                    type: CLOSE_STRIPE_AUTOMATIONS_MODAL
                })
                break;
            default:
                break;
        }
    }

    const handleSetModalPropsData = (type, props) => {
        switch (type) {
            case 'client':
                dispatch({
                    type: SET_MODAL_CLIENT_PROPS,
                    payload: props
                });
                break;
            case 'expense':
                dispatch({
                    type: SET_MODAL_EXPENSE_PROPS,
                    payload: props
                });
                break;
            case 'service':
                dispatch({
                    type: SET_MODAL_SERVICE_PROPS,
                    payload: props
                });
                break;
            case 'paymentrequest':
                dispatch({
                    type: SET_MODAL_PAYMENTREQUEST_PROPS,
                    payload: props
                });
                break;
            case 'recurringpayment':
                dispatch({
                    type: SET_MODAL_RECURRINGPAYMENT_PROPS,
                    payload: props
                });
                break;
            case 'invoice':
                dispatch({
                    type: SET_MODAL_INVOICE_PROPS,
                    payload: props
                });
                break;
            case 'receipt':
                dispatch({
                    type: SET_MODAL_RECEIPT_PROPS,
                    payload: props
                });
                break;
            default:
                break;
        }
    }

    return (
        <ModalContext.Provider
            value={{
                inviteModalOpen: state.inviteModalOpen,
                createTeamModalOpen: state.createTeamModalOpen,
                newClientModalOpen: state.newClientModalOpen,
                newExpenseModalOpen: state.newExpenseModalOpen,
                newServiceModalOpen: state.newServiceModalOpen,
                newPaymentRequestModalOpen: state.newPaymentRequestModalOpen,
                newRecurringPaymentModalOpen: state.newRecurringPaymentModalOpen,
                newInvoiceModalOpen: state.newInvoiceModalOpen,
                newReceiptModalOpen: state.newReceiptModalOpen,
                modalClientProps: state.modalClientProps,
                modalExpenseProps: state.modalExpenseProps,
                modalServiceProps: state.modalServiceProps,
                modalPaymentRequestProps: state.modalPaymentRequestProps,
                modalRecurringPaymentProps: state.modalRecurringPaymentProps,
                modalInvoiceProps: state.modalInvoiceProps,
                modalReceiptProps: state.modalReceiptProps,
                inviteType: state.inviteType,
                modalTeam: state.modalTeam,
                modalBillingAccount: state.modalBillingAccount,
                modalUser: state.modalUser,
                modalPropsData: state.modalPropsData,
                stripeAutomationsOpen: state.stripeAutomationsOpen,
                dataToUpdate: state.dataToUpdate,
                itemsToChange: state.itemsToChange,
                modalData: state.modalData,
                handleOpenInviteModal,
                handleCloseInviteModal,
                handleOpenCreateTeamModal,
                handleCloseCreateTeamModal,
                handleSetModalTeam,
                handleSetModalBillingAccount,
                handleSetModalUser,
                handleSetInviteType,
                handleSetModalPropsData,
                handleOpenNewClientModal,
                handleCloseNewClientModal,
                handleOpenDrawerModal,
                handleCloseDrawerModal,
                setDataToUpdate,
                setOnItemsChange,
            }}
        >
            <InviteMember />
            <CreateTeam />
            <ClientsModalContext />
            <ServiceModalContext />
            <InvoiceModalContext />
            <ExpenseModalContext />
            <StripeAutomationModalContext />
            <PaymentRequestModalContext />
            <RecurringPaymentModalContext />
            <ReceiptModalContext />
            {props.children}
        </ModalContext.Provider>
    )
}

export default ModalState;