import { generalreactSelectStyles } from "../../app/functions/styles"
import { Col, DatePicker, Form, Row, Select, Typography } from "antd"
import moment from "moment"
import DailyRecurring from "../calendarize/DailyRecurring"
import WeeklyRecurring from "../calendarize/WeeklyRecurring"
import MonthlyRecurring from "../calendarize/MonthlyRecurring"
import { SummarizeMessage } from "../calendarize/SummarizeRecurring"

const CalendarOptions = ({ edit = null, changeState, state }) => {



    return <Row justify="space-between">
        <Col xs={24}>
            <Form.Item name="temporality" label="Tipo de recurrencia" rules={[{ required: true, message: 'Por favor selecciona una temporalidad' }]}>
                <Select
                    styles={generalreactSelectStyles}
                    className="pro-select-input"
                    onChange={(v) => {
                        if (v) {

                            changeState({ ...state, temporality: typeof v === 'object' ? v.value : v, onTime: edit ? edit.onTime.format('HH:mm') : null, limitDaysToPay: edit?.limitDaysToPay ?? null, feeAdded: edit?.discount })
                        }
                    }}
                    options={[
                        {
                            value: 'monthly',
                            label: 'Mensual'
                        }, {
                            value: 'weekly',
                            label: 'Semanal'
                        },
                        {
                            value: 'daily',
                            label: 'Diario'
                        },


                    ]}
                    isLoading={false}
                    placeholder="Temporalidad"

                />
            </Form.Item>
        </Col>
        <Col xs={24}>
            <Row gutter={{ xs: 1, md: 10 }} align="middle">
                <Col xs={24} md={12}>
                    <Form.Item label="Fecha de inicio y de fin" name="startEndDate">
                        <DatePicker.RangePicker locale={
                            {
                                "lang": {
                                    "locale": "es_MX",
                                    "placeholder": "Selecciona una fecha",
                                    "rangePlaceholder": ["Inicio", "Final"],
                                }
                            }
                        } ranges={state.temporality === 'monthly' ? {

                            'Un mes': [moment(), moment().add(1, 'month')],
                            'Dos meses': [moment(), moment().add(2, 'month')],
                            'Tres meses': [moment(), moment().add(3, 'month')],
                        } : state.temporality === 'weekly' ? {
                            'Una semana': [moment(), moment().add(1, 'week')],
                            'Dos semanas': [moment(), moment().add(2, 'week')],
                            'Tres semanas': [moment(), moment().add(3, 'week')],

                        } : {}} className="pro-input" format="DD/MM/YYYY" disabledDate={(c) => {
                            return c.isBefore(moment().add(-3, 'days'));
                        }} onCalendarChange={(a) => {

                            if (!a) {
                                return
                            }
                            if (a.length < 2) {
                                return;
                            }
                            if (a[0] && a[1]) {
                                changeState({
                                    ...state,
                                    startDate: a[0]?.format('YYYY-MM-DD HH:mm'),
                                    endDate: a[1]?.endOf('day').format('YYYY-MM-DD HH:mm'),
                                    startEndDate: a,
                                })


                            }

                        }} />
                    </Form.Item>
                </Col>
                {state.temporality === 'daily' && <DailyRecurring edit={edit} updateState={(v) => {

                    changeState({ ...state, ...v })
                }} />}
                {state.temporality === 'weekly' && <WeeklyRecurring edit={edit} onWeekday={state.onWeekday} updateState={(v) => {
                    changeState({ ...state, ...v })

                }
                } />}
                {state.temporality === 'monthly' && <MonthlyRecurring edit={edit} onDay={state.onDay} updateState={(v) => {
                    changeState({ ...state, ...v })
                }
                } />}
            </Row>
        </Col>




        <Col xs={24}>
            {(state.startDate && state.endDate && state.onTime) && SummarizeMessage({ state }).message}
        </Col>

        {!state.startDate && <Col xs={24}>
            <Typography.Text className='' type="danger" style={{ fontSize: '12px' }}>Por favor selecciona las fechas de inicio y de fin</Typography.Text>
        </Col>}
    </Row>
}

export default CalendarOptions;