const webAppVersion = " 2.0.51";

//1.0.16 - 2023-03-02 - Changed membership buttons.
//2.0.0 - 2023-03-20 - Seats 
//2.0.5 - 2023-03-20 - Bug fixes
// 2.0.6 - 2023-03-30 - Modals fixes
// 2.0.7 - 2023-04-01 - Folio fixes
// 2.0.8 - 2023-04-02 - Receipts Errors
//2.0.9 - 2023-04-03 - Allow create new brands with a charge of 5 USD plus taxes.
// 2.0.10 - 2023-04-05 - Client Country Fixed
//2.0.11 - 2023-04-10 - Fix some bugs
// 2.0.12 Stripe Automatic invoicing Modal
// 2.0.13 - 2023-04-12 - API Keys on team
// 2.0.14 - 2023-04-13 - Fix some bugs with bank account add.
// 2.0.15 - 2023-04-14 - Facturapi Modal
// 2.0.16 - 2023-04-15 - Receipts Modal
//2.0.17 - 2023-04-15 - Remove 15 trial
// 2.0.18 - 2023-04-16 - Default taxes added (airplane tickets)
// 2.0.19 - 2023-04-16 - Receipts in main page
// 2.0.20 - 2023-04-20 - Fix some bugs and show name in relations
// 2.0.21 - 2023-04-20 - Add search on receipts 
// 2.0.22 - 2023-04-21 - Create manual payments
// 2.0.23 - 2023-04-22 - Team member permissions Select changed
// 2.0.24 - 2023-04-23 - Hilos new Events
// 2.0.25 - 2023-04-24 - Membership trial 15 days conditional
//2.0.26 - 2023-04-26 - Popconfirm 15 days trial 
// 2.0.27 - 2023-04-27 - Stripe Automatic Invoicing Modal
// 2.0.28 - 2023-04-28 - Remove 15 days trial
// 2.0.29 - 2023-05-04 - Cards in dashboard and remove settings
// 2.0.30 - 2023-05-05 - Fix some bugs
// 2.0.31 - 2023-05-10 - Add PENDING INVITES
// 2.0.32 - 2023-05-11 - Add defaults con client and unit keys
// 2.0.33 - 2023-05-12 - New Invoice Details View
// 2.0.34 - 2023-05-13 - Add custom folios
// 2.0.35 - 2023-05-14 - Add actions to payments on client details
// 2.0.36 - 2023-05-15 - Insigths fixed
// 2.0.37 - 2023-05-16 - Total in receipts
// 2.0.38 - 2023-05-17 - Fix bug on createInvoice modal
// 2.0.39 - 2023-05-18 - Webhooks
//2.0.40 - 2023-05-19 - Manual confirm payment is confirmed as paid in cash.
// 2.0.41 - 2023-05-20 - Openpay Integration
// 2.0.42 - 2023-05-21 - Pagination and Search Modal
// 2.0.43 - 2023-05-22 - Fix on search with local data
// 2.0.44 - 2023-05-23 - RecurringEvents errors
// 2.0.45 - 2023-05-24 - InvoicePDFExtra added
// 2.0.46 - 2023-07-18 - import client from excel
// 2.0.47 - 2023-07-19 - Payment reminder email
// 2.0.48 - 2023-08-4 - Edit receipts
// 2.0.49 - 2023-08-5 - Fix some bugs in realtions amount
// 2.0.50 - 2023-08-6 - Invoices and payments details from search modal
// 2.0.51 - 2023-08-7 - New invoice substitution page
module.exports = {
    webAppVersion
}