import { Button, Col, Row, Space, message } from 'antd';
import React, { Suspense, useState } from 'react';
import ActionablePageHeader from '../../widgets/headers/ActionablePageHeader';
import DefaultHelpPageHeader from '../../widgets/headers/DefaultHelpPageHeader';

// import CreateElementHolder from '../../widgets/drawers/CreateElementHolder';
import PreviewClientModal from '../../widgets/clients/PreviewClientModal';
import AddNewClientForm from '../../widgets/clients/AddNewClientForm';
import ClientsTable from '../../widgets/tables/ClientsTable';
import ImportThings from '../../widgets/modals/ImportThings';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { SignedInternalAPIRequest } from '../functions/APIRequests';
import moment from 'moment';
import { useAuth } from 'reactfire';


function ClientsPage({ userDocument, subtab, changeRender }) {


    // const { team } = useContext(TeamContext)


    // const fs = getFirestore();
    // const clientsRef = query(collection(fs, `clients`), where('team', '==', team?.id ?? ''), orderBy('timestamp', 'desc'));

    // // subscribe to a document for realtime updates. just one line!
    // const { status: clientsDBStatus, data: clientsData } = useFirestoreCollectionData(clientsRef, {
    //     id: 'id', // this field will be added to the object created from each document
    // });


    const [addClient, setaddClient] = useState(false)
    const [editClient, setEditClient] = useState(null)
    const [importClients, setImportClients] = useState(false)
    const [downloadingXLSX, setdownloadingXLSX] = useState(false);
    const auth = useAuth();

    const DownloadXLSX = async () => {
        try {
            setdownloadingXLSX(true);
            await SignedInternalAPIRequest(
                {
                    clientId: null,
                },
                `exportFiles/v1/clients`,
                auth.currentUser,
                {
                    isFile: true,
                    fileName: `clientes-${moment().format("DD/MM/YYYY")}`,
                    fileExtension: "xlsx",
                },
                "POST"
            );
            //DOWNLOAD FILE FROM readableStream
            setdownloadingXLSX(false);

        } catch (error) {
            message.error("Error al descargar el archivo");
            setdownloadingXLSX(false);
        }
    };

    //TODO: Add a modal to add a new client

    /**
     * It returns a CreateElementHolder component, which is a modal that allows the user to add a new
     * client
     * @returns A component that is a modal that is used to add or edit clients.
     */
    const AddComponents = () => {
        return <>
            {<AddNewClientForm open={addClient} edit={editClient ?? null} close={() => {
                setEditClient(null)
                setaddClient(false)
            }} userDocument={userDocument} type="clients" />}
        </>
    }
    /**
     * A function that returns a component that is a table with the clients that the user has.
     * @returns a component
     */

    const [preview, setPreview] = useState(null)
    const DefaultComponent = ({ loading }) => {
        return <Row justify='center' >
            {preview && <Suspense>
                <PreviewClientModal client={preview} open={preview} close={() => setPreview(null)} />
            </Suspense>}
            {importClients && <ImportThings open={importClients} close={() => setImportClients(false)} templateUrl="https://firebasestorage.googleapis.com/v0/b/gigstackpro.appspot.com/o/public%2FClients_tamplate_gigistack.xlsx?alt=media&token=e7ba40f5-35d3-4b38-a74f-fdd0548c769e" type="clients" readableType="clientes" />}
            <AddComponents />
            <Col xs={24}>
                <DefaultHelpPageHeader userDocument={userDocument} title="Clientes" description={"Guarda a tus clientes para que puedas solicitar pagos, enviar facturas y más de manera fácil "} />
            </Col>
            <Col xs={24} style={{ marginTop: '25px' }}>
                <ActionablePageHeader title="Mis clientes" actions={
                    <Space>
                        <Button loading={downloadingXLSX} icon={<CloudDownloadOutlined />} onClick={DownloadXLSX} />
                        <Button icon={<IoCloudUploadOutline style={{ marginRight: '10px' }} />} type="ghost" onClick={() => setImportClients(true)}>Importar</Button>
                        {/* <Button type='primary' onClick={() => setaddClient(true)}>Nuevo cliente</Button> */}
                        <Button type='primary' onClick={() => {
                            setaddClient(true)
                        }}>Nuevo cliente</Button>
                    </Space>}
                />
            </Col>

            <ClientsTable setEditClient={(v) => setEditClient(v)} setaddClient={(v) => setaddClient(v)} changeRender={(v) => changeRender(v)} setPreview={(v) => setPreview(v)} />

        </Row >
    }





    return (
        <DefaultComponent />
    );




}

export default ClientsPage;
