import { Col, Row, Table } from 'antd';

import React from 'react';


import EmptyTable from '../states/EmptyTable';

function DefaultTableItemsLocal({ onRow, avoidContainer, userDocument, updateParent, data, columns, customFilter, loading, empty = "Aún no tienes clientes", emptyAction, elementName, onPageChange = () => { }, defaultPage = 1, onChange = () => { } }) {



    return (
        <Row style={{ padding: 0, margin: 0, width: '100%' }}>
            <Col xs={24}>
                <div className={avoidContainer ? '' : 'tableContainer'}>
                    <Row style={{ width: '100%' }}>
                        {customFilter && <Col xs={24}>
                            <Row justify='end'>
                                {customFilter}
                            </Row>
                        </Col>}
                        <Col xs={24} style={{ marginTop: customFilter ? '25px' : '0px' }}>
                            <Table

                                onChange={onChange}
                                onRow={onRow ? onRow : (record, rowIndex) => { }}
                                rowKey={record => record.id}
                                loading={loading}
                                scroll={{ x: 500 }}
                                columns={columns}
                                dataSource={data}
                                bordered={false}
                                showSorterTooltip={false}
                                pagination={data?.length > 10 ? {
                                    pageSize: 10,
                                    hideOnSinglePage: true,
                                    onChange: (p) => onPageChange(p),
                                    defaultCurrent: defaultPage
                                } : false}
                                className='pro-table'

                                locale={{
                                    emptyText: <EmptyTable customMessage={empty} parentAction={emptyAction} elementName={elementName} />,
                                    filterCheckall: 'Todos',
                                }}


                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}

export default DefaultTableItemsLocal;