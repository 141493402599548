import { Badge, Col, Divider, Row, Space, Tag, Typography } from "antd";
import moment from "moment";

const VerifyAppStatus = () => {

    return (
        <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: window.innerWidth < 750 ? '95%' : '60%', margin: '50px auto', textAlign: 'center', padding: '15px' }}>
                <h1>Todos los sistemas funcionando</h1>
                <p>Esta es la página de status de gigstack, si presentamos alguna interrupción en algunos de nuestros servicios lo notificaremos aquí</p>
                <Divider />

                <Space direction="vertical" style={{ textAlign: 'left', width: '80%', marginTop: '25px' }}>
                    <Row align="middle" justify="space-between">
                        <Badge status="processing" color="green" text="API" />
                        <Tag color="success">Funcionando</Tag>
                    </Row>
                    <Divider type="" />
                    <Row align="middle" justify="space-between">
                        <Badge status="processing" color="green" text="Facturación" />
                        <Tag color="success">Funcionando</Tag>
                    </Row>
                    <Divider type="" />
                    <Row align="middle" justify="space-between">
                        <Badge status="processing" color="green" text="Portal de pagos" />
                        <Tag color="success">Funcionando</Tag>
                    </Row>
                    <Divider type="" />
                    <Row align="middle" justify="space-between">
                        <Badge status="processing" color="green" text="Portal de autofactura" />
                        <Tag color="success">Funcionando</Tag>
                    </Row>
                </Space>

                <Divider />
                <h4> Datos de tu conexión </h4>

                <Space direction="vertical" style={{ textAlign: 'left', width: '80%', marginTop: '25px' }}>
                    <Row align="middle" justify="space-between">
                        <Typography.Text strong>Plataforma</Typography.Text>
                        <Typography.Text>
                            {navigator.platform}
                        </Typography.Text>
                    </Row>
                    <Divider type="" />
                    <Row align="middle" justify="space-between">
                        <Col xl={6} lg={6} md={6} xs={6}>
                            <Typography.Text strong>Navegador</Typography.Text>
                        </Col>
                        <Col xl={18} lg={18} md={18} xs={18}>
                            <Typography.Text>
                                {navigator.userAgent}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Space>
                <Row justify="center" align="middle" style={{ margin: '30px' }}>
                    <Typography.Text type="secondary" style={{ fontSize: '13px' }}>Última actualización: {moment().subtract(17, 'minutes').format('lll')}</Typography.Text>
                </Row>
            </div>
        </div>
    );
}

export default VerifyAppStatus;