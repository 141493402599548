import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row, Space, Tag, Typography } from 'antd';
import React from 'react'

function PreviewClientModal({ open, close, client }) {

    var rfc = client?.rfc ?? 'Sin identificador tributario'
    var name = client?.name
    var company = client?.company
    var taxRegime = typeof client?.tax_system === 'object' ? client?.tax_system?.label : typeof client?.tax_system === 'string' ? typeof client?.tax_system === 'object' : ''
    var line1 = client?.address?.address || 'Sin dirección'
    var zip = client?.address?.zip || ''
    var country = typeof client?.address?.country === 'string' ? client?.address?.country : typeof client?.address?.country === 'object' ? client?.address?.country?.label : ''
    return (
        <Modal style={{ width: window.innerWidth < 800 ? '90%' : '50%' }} visible={open} closable={false} closeIcon={<></>} onCancel={close} footer={null} >
            <div style={{ width: '100%' }} className="d-flex flex-column">
                <Row align="top" style={{ width: '100%', }}>
                    <div className="d-flex flex-column" style={{ flex: 1 }}>
                        <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>{name} {company ? 'de' : ''} {company}</Typography.Title>
                        <Typography.Text type="secondary" >{client?.email ?? 'sin correo'}</Typography.Text>
                    </div>
                    <CloseOutlined onClick={() => close()} />
                </Row>
                <Typography.Text type="" style={{ marginTop: '15px' }} className='cLabel'>Fiscal</Typography.Text>
                <Space style={{ marginTop: '5px' }} wrap >
                    <Tag>{rfc}</Tag>
                    {taxRegime && <Tag>{taxRegime}</Tag>}
                </Space>
                <Typography.Text type="" style={{ marginTop: '15px' }} className='cLabel'>Dirección</Typography.Text>
                <Space style={{ marginTop: '5px' }} size="small" wrap >
                    <Tag color="green">{line1}</Tag>
                    <Tag color="green">{zip}</Tag>
                    <Tag color="green">{country}</Tag>
                </Space>


            </div>
        </Modal>
    );
}

export default PreviewClientModal;