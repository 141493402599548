import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FirebaseAppProvider } from 'reactfire';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import VerifyAppStatus from './widgets/appStatus/VerifyAppStatus';
const firebaseConfig = {
  apiKey: "AIzaSyBzksUCiBmqkt_z-KvqXnxSsyHFY0VLOr8",
  authDomain: "gigstackpro.firebaseapp.com",
  projectId: "gigstackpro",
  storageBucket: "gigstackpro.appspot.com",
  messagingSenderId: "904246244680",
  appId: "1:904246244680:web:07c4ed2e1aacf1a43a6f7d",
  measurementId: "G-MGRGH2ECDP"
};


if (process.env.NODE_ENV !== 'development' || window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: "https://061230ac26c34077878de54b66e72ffd@o4504736504610816.ingest.sentry.io/4504752995631104",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.8,
  });
}


const root = ReactDOM.createRoot(document.getElementById('root'));


if (window.location.href.includes('verifystatus')) {
  root.render(
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<VerifyAppStatus />} />
      </Routes>
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/status" element={<>Status page</>} />
      </Routes>
      <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
        <App />
      </FirebaseAppProvider>
    </BrowserRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
