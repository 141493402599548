const airtableIntegrationCode = (apiKey, tableName) => {
    return `

let APIKey = "${apiKey ?? 'Aquí debes colocar tu API Key'}"


var inputConfig = input.config();

let table = base.getTable("${tableName ?? 'Aquí debes colocar el nombre de la tabla'}");

let fields = table.fields
let keyFields = fields.map((f) => f.name);
let queryResult = await table.selectRecordsAsync({fields});

let mappedAll = queryResult.records.map( (record) => { 
    var values = {} 
    for(const key of keyFields){ 
        values[key] = record.getCellValue(key) 
    } 
    return {...values, externalId:record.id}})

    console.log(mappedAll)

var currentRowValues = {};

var currentRecord = queryResult.getRecord( inputConfig.externalId)

for (const key of keyFields) { 
    currentRowValues[key] = currentRecord.getCellValue(key)
}

console.log(currentRowValues)

await table.updateRecordAsync(inputConfig.externalId, { "gigstackId": 'Processing...' })


const body = {
    "allData":currentRowValues,
    "items":[
      {
      "name": currentRowValues.description,
      "total": currentRowValues.amount,
      "quantity": 1,
      }
      ],
   
    
    'automaticInvoice': currentRowValues.automaticInvoice ?? currentRowValues.automaticInovice,
    'paid': currentRowValues.paid,
    "client":{
      "email": currentRowValues.customerEmail,
      "name": currentRowValues.Name
    },
    'action':'payment_request',
    'custom_method_types':["card","oxxo","bank"],
    'from':'airtable',
    "metadata": {
        "internalId":inputConfig.externalId
    }
  }




let response = await fetch('https://gigstack-cfdi-bjekv7t4.uc.gateway.dev/v1/hooks/push', 
    { 
        method: 'POST', 
        body:JSON.stringify(body), 
        headers:{ 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + APIKey
        }
    });
const json = await response.json();
console.log(json)
if (json.id) { 
    await table.updateRecordAsync(json.metadata.internalId, 
        { "gigstackId": json.id, "PaymentUrl":json.shortUrl }
    )
}


`;
}

export default airtableIntegrationCode;